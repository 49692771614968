import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../../style/themes/theme';

const theme: any = getTheme();

export const PreviewImageContainer = styled.div<{ $background: string }>`
    width: 48px;
    height: 48px;
    border-radius: 4px;
    background: ${({ $background }) => $background};
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 40px;
        height: 40px;
        color: ${theme.palette.white.main};
    }
`;
export const PreviewNameContainer = styled.div`
    ${fontSizesCSS.Caption2};
`;

export const TemplatePreviewCard = styled.div`
    height: 92px;
    box-sizing: border-box;
    display: flex;
    gap: 12px;
    padding: 20px 24px;
    align-items: center;
    border: 1px solid ${theme.palette.background.border};
    border-radius: 4px;
`;
