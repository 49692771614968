import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { TruncatedText } from '../../../style/styled-components/reusable.css';
import { descendingZIndex, UserAvatar } from '../../Projects/Projects.css';

const theme: any = getTheme();

export const GroupName = styled(TruncatedText)`
    color: ${theme.palette.primary.main};
    cursor: pointer;
`;

export const TenantRow = styled.div`
    display: flex;
    align-items: center;
    ${fontSizesCSS.Caption1};
`;

export const TenantIcon = styled.div`
    width: 24px;
    height: 24px;

    img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }
`;

export const TenantName = styled(TruncatedText)`
    margin-left: 8px;
`;

export const IconContainer = styled.div`
    display: flex;
    ${descendingZIndex()};

    ${UserAvatar} {
        border: 1px solid ${theme.palette.white.main};
        margin-right: -8px;
        width: 28px;
        height: 28px;
    }
`;
