import React, { FC } from 'react';
import { ApplicationWrapper, MainContentWrapper } from '../../style/styled-components/reusable.css';
import Sidebar from '../common/Sidebar/Sidebar';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import { Capabilities, CapabilitiesScreen } from '../Capabilities/CapabilitiesTopScreen';
import useRestrictedProjectAccess from '../../hooks/useRestrictedAccess';
import { restrictedSectionsTypes } from '../../types/Project';

const Optimize: FC = () => {
    const { isRestrictedArea: isOptimizeRestricted } = useRestrictedProjectAccess(restrictedSectionsTypes.CAPABILITIES, 'optimize');
    return (
        <ApplicationWrapper>
            <Sidebar />
            <MainContentWrapper>
                <ScreenTitle loading={false} title={'Optimize'} withProfile />
                <CapabilitiesScreen type={Capabilities.OPTIMIZE} disabled={isOptimizeRestricted} />
            </MainContentWrapper>
        </ApplicationWrapper>
    );
};

export default Optimize;
