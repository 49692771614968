import React, { FC, useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogDropdownSingle, DialogTextField, DialogTypes } from '../../common/Dialog/GenericDialog';
import { Project } from '../../../types/Project';
import { validator } from '../../../utils/fnValidator';
import { ProjectsState } from '../../../redux/slices/projectsSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchTenants, TenantsState } from '../../../redux/slices/tenantsSlice';
import _ from 'lodash';

type CopyProjectDialogProps = {
    open: boolean;
    onClose: () => void;
    onCopy: (projectId: string, tenantId: string, projectKey: string) => Promise<void>;
    project?: Project;
};

const CopyProjectDialog: FC<CopyProjectDialogProps> = ({ open, onClose, onCopy, project }) => {
    const [projectKey, setProjectKey] = useState('');
    const [selectedTenantId, setSelectedTenantId] = useState('');
    const [errors, setErrors] = useState<{ key?: string; tenantId?: string }>({});

    const { projects, loading: projectsLoading }: ProjectsState = useAppSelector((state) => state.projects);
    const { tenants, loading: tenantsLoading, error: tenantsError }: TenantsState = useAppSelector((state) => state.tenants);

    const dispatch = useDispatch();

    useEffect(() => {
        setProjectKey(project?.key || '');
    }, [project]);

    useEffect(() => {
        loadTenants();
    }, []);
    const loadTenants = async () => {
        if (tenants.length) return;
        return await dispatch(fetchTenants({})).unwrap();
    };

    const handleCloseClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
        if (projectsLoading) return;
        setErrors({});
        setProjectKey('');
        setSelectedTenantId('');
        onClose();
    };

    const handleCopyClick = async (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        const newErrors = { ...errors };
        newErrors.tenantId = validator({ required: true }, selectedTenantId);
        const isValidKey = !projects.filter((p) => p.tenantId === selectedTenantId).some((p) => p.key === projectKey);
        newErrors.key = !isValidKey ? 'Keys should be unique!' : validator({ required: true, minLength: 3 }, projectKey);

        if (Object.values(newErrors).filter((value) => !!value).length > 0) return setErrors(newErrors);
        // Extra safety, the validators behave correctly, but typescript doesn't know that
        // with this extra if, we make sure that we have non-empty values for the onCopy function call below
        if (!project || !selectedTenantId || !projectKey) return;
        await onCopy(project._id, selectedTenantId, projectKey);
        setErrors({});
        setProjectKey('');
        setSelectedTenantId('');
    };
    const copyButton: DialogButton = {
        label: 'Copy',
        type: 'BLUE',
        onClick: handleCopyClick,
        loading: projectsLoading,
        disabled: tenantsLoading
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick,
        disabled: projectsLoading
    };

    const tenantsOptions = tenants
        .filter((tenant) => tenant._id !== project?.tenantId)
        .map((tenant) => {
            return {
                label: tenant.name,
                value: tenant._id
            };
        });

    if (!open) return null;
    return (
        <GenericDialog title={'Copy To Tenant'} type={DialogTypes.Form} onClose={onClose} actionButtons={[cancelButton, copyButton]}>
            <DialogDropdownSingle
                labelText={'Select Tenant'}
                error={errors?.tenantId}
                value={tenantsOptions.find((opt) => opt.value === selectedTenantId) || undefined}
                options={tenantsOptions}
                placeholder={tenantsLoading ? 'Loading Tenants...' : tenantsError ? `Couldn't load Tenants!` : 'Select Tenant'}
                onChange={(selectedOption: any) => {
                    setSelectedTenantId(selectedOption.value);
                    setErrors(_.omit(errors, 'tenantId'));
                }}
                isDisabled={!project || tenantsLoading}
            />
            <DialogTextField
                value={projectKey}
                error={errors?.key}
                label={'Project Key'}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    setErrors(_.omit(errors, 'key'));
                    if (evt.target.value.length > 3) return;
                    setProjectKey(evt.target.value.toUpperCase());
                }}
            />
        </GenericDialog>
    );
};

export default CopyProjectDialog;
