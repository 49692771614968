import React, { FC, useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../../common/Dialog/GenericDialog';
import { Project } from '../../../types/Project';
import _ from 'lodash';
import { generateKeyFromName, generateUID } from '../../../utils/fnGenerator';
import { validator } from '../../../utils/fnValidator';

interface IDuplicateProject {
    open: boolean;
    onClose: () => void;
    onSave: any;
    projects: Project[];
    project?: Project;
}
const DuplicateProjectDialog: FC<IDuplicateProject> = ({ projects, project, open, onClose, onSave }) => {
    const [projectName, setProjectName] = useState('');
    const [projectKey, setProjectKey] = useState('');
    const [errors, setErrors] = useState<{ name?: string; key?: string }>({});

    useEffect(() => {
        setProjectKey(projectName.length > 2 ? generateKeyFromName(projectName).toUpperCase() : projectKey);
    }, [projectName]);

    const handleCloseClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        if (onClose) {
            onClose();
            setProjectName('');
            setProjectKey('');
            setErrors({});
        }
    };

    const handleSaveClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        const newErrors = { ...errors };
        const isValidKey = !projects.filter((p) => p.tenantId === project?.tenantId).some((p) => p.key === projectKey);

        newErrors.name = validator({ required: true, minLength: 4 }, projectName);
        newErrors.key = !isValidKey ? 'Keys should be unique!' : validator({ required: true, minLength: 3 }, projectKey);

        if (Object.values(newErrors).filter((value) => !!value).length > 0) return setErrors(newErrors);
        if (!project || !projectKey || !projectName) return;
        onSave(project._id, project.tenantId, projectKey, projectName);
        setErrors({});
        setProjectKey('');
        setProjectName('');
    };
    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleSaveClick
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    return open ? (
        <GenericDialog title={'Duplicate project'} type={DialogTypes.Form} onClose={onClose} actionButtons={[cancelButton, saveButton]}>
            <p>
                You are duplicating the project: <strong>{project?.name}</strong>
            </p>
            <DialogTextField
                value={projectName}
                error={errors?.name}
                label={'New Project Name'}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    const value = evt.target.value;
                    setProjectName(value);
                    setErrors(_.omit(errors, 'name'));
                }}
            />
            <DialogTextField
                value={projectKey}
                error={errors?.key}
                label={'New Project Key'}
                placeholder={'(auto-filled)'}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    if (evt.target.value.length > 3) return;
                    setProjectKey(evt.target.value.toUpperCase());
                }}
            />
        </GenericDialog>
    ) : null;
};

export default DuplicateProjectDialog;
