import React, { FC, useState } from 'react';
import icons from '../../../style';
import SVGInline from 'react-inlinesvg';
import {
    CloseIconWrapper,
    SnackBarMessage,
    SnackBarText,
    SnackBarTitle,
    SnackBarWrapper,
    StyledSnackBar,
    MainIconWrapper
} from './Toast.css';
import { iconType } from '../../../assets/images/icons';

export type toastTypes = 'success' | 'warning' | 'critical_warning' | 'error';

type ToastProps = {
    title: string;
    message: string | JSX.Element;
    type: toastTypes;
    customIcon?: iconType;
    dialogRenderFunction?: () => void;
    /*
     * MUI does not support multiple toasts/snackbars on top of each other. For handling 2 toasts (this is the max we have right now), a toast rendering function should be passed to onClose, so that when one dissapears the other one will appear
     * TBD if it makes sense to implement a 3rd party library for toasts which supports multiple toasts as per the MUI docs: https://mui.com/material-ui/react-snackbar/#notistack https://notistack.com/api-reference
     */
    onClose?: () => void;
};
const Toast: FC<ToastProps> = ({ title, message, type, customIcon, dialogRenderFunction, onClose }) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);

    const handleClose = (e: any) => {
        e?.stopPropagation();
        e?.preventDefault();
        onClose?.();
        setIsOpen(false);
    };

    const handleClick = () => {
        dialogRenderFunction?.();
    };

    const renderIcon = () => {
        let icon: iconType | null = null;
        if (customIcon) {
            icon = customIcon;
        } else {
            switch (type) {
                case 'success':
                    icon = icons.checkmarkWhiteIcon;
                    break;
                case 'warning':
                    icon = icons.infoIcon;
                    break;
                case 'critical_warning':
                    icon = icons.warningWhiteIcon;
                    break;
                default:
                    break;
            }
        }
        if (!icon) return null;
        return <SVGInline src={icon} />;
    };

    return (
        <StyledSnackBar open={isOpen} autoHideDuration={3000} onClose={handleClose} onClick={handleClick}>
            <SnackBarWrapper $type={type}>
                <MainIconWrapper>{renderIcon()}</MainIconWrapper>
                <SnackBarText>
                    <SnackBarTitle>{title}</SnackBarTitle>
                    <span>|</span>
                    <SnackBarMessage>{message}</SnackBarMessage>
                </SnackBarText>
                <CloseIconWrapper onClick={handleClose}>
                    <SVGInline src={type !== 'warning' ? icons.closeWhiteIcon : icons.closeIcon} />
                </CloseIconWrapper>
            </SnackBarWrapper>
        </StyledSnackBar>
    );
};

export default Toast;
