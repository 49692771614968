import { useEffect, useRef } from 'react';

/**
 * This hook mimics the behaviour of React 17 useEffect. To be used only when absolutelly needed.
 * It is adviced to get rid of double execution of useEffect with proper cleaning / request aborting rather that using this.
 *
 * @param {() => (() => void)| void} effect The effect to be run. If return type of effect is a function, the function will be run on unmount as a cleanup.
 * @param {any[]} dependencies The effect dependencies. The effect will run at mount and each time a dependency changes.
 */
export const UNSAFE_useEffectOnce = (effect: () => (() => void) | void, dependencies: any[] = []) => {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current || process.env.NODE_ENV !== 'development') {
            const cleanupFunction = effect();
            // if return type of our effect is a function, it means we have a cleanup function so we return for it to be run on unmount
            if (cleanupFunction && typeof cleanupFunction === 'function') {
                return cleanupFunction;
            }
        } else {
            didMountRef.current = true;
        }
    }, dependencies);
};
