import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchObject } from '../../components/common/SearchBar/Header/HeaderSearch';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';

export const searchObjects = createAsyncThunk<{ objects: any; error: { message: string; code: string } | null }, any | undefined>(
    'globalSearch/searchObjects',
    async ({ projectId, searchTerm }, thunkApi) => {
        const result = await ConfigServiceAPI.globalSearch(projectId, searchTerm);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { objects: result.response as any, error: null };
    }
);

export interface SearchState {
    objects: any;
    documentationKeys: any;
    searchResults: SearchObject[];
    searchTerm: string;
    loading: boolean;
    isSidebarOpen: boolean;
    error: {
        message: string;
        code?: string;
        status?: number;
    } | null;
}

const initialState: SearchState = {
    objects: null,
    documentationKeys: null,
    searchResults: [],
    searchTerm: '',
    isSidebarOpen: false,
    error: null,
    loading: false
};

const slice = createSlice({
    name: 'globalSearch',
    initialState,
    reducers: {
        setSearchResults(state, action: PayloadAction<any>) {
            state.searchResults = action.payload;
        },
        unsetSearchResults(state) {
            state.searchResults = [];
        },
        setDocumentationKeys(state, action: PayloadAction<any>) {
            state.documentationKeys = action.payload;
        },
        unsetDocumentationKeys(state) {
            state.documentationKeys = null;
        },
        setSearchTerm(state, action: PayloadAction<any>) {
            state.searchTerm = action.payload;
        },
        unsetSearchTerm(state) {
            state.searchTerm = '';
        },
        toggleOpenSearchBar(state, action: PayloadAction<boolean>) {
            state.isSidebarOpen = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchObjects.fulfilled, (state, action: any) => {
                state.objects = action.payload.objects;
                state.error = null;
                state.loading = false;
            })
            .addCase(searchObjects.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.objects = null;
                state.loading = false;
            })
            .addCase(searchObjects.pending, (state, _action) => {
                state.loading = true;
                state.objects = null;
            });
    }
});

export default slice.reducer;

export const {
    setSearchResults,
    unsetSearchResults,
    setDocumentationKeys,
    unsetDocumentationKeys,
    setSearchTerm,
    toggleOpenSearchBar,
    unsetSearchTerm
} = slice.actions;
