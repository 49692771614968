import styled, { css } from 'styled-components';
import { CardPresenter, CardsContainer, CardWrapper } from '../VisualEditor.css';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';

const theme: any = getTheme();

export const DynamicCardWrapper = styled(CardWrapper)`
    margin: 0 8px;
    ${CardPresenter} {
        cursor: pointer;
    }
`;

export const ViewAllCardWrapper = styled(DynamicCardWrapper)`
    ${fontSizesCSS.Title3};
    color: ${theme.palette.white.main};
`;

export const DynamicCardsContainer = styled(CardsContainer)<{ height: string }>`
    min-height: ${(props) => props.height};
`;
