import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';

export const lockObject = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { objectId: string; objectType: any }
>('lockSystem/lock', async ({ objectId, objectType }, thunkApi) => {
    let result;

    switch (objectType) {
        case 'audiences':
            result = await ConfigServiceAPI.lockAudience(objectId);
            break;
        default:
            result = await ConfigServiceAPI.lockObject(objectId, objectType);
    }

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const unlockObject = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { objectId: string; objectType: any }
>('lockSystem/unlock', async ({ objectId, objectType }, thunkApi) => {
    let result;

    switch (objectType) {
        case 'audiences':
            result = await ConfigServiceAPI.unlockAudience(objectId);
            break;
        default:
            result = await ConfigServiceAPI.unlockObject(objectId, objectType);
    }

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export interface lockingMechanismState {
    loading: boolean;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: lockingMechanismState = {
    loading: false,
    error: null
};

const slice = createSlice({
    name: 'lockSystem',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(lockObject.fulfilled, (state, _action) => {
                state.error = null;
                state.loading = false;
            })
            .addCase(lockObject.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(lockObject.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(unlockObject.fulfilled, (state, _action) => {
                state.error = null;
                state.loading = false;
            })
            .addCase(unlockObject.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(unlockObject.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export default slice.reducer;
