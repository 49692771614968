import React from 'react';
import GenericDialog, { DialogButton, DialogTypes } from '../../common/Dialog/GenericDialog';
import {
    Title,
    FlexContainer,
    FooterContainer,
    HighlightedText,
    LinkText,
    Text,
    BoldText,
    ScrollableContainer,
    GradientBottom
} from '../Login.css';

const TermsOfServiceDialog: React.FC<any> = ({ handleClose, open }) => {
    const cancelButton: DialogButton = {
        label: 'Close',
        type: 'BLUE',
        onClick: handleClose
    };

    if (!open) return null;
    return (
        <GenericDialog
            title={'PRIVACY NOTICE CONTROL CENTER 3.0'}
            type={DialogTypes.DisplayCondition}
            onClose={handleClose}
            actionButtons={[cancelButton]}
        >
            <ScrollableContainer>
                <Text>Last Update 03/2023</Text>
                <Text>3 Screen Solutions GmbH (‘3SS’, ‘we’, ‘us’, ‘our’) is the operator of the CONTROL CENTER 3.0.</Text>
                <Text>
                    With the following data protection information, we explain to you what personal data we collect, the purposes for which
                    we process it and the rights you are entitled to when you use our CONTROL CENTER 3.0 and its individual functions. The
                    protection of your personal data is very important to us.
                </Text>
                <Text>
                    According to the EU Data Protection Basic Regulation (&quot;GDPR&quot;), personal data is any information relating to an
                    identified or identifiable natural person (&quot;data subject&quot;). An identifiable person is a natural person who can
                    be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification
                    number, location data, an online identifier or one or more special personal characteristics.
                </Text>
                <Title>1. RESPONSIBLE PERSON FOR DATA PROTECTION</Title>
                <Text>
                    The person responsible for the collection and processing of your personal data when visiting and using the CONTROL
                    CENTER 3.0 is
                </Text>
                <Text>
                    3 Screen Solutions GmbH
                    <br />
                    Hauptstrasse 20,
                    <br />
                    70563 Stuttgart, Germany
                    <br />
                    Phone:+ 49 711 219508 0<br />
                    Email: cc-support@3ss.tv
                </Text>
                <Title>2. DATA PROTECTION OFFICER</Title>
                <Text>
                    If you have any questions, concerns or suggestions regarding data protection regarding the
                    <br />
                    CONTROL CENTER 3.0, you can contact our data protection officer at any time:
                </Text>
                <FlexContainer>
                    <Text>Email:</Text>
                    <LinkText href="mailto:dataprotection@3ss.tv">dataprotection@3ss.tv</LinkText>
                </FlexContainer>
                <Text>
                    You can also exercise your rights as a data subject by contacting the data protection officer. You
                    <br />
                    will find more detailed information on this in section 7
                </Text>
                <Title>3. COLLECTION AND PROCESSING OF PERSONAL DATA PROVIDED (PROCESSING PURPOSES AND LEGAL BASIS)</Title>
                <Text>
                    The use of certain functions and offers of our CONTROL CENTER 3.0, such as the login to your account, makes it necessary
                    to collect and process personal data, such as your name or your e-mail address. We only process such personal data if
                    you provide us with this information yourself, particularly in the context of a registration or if your company’s
                    administrator invites you to our CONTROL CENTER 3.0.
                </Text>
                <Title>3.1 LOGIN DATA</Title>
                <Text>
                    On our CONTROL CENTER 3.0 you can log in to our account with your login data (e-mail address and password). As we use a
                    two-factor authentification we also proess your telephone number. The legal basis for the processing of the login data
                    is <HighlightedText>Article 6 para. 1 lit. b) GDPR.</HighlightedText> The data processing is necessary to set up your
                    personal account and to provide you with the content of our CONTROL CENTER 3.0.
                </Text>
                <Title>3.2. OTHER DATA</Title>
                <Text>When using our CONTROL CENTER 3.0 we may also process the following data:</Text>
                <Text>
                    <li>Location (country only)</li>
                    <li>Duration of usage</li>
                    <li>of logins and preferred settings</li>
                </Text>
                <Text>
                    We cannot draw conclusions about a specific user from this data, but only use anonymised data for our evaluations.
                    <br />
                    We process this data on the basis of Art. 6 para. 1 lit. f) GDPR. We process this data to better understand the way our
                    products are used, to improve our products and services and to better understand our customers’ requirements. The data
                    processing is in our legitimate interest and we cannot identify any interests of our business users that conflict with
                    this.
                </Text>
                <Title>4. RECIPIENTS OF PERSONAL DATA</Title>
                <Text>
                    The personal data collected by us in the course of the use of our CONTROL CENTER 3.0 will basically be processed by us.
                    In operating our CONTROL CENTER 3.0, we nevertheless make partial use of external technical and other service providers
                    to support us in delivering our services. These are webhosting providers, provoders of analytic tools, member
                    administration providers, cloud providers.
                    <br />
                    Where necessary, these service providers we use will process your personal data within the scope of their respective
                    orders in accordance with our instructions for the purposes stated in this data protection notice. They are
                    contractually obliged to comply strictly with this data protection notice, the applicable statutory data protection
                    provisions and our instructions (so-called processors pursuant to Article 28 of the GDPR). The same applies to any
                    subcontractors, insofar as our processors use them with our prior consent.
                </Text>
                <Title>5. PROCESSING OF PERSONAL DATA IN ‘THIRD COUNTRIES’</Title>
                <Text>
                    The personal data collected by us are processed within the EU. If personal data is transferred to recipients outside the
                    European Economic Area (EEA), the transfer will only take place if the third country has been confirmed by the EU
                    Commission as having an adequate level of data protection or if other adequate data protection guarantees (e.g. EU
                    standard contractual clauses) are in place or if there are legal grounds for the transfer.
                </Text>
                <Title>6. PERIOD OF STORAGE</Title>
                <Text>
                    In general, we only store personal data for as long as this is necessary to fulfil the purpose of the respective data
                    processing or if you request us to delete the personal data for other justified reasons ((see Art. 17 para. 1 GDPR)).
                    After expiry of these storage periods or upon your justified request for deletion, we will check in each case whether
                    certain personal data are still required for (other) legitimate purposes and, if not, whether contractual or statutory
                    storage periods conflict with deletion of the personal data. In these cases, the personal data concerned will be
                    retained for as long as necessary to fulfil this (other) legitimate purpose or for the duration of the respective
                    contractual or statutory retention period for these purposes. For other purposes, however, they are blocked and then
                    permanently deleted after expiry of the respective last contractual or statutory retention period.
                    <br />
                    Your personal data we collected during your use of our CONTROL CENTER 3.0 is stored by us and will be deleted upon
                    request by you or your administrator and in any case 12 months after your last activity on the CONTROL CENTER 3.0.
                </Text>
                <Title>7. YOUR RIGHTS (RIGHTS OF DATA SUBJECT)</Title>
                <Text>
                    To the extent that legal conditions in accordance with Article 15 et seq. of the GDPR are given, you have the following
                    rights regarding the personal data stored by us (so-called rights of data subject):
                </Text>
                <Text>
                    <li>right of access (Art. 15 GDPR),</li>
                    <li>right to rectification (Art. 16 GDPR),</li>
                    <li>a right to erasure (Art. 17 GDPR),</li>
                    <li>a right to restriction of processing (for data to be made unavailable) (Art.18 GDPR),</li>
                    <li>right to data portability (Art. 20 GDPR).</li>
                </Text>
                <Text>
                    You also have the right to <BoldText>withdraw the consent </BoldText> you have given at any time.
                </Text>
                <Text>
                    In addition, have the <BoldText>right to object </BoldText> to data processing in the case of processing that lies in
                    the legitimate interests of the controller or a third party in accordance with point (f) Article 6(1) of the GDPR where
                    the legal conditions in accordance with Article 21 of the GDPR are given.
                </Text>
                <Text>To assert your rights as a data subject, you can contact us at any time at cc-support@3ss.tv.</Text>
                <Text>
                    Additionally, you have the right to lodge a complaint with a supervisory authority for data protection if you are of the
                    opinion that the processing of your personal data breaches data protection law
                    (https://www.bfdi.bund.de/DE/Service/Anschriften/anschriften_table.html).
                </Text>
                <FooterContainer>
                    <Title>Information about your right to object Art. 21 GDPR</Title>

                    <Text>
                        You have the right to object at any time to the processing of your data that is carried out on the basis of Art. 6
                        para. 1) lit. f) GDPR (data processing on the basis of a balance of interests) or Art. 6 para. 1) lit. e) GDPR (data
                        processing in the public interest), if there are grounds for doing so that arise from your particular situation.
                        This also applies to profiling based on this provision within the meaning of Art. 4 No. 4 GDPR.
                    </Text>

                    <Text>
                        If you object, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds
                        for the processing which override your interests, rights and freedoms, or the processing serves to assert, exercise
                        or defend legal claims.
                    </Text>

                    <Text>
                        We may also process your personal data for the purposes of direct marketing. If you do not wish to receive
                        advertising, you have the right to object to this at any time; this also applies to profiling, insofar as it is
                        associated with such direct advertising. We will observe this objection for the future.
                    </Text>

                    <Text>
                        We will no longer process your data for direct marketing purposes if you object to processing for these purposes.
                    </Text>

                    <Text> objection can be made form-free and should preferably be addressed to:</Text>

                    <Text>
                        3 Screen Solutions GmbH <br />
                        Hauptstrasse 20, <br />
                        70563 Stuttgart, Germany <br />
                        Phone:+ 49 711 219508 0 <br />
                        Email: cc-support@3ss.tv
                    </Text>
                </FooterContainer>

                <Title>8.CHANGES TO THE DATA PROTECTION NOTICE</Title>
                <Text>
                    We can adapt this data protection notice to changed actual or legal circumstances at any time and we will publish the
                    latest version of this data protection notice at this site. The date (&quot;Last updated&quot;) at the beginning of this
                    data protection notice indicates when this data protection notice.
                </Text>
                <GradientBottom />
            </ScrollableContainer>
        </GenericDialog>
    );
};

export default TermsOfServiceDialog;
