import { Tenant } from './Tenant';
import { Project } from './Project';
import { Page } from './Page';
import { Module } from './Module';
import { Item } from './Item';
import { Menu } from './Menu';
import { Audience } from './Audience';
import { Setting } from './Setting';
import { AppBranding } from './AppBranding';
import { PageStyle } from './PageStyle';
import { DynamicSource } from './DynamicSource';
import { DisplayCondition } from './DisplayCondition';
import { FeatureFlag } from './FeatureFlags';

export enum TargetGroupAbTestingStatus {
    INACTIVE,
    ACTIVE,
    PAUSED
}

export type TargetGroup = {
    _id: string;
    name: string;
    tenantId: string;
    projectId: string;
    isDefault?: boolean;
    tenant?: Tenant;
    project?: Project;
    active: boolean;
    audiences?: string[] | Audience[];
    pages?: string[] | Page[];
    modules?: string[] | Module[];
    items?: string[] | Item[];
    menus?: string[] | Menu[];
    pageStyles?: string[] | PageStyle[];
    settings?: string[] | Setting[];
    featureFlags?: string[] | FeatureFlag[];
    appBrandings?: string[] | AppBranding[];
    dynamicSources?: string[] | DynamicSource[];
    displayConditions?: string[] | DisplayCondition[];
    lastModified?: number;
    modifiedBy?: string;
    modifiedByUser?: {
        name: string;
        icon?: string;
    };
    abTestingStatus?: TargetGroupAbTestingStatus;
};
