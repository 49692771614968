import { useEffect, useState } from 'react';
import { ActiveItemState } from '../redux/slices/activeItemSlice';
import { useAppSelector } from './redux';
import { ProjectsState } from '../redux/slices/projectsSlice';
import { RestrictedSection } from '../types/Project';

const useRestrictedProjectAccess = (section: keyof RestrictedSection, key: string) => {
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { projects }: ProjectsState = useAppSelector((state) => state.projects);

    const [restrictedSections, setRestrictedSections] = useState<RestrictedSection>({});
    const [isRestrictedArea, setIsRestrictedArea] = useState<boolean>(false);

    useEffect(() => {
        if (!activeProjectId || !projects?.length) return;
        const project = projects.find((project) => project._id === activeProjectId);
        const restrictions = project?.restrictedSections;
        const selectedSection = restrictions?.[section] || [];

        setRestrictedSections(restrictions || {});
        setIsRestrictedArea(!!selectedSection.length && selectedSection.includes(key));
    }, [activeProjectId, projects]);

    return {
        restrictedSections,
        isRestrictedArea
    };
};

export default useRestrictedProjectAccess;
