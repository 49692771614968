import { ComponentType, HTMLAttributes, useEffect, useRef } from 'react';

type ScrollableWrapperProps = { shouldScroll: boolean } & HTMLAttributes<HTMLElement>;

export const withScroll = <T extends ScrollableWrapperProps>(WrappedComponent: ComponentType<T>) => {
    return function ScrollableWrapper(props: T) {
        const { shouldScroll, children, ...restProps } = props;
        const scrollableRef = useRef<any>(null);

        const isElementVisible = (elem: HTMLElement) => {
            if (!elem) return true;
            const { top, bottom, height } = elem.getBoundingClientRect();
            return top >= 0 && bottom + height <= window.innerHeight;
        };

        useEffect(() => {
            if (shouldScroll && !isElementVisible(scrollableRef.current)) {
                scrollableRef.current?.scrollIntoView({ behavior: 'smooth' });
            }
        }, [shouldScroll]);

        return (
            <WrappedComponent {...(restProps as T)} ref={scrollableRef}>
                {children}
            </WrappedComponent>
        );
    };
};
