import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';

export const fetchUserProfile = createAsyncThunk<{ userProfile: any; error: { message: string; code: string } | null }>(
    'permissions/fetchUserProfile',
    async (_, thunkApi) => {
        const result = await ConfigServiceAPI.getUserInfo();

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { userProfile: result.response as any, error: null };
    }
);

export interface UserProfileState {
    userProfile: any;
    loading: boolean;
    error: {
        message: string;
        code?: string;
        status?: number;
    } | null;
}

const initialState: UserProfileState = {
    userProfile: null,
    error: null,
    loading: false
};

const slice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        unsetProfileError(state) {
            state.error = null;
        },
        setUserProfile(state, action: PayloadAction<any>) {
            state.userProfile = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserProfile.fulfilled, (state, action: any) => {
                state.userProfile = action.payload.userProfile;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchUserProfile.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(fetchUserProfile.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetProfileError, setUserProfile } = slice.actions;
export default slice.reducer;
