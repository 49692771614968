import { FC } from 'react';
import { DialogTextField, DialogTextFieldProps } from '../Dialog/GenericDialog';

export const MultiLineTextField: FC<DialogTextFieldProps> = ({ rows, minRows, maxRows, ...rest }) => {
    return (
        <DialogTextField
            multiline
            minRows={minRows}
            maxRows={maxRows}
            rows={rows}
            {...rest}
            InputProps={{
                ...rest.InputProps,
                inputProps: {
                    style: {
                        maxWidth: 'calc(100% - 24px)',
                        maxHeight: '144px',
                        overflow: 'auto'
                    }
                }
            }}
        />
    );
};
