import { FC, useEffect, useState } from 'react';
import { DialogColorField } from '../../../common/Dialog/GenericDialog';
import { PreviewImageContainer, PreviewNameContainer, TemplatePreviewCard } from '../Groups/Groups.css';
import icons from '../../../../style';
import { IconsSelect } from '../../../common/Select/IconsSelect';
import SVGInline from 'react-inlinesvg';
import getTheme from '../../../../style/themes/theme';
import { EMPTY_WORD_STRING } from '../../../../utils/Globals';
import { PreviewSection, PreviewToggle } from '../../../PageEdit/VisualElements/DataPreviewDialog.css';

export type TemplatePreviewProps = {
    name: string;
    icon: string;
    iconBackground: string;
    setIcon: (arg: string) => void;
    setIconBackground: (arg: string) => void;
    iconError?: string;
    unsetIconError?: () => void;
};

export const TemplatePreview: FC<TemplatePreviewProps> = ({
    name,
    icon,
    iconBackground,
    iconError,
    setIcon,
    setIconBackground,
    unsetIconError
}) => {
    const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
    const theme: any = getTheme();

    return (
        <>
            <IconsSelect
                value={icon}
                onChange={(value) => {
                    setIcon(value);
                    unsetIconError?.();
                }}
                hideNoIconOption
                error={iconError}
                templates
            />
            <DialogColorField
                width={'128px'}
                height={'128px'}
                onColorPicked={(color) => setIconBackground(color)}
                value={iconBackground || theme.palette.primary.main}
                showColorValue
                label={'Background Color'}
            />
            <PreviewSection>
                <PreviewToggle onClick={() => setIsPreviewOpen(!isPreviewOpen)}>
                    Template Card Preview <SVGInline src={isPreviewOpen ? icons.arrowUpIcon : icons.arrowDownIcon} />
                </PreviewToggle>
                {isPreviewOpen && (
                    <TemplatePreviewCard>
                        <PreviewImageContainer $background={iconBackground || theme.palette.primary.main}>
                            {icon && <img src={icon} />}
                        </PreviewImageContainer>
                        <PreviewNameContainer>{name || EMPTY_WORD_STRING}</PreviewNameContainer>
                    </TemplatePreviewCard>
                )}
            </PreviewSection>
        </>
    );
};
