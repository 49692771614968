import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Page } from '../../types/Page';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { FailedDeletionMessages } from '../../types/Object';
import { ObjectFilter } from '../../utils/fnFilter';

export const fetchPages = createAsyncThunk<
    { pages: Page[]; error: { message: string; code: string } | null; permissions?: any },
    {
        addPermissions?: boolean;
        projectId?: string;
        level?: number;
        pageSize?: number;
        pageNumber?: number;
        orderBy?: string;
        searchTerm?: string;
        filter?: ObjectFilter;
    }
>('pages/fetchPages', async ({ addPermissions, projectId, level, pageSize, pageNumber, orderBy, searchTerm, filter }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllPages(
        addPermissions,
        projectId,
        typeof level === 'number' ? level : 1,
        pageSize,
        pageNumber,
        orderBy,
        searchTerm,
        filter
    );
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { pages: result.response as any, error: null, permissions: result.permissions, totalResults: result.totalResults };
});

export const fetchAllPages = createAsyncThunk<
    { pages: Page[]; error: { message: string; code: string } | null },
    {
        projectId?: string;
    }
>('pages/fetchAllPages', async ({ projectId }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllPages(undefined, projectId, 3);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { pages: result.response as any, error: null };
});

export const fetchPageIntents = createAsyncThunk<
    { intents: any[]; error: { message: string; code: string } | null },
    { projectId?: string }
>('pages/fetchPageIntents', async ({ projectId }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllPageIntents(projectId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { intents: result.response as any, error: null };
});

export const fetchPage = createAsyncThunk<
    { page: Page; error: { message: string; code: string } | null },
    { pageId: string; filter?: ObjectFilter }
>('pages/fetchPage', async ({ pageId, filter }, thunkApi) => {
    const result = await ConfigServiceAPI.getPageById(pageId, filter);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { page: result.response as any as Page, error: null };
});

export const createPage = createAsyncThunk<
    { id: string; shouldShowSlugWarning: boolean; shouldShowEmptySlugMessage: boolean; error: { message: string; code: string } | null },
    { page: Page; addObjectToGroup?: boolean }
>('pages/createPage', async ({ page, addObjectToGroup = false }, thunkApi) => {
    const result = await ConfigServiceAPI.createPage(page, addObjectToGroup);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return {
        id: (result.response as any)._id,
        shouldShowSlugWarning: (result.response as any).shouldShowSlugWarning,
        shouldShowEmptySlugMessage: (result.response as any).shouldShowEmptySlugMessage,
        error: null
    };
});

export const updatePage = createAsyncThunk<
    { ok: boolean; shouldShowSlugWarning: boolean; shouldShowEmptySlugMessage: boolean; error: { message: string; code: string } | null },
    { page: Page; shouldRepublish?: boolean; addObjectToGroup?: boolean }
>('pages/updatePage', async ({ page, shouldRepublish = false, addObjectToGroup = false }, thunkApi) => {
    const result = await ConfigServiceAPI.updatePage(page, shouldRepublish, addObjectToGroup);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return {
        ok: !!(result.response as any)._id,
        shouldShowSlugWarning: (result.response as any).shouldShowSlugWarning,
        shouldShowEmptySlugMessage: (result.response as any).shouldShowEmptySlugMessage,
        error: null
    };
});

export const deletePage = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null; failedDeletions?: FailedDeletionMessages },
    { id: string; withChildren?: boolean }
>('pages/deletePage', async ({ id, withChildren }, thunkApi) => {
    const result = await ConfigServiceAPI.deletePage(id, withChildren);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return {
        ok: !!result.response,
        error: null,
        failedDeletions: typeof result.response !== 'string' ? (result.response as unknown as FailedDeletionMessages) : undefined
    };
});

export const deletePageList = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null; failedDeletions: FailedDeletionMessages },
    { pages: string[]; withChildren?: boolean }
>('pages/deletePageList', async ({ pages, withChildren }, thunkApi) => {
    const result = await ConfigServiceAPI.deletePageList(pages, withChildren);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null, failedDeletions: result.response as unknown as FailedDeletionMessages };
});

export const publishPage = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { pageId: string; published?: number }
>('pages/publishPage', async ({ pageId }, thunkApi) => {
    const result = await ConfigServiceAPI.publishPage(pageId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const abortPublishPage = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { pageId: string; published?: number }
>('pages/abortPublishPage', async ({ pageId }, thunkApi) => {
    const result = await ConfigServiceAPI.abortPublishPage(pageId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export interface pagesState {
    pages: Page[];
    allPages: Page[]; // this is needed for when we need to load all the pages
    intents: any[];
    selectedPage?: Page;
    loading: boolean;
    loadingAllPages: boolean;
    intentsLoading: boolean;
    createPageLoading: boolean;
    selectedPageLoading: boolean;
    totalResults?: number;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: pagesState = {
    pages: [],
    allPages: [],
    intents: [],
    loading: false,
    loadingAllPages: false,
    intentsLoading: false,
    createPageLoading: false,
    selectedPageLoading: false,
    error: null
};

const slice = createSlice({
    name: 'pages',
    initialState,
    reducers: {
        unsetPageError(state) {
            state.error = null;
        },
        unsetSelectedPage(state) {
            state.selectedPage = undefined;
        },
        unsetPages(state) {
            state.pages = [];
        },
        unsetAllIntents(state) {
            state.intents = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPages.fulfilled, (state, action: any) => {
                state.pages = action.payload.pages;
                state.error = null;
                state.loading = false;
                state.totalResults = action.payload.totalResults;
            })
            .addCase(fetchPages.rejected, (state, action: any) => {
                state.pages = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchPages.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchAllPages.fulfilled, (state, action: any) => {
                state.allPages = action.payload.pages;
                state.error = null;
                state.loadingAllPages = false;
            })
            .addCase(fetchAllPages.rejected, (state, action: any) => {
                state.allPages = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loadingAllPages = false;
            })
            .addCase(fetchAllPages.pending, (state, _action) => {
                state.loadingAllPages = true;
            })
            .addCase(fetchPageIntents.fulfilled, (state, action: any) => {
                state.intents = action.payload.intents;
                state.error = null;
                state.intentsLoading = false;
            })
            .addCase(fetchPageIntents.pending, (state, action: any) => {
                state.intentsLoading = true;
            })
            .addCase(fetchPageIntents.rejected, (state, action: any) => {
                state.intents = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.intentsLoading = false;
            })
            .addCase(fetchPage.fulfilled, (state, action: any) => {
                state.selectedPage = action.payload.page;
                state.error = null;
                state.selectedPageLoading = false;
            })
            .addCase(fetchPage.rejected, (state, action: any) => {
                state.selectedPage = undefined;
                state.error = { ...action.payload.error, status: action.payload.status };
                state.selectedPageLoading = false;
            })
            .addCase(fetchPage.pending, (state, _action) => {
                state.selectedPageLoading = true;
            })
            .addCase(createPage.fulfilled, (state, _action) => {
                state.createPageLoading = false;
            })
            .addCase(createPage.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.createPageLoading = false;
            })
            .addCase(createPage.pending, (state, _action) => {
                state.createPageLoading = true;
            })
            .addCase(updatePage.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updatePage.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(updatePage.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deletePage.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deletePage.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deletePage.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(publishPage.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(publishPage.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(publishPage.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(abortPublishPage.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(abortPublishPage.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(abortPublishPage.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deletePageList.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deletePageList.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deletePageList.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetPageError, unsetSelectedPage, unsetPages, unsetAllIntents } = slice.actions;
export default slice.reducer;
