import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';

export const copyObject = createAsyncThunk<
    { id: string; error: { message: string; code: string } | null },
    { _id: string; objectType: string; projectId: string; newTemplateValues?: { moduleId: string; template: string }[] }
>('copy/copyObjects', async ({ _id, objectType, projectId, newTemplateValues }, thunkApi) => {
    const result = await ConfigServiceAPI.copyObject(_id, objectType, projectId, newTemplateValues);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { id: result.response as any, error: null };
});

export const copyObjectCheck = createAsyncThunk<
    { showDynamicItemWarning: boolean; showItemActionWarningAfterCopy: boolean; error: { message: string; code: string } | null },
    { _id: string; objectType: string; projectId: string }
>('copy/copyObjectsCheck', async ({ _id, objectType, projectId }, thunkApi) => {
    const result = await ConfigServiceAPI.copyObjectCheck(_id, objectType, projectId);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }

    return {
        showDynamicItemWarning: (result.response as any).showDynamicItemWarning,
        showItemActionWarningAfterCopy: (result.response as any).showItemActionWarningAfterCopy,
        error: null
    };
});

export interface copyObjectState {
    loading: boolean;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: copyObjectState = {
    loading: false,
    error: null
};

const slice = createSlice({
    name: 'copy',
    initialState,
    reducers: {
        unsetCopyObjectError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(copyObject.fulfilled, (state, _action) => {
                state.error = null;
                state.loading = false;
            })
            .addCase(copyObject.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(copyObject.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(copyObjectCheck.fulfilled, (state, _action) => {
                state.error = null;
                state.loading = false;
            })
            .addCase(copyObjectCheck.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(copyObjectCheck.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetCopyObjectError } = slice.actions;
export default slice.reducer;
