import React, { FC } from 'react';
import WidgetHeader from './WidgetHeader';
import { AnalyticsWidget } from '../Analytics.css';
import AnalyticsBarChart from '../Charts/AnalyticsBarChart';
import { TableAndBarChartWrapper } from '../Charts/ChartWrappers.css';
import AnalyticsTableChart from '../Charts/AnalyticsTableChart';
import { PlatformAnalyticsData } from '../../../redux/slices/analyticsSlice';

const BarChartWidget: FC<{
    barData: PlatformAnalyticsData;
    title: string;
    subtitle: string;
    comparedTimeframeLabel: string;
    totalDevices: number;
}> = ({ barData, title, subtitle, comparedTimeframeLabel, totalDevices }) => {
    return (
        <AnalyticsWidget>
            <WidgetHeader title={title} subtitle={subtitle} />
            <TableAndBarChartWrapper>
                <AnalyticsTableChart tableData={barData} comparedTimeframeLabel={comparedTimeframeLabel} totalDevices={totalDevices} />
                <AnalyticsBarChart data={barData} />
            </TableAndBarChartWrapper>
        </AnalyticsWidget>
    );
};

export default BarChartWidget;
