import React, { FC } from 'react';
import { AnalyticsWidget, VerticallyCenteredAnalyticsWidgetContent } from '../Analytics.css';
import WidgetHeader from './WidgetHeader';
import AnalyticsTableChart from '../Charts/AnalyticsTableChart';
import { PlatformAnalyticsData } from '../../../redux/slices/analyticsSlice';

export const TableChartWidget: FC<{
    tableData: PlatformAnalyticsData;
    title: string;
    subtitle: string;
    comparedTimeframeLabel: string;
    totalDevices: number;
}> = ({ tableData, comparedTimeframeLabel, title, subtitle, totalDevices }) => {
    return (
        <AnalyticsWidget>
            <WidgetHeader title={title} subtitle={subtitle} />
            <VerticallyCenteredAnalyticsWidgetContent>
                <AnalyticsTableChart tableData={tableData} comparedTimeframeLabel={comparedTimeframeLabel} totalDevices={totalDevices} />
            </VerticallyCenteredAnalyticsWidgetContent>
        </AnalyticsWidget>
    );
};

export default TableChartWidget;
