import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { devices } from '../../style/styled-components/reusable.css';

const theme: any = getTheme();

export const NoDataContainer = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${theme.palette.boxShadow.analytics};
    background-color: ${theme.palette.background.main};
    margin-top: 32px;
`;
export const AnalyticsFiltersContainer = styled.div`
    height: 104px;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: ${theme.palette.boxShadow.analytics};
    background-color: ${theme.palette.background.main};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    ${devices.tablet} {
        flex-direction: column;
        height: fit-content;
        gap: 8px;
    }
`;

export const AnalyticsFilterDropdownWrapper = styled.div`
    width: 100%;
`;

export const AnalyticsWidgetsLayout = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    width: 100%;
`;

export const AnalyticsWidget = styled.div<{ withoutGraph?: boolean }>`
    box-sizing: border-box;
    padding: 16px;
    min-width: 518px;
    flex: 1;
    height: ${(props) => (props.withoutGraph ? '92px' : '452px')};
    background-color: ${theme.palette.background.main};
    box-shadow: ${theme.palette.boxShadow.analytics};
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const VerticallyCenteredAnalyticsWidgetContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1 1;
    gap: 32px;
`;

export const WidgetHeaderLayout = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
`;
export const WidgetHeaderLabelSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
export const WidgetHeaderTitle = styled.div`
    ${fontSizesCSS.Caption2};
`;
export const WidgetHeaderSubtitle = styled.div`
    ${fontSizesCSS.Caption1};
    color: ${theme.palette.text.main};
`;
export const WindowHeaderLeftSpacer = styled.div`
    margin-left: auto;
`;
export const WidgetHeaderValue = styled.div`
    ${fontSizesCSS.Title1};
    flex: 1;
    text-align: end;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
        ${fontSizesCSS.Title4};
        font-weight: bold;
    }
`;
export const WidgetHeaderChangeIndicator = styled.div`
    margin-left: 12px;
    height: 60px;
    min-width: 41px;
    max-width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    svg {
        width: 16px;
        height: 16px;
    }
    background-color: ${theme.palette.background.light};
    color: ${theme.palette.grey.dark};
    ${fontSizesCSS.Body2};
`;

export const WidgetSettingsButton = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.palette.background.light};
    svg {
        width: 18px;
        height: 18px;
        path {
            stroke-width: 2;
        }
    }
    justify-self: flex-end;
    margin-left: 24px;
`;
export const PieDataGenericLegendsRow = styled.div`
    display: flex;
    align-items: center;
    width: 201px;
`;
export const PieDataGenericLegendsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px 32px;
    width: 100%;
    max-height: 104px;
    align-items: center;
    // the first selector is an "if" , so the flex-end is applied only if there are more than 4 elements, meaning it's 2 columns
    ${PieDataGenericLegendsRow}:nth-child(n + 5):not(:empty) > ${PieDataGenericLegendsRow}:nth-child(-n + 4) {
        align-self: flex-end;
    }
    ${PieDataGenericLegendsRow}:nth-child(n + 5) {
        align-self: flex-start;
    }
`;

export const PieDataGenericLegendsBubbleIndicator = styled.div<{ color: string; hovered?: boolean }>`
    background-color: ${(props) => props.color};
    margin-right: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: ${(props) => (props.hovered ? `0 0 0 2px ${theme.palette.grey.light}` : '')};
`;

export const PieDataGenericLegendsRowSpacer = styled.div`
    margin-left: auto;
`;
export const PieDataGenericLegendRowLabel = styled.div`
    ${fontSizesCSS.Caption2};
    color: ${theme.palette.grey.dark};
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
export const PieDataGenericLegendRowValue = styled.div`
    ${fontSizesCSS.Caption1};
    margin-left: 8px;
`;
export const PieDataGenericLegendRowChangeValue = styled.div`
    ${fontSizesCSS.Caption1};
    flex-basis: 38px;
    margin-left: 24px;
    margin-right: 4px;
`;
export const PieDataGenericLegendRowChangeIndicator = styled.div`
    svg {
        width: 14px;
        height: 14px;
    }
`;

export const PieDataDeviceCategoryLegendsWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 16px;
`;
export const PieDataDeviceCategoryColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const PieDataDeviceCategoryIconHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 32px;
    background-color: ${theme.palette.grey.lighter};
`;

export const PieDataDeviceCategoryColumnValue = styled.div`
    ${fontSizesCSS.Caption1};
`;
export const PieDataDeviceCategoryColumnChangeValue = styled.div`
    ${fontSizesCSS.Caption1};
    color: ${theme.palette.grey.light};
`;
export const PieDataDeviceCategoryChangeIndicatorContainer = styled.div`
    width: 44px;
    height: 20px;
    margin-top: -4px;
    display: flex;
    align-items: center;
    gap: 4px;
    svg {
        width: 14px;
        height: 14px;
    }
`;
