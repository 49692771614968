import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserGroup } from '../../types/UserGroup';
import configServiceAPI from '../../utils/api/configServiceAPI';

export const fetchGroups = createAsyncThunk<
    { groups: UserGroup[]; error: { message: string; code: string } | null; permissions?: any },
    { addPermissions?: boolean; orderBy?: string; searchTerm?: string }
>('groups/fetchGroups', async ({ addPermissions, orderBy, searchTerm }, thunkApi) => {
    const result = await configServiceAPI.getAllGroups(addPermissions, orderBy, searchTerm);
    if (!result.response || result.error) {
        return thunkApi.rejectWithValue(result);
    }
    return { groups: result.response as any, error: null, permissions: result.permissions };
});

export const createGroup = createAsyncThunk<{ id: string; error: any }, { group: UserGroup; userIds: string[] }>(
    'groups/createGroups',
    async ({ group, userIds }, thunkApi) => {
        const result = await configServiceAPI.createGroup(group, userIds);
        if (!result.response || result.error) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any, error: null };
    }
);

export const updateGroup = createAsyncThunk<{ ok: boolean; error: any }, { group: UserGroup; userIds: string[] }>(
    'groups/updateGroups',
    async ({ group, userIds }, thunkApi) => {
        const result = await configServiceAPI.updateGroup(group, userIds);
        if (!result.response || result.error) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response as any, error: null };
    }
);

export const deleteGroup = createAsyncThunk('groups/deleteGroups', async (id: string, thunkApi) => {
    const result = await configServiceAPI.deleteGroup(id);
    if (!result.response || result.error) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response as any, error: null };
});

export interface GroupsState {
    groups: UserGroup[];
    loading: boolean;
    error: {
        message: string;
        code?: string;
        status?: number;
    } | null;
}

const initialState: GroupsState = {
    groups: [],
    error: null,
    loading: false
};

const slice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        unsetGroupError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGroups.fulfilled, (state, action: any) => {
                state.groups = action.payload.groups;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchGroups.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.groups = [];
                state.loading = false;
            })
            .addCase(fetchGroups.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createGroup.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createGroup.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createGroup.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(updateGroup.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateGroup.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateGroup.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(deleteGroup.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteGroup.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteGroup.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            });
    }
});

export const { unsetGroupError } = slice.actions;
export default slice.reducer;
