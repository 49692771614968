import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import getTheme, { fontSizes, fontSizesCSS } from '../../../style/themes/theme';
import styled from 'styled-components';
import { TooltipDivider } from '../../Modules/Modules.css';

const theme: any = getTheme();

const Caption3Font = {
    ...fontSizes.Caption3
} as any;

export const TextTooltip = withStyles((theme: any) => ({
    tooltip: {
        pointerEvents: 'all',
        backgroundColor: theme.palette.black.main,
        color: theme.palette.white.main,
        maxWidth: '420px',
        ...Caption3Font
    }
}))(Tooltip);

export const TextTooltipWrapper = styled.span`
    width: fit-content;
    line-height: 0;
    display: block;
`;

export const HtmlTooltip = withStyles((theme: any) => ({
    tooltip: {
        pointerEvents: 'all',
        backgroundColor: theme.palette.black.main,
        color: theme.palette.white.main,
        maxWidth: '420px',
        ...Caption3Font
    }
}))(Tooltip);

export const HtmlTooltipWrapper = styled.span`
    width: fit-content;
    max-width: 100%;
    display: block;
`;

export const ObjectNameTooltipContentHolder = styled.div`
    ${fontSizesCSS.Caption3}
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 12px 10px;
    gap: 10px;
    ${TooltipDivider} {
        margin: 0;
    }
`;
export const ObjectNameTooltipIconHolder = styled.div`
    svg {
        height: 10px;
        width: 12px;
        path {
            fill: ${theme.palette.white.main};
        }
    }
`;
export const ObjectNameTooltipNameHolder = styled.div`
    max-width: 250px;
`;
export const ObjectNameTooltipLabelHolder = styled.div`
    font-weight: bold;
    &:hover {
        cursor: pointer;
    }
`;

export const TooltipWrapper = styled.div`
    align-items: center;
    display: flex;
    padding: 8px;
`;

export const TooltipValue = styled.div`
    overflow: hidden;
    margin-left: 8px;
    padding-right: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    border-right: 1px solid ${theme.palette.background.innerBorder};
`;

export const TooltipActionWrapper = styled.div`
    cursor: pointer;
    margin-left: 8px;
    font-weight: bold;
    white-space: nowrap;
`;
