import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch as useDispatch } from './redux';
import { LanguagesState, fetchLanguages } from '../redux/slices/languagesSlice';
import { ActiveItemState } from '../redux/slices/activeItemSlice';

// These variables are use to prevent multiple loading of the languages
let startedLoadingLanguages = false;
let projectIdForCurrentLanguages = '';

const useTranslation = () => {
    const dispatch = useDispatch();
    const { languages, allTranslations }: LanguagesState = useAppSelector((state) => state.languages);
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const [shouldDisplayTranslations, setShouldDisplayTranslations] = useState<boolean>(false);
    const [translationsLanguageCode, setTranslationsLanguageCode] = useState<string>('');

    const loadLanguages = async () => {
        if (activeProjectId !== projectIdForCurrentLanguages || (!languages.length && !startedLoadingLanguages)) {
            startedLoadingLanguages = true;
            projectIdForCurrentLanguages = activeProjectId || '';

            await dispatch(fetchLanguages({ projectId: activeProjectId || '' })).unwrap();
        }
    };

    useEffect(() => {
        const languageWithTranslations = languages?.find((lang) => !!lang.displayTranslations);
        setShouldDisplayTranslations(!!languageWithTranslations);
        setTranslationsLanguageCode(languageWithTranslations?.code || '');
    }, [languages]);

    useEffect(() => {
        if (!activeProjectId) return;
        loadLanguages();
    }, [activeProjectId]);

    const translate = (key: string) => {
        if (!allTranslations || !(key in allTranslations) || !shouldDisplayTranslations) return key;
        const values = allTranslations[key].values;
        return values[translationsLanguageCode] || key;
    };

    return { shouldDisplayTranslations, translate };
};

export default useTranslation;
