import React, { useEffect, useState } from 'react';
import {
    deleteTenant as removeTenant,
    fetchDisabledTenants,
    enableTenant as restoreTenant,
    TenantsState
} from '../../../redux/slices/tenantsSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { ACCEPTED_SORT_FIELDS, DEFAULT_SORT_CONFIG, ISortConfig } from '../../../utils/fnSort';
import { Tenant } from '../../../types/Tenant';
import useScreenSize from '../../../hooks/useScreenSize';
import { dialogConfirm } from '../../../utils/fnDialogs';
import { TenantAdminsContainer, TenantPreviewForDeletion } from '../../Tenants/Tenants.css';
import { ApplicationWrapper, MainContentWrapper, TruncatedText } from '../../../style/styled-components/reusable.css';
import icons from '../../../style';
import GenericTable, {
    ActionsTableCell,
    HeaderTableCell,
    ImageTableCell,
    SortableHeaderTableCell,
    tableActions
} from '../../common/Table/Table';
import { TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { UserAvatar, UserAvatarPlaceholder } from '../../Projects/Projects.css';
import { generateDateStringForTables } from '../../../utils/fnDate';
import { WidthTableCell } from '../../common/Table/Table.css';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import { SearchBar } from '../../common/SearchBar/SearchBar';
import { Loader } from '../../common/Loader/Loader';
import Sidebar from '../Sidebar/Sidebar';
import ScreenTitle from '../../common/DashboardTitle/ScreenTitle';
import { DisabledTenantsTableSizes } from '../../../types/TableSizes';
import Labels from '../../common/Labels/Labels';
import { MoreInfoDialog, MoreInfoTypes } from '../../common/Dialog/MoreInfoDialog';
import { IconContainer } from '../../UsersAndGroups/Groups/Groups.css';
import { SearchBarContainer } from '../../common/SearchBar/SearchBar.css';

const DisabledTenants = () => {
    const dispatch = useDispatch();
    const { isSmallMobile, isMobile, isDesktop } = useScreenSize();

    const { disabledTenants, error, loading }: TenantsState = useAppSelector((state) => state.tenants);

    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [showMoreDialog, setShowMoreDialog] = useState<{ type: MoreInfoTypes | null; show: boolean; data: any[] }>({
        type: null,
        show: false,
        data: []
    });

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [activeSortingKey, setActiveSortingKey] = useState<string | null>(ACCEPTED_SORT_FIELDS.lastModified);

    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;

    useEffect(() => {
        loadDisabledTenants(searchTerm);
    }, []);

    useEffect(() => {
        if (loading || error) return;
        if (disabledTenants) setTenants(disabledTenants);
    }, [disabledTenants, loading]);

    const handleOnSearch = (searchTerm: string) => {
        loadDisabledTenants(orderBy, searchTerm);
    };

    const loadDisabledTenants = async (orderBy?: string, searchTerm?: string) => {
        return await dispatch(fetchDisabledTenants({ orderBy, searchTerm })).unwrap();
    };

    const deleteTenant = async (id: string) => {
        await dispatch(removeTenant(id)).unwrap();
        loadDisabledTenants(orderBy, searchTerm);
    };
    const enableTenant = async (id: string) => {
        await dispatch(restoreTenant(id)).unwrap();
        loadDisabledTenants(orderBy, searchTerm);
    };

    const handleDeleteIconClick = (id: string) => {
        const tenant = tenants.find((tenant: Tenant) => tenant._id === id);
        const values = {
            title: 'Delete Tenant',
            text: (
                <>
                    <p>
                        You are in the process of PERMANENTLY deleting tenant with the name: <strong>{tenant?.name}</strong>. Are you sure
                        you want to permanently delete the selected tenant?
                    </p>
                    <p>Are you sure you want to delete?</p>
                </>
            )
        };
        dialogConfirm(
            '',
            () => {
                deleteTenant(id);
            },
            values,
            <TenantPreviewForDeletion>
                <img src={tenant?.logo || icons.tenantIcon1} alt="" />
                <p>{tenant?.name}</p>
            </TenantPreviewForDeletion>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Delete Permanently'
                }
            }
        );
    };

    const handleEnableIconClick = (id: string) => {
        const tenant = tenants.find((tenant: Tenant) => tenant._id === id);
        const values = {
            title: 'Enable Tenant',
            text: (
                <>
                    <p>
                        You are in the process of enable/restoring the tenant <strong>{tenant?.name}</strong>.
                    </p>
                    <p>Are you sure you want to continue?</p>
                </>
            )
        };
        dialogConfirm(
            '',
            () => {
                enableTenant(id);
            },
            values,
            <TenantPreviewForDeletion>
                <img src={tenant?.logo || icons.tenantIcon1} alt="" />
                <p>{tenant?.name}</p>
            </TenantPreviewForDeletion>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Enable'
                }
            }
        );
    };

    const buildTableColumns = () => {
        let columns = Object.entries({
            name: 'Tenant Name',
            placed: 'Projects',
            key: 'Key',
            tenant_admins: 'Tenant Admins',
            lastModified: 'Last Modified'
        }).map(([key, value], index) => {
            const isAcceptedSorField = ACCEPTED_SORT_FIELDS[key as keyof typeof ACCEPTED_SORT_FIELDS];

            return isAcceptedSorField ? (
                <SortableHeaderTableCell
                    key={index}
                    text={value}
                    onClick={() => handleSortIconClick(key)}
                    onMouseEnter={() => setShowSortArrows(true)}
                    onMouseLeave={() => setShowSortArrows(false)}
                    hideArrow={!showSortArrows && activeSortingKey !== key}
                    columnSize={DisabledTenantsTableSizes[key as keyof typeof DisabledTenantsTableSizes]}
                    direction={((sortConfig?.field === key || sortConfig?.field.split('.')[0] === key) && sortConfig?.direction) || 'asc'}
                />
            ) : (
                <HeaderTableCell
                    key={index}
                    text={value}
                    columnSize={DisabledTenantsTableSizes[key as keyof typeof DisabledTenantsTableSizes]}
                />
            );
        });

        !isSmallMobile && columns.unshift(<TableCell key={'first_cell'} />);
        isMobile && columns.splice(isSmallMobile ? 4 : 5, 1); // remove last modified column if is mobile view
        return columns;
    };

    const buildTenantAdmins = (tenantAdmins: any[]) => {
        const itemsToShow = isDesktop ? 3 : 1;
        return (
            <IconContainer onClick={() => setShowMoreDialog({ type: MoreInfoTypes.USERS, show: true, data: tenantAdmins })}>
                {tenantAdmins.map((admin, index) => {
                    if (index < itemsToShow) {
                        return (
                            <Tooltip key={index} title={admin.fullName} placement="right-start">
                                <UserAvatar background={admin.icon || icons.userIcon} />
                            </Tooltip>
                        );
                    }
                })}
                {tenantAdmins && tenantAdmins.length > itemsToShow && (
                    <UserAvatarPlaceholder>+{tenantAdmins.length - itemsToShow}</UserAvatarPlaceholder>
                )}
            </IconContainer>
        );
    };

    const buildTableBody = () => {
        const rows: JSX.Element[] = [];
        tenants?.forEach((tenant) => {
            const lastModified = generateDateStringForTables(tenant.lastModified || 0);
            rows.push(
                <TableRow key={tenant._id}>
                    {/* TENANTS ICON TABLE CELL */}
                    {!isSmallMobile && (
                        <ImageTableCell shape={'square'} imageSize={{ width: 48, height: 48 }} src={tenant.logo || icons.tenantIcon1} />
                    )}

                    {/* TENANTS NAME TABLE CELL */}
                    <WidthTableCell {...DisabledTenantsTableSizes.name}>
                        <TruncatedText>{tenant.name}</TruncatedText>
                    </WidthTableCell>

                    {/* CONNECTED TO */}
                    <WidthTableCell {...DisabledTenantsTableSizes.placed}>
                        <Labels
                            values={tenant.projects || []}
                            type={MoreInfoTypes.CONNECTED_TO}
                            noOfLabels={isDesktop ? 3 : isMobile ? 1 : 2}
                        />
                    </WidthTableCell>

                    {/* KEY TABLE CELL */}
                    <WidthTableCell {...DisabledTenantsTableSizes.key}>{tenant.key}</WidthTableCell>

                    {/* TENANT ADMINS TABLE CELL */}
                    <WidthTableCell {...DisabledTenantsTableSizes.tenant_admins}>
                        <TenantAdminsContainer>{buildTenantAdmins(tenant.tenantAdmins || [])}</TenantAdminsContainer>
                    </WidthTableCell>

                    {/* LAST MODIFIED TABLE CELL */}
                    {!isMobile && <WidthTableCell {...DisabledTenantsTableSizes.lastModified}>{lastModified}</WidthTableCell>}

                    {/* ACTIONS TABLE CELL */}
                    <WidthTableCell {...DisabledTenantsTableSizes.actions}>
                        <ActionsTableCell
                            tooltipTexts={{
                                delete: 'tenants_icon_delete',
                                enable: 'tenants_icon_enable'
                            }}
                            actions={[tableActions.ENABLE, tableActions.REMOVE]}
                            onRemove={() => handleDeleteIconClick(tenant._id)}
                            onEnable={() => handleEnableIconClick(tenant._id)}
                        />
                    </WidthTableCell>
                </TableRow>
            );
        });
        return <TableBody>{rows}</TableBody>;
    };

    const handleSortIconClick = (field: string) => {
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            direction,
            field
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadDisabledTenants(orderBy, searchTerm);
    };

    const renderNoDisabledProjects = () => <div>There are no disabled tenants</div>;
    const renderDisabledProjects = () => (
        <GenericTable body={buildTableBody()} columns={buildTableColumns()} sortableColumns={['name', 'key', 'lastModified']} />
    );

    const isEmpty = !tenants.length;

    return (
        <>
            {error && <BackendErrorDialog error={error} />}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle loading={loading} title="Disabled Tenants" withProfile withoutSearch />
                    <SearchBarContainer>
                        <SearchBar
                            title={'Search Name'}
                            disabled={loading}
                            searchTerm={searchTerm}
                            onSearch={handleOnSearch}
                            setSearchTerm={setSearchTerm}
                            tooltipText={'tenants_icon_search'}
                        />
                    </SearchBarContainer>
                    {loading ? <Loader title={'Disabled Tenants'} /> : isEmpty ? renderNoDisabledProjects() : renderDisabledProjects()}
                </MainContentWrapper>
            </ApplicationWrapper>
            <MoreInfoDialog
                type={showMoreDialog.type!}
                open={showMoreDialog.show}
                onClose={() => setShowMoreDialog({ type: null, show: false, data: [] })}
                data={showMoreDialog.data}
            />
        </>
    );
};

export default DisabledTenants;
