import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { generateKeyFromName } from '../../../utils/fnGenerator';
import GenericDialog, {
    DialogButton,
    DialogDropdownMultiple,
    DialogDropdownSingle,
    DialogFileField,
    DialogTextField,
    DialogTypes
} from '../../common/Dialog/GenericDialog';
import { Project } from '../../../types/Project';
import { PasscodeContainer, UserAvatar, UserOptionContainer, UserOptionName } from '../Projects.css';
import { fetchUsers, UsersState } from '../../../redux/slices/usersSlice';
import { fetchTenants, TenantsState } from '../../../redux/slices/tenantsSlice';
import { API_ERROR_CODES, getImgixUrl, USERROLES } from '../../../utils/Globals';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import { validator } from '../../../utils/fnValidator';
import icons from '../../../assets/images/icons';
import { FilesState, uploadFilesSync } from '../../../redux/slices/fileManagerSlice';
import { extractFileNameFromAzureURL } from '../../../utils/fnUrl';
import usePrevious from '../../../hooks/usePrevious';
import { InputAdornment } from '@material-ui/core';
import SVGInline from 'react-inlinesvg';
import { fetchSecretPasscode, ProjectsState } from '../../../redux/slices/projectsSlice';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';

export type NewProjectProps = {
    open: boolean;
    onSave: (value: any) => void;
    onClose: () => void;
    errorMsg: string;
    editIndex: number | null;
    project?: Project;
    projects: Project[];
    isProjectLead?: boolean;
};

const NewProjectDialog: React.FC<NewProjectProps> = ({ open, onSave, onClose, errorMsg, project, editIndex, projects, isProjectLead }) => {
    const { users, error, loading }: UsersState = useAppSelector((state) => state.users);
    const { secretPasscode: _secretPasscode, secretPasscodeLoading }: ProjectsState = useAppSelector((state) => state.projects);
    const { tenants, error: tenantsError, loading: tenantsLoading }: TenantsState = useAppSelector((state) => state.tenants);
    const { userProfile } = useAppSelector((state) => state.profile);
    const { config } = useAppSelector((state) => state.config);
    const [isOpen, setIsOpen] = useState(open);
    const [errors, setErrors] = useState<{ name?: string; key?: string; tenantId?: string }>({
        name: errorMsg
    });
    const { error: imageError, loading: imageLoading }: FilesState = useAppSelector((state) => state.files);
    const [projectName, setProjectName] = useState(project?.name || '');
    const [projectKey, setProjectKey] = useState(project?.key || '');
    const [projectLeadIds, setProjectLeadIds] = useState(project?.projectLeadIds || []);
    const [projectTenantId, setProjectTenantId] = useState(project?.tenantId || '');
    const [projectDescription, setProjectDescription] = useState('');
    const [logoFile, setLogoFile] = useState<File | null>(null);
    const [deleteLogo, setDeleteLogo] = useState(false);
    const [members, setMembers] = useState<string[]>([]);
    const [memberOptions, setMemberOptions] = useState<any[]>([]);
    const [secretPasscode, setSecretPasscode] = useState('');
    const [editingPasscode, setEditingPasscode] = useState(true);

    const title = project?.name || 'Create Project';
    const dispatch = useDispatch();

    const previousProjectLeads = usePrevious(projectLeadIds);

    useEffect(() => {
        const loadUsers = async () => {
            return await dispatch(fetchUsers({})).unwrap();
        };
        const loadTenants = async () => {
            await dispatch(fetchTenants({})).unwrap();
        };
        if (open) {
            project?._id && dispatch(fetchSecretPasscode(project._id));
            loadUsers().then((response) => {
                if (response.error?.code !== API_ERROR_CODES.AUTHENTICATION_ERROR) {
                    loadTenants();
                }
            });
        }
        setIsOpen(open);
        setDeleteLogo(false);
    }, [open]);

    useEffect(() => {
        setErrors({
            name: errorMsg
        });
    }, [errorMsg]);

    useEffect(() => {
        if (secretPasscodeLoading) return;
        if (_secretPasscode) {
            setSecretPasscode(_secretPasscode);
        }
        setEditingPasscode(!_secretPasscode);
    }, [secretPasscodeLoading]);

    // in case a user is tenant admin in only one project that tenant will  automatically be selected as the project tenant id
    useEffect(() => {
        tenants.length === 1 && setProjectTenantId(tenants[0]._id);
    }, [tenants]);

    useEffect(() => {
        if (!users?.length) return;
        const options = users
            ?.filter((user) => {
                // we filter out superAdmins and tenantAdmins and projectLeads from the options
                // and we make sure the user is a part of the projects tenant
                const tenant = tenants.find((t) => t._id === projectTenantId);
                return (
                    user._id !== userProfile?._id &&
                    user.tenantIds?.includes(projectTenantId) &&
                    user.role?.name !== USERROLES.SUPER_ADMIN &&
                    !project?.projectLeadIds?.includes(user._id) &&
                    !tenant?.tenantAdminIds.includes(user._id)
                );
            })
            ?.map((user) => {
                return {
                    label: (
                        <UserOptionContainer>
                            <UserAvatar background={user.icon || icons.userIcon} />
                            <UserOptionName>{user.fullName}</UserOptionName>
                        </UserOptionContainer>
                    ),
                    value: user._id,
                    valueForSearch: user.fullName
                };
            });

        const members = options
            ?.filter((option) => {
                if (!project) return false;
                const user = users.find((user) => user._id === option.value);
                return user?.projectIds?.includes(project._id);
            })
            ?.map((option) => option.value);

        setMemberOptions(options || []);
        setMembers(members || []);
    }, [users, tenants, projectTenantId]);

    useEffect(() => {
        if (project) {
            setProjectName(project.name);
            setProjectKey(project.key);
            setProjectLeadIds(project.projectLeadIds || []);
            setProjectTenantId(project.tenantId);
            setProjectDescription(project?.description || '');
        }
    }, [project]);

    useEffect(() => {
        const removedProjectLeads = previousProjectLeads?.filter((lead) => !projectLeadIds.includes(lead));
        if (removedProjectLeads?.length) {
            setMembers((members) => [...members, ...removedProjectLeads]);
        }
    }, [projectLeadIds]);

    useEffect(() => {
        if (project?.key) return;
        setProjectKey(projectName.length > 2 ? generateKeyFromName(projectName).toUpperCase() : projectKey);
    }, [projectName]);

    const validateNewProject = () => {
        const newErrors = { ...errors };
        const actualProjectKey = project?.key;
        const actualTenantId = project?.tenantId;
        const validKey =
            editIndex === null
                ? projects.filter((p) => p.tenantId === projectTenantId).some(({ key }) => key === projectKey)
                : projects
                      .filter((project) => project.key !== actualProjectKey && project.tenantId === actualTenantId)
                      .some(({ key }) => key === projectKey);

        newErrors.name = validator({ required: true, minLength: 4 }, projectName);
        newErrors.key = validKey ? 'Keys should be unique!' : validator({ required: true, minLength: 3 }, projectKey);
        newErrors.tenantId = tenants?.length > 1 ? validator({ required: true }, projectTenantId) : '';

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    const handleCloseClick = (evt: any) => {
        evt.preventDefault();
        if (onClose) {
            onClose();
            setErrors({});
            setProjectName('');
            setProjectKey('');
            setProjectLeadIds([]);
            setProjectTenantId('');
            setProjectDescription('');
            setMemberOptions([]);
            setMembers([]);
            setSecretPasscode('');
            setEditingPasscode(true);
        }
    };

    const handleSaveClick = async (evt: any) => {
        evt.preventDefault();
        if (!onSave) return;

        if (!validateNewProject()) return;

        let newProject: Project;

        newProject = {
            _id: project?._id || '',
            name: projectName,
            key: project ? project.key : projectKey,
            projectLeadIds: projectLeadIds,
            lastModified: Math.floor(new Date().getTime() / 1000),
            tenantId: projectTenantId,
            description: projectDescription || '',
            logo: encodeURIComponent(project?.logo || ''),
            secretPasscode
        };

        if (logoFile) {
            const urls = await createFile(logoFile);
            if (urls?.length) {
                const fileName = extractFileNameFromAzureURL(config.AZURE_CONFIG.account, config.AZURE_CONFIG.container, urls[0]);
                newProject.logo = encodeURIComponent(getImgixUrl(config.imgixBaseUrl, fileName));
            }
        } else if (deleteLogo) {
            newProject.logo = '';
        }

        // if a projectLead is editing the project, we need to make sure he is added in both arrays
        //  because he is filtered out from the options to not create confusion
        if (isProjectLead) {
            if (!newProject.projectLeadIds?.includes(userProfile?._id)) {
                newProject.projectLeadIds?.push(userProfile?._id);
            }
            if (!members.includes(userProfile?._id)) {
                members.push(userProfile?._id);
            }
        }

        // push all projectLeads into the members array so that they don't get unassigned
        members.push(...users.filter((u) => newProject.projectLeadIds?.includes(u._id) && !members.includes(u._id)).map((u) => u._id));

        onSave({ ...newProject, members });
        setErrors({});
        setProjectName('');
        setProjectKey('');
        setProjectLeadIds([]);
        setProjectDescription('');
        setSecretPasscode('');
    };

    const handleSelectChange = (value: any, members?: boolean) => {
        if (members) {
            setMembers(value ? value.map((v: any) => v.value) : []);
            return;
        }
        if (value === null) {
            setProjectLeadIds([]);
            setErrors(_.omit(errors, 'lead'));
            return;
        }
        if (Array.isArray(value)) {
            setProjectLeadIds([...value.map((v: any) => v.value)]);
            setErrors(_.omit(errors, 'lead'));
        } else {
            setProjectTenantId(value.value);
            setErrors(_.omit(errors, 'tenantId'));
        }
    };

    const createFile = async (file: File) => {
        try {
            const response = await dispatch(uploadFilesSync({ files: [file], prefix: 'project_logos', overwrite: true })).unwrap();
            return response.urls;
        } catch (ex) {
            return [];
        }
    };

    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleSaveClick,
        loading: imageLoading,
        disabled: imageLoading || loading || tenantsLoading || secretPasscodeLoading
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    const tenantsOptions = tenants?.map((tenant) => {
        return {
            label: tenant.name,
            value: tenant._id
        };
    });
    const tenantIdValue = tenantsOptions.find((option) => projectTenantId === option.value);
    const projectLeadsOptions = users
        ?.filter((user) => {
            // we filter out superAdmins and tenantAdmins from the options
            const tenantId = tenants?.length === 1 ? tenants[0]._id : projectTenantId;
            const currentlySelectedTenant = tenants.find((t) => t._id === tenantId);
            return (
                userProfile?._id !== user._id &&
                user.role?.name !== USERROLES.SUPER_ADMIN &&
                !currentlySelectedTenant?.tenantAdminIds.includes(user._id)
            );
        })
        ?.map((user) => {
            return {
                label: (
                    <UserOptionContainer>
                        <UserAvatar background={user.icon || icons.userIcon} />
                        <UserOptionName>{user.fullName}</UserOptionName>
                    </UserOptionContainer>
                ),
                value: user._id,
                valueForSearch: user.fullName
            };
        });
    const projectLeadsValue = projectLeadsOptions.filter((option) => projectLeadIds.includes(option.value));

    // this is outside the main return because we do not open the dialog if we have error
    // so isOpen will be false
    if (error || tenantsError) {
        return <BackendErrorDialog error={error || tenantsError} />;
    }
    const deleteTokenAdornment = (
        <InputAdornment position="end">
            <SVGInline
                src={icons.trashIcon}
                onClick={() => {
                    setEditingPasscode(true);
                    setSecretPasscode('');
                }}
            />
        </InputAdornment>
    );

    if (!isOpen) {
        return null;
    }
    return (
        <GenericDialog
            type={DialogTypes.Form}
            title={`${title}`}
            onClose={handleCloseClick}
            actionButtons={[cancelButton, saveButton]}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.projects }}
        >
            <DialogFileField
                fieldKey={'logo'}
                imageInfo={{ previewImage: true, height: 128, width: 128, um: 'px' }}
                localFileCallBack={(file: File) => {
                    setLogoFile(file);
                    setDeleteLogo(file === null);
                }}
                error={imageError?.message || undefined}
                preview={project?.logo}
                localFileOnly
                withoutPreview
                projectId={project?._id}
            />

            {/* this will only appear for super admins or users that are tenant admins in more than one tenant
             * they will need to select for which tenant the project is created
             */}
            {tenants?.length > 1 && (
                <DialogDropdownSingle
                    labelText={'Tenant'}
                    error={errors?.tenantId}
                    value={tenantIdValue}
                    options={tenantsOptions}
                    placeholder={tenantsLoading ? 'Loading Tenants...' : tenantsError ? `Couldn't load Tenants!` : 'Select Tenant'}
                    onChange={(value: any) => handleSelectChange(value)}
                    isDisabled={!!project || tenantsLoading}
                    withTopMargin
                />
            )}
            <DialogTextField
                value={projectName}
                error={errors?.name}
                label={'Project name'}
                placeholder={'Add project name'}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    const value = evt.target.value;
                    setProjectName(value);
                    setErrors(_.omit(errors, 'name'));
                    if (evt.target.value.length > 2) {
                        setErrors(_.omit(errors, 'key'));
                    }
                }}
                withTopMargin
            />
            <DialogTextField
                value={projectKey}
                label={'Key'}
                error={errors?.key}
                placeholder={'(auto-filled)'}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    if (evt.target.value.length > 3) return;
                    setProjectKey(evt.target.value.toUpperCase());
                }}
                isDisabled={!!project?.key}
                withTopMargin
            />
            <DialogTextField
                value={projectDescription}
                label={'Short Description'}
                placeholder={'Add Description'}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    const value = evt.target.value;
                    setProjectDescription(value);
                }}
                maxLength={50}
                optional={true}
                toolTipText={'Less than 50 characters'}
                withTopMargin
            />
            <DialogDropdownMultiple
                up
                labelText={'Project Lead(s)'}
                value={projectLeadsValue}
                options={projectLeadsOptions}
                placeholder={loading || tenantsLoading ? 'Loading Users...' : error ? `Couldn't load Users!` : 'Add Users'}
                allowSelectAll={true}
                onChange={(value: any) => handleSelectChange(value)}
                isDisabled={loading || tenantsLoading}
                withTopMargin
            />
            <DialogDropdownMultiple
                up
                labelText={'Members'}
                value={memberOptions.filter((opt) => members.includes(opt.value))}
                options={memberOptions}
                placeholder={loading || tenantsLoading ? 'Loading Users...' : error ? `Couldn't load Users!` : 'Add Users'}
                allowSelectAll={true}
                onChange={(value: any) => handleSelectChange(value, true)}
                isDisabled={loading || tenantsLoading}
                withTopMargin
            />
            <PasscodeContainer>
                <DialogTextField
                    optional={true}
                    value={secretPasscode}
                    label={'Secret Passcode'}
                    placeholder={'Add Passcode'}
                    isDisabled={!editingPasscode || secretPasscodeLoading}
                    InputProps={!editingPasscode ? { endAdornment: deleteTokenAdornment } : undefined}
                    toolTipText={"The password required to enter the client application's secret menu"}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        setSecretPasscode(evt.target.value);
                    }}
                    withTopMargin
                />
            </PasscodeContainer>
        </GenericDialog>
    );
};

export default NewProjectDialog;
