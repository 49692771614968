import React, { useCallback, useEffect, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import { ApplicationWrapper, MainContentWrapper, PageActionButton, PageActionsWrapper } from '../../style/styled-components/reusable.css';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import Sidebar from '../common/Sidebar/Sidebar';

import { Language } from '../../types/Language';

import { useDrop } from 'react-dnd';
import { Radio, TableBody, TableCell } from '@material-ui/core';
import GenericTable, {
    ActionsTableCell,
    DraggableTableRow,
    HeaderTableCell,
    ImageTableCell,
    SortableHeaderTableCell,
    tableActions
} from '../common/Table/Table';
import { API_ERROR_CODES, DRAGGABLE_TYPES } from '../../utils/Globals';
import { ACCEPTED_SORT_FIELDS, ISortConfig } from '../../utils/fnSort';
import { Link } from 'react-router-dom';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import {
    createLanguage,
    deleteLanguage,
    fetchLanguage,
    fetchLanguageCodes,
    fetchLanguages,
    LanguagesState,
    unsetLanguages,
    updateLanguage,
    updateLanguageList
} from '../../redux/slices/languagesSlice';
import { PermissionsState, setUserPermissions } from '../../redux/slices/permissionsSlice';
import {
    AddTranslationLabelWrapper,
    DefaultLanguageWrapper,
    LanguageFlagImg,
    LanguageName,
    LanguagePreviewForDeletion
} from './Languages.css';
import icons from '../../assets/images/icons';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { NewLanguageDialog } from './Dialogs/NewLanguage';
import { DIALOG_NAMES, dialogConfirm, ToastAlert } from '../../utils/fnDialogs';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import { generateDateStringForTables } from '../../utils/fnDate';
import PageActions from '../common/PageActions/PageActions';
import { WidthTableCell } from '../common/Table/Table.css';
import { Loader } from '../common/Loader/Loader';
import { renderTooltipWithKey } from '../common/Tooltips/Tooltips';
import useScreenSize from '../../hooks/useScreenSize';
import { SearchBar } from '../common/SearchBar/SearchBar';
import { buildPathWithProjectId, PageRoutes } from '../../types/RouteTypes';
import useLockSystem, { LockableObjectTypes } from '../../hooks/useLockSystem';
import { renderLockedError, renderLockedWarningAlert, renderLockIcon } from '../../utils/fnLockingSystem';
import { NewTranslation } from '../Translations/NewTranslationKeyDialog';
import { ButtonIcon } from '../Buttons/Button/Button.css';
import { LanguagesTableSizes } from '../../types/TableSizes';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';
import { SearchBarContainer } from '../common/SearchBar/SearchBar.css';

export const Languages: React.FC = () => {
    const dispatch = useDispatch();
    const { isSmallMobile } = useScreenSize();

    const {
        languages: storeLanguages,
        error: languagesError,
        loading: languagesLoading,
        loadingLanguage,
        languageCodes
    }: LanguagesState = useAppSelector((state) => state.languages);
    const { userPermissions }: PermissionsState = useAppSelector((state) => state.permissions);
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);

    const [languages, setLanguages] = useState<Language[]>(languagesError ? [] : storeLanguages);
    const [isOpenLanguageDialog, setIsOpenLanguageDialog] = useState<boolean>(false);
    const [languageToEdit, setLanguageToEdit] = useState<Language | null>(null);
    const [openNewTranslationKey, setOpeNewTranslationKey] = useState<boolean>(false);
    const [, drop] = useDrop(() => ({ accept: DRAGGABLE_TYPES.LANGUAGE }));

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [sortConfig, setSortConfig] = useState<ISortConfig | null>(null);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [activeSortingKey, setActiveSortingKey] = useState<string | null>(null);

    const orderBy = sortConfig ? `${sortConfig.field}[${sortConfig.direction}]` : '';

    // locking
    const { isObjectLocked, objectIsLockedBy, lock, unlockOnClose, unlock } = useLockSystem(LockableObjectTypes.LANGUAGES);

    const handleResetFilters = () => {
        setActiveSortingKey(null);
        setSortConfig(null);
        setSearchTerm('');
    };

    useEffect(() => {
        if (!activeProjectId) return;
        if (storeLanguages.length) {
            dispatch(unsetLanguages());
        }
        handleResetFilters();
        loadLanguages(true, activeProjectId).then((response: any) => {
            if (response.permissions) {
                dispatch(setUserPermissions(response.permissions));
            }
            loadLanguageCodes();
        });
    }, [activeProjectId]);

    useEffect(() => {
        if (languagesLoading || languagesError) return;
        if (storeLanguages) {
            setLanguages(storeLanguages);
        }
    }, [languagesLoading]);

    const lockLanguage = (id: string) => {
        lock(id);
        unlockOnClose(id);
    };

    const loadLanguages = async (addPermissions?: boolean, projectId?: string, orderBy?: string, searchTerm?: string) => {
        return await dispatch(fetchLanguages({ addPermissions, projectId, orderBy, searchTerm })).unwrap();
    };

    const loadLanguage = async (id: string) => {
        return await dispatch(fetchLanguage({ id, addTranslations: true })).unwrap();
    };

    const loadLanguageCodes = async () => {
        if (!languageCodes?.length) {
            await dispatch(fetchLanguageCodes()).unwrap();
        }
    };

    const saveLanguage = async (newLanguage: Language) => {
        const result = await dispatch(createLanguage(newLanguage)).unwrap();
        if (result.id) {
            loadLanguages(false, activeProjectId);
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.LANGUAGE_CREATED);
            handleResetFilters();
        }
    };
    const removeLanguage = async (id: string) => {
        await dispatch(deleteLanguage(id)).unwrap();
        loadLanguages(false, activeProjectId, orderBy, searchTerm);
    };

    const modifyLanguage = async (newLanguage: Language) => {
        await dispatch(updateLanguage({ language: newLanguage })).unwrap();
        loadLanguages(false, activeProjectId, orderBy, searchTerm);
    };

    const modifyLanguages = async (languages: any[]) => {
        await dispatch(updateLanguageList(languages)).unwrap();
        loadLanguages(false, activeProjectId, orderBy, searchTerm);
    };

    const handleOnSearch = (searchTerm: string) => {
        loadLanguages(false, activeProjectId, orderBy, searchTerm);
    };

    const handleDeleteIconClick = (id: string) => {
        const language = languages.find((l) => l._id === id);
        const flag = languageCodes.find((elem) => elem.code === language?.code)?.flag || '';
        const values = {
            title: 'Delete Language',
            text: (
                <p>
                    <strong>Are you sure you want to delete this language?</strong>
                </p>
            )
        };
        dialogConfirm(
            '',
            () => {
                removeLanguage(id);
            },
            values,
            <LanguagePreviewForDeletion>
                <LanguageFlagImg src={language?.icon || flag} />
                <p>{`${language?.name} (${language?.code})`}</p>
            </LanguagePreviewForDeletion>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Delete'
                }
            },
            undefined,
            undefined,
            true
        );
    };
    const onSaveLanguage = (newLanguage: Language) => {
        if (!languageToEdit) {
            saveLanguage(newLanguage);
        } else {
            modifyLanguage(newLanguage);
        }
        setLanguageToEdit(null);
        setIsOpenLanguageDialog(false);
    };
    const shouldSave = () => {
        const initialDefault = storeLanguages.find((l) => l.isDefault)?._id;
        const currentDefault = languages.find((l) => l.isDefault)?._id;
        const initialOrder = storeLanguages.map((l) => {
            return {
                _id: l._id,
                order: l.order
            };
        });
        const currentOrder = languages.map((l) => {
            return {
                _id: l._id,
                order: l.order
            };
        });
        const orderChanged = initialOrder.some((l) => currentOrder.find((lang) => lang._id === l._id)?.order !== l.order);
        return orderChanged || initialDefault !== currentDefault;
    };

    const findLanguage = useCallback(
        (id: string) => {
            const language = languages.find((c: Language) => `${c._id}` === id) || languages[0];
            return {
                language,
                index: languages.indexOf(language)
            };
        },
        [languages]
    );

    const moveLanguage = useCallback(
        (id: string, atIndex: number) => {
            const { language, index } = findLanguage(id);
            setLanguages((languages: Language[]) => {
                let newLanguages = [...languages];
                newLanguages.splice(index, 1);
                newLanguages.splice(atIndex, 0, language);
                newLanguages = newLanguages.map((lang, index) => {
                    return {
                        ...lang,
                        order: index + 1
                    };
                });
                return newLanguages;
            });
        },
        [findLanguage, languages, setLanguages]
    );

    const handleSortIconClick = (field: string) => {
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            direction,
            field
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadLanguages(false, activeProjectId, orderBy, searchTerm);
        setShowSortArrows(false);
    };

    const handleDownloadClick = async (id: string) => {
        if (loadingLanguage) return;

        let language: Language | null = null;
        try {
            language = (await loadLanguage(id)).language;
        } catch (ex) {}
        if (!language) return;

        const fileName = `${language.name}_${language._id}_translations`;
        const translations = JSON.stringify(language.translations);
        const blob = new Blob([translations], { type: 'application/json' });

        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + '.json';
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    };

    const buildTableColumns = () => {
        const columns = languages?.length
            ? Object.entries({
                  name: 'Language',
                  default: 'Default',
                  translationProgress: 'Translation Progress',
                  lastModified: 'Last Modified'
              }).map(([key, value], index) => {
                  const isAcceptedSorField = ACCEPTED_SORT_FIELDS[key as keyof typeof ACCEPTED_SORT_FIELDS];
                  return isAcceptedSorField ? (
                      <SortableHeaderTableCell
                          key={`${index}_cell`}
                          direction={(sortConfig?.field === key && sortConfig?.direction) || 'asc'}
                          text={value}
                          columnSize={LanguagesTableSizes[key as keyof typeof LanguagesTableSizes]}
                          hideArrow={!showSortArrows && activeSortingKey !== key}
                          onMouseEnter={() => setShowSortArrows(true)}
                          onMouseLeave={() => setShowSortArrows(false)}
                          onClick={() => handleSortIconClick(key)}
                      />
                  ) : (
                      <HeaderTableCell
                          text={value}
                          key={`${index}_cell`}
                          columnSize={LanguagesTableSizes[key as keyof typeof LanguagesTableSizes]}
                      />
                  );
              })
            : [];

        columns.unshift(<TableCell key={'first_cell_configurations'} />);
        return columns;
    };

    const buildTableBody = () => {
        const rows = languages?.map((lang, index) => {
            const dateString = generateDateStringForTables(lang.lastModified || 0);
            const flag = languageCodes?.find((elem) => elem.code === lang.code)?.flag || '';
            const locked = isObjectLocked(lang);
            const lockedBy = objectIsLockedBy(lang);

            return (
                <DraggableTableRow
                    key={lang._id}
                    id={`${lang._id}`}
                    findItem={findLanguage}
                    moveItem={moveLanguage}
                    type={DRAGGABLE_TYPES.LANGUAGE}
                    dataCy={`language-row-${index}`}
                >
                    {/* LANGUAGE NAME TABLE CELL */}
                    <WidthTableCell {...LanguagesTableSizes.name}>
                        <LanguageName>
                            <LanguageFlagImg src={flag} />
                            <Link
                                to={buildPathWithProjectId(
                                    activeProjectId,
                                    PageRoutes.LANGUAGE_TRANSLATIONS.replace(':language_id', lang._id)
                                )}
                            >
                                {`${lang.name} (${lang.code})`}
                            </Link>
                            {locked && renderLockIcon(lockedBy)}
                        </LanguageName>
                    </WidthTableCell>

                    {/* DEFAULT TABLE CELL */}
                    <WidthTableCell {...LanguagesTableSizes.default}>
                        <DefaultLanguageWrapper>
                            {renderTooltipWithKey(
                                <Radio
                                    color="primary"
                                    checked={lang.isDefault}
                                    onClick={() => {
                                        let newLanguages = languages.map((l) => {
                                            return {
                                                ...l,
                                                isDefault: l.isDefault ? false : l._id === lang._id
                                            };
                                        });
                                        setLanguages(newLanguages);
                                    }}
                                />,
                                'languages_icon_default'
                            )}
                        </DefaultLanguageWrapper>
                    </WidthTableCell>

                    {/* TRANSLATION PROGRESS TABLE CELL */}
                    <WidthTableCell {...LanguagesTableSizes.translationProgress}>{lang.translationProgress}%</WidthTableCell>

                    {/* LAST MODIFIED TABLE CELL */}
                    <WidthTableCell {...LanguagesTableSizes.lastModified}>{dateString}</WidthTableCell>

                    {/* MODIFIED BY TABLE CELL */}
                    {!isSmallMobile && (
                        <ImageTableCell
                            width={0}
                            um={'%'}
                            src={lang?.modifiedByUser?.icon || icons.avatarIcon}
                            shape="round"
                            toolTipName={lang?.modifiedByUser?.name}
                            imageSize={{ width: 32, height: 32 }}
                        />
                    )}

                    {/* ACTIONS TABLE CELL */}
                    <WidthTableCell {...LanguagesTableSizes.actions}>
                        <ActionsTableCell
                            actions={[tableActions.DOWNLOAD, tableActions.EDIT, tableActions.REMOVE]}
                            onEdit={() => {
                                if (locked) {
                                    return renderLockedWarningAlert(lockedBy);
                                }
                                setIsOpenLanguageDialog(true);
                                setLanguageToEdit(lang);
                                lockLanguage(lang._id);
                            }}
                            onDownload={() => handleDownloadClick(lang._id)}
                            onRemove={() => {
                                if (locked) return renderLockedWarningAlert(lockedBy);
                                handleDeleteIconClick(lang._id);
                            }}
                            tooltipTexts={{ edit: 'languages_icon_edit', delete: 'languages_icon_delete' }}
                        />
                    </WidthTableCell>
                </DraggableTableRow>
            );
        });
        return <TableBody ref={drop}>{rows}</TableBody>;
    };

    if (!languagesLoading && !userPermissions) {
        return <BackendErrorDialog error={{ status: 401 }} />;
    }

    const AddTranslationKeyLabel = (
        <AddTranslationLabelWrapper>
            <ButtonIcon>
                <SVGInline src={icons.globalTranslationsIcon} />
            </ButtonIcon>
            <span>Add Translation Key</span>
        </AddTranslationLabelWrapper>
    );

    return (
        <>
            {languagesError &&
                (languagesError.code === API_ERROR_CODES.LOCKED_ERROR ? (
                    renderLockedError(languagesError)
                ) : (
                    <BackendErrorDialog error={languagesError} />
                ))}
            <ApplicationWrapper>
                <Sidebar onProjectSelect={(projectId: string) => loadLanguages(false, projectId)} />
                <MainContentWrapper>
                    <ScreenTitle
                        title="Languages"
                        withProfile={true}
                        withAddButton={!!languages.length && !languagesLoading}
                        addLabel={'Add Language'}
                        withSecondaryButton={!!languages.length && !languagesLoading}
                        secondaryButtonLabel={AddTranslationKeyLabel}
                        onSecondarybuttonClick={() => {
                            setOpeNewTranslationKey(true);
                        }}
                        loading={languagesLoading}
                        onAdd={() => setIsOpenLanguageDialog(true)}
                        circlesSlugOptions={{ default: CIRCLE_SLUGS.languages, onboarding: ONBOARDING_CIRCLE_SLUGS.languages }}
                    />
                    <SearchBarContainer>
                        <SearchBar
                            title={'Search by Name'}
                            searchTerm={searchTerm}
                            onSearch={handleOnSearch}
                            disabled={languagesLoading}
                            setSearchTerm={setSearchTerm}
                            tooltipText={'languages_icon_search'}
                        />
                    </SearchBarContainer>
                    {languagesLoading ? (
                        <Loader title={'Languages'} />
                    ) : (
                        <>
                            <GenericTable body={buildTableBody()} columns={buildTableColumns()} dataCy={'languages-table'} />
                            <PageActionsWrapper>
                                <PageActionButton
                                    onClick={() => {
                                        setIsOpenLanguageDialog(true);
                                    }}
                                    label={'Add language'}
                                    type={'BLUE'}
                                />
                            </PageActionsWrapper>
                        </>
                    )}
                </MainContentWrapper>
                <PageActions
                    onSave={() => {
                        const languagesToUpdate = languages.map((lang) => {
                            return {
                                _id: lang._id,
                                order: lang.order,
                                isDefault: lang.isDefault
                            };
                        });
                        modifyLanguages(languagesToUpdate);
                    }}
                    onCancel={() => {
                        setLanguages(storeLanguages);
                    }}
                    disabled={{
                        save: !shouldSave()
                    }}
                />
                <NewLanguageDialog
                    open={isOpenLanguageDialog}
                    onSave={(language) => {
                        onSaveLanguage(language);
                    }}
                    onClose={() => {
                        !!languageToEdit && unlock(languageToEdit._id);
                        setIsOpenLanguageDialog(false);
                        setLanguageToEdit(null);
                    }}
                    language={languageToEdit}
                    order={languages.length + 1}
                />
                <NewTranslation
                    open={openNewTranslationKey}
                    onClose={() => setOpeNewTranslationKey(false)}
                    onChange={() => {
                        loadLanguages(false, activeProjectId);
                    }}
                />
            </ApplicationWrapper>
        </>
    );
};
