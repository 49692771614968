import styled from 'styled-components';
import { SelectWrapper } from '../common/Select/Select.css';
import getTheme from '../../style/themes/theme';

const theme: any = getTheme();

export const SearchBarWrapper = styled.div`
    flex: 1;

    ${SelectWrapper} {
        transform-origin: bottom left;

        [class$='placeholder'] {
            color: ${theme.palette.text.main};
            opacity: 0.5;
        }
        [class$='menu'] {
            position: absolute;
            top: 100%;
        }
        [class$='control'] {
            box-shadow: none;
            &:hover {
                border-color: ${theme.palette.background.border};
            }
        }
    }
`;

export const SelectPageSize = styled.div`
    width: 144px;
`;

export const PaginationContainer = styled.div<{ $extraPadding?: boolean }>`
    ${(props) => props.$extraPadding && 'padding-bottom: 72px'};
    justify-content: flex-end;
    margin-top: 24px;
    display: flex;
`;
