import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { ImageFieldContainer, ImageHeader, PageStyleSectionTitle, ImageFields } from '../StyleAndBranding/PageStyle/PageStyle.css';

const theme: any = getTheme();

export const AsssetManagerWrapper = styled.div``;

export const ThumbnailWrapper = styled.div<{ $image: string }>`
    width: 180px;
    height: 120px;
    border-radius: 4px;
    background-image: url(${(props) => `'${props.$image}'`});
    background-size: cover;
    background-position: center;
    background-color: ${theme.palette.text.main};
`;

export const AssetStatus = styled.div<{ $imported?: boolean }>`
    min-width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${(props) => (props.$imported ? theme.palette.success.semantic : theme.palette.warning.main)};
`;

export const AssetStatusWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const AssetManagerAssetFieldsContainer = styled(ImageFieldContainer)`
    padding: 0;
    background-color: transparent;
`;

export const AssetManagerSectionTitle = styled(PageStyleSectionTitle)``;
export const AssetManagerImageHeader = styled(ImageHeader)``;
export const AssetManagerImageFields = styled(ImageFields)`
    padding-bottom: 0;
    margin-bottom: 0;
}   
`;
export const AssetManagerFileField = styled.div<{ $hasValue?: boolean }>`
    width: 188px;
    height: 106px;
    border-radius: 3px;
    background: ${({ $hasValue }) => ($hasValue ? 'unset' : theme.palette.foreground.main)};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
        width: 32px;
        height: 32px;
    }
`;
export const AssetManagerFileLabel = styled.div<{ $error?: boolean }>`
    height: 16px;
    ${fontSizesCSS.Caption3};
    color: ${({ $error }) => ($error ? theme.palette.error.main : theme.palette.foreground.main)};
    opacity: ${({ $error }) => ($error ? 1 : 0.7)};
`;
export const AssetManagerFileContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
export const AssetsPaginationBottomContainer = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
`;

export const LastModifiedWrapper = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
`;

export const TagsWrapper = styled.div`
    width: fit-content;
    max-width: 100%;
`;
