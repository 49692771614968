import React, { FC, useEffect, useRef, useState } from 'react';
import { DialogTextField, DialogTextFieldProps } from '../Dialog/GenericDialog';
import { AutocompleteSelectWrapper } from './Select.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { InputAdornment } from '@material-ui/core';
import SVGInline from 'react-inlinesvg';
import icons from '../../../assets/images/icons';
import { VirtualizedList } from './VirtualizedList';

export const AutocompleteSelect: FC<DialogTextFieldProps & { options: any[]; withAutocomplete?: boolean }> = (props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const inputSelectRef = useRef<HTMLInputElement | null>(null);
    const handleChange = (value: any) => {
        setIsOpen(true);
        if (props.onChange) {
            props.onChange(typeof value === 'string' ? value : value?.value || '');
        }
    };

    useEffect(() => {
        isOpen && inputSelectRef.current?.focus();
    }, [isOpen]);

    const handleBlur = () => {
        setIsOpen(false);
    };
    const arrowAdornment = () => (
        <InputAdornment position="end">
            <SVGInline
                src={icons.arrowDownIcon}
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            />
        </InputAdornment>
    );

    return (
        <AutocompleteSelectWrapper onBlur={handleBlur}>
            <Autocomplete
                disablePortal
                getOptionLabel={(option) => {
                    // Value selected from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Normal option
                    return option.value;
                }}
                options={props.options}
                onChange={() => setIsOpen(false)}
                renderInput={(params) => {
                    const InputProps = { ...params.InputProps };
                    InputProps.endAdornment = arrowAdornment();
                    return (
                        <DialogTextField
                            {...props}
                            onChange={(e: any) => handleChange(e.target.value)}
                            InputProps={InputProps}
                            inputProps={{
                                ...params.inputProps,
                                onKeyDown: (e: any) => {
                                    if (e.key === 'Enter') {
                                        e?.stopPropagation();
                                    }
                                }
                            }}
                            inputRef={(input: any) => {
                                inputSelectRef.current = input;
                            }}
                        />
                    );
                }}
                value={props.value}
                filterOptions={(options) => {
                    // Filter based on the input value
                    const filtered = options.filter((option) => option.value.toLowerCase().includes(props.value.toLowerCase()));

                    // If the inputted key does not exist, show option to create one.
                    const isExisting = options.some((option) => props.value === option.value);
                    if (props.value !== '' && !isExisting) {
                        filtered.push({
                            label: `Add Translation Key For "${props.value}"`,
                            value: props.value
                        });
                    }

                    return filtered;
                }}
                renderOption={(option) => (typeof option === 'string' ? option : option.label || '')}
                onInputChange={(_, value) => handleChange(value)}
                freeSolo
                open={isOpen}
                ListboxComponent={VirtualizedList}
            />
        </AutocompleteSelectWrapper>
    );
};
