import React, { useEffect, useRef, useState } from 'react';
import { DragAndDropWrapper } from './DragAndDrop.css';
import { UMTypes } from '../../components/common/Dialog/GenericDialog';

type DragAndDropProps = {
    onDrop: any;
    accepted?: string[];
    imageInfo?: { previewImage: boolean; width: number; height: number; um: UMTypes };
    validationError?: boolean;
    errorCallback?: () => void;
};

const DragAndDrop: React.FC<DragAndDropProps> = ({ onDrop, accepted, children, imageInfo, validationError, errorCallback }) => {
    const [drag, setDrag] = useState(false);
    const [error, setError] = useState(false);
    const dragCounter = useRef<number>(0);

    const dropRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        let div: any = dropRef.current;
        if (div) {
            div.addEventListener('dragenter', handleDragIn);
            div.addEventListener('dragleave', handleDragOut);
            div.addEventListener('dragover', handleDrag);
            div.addEventListener('drop', handleDrop);
        }
        return () => {
            let div: any = dropRef.current;
            if (div) {
                div.removeEventListener('dragenter', handleDragIn);
                div.removeEventListener('dragleave', handleDragOut);
                div.removeEventListener('dragover', handleDrag);
                div.removeEventListener('drop', handleDrop);
            }
        };
    }, []);

    const handleDrag = (evt: any) => {
        evt.preventDefault();
        evt.stopPropagation();
    };

    const handleDragIn = (evt: any) => {
        evt.preventDefault();
        evt.stopPropagation();
        dragCounter.current++;
        if (evt.dataTransfer.items?.length) {
            setDrag(true);
        }
    };

    const handleDragOut = (evt: any) => {
        evt.preventDefault();
        evt.stopPropagation();
        dragCounter.current--;
        if (dragCounter.current === 0) {
            setDrag(false);
        }
    };

    const handleDrop = (evt: any) => {
        evt.preventDefault();
        evt.stopPropagation();
        setDrag(false);
        if (evt.dataTransfer.files?.length) {
            const files: File[] = Array.from(evt.dataTransfer.files);
            if (accepted) {
                let error = false;
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    const ext = file.name.split('.').pop();
                    error = !accepted.includes(`.${ext}`);
                    if (error) {
                        setError(true);
                        errorCallback?.();
                        return;
                    }
                }
                setError(false);
            }
            if (onDrop) {
                onDrop(files);
            }
            evt.dataTransfer.clearData();
            dragCounter.current = 0;
        }
    };

    return (
        <DragAndDropWrapper imageInfo={imageInfo} ref={dropRef} $error={error || validationError}>
            {children}
        </DragAndDropWrapper>
    );
};

export default DragAndDrop;
