import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppSelector, useAppDispatch as useDispatch } from '../../../hooks/redux';
import useScreenSize from '../../../hooks/useScreenSize';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { AB_TESTING_GROUP_LABEL, AUDIENCES_GROUP_LABEL, buildABTestingOptions, CONDITIONS_GROUP_LABEL } from '../../../utils/fnDialogs';
import BackendErrorDialog from '../Dialog/BackendErrorDialog';
import Select, { components } from 'react-select';
import { GroupColumnContainer, GroupHeadingText, GroupHeadingWithTooltip, OptionsColumnContainer, SelectWrapper } from './Select.css';
import icons from '../../../style';
import SVGInline from 'react-inlinesvg';
import { ShowMoreButton } from '../../../style/styled-components/reusable.css';
import { renderTooltipWithKey } from '../Tooltips/Tooltips';
import { CustomArrow } from './SelectSingle';
import { AbTestingGroupsState, fetchAbTestingGroups } from '../../../redux/slices/abTestingGroupSlice';
import { InputLabelWithIconWrapper } from '../Dialog/GenericDialog.css';
import { calculateElementsToShow } from '../../../utils/fnDynamicWidth';

export type ABTestingGroupSelectProps = {
    id?: string;
    selectedGroups: string[];
    selectedTargetConditions?: string[];
    onChange: (values: any[]) => void;
    inDialog?: boolean;
    withTopMargin?: boolean;
    isDisabled?: boolean;
};

export const ABTestingGroupSelect: React.FC<ABTestingGroupSelectProps> = ({
    id,
    selectedGroups,
    selectedTargetConditions,
    onChange,
    inDialog,
    withTopMargin,
    isDisabled
}) => {
    const dispatch = useDispatch();
    const calculationDone = useRef(false);

    const [maxToShow, setMaxToShow] = useState<number>(1);
    const [noOfHidden, setNoOfHidden] = useState<number>(0);
    const [abTestingGroups, setABTestingGroups] = useState<any[]>([]);

    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { abTestingGroups: storeGroups, loading, error } = useAppSelector<AbTestingGroupsState>((state) => state.abTestingGroups);

    const setElementsToShow = (value: any[]) => {
        const container = document.getElementById(`value-container-A/B Testing-${id}`);
        const containerWidth = container?.offsetWidth || 0;
        const elementsToShow = calculateElementsToShow(
            value.map((el: any) => el.label),
            containerWidth
        );
        setNoOfHidden(value.length - elementsToShow);
        setMaxToShow(elementsToShow);
    };

    // flatten the options and filter the selected ones
    const selectedValues: any = [];
    abTestingGroups
        .reduce((options, current) => {
            options.push(...current.options);
            return options;
        }, [])
        .forEach((opt: any) => {
            const index = selectedGroups.indexOf(opt.value);
            if (index !== -1) {
                selectedValues[index] = opt;
            }
        });

    useEffect(() => {
        if (selectedValues.length && !calculationDone.current) {
            setElementsToShow(selectedValues);
            calculationDone.current = true;
        }
    }, [selectedValues]);

    useEffect(() => {
        if (activeProjectId) loadAbTestingGroups();
    }, [activeProjectId]);

    useEffect(() => {
        setABTestingGroups(buildABTestingOptions(storeGroups, selectedTargetConditions, selectedGroups));
    }, [storeGroups, selectedTargetConditions, selectedGroups]);

    useEffect(() => {
        const targetGroups = storeGroups.map((elem) => elem.targetGroup);
        const targetGroupAudiences = targetGroups.reduce((acc, targetGroup) => {
            if (!targetGroup) return acc;
            acc[targetGroup._id] = targetGroup.audiences as string[];
            return acc;
        }, {} as Record<string, string[]>);
        const targetGroupsToExclude = Object.keys(targetGroupAudiences).filter(
            (elem) => !targetGroupAudiences[elem].some((audience) => selectedTargetConditions?.includes(audience))
        );
        const newSelectedGroups = selectedGroups.filter(
            (elem) => !targetGroupsToExclude.includes(storeGroups.find((group) => group._id === elem)?.targetGroupId!)
        );
        if (newSelectedGroups.length < selectedGroups.length) onChange(newSelectedGroups);
    }, [selectedTargetConditions]);

    const loadAbTestingGroups = async () => {
        await dispatch(fetchAbTestingGroups(activeProjectId)).unwrap();
    };

    // Used to prevent the jumping of the MenuList upon selecting something
    const _CustomMenuList = useCallback((props: any) => {
        return CustomMenuList(props, inDialog);
    }, []);

    const _GroupHeading = useCallback(
        (props) => {
            return GroupHeading(props);
        },
        [abTestingGroups]
    );

    const _Group = useCallback((props) => {
        return CustomGroup(props, inDialog);
    }, []);

    const _DropdownIndicator = useCallback((props) => {
        return CustomArrow(props, props.selectProps.menuIsOpen || false);
    }, []);

    const _CustomMenuMemo = useCallback((props) => CustomMenu(props), []);
    const _CustomValueContainer = useCallback((props: any) => CustomValueContainer(props, id), []);
    const _CustomMultiValue = useCallback((props: any) => CustomMultiValue(props, maxToShow, noOfHidden), [maxToShow, noOfHidden]);

    const selectDisabled = !abTestingGroups?.length || isDisabled;
    const selectDisabledTooltip = !abTestingGroups?.length
        ? 'ab_testing_dropdown_is_disabled'
        : 'ab_testing_dropdown_parent_conditions_selected';

    return (
        <>
            {error && <BackendErrorDialog error={error} />}
            <SelectWrapper
                id={`select_wrapper_A/B Testing`}
                $error={false}
                className={'multiple'}
                inDialog={inDialog}
                $withTopMargin={withTopMargin}
            >
                <label>
                    {selectDisabled ? (
                        <InputLabelWithIconWrapper>
                            A/B Testing
                            {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, selectDisabledTooltip)}
                        </InputLabelWithIconWrapper>
                    ) : (
                        'A/B Testing'
                    )}
                </label>
                <Select
                    isMulti
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    isDisabled={loading || selectDisabled}
                    options={abTestingGroups}
                    value={selectedValues}
                    isClearable={false}
                    classNamePrefix={'conditions-select'}
                    placeholder={'A/B Testing'}
                    onChange={(val: any) => {
                        setElementsToShow(val);
                        let newValue = val.map((elem: any) => elem.value);
                        onChange(newValue);
                    }}
                    filterOption={({ label, data }, inputValue) => {
                        const value = inputValue.toLowerCase().trim();
                        if (data?.value === AB_TESTING_GROUP_LABEL) return true;
                        if (!data?.valueForSearch) {
                            return typeof label === 'string' && label.toLowerCase().includes(value);
                        }
                        return data?.valueForSearch.toLowerCase().includes(value);
                    }}
                    components={{
                        MenuList: _CustomMenuList,
                        Option: CustomOption,
                        MultiValue: _CustomMultiValue,
                        ValueContainer: _CustomValueContainer,
                        MultiValueLabel: CustomMultiValueLabel,
                        MultiValueRemove: CustomMultiValueRemove,
                        Menu: _CustomMenuMemo,
                        GroupHeading: _GroupHeading,
                        Group: _Group,
                        DropdownIndicator: _DropdownIndicator,
                        IndicatorSeparator: null
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }}
                />
                {/*for correct height calculation, no error is needed for target conditions*/}
                <label className="error" />
            </SelectWrapper>
        </>
    );
};

const CustomValueContainer = (props: any, id?: string) => {
    return (
        <components.ValueContainer
            {...props}
            innerProps={{
                className: 'custom-value-container',
                id: `value-container-A/B Testing-${id}`
            }}
        >
            {props.children}
        </components.ValueContainer>
    );
};

const CustomMenu = (props: any) => {
    return <components.Menu {...props}>{props.children}</components.Menu>;
};

const CustomMenuList = (props: any, inDialog?: boolean) => {
    const { isDesktop } = useScreenSize();
    return (
        <components.MenuList {...props}>
            <GroupColumnContainer numOfColumns={isDesktop && !inDialog ? 3 : 2}>{props.children}</GroupColumnContainer>
        </components.MenuList>
    );
};

const CustomGroup = (props: any, inDialog?: boolean) => {
    const fullWidth = props.data.label === CONDITIONS_GROUP_LABEL || props.data.label === AUDIENCES_GROUP_LABEL;
    const { isDesktop } = useScreenSize();
    return (
        <components.Group {...props} className={fullWidth ? 'full-width' : ''}>
            {fullWidth ? (
                <OptionsColumnContainer numOfColumns={isDesktop && !inDialog ? 3 : 2}>{props.children}</OptionsColumnContainer>
            ) : (
                props.children
            )}
        </components.Group>
    );
};
const GroupHeading = (props: any) => {
    const options: any[] = props?.data?.options || [];
    const showABTestingInactiveTooltip = options.length && options.every((elem) => elem.isDisabled && elem.ABTestingInactive);
    const showAudiencesTooltip = options.length && options.every((elem) => elem.isDisabled);
    const showAllSelectedTooltip = options.filter((elem) => elem.isDisabled).length === 1;
    const withTooltip = showABTestingInactiveTooltip || showAudiencesTooltip || showAllSelectedTooltip;
    return (
        <components.GroupHeading {...props}>
            {withTooltip ? (
                <GroupHeadingWithTooltip>
                    <GroupHeadingText>{props.children}</GroupHeadingText>
                    {renderTooltipWithKey(
                        <SVGInline src={icons.infoIcon} />,
                        showABTestingInactiveTooltip
                            ? 'ab_testing_dropdown_ab_testing_inactive_for_target_group'
                            : showAudiencesTooltip
                            ? 'ab_testing_dropdown_no_audience_selected_for_target_group'
                            : 'ab_testing_dropdown_all_selected_for_target_Group'
                    )}
                </GroupHeadingWithTooltip>
            ) : (
                <GroupHeadingText>{props.children}</GroupHeadingText>
            )}
        </components.GroupHeading>
    );
};

const CustomOption = (props: any) => {
    return (
        <components.Option {...props} onClick={(e: any) => e.stopPropagation()}>
            {props.label}
            {props.isSelected ? <SVGInline src={icons.selectCheckIcon} /> : ''}
        </components.Option>
    );
};

const CustomMultiValue = ({ index, ...props }: any, maxToShow: number, noOfHidden: number) => {
    return index < maxToShow ? (
        <components.MultiValue {...props}>{props.children}</components.MultiValue>
    ) : index === maxToShow ? (
        <ShowMoreButton>+{noOfHidden}</ShowMoreButton>
    ) : null;
};

const CustomMultiValueLabel = (props: any) => {
    return (
        <components.MultiValueLabel {...props} innerProps={{ className: 'custom-value-label' }}>
            {props.children}
        </components.MultiValueLabel>
    );
};

const CustomMultiValueRemove = (props: any) => {
    return (
        <components.MultiValueRemove {...props}>
            <SVGInline src={icons.closeIcon} />
        </components.MultiValueRemove>
    );
};
