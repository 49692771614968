import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { ConfigurableKeys } from '../../components/TargetConditions/DisplayConditions/ConfigurableSelect';

export interface ConfigurableValuesState {
    configurableValues: { [key in ConfigurableKeys]?: { values: string[] | number[]; labels?: Record<string, string> } };
    loading: boolean;
    error: {
        message: string;
        code?: string;
        status?: number;
    } | null;
}

const initialState: ConfigurableValuesState = {
    configurableValues: {},
    error: null,
    loading: false
};

export const fetchValues = createAsyncThunk<
    { data: any; error: { message: string; code: string } | null },
    { type: ConfigurableKeys; projectId: string }
>('configurableValues/fetchValues', async ({ type, projectId }, thunkApi) => {
    const result = await ConfigServiceAPI.getValues(type, projectId);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { data: result.response as any, error: null };
});

export const createValue = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { type: string; value: string | number; projectId: string; label?: string }
>('configurableValues/createValue', async ({ type, value, projectId, label }, thunkApi) => {
    const result = await ConfigServiceAPI.createValue(type, value, projectId, label);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const updateValue = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { type: string; oldValue: string | number; value: string | number; projectId: string; label?: string }
>('configurableValues/updateValue', async ({ type, value, oldValue, projectId, label }, thunkApi) => {
    const result = await ConfigServiceAPI.updateValue(type, oldValue, value, projectId, label);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const deleteValue = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { type: string; value: string | number; projectId: string }
>('configurableValues/deleteValue', async ({ type, value, projectId }, thunkApi) => {
    const result = await ConfigServiceAPI.deleteValue(type, value, projectId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

const slice = createSlice({
    name: 'configurableValues',
    initialState,
    reducers: {
        unsetConfigurableValuesError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchValues.fulfilled, (state, action) => {
                const { values = [], labels = {} } = action.payload.data;
                state.configurableValues = {
                    ...state.configurableValues,
                    [action.meta.arg.type]: { values, labels }
                };
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchValues.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.configurableValues = { ...state.configurableValues, [action.meta.arg.type]: {} };
                state.loading = false;
            })
            .addCase(fetchValues.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createValue.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createValue.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createValue.rejected, (state, action: any) => {
                state.loading = false;
                state.error = { ...action.payload.error, status: action.payload.status };
            })
            .addCase(updateValue.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateValue.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateValue.rejected, (state, action: any) => {
                state.loading = false;
                state.error = { ...action.payload.error, status: action.payload.status };
            })
            .addCase(deleteValue.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteValue.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteValue.rejected, (state, action: any) => {
                state.loading = false;
                state.error = { ...action.payload.error, status: action.payload.status };
            });
    }
});

export const { unsetConfigurableValuesError } = slice.actions;

export default slice.reducer;
