import React, { FC, useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../../common/Dialog/GenericDialog';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { useAppSelector, useAppDispatch as useDispatch } from '../../../hooks/redux';
import { TargetConditionsSelect } from '../../common/Select/TargetConditionsSelect';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';
import _ from 'lodash';
import { ABTestingGroupSelect } from '../../common/Select/ABTestingGroupSelect';
import { DEFAULT_FLAGS, FeatureFlag } from '../../../types/FeatureFlags';

export type NewFeatureFlagsDialog = {
    open: boolean;
    onClose: () => void;
    onSave: (arg: FeatureFlag) => void;
};

export const NewFeatureFlagsDialog: FC<NewFeatureFlagsDialog> = ({ open, onClose, onSave }) => {
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [conditionIds, setConditionIds] = useState<string[]>([]);
    const [abTestingGroupIds, setAbTestingGroupIds] = useState<string[]>([]);

    const handleCloseClick = () => {
        setName('');
        setConditionIds([]);
        setAbTestingGroupIds([]);
        onClose();
    };

    const handleSaveClick = () => {
        const newFeatureFlag: FeatureFlag = {
            _id: '',
            name: name,
            tenantId: activeTenantId || '',
            projectId: activeProjectId || '',
            conditionIds: conditionIds,
            abTestingGroupIds: abTestingGroupIds,
            flags: DEFAULT_FLAGS
        };

        onSave(newFeatureFlag);
        handleCloseClick();
    };

    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleSaveClick
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    if (!isOpen) return null;

    return (
        <GenericDialog
            type={DialogTypes.Form}
            title={'Create Feature Flag'}
            onClose={handleCloseClick}
            actionButtons={[cancelButton, saveButton]}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.feature_flags }}
        >
            <DialogTextField
                label={'Feature Flag Name'}
                value={name}
                onChange={(evt: any) => {
                    setName(evt.target.value);
                }}
                placeholder={'Feature Flag Name'}
            />

            <TargetConditionsSelect
                targets={conditionIds}
                onChange={(val) => {
                    setConditionIds(val);
                }}
                inDialog
                withTopMargin
                hideCreateNew
            />
            <ABTestingGroupSelect
                inDialog
                withTopMargin
                selectedGroups={abTestingGroupIds}
                onChange={(newValues) => setAbTestingGroupIds(newValues)}
                selectedTargetConditions={conditionIds}
            />
        </GenericDialog>
    );
};
