import { ResponsiveBar } from '@nivo/bar';
import { chartColorPalette, labelData } from './ChartUtils';
import { BarChartWrapper } from './ChartWrappers.css';
import getTheme from '../../../style/themes/theme';
import { FC } from 'react';
import { PlatformAnalyticsData } from '../../../redux/slices/analyticsSlice';

const theme: any = getTheme();

const AnalyticsBarChart: FC<{ data: PlatformAnalyticsData }> = ({ data }) => {
    const colorPalette = chartColorPalette.slice(0, data.length).reverse();
    return (
        <BarChartWrapper height={data.length * 30}>
            <ResponsiveBar
                data={labelData([...data].reverse()) as any}
                tooltipLabel={(data) => data.indexValue.toString()}
                colors={colorPalette}
                padding={0.3}
                innerPadding={12}
                colorBy={'indexValue'}
                axisTop={null}
                axisRight={null}
                axisLeft={null}
                axisBottom={null}
                layout={'horizontal'}
                enableGridX={false}
                enableGridY={false}
                enableLabel={false}
                onMouseEnter={(_data, event) => {
                    if (event.target instanceof SVGRectElement) {
                        event.target.style.filter = theme.palette.boxShadow.pieChartArc;
                        event.target.style.strokeWidth = '2';
                        event.target.style.stroke = theme.palette.white.main;
                    }
                }}
                onMouseLeave={(_data, event) => {
                    if (event.target instanceof SVGRectElement) {
                        event.target.style.filter = 'unset';
                        event.target.style.strokeWidth = '0';
                        event.target.style.stroke = 'inherit';
                    }
                }}
                isInteractive
            />
        </BarChartWrapper>
    );
};

export default AnalyticsBarChart;
