import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AbTestingGroup } from '../../types/AbTestingGroup';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';

export const fetchAbTestingGroups = createAsyncThunk<
    { abTestingGroups: AbTestingGroup[]; error: { message: string; code: string } | null },
    string | undefined
>('abTestingGroups/fetchAbTestingGroups', async (projectId, thunkApi) => {
    const result = await ConfigServiceAPI.getAbTestingGroups(projectId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { abTestingGroups: result.response as any, error: null, permissions: result.permissions };
});

export const fetchAbTestingGroupsByTargetGroupId = createAsyncThunk<
    { abTestingGroups: AbTestingGroup[]; error: { message: string; code: string } | null },
    { targetGroupId: string; createDefault?: boolean }
>('abTestingGroups/fetchAbTestingGroupsByTargetGroupId', async ({ targetGroupId, createDefault }, thunkApi) => {
    const result = await ConfigServiceAPI.getAbTestingGroupsByTargetGroupId(targetGroupId, createDefault);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { abTestingGroups: result.response as any, error: null, permissions: result.permissions };
});

export const createAbTestingGroupsForTargetGroup = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { targetGroupId: string; abTestingGroups: { name: string; percentage: number; _id?: string }[] }
>('abTestingGroups/createAbTestingGroupsForTargetGroup', async ({ targetGroupId, abTestingGroups }, thunkApi) => {
    const result = await ConfigServiceAPI.createAbTestingGroupsForTargetGroup(targetGroupId, abTestingGroups);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const createAbTestingGroup = createAsyncThunk<{ id: string; error: { message: string; code: string } | null }, AbTestingGroup>(
    'abTestingGroups/createAbTestingGroup',
    async (abTestingGroup, thunkApi) => {
        const result = await ConfigServiceAPI.createAbTestingGroup(abTestingGroup);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any as string, error: null };
    }
);

export const updateAbTestingGroup = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, any>(
    'abTestingGroups/updateAbTestingGroup',
    async (values, thunkApi) => {
        const result = await ConfigServiceAPI.updateAbTestingGroup(values);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export const deleteAbTestingGroup = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'abTestingGroups/deleteAbTestingGroup',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.deleteAbTestingGroup(id);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export interface AbTestingGroupsState {
    abTestingGroups: AbTestingGroup[];
    loading: boolean;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: AbTestingGroupsState = {
    abTestingGroups: [],
    loading: false,
    error: null
};

const slice = createSlice({
    name: 'audiences',
    initialState,
    reducers: {
        unsetAbTestingGroupError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAbTestingGroups.fulfilled, (state, action: any) => {
                state.abTestingGroups = action.payload.abTestingGroups;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchAbTestingGroups.rejected, (state, action: any) => {
                state.abTestingGroups = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchAbTestingGroups.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchAbTestingGroupsByTargetGroupId.fulfilled, (state, action: any) => {
                state.abTestingGroups = action.payload.abTestingGroups;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchAbTestingGroupsByTargetGroupId.rejected, (state, action: any) => {
                state.abTestingGroups = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchAbTestingGroupsByTargetGroupId.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createAbTestingGroupsForTargetGroup.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createAbTestingGroupsForTargetGroup.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(createAbTestingGroupsForTargetGroup.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createAbTestingGroup.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createAbTestingGroup.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(createAbTestingGroup.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateAbTestingGroup.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateAbTestingGroup.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(updateAbTestingGroup.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteAbTestingGroup.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteAbTestingGroup.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteAbTestingGroup.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetAbTestingGroupError } = slice.actions;

export default slice.reducer;
