import React, { useEffect, useState } from 'react';
import Sidebar from '../common/Sidebar/Sidebar';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import { ApplicationWrapper, MainContentWrapper, TruncatedText } from '../../style/styled-components/reusable.css';
import ToggleViewSwitch from '../common/Switch/ToggleViewSwitch';
import { ToggleViewSwitchHolder } from '../Menus/Menus.css';
import {
    ABTestingIndicator,
    ActionIconHolder,
    ActionsContainer,
    ButtonsContainerList,
    GridViewWrapper,
    GroupAddGrid,
    GroupAddList,
    GroupContainerGrid,
    GroupContainerGridActionRow,
    GroupContainerGridName,
    GroupContainerList,
    GroupNameContainerList,
    ListViewWrapper,
    GroupIconsContainer,
    GroupContainerListName
} from './TargetGroups.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../style';
import Button from '../Buttons/Button/Button';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import {
    createTargetGroup,
    deleteTargetGroup,
    fetchTargetGroups,
    targetGroupsState,
    unsetTargetGroups,
    updateTargetGroup
} from '../../redux/slices/targetGroupsSlice';
import { setUserPermissions } from '../../redux/slices/permissionsSlice';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import { TargetGroup, TargetGroupAbTestingStatus } from '../../types/TargetGroup';
import NewTargetGroup from './Dialogs/NewTargetGroup';
import CreateResourceDialog from '../common/Dialog/CreateResourceDialog';
import TemplateSelection from '../Pages/Dialogs/TemplateSelection';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { dialogConfirm } from '../../utils/fnDialogs';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loader } from '../common/Loader/Loader';
import { renderTooltipWithKey } from '../common/Tooltips/Tooltips';
import { templateTypes } from '../../types/Template';
import { applyTemplate, templatesState } from '../../redux/slices/templatesSlice';
import { buildPathWithProjectId, PageRoutes } from '../../types/RouteTypes';
import { API_ERROR_CODES } from '../../utils/Globals';

import { RemoveModuleWrapper } from '../Modules/Modules.css';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';

const TargetGroups = () => {
    const [view, setView] = useState<'LIST' | 'GRID'>('GRID');
    const [duplicating, setDuplicating] = useState(false);
    const [targetGroupToEdit, setTargetGroupToEdit] = useState<TargetGroup | undefined>(undefined);
    const [openNewTargetGroupDialog, setOpenNewTargetGroupDialog] = useState(false);
    const [openNewResourceDialog, setOpenNewResourceDialog] = useState(false);
    const [openTemplateSelectionDialog, setOpenTemplateSelectionDialog] = useState(false);

    // used for location state variable
    const [targetGroupId, setTargetGroupId] = useState<string>('');
    const [openCreateGroup, setOpenCreateGroup] = useState<boolean>(false);

    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);

    const { targetGroups, error: targetGroupsError, loading }: targetGroupsState = useAppSelector((state) => state.targetGroups);
    const { error: templatesError }: templatesState = useAppSelector((state) => state.templates);

    // TO BE REMOVED WHEN A/B WILL BE PRODUCTION READY
    const isABDisabled = false;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location: any = useLocation();

    useEffect(() => {
        if (!activeProjectId) return;
        if (targetGroups.length) {
            dispatch(unsetTargetGroups());
        }
        loadTargetGroups(true, activeProjectId).then((response: any) => {
            if (response.permissions) {
                dispatch(setUserPermissions(response.permissions));
            }
        });

        if (location?.state?.openCreate) {
            setOpenCreateGroup(true);
            window.history.replaceState({}, '');
        }
        if (location?.state?.targetGroupId) {
            setTargetGroupId(location.state.targetGroupId);
            window.history.replaceState({}, '');
        }
    }, [activeProjectId]);

    // When redirected from Dashboard open Edit Or Create
    useEffect(() => {
        if (openCreateGroup) {
            handleCreateClick();
            window.scrollTo(0, document.body.scrollHeight);
        }
        if (targetGroupId) {
            handleEditClick(targetGroupId);
            document.getElementById(targetGroupId)?.scrollIntoView();
        }
        // Cleanup location.state variables
        return () => {
            setTargetGroupId('');
            setOpenCreateGroup(false);
        };
    }, [loading]);

    const loadTargetGroups = async (addPermissions?: boolean, projectId?: string) => {
        return await dispatch(fetchTargetGroups({ addPermissions, projectId })).unwrap();
    };

    const saveTargetGroup = async (targetGroup: TargetGroup) => {
        await dispatch(createTargetGroup(targetGroup));
        loadTargetGroups(false, activeProjectId);
    };

    const modifyTargetGroup = async (targetGroup: TargetGroup) => {
        await dispatch(updateTargetGroup({ targetGroup }));
        loadTargetGroups(false, activeProjectId);
    };

    const removeTargetGroup = async (id: string) => {
        await dispatch(deleteTargetGroup(id)).unwrap();
        loadTargetGroups(false, activeProjectId);
    };

    const useTemplate = async (id: string) => {
        const result = await dispatch(
            applyTemplate({
                templateId: id,
                type: templateTypes.TARGET_GROUP,
                projectId: `${activeProjectId}`,
                tenantId: `${activeTenantId}`
            })
        ).unwrap();
        if (result.id) loadTargetGroups(false, activeProjectId);
    };

    // Handle Actions
    //Create
    const handleCreateClick = () => {
        setOpenNewResourceDialog(true);
    };
    //Edit
    const handleEditClick = (id: string) => {
        const tGroup = targetGroups.find((tg) => tg._id === id);
        setTargetGroupToEdit(tGroup);
        setOpenNewTargetGroupDialog(true);
    };
    //Duplicate
    const handleDuplicateClick = (id: string) => {
        const tGroup = targetGroups.find((tg) => tg._id === id);
        setTargetGroupToEdit(tGroup);
        setDuplicating(true);
        setOpenNewTargetGroupDialog(true);
    };
    //Delete
    const handleDeleteClick = (id: string) => {
        const values = {
            title: 'Delete Group',
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                removeTargetGroup(id);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to delete this Group?</strong>
                    <br />
                    By Pressing “Delete” you still will be able to create new one from list
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Delete'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    const handleABTestingClick = (id: string) => {
        navigate(buildPathWithProjectId(activeProjectId, PageRoutes.TARGET_GROUP.replace(':group_id', id)), {
            state: {
                section: 'AB_TESTING_GROUPS'
            }
        });
    };
    const handleUXContentClick = (id: string) => {
        navigate(buildPathWithProjectId(activeProjectId, PageRoutes.TARGET_GROUP.replace(':group_id', id)), {
            state: {
                section: 'UX_CONTENT'
            }
        });
    };

    const renderActionsContainer = (targetGroup: TargetGroup) => {
        return (
            <ActionsContainer>
                <ActionIconHolder>
                    {renderTooltipWithKey(
                        <SVGInline
                            src={icons.editIcon}
                            data-cy={'targetGroup-edit_button'}
                            onClick={() => {
                                handleEditClick(targetGroup._id);
                            }}
                        />,
                        'target_groups_icon_edit'
                    )}
                </ActionIconHolder>

                <ActionIconHolder>
                    {!targetGroup.isDefault &&
                        renderTooltipWithKey(
                            <SVGInline
                                src={icons.trashIcon}
                                data-cy={'targetGroup-delete_button'}
                                onClick={() => {
                                    handleDeleteClick(targetGroup._id);
                                }}
                            />,
                            'target_groups_icon_delete'
                        )}
                </ActionIconHolder>

                <ActionIconHolder>
                    <SVGInline src={icons.moreIcon} />
                </ActionIconHolder>
            </ActionsContainer>
        );
    };

    const renderGridView = () => {
        return (
            <GridViewWrapper data-cy={`targetGroups-grid_view`}>
                {targetGroups.map((targetGroup: TargetGroup, index: number) => {
                    const shouldShowABTestingIndicator =
                        !!(
                            targetGroup.abTestingStatus &&
                            [TargetGroupAbTestingStatus.ACTIVE, TargetGroupAbTestingStatus.PAUSED].includes(targetGroup.abTestingStatus)
                        ) && !isABDisabled;
                    return (
                        <GroupContainerGrid data-cy={`targetGroup-grid_view-${index}`} key={index} id={targetGroup._id}>
                            <GroupContainerGridName>
                                <TruncatedText>{targetGroup.name}</TruncatedText>
                            </GroupContainerGridName>
                            <GroupContainerGridActionRow>
                                <GroupIconsContainer>
                                    {renderTooltipWithKey(
                                        <SVGInline src={targetGroup.active ? icons.groupActiveIcon : icons.groupInactiveIcon} />,
                                        targetGroup.active ? 'target_groups_icon_active' : 'target_groups_icon_inactive'
                                    )}
                                    {shouldShowABTestingIndicator &&
                                        renderTooltipWithKey(
                                            <ABTestingIndicator>
                                                <SVGInline
                                                    src={
                                                        targetGroup.abTestingStatus === TargetGroupAbTestingStatus.PAUSED
                                                            ? icons.abTestingPauseIcon
                                                            : icons.abTestingPlayIcon
                                                    }
                                                />
                                                <span>A/B</span>
                                            </ABTestingIndicator>,
                                            targetGroup.abTestingStatus === TargetGroupAbTestingStatus.PAUSED
                                                ? 'target_groups_ab_testing_paused'
                                                : 'target_groups_ab_testing_started'
                                        )}
                                </GroupIconsContainer>
                                {renderActionsContainer(targetGroup)}
                            </GroupContainerGridActionRow>
                            <Button type={'DEFAULT'} label={'UI & UX Elements'} onClick={() => handleUXContentClick(targetGroup._id)} />
                            <Button type={'DEFAULT'} label={'A/B Testing'} onClick={() => handleABTestingClick(targetGroup._id)} />
                        </GroupContainerGrid>
                    );
                })}
                <GroupAddGrid onClick={() => setOpenNewResourceDialog(true)}>
                    {renderTooltipWithKey(<SVGInline src={icons.addIcon} />, 'target_groups_icon_add')}
                </GroupAddGrid>
            </GridViewWrapper>
        );
    };
    const renderListView = () => {
        return (
            <ListViewWrapper data-cy={'targetGroups-list_view'}>
                {targetGroups.map((targetGroup: TargetGroup, index: number) => {
                    const shouldShowABTestingIndicator =
                        !!(
                            targetGroup.abTestingStatus &&
                            [TargetGroupAbTestingStatus.ACTIVE, TargetGroupAbTestingStatus.PAUSED].includes(targetGroup.abTestingStatus)
                        ) && !isABDisabled;
                    return (
                        <GroupContainerList key={index} data-cy={`targetGroup-list_view-${index}`}>
                            <GroupNameContainerList>
                                <GroupContainerListName>
                                    <TruncatedText>{targetGroup.name}</TruncatedText>
                                </GroupContainerListName>
                                {renderTooltipWithKey(
                                    <SVGInline src={targetGroup.active ? icons.groupActiveIcon : icons.groupInactiveIcon} />,
                                    'target_groups_icon_active'
                                )}
                                {shouldShowABTestingIndicator && (
                                    <ABTestingIndicator>
                                        {targetGroup.abTestingStatus === TargetGroupAbTestingStatus.ACTIVE && (
                                            <SVGInline src={icons.abTestingPlayIcon} />
                                        )}
                                        {targetGroup.abTestingStatus === TargetGroupAbTestingStatus.PAUSED && (
                                            <SVGInline src={icons.abTestingPauseIcon} />
                                        )}
                                        <span>A/B</span>
                                    </ABTestingIndicator>
                                )}
                            </GroupNameContainerList>
                            <ButtonsContainerList>
                                <Button
                                    type={'DEFAULT'}
                                    label={'UI & UX Elements'}
                                    dataCy={'targetGroup-open_elements_button'}
                                    onClick={() => handleUXContentClick(targetGroup._id)}
                                />
                                <Button type={'DEFAULT'} label={'A/B Testing'} onClick={() => handleABTestingClick(targetGroup._id)} />
                            </ButtonsContainerList>
                            {renderActionsContainer(targetGroup)}
                        </GroupContainerList>
                    );
                })}
                <GroupAddList onClick={() => setOpenNewResourceDialog(true)}>
                    Create Group
                    {renderTooltipWithKey(<SVGInline src={icons.addIcon} />, 'target_groups_icon_add')}
                </GroupAddList>
            </ListViewWrapper>
        );
    };

    const error = targetGroupsError || templatesError;

    return (
        <>
            {error && (
                <BackendErrorDialog
                    error={error}
                    customValues={
                        error.code === API_ERROR_CODES.AUDIENCE_VALIDATION_ERROR_SIMILAR_TEMPLATE
                            ? { title: 'Audience already exists', subtitle: 'An error occurred during the process', text: error.message }
                            : undefined
                    }
                />
            )}
            <ApplicationWrapper $loading={loading}>
                <Sidebar disabled={loading} />
                <MainContentWrapper>
                    <ScreenTitle
                        title="Groups"
                        withAddButton
                        withProfile
                        addLabel={'Create Group'}
                        onAdd={() => {
                            handleCreateClick();
                        }}
                        circlesSlugOptions={{ default: CIRCLE_SLUGS.target_groups, onboarding: ONBOARDING_CIRCLE_SLUGS.target_groups }}
                    />
                    {loading && <Loader title="Groups" />}
                    <ToggleViewSwitchHolder>
                        <ToggleViewSwitch
                            checked={view === 'GRID'}
                            dataCy={'targetGroups-toggle_view_switch'}
                            toggleCallback={() => setView(view === 'LIST' ? 'GRID' : 'LIST')}
                            tooltipTexts={{ list: 'target_groups_icon_switch_view_list', grid: 'target_groups_icon_switch_view_grid' }}
                        />
                    </ToggleViewSwitchHolder>
                    {view === 'GRID' ? renderGridView() : renderListView()}
                </MainContentWrapper>
                {!loading && (
                    <>
                        <NewTargetGroup
                            open={openNewTargetGroupDialog}
                            targetGroup={targetGroupToEdit}
                            duplicate={duplicating}
                            onSave={(newTargetGroup) => {
                                if (targetGroupToEdit && !duplicating) {
                                    modifyTargetGroup(newTargetGroup);
                                } else {
                                    saveTargetGroup(newTargetGroup);
                                }
                            }}
                            onClose={() => {
                                setOpenNewTargetGroupDialog(false);
                                setTargetGroupToEdit(undefined);
                                setDuplicating(false);
                            }}
                        />
                        <CreateResourceDialog
                            title={'Group'}
                            open={openNewResourceDialog}
                            onClose={() => {
                                setOpenNewResourceDialog(false);
                            }}
                            historyUrl={''}
                            handleOpenTemplateClick={() => setOpenTemplateSelectionDialog(true)}
                            handleCreateNewResourceClick={() => {
                                setOpenNewResourceDialog(false);
                                setOpenNewTargetGroupDialog(true);
                            }}
                        />
                    </>
                )}
                <TemplateSelection
                    open={openTemplateSelectionDialog}
                    onClose={() => {
                        setOpenTemplateSelectionDialog(false);
                    }}
                    templateUrl={''}
                    callback={(id: any) => {
                        setOpenNewResourceDialog(false);
                        setOpenTemplateSelectionDialog(false);
                        if (id) {
                            useTemplate(id);
                        } else setOpenNewTargetGroupDialog(true);
                    }}
                    resourceType={templateTypes.TARGET_GROUP}
                />
            </ApplicationWrapper>
        </>
    );
};

export default TargetGroups;
