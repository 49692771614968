import { useEffect } from 'react';
import history from '../history';

// function used to cancel the retry when the user closes the UNSAVED_CHANGES dialog via the Close Button;
let retryFunction: (() => void) | null = null;

export const unsetRetryFunction = () => {
    retryFunction = null;
};
const useBlockNavigation = (when: boolean, dialogRenderingFunction?: any, dependencyArray: any[] = []) => {
    useEffect(() => {
        if (!when && retryFunction) {
            retryFunction();
            unsetRetryFunction(); // reset
        }
    }, [when]);

    useEffect(() => {
        if (!when || history.action === 'REPLACE') {
            return;
        }

        const unblock = history.block(({ action, location, retry }) => {
            retryFunction = retry;
            dialogRenderingFunction?.();
            return false;
        });

        return () => {
            unblock();
        };
    }, [when, history, ...dependencyArray]);
};

export default useBlockNavigation;
