import styled from 'styled-components';

export const ApplicationOption = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
    &:hover {
        svg {
            opacity: 0.7;
            &:hover {
                opacity: 1;
            }
        }
    }
    svg {
        width: 14px;
        height: 14px;
        opacity: 0;
    }
    &:hover {
        cursor: pointer;
    }
`;
export const ApplicationOptionSpacer = styled.span`
    margin-left: auto;
`;
