import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { validator } from '../../../../utils/fnValidator';
import { Template, TemplateDefaultValues, templateTypes } from '../../../../types/Template';
import { generateUID } from '../../../../utils/fnGenerator';
import { DialogTextField } from '../../../common/Dialog/GenericDialog';
import { NewTemplate } from '../Common/NewTemplate';

type groupDialogProps = {
    onClose: () => void;
    open: boolean;
    onSave: (value: any) => void;
    onRelease: (id: string) => void;
    onRetract: (id: string) => void;
    group?: Template;
};

export const NewGroupTemplate: FC<groupDialogProps> = ({ open, onClose, onSave, onRelease, onRetract, group }) => {
    const [name, setName] = useState('');
    const [errors, setErrors] = useState<{ name?: string; icon?: string }>({});
    const [isOpen, setIsOpen] = useState(open);

    //TODO: to change the namings
    const validateNewGroup = (val: TemplateDefaultValues) => {
        const { icon } = val;
        const newErrors = { ...errors };
        newErrors.name = validator({ required: true, minLength: 3 }, name);
        newErrors.icon = validator({ required: true, minLength: 3 }, icon);

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    const clearState = () => {
        setName('');
        setErrors({});
    };

    const onCloseDialog = () => {
        if (onClose) {
            onClose();
        }
        clearState();
    };

    const onSaveDialog = (values: TemplateDefaultValues) => {
        if (onSave) {
            if (!validateNewGroup(values)) return;
            const { icon, iconBackground, projectIds, tenantIds } = values;
            let newGroup: Template = {
                _id: group?._id || generateUID(),
                type: templateTypes.TARGET_GROUP,
                projectIds,
                tenantIds,
                values: {
                    name
                },
                icon,
                iconBackground,
                audienceValues: group?.audienceValues || [],
                lastModified: group?.lastModified
            };
            onSave(newGroup);
        }
    };

    const onReleaseDialog = () => {
        if (!group?._id) return;
        onRelease(group._id);
    };

    const onRetractDialog = () => {
        if (!group?._id) return;
        onRetract(group._id);
    };

    useEffect(() => {
        setName(group?.values?.name || '');
    }, [group]);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    if (!isOpen) return null;

    const children = (
        <DialogTextField
            label={'Group Name'}
            value={name}
            onChange={(e: any) => {
                setName(e.target.value);
                setErrors(_.omit(errors, 'name'));
            }}
            error={errors.name}
        />
    );

    return (
        <NewTemplate
            templateType={templateTypes.TARGET_GROUP}
            template={group}
            open={open}
            onRelease={onReleaseDialog}
            onRetract={onRetractDialog}
            onSave={onSaveDialog}
            onClose={onCloseDialog}
            setErrors={setErrors}
            extraValues={{ name }}
            errors={errors}
        >
            {children}
        </NewTemplate>
    );
};
