import icons from '../assets/images/icons';
import { renderTooltip, tooltipPositions, tooltipTypes } from '../components/common/Tooltips/Tooltips';
import SVGInline from 'react-inlinesvg';
import { dialogAlert, ToastAlert } from './fnDialogs';
import BackendErrorDialog from '../components/common/Dialog/BackendErrorDialog';
import { ShowMoreIcon } from '../components/common/ShowMoreIcon/ShowMoreIcon';
import { MoreInfoTypes } from '../components/common/Dialog/MoreInfoDialog';

export const renderLockIcon = (name: string = '') => {
    const toolTipText = `The object is currently edited by "${name}"`;
    return renderTooltip(
        <SVGInline key={`locked`} src={icons.lockedObjectIcon} />,
        tooltipTypes.TEXT,
        toolTipText || '',
        tooltipPositions.TOP
    );
};

export const renderLockedWarningAlert = (name: string = '') => {
    const values = {
        title: 'Someone Else is Editing!',
        text: (
            <>
                it looks like <strong>&quot;{name}&quot;</strong> edited this activity a few seconds ago. Only one person can edit the
                activity at the same time
            </>
        )
    };

    return ToastAlert('critical_warning', values.title, values.text, icons.lockedObjectIcon, undefined, () => {
        dialogAlert('', false, values, null, false, icons.lockedObjectIcon, null, true);
    });
};

export const renderLockedError = (error: any) => {
    const values = {
        title: 'Someone Else is Editing!',
        text: (
            <>
                it looks like <strong>&quot;{error?.data?.name}&quot;</strong> edited this activity a few seconds ago. Only one person can
                edit the activity at the same time
            </>
        )
    };
    return <BackendErrorDialog error={error} customValues={values} />;
};

export const renderLockedListActionError = (error: any) => {
    const values = {
        title: 'Delete multiple objects is not allowed!',
        text: 'You are not able to delete this list because there are locked objects within it. Please exclude the locked objects before deleting the list!'
    };
    return <BackendErrorDialog error={error} customValues={values} />;
};

export const renderABIcon = (obj: any, isVisualEditor?: boolean) => {
    const abGroups: any[] = obj.abTestingGroups || [];
    if (!abGroups.length) return;
    const icon = isVisualEditor ? icons.abTestingLightIcon : icons.abTestingDarkIcon;
    return <ShowMoreIcon withTransparency={!isVisualEditor} icon={icon} values={abGroups} type={MoreInfoTypes.AB_GROUPS} />;
};
