import styled from 'styled-components';
import { fontSizesCSS } from '../../../style/themes/theme';

export const MainTitle = styled.div`
    display: flex;
    align-items: center;
    span {
        ${fontSizesCSS.Body1};
    }
`;
