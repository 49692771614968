import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../../common/Dialog/GenericDialog';
import { useExistingDialogStyle } from '../../PageEdit/Dialogs/UseExistingDialog.css';
import { ParsedFile } from '../FileManager';
import { validator } from '../../../utils/fnValidator';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';

type newFolderProps = {
    open: boolean;
    onClose: () => void;
    onSave: (arg: string) => void;
    file?: ParsedFile;
    isFolder?: boolean;
};

export const NewFolder: FC<newFolderProps> = ({ onSave, onClose, open, file, isFolder }) => {
    const [fileName, setFileName] = useState<string>('');
    const [extension, setExtension] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');

    const handleSave = () => {
        const error = validator({ required: true }, fileName);
        if (error) {
            return setNameError(error);
        }
        onSave(isFolder ? fileName : `${fileName}.${extension}`);
        handleClose();
    };

    const handleClose = () => {
        setFileName('');
        setExtension('');
        setNameError('');
        onClose();
    };

    const saveButton: DialogButton = {
        label: !file ? 'Create' : 'Edit',
        type: 'BLUE',
        onClick: handleSave
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleClose
    };

    useEffect(() => {
        if (!file) return;
        const fileExtension = _.last(file.name.split('.'));
        const fileName = file.name.replace(`.${fileExtension}` || '', '');
        setFileName(fileName);
        setExtension(fileExtension || '');
    }, [file]);

    if (!open) {
        return null;
    }

    return (
        <GenericDialog
            title={!file ? `Create ${isFolder ? 'Folder' : 'File'}` : 'Edit File'}
            actionButtons={[cancelButton, saveButton]}
            type={DialogTypes.Form}
            onClose={handleClose}
            style={useExistingDialogStyle}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.file_manager }}
        >
            <DialogTextField
                label={isFolder ? 'Folder Name' : 'File Name'}
                value={fileName}
                onChange={(e: any) => {
                    const value = e.target.value?.replace(/[^A-Za-z0-9_-]/g, '');
                    setFileName(value);
                    setNameError('');
                }}
                error={nameError}
            />
        </GenericDialog>
    );
};
