import styled from 'styled-components';
import { devices, TruncatedText } from '../../style/styled-components/reusable.css';
import getTheme, { fontSizes } from '../../style/themes/theme';

const theme: any = getTheme();

export const flagsWrapper = styled.div``;

export const FeatureFlagEditWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

    ${devices.tablet} {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }
`;

export const FeatureFlagTitle = styled.div`
    gap: 8px;
    display: flex;
    ${fontSizes.Body2}
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`;

export const FeatureFlagFieldWrapper = styled.div`
    width: 100%;
`;

export const FlagSection = styled.div<{ $expanded?: boolean }>`
    border-top: 1px solid ${theme.palette.background.pressed};
    ${fontSizes.Body2}
    width: calc(100% - 48px);
    background: ${theme.palette.white.main};
    display: flex;
    ${({ $expanded }) => !$expanded && 'margin-bottom: 1px'};
    align-items: center;
    justify-content: space-between;
    padding: 24px;

    &:first-child {
        ${({ $expanded }) => !$expanded && 'margin-top: 1px'};
    }

    svg {
        &:hover {
            cursor: pointer;
        }
        height: 16px;
        width: 16px;
    }
`;

export const FlagsSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: ${theme.palette.background.pressed};
    padding: 16px 24px 24px;
`;

export const FlagsSubsectionName = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    &:hover {
        cursor: pointer;
    }
`;

export const FlagsExpandedRow = styled.div`
    background-color: ${theme.palette.background.container};
    padding: 24px;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    display: flex;
    height: 36px;
`;
export const FlagSubsection = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
`;
