import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import { ActiveItemState } from '../../../../redux/slices/activeItemSlice';
import {
    searchObjects,
    SearchState,
    setDocumentationKeys,
    setSearchResults,
    setSearchTerm,
    toggleOpenSearchBar,
    unsetSearchResults
} from '../../../../redux/slices/searchSlice';
import icons from '../../../../style';
import { SearchSVGInline } from '../../../../style/styled-components/reusable.css';
import { renderTooltipWithKey } from '../../Tooltips/Tooltips';
import circleSlugs from '../../../../utils/search_slugs.json';
import { docsWidget } from '../../../App';
import {
    HeaderSearchIcon,
    HeaderSearchInput,
    HeaderSearchWrapper,
    NoResultsSection,
    ResultsContainer,
    ResultsLoadingSkeleton,
    ResultsSection
} from './HeaderSearch.css';
import { parseSearchDocumentation, parseSearchObjects } from '../../../../utils/parsers';
import { NoContentIcon, NoContentText } from '../../../SearchPage/SearchPage.css';
import { NO_CONTENT, splitObjectType } from '../../../SearchPage/SearchPage';
import SVGInline from 'react-inlinesvg';
import { buildPathWithProjectId, PageRoutes } from '../../../../types/RouteTypes';
import { SCREEN_TITLE_ID } from '../../DashboardTitle/ScreenTitle';

export enum searchTypes {
    OBJECT = 'Object',
    DOCUMENTATION = 'Documentation',
    TUTORIALS = 'Video Tutorials'
}

const SEARCH_TITLE = 'Search';
export const CIRCLE_SEPARATOR = '&&&';

export const objectLinks: any = {
    audiences: PageRoutes.AUDIENCES,
    dynamicSources: PageRoutes.SOURCES,
    pageStyles: PageRoutes.PAGE_STYLES,
    menus: PageRoutes.MENUS,
    items: PageRoutes.ITEMS,
    modules: PageRoutes.MODULES,
    pages: PageRoutes.PAGES,
    targetGroups: PageRoutes.TARGET_GROUPS,
    displayConditions: PageRoutes.TARGET_CONDITIONS
};

export type SearchObject = {
    type: searchTypes;
    name: string;
    path: string;
    object_type?: string;
    lastModified?: number;
    spaceSlug?: string;
    tags?: string[];
};

export const HeaderSearch = () => {
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const searchRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();

    const {
        objects,
        documentationKeys,
        searchResults,
        searchTerm,
        isSidebarOpen: isOpen,
        loading,
        error
    }: SearchState = useAppSelector((state) => state.search);
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const dispatch = useDispatch();
    const location: any = useLocation();

    const path = `/${_.last(location.pathname.split('/'))}`;
    const isSearchPage = path === '/search';

    const changeSearchTerm = (term: string) => {
        dispatch(setSearchTerm(term));
    };

    const removeSearchResults = () => {
        dispatch(unsetSearchResults());
    };

    const setIsOpen = (value: boolean) => {
        dispatch(toggleOpenSearchBar(value));
    };

    const search = async (searchTerm: string) => {
        return await dispatch(searchObjects({ projectId: activeProjectId, searchTerm })).unwrap();
    };

    const circleSearch = (term: string) => {
        if (term === CIRCLE_SEPARATOR) return;
        const onBoarding = circleSlugs['cc-onboarding'];

        const documentationSlugs = (Object.keys(circleSlugs) as Array<keyof typeof circleSlugs>).filter((key) => {
            const circleValue = circleSlugs[key].toString();

            if (circleValue.includes(CIRCLE_SEPARATOR)) {
                return circleSlugs[key]
                    .toString()
                    .split(CIRCLE_SEPARATOR)
                    .some((value) => value.includes(term));
            }
            return circleValue.includes(term);
        });

        const videoSlugs = (Object.keys(onBoarding) as Array<keyof typeof onBoarding>).filter((key) => {
            const circleValue = onBoarding[key].toString();
            return circleValue.includes(term);
        });

        const documentationKeys = {
            documentation: documentationSlugs,
            tutorials: videoSlugs
        };

        dispatch(setDocumentationKeys(documentationKeys));
    };

    const handleSearch = useCallback(
        _.debounce((searchTerm: string) => {
            const term = searchTerm.toLowerCase().trim();
            setIsSearching(false);
            search(encodeURIComponent(term));
            circleSearch(term);
        }, 1000),
        []
    );

    const onKeyUp = (e: any) => {
        if (e.keyCode !== 13 || searchTerm.length === 0 || isSearchPage) return;
        navigate(buildPathWithProjectId(activeProjectId, PageRoutes.SEARCH), { state: { shouldShowSearch: true } });
    };

    useEffect(() => {
        // should not search again when we redirect to search page
        if (location?.state?.shouldShowSearch) return;
        if (!searchTerm) {
            removeSearchResults();
            return;
        }
        setIsSearching(true);
        handleSearch(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        dispatch(setSearchResults([...parseSearchObjects(objects), ...parseSearchDocumentation(documentationKeys)]));
    }, [documentationKeys, objects]);

    useEffect(() => {
        const clickOutside = (evt: any) => {
            if (!isOpen) return;

            if (isSearchPage) {
                if (document.getElementById(SCREEN_TITLE_ID)?.contains(evt.target)) return navigate(-1);
                return;
            }
            if (searchRef?.current && !searchRef.current.contains(evt.target) && !evt.target?.classList?.contains(SEARCH_TITLE)) {
                setIsOpen(false);
                changeSearchTerm('');
            }
        };

        document.addEventListener('click', clickOutside);
        document.addEventListener('touchstart', clickOutside);
        document.addEventListener('keyup', onKeyUp);
        return () => {
            document.removeEventListener('click', clickOutside);
            document.removeEventListener('touchstart', clickOutside);
            document.removeEventListener('keyup', onKeyUp);
        };
    }, [isOpen, searchTerm]);

    const onSectionClick = (obj: SearchObject) => {
        if (obj.type === searchTypes.OBJECT) {
            if (path === obj.path) {
                changeSearchTerm('');
                setIsOpen(false);
                return;
            }
            return navigate(buildPathWithProjectId(activeProjectId, obj.path));
        }

        if (!docsWidget) return;
        docsWidget.setSlug(obj.path, obj?.spaceSlug);
        return docsWidget.element?.click();
    };

    const renderLoader = () => {
        const loaderRows: JSX.Element[] = [];

        for (let x = 0; x <= 4; x++) {
            loaderRows.push(
                <ResultsSection loading>
                    <ResultsLoadingSkeleton />
                </ResultsSection>
            );
        }
        return <ResultsContainer>{loaderRows}</ResultsContainer>;
    };

    const renderSuggestions = (results: SearchObject[]) => {
        let data: SearchObject[] = [];
        const itemsToShow = 4;

        if (!searchTerm) return;

        if (!results.length)
            return (
                <ResultsContainer>
                    <NoResultsSection>
                        <NoContentIcon>
                            <SVGInline src={icons.warningIcon} />
                        </NoContentIcon>
                        <NoContentText>{NO_CONTENT}</NoContentText>
                    </NoResultsSection>
                </ResultsContainer>
            );

        data = results.slice(0, itemsToShow);

        if (results.length > itemsToShow) {
            const documentationResults = results.filter((result) =>
                [searchTypes.DOCUMENTATION, searchTypes.TUTORIALS].includes(result.type)
            );

            // add one documentation file in the suggestions if there are more objects than the number of suggestions
            if (!!documentationResults.length && results.length - documentationResults.length >= itemsToShow) {
                data.splice(-1, 1, documentationResults[0]);
            }
        }

        return (
            <ResultsContainer>
                {data.map((object, index) => {
                    return (
                        <ResultsSection
                            onClick={() => {
                                onSectionClick(object);
                            }}
                            key={index}
                        >
                            {`${object.name} (${object?.object_type ? splitObjectType(object.object_type) : object.type})`}
                        </ResultsSection>
                    );
                })}
                {results.length > itemsToShow && (
                    <ResultsSection
                        className={SEARCH_TITLE}
                        seAll
                        onClick={() => {
                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.SEARCH), { state: { shouldShowSearch: true } });
                        }}
                    >
                        {`See all of the results for ${searchTerm}`}
                    </ResultsSection>
                )}
            </ResultsContainer>
        );
    };

    const openSearchBar = isSearchPage || isOpen;
    const isLoading = isSearching || loading;

    return (
        <HeaderSearchWrapper ref={searchRef} open={openSearchBar}>
            <HeaderSearchInput
                inputRef={inputRef}
                placeholder={SEARCH_TITLE}
                value={searchTerm}
                onChange={(e: any) => {
                    changeSearchTerm(e.target.value);
                }}
                onClick={(e: any) => {
                    e?.stopPropagation();
                }}
            />
            <HeaderSearchIcon
                onClick={(e: any) => {
                    e?.stopPropagation();
                    if (!isOpen) {
                        setIsOpen(true);
                        inputRef.current?.focus();
                    }
                    if (isOpen && searchTerm) {
                        if (isSearchPage) {
                            return navigate(-1);
                        }
                        changeSearchTerm('');
                        setIsOpen(false);
                    }
                }}
            >
                {renderTooltipWithKey(
                    <SearchSVGInline src={openSearchBar && searchTerm ? icons.closeIcon : icons.searchIcon} />,
                    `${openSearchBar && searchTerm ? 'global_search_icon_close' : 'generic_icon_search'}`
                )}
            </HeaderSearchIcon>

            {!isSearchPage && (isLoading ? renderLoader() : renderSuggestions(searchResults))}
        </HeaderSearchWrapper>
    );
};
