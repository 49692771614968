import WebOsIcon from './ico-web-os.svg';
import TvOsIcon from './ico-tv-os.svg';
import TizenIcon from './ico-tizen.svg';
import TabletIcon from './ico-tablet.svg';
import SmartTvSickIcon from './ico-smart-tv-stick.svg';
import SmartTvIcon from './ico-smart-tv.svg';
import RokuIcon from './ico-roku.svg';
import PCIcon from './ico-pc.svg';
import PCBrowserIcon from './ico-pc-browser.svg';
import MobileIcon from './ico-mobile.svg';
import LocationIcon from './ico-location.svg';
import FireTVIcon from './ico-fire-tv.svg';
import DeviceIdIcon from './ico-device-id.svg';
import ClientAppVersionIcon from './ico-client-app-version.svg';
import CableOperatorIcon from './ico-cable-operator.svg';
import CountryIcon from './ico-country.svg';
import BigScreenIcon from './ico-big-screen.svg';
import AndroidIcon from './ico-android.svg';
import SetTopBoxIcon from './ico-set-top-box.svg';
import OperatingSystemVersionIcon from './ico-operating-system-version.svg';
import IOSicon from './ico-ios.svg';
import GameConsoleIcon from './ico-game-console.svg';
import AutomotiveIcon from './ico-automotive.svg';
import UserIdIcon from './ico-user-id.svg';
import MobileIcon2 from './ico-mobile-2.svg';
import TabletIcon2 from './ico-tablet-2.svg';
import RokuIcon2 from './ico-roku-2.svg';
import OperatingSystemVersionIcon2 from './ico-operating-system-version-2.svg';
import DeviceIdIcon2 from './ico-device-id-2.svg';
import UserIdIcon2 from './ico-user-id-2.svg';
import ClientAppVersionIcon2 from './ico-client-app-version-2.svg';
import ManufacturerIcon from './ico-manufacturer.svg';
import ClientAppVariantIcon from './ico-client-app-variant.svg';
import VisionOsIcon from './ico-vision-os.svg';
import XRHeadsetIcon from './ico-xr-headset.svg';

export const audiencesIcons: any = {
    deviceClass: {
        bigscreen: BigScreenIcon,
        mobile: MobileIcon,
        pc: PCIcon,
        tablet: TabletIcon
    },
    deviceType: {
        automotive: AutomotiveIcon,
        pc: PCIcon,
        set_top_box: SetTopBoxIcon,
        smart_tv_stick: SmartTvSickIcon,
        smart_tv: SmartTvIcon,
        game_console: GameConsoleIcon,
        roku: RokuIcon,
        tablet: TabletIcon2,
        mobile: MobileIcon2,
        xr_headset: XRHeadsetIcon
    },
    operatingSystem: {
        android: AndroidIcon,
        browser: PCBrowserIcon,
        ios: IOSicon,
        fire_tv: FireTVIcon,
        tvos: TvOsIcon,
        webos: WebOsIcon,
        tizen: TizenIcon,
        roku: RokuIcon2,
        vidaa_os: TvOsIcon,
        ipad_os: TabletIcon,
        qnx: AutomotiveIcon,
        linux: SetTopBoxIcon,
        rdk: SetTopBoxIcon,
        vision_os: VisionOsIcon
    },
    operatingSystemVersion: OperatingSystemVersionIcon,
    operatingSystemVersion2: OperatingSystemVersionIcon2,
    clientAppVersion: ClientAppVersionIcon,
    clientAppVersion2: ClientAppVersionIcon2,
    deviceIds: DeviceIdIcon,
    deviceIds2: DeviceIdIcon2,
    userIds: UserIdIcon,
    userIds2: UserIdIcon2,
    cableOperator: CableOperatorIcon,
    country: CountryIcon,
    location: LocationIcon,
    manufacturer: ManufacturerIcon,
    clientAppVariants: ClientAppVariantIcon
};
