import React, { FC, useEffect, useState } from 'react';
import Button from '../../Buttons/Button/Button';
import { DialogCheckbox } from '../Dialog/GenericDialog';
import { useAppDispatch as useDispatch } from '../../../hooks/redux';
import { hidePageActions, showPageActions } from '../../../redux/slices/pageActionsShowSlice';
import { PublishButtonsWrapper, ScheduleButtonWrapper } from './PageActions.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../style';
import { DatePickerComponent as DatePicker, DatePickerDialog } from '../DatePicker/DatePicker';
import { TimePicker } from '../TimePicker/TimePicker';
import { DatepickerBackdrop, PageButtonsWrapper } from '../../../style/styled-components/reusable.css';
import { renderTooltip, tooltipPositions, tooltipTypes } from '../Tooltips/Tooltips';
import { PUBLISHED_STATUS } from '../../../utils/fnPublish';
import { ButtonIcon } from '../../Buttons/Button/Button.css';
import { iconType } from '../../../assets/images/icons';
import { WarningLabelsWrapper, WarningLabel } from '../../TargetGroups/GroupEdit.css';

export type PageActionsProps = {
    disabled?: {
        save?: boolean;
        publish?: boolean;
        cancel?: boolean;
        release?: boolean;
        retract?: boolean;
    };
    loading?: {
        save?: boolean;
    };
    checked?: {
        template?: boolean;
    };
    onSave?: () => void;
    onPublish?: (publishAt?: number) => void;
    onAbortPublish?: () => void;
    onCancel?: () => void;
    onSaveTemplate?: () => void;
    onRelease?: () => void;
    onRetract?: () => void;
    publishedStatus?: PUBLISHED_STATUS;
    withSchedule?: boolean;
    timestamp?: number;
    customSaveLabel?: string;
    warnings?: {
        icon: iconType;
        critical?: boolean;
        onClick?: () => void;
    }[];
};

export type ScheduleButtonProps = {
    disabled?: boolean;
    onSchedule?: (arg: number) => void;
    onPublish?: (arg: number) => void;
};

export const ScheduleButton: FC<ScheduleButtonProps> = ({ onSchedule, onPublish, disabled }) => {
    const [isTimePickerOpen, setIsTimePickerOpen] = useState<boolean>(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
    const [date, setDate] = useState<number | undefined>(undefined);
    const [time, setTime] = useState<number | undefined>(undefined);

    useEffect(() => {
        // if 12:00 AM is set time === 0, we do a check for undefined
        if (!date || time === undefined) return;
        const dateTime = date * 1000 + time;
        onSchedule?.(dateTime);
    }, [date, time]);

    useEffect(() => {
        if (disabled) {
            setDate(undefined);
            setTime(undefined);
        }
    }, [disabled]);

    const renderScheduleButton = () => {
        const toolTipText =
            date && time ? `Publishing was scheduled for ${new Date(date * 1000 + time)}` : 'Pick a date and time to schedule publishing';
        return (
            <ScheduleButtonWrapper>
                <Button
                    type={'DEFAULT'}
                    label={renderTooltip(
                        <ButtonIcon>
                            <SVGInline src={icons.scheduleDatePickerIcon} />
                        </ButtonIcon>,
                        tooltipTypes.TEXT,
                        toolTipText || '',
                        tooltipPositions.TOP
                    )}
                    onClick={() => setIsDatePickerOpen(true)}
                    isDisabled={disabled}
                />
            </ScheduleButtonWrapper>
        );
    };
    return (
        <>
            {renderScheduleButton()}
            <DatePickerDialog
                saveDate={(date) => {
                    setDate(date + 1);
                    setIsTimePickerOpen(true);
                    setIsDatePickerOpen(false);
                }}
                toggleOpenDialog={disabled ? () => {} : setIsDatePickerOpen}
                isOpen={isDatePickerOpen}
                sDate={date}
                filterDate={(date) => {
                    let yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
                    return yesterday <= date;
                }}
                onClose={() => {
                    if (!time) setDate(undefined);
                }}
            />
            <TimePicker
                open={isTimePickerOpen}
                onClose={() => {
                    setIsTimePickerOpen(false);
                    setDate(undefined);
                    setTime(undefined);
                }}
                saveTime={(time) => {
                    setTime(time);
                    if (date) onPublish?.(date * 1000 + time);
                    setIsDatePickerOpen(false);
                }}
                sTime={time}
                timeIncrement={15}
                strictTimeSelection
                selectedDate={date}
                customSaveButtonLabel={'Schedule Publish'}
            />
        </>
    );
};

const PageActions: FC<PageActionsProps> = ({
    disabled,
    checked,
    publishedStatus,
    withSchedule,
    onSave,
    onPublish,
    onAbortPublish,
    onCancel,
    onSaveTemplate,
    onRelease,
    onRetract,
    timestamp,
    loading,
    customSaveLabel,
    warnings
}) => {
    const [schedulePublishValue, setSchedulePublishValue] = useState<number | undefined>(undefined);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showPageActions());
        return () => {
            dispatch(hidePageActions());
        };
    }, []);

    const renderPublishButtons = () => {
        if (!publishedStatus) return;
        const toolTipText = timestamp ? `Publishing was scheduled for ${new Date(timestamp)}` : '';
        if ([PUBLISHED_STATUS.SCHEDULED_TO_PUBLISH, PUBLISHED_STATUS.SCHEDULED_TO_REPUBLISH].includes(publishedStatus)) {
            return (
                <PublishButtonsWrapper>
                    <Button
                        type={'DEFAULT'}
                        label={renderTooltip(<div>Abort Publish</div>, tooltipTypes.TEXT, toolTipText || '', tooltipPositions.TOP)}
                        onClick={(evt: any) => {
                            evt.preventDefault();
                            onAbortPublish?.();
                            setSchedulePublishValue(undefined);
                        }}
                    />
                </PublishButtonsWrapper>
            );
        }
        return (
            <PublishButtonsWrapper>
                <Button
                    type={'DEFAULT'}
                    label={'Publish'}
                    onClick={(evt: any) => {
                        evt.preventDefault();
                        onPublish?.();
                    }}
                    isDisabled={disabled?.publish}
                />
                {withSchedule && (
                    <ScheduleButton
                        onSchedule={(dateTime) => {
                            setSchedulePublishValue(dateTime);
                        }}
                        onPublish={(dateTime) => {
                            onPublish?.(dateTime);
                        }}
                        disabled={disabled?.publish}
                    />
                )}
            </PublishButtonsWrapper>
        );
    };

    const renderWarnings = () => {
        if (!warnings?.length) return null;
        return warnings?.map((warning, index) => (
            <WarningLabelsWrapper key={`warning-${index}`}>
                <WarningLabel
                    critical={warning.critical}
                    withoutText
                    onClick={() => {
                        warning.onClick?.();
                    }}
                >
                    <SVGInline src={warning.icon} />
                </WarningLabel>
            </WarningLabelsWrapper>
        ));
    };

    return (
        <PageButtonsWrapper>
            {renderWarnings()}
            {onSaveTemplate && (
                <DialogCheckbox
                    onClick={(evt: any) => {
                        evt.preventDefault();
                        onSaveTemplate();
                    }}
                    text={'Save as template'}
                    active={!!checked?.template}
                    value={!!checked?.template}
                    tooltipText="pages_edit_icon_save_as_template"
                />
            )}

            <Button
                type={'DEFAULT'}
                label={'Cancel'}
                onClick={(evt: any) => {
                    evt.preventDefault();
                    onCancel && onCancel();
                }}
                isDisabled={disabled?.cancel}
            />
            {renderPublishButtons()}
            {onRelease && (
                <Button
                    type={'BLUE'}
                    label={'Release'}
                    onClick={(evt: any) => {
                        evt.preventDefault();
                        onRelease();
                    }}
                    isDisabled={disabled?.release}
                />
            )}

            {onRetract && (
                <Button
                    type={'DEFAULT'}
                    label={'retract'}
                    onClick={(evt: any) => {
                        evt.preventDefault();
                        onRetract();
                    }}
                    isDisabled={disabled?.retract}
                />
            )}

            <Button
                type={'BLUE'}
                label={customSaveLabel || 'Save'}
                onClick={(evt: any) => {
                    evt.preventDefault();
                    onSave && onSave();
                }}
                isDisabled={disabled?.save}
                loading={loading?.save}
                dataCy={'page-action-save-button'}
            />
        </PageButtonsWrapper>
    );
};

export default PageActions;
