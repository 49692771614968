import React, { useEffect, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';

import { ApplicationWrapper, MainContentWrapper, TruncatedText } from '../../../style/styled-components/reusable.css';
import Sidebar from '../Sidebar/Sidebar';
import ScreenTitle from '../../common/DashboardTitle/ScreenTitle';
import { Loader } from '../../common/Loader/Loader';
import {
    deleteProject as removeProject,
    enableProject as restoreProject,
    fetchDisabledProjects,
    ProjectsState
} from '../../../redux/slices/projectsSlice';
import GenericTable, {
    ActionsTableCell,
    HeaderTableCell,
    ImageTableCell,
    SortableHeaderTableCell,
    tableActions
} from '../../common/Table/Table';
import { TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import { generateDateStringForTables } from '../../../utils/fnDate';
import { CategoryRow, ProjectLeadsContainer, ProjectPreviewForDeletion, UserAvatar } from '../../Projects/Projects.css';
import icons from '../../../style';
import { BlankTableRow, GroupedByIcon, GroupedByTableRow, GroupedByTitle, WidthTableCell } from '../../common/Table/Table.css';
import { NameWrapper } from '../../Modules/Modules.css';
import { Project } from '../../../types/Project';
import { ACCEPTED_SORT_FIELDS, DEFAULT_SORT_CONFIG, ISortConfig } from '../../../utils/fnSort';
import useScreenSize from '../../../hooks/useScreenSize';
import { dialogConfirm } from '../../../utils/fnDialogs';
import { SearchBar } from '../../common/SearchBar/SearchBar';
import { generateRandomColor } from '../../../utils/fnGenerator';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import { ProjectsTableSizes } from '../../../types/TableSizes';
import { capitalizeAndSplitCamelCaseString } from '../../../utils/fnString';
import { SearchBarContainer } from '../../common/SearchBar/SearchBar.css';

const DisabledProjects = () => {
    const dispatch = useDispatch();
    const { isSmallMobile, isMobile } = useScreenSize();
    const { disabledProjects, loading, error }: ProjectsState = useAppSelector((state) => state.projects);

    // second projects state needed for sorting
    const [projects, setProjects] = useState<Project[]>([]);
    const [colors, setColors] = useState<any>(null);

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [activeSortingKey, setActiveSortingKey] = useState<string | null>('lastModified');

    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;
    const columnKeys = ['name', 'key', 'projectLeads', 'lastModified'];

    const loadDisabledProjects = async (orderBy?: string, searchTerm?: string) => {
        await dispatch(fetchDisabledProjects({ orderBy, searchTerm }));
    };

    const deleteProject = async (id: string) => {
        await dispatch(removeProject(id)).unwrap();
        loadDisabledProjects(orderBy, searchTerm);
    };

    const enableProject = async (id: string) => {
        await dispatch(restoreProject(id)).unwrap();
        loadDisabledProjects(orderBy, searchTerm);
    };

    useEffect(() => {
        loadDisabledProjects(orderBy, searchTerm);
    }, []);

    useEffect(() => {
        if (loading || error) return;
        if (disabledProjects) {
            const newColors = { ...colors };
            const newProjects = [...disabledProjects];
            newProjects.forEach((project) => {
                newColors[project._id] = generateRandomColor();
            });
            setColors(newColors);
            setProjects(newProjects);
        }
    }, [loading, disabledProjects]);

    const handleOnSearch = (searchTerm: string) => {
        loadDisabledProjects(orderBy, searchTerm);
    };

    const handleSortIconClick = (field: string) => {
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && (sortConfig.field === field || sortConfig.field.split('.')[0] === field) && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            direction,
            field
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadDisabledProjects(orderBy, searchTerm);
        setShowSortArrows(false);
    };

    const handleDeleteIconClick = (id: string) => {
        const project = projects.find((project) => project._id === id);
        const values = {
            title: 'Delete Project',
            text: (
                <>
                    <p>
                        You are in the process of PERMANENTLY deleting your project <strong>{project?.name}</strong>. Are you sure you want
                        to permanently delete the selected project including all its connected configurations, audiences, targets and files?
                    </p>
                    <p>Are you sure you want to delete?</p>
                </>
            )
        };
        dialogConfirm(
            '',
            () => {
                deleteProject(id);
            },
            values,
            <ProjectPreviewForDeletion>
                <img src={project?.logo || icons.projectIcon1} alt={'project_logo'} />
                <p>{project?.name}</p>
            </ProjectPreviewForDeletion>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Delete Permanently'
                }
            }
        );
    };

    const handleEnableIconClick = (id: string) => {
        const project = projects.find((project) => project._id === id);
        const values = {
            title: 'Enable Project',
            text: (
                <>
                    <p>
                        You are in the process of enable/restoring the project <strong>{project?.name}</strong>.
                    </p>
                    <p>Are you sure you want to continue?</p>
                </>
            )
        };
        dialogConfirm(
            '',
            () => {
                enableProject(id);
            },
            values,
            <ProjectPreviewForDeletion>
                <img src={project?.logo || icons.projectIcon1} alt={'project_logo'} />
                <p>{project?.name}</p>
            </ProjectPreviewForDeletion>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Enable'
                }
            }
        );
    };

    const renderActions = () => {
        return [tableActions.ENABLE, tableActions.REMOVE];
    };

    const buildTableColumns = () => {
        const columns = projects?.length
            ? columnKeys.map((key, index) => {
                  const text = capitalizeAndSplitCamelCaseString(key);
                  const isAcceptedSorField = ACCEPTED_SORT_FIELDS[key as keyof typeof ACCEPTED_SORT_FIELDS];

                  return isAcceptedSorField ? (
                      <SortableHeaderTableCell
                          key={index}
                          text={text}
                          onClick={() => handleSortIconClick(key)}
                          onMouseEnter={() => setShowSortArrows(true)}
                          onMouseLeave={() => setShowSortArrows(false)}
                          hideArrow={!showSortArrows && activeSortingKey !== key}
                          columnSize={ProjectsTableSizes[key as keyof typeof ProjectsTableSizes]}
                          direction={
                              ((sortConfig?.field === key || sortConfig?.field.split('.')[0] === key) && sortConfig?.direction) || 'asc'
                          }
                      />
                  ) : (
                      <HeaderTableCell text={text} key={index} columnSize={ProjectsTableSizes[key as keyof typeof ProjectsTableSizes]} />
                  );
              })
            : [];
        !isSmallMobile && columns.unshift(<TableCell key={'first_cell'} />);
        isMobile && columns.splice(isSmallMobile ? 3 : 4, 1); // remove last modified column if is mobile view
        return columns;
    };
    const buildTableBody = () => {
        const projectsToRender = _.groupBy(projects, 'tenantId');
        const multipleTenants = Object.keys(projectsToRender).length > 1;

        const rows: JSX.Element[] = [];
        Object.values(projectsToRender)
            .flat()
            .forEach((project, index) => {
                const lastModified = generateDateStringForTables(project.lastModified || 0);

                if (multipleTenants && rows.findIndex((elem) => elem.key === project.tenantId) < 0) {
                    index !== 0 && rows.push(<BlankTableRow key={`blank_tr_${index}`} />);
                    rows.push(
                        <GroupedByTableRow key={project.tenantId}>
                            <TableCell colSpan={7}>
                                <GroupedByIcon src={project.tenant?.logo || icons.tenantIcon} />
                                <GroupedByTitle> {project.tenant?.name}</GroupedByTitle>
                            </TableCell>
                        </GroupedByTableRow>
                    );
                }
                rows.push(
                    <TableRow key={project._id} id={`${project._id}`}>
                        {/* PROJECTS ICON TABLE CELL */}
                        {!isSmallMobile && (
                            <ImageTableCell
                                shape={'square'}
                                imageSize={{ width: 48, height: 48 }}
                                src={project.logo || icons.projectIcon1}
                            />
                        )}

                        {/* PROJECTS NAME TABLE CELL */}
                        <WidthTableCell {...ProjectsTableSizes.name}>
                            <NameWrapper>
                                <TruncatedText>{project.name}</TruncatedText>
                            </NameWrapper>
                            {project?.description && (
                                <CategoryRow>
                                    <TruncatedText>{project.description}</TruncatedText>
                                </CategoryRow>
                            )}
                        </WidthTableCell>

                        {/* KEY TABLE CELL */}
                        <WidthTableCell {...ProjectsTableSizes.key}>{project.key}</WidthTableCell>

                        <WidthTableCell {...ProjectsTableSizes.projectLeads}>
                            <ProjectLeadsContainer>{buildProjectLeads(project.projectLeads || [])}</ProjectLeadsContainer>
                        </WidthTableCell>

                        {/* LAST MODIFIED TABLE CELL */}
                        {!isMobile && <WidthTableCell {...ProjectsTableSizes.lastModified}>{lastModified}</WidthTableCell>}

                        {/* ACTIONS TABLE CELL */}
                        <WidthTableCell {...ProjectsTableSizes.actions}>
                            <ActionsTableCell
                                tooltipTexts={{
                                    delete: 'projects_icon_delete',
                                    enable: 'projects_icon_enable'
                                }}
                                actions={renderActions()}
                                onRemove={() => handleDeleteIconClick(project._id)}
                                onEnable={() => handleEnableIconClick(project._id)}
                            />
                        </WidthTableCell>
                    </TableRow>
                );
            });

        return <TableBody>{rows}</TableBody>;
    };
    const buildProjectLeads = (projectLeads: any[]) => {
        return projectLeads.map((lead, index) => {
            return (
                <Tooltip key={index} title={lead.fullName} placement="right-start">
                    <UserAvatar background={lead.icon || icons.userIcon} />
                </Tooltip>
            );
        });
    };

    const renderNoDisabledProjects = () => <div>There are no disabled projects</div>;
    const renderDisabledProjects = () => (
        <GenericTable body={buildTableBody()} columns={buildTableColumns()} sortableColumns={['name', 'key', 'lead', 'type']} />
    );

    const isEmpty = !projects.length;

    return (
        <>
            {error && <BackendErrorDialog error={error} />}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle loading={loading} title="Disabled Projects" withProfile withoutSearch />
                    <SearchBarContainer>
                        <SearchBar
                            title={'Search Name'}
                            disabled={loading}
                            searchTerm={searchTerm}
                            onSearch={handleOnSearch}
                            setSearchTerm={setSearchTerm}
                            tooltipText={'projects_icon_search'}
                        />
                    </SearchBarContainer>
                    {loading ? <Loader title={'Disabled Projects'} /> : isEmpty ? renderNoDisabledProjects() : renderDisabledProjects()}
                </MainContentWrapper>
            </ApplicationWrapper>
        </>
    );
};

export default DisabledProjects;
