import { FC, useRef, useState, useEffect } from 'react';
import { VideoContainer, FileIconContainer, VideoTooltip, VideoWrapper } from './FileManager.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../assets/images/icons';
import { renderTooltipWithKey } from '../common/Tooltips/Tooltips';

type PlayerProps = {
    src: string;
    extension: string;
    withIcon?: boolean;
    withControls?: boolean;
    autoPlay?: boolean;
    isMuted?: boolean;
    loop?: boolean;
    tableView?: boolean;
};

const supportedFormats = { mov: 'quicktime', mp4: 'mp4', webm: 'webm' };

export const Player: FC<PlayerProps> = ({ src, extension, autoPlay, withControls, isMuted, loop, withIcon, tableView }) => {
    const playerRef = useRef<HTMLVideoElement>(null);
    const [isNotSupported, setIsNotSupported] = useState<boolean>(false);

    const onPlay = () => {
        if (!playerRef.current || playerRef.current.readyState === playerRef.current.HAVE_NOTHING) return;
        playerRef.current.play();
    };

    const onPause = () => {
        if (!playerRef.current || playerRef.current.readyState === playerRef.current.HAVE_NOTHING) return;
        playerRef.current.pause();
    };

    useEffect(() => {
        setIsNotSupported(!Object.keys(supportedFormats).includes(extension));
    }, [extension]);

    return (
        <VideoContainer
            onMouseEnter={() => {
                if (!withIcon) return;
                onPlay();
            }}
            onMouseLeave={() => {
                if (!withIcon) return;
                onPause();
            }}
        >
            {isNotSupported && (
                <VideoTooltip>
                    {' '}
                    {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'file_manager_player_icon_unsupported_format')}
                </VideoTooltip>
            )}

            <VideoWrapper ref={playerRef} controls={!!withControls} autoPlay={!!autoPlay} muted={!!isMuted} loop={!!loop}>
                {Object.values(supportedFormats).map((format) => (
                    <source key={`source_${format}`} src={src} type={`video/${format}`} />
                ))}
            </VideoWrapper>

            {withIcon && (
                <FileIconContainer tableView={tableView}>
                    <SVGInline src={icons.videoIcon} />
                </FileIconContainer>
            )}
        </VideoContainer>
    );
};
