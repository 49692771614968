import { PlacedObjectType } from './Object';
import { Project } from './Project';
import { Tenant } from './Tenant';

export type PageStyle = {
    _id: string;
    name: string;
    tenantId: string;
    projectId: string;
    tenant?: Tenant;
    project?: Project;
    colors?: PageStyleColors;
    increasedTopMargin?: boolean;
    pageLogo?: DeviceClasses;
    pageBackgroundImage?: DeviceClasses;
    contentMarkers?: any;
    isDefault?: boolean;
    pages?: { pageId?: string; name?: string }[];
    lastModified?: number;
    modifiedBy?: string;
    modifiedByUser?: {
        name: string;
        icon?: string;
    };

    locked?: string;
    lockedByUser?: {
        name?: string;
        icon?: string;
    };
    placed?: { name: string; _id: string; type: PlacedObjectType }[];
};

export type DeviceClasses = {
    bigScreen?: string;
    mobile?: string;
    pc?: string;
    tablet?: string;
};

export type PageStyleColors = {
    primary?: string;
    background?: string;
    foreground?: string;
    body?: string;
    black?: string;
    white?: string;
    error?: string;
    success?: string;
    warning?: string;
};

export const availableColors = ['primary', 'background', 'foreground', 'body', 'black', 'white', 'error', 'success', 'warning'];

export const defaultColorValues: PageStyleColors = {
    primary: '#0066FF',
    background: '#000000',
    foreground: '#1A1A1A',
    body: '#EEEEEE',
    black: '#000000',
    white: '#FFFFFF',
    error: '#FF3333',
    success: '#44BB44',
    warning: '#FFBB00'
};

export type PageStyleTemplateValues = Pick<
    PageStyle,
    'name' | 'colors' | 'increasedTopMargin' | 'pageLogo' | 'pageBackgroundImage' | 'contentMarkers'
>;
