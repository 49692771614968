import React, { FC } from 'react';
import { LoaderContainer, LoaderText, ProgressBar, WrapperBar } from './Loader.css';

export type LoaderProps = {
    title?: string;
    lightTitle?: boolean;
};
export const Loader: FC<LoaderProps> = ({ title, lightTitle }) => {
    return (
        <LoaderContainer>
            <LoaderText whiteTitle={lightTitle}>{title ? `Loading ${title}` : 'Loading'}</LoaderText>
            <WrapperBar>
                <ProgressBar />
            </WrapperBar>
        </LoaderContainer>
    );
};
