import React, { FC, useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import _ from 'lodash';

import { TruncatedText } from '../../../style/styled-components/reusable.css';
import GenericDialog, { DialogButton, DialogTypes } from './GenericDialog';
import { UserAvatar } from '../../Projects/Projects.css';
import {
    ActivityDialogObjectName,
    ActivityFieldStatus,
    ActivityDialogInfo,
    ActivitiesWrapper,
    ActivityContainer,
    ActivityFieldKey,
    ActivityDetails,
    ObjectTypeIcon,
    ActivityField,
    ActivityDate,
    ActivityInfo,
    ActivityType,
    ArrowIcon,
    ChildrenContainer,
    ActivityFieldWrapper,
    ActivitySubField,
    ChildWrapper
} from './ObjectActivityDialog.css';

import { formatObjectActivityKey, RenamedKeysType } from '../../../utils/fnString';
import {
    ActivityTreeNode,
    ObjectActivityType,
    ObjectActivityTypes,
    ObjectType,
    ObjectTypes,
    parsedObjectActivities
} from '../../../types/Object';
import { EMPTY_WORD_STRING, getIconByObjectType } from '../../../utils/Globals';
import { useAppSelector } from '../../../hooks/redux';
import { CIRCLE_SLUGS } from '../HelpIcon/HelpIcon';
import icons from '../../../assets/images/icons';
import SVGInline from 'react-inlinesvg';
import useTranslation from '../../../hooks/useTranslation';
import { ObjectActivityState } from '../../../redux/slices/objectActivitySlice';
import { getDateAndTime } from '../../../utils/fnDate';

export type ObjectActivityDialogProps = {
    open: boolean;
    objectTitle: string;
    onClose: () => void;
    objectType: ObjectType;
};

const ObjectActivityDialog: FC<ObjectActivityDialogProps> = ({ open, objectType, objectTitle, onClose }) => {
    const { objectActivity, loading }: ObjectActivityState = useAppSelector((state) => state.objectActivity);
    const { translate } = useTranslation();
    const [showActivityDetails, setShowActivityDetails] = useState<string>('');
    const [objectActivities, setObjectActivities] = useState<parsedObjectActivities | undefined>(undefined);

    const handleArrowClick = (index: string) => {
        setShowActivityDetails((prevOpen) => (prevOpen === index ? '' : index));
    };

    const handleOnClose = () => {
        setShowActivityDetails('');
        setObjectActivities(undefined);
        onClose();
    };

    const closeButton: DialogButton = {
        type: 'BLUE',
        label: 'Close',
        onClick: handleOnClose
    };

    useEffect(() => {
        setObjectActivities(objectActivity);
    }, [objectActivity]);

    const renderExpandedActivity = (activities: ActivityTreeNode[], index: string) => {
        if (showActivityDetails !== index) return <></>;

        return activities.map((activity) => (
            <ActivityDetails key={activity.objectId}>
                {activity.objectType !== objectType ? (
                    <ActivityFieldWrapper>{renderChildActivity(activity)}</ActivityFieldWrapper>
                ) : (
                    <>
                        {activity.updatedKeys?.map((key: any) => (
                            <ActivityField key={key}>
                                <ActivityFieldKey>
                                    {formatObjectActivityKey(activity.objectType as keyof RenamedKeysType, key) || ''}
                                </ActivityFieldKey>
                                <ActivityFieldStatus>Updated</ActivityFieldStatus>
                            </ActivityField>
                        ))}
                        {renderChildrenTree(activity?.children || [], true)}
                    </>
                )}
            </ActivityDetails>
        ));
    };

    const renderChildActivity = (activity: ActivityTreeNode) => {
        return (
            <ChildWrapper>
                <ActivitySubField>
                    <ActivityFieldKey>
                        <ObjectTypeIcon>{getIconByObjectType(activity.objectType as ObjectType)}</ObjectTypeIcon>
                        <TruncatedText>{translate(activity.objectName) || EMPTY_WORD_STRING}</TruncatedText>
                    </ActivityFieldKey>
                    <ActivityFieldStatus>Updated</ActivityFieldStatus>
                </ActivitySubField>
                <ChildrenContainer>
                    {activity.updatedKeys?.map((key) => (
                        <ChildWrapper key={key}>
                            <ActivitySubField>
                                <ActivityFieldKey>
                                    {formatObjectActivityKey(activity.objectType as keyof RenamedKeysType, key) || ''}
                                </ActivityFieldKey>
                                <ActivityFieldStatus>Updated</ActivityFieldStatus>
                            </ActivitySubField>
                        </ChildWrapper>
                    ))}
                    {renderChildrenTree(activity.children || [])}
                </ChildrenContainer>
            </ChildWrapper>
        );
    };

    const renderChildrenTree = (activities: ActivityTreeNode[], withWrapper?: boolean) => {
        if (!activities.length) return <></>;

        return withWrapper ? (
            <ActivityFieldWrapper> {activities.map((activity) => renderChildActivity(activity))}</ActivityFieldWrapper>
        ) : (
            activities.map((activity) => renderChildActivity(activity))
        );
    };

    const renderActivities = () => {
        return Object.entries(objectActivities || {}).map(([timestamp, values]) => {
            const types = Object.keys(values);

            return (types as Array<ObjectActivityType>).map((type, index) => {
                if (!values?.[type]) return <></>;
                const user = values[type]?.[0]?.createdByUser;
                const activities = values[type] || [];
                const isOpen = showActivityDetails === timestamp;
                return (
                    <ActivityContainer key={`${type}_${timestamp}_${index}`}>
                        <ActivityInfo
                            $withArrow={type === ObjectActivityTypes.UPDATED}
                            $open={isOpen}
                            onClick={() => {
                                if (type !== ObjectActivityTypes.UPDATED) return;
                                handleArrowClick(timestamp);
                            }}
                        >
                            <ActivityType>{_.capitalize(type)}</ActivityType>
                            <Tooltip title={user?.name || ''} placement="top">
                                <UserAvatar background={user?.icon || icons.userIcon} />
                            </Tooltip>
                            <ActivityDate>{getDateAndTime(parseInt(timestamp))}</ActivityDate>
                            {type === ObjectActivityTypes.UPDATED && (
                                <ArrowIcon>
                                    <SVGInline src={isOpen ? icons.arrowUpIcon : icons.arrowDownIcon} />
                                </ArrowIcon>
                            )}
                        </ActivityInfo>
                        {renderExpandedActivity(activities, timestamp)}
                    </ActivityContainer>
                );
            });
        });
    };

    if (!open || loading) return null;

    return (
        <GenericDialog
            onClose={handleOnClose}
            type={DialogTypes.Form}
            title={'Activity Log'}
            actionButtons={[closeButton]}
            circlesSlugOptions={{
                default:
                    objectType === ObjectTypes.DYNAMIC_SOURCES
                        ? CIRCLE_SLUGS['sources']
                        : objectType === ObjectTypes.PAGE_STYLES
                        ? CIRCLE_SLUGS['style_and_branding']
                        : CIRCLE_SLUGS[objectType as keyof typeof CIRCLE_SLUGS]
            }}
        >
            <ActivityDialogObjectName>
                <TruncatedText>{objectTitle}</TruncatedText>
            </ActivityDialogObjectName>
            <ActivityDialogInfo>Last 7 Days</ActivityDialogInfo>

            <ActivitiesWrapper>
                {!objectActivities || _.isEmpty(objectActivities) ? (
                    <ActivityDialogInfo>No activity in the last 7 days</ActivityDialogInfo>
                ) : (
                    renderActivities()
                )}
            </ActivitiesWrapper>
        </GenericDialog>
    );
};

export default ObjectActivityDialog;
