import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { TranslationsInputWrapper } from '../../Languages/Languages.css';

const theme: any = getTheme();

export const ErrorContainer = styled.div`
    margin-top: 4px;
    ${fontSizesCSS.Caption3};
    color: ${theme.palette.error.main};
    white-space: pre-line;
`;

export const AbTestingGroupPercentageInputWrapper = styled(TranslationsInputWrapper)`
    gap: 0;
    .MuiFormControl-root {
        width: 32px;
    }
`;

export const AbTestingPercentageInputWrapper = styled.div<{ $disabled?: boolean }>`
    ${(props) => props.$disabled && ' opacity: 0.5'};
`;

export const AbTestingGroupNameInputWrapper = styled(TranslationsInputWrapper)``;

export const ABTestingStatusWrapper = styled.div`
    box-sizing: border-box;
    background: ${theme.palette.background.pressed};
    border: 1px solid ${theme.palette.background.pressed};
    border-radius: 3px;
    height: 60px;
    align-items: center;
    width: 100%;
    padding: 12px 24px;
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
`;

export const ABTestingStatusButton = styled.div`
    padding: 0 2px;
`;

export const AbTestingIconActiveWrapper = styled.div<{ $editing?: boolean }>`
    width: 26px;
    height: 26px;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-left: 2px;
    ${({ $editing }) =>
        !$editing
            ? `
        cursor: pointer;
        background: ${theme.palette.primary.main}`
            : `
            border: 1px solid ${theme.palette.background.hovered};
            svg {
                path {
                    fill: ${theme.palette.foreground.main};
                    opacity: 0.5;
                }
            }
            `};
`;

export const AbTestingIconOngoingWrapper = styled.div<{ $paused?: boolean }>`
    width: 53px;
    height: 26px;
    display: flex;
    gap: 1px;
    align-items: center;
    box-sizing: border-box;
    div {
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        background: ${({ $paused }) => ($paused ? theme.palette.warning.main : theme.palette.success.increase)};
        cursor: pointer;
    }
    & > :first-child {
        border-radius: 26px 0 0 26px;
        padding-left: 2px;
    }
    & > :not(:first-child) {
        border-radius: 0 26px 26px 0;
        padding-right: 2px;
    }
`;

export const ABTestingStatusInfo = styled.div`
    flex-grow: 1;
    & > :first-child {
        ${fontSizesCSS.Caption2};
    }
    & > :not(:first-child) {
        ${fontSizesCSS.Caption3};
    }
`;
