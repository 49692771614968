import { Tooltip } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { LabelContainer, NoResourcesContainer, ObjectNameLabel, PageActionsWrapper } from '../../../style/styled-components/reusable.css';
import { generateDateStringForTables } from '../../../utils/fnDate';
import { ResourceCard } from '../../Cards/ResourceCard/ResourceCard';
import {
    AppBrandingsConditionsView,
    AppBrandingsHolder,
    BrandingContainer,
    BrandingViewName,
    LastModifiedBrandings,
    SmallScreenFirstRow,
    SmallScreenSecondRow,
    SmallScreenThirdRow
} from './AppBranding.css';
import icons from '../../../assets/images/icons';
import { DialogFileField, DialogTextField, DialogToggleButton } from '../../common/Dialog/GenericDialog';
import { fetchAudiences } from '../../../redux/slices/audienceSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import Button from '../../Buttons/Button/Button';
import PageActions from '../../common/PageActions/PageActions';
import { AppBranding } from '../../../types/AppBranding';
import {
    appBrandingsState,
    createAppBranding,
    deleteAppBranding,
    fetchAppBrandings,
    updateAppBranding
} from '../../../redux/slices/appBrandingsSlice';
import { setUserPermissions } from '../../../redux/slices/permissionsSlice';
import { MoreInfoDialog, MoreInfoTypes } from '../../common/Dialog/MoreInfoDialog';
import { DIALOG_NAMES, dialogConfirm } from '../../../utils/fnDialogs';
import { Loader } from '../../common/Loader/Loader';
import { ImageFieldContainer, ImageFields, ImageHeader } from '../PageStyle/PageStyle.css';
import { supportedResolutions, supportedResolutionsType } from '../../Items/Dialogs/BackgroundDialog';
import { EMPTY_WORD_STRING, getImgixUrl, imgixDefaultQuery } from '../../../utils/Globals';
import { DisplayConditionsState, fetchDisplayConditions } from '../../../redux/slices/displayConditionsSlice';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { resourceCardImages } from '../../../assets/images/resourceCards';
import useBlockNavigation from '../../../hooks/useBlockNavigation';
import { FilesState, uploadFilesSync } from '../../../redux/slices/fileManagerSlice';
import useScreenSize from '../../../hooks/useScreenSize';
import { extractFileNameFromAzureURL, isOpenFileUrl } from '../../../utils/fnUrl';
import { TargetConditionsSelect } from '../../common/Select/TargetConditionsSelect';
import circleSlugs from '../../../utils/circle_slugs.json';
import { openDocumentation } from '../../../utils/parsers';
import { ObjectActions } from '../../common/Actions/Actions';
import { tableActions } from '../../common/Table/Table';
import { pageStyleImageInfo } from '../PageStyle/PageStyle';

export const APP_BRANDING_ADD_BUTTON_ID = 'APP_BRANDING_ADD_BUTTON_ID';

const AppBrandings: React.FC = () => {
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);

    const [showMoreDialog, setShowMoreDialog] = useState<{ type: MoreInfoTypes; show: boolean; data: any[] }>({
        type: MoreInfoTypes.TARGETS,
        show: false,
        data: []
    });

    // APP BRANDINGS
    const { appBrandings: storeAppBrandings, loading, error }: appBrandingsState = useAppSelector((state) => state.appBrandings);
    const { error: imageError }: FilesState = useAppSelector((state) => state.files);
    const { config } = useAppSelector((state) => state.config);
    const [filteredAppBrandings, setFilteredAppBrandings] = useState<AppBranding[] | null>(null);

    // Hold the AppBranding object which is being worked on
    const [appBrandingToEdit, setAppBrandingToEdit] = useState<any | undefined>(undefined);
    // Tracks whether the top values can be edited or not
    const [isEditing, setIsEditing] = useState(false);

    const [creatingAppBranding, setCreatingAppBranding] = useState(false);

    const [showUnsaved, setShowUnsaved] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState('');

    //IMAGES
    const [applyForAll, setApplyForAll] = useState<{
        appLogo?: boolean;
        landingScreenBackground?: boolean;
        loginScreenBackground?: boolean;
        welcomeScreenBackground?: boolean;
    }>({
        appLogo: true,
        landingScreenBackground: true,
        loginScreenBackground: true,
        welcomeScreenBackground: true
    });
    const [imageFiles, setImageFiles] = useState<{
        appLogo?: supportedResolutionsType;
        landingScreenBackground?: supportedResolutionsType;
        loginScreenBackground?: supportedResolutionsType;
        welcomeScreenBackground?: supportedResolutionsType;
    }>({
        appLogo: {},
        landingScreenBackground: {},
        loginScreenBackground: {},
        welcomeScreenBackground: {}
    });
    const [imageUrls, setImageUrls] = useState<{
        appLogo?: supportedResolutionsType;
        landingScreenBackground?: supportedResolutionsType;
        loginScreenBackground?: supportedResolutionsType;
        welcomeScreenBackground?: supportedResolutionsType;
    }>({
        appLogo: {},
        landingScreenBackground: {},
        loginScreenBackground: {},
        welcomeScreenBackground: {}
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location: any = useLocation();

    // These variables are used when appBranding is created into a group
    const redirectAfterSave = location?.state?.redirectTo;
    const preselectedConditions = location?.state?.conditions; // targetGroup audiences
    const editAppBrandingId = location?.state?.editingId;

    const { isSmallMobile, isTablet, isDesktop } = useScreenSize();
    const { loading: conditionsLoading }: DisplayConditionsState = useAppSelector((state) => state.displayConditions);

    const unsavedDependencyArray = [creatingAppBranding, appBrandingToEdit, applyForAll];

    // Blocking navigation when there are unsaved changes
    useBlockNavigation(showUnsaved, () => renderAlertUnsavedChanges(), unsavedDependencyArray);

    const loadAppBrandings = async (addPermissions?: boolean, projectId?: string) => {
        return await dispatch(fetchAppBrandings({ addPermissions, projectId })).unwrap();
    };

    const saveAppBranding = async (newAppBranding: AppBranding) => {
        const response = await dispatch(createAppBranding(newAppBranding)).unwrap();
        if (response.id) {
            // SnackBarAlert(DIALOG_NAMES.APP_BRANDING_CREATED, 'bottom', 'right', null, true);
            if (!redirectAfterSave) return loadAppBrandings(false, activeProjectId);
            navigate(redirectAfterSave, { state: { appBrandingId: response.id } });
        }
    };

    const modifyAppBranding = async (newAppBranding: AppBranding) => {
        const response = await dispatch(updateAppBranding(newAppBranding)).unwrap();
        if (response.ok) {
            //SnackBarAlert(DIALOG_NAMES.APP_BRANDING_UPDATED, 'bottom', 'right', null, true);
            if (!redirectAfterSave) return loadAppBrandings(false, activeProjectId);
            navigate(redirectAfterSave);
        }
    };

    const removeAppBranding = async (id: string) => {
        const response = await dispatch(deleteAppBranding(id)).unwrap();
        if (response.ok) {
            loadAppBrandings(false, activeProjectId);
            //return SnackBarAlert(DIALOG_NAMES.APP_BRANDING_DELETED, 'bottom', 'right', null, true);
        }
    };

    const loadAudiences = async () => {
        return await dispatch(fetchAudiences({ addPermissions: false, projectId: activeProjectId }));
    };

    const loadConditions = async () => {
        return await dispatch(fetchDisplayConditions({ addPermissions: false, projectId: activeProjectId }));
    };

    const createFiles = async (files: File[]) => {
        const prefix = `${activeProjectId}/appBranding_backgrounds`;
        try {
            const response = await dispatch(uploadFilesSync({ files, prefix, overwrite: true })).unwrap();
            return response.urls;
        } catch (ex) {
            return [];
        }
    };

    useEffect(() => {
        if (!redirectAfterSave || loading) return;
        //if the appBranding  opens from the group creating it needs to be in the creating mode
        if (editAppBrandingId) {
            expandAppBranding(editAppBrandingId);
            setIsEditing(true);
            document.getElementById(editAppBrandingId)?.scrollIntoView();
        } else {
            handleCreateClick();
            document.getElementById('create-app-branding-container')?.scrollIntoView();
            preselectedConditions?.length &&
                setAppBrandingToEdit({
                    conditionIds: preselectedConditions
                });
        }
    }, [redirectAfterSave, editAppBrandingId, preselectedConditions, loading]);

    useEffect(() => {
        // when changing between projects or onLoad, we cancel any editing state, unless we got redirected from Target-Groups
        if (!redirectAfterSave && !editAppBrandingId) {
            handleCancelClick();
        }
        setSearchTerm('');

        if (!activeProjectId) return;
        loadAppBrandings(true, activeProjectId).then((response: any) => {
            if (response.permissions) {
                setUserPermissions(response.permissions);
            }
        });

        loadAudiences();
        loadConditions();
    }, [activeProjectId]);

    const calculateApplyForAll = (appBranding: any, key: string) => {
        const values: any[] = Object.values({ ...appBranding?.[key] });
        if (!values.length) return true;
        const firstUrl = decodeURIComponent(values[0]);
        const isOpenUrl = isOpenFileUrl(config.imgixBaseUrl, firstUrl);
        const firstFileName = !isOpenUrl ? firstUrl.split(config.imgixBaseUrl)[1].split('?')[0] : firstUrl.split('/').pop();
        return (
            !values
                .filter((_, index) => index > 0)
                .some((value) => {
                    const url = decodeURIComponent(value);
                    const isOpenUrl = isOpenFileUrl(config.imgixBaseUrl, url);
                    const fileName = !isOpenUrl ? url.split(config.imgixBaseUrl)[1].split('?')[0] : url.split('/').pop();
                    return fileName !== firstFileName;
                }) && values.length === 4
        );
    };

    const expandAppBranding = (id: string) => {
        if (id === appBrandingToEdit?._id) {
            setIsEditing(false);
            setAppBrandingToEdit(undefined);
            return;
        }
        const appBranding = storeAppBrandings?.find((appBranding) => appBranding._id === id);
        setAppBrandingToEdit(_.cloneDeep(appBranding));
        setApplyForAll({
            appLogo: calculateApplyForAll(appBranding, 'appLogo'),
            landingScreenBackground: calculateApplyForAll(appBranding, 'landingScreenBackground'),
            loginScreenBackground: calculateApplyForAll(appBranding, 'loginScreenBackground'),
            welcomeScreenBackground: calculateApplyForAll(appBranding, 'welcomeScreenBackground')
        });
    };

    useEffect(() => {
        setShowUnsaved(checkUnsaved());
    }, [creatingAppBranding, appBrandingToEdit, applyForAll]);

    const checkUnsaved = () => {
        if (creatingAppBranding) return true;
        if (!appBrandingToEdit) return false;

        const oldAppBranding = storeAppBrandings.find((appBranding) => appBranding._id === appBrandingToEdit?._id);

        return !_.isEqual(oldAppBranding, appBrandingToEdit);
    };

    const renderAlertUnsavedChanges = () => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => {
                handleSaveClick();
            },
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                handleCancelClick();
            },
            true
        );
    };

    const handleCreateClick = () => {
        setCreatingAppBranding(true);
        setIsEditing(true);
        setAppBrandingToEdit({ _id: '-1' });
        setApplyForAll({
            appLogo: true,
            landingScreenBackground: true,
            loginScreenBackground: true,
            welcomeScreenBackground: true
        });
    };

    const handleDuplicateClick = (appBranding: AppBranding) => {
        setCreatingAppBranding(true);
        setAppBrandingToEdit({ ..._.cloneDeep(appBranding), _id: '-1' });
        setApplyForAll({
            appLogo: calculateApplyForAll(appBranding, 'appLogo'),
            landingScreenBackground: calculateApplyForAll(appBranding, 'landingScreenBackground'),
            loginScreenBackground: calculateApplyForAll(appBranding, 'loginScreenBackground'),
            welcomeScreenBackground: calculateApplyForAll(appBranding, 'welcomeScreenBackground')
        });
        setIsEditing(true);
    };

    const handleDeleteClick = (id: string) => {
        const values = {
            title: 'Remove App Branding',
            text: 'Are you sure you want to remove this App Branding?'
        };

        dialogConfirm(
            '',
            () => {
                removeAppBranding(id);
            },
            values,
            null,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            }
        );
    };

    const handleCancelClick = () => {
        setCreatingAppBranding(false);
        setAppBrandingToEdit(undefined);
        setIsEditing(false);
        // Clearing the history.state to prevent redirection if the user discards the changes to the given object
        setTimeout(() => window.history.replaceState({}, ''), 100);
    };

    const handleSaveClick = async () => {
        const appBrandingToSave: any = {
            ...appBrandingToEdit,
            _id: appBrandingToEdit?._id === '-1' ? '' : appBrandingToEdit?._id || '',
            name: appBrandingToEdit?.name || '',
            tenantId: activeTenantId || '',
            projectId: activeProjectId || '',
            conditionIds: appBrandingToEdit?.conditionIds || []
        };
        const imagesResult = await handleImages();
        if (imagesResult !== null) {
            Object.entries(imagesResult).forEach(([key, imageValues]) => {
                if (!imageValues) return;
                _.set(appBrandingToSave, key, imageValues);
            });
        }
        handleCancelClick();
        await (creatingAppBranding ? saveAppBranding(appBrandingToSave) : modifyAppBranding(appBrandingToSave));
    };

    // IMAGES
    const handleImageSelect = (
        image: File | string,
        parentKey: 'appLogo' | 'loginScreenBackground' | 'landingScreenBackground' | 'welcomeScreenBackground',
        key: keyof supportedResolutionsType
    ) => {
        let newImages: any = { ...imageFiles };
        let newUrls: any = { ...imageUrls };
        let newAppBranding: any = { ...appBrandingToEdit };
        //Deleting Image
        if (!image) {
            if (applyForAll[parentKey]) {
                newImages = _.set(newImages, parentKey, {});
                newUrls = _.set(newUrls, parentKey, {});
                newAppBranding = _.set(newAppBranding, parentKey, {});
            } else {
                newImages[parentKey] = _.omit(newImages[parentKey], key);
                newUrls[parentKey] = _.omit(newUrls[parentKey], key);
                newAppBranding[parentKey] = _.omit(newAppBranding[parentKey], key);
            }
        } else {
            //Adding Image
            if (typeof image === 'string') {
                const isOpenUrl = !image.includes(`${activeProjectId}`);
                _.set(newUrls, `${parentKey}.${key}`, isOpenUrl ? image : getImgixUrl(config.imgixBaseUrl, image));
                _.set(newImages, `${parentKey}.${key}`, isOpenUrl ? image : getImgixUrl(config.imgixBaseUrl, image));
                _.set(newAppBranding, `${parentKey}.${key}`, isOpenUrl ? image : getImgixUrl(config.imgixBaseUrl, image));
            } else {
                _.set(newImages, `${parentKey}.${key}`, image);
                _.set(newAppBranding, `${parentKey}.${key}`, image);
            }
        }
        setImageFiles(newImages);
        setImageUrls(newUrls);
        setAppBrandingToEdit(newAppBranding);
    };

    const handleImages = async () => {
        const result: { [key: string]: any } = {};
        for (const key in imageFiles) {
            result[key] = [];
            let imgixUrls: any = {};
            const imgUrls = _.get(imageUrls, key);
            const imageUrl = `${Object.values(imgUrls).find((elem) => elem) || ''}`;
            if (imageUrl) {
                // if there is an imageUrl, it means we are not uploading files but picking urls
                if (_.get(applyForAll, key)) {
                    const isOpenUrl = isOpenFileUrl(config.imgixBaseUrl, imageUrl);
                    Object.values(supportedResolutions).forEach((value, index) => {
                        if (isOpenUrl) {
                            // if it's openUrl, just replicate the same url for each key
                            imgixUrls[Object.keys(supportedResolutions)[index]] = encodeURIComponent(imageUrl);
                            return;
                        }
                        const [width, height] = value.split('x');
                        const query = `&width=${width}&height=${height}`;
                        // if picked by the FM, it already is a imgix url so just append the width/height query
                        imgixUrls[Object.keys(supportedResolutions)[index]] = encodeURIComponent(`${imageUrl}${query}`);
                    });
                    result[key] = imgixUrls;
                    continue;
                }
                result[key] = imgUrls;
                continue;
            }
            const files = _.get(imageFiles, key);
            const imagesToUpload = Object.values(files).filter((image): image is File => typeof image !== 'undefined');
            if (!imagesToUpload.length) {
                // if there is not value for a key, push it's initial value or null
                result[key] = appBrandingToEdit?.[key] || null;
                continue;
            }

            const urls = await createFiles(imagesToUpload);
            if ((applyForAll as any)?.[key]) {
                // if apply for all, generate imgix urls for the save picture, but use width and height in query
                const fileName = extractFileNameFromAzureURL(config.AZURE_CONFIG.account, config.AZURE_ACCOUNT.container, urls[0]);
                Object.values(supportedResolutions).forEach((value, index) => {
                    const [width, height] = value.split('x');
                    const query = `${imgixDefaultQuery}&width=${width}&height=${height}`;
                    imgixUrls[Object.keys(supportedResolutions)[index]] = encodeURIComponent(
                        getImgixUrl(config.imgixBaseUrl, fileName, query)
                    );
                });
            } else {
                Object.keys(files).forEach((key, index) => {
                    // if not apply for all, still generate imgix url, but the resolution is kept as inputed
                    const fileName = extractFileNameFromAzureURL(config.AZURE_CONFIG.account, config.AZURE_ACCOUNT.container, urls[index]);
                    imgixUrls[key] = encodeURIComponent(getImgixUrl(config.imgixBaseUrl, fileName));
                });
            }
            result[key] = imgixUrls;
        }
        return result;
    };

    const resetImageKeys = (parentKey: 'appLogo' | 'loginScreenBackground' | 'landingScreenBackground' | 'welcomeScreenBackground') => {
        let newImages: any = { ...imageFiles };
        let newAppBranding: any = { ...appBrandingToEdit };
        let newUrls: any = { ...imageUrls };
        newImages = _.set(newImages, parentKey, {});
        newAppBranding = _.set(newAppBranding, parentKey, {});
        newUrls = _.set(newUrls, parentKey, {});
        setImageFiles(newImages);
        setAppBrandingToEdit(newAppBranding);
        setImageUrls(newUrls);
    };

    const renderNoAppBranding = () => {
        return (
            <NoResourcesContainer>
                <ResourceCard
                    image={resourceCardImages.appBrandingsNewCard}
                    title={'Create App Branding'}
                    subtitle={'Create an App Branding Object'}
                    primaryButtonLabel={'Create App Branding'}
                    secondaryButtonLabel={'Learn more'}
                    onPrimaryButtonClick={() => {
                        setCreatingAppBranding(true);
                    }}
                    onSecondaryButtonClick={() => {
                        openDocumentation(circleSlugs.app_branding);
                    }}
                    createResource
                />
            </NoResourcesContainer>
        );
    };

    const renderExpandedAppBrandingSection = (appBranding: AppBranding) => {
        return (
            <>
                {/*APP LOGO*/}
                <ImageFieldContainer>
                    <ImageHeader>App Logo</ImageHeader>
                    <ImageFields>
                        <DialogFileField
                            imageInfo={pageStyleImageInfo}
                            customAddIcon={icons.addIconWhite}
                            preview={appBranding?.appLogo?.bigScreen}
                            fieldKey={'Big Screen (1920x1080)'}
                            customLabel={applyForAll.appLogo ? 'All Screens' : 'Big Screen (1920x1080)'}
                            localFileCallBack={(file) => {
                                handleImageSelect(file, 'appLogo', 'bigScreen');
                            }}
                            fileManagerCallback={(url: string) => handleImageSelect(url, 'appLogo', 'bigScreen')}
                            openUrlCallback={(url: string) => handleImageSelect(url, 'appLogo', 'bigScreen')}
                            alwaysShowLabel
                        />
                        {!applyForAll.appLogo && (
                            <>
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={appBranding?.appLogo?.pc}
                                    fieldKey={'PC (1280x720)'}
                                    customLabel={'PC (1280x720)'}
                                    localFileCallBack={(file) => {
                                        handleImageSelect(file, 'appLogo', 'pc');
                                    }}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'appLogo', 'pc')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'appLogo', 'pc')}
                                    alwaysShowLabel
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={appBranding?.appLogo?.tablet}
                                    fieldKey={'Tablet (960x540)'}
                                    customLabel={'Tablet (960x540)'}
                                    localFileCallBack={(file) => {
                                        handleImageSelect(file, 'appLogo', 'tablet');
                                    }}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'appLogo', 'tablet')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'appLogo', 'tablet')}
                                    alwaysShowLabel
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={appBranding?.appLogo?.mobile}
                                    fieldKey={'Mobile (360x202)'}
                                    customLabel={'Mobile (360x202)'}
                                    localFileCallBack={(file) => {
                                        handleImageSelect(file, 'appLogo', 'mobile');
                                    }}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'appLogo', 'mobile')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'appLogo', 'mobile')}
                                    alwaysShowLabel
                                />
                            </>
                        )}
                    </ImageFields>
                    <DialogToggleButton
                        checked={!!applyForAll?.appLogo}
                        toggleCallback={() => {
                            setApplyForAll({
                                ...applyForAll,
                                appLogo: !applyForAll?.appLogo
                            });
                            resetImageKeys('appLogo');
                        }}
                        text={'Apply for all screens'}
                    />
                </ImageFieldContainer>
                {/*LANDING SCREEN*/}
                <ImageFieldContainer>
                    <ImageHeader>Landing Screen Background</ImageHeader>
                    <ImageFields>
                        <DialogFileField
                            imageInfo={pageStyleImageInfo}
                            customAddIcon={icons.addIconWhite}
                            preview={appBranding?.landingScreenBackground?.bigScreen}
                            fieldKey={'Big Screen (1920x1080)'}
                            customLabel={applyForAll.landingScreenBackground ? 'All Screens' : 'Big Screen (1920x1080)'}
                            localFileCallBack={(file) => {
                                handleImageSelect(file, 'landingScreenBackground', 'bigScreen');
                            }}
                            fileManagerCallback={(url: string) => handleImageSelect(url, 'landingScreenBackground', 'bigScreen')}
                            openUrlCallback={(url: string) => handleImageSelect(url, 'landingScreenBackground', 'bigScreen')}
                            alwaysShowLabel
                        />
                        {!applyForAll.landingScreenBackground && (
                            <>
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={appBranding?.landingScreenBackground?.pc}
                                    fieldKey={'PC (1280x720)'}
                                    customLabel={'PC (1280x720)'}
                                    localFileCallBack={(file) => {
                                        handleImageSelect(file, 'landingScreenBackground', 'pc');
                                    }}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'landingScreenBackground', 'pc')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'landingScreenBackground', 'pc')}
                                    alwaysShowLabel
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={appBranding?.landingScreenBackground?.tablet}
                                    fieldKey={'Tablet (960x540)'}
                                    customLabel={'Tablet (960x540)'}
                                    localFileCallBack={(file) => {
                                        handleImageSelect(file, 'landingScreenBackground', 'tablet');
                                    }}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'landingScreenBackground', 'tablet')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'landingScreenBackground', 'tablet')}
                                    alwaysShowLabel
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={appBranding?.landingScreenBackground?.mobile}
                                    fieldKey={'Mobile (360x202)'}
                                    customLabel={'Mobile (360x202)'}
                                    localFileCallBack={(file) => {
                                        handleImageSelect(file, 'landingScreenBackground', 'mobile');
                                    }}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'landingScreenBackground', 'mobile')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'landingScreenBackground', 'mobile')}
                                    alwaysShowLabel
                                />
                            </>
                        )}
                    </ImageFields>
                    <DialogToggleButton
                        checked={!!applyForAll?.landingScreenBackground}
                        toggleCallback={() => {
                            setApplyForAll({
                                ...applyForAll,
                                landingScreenBackground: !applyForAll?.landingScreenBackground
                            });
                            resetImageKeys('landingScreenBackground');
                        }}
                        text={'Apply for all screens'}
                    />
                </ImageFieldContainer>
                {/*LOGIN SCREEN*/}
                <ImageFieldContainer>
                    <ImageHeader>Login Screen Background</ImageHeader>
                    <ImageFields>
                        <DialogFileField
                            imageInfo={pageStyleImageInfo}
                            customAddIcon={icons.addIconWhite}
                            preview={appBranding?.loginScreenBackground?.bigScreen}
                            fieldKey={'Big Screen (1920x1080)'}
                            customLabel={applyForAll.loginScreenBackground ? 'All Screens' : 'Big Screen (1920x1080)'}
                            localFileCallBack={(file) => {
                                handleImageSelect(file, 'loginScreenBackground', 'bigScreen');
                            }}
                            fileManagerCallback={(url: string) => handleImageSelect(url, 'loginScreenBackground', 'bigScreen')}
                            openUrlCallback={(url: string) => handleImageSelect(url, 'loginScreenBackground', 'bigScreen')}
                            alwaysShowLabel
                        />
                        {!applyForAll.loginScreenBackground && (
                            <>
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={appBranding?.loginScreenBackground?.pc}
                                    fieldKey={'PC (1280x720)'}
                                    customLabel={'PC (1280x720)'}
                                    localFileCallBack={(file) => {
                                        handleImageSelect(file, 'loginScreenBackground', 'pc');
                                    }}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'loginScreenBackground', 'pc')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'loginScreenBackground', 'pc')}
                                    alwaysShowLabel
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={appBranding?.loginScreenBackground?.tablet}
                                    fieldKey={'Tablet (960x540)'}
                                    customLabel={'Tablet (960x540)'}
                                    localFileCallBack={(file) => {
                                        handleImageSelect(file, 'loginScreenBackground', 'tablet');
                                    }}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'loginScreenBackground', 'tablet')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'loginScreenBackground', 'tablet')}
                                    alwaysShowLabel
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={appBranding?.loginScreenBackground?.mobile}
                                    fieldKey={'Mobile (360x202)'}
                                    customLabel={'Mobile (360x202)'}
                                    localFileCallBack={(file) => {
                                        handleImageSelect(file, 'loginScreenBackground', 'mobile');
                                    }}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'loginScreenBackground', 'mobile')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'loginScreenBackground', 'mobile')}
                                    alwaysShowLabel
                                />
                            </>
                        )}
                    </ImageFields>
                    <DialogToggleButton
                        checked={!!applyForAll?.loginScreenBackground}
                        toggleCallback={() => {
                            setApplyForAll({
                                ...applyForAll,
                                loginScreenBackground: !applyForAll?.loginScreenBackground
                            });
                            resetImageKeys('loginScreenBackground');
                        }}
                        text={'Apply for all screens'}
                    />
                </ImageFieldContainer>
                {/*WELCOME SCREEN*/}
                <ImageFieldContainer>
                    <ImageHeader>Welcome Screen Background</ImageHeader>
                    <ImageFields>
                        <DialogFileField
                            imageInfo={pageStyleImageInfo}
                            customAddIcon={icons.addIconWhite}
                            preview={appBranding?.welcomeScreenBackground?.bigScreen}
                            fieldKey={'Big Screen (1920x1080)'}
                            customLabel={applyForAll.welcomeScreenBackground ? 'All Screens' : 'Big Screen (1920x1080)'}
                            localFileCallBack={(file) => {
                                handleImageSelect(file, 'welcomeScreenBackground', 'bigScreen');
                            }}
                            fileManagerCallback={(url: string) => handleImageSelect(url, 'welcomeScreenBackground', 'bigScreen')}
                            openUrlCallback={(url: string) => handleImageSelect(url, 'welcomeScreenBackground', 'bigScreen')}
                            alwaysShowLabel
                        />
                        {!applyForAll.welcomeScreenBackground && (
                            <>
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={appBranding?.welcomeScreenBackground?.pc}
                                    fieldKey={'PC (1280x720)'}
                                    customLabel={'PC (1280x720)'}
                                    localFileCallBack={(file) => {
                                        handleImageSelect(file, 'welcomeScreenBackground', 'pc');
                                    }}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'welcomeScreenBackground', 'pc')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'welcomeScreenBackground', 'pc')}
                                    alwaysShowLabel
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={appBranding?.welcomeScreenBackground?.tablet}
                                    fieldKey={'Tablet (960x540)'}
                                    customLabel={'Tablet (960x540)'}
                                    localFileCallBack={(file) => {
                                        handleImageSelect(file, 'welcomeScreenBackground', 'tablet');
                                    }}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'welcomeScreenBackground', 'tablet')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'welcomeScreenBackground', 'tablet')}
                                    alwaysShowLabel
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={appBranding?.welcomeScreenBackground?.mobile}
                                    fieldKey={'Mobile (360x202)'}
                                    customLabel={'Mobile (360x202)'}
                                    localFileCallBack={(file) => {
                                        handleImageSelect(file, 'welcomeScreenBackground', 'mobile');
                                    }}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'welcomeScreenBackground', 'mobile')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'welcomeScreenBackground', 'mobile')}
                                    alwaysShowLabel
                                />
                            </>
                        )}
                    </ImageFields>
                    <DialogToggleButton
                        checked={!!applyForAll?.welcomeScreenBackground}
                        toggleCallback={() => {
                            setApplyForAll({
                                ...applyForAll,
                                welcomeScreenBackground: !applyForAll?.welcomeScreenBackground
                            });
                            resetImageKeys('welcomeScreenBackground');
                        }}
                        text={'Apply for all screens'}
                    />
                </ImageFieldContainer>
            </>
        );
    };

    const renderExistingAppBrandings = () => {
        return (filteredAppBrandings !== null ? filteredAppBrandings : storeAppBrandings)?.map((appBranding: any) => {
            const dateString = generateDateStringForTables(appBranding.lastModified || 0);
            const expanded = appBrandingToEdit?._id === appBranding._id;
            const noOfPagesToShow = isSmallMobile || isDesktop ? 3 : isTablet ? 2 : 1;
            let brandingContainer: JSX.Element | null = null;

            if (isSmallMobile) {
                brandingContainer = (
                    <BrandingContainer key={appBranding._id} id={appBranding._id}>
                        <SmallScreenFirstRow>
                            <LastModifiedBrandings>
                                <span>{dateString}</span>
                                <Tooltip title={appBranding?.modifiedByUser?.name || ''} placement="right-start">
                                    <img src={appBranding?.modifiedByUser?.icon || icons.avatarIcon} alt="" />
                                </Tooltip>
                            </LastModifiedBrandings>
                            <ObjectActions
                                actions={[tableActions.EDIT, tableActions.REMOVE]}
                                withArrow
                                onArrowToggle={() => {
                                    if (showUnsaved) {
                                        return renderAlertUnsavedChanges();
                                    }
                                    expandAppBranding(appBranding._id);
                                }}
                                onEdit={() => {
                                    if (
                                        showUnsaved &&
                                        (appBranding._id !== appBrandingToEdit?._id ||
                                            (isEditing && appBranding._id === appBrandingToEdit?._id))
                                    )
                                        return renderAlertUnsavedChanges();

                                    setIsEditing(appBranding._id === appBrandingToEdit?._id ? !isEditing : true);
                                    appBrandingToEdit?._id !== appBranding._id && expandAppBranding(appBranding._id);
                                    setCreatingAppBranding(false);
                                }}
                                onRemove={() => {
                                    showUnsaved ? renderAlertUnsavedChanges() : handleDeleteClick(appBranding._id);
                                }}
                                open={expanded}
                                tooltipTexts={{
                                    arrowOpen: 'style_and_branding_app_branding_icon_show',
                                    arrowClose: 'style_and_branding_app_branding_icon_hide',
                                    edit: 'style_and_branding_app_branding_icon_edit',
                                    delete: 'style_and_branding_app_branding_icon_delete'
                                }}
                            />
                        </SmallScreenFirstRow>
                        <SmallScreenSecondRow>
                            {isEditing && expanded ? (
                                <div>
                                    <DialogTextField
                                        label={'App Branding Name'}
                                        placeholder={'App Branding Name'}
                                        value={appBrandingToEdit?.name || ''}
                                        onChange={(evt: any) => {
                                            setAppBrandingToEdit({ ..._.set(appBrandingToEdit, 'name', evt.target.value) });
                                        }}
                                    />
                                </div>
                            ) : (
                                <BrandingViewName>{appBranding.name || EMPTY_WORD_STRING}</BrandingViewName>
                            )}
                        </SmallScreenSecondRow>
                        <SmallScreenThirdRow>
                            {isEditing && expanded ? (
                                <AppBrandingsConditionsView>
                                    <TargetConditionsSelect
                                        targets={appBrandingToEdit?.conditionIds || []}
                                        onChange={(val) => {
                                            setAppBrandingToEdit({
                                                ..._.set(appBrandingToEdit, 'conditionIds', val)
                                            });
                                        }}
                                    />
                                </AppBrandingsConditionsView>
                            ) : (
                                <AppBrandingsConditionsView>
                                    <LabelContainer>
                                        {appBranding?.conditions?.map((condition: any, index: number) => {
                                            if (index < noOfPagesToShow) {
                                                return (
                                                    <Tooltip title={condition.name?.length > 13 ? condition.name : ''} key={index}>
                                                        <ObjectNameLabel>
                                                            {_.truncate(condition.name, { length: 14 }) || EMPTY_WORD_STRING}
                                                        </ObjectNameLabel>
                                                    </Tooltip>
                                                );
                                            }
                                        })}
                                        {appBranding?.conditions && appBranding.conditions.length > noOfPagesToShow && (
                                            <ObjectNameLabel
                                                onClick={(e: React.MouseEvent<any>) => {
                                                    setShowMoreDialog({
                                                        type: MoreInfoTypes.TARGETS,
                                                        show: true,
                                                        data: appBranding.conditions!
                                                    });
                                                    e.stopPropagation();
                                                }}
                                            >
                                                ...
                                            </ObjectNameLabel>
                                        )}
                                    </LabelContainer>
                                </AppBrandingsConditionsView>
                            )}
                        </SmallScreenThirdRow>
                    </BrandingContainer>
                );
            } else {
                brandingContainer = (
                    <BrandingContainer key={appBranding._id}>
                        {isEditing && expanded ? (
                            <div>
                                <DialogTextField
                                    label={'App Branding Name'}
                                    placeholder={'App Branding Name'}
                                    value={appBrandingToEdit?.name || ''}
                                    onChange={(evt: any) => {
                                        setAppBrandingToEdit({ ..._.set(appBrandingToEdit, 'name', evt.target.value) });
                                    }}
                                />
                            </div>
                        ) : (
                            <BrandingViewName
                                onClick={() => {
                                    if (showUnsaved) {
                                        return renderAlertUnsavedChanges();
                                    }
                                    expandAppBranding(appBranding._id);
                                }}
                            >
                                {appBranding.name || EMPTY_WORD_STRING}
                            </BrandingViewName>
                        )}

                        {isEditing && expanded ? (
                            <AppBrandingsConditionsView>
                                <TargetConditionsSelect
                                    targets={appBrandingToEdit?.conditionIds || []}
                                    onChange={(val) => {
                                        setAppBrandingToEdit({
                                            ..._.set(appBrandingToEdit, 'conditionIds', val)
                                        });
                                    }}
                                />
                            </AppBrandingsConditionsView>
                        ) : (
                            <AppBrandingsConditionsView>
                                <LabelContainer>
                                    {appBranding?.conditions?.map((condition: any, index: number) => {
                                        if (index < noOfPagesToShow) {
                                            return (
                                                <Tooltip title={condition.name?.length > 13 ? condition.name : ''} key={index}>
                                                    <ObjectNameLabel>
                                                        {_.truncate(condition.name, { length: 14 }) || EMPTY_WORD_STRING}
                                                    </ObjectNameLabel>
                                                </Tooltip>
                                            );
                                        }
                                    })}
                                    {appBranding?.conditions && appBranding.conditions.length > noOfPagesToShow && (
                                        <ObjectNameLabel
                                            onClick={(e: React.MouseEvent<any>) => {
                                                setShowMoreDialog({
                                                    type: MoreInfoTypes.TARGETS,
                                                    show: true,
                                                    data: appBranding.conditions!
                                                });
                                                e.stopPropagation();
                                            }}
                                        >
                                            ...
                                        </ObjectNameLabel>
                                    )}
                                </LabelContainer>
                            </AppBrandingsConditionsView>
                        )}
                        {(!isEditing || !expanded) && (
                            <LastModifiedBrandings>
                                <span>{dateString}</span>
                                <Tooltip title={appBranding?.modifiedByUser?.name || ''} placement="right-start">
                                    <img src={appBranding?.modifiedByUser?.icon || icons.avatarIcon} alt="" />
                                </Tooltip>
                            </LastModifiedBrandings>
                        )}

                        <ObjectActions
                            actions={[tableActions.EDIT, tableActions.REMOVE]}
                            withArrow
                            onArrowToggle={() => {
                                if (showUnsaved) {
                                    return renderAlertUnsavedChanges();
                                }
                                expandAppBranding(appBranding._id);
                            }}
                            onEdit={() => {
                                if (
                                    showUnsaved &&
                                    (appBranding._id !== appBrandingToEdit?._id ||
                                        (isEditing && appBranding._id === appBrandingToEdit?._id))
                                )
                                    return renderAlertUnsavedChanges();

                                setIsEditing(appBranding._id === appBrandingToEdit?._id ? !isEditing : true);
                                appBrandingToEdit?._id !== appBranding._id && expandAppBranding(appBranding._id);
                                setCreatingAppBranding(false);
                            }}
                            onRemove={() => {
                                showUnsaved ? renderAlertUnsavedChanges() : handleDeleteClick(appBranding._id);
                            }}
                            open={expanded}
                            tooltipTexts={{
                                arrowOpen: 'style_and_branding_app_branding_icon_show',
                                arrowClose: 'style_and_branding_app_branding_icon_hide',
                                edit: 'style_and_branding_app_branding_icon_edit',
                                delete: 'style_and_branding_app_branding_icon_delete'
                            }}
                        />
                    </BrandingContainer>
                );
            }
            return (
                <>
                    {brandingContainer}
                    <AppBrandingsHolder>{expanded && renderExpandedAppBrandingSection(appBrandingToEdit)}</AppBrandingsHolder>
                </>
            );
        });
    };

    const renderCreatingAppBranding = () => {
        const expanded = appBrandingToEdit?._id === '-1';

        if (isSmallMobile) {
            return (
                <>
                    <BrandingContainer id={'create-app-branding-container'}>
                        <SmallScreenFirstRow>
                            <ObjectActions
                                actions={[tableActions.REMOVE]}
                                withArrow
                                onArrowToggle={() => {
                                    showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick();
                                }}
                                onRemove={() => {
                                    showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick();
                                }}
                                open={expanded}
                                tooltipTexts={{
                                    arrowOpen: 'style_and_branding_app_branding_icon_show',
                                    arrowClose: 'style_and_branding_app_branding_icon_hide',
                                    delete: 'style_and_branding_app_branding_icon_delete'
                                }}
                            />
                        </SmallScreenFirstRow>
                        <SmallScreenSecondRow>
                            <div>
                                <DialogTextField
                                    label={'App Branding Name'}
                                    placeholder={'App Branding Name'}
                                    value={appBrandingToEdit?.name || ''}
                                    onChange={(evt: any) => {
                                        setAppBrandingToEdit({ ..._.set(appBrandingToEdit, 'name', evt.target.value) });
                                    }}
                                />
                            </div>
                        </SmallScreenSecondRow>

                        <SmallScreenThirdRow>
                            <AppBrandingsConditionsView>
                                <TargetConditionsSelect
                                    targets={appBrandingToEdit?.conditionIds || []}
                                    onChange={(val) => {
                                        setAppBrandingToEdit({
                                            ..._.set(appBrandingToEdit, 'conditionIds', val)
                                        });
                                    }}
                                />
                            </AppBrandingsConditionsView>
                        </SmallScreenThirdRow>
                    </BrandingContainer>
                    <AppBrandingsHolder>{expanded && renderExpandedAppBrandingSection(appBrandingToEdit)}</AppBrandingsHolder>
                </>
            );
        }

        return (
            <>
                <BrandingContainer key={'creating_app_branding'}>
                    <div>
                        <DialogTextField
                            label={'App Branding Name'}
                            placeholder={'App Branding Name'}
                            value={appBrandingToEdit?.name || ''}
                            onChange={(evt: any) => {
                                setAppBrandingToEdit({ ..._.set(appBrandingToEdit, 'name', evt.target.value) });
                            }}
                        />
                    </div>

                    <AppBrandingsConditionsView>
                        <TargetConditionsSelect
                            targets={appBrandingToEdit?.conditionIds || []}
                            onChange={(val) => {
                                setAppBrandingToEdit({
                                    ..._.set(appBrandingToEdit, 'conditionIds', val)
                                });
                            }}
                        />
                    </AppBrandingsConditionsView>

                    <ObjectActions
                        actions={[tableActions.REMOVE]}
                        withArrow
                        onArrowToggle={() => {
                            showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick();
                        }}
                        onRemove={() => {
                            showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick();
                        }}
                        open={expanded}
                        tooltipTexts={{
                            arrowOpen: 'style_and_branding_app_branding_icon_show',
                            arrowClose: 'style_and_branding_app_branding_icon_hide',
                            delete: 'style_and_branding_app_branding_icon_delete'
                        }}
                    />
                </BrandingContainer>
                <AppBrandingsHolder>{expanded && renderExpandedAppBrandingSection(appBrandingToEdit)}</AppBrandingsHolder>
            </>
        );
    };
    const isEmpty = !storeAppBrandings?.length;
    const beError = error || imageError;
    return (
        <>
            {beError && <BackendErrorDialog error={beError} />}
            {loading ? (
                <Loader title="App Branding" />
            ) : isEmpty && !creatingAppBranding ? (
                renderNoAppBranding()
            ) : (
                renderExistingAppBrandings()
            )}
            {creatingAppBranding && renderCreatingAppBranding()}
            {!loading && !creatingAppBranding && !isEmpty && (
                <PageActionsWrapper>
                    <Button
                        id={APP_BRANDING_ADD_BUTTON_ID}
                        onClick={() => {
                            showUnsaved ? renderAlertUnsavedChanges() : handleCreateClick();
                        }}
                        label={'Create App Branding'}
                        type={'BLUE'}
                    />
                </PageActionsWrapper>
            )}
            {(creatingAppBranding || appBrandingToEdit) && (
                <PageActions
                    onSave={() => {
                        handleSaveClick();
                    }}
                    onCancel={() => {
                        showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick();
                    }}
                    disabled={{ save: !showUnsaved || conditionsLoading }}
                />
            )}
            <MoreInfoDialog
                open={showMoreDialog.show}
                onClose={() => setShowMoreDialog({ type: MoreInfoTypes.TARGETS, show: false, data: [] })}
                type={showMoreDialog.type!}
                data={showMoreDialog.data}
            />
        </>
    );
};

export default AppBrandings;
