import { FC } from 'react';
import { CapabilitiesLink, CapabilitiesWrapper, CapabilitiesTitle, CapabilitiesImage } from './CapabilitiesTopScreen.css';
import { resourceCardImages } from '../../assets/images/resourceCards';
import Button from '../Buttons/Button/Button';
import { openDocumentation } from '../../utils/parsers';

export enum Capabilities {
    ANALYZE = 'analyze',
    AGGREGATE = 'aggregate',
    OPTIMIZE = 'optimize',
    PERSONALIZE = 'personalize',
    MONETIZE = 'monetize'
}

export type CapabilitiesScreenProps = {
    type: Capabilities;
    disabled?: boolean;
};

const capabilitiesTitles = {
    [Capabilities.ANALYZE]: (
        <>
            Unlock the power of data-driven decisions! Explore the Analyze capability and discover how it can transform your content
            management experience. Ready to take control? Visit{' '}
            <CapabilitiesLink onClick={() => openDocumentation(capabilitiesCircleSlugs[Capabilities.ANALYZE], true)}>
                3Ready+
            </CapabilitiesLink>{' '}
            to learn more.
        </>
    ),
    [Capabilities.AGGREGATE]: (
        <>
            Streamline your content management process with the Aggregate capability. Curate and organize effortlessly. Dive into{' '}
            <CapabilitiesLink onClick={() => openDocumentation(capabilitiesCircleSlugs[Capabilities.AGGREGATE], true)}>
                3Ready+
            </CapabilitiesLink>{' '}
            to see how content aggregation can elevate the user experience.
        </>
    ),
    [Capabilities.OPTIMIZE]: (
        <>
            Enhance your content for peak performance! Explore the Optimize capability and witness a faster, more efficient time to content.
            Take the next step at{' '}
            <CapabilitiesLink onClick={() => openDocumentation(capabilitiesCircleSlugs[Capabilities.OPTIMIZE], true)}>
                3Ready+
            </CapabilitiesLink>
            .
        </>
    ),
    [Capabilities.MONETIZE]: (
        <>
            Unlock new revenue streams with our Monetize capability. Discover how your content can become a lucrative asset. Dive into{' '}
            <CapabilitiesLink onClick={() => openDocumentation(capabilitiesCircleSlugs[Capabilities.MONETIZE], true)}>
                3Ready+
            </CapabilitiesLink>{' '}
            and start capitalizing on your control.
        </>
    ),
    [Capabilities.PERSONALIZE]: (
        <>
            Make every user experience unique! Explore the Personalize capability to customize content and boost engagement. Ready to
            captivate your audience? Head to{' '}
            <CapabilitiesLink onClick={() => openDocumentation(capabilitiesCircleSlugs[Capabilities.PERSONALIZE], true)}>
                3Ready+
            </CapabilitiesLink>
            .
        </>
    )
};

const capabilitiesImages = {
    [Capabilities.ANALYZE]: resourceCardImages.analyzeCard,
    [Capabilities.AGGREGATE]: resourceCardImages.aggregateCard,
    [Capabilities.OPTIMIZE]: resourceCardImages.optimizeCard,
    [Capabilities.MONETIZE]: resourceCardImages.monetizeCard,
    [Capabilities.PERSONALIZE]: resourceCardImages.personalizeCard
};

export const capabilitiesCircleSlugs = {
    [Capabilities.ANALYZE]: 'https://plus.3ready.tv/c/cc-capabilities/3ready-analyze',
    [Capabilities.AGGREGATE]: 'https://plus.3ready.tv/c/cc-capabilities/3ready-aggregate',
    [Capabilities.OPTIMIZE]: 'https://plus.3ready.tv/c/cc-capabilities/3ready-optimize',
    [Capabilities.MONETIZE]: 'https://plus.3ready.tv/c/cc-capabilities/3ready-monetize',
    [Capabilities.PERSONALIZE]: 'https://plus.3ready.tv/c/cc-capabilities/3ready-personalize'
};

export const CapabilitiesScreen: FC<CapabilitiesScreenProps> = ({ type, disabled }) => {
    return (
        <CapabilitiesWrapper>
            <CapabilitiesTitle $extraMargin={!disabled}>{capabilitiesTitles[type]}</CapabilitiesTitle>
            {disabled && (
                <>
                    <CapabilitiesImage>
                        <img src={capabilitiesImages[type]} alt={''} />
                    </CapabilitiesImage>
                    <Button
                        type={'BLUE'}
                        onClick={() => {
                            openDocumentation(capabilitiesCircleSlugs[type], true);
                        }}
                        label={'Get Started'}
                    />
                </>
            )}
        </CapabilitiesWrapper>
    );
};
