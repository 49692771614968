import React, { useState } from 'react';

import { ApplicationWrapper } from '../../style/styled-components/reusable.css';
import Sidebar from '../common/Sidebar/Sidebar';
import { Groups } from './Groups/Groups';
import { Users } from './Users/Users';

const USERSPAGE = 'Users';

export const UsersAndGroups: React.FC = () => {
    const [activePage, setActivePage] = useState(USERSPAGE);
    const [newGroupDialogOpen, setNewGroupDialogOpen] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState('');

    return (
        <ApplicationWrapper>
            <Sidebar isUserPage={true} callback={setActivePage} activePage={activePage} />
            {activePage === USERSPAGE ? (
                <Users
                    onGroupNameClick={(groupId: string) => {
                        setSelectedGroupId(groupId);
                        setActivePage('groups');
                        setNewGroupDialogOpen(true);
                    }}
                />
            ) : (
                <Groups
                    dialogOpen={newGroupDialogOpen}
                    selectedGroupId={selectedGroupId}
                    onDialogClose={() => {
                        setSelectedGroupId('');
                        setNewGroupDialogOpen(false);
                    }}
                />
            )}
        </ApplicationWrapper>
    );
};
