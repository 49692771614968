import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { ObjectType } from '../../types/Object';

export const getFilterData = createAsyncThunk<
    { filterData: any; error: { message: string; code: string } | null },
    { projectId: string; objectType: ObjectType }
>('filterData/getFilterData', async ({ projectId, objectType }, thunkApi) => {
    let result;

    result = await ConfigServiceAPI.getFilterData(projectId, objectType);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { filterData: result.response as any, error: null };
});

export interface FilterDataState {
    loading: boolean;
    filterData: { fields: []; filterValues: {} };
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: FilterDataState = {
    loading: false,
    error: null,
    filterData: { fields: [], filterValues: {} }
};

const slice = createSlice({
    name: 'filterData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFilterData.fulfilled, (state, _action) => {
                state.error = null;
                state.loading = false;
                state.filterData = _action.payload.filterData;
            })
            .addCase(getFilterData.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.filterData = { fields: [], filterValues: {} };
                state.loading = false;
            })
            .addCase(getFilterData.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export default slice.reducer;
