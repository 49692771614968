import React, { useEffect, useState } from 'react';
import Sidebar from '../../Sidebar/Sidebar';
import {
    ApplicationWrapper,
    MainContentWrapper,
    NoResourcesContainer,
    PageActionButton,
    PageActionsWrapper,
    TruncatedText
} from '../../../../style/styled-components/reusable.css';
import ScreenTitle from '../../../common/DashboardTitle/ScreenTitle';
import { FieldAndToggleContainer, GroupField, GroupFieldsContainer } from '../../../TargetGroups/GroupEdit.css';
import { Loader } from '../../../common/Loader/Loader';
import {
    createTemplate as _createTemplate,
    deleteTemplate as _deleteTemplate,
    fetchTemplates,
    fetchTemplatesTags,
    releaseTemplate as _releaseTemplate,
    retractTemplate as _retractTemplate,
    templatesState,
    updateTemplate as _updateTemplate
} from '../../../../redux/slices/templatesSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { ResourceCard } from '../../../Cards/ResourceCard/ResourceCard';
import { resourceCardImages } from '../../../../assets/images/resourceCards';
import circleSlugs from '../../../../utils/circle_slugs.json';
import NewModule from './NewModule';
import { parseModuleTemplate, Template, templateType, templateTypes } from '../../../../types/Template';
import { fetchModulesTemplates, ModulesState } from '../../../../redux/slices/moduleSlice';
import { openDocumentation } from '../../../../utils/parsers';
import { ACCEPTED_SORT_FIELDS, calculateOrderByFromSortConfig, DEFAULT_SORT_CONFIG, ISortConfig } from '../../../../utils/fnSort';
import GenericTable, {
    ActionsTableCell,
    HeaderTableCell,
    ScrollableTableRow,
    SortableHeaderTableCell,
    tableActions
} from '../../../common/Table/Table';
import { TemplateImage, TemplateImageWrapper, TemplateName, TemplatesWrapper } from '../Templates.css';
import { generateDateStringForTables } from '../../../../utils/fnDate';
import { PUBLISHED_STATUS } from '../../../../utils/fnPublish';
import { TableBody, TableCell } from '@material-ui/core';
import { WidthTableCell } from '../../../common/Table/Table.css';
import SVGInline from 'react-inlinesvg';
import { DIALOG_NAMES, dialogConfirm } from '../../../../utils/fnDialogs';
import { ContentTemplateLogo, ContentTemplateName, RemoveModuleWrapper } from '../../../Modules/Modules.css';
import { TemplateIcons } from '../../../../assets/images/icons';
import UseExistingDialog, { EXISTING_ITEMS } from '../../../PageEdit/Dialogs/UseExistingDialog';
import { Hint, HINT_TYPES } from '../../../common/Hints/Hint';
import { EditorialTableRow } from '../../../Items/EditorialView.css';
import { VisualEditor } from '../../../Modules/VisualEditor';
import { Actions } from '../../../Items/EditorialView';
import useScreenSize from '../../../../hooks/useScreenSize';
import CreateResourceDialog from '../../../common/Dialog/CreateResourceDialog';
import { NewItemTemplate } from '../Items/NewItem';
import PageActions from '../../../common/PageActions/PageActions';
import useBlockNavigation from '../../../../hooks/useBlockNavigation';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { EMPTY_WORD_STRING, moduleTypes } from '../../../../utils/Globals';
import { PageRoutes } from '../../../../types/RouteTypes';
import { MoreInfoTypes } from '../../../common/Dialog/MoreInfoDialog';
import { ModulesTemplatesTableSizes } from '../../../../types/TableSizes';
import Labels from '../../../common/Labels/Labels';
import { itemTypes } from '../../../../types/Item';
import { fetchContentSourceTypes } from '../../../../redux/slices/itemSlice';
import { SearchBarContainer } from '../../../common/SearchBar/SearchBar.css';
import { SearchBar } from '../../../common/SearchBar/SearchBar';

export const Modules = () => {
    const { templates: storeTemplates, templateTypeLoading, loading }: templatesState = useAppSelector((state) => state.templates);
    const { validTemplates }: ModulesState = useAppSelector((state) => state.modules);

    const [openEditViewModuleId, setOpenEditViewModuleId] = useState<string | null>(null);
    //used to open visual editor from redirect
    const [previewModuleId, setPreviewModuleId] = useState<string>('');
    const [openUseExistingDialog, setOpenUseExistingDialog] = useState<boolean>(false);
    const [openNewResourceDialog, setOpenNewResourceDialog] = useState<boolean>(false);
    const [moduleToEdit, setModuleToEdit] = useState<Template | undefined>(undefined);
    const [openNewItemTemplate, setOpenNewItemTemplate] = useState<boolean>(false);
    const [itemToEdit, setItemToEdit] = useState<Template | undefined>(undefined);
    const [activeTab, setActiveTab] = useState<string>('SYSTEM_TEMPLATES');
    const [openNewTemplate, setOpenNewTemplate] = useState<boolean>(false);
    const [showHintScreen, setShowHintScreen] = useState<boolean>(false);
    const [moduleTemplates, setModuleTemplates] = useState<any[]>([]);
    const [showUnsaved, setShowUnsaved] = useState<boolean>(false);
    const [orderedItems, setOrderedItems] = useState<string[]>([]);
    const [newModuleId, setNewModuleId] = useState<string>('');
    const [newItemId, setNewItemId] = useState<string>('');
    const [addItemAtIndex, setAddItemAtIndex] = useState<number | null>(null);
    const [collectionType, setCollectionType] = useState<itemTypes | undefined>(undefined);
    const [isCollectionItem, setIsCollectionItem] = useState<boolean>(false);
    const [includeProviderLogoCardProviderName, setIncludeProviderLogoCardProviderName] = useState<string>('');
    const [isAutoCollection, setIsAutoCollection] = useState<boolean>(false);
    const [isContentWorld, setIsContentWorld] = useState<boolean>(false);

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [activeSortingKey, setActiveSortingKey] = useState<string | null>(ACCEPTED_SORT_FIELDS.lastModified);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

    const unsavedDependencyArray = [orderedItems, openEditViewModuleId, moduleTemplates];
    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;

    useBlockNavigation(showUnsaved, () => renderAlertShowUnsaved(), unsavedDependencyArray);
    const { isDesktop, isMobile } = useScreenSize();
    const isEmpty = !storeTemplates?.module?.length;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location: any = useLocation();

    const loadTemplates = async (orderBy?: string, searchTerm?: string) => {
        await dispatch(fetchTemplates({ type: templateTypes.MODULE, orderBy, searchTerm })).unwrap();
        await dispatch(fetchModulesTemplates()).unwrap();
    };

    const loadTemplatesTags = async () => {
        await dispatch(fetchTemplatesTags()).unwrap();
    };

    const loadContentSourceTypes = async () => {
        return await dispatch(fetchContentSourceTypes({ projectId: '' }));
    };

    const createTemplate = async (template: Template, removeAutoCollection?: boolean, removeIncludeProviderCardLogo?: boolean) => {
        const response = await dispatch(_createTemplate(template)).unwrap();

        if (!response.id) return;

        if (template.type === templateTypes.MODULE) {
            setNewModuleId(response.id);
            loadTemplates();
            setSearchTerm(undefined);
            setSortConfig(DEFAULT_SORT_CONFIG);
            setActiveSortingKey(ACCEPTED_SORT_FIELDS.lastModified);
        } else {
            addItemTemplate(response.id, removeAutoCollection, removeIncludeProviderCardLogo);
        }
    };

    const updateTemplate = async (values: any) => {
        const response = await dispatch(_updateTemplate(values)).unwrap();
        if (response.id) {
            values?.type === templateTypes.MODULE ? setNewModuleId(moduleToEdit?._id || '') : setNewItemId(itemToEdit?._id || '');
            loadTemplates(calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG), searchTerm);
            setActiveSortingKey(ACCEPTED_SORT_FIELDS.lastModified);
            setSortConfig(DEFAULT_SORT_CONFIG);
        }
    };

    const releaseTemplate = async (type: templateType) => {
        const response = await dispatch(
            _releaseTemplate({ templateId: type === templateTypes.ITEM ? itemToEdit?._id || '' : moduleToEdit?._id || '', type })
        ).unwrap();

        if (!response.ok) return;

        type === templateTypes.ITEM ? setNewItemId(itemToEdit?._id || '') : setNewModuleId(moduleToEdit?._id || '');
        loadTemplates(orderBy, searchTerm);
    };

    const retractTemplate = async (type: templateType) => {
        const response = await dispatch(
            _retractTemplate({ templateId: type === templateTypes.ITEM ? itemToEdit?._id || '' : moduleToEdit?._id || '', type })
        ).unwrap();
        if (!response.ok) return;

        type === templateTypes.ITEM ? setNewItemId(itemToEdit?._id || '') : setNewModuleId(moduleToEdit?._id || '');
        loadTemplates(orderBy, searchTerm);
    };

    const deleteTemplate = async (id: string) => {
        const response = await dispatch(_deleteTemplate({ templateId: id, type: templateTypes.MODULE })).unwrap();
        if (response) loadTemplates(orderBy, searchTerm);
    };

    const addItemTemplate = async (itemTemplateId: string, removeAutoCollection?: boolean, removeIncludeProviderCardLogo?: boolean) => {
        const newModule = storeTemplates.module?.find((t) => t._id === openEditViewModuleId);
        if (newModule !== undefined) {
            let newItemTemplates = [];
            if (addItemAtIndex !== null) {
                newItemTemplates = [...(newModule.values.itemTemplatesIds || [])];
                newItemTemplates.splice(addItemAtIndex, 0, itemTemplateId);
            } else {
                newItemTemplates = [...(newModule.values.itemTemplatesIds || []), itemTemplateId];
            }

            const updatedModule = {
                ...newModule,
                values: {
                    ...newModule.values,
                    ...(removeAutoCollection && { autoCollection: false }),
                    ...(removeIncludeProviderCardLogo && { includeProviderLogoCard: false }),
                    itemTemplatesIds: newItemTemplates
                }
            };
            const response = await dispatch(_updateTemplate(updatedModule)).unwrap();
            if (response.id) {
                loadTemplates(searchTerm);
                setSortConfig(DEFAULT_SORT_CONFIG);
                setActiveSortingKey(ACCEPTED_SORT_FIELDS.lastModified);
            }
        }

        setOpenNewResourceDialog(false);
        setOpenUseExistingDialog(false);
        setOpenNewItemTemplate(false);
    };

    useEffect(() => {
        loadTemplates(orderBy, searchTerm);
        loadTemplatesTags();
        loadContentSourceTypes();
    }, []);

    useEffect(() => {
        !isDesktop && setOpenEditViewModuleId('');
    }, [isDesktop]);

    useEffect(() => {
        if (templateTypeLoading.module) return;
        if (storeTemplates?.module?.length) {
            setModuleTemplates(storeTemplates.module);
            if (!openNewTemplate && !openNewItemTemplate) return;

            if (newModuleId) {
                setModuleToEdit(storeTemplates.module?.find((template) => template._id === newModuleId));
                setNewModuleId('');
            }

            if (newItemId) {
                const selectedModule = storeTemplates.module.find((module) => module.values?.itemTemplatesIds?.includes(newItemId));

                setItemToEdit(selectedModule?.itemTemplates?.find((item) => item._id === newItemId));
                setNewItemId('');
            }
        } else setModuleTemplates([]);
    }, [templateTypeLoading.module]);

    useEffect(() => {
        if (!_.isEmpty(location.state) && location.state?.moduleId) {
            setOpenEditViewModuleId(location.state.moduleId);
            setPreviewModuleId(location.state.moduleId);
            window.history.replaceState({}, '');
        }
    }, []);

    useEffect(() => {
        if (!moduleTemplates || !openEditViewModuleId) return;

        const initialOrder = moduleTemplates.find((module) => module._id === openEditViewModuleId)?.values?.itemTemplatesIds || [];
        setOrderedItems(initialOrder);
    }, [openEditViewModuleId, moduleTemplates]);

    useEffect(() => {
        setShowUnsaved(() => {
            const module = moduleTemplates.find((module) => module._id === openEditViewModuleId);
            if (!orderedItems || !module) return false;

            return (module.values?.itemTemplatesIds || []).join(',') !== orderedItems.join(',');
        });
    }, [...unsavedDependencyArray]);

    const handleOnSearch = (searchTerm: string) => {
        loadTemplates(orderBy, searchTerm);
    };

    const handleSortIconClick = (field: string) => {
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && (sortConfig.field === field || sortConfig.field.split('.')[0] === field) && sortConfig.direction === 'asc') {
            direction = 'desc';
        }

        const config = {
            direction,
            field
        };

        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadTemplates(orderBy, searchTerm);
        setShowSortArrows(false);
    };

    const updateItemsOrder = () => {
        const module = moduleTemplates.find((module) => module._id === openEditViewModuleId);
        if (!module) return;

        updateTemplate({
            ...module,
            values: {
                ...module.values,
                itemTemplatesIds: orderedItems
            }
        });
        setPreviewModuleId('');
    };

    const removeItemFromModule = async (module: Template, item: Template) => {
        const updatedModule = {
            ...module,
            values: {
                ...module.values,
                itemTemplatesIds: module.values.itemTemplatesIds?.filter((id: string) => id !== item._id)
            }
        };
        setPreviewModuleId('');
        await updateTemplate(updatedModule);
    };
    const getProviderNameForModule = (module: Template) => {
        if (!module?.values?.includeProviderLogoCard) return '';
        return module?.itemTemplates?.find((item) => item.values?.itemType === itemTypes.DYNAMIC)?.values?.contentSourceType || '';
    };

    const addItem = (module: Template, index?: number) => {
        setOpenNewResourceDialog(true);
        typeof index === 'number' && setAddItemAtIndex(index + 1);
        setCollectionType(
            module.values?.moduleType === moduleTypes.COLLECTION && !!module?.itemTemplates?.length
                ? module.itemTemplates[0].values?.itemType
                : undefined
        );
        setIsCollectionItem(module.values?.moduleType === moduleTypes.COLLECTION);
        setIncludeProviderLogoCardProviderName(getProviderNameForModule(module));
        setIsAutoCollection(module.values?.autoCollection || false);
        setIsContentWorld(module.values?.contentWorld || false);
    };

    const renderAlertShowUnsaved = () => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => {
                updateItemsOrder();
                setOpenEditViewModuleId('');
                setOrderedItems([]);
            },
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                setOpenEditViewModuleId('');
                setOrderedItems([]);
            },
            true
        );
    };
    const handleItemRemoveIconClick = (module: Template, item: Template) => {
        const labelName = 'Editorial Item';
        const values = {
            title: `Remove ${labelName} From Module`,
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                removeItemFromModule(module, item);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    Are you sure you want to remove the{' '}
                    <strong>
                        {labelName} {`(${item.values?.name || EMPTY_WORD_STRING})`}
                    </strong>{' '}
                    from the <strong>Module{`(${module.values?.name || EMPTY_WORD_STRING})`}</strong>?
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    const handleDeleteClick = (id: string) =>
        dialogConfirm(
            '',
            () => {
                deleteTemplate(id);
                setPreviewModuleId('');
            },
            {
                title: 'Remove Module Template',
                text: ''
            },
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to remove this Module Template?</strong>
                    <br />
                    By Pressing “Remove” you still will be able to create new one from list
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );

    const getTemplateNames = (title: string) => {
        return <ContentTemplateName>{title || '(Unknown)'}</ContentTemplateName>;
    };

    const buildTableColumns = () => {
        const columns = Object.entries({
            icon: 'Icon',
            template: 'UI',
            name: 'Module Name',
            placed: 'Connected To',
            lastModified: 'Last Modified'
        }).map(([key, value], index) => {
            const isAcceptedSorField = ACCEPTED_SORT_FIELDS[key as keyof typeof ACCEPTED_SORT_FIELDS];
            return isAcceptedSorField ? (
                <SortableHeaderTableCell
                    text={value}
                    key={`${index}_cell`}
                    onClick={() => handleSortIconClick(key)}
                    onMouseEnter={() => setShowSortArrows(true)}
                    onMouseLeave={() => setShowSortArrows(false)}
                    columnSize={ModulesTemplatesTableSizes[key as keyof typeof ModulesTemplatesTableSizes]}
                    direction={((sortConfig?.field === key || sortConfig?.field.split('.')[0] === key) && sortConfig?.direction) || 'asc'}
                    hideArrow={
                        (!showSortArrows && activeSortingKey !== key) || !ACCEPTED_SORT_FIELDS[key as keyof typeof ACCEPTED_SORT_FIELDS]
                    }
                />
            ) : (
                <HeaderTableCell
                    text={value}
                    key={`${index}_cell`}
                    columnSize={ModulesTemplatesTableSizes[key as keyof typeof ModulesTemplatesTableSizes]}
                />
            );
        });

        isMobile && columns.splice(4, 1); // remove last modified column if is mobile view
        return columns;
    };

    const buildTableBody = () => {
        const rows = moduleTemplates.map((module, index) => {
            const template = validTemplates?.find((t) => t.value === module.values.template);
            const lastModified = generateDateStringForTables(module?.lastModified || 0);
            const publishedStatus = module?.released ? PUBLISHED_STATUS.PUBLISHED : PUBLISHED_STATUS.UNPUBLISHED;

            return (
                <>
                    <ScrollableTableRow
                        shouldScroll={previewModuleId === module._id}
                        onClick={() => {
                            if (!isDesktop) return setShowHintScreen(true);
                            setOpenEditViewModuleId(module._id);
                        }}
                    >
                        {/* ICON TABLE CELL */}
                        <WidthTableCell {...ModulesTemplatesTableSizes.icon}>
                            <TemplateImageWrapper $background={module?.iconBackground}>
                                <TemplateImage src={module?.icon} />
                            </TemplateImageWrapper>
                        </WidthTableCell>

                        {/* MODULE TEMPLATE TABLE CELL */}
                        <WidthTableCell {...ModulesTemplatesTableSizes.template}>
                            <ContentTemplateLogo>
                                <SVGInline src={template ? TemplateIcons[template?.key] : TemplateIcons.GALLERY} />
                                {getTemplateNames(template?.title)}
                            </ContentTemplateLogo>
                        </WidthTableCell>

                        {/* MODULE NAME TABLE CELL */}
                        <WidthTableCell {...ModulesTemplatesTableSizes.name}>
                            <TemplateName>
                                <TruncatedText>{module?.values?.name}</TruncatedText>
                            </TemplateName>
                        </WidthTableCell>

                        {/*  CONNECTED TO TABLE CELL */}
                        <WidthTableCell {...ModulesTemplatesTableSizes.placed}>
                            <Labels
                                values={module?.placed || []}
                                type={MoreInfoTypes.CONNECTED_TO}
                                noOfLabels={isDesktop ? 3 : isMobile ? 0 : 1}
                                onClickLabel={(page) => {
                                    navigate(PageRoutes.SUPERADMIN_PAGE.replace(':page_id', page._id));
                                }}
                            />
                        </WidthTableCell>

                        {/* LAST MODIFIED TABLE CELL */}
                        {!isMobile && <WidthTableCell {...ModulesTemplatesTableSizes.lastModified}>{lastModified}</WidthTableCell>}

                        {/* ACTIONS TABLE CELL */}
                        <WidthTableCell {...ModulesTemplatesTableSizes.actions}>
                            <ActionsTableCell
                                actions={[tableActions.EDIT, tableActions.REMOVE]}
                                onEdit={(e: React.MouseEvent<any>) => {
                                    setOpenNewTemplate(true);
                                    setModuleToEdit(module);
                                    e.stopPropagation();
                                }}
                                onRemove={(e: React.MouseEvent<any>) => {
                                    handleDeleteClick(module._id);
                                    e.stopPropagation();
                                }}
                                tooltipTexts={{
                                    edit: 'superadmin_module_templates_icon_edit',
                                    delete: 'superadmin_module_templates_icon_delete',
                                    published: module?.released
                                        ? 'superadmin_module_templates_icon_released'
                                        : 'superadmin_module_templates_icon_unreleased'
                                }}
                                publishedStatus={publishedStatus}
                            />
                        </WidthTableCell>
                    </ScrollableTableRow>
                    {openEditViewModuleId && renderVisualEditor(module, index)}
                </>
            );
        });
        return <TableBody>{rows}</TableBody>;
    };

    const itemActionsSwitcher = async (type: 'ADD' | 'EDIT' | 'DELETE', moduleTemplate: Template, item?: any, index?: number) => {
        switch (type) {
            case 'ADD':
                addItem(moduleTemplate, index);
                break;

            case 'EDIT':
                if (!item) break;
                setOpenNewItemTemplate(true);
                setItemToEdit(item);
                break;

            case 'DELETE':
                if (!item) break;
                const module = storeTemplates.module?.find((t) => t._id === openEditViewModuleId);
                if (module) {
                    handleItemRemoveIconClick(module, item);
                }

                break;

            default:
                break;
        }
    };

    const renderVisualEditor = (module: Template, moduleIndex: number) => {
        const { _id } = module;
        if (openEditViewModuleId !== _id) return;
        const _module = parseModuleTemplate(module);
        return (
            <EditorialTableRow>
                <TableCell colSpan={8}>
                    <VisualEditor
                        isTemplate
                        module={_module}
                        onSave={() => new Promise<boolean>((resolve) => resolve(true))}
                        onDuplicate={() => {}}
                        moduleIndex={moduleIndex}
                        setItemForPreview={(itemId) => {
                            navigate(PageRoutes.SUPERADMIN_ITEMS, { state: { itemId } });
                        }}
                        actions={[Actions.EDIT, Actions.REMOVE]}
                        onRemove={() => handleDeleteClick(module._id)}
                        template={module.values.template.toUpperCase()}
                        createItem={(index) => {
                            addItem(module, index);
                        }}
                        onItemAction={(actionType, item, index) => itemActionsSwitcher(actionType, module, item, index)}
                        setOrderedModule={(module) => {
                            if (!module.itemIds || orderedItems.join(',') === module.itemIds.join(',')) return;
                            setOrderedItems(module.itemIds);
                        }}
                        onEdit={() => {
                            setModuleToEdit(module);
                            setOpenNewTemplate(true);
                        }}
                        onCancel={() => {
                            if (showUnsaved) {
                                return renderAlertShowUnsaved();
                            }
                            setOpenEditViewModuleId('');
                            setPreviewModuleId('');
                        }}
                    />
                </TableCell>
            </EditorialTableRow>
        );
    };

    return (
        <>
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle
                        withProfile
                        withoutSearch
                        title="Modules"
                        withAddButton={!isEmpty}
                        loading={templateTypeLoading.module}
                        addLabel={'Create Module Template'}
                        onAdd={() => setOpenNewTemplate(true)}
                    />
                    <FieldAndToggleContainer>
                        <GroupFieldsContainer>
                            <GroupField onClick={() => setActiveTab('SYSTEM_TEMPLATES')} $active={activeTab === 'SYSTEM_TEMPLATES'}>
                                System Templates
                            </GroupField>
                            <GroupField
                                onClick={() => setActiveTab('SYSTEM_TEMPLATES')}
                                $active={activeTab === 'USER_SUGGESTIONS'}
                                $disabled
                            >
                                User Suggestions
                            </GroupField>
                        </GroupFieldsContainer>
                    </FieldAndToggleContainer>
                    <SearchBarContainer>
                        <SearchBar
                            title={'Search by Name'}
                            searchTerm={searchTerm}
                            onSearch={handleOnSearch}
                            setSearchTerm={setSearchTerm}
                            disabled={templateTypeLoading.module}
                        />
                    </SearchBarContainer>
                    {!openUseExistingDialog && templateTypeLoading.module ? (
                        <Loader title={'Module Templates'} />
                    ) : (
                        <TemplatesWrapper>
                            {isEmpty ? (
                                <NoResourcesContainer>
                                    <ResourceCard
                                        createResource
                                        title={'New Module'}
                                        secondaryButtonLabel={'Learn more'}
                                        primaryButtonLabel={'Create Module Template'}
                                        image={resourceCardImages.modulesNewCard}
                                        subtitle={'Create a module template'}
                                        onPrimaryButtonClick={() => setOpenNewTemplate(true)}
                                        onSecondaryButtonClick={() => openDocumentation(circleSlugs.modules)}
                                    />
                                </NoResourcesContainer>
                            ) : (
                                <>
                                    <GenericTable body={buildTableBody()} columns={buildTableColumns()} />
                                    {!templateTypeLoading.module && (
                                        <PageActionsWrapper>
                                            <PageActionButton
                                                onClick={() => setOpenNewTemplate(true)}
                                                label={'Create Module Template'}
                                                type={'BLUE'}
                                            />
                                        </PageActionsWrapper>
                                    )}
                                </>
                            )}
                        </TemplatesWrapper>
                    )}
                    <NewModule
                        module={moduleToEdit}
                        onRelease={() => releaseTemplate(templateTypes.MODULE)}
                        onRetract={() => retractTemplate(templateTypes.MODULE)}
                        onSave={(module) => {
                            moduleToEdit ? updateTemplate(module) : createTemplate(module);
                            setPreviewModuleId('');
                        }}
                        onClose={() => {
                            setOpenNewTemplate(false);
                            setModuleToEdit(undefined);
                        }}
                        open={openNewTemplate}
                    />
                </MainContentWrapper>

                <CreateResourceDialog
                    historyUrl={''}
                    withSelectExisting
                    withoutSelectTemplate
                    open={openNewResourceDialog}
                    title={'List or Item Template'}
                    handleSelectExistingClick={() => setOpenUseExistingDialog(true)}
                    handleCreateNewResourceClick={() => setOpenNewItemTemplate(true)}
                    onClose={() => {
                        setOpenNewResourceDialog(false);
                        setItemToEdit(undefined);
                        setAddItemAtIndex(null);
                        setCollectionType(undefined);
                        setIsCollectionItem(false);
                        setIncludeProviderLogoCardProviderName('');
                        setIsAutoCollection(false);
                        setIsContentWorld(false);
                    }}
                />

                <NewItemTemplate
                    item={itemToEdit}
                    open={openNewItemTemplate}
                    onRelease={() => releaseTemplate(templateTypes.ITEM)}
                    onRetract={() => retractTemplate(templateTypes.ITEM)}
                    module={storeTemplates.module?.find((t) => t._id === openEditViewModuleId)}
                    onSave={(template, removeAutoCollection, removeIncludeProviderCardLogo) => {
                        itemToEdit
                            ? updateTemplate(template)
                            : createTemplate(template, removeAutoCollection, removeIncludeProviderCardLogo);
                        setPreviewModuleId('');
                        setAddItemAtIndex(null);
                    }}
                    onClose={() => {
                        setOpenNewItemTemplate(false);
                        setItemToEdit(undefined);
                        setAddItemAtIndex(null);
                    }}
                    isCollection={isCollectionItem}
                    collectionType={collectionType}
                    includeProviderLogoCardProviderName={includeProviderLogoCardProviderName}
                    isContentWorldModule={isContentWorld}
                />

                <UseExistingDialog
                    open={openUseExistingDialog}
                    existingTemplateType={templateTypes.ITEM}
                    existingItemType={EXISTING_ITEMS.TEMPLATE}
                    onClose={() => setOpenUseExistingDialog(false)}
                    onSave={(itemTemplateId, undefined, removeAutoCollection, removeIncludeProviderCardLogo) => {
                        addItemTemplate(itemTemplateId || '', removeAutoCollection, removeIncludeProviderCardLogo);
                        setPreviewModuleId('');
                        setAddItemAtIndex(null);
                    }}
                    isAutoCollection={isAutoCollection}
                    itemIdsToExclude={storeTemplates.module?.find((t) => t._id === openEditViewModuleId)?.values.itemTemplatesIds || []}
                    collectionType={collectionType}
                    includeProviderCardLogoProviderName={includeProviderLogoCardProviderName}
                    isContentWorld={isContentWorld}
                    isSuperAdminUI
                />

                <Hint type={HINT_TYPES.SCREEN_SIZE_CIS} showHint={showHintScreen} onHide={() => setShowHintScreen(false)} />

                {openEditViewModuleId && (
                    <PageActions
                        onSave={updateItemsOrder}
                        onCancel={() => {
                            if (showUnsaved) {
                                return renderAlertShowUnsaved();
                            }
                            setOpenEditViewModuleId('');
                            setPreviewModuleId('');
                        }}
                        disabled={{ save: !showUnsaved || loading }}
                    />
                )}
            </ApplicationWrapper>
        </>
    );
};
