import styled, { css } from 'styled-components';
import getTheme, { fontSizesCSS, zIndexesCSS } from '../../../style/themes/theme';

const theme: any = getTheme();

export const LoaderContainer = styled.div`
    position: fixed;
    z-index: ${zIndexesCSS.zIndexLoader};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 320px;
`;

export const LoaderText = styled.div<{ whiteTitle?: boolean }>`
    ${fontSizesCSS.Title4};
    color: ${(props) => (props.whiteTitle ? theme.palette.white.main : theme.palette.text.main)};
    text-align: center;
    margin-bottom: 24px;
`;

export const WrapperBar = styled.div`
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: ${theme.palette.background.pressed};
    overflow: hidden;
`;

export const ProgressBar = styled.div<{ $progress?: number }>`
    width: ${({ $progress }) => $progress ?? 50}%;
    height: 4px;
    border-radius: 4px;
    background-color: ${theme.palette.primary.main};

    ${({ $progress }) =>
        $progress === undefined &&
        css`
            animation: progressAnimation 1s infinite linear;
        `};

    @keyframes progressAnimation {
        0% {
            margin-left: -100%;
        }
        100% {
            margin-left: 100%;
        }
    }
`;
