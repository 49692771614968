import { useEffect, useState } from 'react';

export const useMultiSelect = () => {
    const [isShiftPressed, setIsShiftPressed] = useState<boolean>(false);
    const [lastItemSelected, setLastItemSelected] = useState<string>('');
    const onKeyDown = (e: any) => {
        const { keyCode } = e;
        if (keyCode === 16) {
            setIsShiftPressed(true);
        }
    };

    const onKeyUp = (e: any) => {
        const { keyCode } = e;
        if (keyCode === 16) {
            setIsShiftPressed(false);
        }
    };

    const select = (initialState: string[], selectedValues: string[], value: string) => {
        const isSelected = selectedValues.includes(value);

        if (!!lastItemSelected && isShiftPressed) {
            const lastIndex = initialState.indexOf(lastItemSelected);
            const currentIndex = initialState.indexOf(value);
            setLastItemSelected(value);

            if ([lastIndex, currentIndex].includes(-1)) return selectedValues;

            const newValues =
                lastIndex > currentIndex
                    ? initialState.slice(currentIndex, lastIndex + 1)
                    : initialState.slice(lastIndex, currentIndex + 1);
            return isSelected
                ? selectedValues.filter((value) => !newValues.includes(value))
                : [...selectedValues, ...newValues.filter((value) => !selectedValues.includes(value))];
        }
        if (isSelected) {
            const index = selectedValues.indexOf(value);
            selectedValues.splice(index, 1);
        } else {
            selectedValues.push(value);
        }

        setLastItemSelected(value);
        return selectedValues;
    };

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        document.addEventListener('keyup', onKeyUp);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
            document.removeEventListener('keyup', onKeyUp);
        };
    }, [isShiftPressed]);

    return {
        select
    };
};
