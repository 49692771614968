import styled from 'styled-components';
import { ToggleCheckBox, ToggleLabel, ToggleSlider } from '../Dialog/GenericDialog.css';
import getTheme from '../../../style/themes/theme';

const theme: any = getTheme();

export const ToggleViewSwitchWrapper = styled.div<{ leftIcon: string; rightIcon: string }>`
    padding: 8px;
    ${ToggleLabel} {
        width: 60px;
        height: 26px;
    }
    ${ToggleSlider} {
        background-color: ${theme.palette.grey.light};
        background-image: ${({ rightIcon }) => rightIcon};
        background-size: 14px 14px;
        background-repeat: no-repeat;
        background-position: right 8px bottom 6px;
        transition: transform 0.2s;
        &:before {
            transition: transform 0.2s;
            width: 34px;
            height: 34px;
            top: -4px;
            left: -4px;
            background-image: ${({ leftIcon }) => leftIcon};
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    ${ToggleCheckBox} {
        &:checked + ${ToggleSlider} {
            background-color: ${theme.palette.grey.light};
            background-image: ${({ leftIcon }) => leftIcon};
            background-position: left 8px bottom 6px;
        }
        &:checked + ${ToggleSlider}:before {
            transform: translateX(34px);
            background-image: ${({ rightIcon }) => rightIcon};
        }
    }
`;
