import React, { FC } from 'react';
import {
    DashboardCardDetails,
    DashboardCardWrapper,
    LandingCardSubtitle,
    LandingCardTitle,
    ResourceDefaultImage
} from '../DashboardCard/DashboardCard.css';
import Button from '../../Buttons/Button/Button';

type ResourceCardProps = {
    image?: string;
    title: string;
    subtitle: string;
    primaryButtonLabel: string;
    secondaryButtonLabel: string;
    onPrimaryButtonClick?: any;
    onSecondaryButtonClick?: any;
    createResource?: boolean;
};

export const ResourceCard: FC<ResourceCardProps> = ({
    image,
    title,
    subtitle,
    primaryButtonLabel,
    secondaryButtonLabel,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    createResource
}) => {
    const handlePrimaryButtonClick = (evt: any) => {
        evt.preventDefault();
        if (onPrimaryButtonClick) {
            onPrimaryButtonClick();
        }
    };

    const handleSecondaryButtonClick = (evt: any) => {
        evt.preventDefault();
        if (onSecondaryButtonClick) {
            onSecondaryButtonClick();
        }
    };

    return (
        <DashboardCardWrapper>
            {image ? <img src={image} /> : <ResourceDefaultImage $createResource={!!createResource} />}
            <DashboardCardDetails>
                <LandingCardTitle>{title}</LandingCardTitle>
                <LandingCardSubtitle>{subtitle}</LandingCardSubtitle>
                <Button type="BLUE" label={primaryButtonLabel} onClick={handlePrimaryButtonClick} />
                <Button type="DEFAULT" label={secondaryButtonLabel} onClick={handleSecondaryButtonClick} />
            </DashboardCardDetails>
        </DashboardCardWrapper>
    );
};
