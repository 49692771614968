import React, { useCallback, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { getHelperText } from '../../../utils/fnDialogs';
import { AddNewOption, CheckedIndicatorContainer, SelectWrapper } from './Select.css';
import { CustomArrow, ISelectProps, LoadingAdornment } from './SelectSingle';
import { DROPDOWN_ADD_VALUE, DROPDOWN_GROUP_LABEL_VALUE, DROPDOWN_SELECT_ALL_VALUE } from '../../../utils/Globals';
import SVGInline from 'react-inlinesvg';
import icons from '../../../style';
import { DropdownLabel, ShowMoreButton } from '../../../style/styled-components/reusable.css';
import _ from 'lodash';
import { DialogDropdownNewOption } from '../Dialog/GenericDialog';
import { calculateElementsToShow } from '../../../utils/fnDynamicWidth';

const SelectMultiple: React.FC<ISelectProps> = (props) => {
    const { value } = props;

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [maxToShow, setMaxToShow] = useState<number>(1);
    const [noOfHidden, setNoOfHidden] = useState<number>(0);
    const [optionsToRender, setOptionsToRender] = useState<any[]>([]);

    const noLabel = props.noLabel === true;
    const labelValue = props.labelText || props.placeholder;

    const setElementsToShow = (value: any[]) => {
        if (!value) return;
        const container = document.getElementById(`value-container-${props.id || labelValue}`);
        const containerWidth = container?.offsetWidth || 0;
        const elementsToShow = calculateElementsToShow(
            value.map((el: any) => el.valueToDisplay || el.label),
            containerWidth
        );
        setNoOfHidden(value.length - elementsToShow);
        setMaxToShow(elementsToShow);
    };

    useEffect(() => {
        setElementsToShow(value);
    }, [value]);

    // set initial options when component mounts
    useEffect(() => {
        setOptionsToRender(props.options);
    }, [props.options.length]);

    // sort selected items only after close
    useEffect(() => {
        !isMenuOpen &&
            setOptionsToRender(
                sortMultiOptions(props.options || [], value, props.newOption, props.notSorted, props.orderMatters, props.selectAllOption)
            );
    }, [isMenuOpen, value, props.orderMatters]);

    const handleChange = (newValue: any[]) => {
        if (props.forceLowerCase) {
            newValue.forEach((value, index) => {
                if (props.forceLowerCase) {
                    value.value = value.value?.toLowerCase();
                }
                newValue.splice(index, 1, value);
            });
        }
        if (props.onChange) {
            props.onChange(newValue);
        }
    };

    const handleBlur = (evt: any) => {
        evt.preventDefault();
        if (props.onBlur) {
            props.onBlur();
        }
    };

    // the newOption and/or select all buttons will be the first options in the list so the indexes of the other options will be shifted by 1/2
    const unclickableIndexes = props.unclickableIndexes?.length
        ? props.newOption || props.selectAllOption
            ? props.unclickableIndexes?.map((index) => index + (props.newOption && props.selectAllOption ? 2 : 1))
            : props.unclickableIndexes
        : undefined;

    const MenuMemo = useCallback((props) => Menu(props), []);
    const MultiValueLabelMemo = useCallback((props) => MultiValueLabel(props), []);
    const MultiValueRemoveMemo = useCallback((props) => MultiValueRemove(props), []);
    const ValueContainerMemo = useCallback((props: any) => ValueContainer(props), []);
    const MultiValueMemo = useCallback((props: any) => MultiValue(props, maxToShow, noOfHidden), [maxToShow, noOfHidden]);
    const OptionMemo = useCallback((componentProps) => Option(componentProps, props.newOption, props.orderMatters), []);
    const DropdownIndicatorMemo = useCallback((props) => {
        return props.withLoadingIndicator ? LoadingAdornment(props) : CustomArrow(props, isMenuOpen);
    }, []);

    return (
        <SelectWrapper
            id={`select_wrapper_${labelValue}`}
            className={'multiple'}
            $error={!!props.error}
            onBlur={handleBlur}
            tabIndex={1}
            unclickableIndexes={unclickableIndexes}
            $noError={!!props.noError}
            $withTopMargin={props.withTopMargin}
            data-cy={props?.dataCy}
        >
            {!noLabel && <label>{labelValue}</label>}
            <Select
                isDisabled={props.isDisabled || false}
                {...props}
                placeholder={!props.noPlaceholder ? props.placeholder : ''}
                classNamePrefix="cc_select"
                isMulti
                closeMenuOnSelect={false}
                value={value}
                clearable={props.clearable || true}
                options={optionsToRender}
                menuPlacement={props.up ? 'top' : 'bottom'}
                onChange={(value: any) => {
                    setElementsToShow(value);
                    if (!props.allowSelectAll && props.options.length === value?.length) {
                        return;
                    }
                    if (props.maxSelected && props.maxSelected === value?.length - 1) {
                        return;
                    }
                    handleChange(value);
                }}
                filterOption={({ label, data }, inputValue) => {
                    const value = inputValue.toLowerCase().trim();
                    if (data?.value === DROPDOWN_GROUP_LABEL_VALUE || data?.value === DROPDOWN_ADD_VALUE) return true;
                    if (!data?.valueForSearch) {
                        return typeof label === 'string' && label.toLowerCase().includes(value);
                    }
                    return data?.valueForSearch.toLowerCase().includes(value);
                }}
                maxMenuHeight={300}
                components={{
                    Menu: MenuMemo,
                    Option: OptionMemo,
                    MultiValue: MultiValueMemo,
                    ValueContainer: ValueContainerMemo,
                    MultiValueLabel: MultiValueLabelMemo,
                    DropdownIndicator: DropdownIndicatorMemo,
                    MultiValueRemove: MultiValueRemoveMemo,
                    ClearIndicator: null,
                    IndicatorSeparator: null
                }}
                onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
                hideSelectedOptions={false}
                openMenuOnClick={props.openOnClick !== false}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={() => setIsMenuOpen(false)}
            />
            <label className="error">{getHelperText(props.error, props?.optional)}</label>
        </SelectWrapper>
    );
};

const ValueContainer = (props: any) => {
    const containerIdentifier = props.selectProps?.id || props.selectProps?.labelText || props.selectProps?.placeholder;

    return (
        <components.ValueContainer
            {...props}
            innerProps={{
                className: 'custom-value-container',
                id: `value-container-${containerIdentifier}`
            }}
        >
            {props.children}
        </components.ValueContainer>
    );
};

const MultiValue = ({ index, ...props }: any, maxToShow: number, noOfHidden: number) => {
    return index < maxToShow ? (
        <components.MultiValue {...props}>{props.children}</components.MultiValue>
    ) : index === maxToShow ? (
        <ShowMoreButton>+{noOfHidden}</ShowMoreButton>
    ) : null;
};

const MultiValueLabel = (props: any) => {
    return <components.MultiValueLabel {...props} innerProps={{ className: 'custom-value-label' }}></components.MultiValueLabel>;
};

const MultiValueRemove = (props: any) => {
    return (
        <components.MultiValueRemove {...props}>
            <SVGInline src={icons.closeIcon} />
        </components.MultiValueRemove>
    );
};

const Menu = (props: any) => {
    return <components.Menu {...props}>{props.children}</components.Menu>;
};

const Option = (props: any, newOption?: DialogDropdownNewOption, orderMatters?: boolean) => {
    const selectValue = props.selectProps?.value || [];
    const optionValue = props.value;
    let isFirstUnchecked = false;
    let optionIndex = 0;

    const isChecked: (value: any) => boolean = (value) =>
        selectValue.some((item: any, index: number) => {
            if (item.value === value) {
                optionIndex = index;
                return true;
            }
            return false;
        });
    const checked = isChecked(optionValue);

    if (!checked) {
        props.options.forEach((item: any, index: number) => {
            if (item.value === optionValue && isChecked(props.options[index - 1]?.value)) {
                isFirstUnchecked = true;
            }
        });
    }

    const innerProps = { ...props.innerProps };
    if (isFirstUnchecked) {
        innerProps.className = 'cc_select__option option-with-border';
    }

    // SELCT ALL OPTION
    const unclickableIndexes = props.selectProps.unclickableIndexes || [];
    const optionsToSelect = props.options.filter(
        (option: any, index: number) =>
            ![DROPDOWN_ADD_VALUE, DROPDOWN_GROUP_LABEL_VALUE, DROPDOWN_SELECT_ALL_VALUE].includes(option.value) &&
            !unclickableIndexes.includes(index)
    );
    const allSelected = props.selectProps.value?.length === optionsToSelect?.length;
    const onSelectAll = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        props.selectProps.onChange(allSelected ? [] : optionsToSelect);
    };
    if (props.value === DROPDOWN_SELECT_ALL_VALUE) {
        innerProps.className = 'cc_select__option select-all-option';
    }

    return (
        <components.Option {...props} innerProps={innerProps}>
            {props.value === DROPDOWN_ADD_VALUE ? (
                <AddNewOption
                    onClick={(e) => {
                        newOption?.onClick();
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <SVGInline src={icons.addIcon} /> {props.label}
                </AddNewOption>
            ) : props.value === DROPDOWN_SELECT_ALL_VALUE ? (
                <>
                    <DropdownLabel onClick={onSelectAll}>
                        <span>Select All</span>
                        {allSelected && <SVGInline src={icons.selectCheckIcon} />}
                    </DropdownLabel>
                </>
            ) : (
                <>
                    {props.label}
                    {checked && (
                        <CheckedIndicatorContainer>
                            <SVGInline src={icons.selectCheckIcon} /> {orderMatters && <span>({optionIndex + 1})</span>}{' '}
                        </CheckedIndicatorContainer>
                    )}
                </>
            )}
        </components.Option>
    );
};

const sortMultiOptions = (
    options: any[],
    selected: string[],
    newOption?: DialogDropdownNewOption,
    notSorted?: boolean,
    orderMatters?: boolean,
    selectAll?: boolean
) => {
    if (orderMatters) {
        const newOptions = [...options];
        if (selectAll) newOptions.unshift({ value: DROPDOWN_SELECT_ALL_VALUE, label: `Select All` });
        if (newOption) newOptions.unshift({ value: DROPDOWN_ADD_VALUE, label: `Create ${_.startCase(newOption.name)}`, isDisabled: true });
        return newOptions;
    }
    const _options = [...options];
    let sortedOptions: any[] = [];

    options.forEach((item: any, index) => {
        if ((index !== 0 && item.value === DROPDOWN_GROUP_LABEL_VALUE) || index === options.length - 1) {
            const selectedValues = (selected || []).map((item: any) => item.value);
            const deleteCount = index === options.length - 1 ? index + 1 : index;
            let remaining = _options.splice(0, deleteCount);

            remaining = remaining.map((option: any) => {
                const newOption = { ...option };
                newOption.selected =
                    selectedValues.includes(option.value) ||
                    option.value === DROPDOWN_GROUP_LABEL_VALUE ||
                    option.value === DROPDOWN_ADD_VALUE;
                return newOption;
            });

            if (!notSorted) {
                remaining = remaining.sort((a: any, b: any) => {
                    // group label should always be first
                    if (a.value === DROPDOWN_GROUP_LABEL_VALUE) return -1;
                    if (b.value === DROPDOWN_GROUP_LABEL_VALUE) return 1;

                    let nameA = typeof a.label === 'string' ? a.label : a.valueForSearch || a.value || '';
                    let nameB = typeof b.label === 'string' ? b.label : b.valueForSearch || b.value || '';

                    nameA = nameA.toLowerCase();
                    nameB = nameB.toLowerCase();

                    if (!isNaN(nameA)) {
                        nameA = parseInt(nameA);
                    }
                    if (!isNaN(nameB)) {
                        nameB = parseInt(nameB);
                    }
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
            }

            remaining = remaining.sort((a: any, b: any) => b.selected - a.selected).map((option: any) => _.omit(option, 'selected'));

            sortedOptions = [...sortedOptions, ...remaining];
        }
    });

    if (selectAll) {
        sortedOptions = [{ value: DROPDOWN_SELECT_ALL_VALUE, label: `Select All` }, ...sortedOptions];
    }
    if (newOption) {
        sortedOptions = [{ value: DROPDOWN_ADD_VALUE, label: `Create ${_.startCase(newOption.name)}`, isDisabled: true }, ...sortedOptions];
    }

    return sortedOptions;
};

export default SelectMultiple;
