import React, { forwardRef, ForwardRefRenderFunction, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import icons from '../../../style';
import { SearchSVGInline } from '../../../style/styled-components/reusable.css';
import { renderTooltipWithKey } from '../Tooltips/Tooltips';
import { SearchIcon, SearchInput } from './SearchBar.css';
import { useAppSelector } from '../../../hooks/redux';
import { InputAdornment } from '@material-ui/core';
import _ from 'lodash';
import { Audience } from '../../../types/Audience';

export const searchByNameAndDescription = (object: any, value: string) => {
    return (
        object?.['name']?.toString()?.toLowerCase().includes(value) ||
        object?.['fullName']?.toString()?.toLowerCase().includes(value) ||
        object?.['description']?.toString()?.toLowerCase().includes(value) ||
        false
    );
};

const filterByFields = (filter: Record<string, string[]>, object: any) => {
    const keysToCheck: Record<string, string> = {
        audiences: 'conditionIds',
        conditions: 'conditionIds',
        abGroups: 'abTestingGroupIds'
    };
    return Object.keys(filter).every((field) => {
        const key = keysToCheck[field];
        return filter[field].some((value) => object[key]?.includes(value));
    });
};

export const searchInGroup = (
    content: Record<string, any[]>,
    audiences: Audience[],
    searchTerm?: string,
    filters?: Record<string, string[]>
) => {
    const searchValue = searchTerm?.toLowerCase().trim() || '';

    let filteredContent: any = {};
    let filteredAudiences: Audience[] = [];

    if (!filters) {
        Object.keys(content).forEach((key) => {
            filteredContent[key] = content[key].filter((object) => searchByNameAndDescription(object, searchValue));
        });

        filteredAudiences = audiences.filter((object) => {
            return searchByNameAndDescription(object, searchValue);
        });

        return {
            content: filteredContent,
            audiences: filteredAudiences
        };
    }

    Object.keys(content).forEach((key) => {
        filteredContent[key] = content[key].filter((object) => filterByFields(filters, object));
    });

    filteredAudiences = audiences.filter((audience) => {
        return !filters['audiences']?.length ? false : filters['audiences'].includes(audience._id);
    });

    return {
        content: filteredContent,
        audiences: filteredAudiences
    };
};

export const search = (objects: any[], value: string, extraFields?: any[], specificField?: string) => {
    const searchValue = value.toLowerCase().trim();
    if (!objects?.length) return [];
    return objects.filter((object) => {
        let isMatch: boolean;

        return Object.keys(object).some((key) => {
            if (specificField && key !== specificField) return false;

            if (Array.isArray(object[key]) && object[key].length) {
                isMatch = object[key].some((item: any) => {
                    if (typeof item === 'object') {
                        return searchByNameAndDescription(item, searchValue);
                    }
                });
            } else {
                if (typeof object[key] === 'object') {
                    const item = object[key];
                    isMatch = searchByNameAndDescription(item, searchValue);
                    return isMatch;
                }
                isMatch =
                    searchByNameAndDescription(object, searchValue) ||
                    (extraFields &&
                        extraFields.some((field) =>
                            object?.[field]?.toString()?.toLowerCase()?.split('_')?.join(' ').includes(searchValue)
                        ));
            }
            return isMatch;
        });
    });
};

type searchBarProps = {
    title: string;
    searchTerm: string | undefined; // undefined is used to prevent search for empty string when mounting components
    tooltipText?: string;
    onSearch: (searchTerm: string) => void;
    setSearchTerm: (searchTerm: string) => void;
    disabled?: boolean;
};
export interface SearchBarHandle {
    blurInput: () => void;
}
const SearchBarComponent: ForwardRefRenderFunction<SearchBarHandle, searchBarProps> = (
    { title, tooltipText, searchTerm, setSearchTerm, onSearch, disabled },
    ref
) => {
    const inputRef = useRef<HTMLInputElement>();
    const { activeProjectId } = useAppSelector((state) => state.activeItem);

    useImperativeHandle(
        ref,
        () => ({
            blurInput: () => inputRef?.current?.blur()
        }),
        []
    );

    const debouncedSearch = useCallback(
        _.debounce((searchTerm: string) => {
            onSearch(searchTerm);
        }, 1000),
        [activeProjectId, searchTerm]
    );

    useEffect(() => {
        return () => {
            debouncedSearch?.cancel();
        };
    }, [debouncedSearch]);

    useEffect(() => {
        if (searchTerm === undefined) return;
        if (searchTerm === '') {
            onSearch('');
            return;
        }
        debouncedSearch(searchTerm);
    }, [searchTerm]);

    const endAdornment = (
        <InputAdornment position="end">
            {renderTooltipWithKey(
                <SearchIcon
                    onClick={() => {
                        if (!searchTerm || disabled) return;
                        setSearchTerm('');
                    }}
                >
                    <SearchSVGInline src={searchTerm ? icons.closeIcon : icons.searchIcon} />
                </SearchIcon>,
                `${searchTerm ? 'generic_icon_close_search' : tooltipText || 'generic_icon_search'}`
            )}
        </InputAdornment>
    );

    return (
        <SearchInput
            title={title}
            disabled={disabled}
            inputRef={inputRef}
            placeholder={title}
            value={searchTerm || ''}
            InputProps={searchTerm && disabled ? {} : { endAdornment: endAdornment }}
            onChange={(e: any) => {
                const newValue = e.target.value;
                setSearchTerm(newValue);
            }}
        />
    );
};

export const SearchBar = forwardRef(SearchBarComponent);
