import styled from 'styled-components';
import getTheme from '../../../style/themes/theme';

const theme: any = getTheme();

export const ArrowIconHolder = styled.div`
    padding-top: 8px;
    svg {
        opacity: 1;
        width: 16px;
        height: 16px;
        &:hover {
            cursor: pointer;
        }
    }
`;

export const ActionIconHolder = styled.div`
    width: 24px;
    height: 24px;
    padding: 4px;
`;

export const ActionWrapper = styled.div<{ $elements?: number }>`
    gap: 4px;
    display: flex;
    justify-content: flex-end;
    ${(props) => (props.$elements ? `width: ${props.$elements * 36 - 4}px` : '')};
`;

export const ActionCheckboxWrapper = styled.div`
    width: 24px;
    height: 24px;
    padding: 4px;

    svg {
        padding: 0;
    }
    a {
        margin-bottom: 0;
    }
`;

export const ActionContainer = styled.div`
    gap: 8px;
    display: flex;
    margin-left: auto;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;

    ${ArrowIconHolder} {
        svg {
            &:hover {
                cursor: pointer;
            }
            width: 16px;
            height: 16px;
            opacity: 1;
        }
    }

    ${ActionWrapper} {
        ${ActionIconHolder} {
            svg {
                width: 24px;
                height: 24px;
                cursor: pointer;
                opacity: 0.7;
                &:hover {
                    opacity: 1;
                }
            }

            display: none;
            &:last-child {
                display: block;
            }
        }
    }
`;

export const MobileActionContainer = styled.div`
    flex-direction: column;
    align-items: flex-end;
    padding-right: 16px;
    margin-left: auto;
    display: flex;
    gap: 8px;
`;

export const MobileActionsDropdown = styled.div`
    position: absolute;
    width: 200px;
    height: auto;
    top: 24px;
    right: 10px;
    background: ${theme.palette.white.main};
    display: none;
    border-radius: 4px;
    padding: 16px 0;
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.04);
    z-index: 9999;
`;

export const MobileActionsMenuWrapper = styled.div`
    justify-content: flex-end;
    position: relative;
    display: flex;
    gap: 4px;

    &:hover {
        ${MobileActionsDropdown} {
            display: block;
        }
    }
`;

export const MobileActionOption = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    gap: 12px;
    padding: 0 24px;
    cursor: pointer;

    svg {
        min-width: 24px;
        padding: 0;
    }
`;

export const MobileActionText = styled.div``;
