import styled, { css } from 'styled-components';
import { devices } from '../../../../style/styled-components/reusable.css';
import getTheme, { fontSizes } from '../../../../style/themes/theme';
import { SearchIcon, SearchInput } from '../SearchBar.css';

const theme: any = getTheme();

export const HeaderSearchInput = styled(SearchInput)`
    display: none;
    width: 0;
    border: 0;
    transition: 200ms all ease-in-out;
    position: relative;
    .MuiInputBase-root {
        width: 100%;
    }
`;

export const HeaderSearchIcon = styled(SearchIcon)`
    top: 8px;
    height: 20px;
`;

export const HeaderSearchWrapper = styled.div<{ open: boolean }>`
    position: relative;
    max-width: 480px;
    height: 36px;

    ${devices.tablet} {
        max-width: 100%;
    }

    &:hover {
        ${HeaderSearchInput} {
            display: block;
        }
    }

    ${(props) =>
        props.open
            ? css`
                  ${devices.tablet} {
                      width: 100%;
                  }
                  ${HeaderSearchInput} {
                      border: 1px solid ${theme.palette.background.border};
                      display: block;
                      width: 480px;
                      ${devices.tablet} {
                          width: 100%;
                      }
                  }
                  ${ResultsContainer} {
                      border: 1px solid ${theme.palette.background.border};
                  }
              `
            : css``}
`;

export const ResultsContainer = styled.div`
    position: absolute;
    width: 100%;
    max-height: 256px;
    background: ${theme.palette.white.main};
    z-index: 999;
    box-sizing: border-box;
    top: 36px;
    border-radius: 4px;
    overflow: hidden;
`;

export const ResultsSection = styled.div<{ loading?: boolean; seAll?: boolean }>`
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        ${(props) => !props.loading && `background: ${theme.palette.background.border}`};
    }
    ${(props) => (props.seAll ? fontSizes.Body1 : fontSizes.Body2)};
    padding: 12px;
`;

export const NoResultsSection = styled(ResultsSection)`
    display: flex;
    gap: 10px;
    cursor: unset;
`;

export const ResultsLoadingSkeleton = styled.div`
    width: 50%;
    height: 100%;
    background: ${theme.palette.background.border};
    animation: colorBounce 700ms linear infinite alternate;

    @keyframes colorBounce {
        0% {
            background: ${theme.palette.background.border};
        }
        100% {
            background: ${theme.palette.background.pressed};
        }
    }
`;
