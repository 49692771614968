import _ from 'lodash';
import { CIRCLE_SEPARATOR, objectLinks, SearchObject, searchTypes } from '../components/common/SearchBar/Header/HeaderSearch';
import { Language } from '../types/Language';
import { API_ERROR_CODES, EMPTY_WORD_STRING } from './Globals';
import circleSlugs from '../utils/search_slugs.json';
import { docsWidget } from '../components/App';

const keysToDecode = ['icon', 'logo', 'tv', 'desktop', 'tablet', 'phone', 'bigScreen', 'mobile', 'pc'];
export const encodedDataParser = (data: any) => {
    if (Array.isArray(data)) {
        data.forEach((item) => {
            if (typeof item === 'object') {
                encodedDataParser(item);
            }
        });
    }
    if (data && typeof data === 'object' && !Array.isArray(data)) {
        Object.entries(data || {}).forEach(([key, value]) => {
            if (typeof value === 'string' && keysToDecode.includes(key) && value) {
                data[key] = decodeURIComponent(value);
            }
            if (typeof value === 'object' && value) {
                encodedDataParser(data[key]);
            }
        });
    }
    return data;
};

export const entityDataParser = (response: any) => {
    if (response.success) {
        return encodedDataParser(response.data);
    }
    return null;
};

export const permissionsAndPaginationParser: (response: any) => { data: any; permissions: any; totalResults?: number } = (
    response: any
) => {
    if (!response?.data?.permissions) {
        return { ...response, totalResults: response?.data?.totalCount };
    }
    const { permissions, totalCount, ...rest } = response.data;

    return {
        data: rest,
        permissions,
        totalResults: totalCount
    };
};

export const authenticationErrorParser = (err: { code: string; message: string; data?: any }) => {
    const { code, message } = err;
    const result = {
        isWrongInput: false,
        message,
        password: false,
        twoFARequired: false,
        phoneNumberNotSet: false,
        data: undefined as any
    };
    if (code === API_ERROR_CODES.AUTHENTICATION_ERROR) {
        if (['email', 'password'].some((elem) => message?.toLowerCase().includes(elem))) {
            result.message = message.includes(':') ? message.split(':')[1].trim() : message;
            result.isWrongInput = true;
            result.password = message?.toLowerCase().includes('password');
        }
    }
    if (code === API_ERROR_CODES.AUTHENTICATION_ERROR_2FA_REQUIRED) {
        result.twoFARequired = true;
        result.data = err.data;
    }
    if (code === API_ERROR_CODES.AUTHENTICATION_ERROR_PHONE_NUMBER_NOT_SET) {
        result.phoneNumberNotSet = true;
        result.data = err.data;
    }
    return result;
};

export const audienceValidationErrorParser = (err: { code: string; message: string }) => {
    const { code, message } = err;
    const result: { errors: any; isSimilar: boolean } = {
        errors: null,
        isSimilar: false
    };
    if (code === API_ERROR_CODES.AUDIENCE_VALIDATION_ERROR_SIMILAR) {
        result.isSimilar = true;
    } else if (code === API_ERROR_CODES.AUDIENCE_VALIDATION_ERROR) {
        const commonErrorMessage = 'Provided data is not valid.';
        const errors = message.split(commonErrorMessage)[1].trim().split('\n');
        result.errors = {};
        errors.forEach((error) => {
            const [key, value] = error.trim().split('-');
            result.errors[key.trim()] = value.trim();
        });
    }
    return result;
};

export const changePasswordErrorParser = (err: { code: string; message: string }) => {
    const { code, message } = err;
    const result = {
        isWrongInput: false,
        message,
        oldPassword: false
    };
    if (code === API_ERROR_CODES.DATA_VALIDATION_ERROR) {
        if (['oldpassword', 'newpassword'].some((elem) => message?.toLowerCase().includes(elem))) {
            result.message = message.includes(':') ? message.split(':')[1].trim() : message;
            result.isWrongInput = true;
            result.oldPassword = message?.toLowerCase().includes('oldpassword');
        }
    }
    return result;
};

export const translationsParser = (data: Language[]) => {
    data.forEach((language) => {
        if (language.translations && typeof language.translations === 'string') {
            language.translations = JSON.parse(language.translations);
        }
    });

    return data;
};

export const parseSearchObjects = (objects: any) => {
    if (!objects) return [];
    const parsedObjects: any[] = [];
    Object.keys(objects).forEach((key) => {
        const itemsArray = objects[key];
        if (objects[key].length) {
            itemsArray.forEach((item: any) => {
                parsedObjects.push({
                    type: searchTypes.OBJECT,
                    path: objectLinks[key],
                    name: item?.title || item.name || EMPTY_WORD_STRING,
                    lastModified: item?.lastModified || 0,
                    object_type: key
                });
            });
        }
    });
    return parsedObjects;
};

export const parseSearchDocumentation = (slugs: any): SearchObject[] => {
    if (!slugs) return [];
    const parsedDocumentation: any[] = [];
    const onBoardingSlugs = circleSlugs['cc-onboarding'];

    slugs?.tutorials?.forEach((key: keyof typeof onBoardingSlugs) => {
        parsedDocumentation.push({
            type: searchTypes.TUTORIALS,
            name: _.upperFirst(slugNames?.[key]).split('_').join(' '),
            path: key,
            spaceSlug: 'cc-onboarding',
            tags: onBoardingSlugs?.[key]?.toString().split(CIRCLE_SEPARATOR)
        });
    });

    slugs?.documentation?.forEach((key: keyof typeof circleSlugs) => {
        const spaceSlugs = ['control-center-news', 'cc-faq', 'cc-documentation'];
        const isSpaceSlug = spaceSlugs.includes(key);
        parsedDocumentation.push({
            type: searchTypes.DOCUMENTATION,
            name: _.upperFirst(slugNames?.[key]).split('_').join(' '),
            path: isSpaceSlug ? '' : key,
            tags: circleSlugs?.[key]?.toString().split(CIRCLE_SEPARATOR),
            spaceSlug: !!isSpaceSlug ? key : undefined
        });
    });

    return parsedDocumentation;
};

export const openDocumentation = (key: string, fullUrl?: boolean) => {
    if (!docsWidget) return;
    docsWidget.setSlug(key, '', fullUrl);
    return docsWidget.element?.click();
};

export const slugNames: any = {
    page: 'page',
    login: 'login',
    projects: 'projects',
    users: 'users',
    'user-groups': 'user_groups',
    'manage-users': 'users_and_groups ',
    'profile-management': 'profile',
    'dashboard-getting-started': 'dashboard',
    'target-groups': 'target_groups',
    'ux-content': 'ux_content',
    audience: 'audience',
    pages: 'pages',
    'page-types': 'templates',
    modules: 'modules',
    items: 'items',
    menus: 'menus',
    'style-branding': 'style_and_branding',
    'app-branding': 'app_branding',
    'page-style': 'page_style',
    'settings-702629': 'settings',
    sources: 'sources',
    languages: 'languages',
    conditions: 'display_conditions',
    'file-manager': 'file_manager',
    insights: 'insights',
    templates: 'templates',
    swagger: 'swagger',
    'cc-documentation': 'CC_Documentation',
    'cc-faq': 'FAQ',
    'control-center-news': 'news',
    'onboarding-login-and-projects': 'projects',
    'onboarding-manage-users': 'users',
    'onboarding-user-groups': 'user_groups',
    'onboarding-profile-management': 'profile',
    'learning-course-getting-started-817bc8': 'getting_started',
    'onboarding-content-items': 'items',
    'onboarding-target-groups': 'target_groups',
    'onboarding-ux-content': 'ux_content',
    'onboarding-audiences': 'audiences',
    'onboarding-content-modules': 'modules',
    'onboarding-pages': 'pages',
    'onboarding-page-types': 'page_types',
    'onboarding-visual-editor': 'visual_editor',
    'onboarding-dynamic-content-item': 'list',
    'onboarding-editorial-content-item': 'item',
    'onboarding-menus': 'menus',
    'onboarding-settings': 'settings',
    'onboarding-sources': 'sources',
    'onboarding-style-and-branding': 'style_and_branding',
    'onboarding-file-manager': 'file_manager',
    'onboarding-insights': 'insights',
    'onboarding-languages': 'languages',
    'onboarding-display-conditions': 'display_conditions'
};
