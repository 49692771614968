import { FC, useEffect, useMemo, useState } from 'react';
import GenericDialog, { DialogButton, DialogDropdownMultiple, DialogTypes } from '../../common/Dialog/GenericDialog';
import { MissingAudiencesTitle, MissingAudiencesWrapper } from '../GroupEdit.css';
import { EMPTY_WORD_STRING } from '../../../utils/Globals';
import useTranslation from '../../../hooks/useTranslation';
import { FeatureFlag } from '../../../types/FeatureFlags';
import { Setting } from '../../../types/Setting';
import { Page } from '../../../types/Page';
import { Menu } from '../../../types/Menu';

const toolTipText = 'The objects will be updated with the new audiences only after saving the group';
type AddAudienceToGroupObjectsDialogProps = {
    open: boolean;
    onClose: () => void;
    onAdd: (ids: string[]) => void;
    onDontAdd: () => void;
    objects: { menus?: Menu[]; pages?: Page[]; settings?: Setting[]; featureFlags?: FeatureFlag[] };
    audienceName?: string;
};

export const AddAudienceToGroupObjectsDialog: FC<AddAudienceToGroupObjectsDialogProps> = ({
    open,
    audienceName,
    objects,
    onClose,
    onAdd,
    onDontAdd
}) => {
    const { translate } = useTranslation();

    const [selectedPages, setSelectedPages] = useState<string[]>([]);
    const [selectedMenus, setSelectedMenus] = useState<string[]>([]);
    const [selectedSettings, setSelectedSettings] = useState<string[]>([]);
    const [selectedFeatureFlags, setSelectedFeatureFlags] = useState<string[]>([]);
    const handleAddClick = () => {
        onAdd([...selectedPages, ...selectedMenus, ...selectedSettings, ...selectedFeatureFlags]);
        handleCloseClick();
    };

    useEffect(() => {
        setSelectedPages(objects.pages?.map((p) => p._id) || []);
        setSelectedMenus(objects.menus?.map((m) => m._id) || []);
        setSelectedSettings(objects.settings?.map((s) => s._id) || []);
        setSelectedFeatureFlags(objects.featureFlags?.map((f) => f._id) || []);
    }, [objects]);

    const pageOptions = useMemo(() => {
        return objects.pages?.map((page) => ({ value: page._id, label: page.name || EMPTY_WORD_STRING })) || [];
    }, [objects.pages]);

    const menuOptions = useMemo(() => {
        return objects.menus?.map((menu) => ({ value: menu._id, label: menu.name || EMPTY_WORD_STRING })) || [];
    }, [objects.menus]);

    const settingsOptions = useMemo(() => {
        return objects.settings?.map((setting) => ({ value: setting._id, label: setting.name || EMPTY_WORD_STRING })) || [];
    }, [objects.settings]);

    const featureFlagOptions = useMemo(() => {
        return objects.featureFlags?.map((featureFlag) => ({ value: featureFlag._id, label: featureFlag.name || EMPTY_WORD_STRING })) || [];
    }, [objects.featureFlags]);

    const handleCloseClick = () => {
        setSelectedPages([]);
        setSelectedMenus([]);
        setSelectedSettings([]);
        setSelectedFeatureFlags([]);
        onClose();
    };

    const handleDontAdd = () => {
        onDontAdd();
        handleCloseClick();
    };

    const addButton: DialogButton = {
        label: 'Add',
        type: 'DEFAULT',
        onClick: handleAddClick
    };

    const dontAddButton: DialogButton = {
        label: `Don't Add`,
        type: 'BLUE',
        onClick: handleDontAdd
    };

    if (!open) return null;

    return (
        <GenericDialog
            type={DialogTypes.Form}
            title={"Some objects don't have this Audience"}
            dataCy={"confirm-dialog-Some objects don't have this Audience"}
            actionButtons={[addButton, dontAddButton]}
            onClose={handleCloseClick}
        >
            <MissingAudiencesWrapper>
                <MissingAudiencesTitle>
                    {`Do you wish to add audience '${audienceName || EMPTY_WORD_STRING}' to the following objects?`}
                </MissingAudiencesTitle>
                {/*MENUS*/}
                {!!menuOptions.length && (
                    <DialogDropdownMultiple
                        allowSelectAll
                        key={'menus'}
                        onChange={(selectedOptions: any[]) => {
                            setSelectedMenus(selectedOptions?.map((so) => so.value) || []);
                        }}
                        placeholder={''}
                        labelText={'Menus'}
                        options={menuOptions}
                        value={menuOptions.filter((opt) => selectedMenus.includes(opt.value))}
                    />
                )}
                {/*PAGES*/}
                {!!pageOptions.length && (
                    <DialogDropdownMultiple
                        allowSelectAll
                        key={'pages'}
                        onChange={(selectedOptions: any[]) => {
                            setSelectedPages(selectedOptions?.map((so) => so.value) || []);
                        }}
                        placeholder={''}
                        labelText={'Pages'}
                        options={pageOptions}
                        value={pageOptions.filter((opt) => selectedPages.includes(opt.value))}
                    />
                )}
                {/*SETTINGS*/}
                {!!settingsOptions.length && (
                    <DialogDropdownMultiple
                        allowSelectAll
                        key={'settings'}
                        onChange={(selectedOptions: any[]) => {
                            setSelectedSettings(selectedOptions?.map((so) => so.value) || []);
                        }}
                        placeholder={''}
                        labelText={'Settings'}
                        options={settingsOptions}
                        value={settingsOptions.filter((opt) => selectedSettings.includes(opt.value))}
                    />
                )}
                {/*FEATURE FLAGS*/}
                {!!featureFlagOptions.length && (
                    <DialogDropdownMultiple
                        allowSelectAll
                        key={'featureFlags'}
                        onChange={(selectedOptions: any[]) => {
                            setSelectedFeatureFlags(selectedOptions?.map((so) => so.value) || []);
                        }}
                        placeholder={''}
                        labelText={'Feature Flags'}
                        options={featureFlagOptions}
                        value={featureFlagOptions.filter((opt) => selectedFeatureFlags.includes(opt.value))}
                    />
                )}
            </MissingAudiencesWrapper>
        </GenericDialog>
    );
};
