import React, { useEffect, useState } from 'react';
import GenericDialog, { DialogTypes } from '../GenericDialog';

import FileManager from '../../../FileManager/FileManager';
import { DialogFileManagerWrapper } from '../../../FileManager/FileManager.css';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../../HelpIcon/HelpIcon';

export type FileManagerDialogProps = {
    open: boolean;
    onClose: any;
    onFileClick: any;
};

const FileManagerDialog: React.FC<FileManagerDialogProps> = ({ open, onClose, onFileClick }) => {
    const [isOpen, setIsOpen] = useState(open);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const handleCloseClick = (evt: any) => {
        evt.preventDefault();
        if (onClose) {
            onClose();
        }
    };

    const handleFileClick = (url: any) => {
        onFileClick?.(url);
    };

    if (!isOpen) {
        return null;
    }

    return (
        <GenericDialog
            title={``}
            type={DialogTypes.FileManager}
            onClose={handleCloseClick}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.file_manager }}
        >
            <DialogFileManagerWrapper>
                <FileManager openInDialog onFileClick={handleFileClick} />
            </DialogFileManagerWrapper>
        </GenericDialog>
    );
};

export default FileManagerDialog;
