import React, { useEffect, useRef, useState } from 'react';
import { dialogAlert, ToastAlert } from '../../../utils/fnDialogs';
import { DialogTextField } from '../../common/Dialog/GenericDialog';

import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { setUserProfile } from '../../../redux/slices/profileSlice';
import { setUserPermissions } from '../../../redux/slices/permissionsSlice';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { AuthState, logUserInWithOTP } from '../../../redux/slices/authSlice';
import configServiceAPI from '../../../utils/api/configServiceAPI';
import { FormDescription, FormSubtitle, FormTitle, FormWrapper, loginButtonStyle, ResendLabel, sendOtpButtonStyle } from '../Login.css';
import { PageRoutes } from '../../../types/RouteTypes';
import Button from '../../Buttons/Button/Button';
import { API_ERROR_CODES } from '../../../utils/Globals';

export type TwoFactorAuthenticationDialogProps = {
    data: any;
};

const TwoFactorAuthentication: React.FC<TwoFactorAuthenticationDialogProps> = ({ data }) => {
    const [otp, setOtp] = useState('');
    const otpInputRef = useRef<HTMLInputElement>();
    const { loading }: AuthState = useAppSelector((state) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        otpInputRef.current?.focus();
    }, [data]);

    const handleVerifyOTPClick = async (evt: any) => {
        evt.preventDefault();
        try {
            const response = await dispatch(
                logUserInWithOTP({
                    phoneNumber: data.phoneNumber,
                    otp,
                    email: data.email,
                    password: data.password,
                    rememberMe: data.rememberMe
                })
            ).unwrap();
            if (response.userData.userInfo) {
                dispatch(setUserProfile(response.userData.userInfo));
            }
            if (response.userData.permissions) {
                dispatch(setUserPermissions(response.userData.permissions));
            }
            navigate(PageRoutes.PROJECTS);
        } catch (err) {
            if (!err) return;
            let title = 'Code could not be verified';
            let text = 'The Code could not be verified!';
            if (err.code === API_ERROR_CODES.AUTHENTIFICATION_ERROR_OTP_NOT_VALID) {
                title = err.message;
                text = err.message;
            }
            const values = {
                title,
                text
            };
            dialogAlert('', false, values);
        }
    };

    const handleResendClick = async (evt: any) => {
        evt.preventDefault();
        const result = await configServiceAPI.resendOTP(data.phoneNumber);
        let values: any = {};
        if (!result.response?.data) {
            values.title = 'The code could not be sent';
            values.text = 'The Code could not be sent to the given phone number! ' + (result?.error?.message || '');
            dialogAlert('', false, values);
            return;
        }
        ToastAlert('success', 'Success', 'The Code has been sent to the specified phone number.');
    };

    return (
        <FormWrapper>
            <FormDescription style={{ marginTop: '16px' }}>
                Please enter the Code that was sent to the phone below {'******' + data?.phoneNumber.slice(-4)}
            </FormDescription>
            <DialogTextField
                inputRef={otpInputRef}
                value={otp}
                label={'Enter the Code received via SMS'}
                type={'text'}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = evt.target;
                    setOtp(value);
                }}
                onKeyPress={(evt: any) => {
                    if (evt.key === 'Enter') handleVerifyOTPClick(evt);
                }}
            />
            <ResendLabel onClick={handleResendClick}>{`Didn't get an SMS? Resend Code!`}</ResendLabel>
            <Button
                onClick={handleVerifyOTPClick}
                loading={loading}
                id="verify-otp-button"
                label={'Next'}
                type="BLUE"
                style={sendOtpButtonStyle}
            />
        </FormWrapper>
    );
};

export default TwoFactorAuthentication;
