import styled from 'styled-components';
import { StyledSVGInline } from '../../../style/styled-components/reusable.css';
import getTheme, { fontSizesCSS, zIndexesCSS } from '../../../style/themes/theme';

const theme: any = getTheme();

export const DropdownWrapper = styled.div`
    z-index: ${zIndexesCSS.zIndexDropdown};
`;

export const DropdownContent = styled.div<{
    showMenuContent: boolean;
    verticalPosition: string;
    horizontalPosition: string;
    disabled?: boolean;
}>`
    transition: opacity 0.3s ease-in-out;
    opacity: ${({ showMenuContent, disabled }) => (showMenuContent ? (disabled ? '0.3' : '1') : '0')};
    pointer-events: ${({ showMenuContent, disabled }) => (showMenuContent && !disabled ? 'all' : 'none')};
    background: ${theme.palette.white.main};

    left: ${(props: any) => (props.horizontalPosition === 'left' ? '0' : 'auto')};
    right: ${(props: any) => (props.horizontalPosition === 'left' ? 'auto' : '0')};
    top: ${(props: any) => (props.verticalPosition === 'bottom' ? '100%' : 'auto')};
    bottom: ${(props: any) => (props.verticalPosition === 'bottom' ? 'auto' : '100%')};
    z-index: ${zIndexesCSS.zIndexDropdown};
`;

export const DropdownContentModified = styled(DropdownContent)`
    display: ${(props) => (props.showMenuContent ? 'block' : 'none')};
    margin-bottom: 0;
`;

export const DropdownMenuList = styled.div``;

export const DropdownLabel = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
    height: 24px;
    line-height: 24px;
    color: #333333;
    white-space: nowrap;
    text-decoration: none;
`;

export const DropdownItemWrapper = styled.a`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 8px 0 12px 0;
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        padding-bottom: 0;
    }
    .user-role {
        display: flex;
        color: #ffffff;
        width: 48px;
        height: 20px;
        border-radius: 4px;
        ${fontSizesCSS.Caption3};
        text-align: center;
        text-transform: uppercase;
        align-items: flex-end;
        justify-content: center;
        margin-left: 40px;
    }
`;

export const SVGWrapper = styled.span`
    padding-right: 8px;

    ${StyledSVGInline} {
        padding: 0;
        width: 22px;
        height: 22px;
    }
`;

export const DropdownItemSimpleText = styled.a`
    color: rgba(51, 51, 51, 0.8);
    position: relative;
`;
