import { useEffect, useState } from 'react';
import { SCREEN_SIZES } from '../style/styled-components/reusable.css';

export type ScreenType = keyof typeof SCREEN_SIZES;

const useScreenSize = () => {
    const [screenType, setScreenType] = useState<ScreenType | undefined>(undefined);
    const [screenSize, setScreenSize] = useState<number>(0);
    useEffect(() => {
        const onResize = () => {
            if (window.innerWidth <= SCREEN_SIZES.MOBILE_S) {
                setScreenType('MOBILE_S');
                setScreenSize(window.innerWidth);
                return;
            }

            Object.entries(SCREEN_SIZES).some(([key, value]) => {
                if (600 >= window.innerWidth) {
                    setScreenType('MOBILE_S');
                    setScreenSize(window.innerWidth);
                    return true;
                }
                if (value < window.innerWidth) {
                    setScreenType(key as ScreenType);
                    setScreenSize(window.innerWidth);
                    return true;
                }
                return false;
            });
        };
        onResize();
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);

    const isSmallMobile = screenType === 'MOBILE_S';
    const isMobile = screenType === 'MOBILE_L' || screenType === 'MOBILE_S';
    const isTablet = screenType === 'TABLET';
    // desktop is true by default if screenType is not calculated yet
    const isDesktop = !screenType || screenType === 'DESKTOP_S' || screenType === 'DESKTOP_L';
    const isLargeDesktop = screenType === 'DESKTOP_L';

    return { screenSize, screenType, isSmallMobile, isMobile, isTablet, isDesktop, isLargeDesktop };
};

export default useScreenSize;
