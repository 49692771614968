import React, { FC } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { PieChartWrapper } from './ChartWrappers.css';
import getTheme from '../../../style/themes/theme';
import { chartColorPalette } from './ChartUtils';
import { PlatformAnalyticsData } from '../../../redux/slices/analyticsSlice';

const theme: any = getTheme();

const AnalyticsPieChart: FC<{ data: PlatformAnalyticsData; onHoverData?: (hoveredDataId: string) => void }> = ({ data, onHoverData }) => {
    return (
        <PieChartWrapper>
            <ResponsivePie
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                data={data}
                innerRadius={0.5}
                padAngle={0}
                activeOuterRadiusOffset={2}
                activeInnerRadiusOffset={2}
                borderWidth={0}
                borderColor={theme.palette.white.main}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                colors={chartColorPalette}
                onMouseEnter={(data, event) => {
                    onHoverData?.(data.id.toString());
                    if (event.target instanceof SVGPathElement) {
                        event.target.style.filter = theme.palette.boxShadow.pieChartArc;
                        event.target.style.strokeWidth = '2';
                    }
                }}
                onMouseLeave={(_data, event) => {
                    onHoverData?.('');
                    if (event.target instanceof SVGPathElement) {
                        event.target.style.filter = 'unset';
                        event.target.style.strokeWidth = '0';
                    }
                }}
                sortByValue
            />
        </PieChartWrapper>
    );
};

export default AnalyticsPieChart;
