import styled, { css } from 'styled-components';
import getTheme, { fontSizes } from '../../style/themes/theme';
import { SelectWrapper } from '../common/Select/Select.css';
import { devices } from '../../style/styled-components/reusable.css';

const theme: any = getTheme();

export const SearchPageContainer = styled.div``;

export const FilterRow = styled.div`
    display: flex;
    height: 40px;
    width: 100%;

    ${devices.tablet} {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 24px;
    }
`;

export const FilterTabs = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    gap: 10px;
    ${devices.tablet} {
        width: 100%;
    }
`;

export const FilterTab = styled.div<{ isActive?: boolean }>`
    padding: 8px 16px;
    cursor: pointer;
    ${fontSizes.Body2};

    ${(props) =>
        props.isActive
            ? css`
                  color: ${theme.palette.text.main};
                  background: ${theme.palette.background.active};
              `
            : css`
                  color: ${theme.palette.background.hover};
              `}
`;

export const SortField = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    ${SelectWrapper} {
        width: 226px;

        ${devices.tablet} {
            width: 100%;
        }
    }

    ${devices.tablet} {
        width: 100%;
    }
`;

export const ResultsContainer = styled.div`
    margin-top: 24px;
    padding: 20px 0;
    width: 100%;
`;

export const ResultWrapper = styled.div`
    width: 100%;
    height: 160px;
    background: ${theme.palette.white.main};
    margin-bottom: 8px;
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 20px 24px;
    box-sizing: border-box;
    gap: 24px;
    overflow: hidden;
`;

export const ResultContent = styled.div`
    width: 70%;
`;

export const ResultType = styled.div`
    ${fontSizes.Body2};
    color: ${theme.palette.background.hover};
    margin-bottom: 8px;
`;

export const ResultTitle = styled.div`
    ${fontSizes.Body2};
`;

export const ResultSubtitle = styled.div`
    ${fontSizes.Caption1};
`;
export const ResultTags = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    margin-top: 8px;
`;

export const ResultsLabels = styled.div`
    margin-top: 8px;
`;

export const NoContentRow = styled.div`
    width: 100%;
    height: 72px;
    background: ${theme.palette.white.main};
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 24px 20px;
    box-sizing: border-box;
`;

export const NoContentIcon = styled.div`
    width: 28px;
    height: 24px;
    svg {
        width: 100%;
        height: 100%;
    }
`;

export const NoContentText = styled.div`
    ${fontSizes.Body2};
`;
export const ResultDate = styled.div`
    width: 20%;
`;

export const PreviewContainer = styled.div<{ image: string }>`
    min-width: 213px;
    height: 120px;
    background: url(${(props) => `'${props.image}'`});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

export const MobileResultWrapper = styled.div`
    width: 100%;
    min-height: 188px;
    background: ${theme.palette.white.main};
    margin-bottom: 8px;
    cursor: pointer;
    padding: 20px 24px;
    box-sizing: border-box;
    overflow: hidden;
`;

export const MobileHeaderSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 14px;
    color: ${theme.palette.background.hover};

    ${ResultDate} {
        margin-left: auto;
    }
    ${ResultType} {
        margin: unset;
    }
`;

export const MobileResultContent = styled.div``;

export const MobilePreviewContainer = styled(PreviewContainer)`
    width: 100%;
    height: 230px;
    background-size: cover;
    margin-bottom: 8px;
`;
