import React, { FC, useEffect, useState } from 'react';
import {
    CloudIconWrapper,
    DragAndDropSuggestionText,
    DragAndDropSuggestionWrapper,
    DraggedFilePreview,
    FileManagerDNDSuggestionZone,
    FileManagerDraggableFileCardWrapper,
    FileManagerStyledDraggableRow,
    UploadDropZoneContainer
} from './FileManager.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../assets/images/icons';
import { useDropzone } from 'react-dropzone';
import { DragLayerMonitor, XYCoord, useDrag, useDragLayer, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import fileExtensionIcons from '../../assets/images/icons/fileExtensionIcons';
import { imgExtensions, videoExtensions } from './FileManager';
import { FILE_MANAGER_DRAGGABLE_TYPES } from '../../utils/Globals';
import _ from 'lodash';
import { FilesState } from '../../redux/slices/fileManagerSlice';
import { useAppSelector } from '../../hooks/redux';

export const FileManagerDNDSuggestion = () => {
    return (
        <FileManagerDNDSuggestionZone>
            <DragAndDropSuggestionWrapper>
                <CloudIconWrapper>
                    <SVGInline src={icons.cloudUploadIcon} />
                </CloudIconWrapper>
                <DragAndDropSuggestionText>Drag and Drop files from your device into the File Manager</DragAndDropSuggestionText>
            </DragAndDropSuggestionWrapper>
        </FileManagerDNDSuggestionZone>
    );
};

export const UploadDropZone: FC<{ onDrop: any; accept: any; inDialog?: boolean }> = ({ onDrop, accept, inDialog }) => {
    const { getRootProps, isDragActive } = useDropzone({
        onDrop,
        accept
    });
    const [isDragging, setIsDragging] = useState(false);

    // We need event listeners on window for dragging, so we can set the pointer event of the UploadZoneContainer via the isVisible prop
    useEffect(() => {
        window.addEventListener('dragenter', () => setIsDragging(true));
        window.addEventListener('dragleave', () => setIsDragging(false));
        return () => {
            window.removeEventListener('dragenter', () => setIsDragging(true));
            window.removeEventListener('dragleave', () => setIsDragging(false));
        };
    }, []);
    return (
        <UploadDropZoneContainer {...getRootProps()} isVisible={isDragActive || isDragging} inDialog={inDialog}>
            <CloudIconWrapper>
                <SVGInline src={icons.cloudUploadIcon} />
            </CloudIconWrapper>
            <DragAndDropSuggestionText>Drop Files to upload</DragAndDropSuggestionText>
        </UploadDropZoneContainer>
    );
};

export const FileManagerDraggableFile: FC<{
    view: 'LIST' | 'GRID';
    type: string;
    name: string;
    moveFileIntoFolder?: (fileName: string, destinationFolder: string) => void;
    onClick?: () => void;
    filesPendingEncode: any;
}> = ({ view, type, name, moveFileIntoFolder, onClick, filesPendingEncode, children }) => {
    const { uploadFilesProgress, files }: FilesState = useAppSelector((state) => state.files);
    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: type,
            // data of the item to be available to the drop methods
            item: { type, name: name },
            // method to collect additional data for drop handling like whether is currently being dragged
            collect: (monitor) => {
                return {
                    isDragging: monitor.isDragging()
                };
            },
            canDrag: type === FILE_MANAGER_DRAGGABLE_TYPES.FILE
        }),
        [type, name]
    );

    const [{ isHovering }, drop] = useDrop(
        {
            accept: FILE_MANAGER_DRAGGABLE_TYPES.FILE,
            canDrop: () => type === FILE_MANAGER_DRAGGABLE_TYPES.FOLDER,
            drop(_, monitor) {
                /**
                 *  At this part of the code, we are in the component on which the "item" is dropped, meaning,
                 *  item = draggedFile , currentComponent = destinationFolder
                 */
                const destinationFolderName = name;
                const item = monitor.getItem<{ name: string }>();
                moveFileIntoFolder?.(item.name, destinationFolderName);
            },
            collect(monitor) {
                return {
                    isHovering: monitor.isOver() && type === FILE_MANAGER_DRAGGABLE_TYPES.FOLDER
                };
            }
        },
        [
            name,
            type,
            files,
            filesPendingEncode,
            uploadFilesProgress /*must add files,filesPendingEncode,uploadFilesProgress here, otherwise the moveFileIntoFolder will have an outdated values, and blocking will not happen properly*/
        ]
    );

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    const renderListView = () => {
        return (
            <FileManagerStyledDraggableRow ref={(node) => drag(drop(node))} isDragging={isDragging} isHovering={isHovering}>
                {children}
            </FileManagerStyledDraggableRow>
        );
    };

    const renderGridView = () => {
        return (
            <FileManagerDraggableFileCardWrapper
                ref={(node) => drag(drop(node))}
                isDragging={isDragging}
                isHovering={isHovering}
                onClick={() => onClick?.()}
            >
                {children}
            </FileManagerDraggableFileCardWrapper>
        );
    };

    return view === 'GRID' ? renderGridView() : renderListView();
};

const FileManagerCustomDragLayer: React.FC<{
    onDragging: (isDragging: boolean) => void;
    scrollOnDrag: (currentOffset: XYCoord) => void;
}> = ({ onDragging, scrollOnDrag }) => {
    const { isDragging, currentOffset, item } = useDragLayer((monitor: DragLayerMonitor) => {
        return {
            isDragging: monitor.isDragging(),
            currentOffset: monitor.getClientOffset(),
            item: monitor.getItem()
        };
    });

    useEffect(() => {
        if (!currentOffset) return;
        scrollOnDrag(currentOffset);
    }, [currentOffset]);

    useEffect(() => {
        if (isDragging && item?.type !== 'FILE') return;
        onDragging(isDragging);
    }, [isDragging, item]);
    if (![FILE_MANAGER_DRAGGABLE_TYPES.FILE, FILE_MANAGER_DRAGGABLE_TYPES.FOLDER].includes(item?.type)) {
        return null;
    }
    const getExtensionIcon = () => {
        const extension = _.last<string>(item.name.split('.')) || '';
        switch (true) {
            case extension === 'pdf':
                return <SVGInline src={fileExtensionIcons.iconPdfExtension} />;
            case imgExtensions.includes(extension):
                return <SVGInline src={fileExtensionIcons.iconImageExtension} />;
            case videoExtensions.includes(extension):
                return <SVGInline src={fileExtensionIcons.iconVideoExtension} />;
            case ['mp3'].includes(extension):
                return <SVGInline src={fileExtensionIcons.iconAudioExtension} />;
            case extension === 'svg':
                return <SVGInline src={fileExtensionIcons.iconSvgExtension} />;
            default:
                return <SVGInline src={fileExtensionIcons.iconUnknownExtension} />;
        }
    };
    return isDragging && currentOffset !== null ? (
        <DraggedFilePreview
            style={{
                //Functional style
                transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`
            }}
        >
            {getExtensionIcon()}
            {item.name}
        </DraggedFilePreview>
    ) : null;
};

export default FileManagerCustomDragLayer;
