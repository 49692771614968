import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { Application, ApplicationValues } from '../../components/Applications/ApplicationDialog';

export interface ApplicationsState {
    applications: { [key: string]: ApplicationValues };
    loading: boolean;
    error: {
        message: string;
        code?: string;
    } | null;
}

const initialState: ApplicationsState = {
    applications: {},
    error: null,
    loading: false
};

export const fetchApplications = createAsyncThunk<{ applications: any; error: { message: string; code: string } | null }>(
    'applications/fetchApplications',
    async (_, thunkApi) => {
        const result = await ConfigServiceAPI.getApplications();

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { applications: result.response as any, error: null };
    }
);

export const createApplication = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, Application>(
    'applications/createApplication',
    async (application, thunkApi) => {
        const result = await ConfigServiceAPI.createApplication(application);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export const updateApplication = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, Application>(
    'applications/updateApplications',
    async (application, thunkApi) => {
        const result = await ConfigServiceAPI.updateApplications(application);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export const deleteApplication = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    {
        applicationKey: string;
    }
>('applications/deleteApplication', async ({ applicationKey }, thunkApi) => {
    const result = await ConfigServiceAPI.deleteApplication(applicationKey);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

const slice = createSlice({
    name: 'applications',
    initialState,
    reducers: {
        unsetApplicationsError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchApplications.fulfilled, (state, action) => {
                state.applications = action.payload.applications || {};
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchApplications.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.applications = {};
                state.loading = false;
            })
            .addCase(fetchApplications.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createApplication.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createApplication.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createApplication.rejected, (state, action: any) => {
                state.loading = false;
                state.error = { ...action.payload.error, status: action.payload.status };
            })
            .addCase(updateApplication.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateApplication.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateApplication.rejected, (state, action: any) => {
                state.loading = false;
                state.error = { ...action.payload.error, status: action.payload.status };
            })
            .addCase(deleteApplication.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteApplication.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteApplication.rejected, (state, action: any) => {
                state.loading = false;
                state.error = { ...action.payload.error, status: action.payload.status };
            });
    }
});

export const { unsetApplicationsError } = slice.actions;

export default slice.reducer;
