import { Tenant } from './Tenant';
import { User } from './User';

export type Project = {
    _id: string;
    name: string;
    key: string;
    logo?: string;
    lastModified?: number;
    projectLeadIds?: string[];
    projectLeads?: User[];
    tenantId: string;
    tenant?: Tenant;
    description?: string;
    extraPages?: any[];
    restrictedSections?: RestrictedSection;
    secretPasscode?: string;
    previewTokens?: Record<string, string>;
};

export enum restrictedSectionsTypes {
    TEMPLATES = 'templates',
    SERVICES = 'services',
    MISCELLANEOUS = 'miscellaneous',
    CAPABILITIES = 'capabilities'
}

export type RestrictedSection = {
    [value in restrictedSectionsTypes]?: string[];
};
