import styled from 'styled-components';
import getTheme, { fontSizesCSS, zIndexesCSS } from '../../../style/themes/theme';
import { GradientBackgroundToRight } from '../../PageEdit/VisualEditor.css';

const theme: any = getTheme();

export const HintContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    z-index: ${zIndexesCSS.zIndexMax};

    ${GradientBackgroundToRight} {
        z-index: -1;
        opacity: 0.6;
    }
`;

export const HintMainSection = styled.div`
    height: 276px;
    width: 100%;
    padding: 0 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
`;

export const HintIcon = styled.div`
    width: 64px;
    height: 64px;
`;

export const HintText = styled.div`
    padding: 0 160px;
    width: 100%;
    text-align: center;
    color: ${theme.palette.white.main};
`;

export const HintTitle = styled.div`
    margin-bottom: 24px;
    ${fontSizesCSS.HintTitle}
`;

export const HintSubtitle = styled.div`
    ${fontSizesCSS.HintSubtitle}
`;
