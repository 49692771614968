import React, { useEffect, useState } from 'react';
import { ApplicationWrapper, MainContentWrapper } from '../../style/styled-components/reusable.css';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';

import Sidebar from '../common/Sidebar/Sidebar';
import { AnalyticsFilterDropdownWrapper, AnalyticsFiltersContainer, AnalyticsWidgetsLayout, NoDataContainer } from './Analytics.css';
import { DialogDropdownSingle } from '../common/Dialog/GenericDialog';
import {
    calcSumOfDataEntries,
    calculateComparedTimeframeLabel,
    CHART_KEY_TO_INITIAL_WIDGET_TYPE_MAPPING,
    CHART_KEYS_TO_TILE_MAPPING,
    createLabelFromStartEndDate,
    formatNumber,
    WidgetKeysWithCharts,
    WidgetKeysWithoutCharts,
    WidgetTypes
} from './Charts/ChartUtils';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import { AnalyticsSlice, fetchPlatformAnalytics, PlatformAnalyticsData } from '../../redux/slices/analyticsSlice';
import ChartlessWidget from './Widgets/ChartlessWidget';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import { Loader } from '../common/Loader/Loader';
import PieChartWidget from './Widgets/PieChartWidget';
import BarChartWidget from './Widgets/BarChartWidget';
import TableChartWidget from './Widgets/TableChartWidget';
import { DatePickerDialog } from '../common/DatePicker/DatePicker';
import { ONE_DAY_SECONDS } from '../common/TimePicker/TimePicker';
import { DEVICE_CATEGORIES, deviceCategoryOptions, TIMEFRAME_LABEL_MAPPING, TIMEFRAME_VALUES } from './AnalyticsConstants';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { Capabilities, CapabilitiesScreen } from '../Capabilities/CapabilitiesTopScreen';
import useRestrictedProjectAccess from '../../hooks/useRestrictedAccess';
import { restrictedSectionsTypes } from '../../types/Project';
import { ProjectsState } from '../../redux/slices/projectsSlice';
import moment from 'moment';

const Analytics: React.FC = () => {
    const { platformData, loading, error }: AnalyticsSlice = useAppSelector((state) => state.analytics);
    const { loading: projectsLoading }: ProjectsState = useAppSelector((state) => state.projects);
    const { isRestrictedArea: isAnalyticsRestricted } = useRestrictedProjectAccess(restrictedSectionsTypes.CAPABILITIES, 'analyze');
    // TODOD, next iteration, add posibility to change the chart type, based on the allowed types for that data Format
    const [widgetChartMapping, setWidgetChartMapping] = useState(CHART_KEY_TO_INITIAL_WIDGET_TYPE_MAPPING);
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);

    const [openDatepicker, setOpenDatepicker] = useState(false);

    const [timeframeOptions, setTimeframeOptions] = useState<{ label: string; value: string }[]>([
        { value: TIMEFRAME_VALUES.yesterday, label: TIMEFRAME_LABEL_MAPPING.yesterday },
        { value: TIMEFRAME_VALUES.last_7_days, label: TIMEFRAME_LABEL_MAPPING.last_7_days },
        { value: TIMEFRAME_VALUES.last_30_days, label: TIMEFRAME_LABEL_MAPPING.last_30_days },
        { value: TIMEFRAME_VALUES.custom, label: TIMEFRAME_LABEL_MAPPING.custom }
    ]);
    const [selectedDeviceCategory, setSelectedDeviceCategory] = useState<string>(DEVICE_CATEGORIES.all);
    const [selectedTimeframe, setSelectedTimeframe] = useState<typeof TIMEFRAME_VALUES[keyof typeof TIMEFRAME_VALUES]>(
        TIMEFRAME_VALUES.yesterday
    );
    const [customDate, setCustomDate] = useState<{ startDate: number; endDate: number } | undefined>(undefined);

    const dispatch = useDispatch();
    const loadPlatformAnalytics = () => {
        let toDate = Math.floor(moment().startOf('day').unix());
        let fromDate = toDate - ONE_DAY_SECONDS; // yesterday
        if (selectedTimeframe === TIMEFRAME_LABEL_MAPPING.custom_placeholder && customDate) {
            toDate = customDate.endDate;
            fromDate = customDate.startDate;
        }
        if (selectedTimeframe === TIMEFRAME_VALUES.last_7_days) {
            fromDate = toDate - 7 * ONE_DAY_SECONDS;
        }
        if (selectedTimeframe === TIMEFRAME_VALUES.last_30_days) {
            fromDate = toDate - 30 * ONE_DAY_SECONDS;
        }
        dispatch(
            fetchPlatformAnalytics({
                fromDate: fromDate,
                toDate: toDate,
                deviceCategory: selectedDeviceCategory,
                tenantId: activeTenantId || '',
                projectId: activeProjectId || ''
            })
        );
    };
    useEffect(() => {
        // wait for custom date to be set, only load after that
        if (selectedTimeframe === TIMEFRAME_VALUES.custom_placeholder && !customDate) return;
        loadPlatformAnalytics();
    }, [activeProjectId, activeTenantId, customDate, selectedTimeframe, selectedDeviceCategory]);

    const renderAnalyticsFilters = () => {
        return (
            <AnalyticsFiltersContainer>
                <AnalyticsFilterDropdownWrapper>
                    <DialogDropdownSingle
                        onChange={(newTimeframe: any) => {
                            setOpenDatepicker(false);
                            if (newTimeframe.value === TIMEFRAME_VALUES.custom_placeholder) {
                                return setSelectedTimeframe(newTimeframe.value); // allow reselecting the custom
                            }
                            if (newTimeframe.value === TIMEFRAME_VALUES.custom) {
                                return setOpenDatepicker(true);
                            }
                            // if no custom thing is selected proceed and reset the custom date and filter it from the options
                            setCustomDate(undefined);
                            setTimeframeOptions([...timeframeOptions].filter((opt) => opt.value !== TIMEFRAME_VALUES.custom_placeholder));
                            setSelectedTimeframe(newTimeframe.value);
                        }}
                        placeholder={'Timeframe'}
                        options={timeframeOptions}
                        value={timeframeOptions.find((opt) => opt.value === selectedTimeframe)}
                        notSorted
                    />
                </AnalyticsFilterDropdownWrapper>
                <AnalyticsFilterDropdownWrapper>
                    <DialogDropdownSingle
                        onChange={(opt: { value: string; label: string }) => {
                            setSelectedDeviceCategory(opt.value);
                        }}
                        placeholder={'Filter'}
                        options={deviceCategoryOptions}
                        value={deviceCategoryOptions.find((opt) => opt.value === selectedDeviceCategory)}
                    />
                </AnalyticsFilterDropdownWrapper>
            </AnalyticsFiltersContainer>
        );
    };

    const renderChartlessWidgets = () => {
        if (!platformData) return null;
        const widgets: any[] = [];

        WidgetKeysWithoutCharts.forEach((key) => {
            if (!platformData[key]) return;
            widgets.push(
                <ChartlessWidget
                    dataKey={key}
                    title={CHART_KEYS_TO_TILE_MAPPING[key]}
                    value={platformData[key]?.value || 0}
                    comparedValue={platformData[key]?.comparedValue}
                    comparedTimeframeLabel={calculateComparedTimeframeLabel(selectedTimeframe, customDate)}
                    subtitle={
                        selectedTimeframe === TIMEFRAME_VALUES.custom_placeholder
                            ? createLabelFromStartEndDate(customDate?.startDate, customDate?.endDate)
                            : TIMEFRAME_LABEL_MAPPING[selectedTimeframe]
                    }
                />
            );
        });
        return <AnalyticsWidgetsLayout>{widgets}</AnalyticsWidgetsLayout>;
    };

    const renderWidgetsWithCharts = () => {
        if (!platformData) return null;
        const widgets: any[] = [];
        const buildWidget = (key: string, title: string, data: PlatformAnalyticsData, widgetType: WidgetTypes) => {
            switch (widgetType) {
                case 'PIE':
                    return (
                        <PieChartWidget
                            totalDevices={platformData.unique_devices?.value || 0}
                            dataKey={key}
                            pieData={data}
                            title={title}
                            subtitle={`All ${formatNumber(calcSumOfDataEntries(data))}`}
                            comparedTimeframeLabel={calculateComparedTimeframeLabel(selectedTimeframe, customDate)}
                        />
                    );
                case 'BAR':
                    return (
                        <BarChartWidget
                            totalDevices={platformData.unique_devices?.value || 0}
                            barData={data}
                            title={title}
                            subtitle={`All ${formatNumber(calcSumOfDataEntries(data))}`}
                            comparedTimeframeLabel={calculateComparedTimeframeLabel(selectedTimeframe, customDate)}
                        />
                    );
                case 'TABLE':
                    return (
                        <TableChartWidget
                            totalDevices={platformData.unique_devices?.value || 0}
                            tableData={data}
                            title={title}
                            subtitle={`All ${formatNumber(calcSumOfDataEntries(data))}`}
                            comparedTimeframeLabel={calculateComparedTimeframeLabel(selectedTimeframe, customDate)}
                        />
                    );
            }
        };

        WidgetKeysWithCharts.forEach((key) => {
            if (!platformData[key]) return;
            widgets.push(buildWidget(key, CHART_KEYS_TO_TILE_MAPPING[key], platformData[key] || [], widgetChartMapping[key]));
        });

        return <AnalyticsWidgetsLayout>{widgets}</AnalyticsWidgetsLayout>;
    };

    const renderAnalytics = () => {
        return (
            <>
                {renderAnalyticsFilters()}
                {!Object.keys(platformData).length && <NoDataContainer>No data is available</NoDataContainer>}
                {renderChartlessWidgets()}
                {renderWidgetsWithCharts()}
            </>
        );
    };

    const generalLoading = loading || projectsLoading;

    return (
        <>
            <ApplicationWrapper>
                {error && <BackendErrorDialog error={error} />}
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle title="Analyze" withProfile />
                    <CapabilitiesScreen disabled={isAnalyticsRestricted} type={Capabilities.ANALYZE} />
                    {!isAnalyticsRestricted && (generalLoading ? <Loader title={'Analyze'} /> : renderAnalytics())}
                </MainContentWrapper>
            </ApplicationWrapper>
            <DatePickerDialog
                saveDate={(date) => {
                    setCustomDate({ startDate: date.startDate, endDate: date.endDate });
                    const newOptions = [...timeframeOptions];
                    const customOption = newOptions.find((opt) => opt.value === TIMEFRAME_VALUES.custom_placeholder);
                    // in case the custom option already exists, overwrite the label of it, this can happen when 2 custom options are selected one after another
                    // else, create and push the new option
                    if (customOption) {
                        customOption.label = createLabelFromStartEndDate(date.startDate, date.endDate);
                    } else {
                        newOptions.push({
                            label: createLabelFromStartEndDate(date.startDate, date.endDate),
                            value: TIMEFRAME_VALUES.custom_placeholder
                        });
                    }

                    setTimeframeOptions(newOptions);
                    setSelectedTimeframe(TIMEFRAME_VALUES.custom_placeholder);
                    setOpenDatepicker(false);
                }}
                toggleOpenDialog={setOpenDatepicker}
                isOpen={openDatepicker}
                isRange
                onClose={() => setOpenDatepicker(false)}
                filterDate={(date) => new Date() > date && Date.now() - 4 * 31556926000 < date} // allow dates from 4yearsInPast<date<today
            />
        </>
    );
};

export default Analytics;
