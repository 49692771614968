import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { FailedDeletionMessages } from '../../types/Object';

export const deactivateObject = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { objectId: string; objectType: any }
>('deactivateObjectSystem/deactivate', async ({ objectId, objectType }, thunkApi) => {
    const result = await ConfigServiceAPI.deactivateObject(objectId, objectType);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const activateObject = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { objectId: string; objectType: any }
>('deactivateObjectSystem/activate', async ({ objectId, objectType }, thunkApi) => {
    const result = await ConfigServiceAPI.activateObject(objectId, objectType);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const deactivateObjectList = createAsyncThunk<
    { ok: boolean; failedActions: FailedDeletionMessages; error: { message: string; code: string } | null },
    { objectIds: string[]; objectType: any }
>('deactivateObjectSystem/deactivateObjectList', async ({ objectIds, objectType }, thunkApi) => {
    const result = await ConfigServiceAPI.deactivateObjectList(objectIds, objectType);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }

    return { ok: !!result.response, failedActions: result.response, error: null };
});

export const activateObjectList = createAsyncThunk<
    { ok: boolean; failedActions: FailedDeletionMessages; error: { message: string; code: string } | null },
    { objectIds: string[]; objectType: any }
>('deactivateObjectSystem/activateObjectList', async ({ objectIds, objectType }, thunkApi) => {
    const result = await ConfigServiceAPI.activateObjectList(objectIds, objectType);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }

    return { ok: !!result.response, failedActions: result.response, error: null };
});

export interface DeactivateObjectState {
    loading: boolean;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: DeactivateObjectState = {
    loading: false,
    error: null
};

const slice = createSlice({
    name: 'deactivateObjectSystem',
    initialState,
    reducers: {
        unsetDeactivateObjectError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(deactivateObject.fulfilled, (state, _action) => {
                state.error = null;
                state.loading = false;
            })
            .addCase(deactivateObject.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deactivateObject.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deactivateObjectList.fulfilled, (state, _action) => {
                state.error = null;
                state.loading = false;
            })
            .addCase(deactivateObjectList.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deactivateObjectList.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(activateObject.fulfilled, (state, _action) => {
                state.error = null;
                state.loading = false;
            })
            .addCase(activateObject.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(activateObject.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(activateObjectList.fulfilled, (state, _action) => {
                state.error = null;
                state.loading = false;
            })
            .addCase(activateObjectList.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(activateObjectList.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetDeactivateObjectError } = slice.actions;
export default slice.reducer;
