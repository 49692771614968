import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';

const theme: any = getTheme();
export const TranslationTooltipIconContainer = styled.span<{ iconColor?: string }>`
    display: flex;
    width: max-content;
    align-items: center;
    justify-content: center;
    svg {
        path {
            fill: ${({ iconColor }) => iconColor || theme.palette.grey.dark};
        }
        width: 12px;
        height: 12px;
    }
`;

export const VisualEditorTranslationTextWrapper = styled.div`
    color: ${theme.palette.white.main};
    font-size: 15px;
    margin-left: 8px;
    width: 240px;
    opacity: 0;
    transition: opacity 0.1s linear;
`;

export const VisualEditorTranslationContainer = styled.div`
    background-color: transparent;
    z-index: 999;
    display: flex;
    align-items: center;
    border-radius: 38px;
    width: 24px;
    height: 24px;
    transition: width 0.5s, background-color 0.5s;
    padding: 8px;

    &:hover {
        background-color: ${theme.palette.black.main};
        ${fontSizesCSS.Body1};
        cursor: pointer;
        width: 240px;
        transition: width 0.5s, background-color 0.5s;
        ${VisualEditorTranslationTextWrapper} {
            opacity: 1;
            transition: opacity 0.2s linear 0.3s;
        }
    }
`;

export const VisualEditorTranslationTooltipIcon = styled.div`
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 16px;
      path {
          fill: black;
      }

`;
