import { FC, useEffect, useState } from 'react';
import { PagePreviewFilterWrapper, PagePreviewLabel, pageOptionsButtonStyleDesktop } from './PageEdit.css';
import { DialogDropdownMultiple } from '../common/Dialog/GenericDialog';
import Button from '../Buttons/Button/Button';
import icons from '../../style';
import SVGInline from 'react-inlinesvg';
import { FancyFilter } from '../common/Select/FancyFilter';
import { ObjectFilter } from '../../utils/fnFilter';
import { DisplayConditionsState } from '../../redux/slices/displayConditionsSlice';
import { audiencesState } from '../../redux/slices/audienceSlice';
import { useAppSelector } from '../../hooks/redux';
import { EMPTY_WORD_STRING } from '../../utils/Globals';
import { ButtonIcon } from '../Buttons/Button/Button.css';
import { Module } from '../../types/Module';
import { AbTestingGroupsState } from '../../redux/slices/abTestingGroupSlice';
import _ from 'lodash';

const pageFilterFields = [
    {
        label: 'Audiences',
        value: 'audienceIds',
        icon: 'conditionsIcon',
        type: 'field'
    },
    {
        label: 'Display Conditions',
        value: 'displayConditionIds',
        icon: 'displayConditionsIcon',
        type: 'field'
    },
    {
        label: 'A/B Testing Groups',
        value: 'abTestingGroupIds',
        icon: 'abTestingStatusDefaultIcon',
        type: 'field'
    }
];

type PagePreviewFilterProps = {
    onFilterChange: (filter: ObjectFilter) => void;
    onHidePreview: () => void;
    pageAudiences: string[];
    conditionIds: string[];
    abTestingGroupIds: string[];
    setFilter: (filter: ObjectFilter) => void;
    filter?: ObjectFilter;
};

export const PagePreviewFilter: FC<PagePreviewFilterProps> = ({
    onHidePreview,
    onFilterChange,
    conditionIds,
    pageAudiences,
    abTestingGroupIds,
    filter,
    setFilter
}) => {
    const { displayConditions }: DisplayConditionsState = useAppSelector((state) => state.displayConditions);
    const { audiences }: audiencesState = useAppSelector((state) => state.audiences);
    const { abTestingGroups }: AbTestingGroupsState = useAppSelector((state) => state.abTestingGroups);

    const [pageFilters, setPageFilters] = useState<any>({});
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        const filters: Record<string, { label?: string; value?: string }[]> = {
            audienceIds: [],
            displayConditionIds: [],
            abTestingGroupIds: []
        };

        filters.audienceIds = [...audiences]
            .filter((audience) => pageAudiences.includes(audience._id))
            .map((el) => ({ label: el.name || EMPTY_WORD_STRING, value: el._id }));
        filters.displayConditionIds = [...displayConditions]
            .filter((condition) => conditionIds.includes(condition._id))
            .map((el) => ({ label: el.name || EMPTY_WORD_STRING, value: el._id }));
        filters.abTestingGroupIds = [...abTestingGroups]
            .filter((group) => abTestingGroupIds.includes(group._id))
            .map((el) => ({ label: el.name || EMPTY_WORD_STRING, value: el._id }));

        setPageFilters(filters);
    }, [conditionIds, abTestingGroupIds, pageAudiences, displayConditions, audiences, abTestingGroups]);

    return (
        <PagePreviewFilterWrapper>
            <FancyFilter
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm || ''}
                onChange={(filter) => {
                    if (typeof filter === 'string') return;
                    onFilterChange(filter);
                    setFilter(filter);
                }}
                extraFilters={{ fields: pageFilterFields, filterValues: pageFilters }}
                activeObjectFilter={filter}
                label={'Preview'}
                isPageFilter
            />
            <Button
                type={'DEFAULT'}
                label={
                    <PagePreviewLabel>
                        <ButtonIcon>
                            <SVGInline src={icons.closeIcon} />
                        </ButtonIcon>
                        Close
                    </PagePreviewLabel>
                }
                onClick={() => {
                    onHidePreview();
                }}
            />
        </PagePreviewFilterWrapper>
    );
};
