import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

let config: any = null;
if (process.env.REACT_APP_FIREBASE_CONFIG) config = JSON.parse(atob(`${process.env.REACT_APP_FIREBASE_CONFIG}`));

const app = config ? initializeApp(config) : undefined;
const analytics = config ? getAnalytics(app) : undefined;

export default {
    app,
    analytics
};
