import React, { useEffect } from 'react';
import { PaginationItem, PaginationWrapper } from './Pagination.css';
import { renderUnsavedAlertBeforeAction } from '../../PaginationWrapper/PaginationWrapper';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from: number, to: number, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

const Pagination: React.FC<{
    totalPages: number;
    pageLimit: number;
    pageNeighbours: number;
    onPageChanged: any;
    currentPage: number;
    setCurrentPage: any;
    showUnsaved?: boolean;
}> = ({ totalPages, pageLimit, pageNeighbours, onPageChanged, currentPage, setCurrentPage, showUnsaved }) => {
    const gotoPage = (page: number) => {
        if (showUnsaved) return renderUnsavedAlertBeforeAction();
        const currentPage = Math.max(0, Math.min(page, totalPages));
        const paginationData = {
            currentPage,
            totalPages,
            pageLimit
        };
        setCurrentPage(currentPage);
        onPageChanged?.(paginationData);
    };

    const handleClick = (page: number, evt: any) => {
        evt?.preventDefault();
        gotoPage(page);
    };

    const handleMoveLeft = (evt: any) => {
        evt?.preventDefault();
        gotoPage(currentPage - pageNeighbours - 1);
    };

    const handleMoveRight = (evt: any) => {
        evt?.preventDefault();
        gotoPage(currentPage + pageNeighbours + 1);
    };

    const fetchPageNumbers = () => {
        const totalNumbers = pageNeighbours + 2;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            let pages = [];

            const leftBound = currentPage - pageNeighbours;
            const rightBound = currentPage + pageNeighbours;
            const beforeLastPage = totalPages - 1;

            const startPage = leftBound > 2 ? leftBound : 2;
            const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

            pages = range(startPage, endPage);

            const pagesCount = pages.length;
            const singleSpillOffset = totalNumbers - pagesCount - 1;

            const leftSpill = startPage > 2;
            const rightSpill = endPage < beforeLastPage;

            const leftSpillPage = LEFT_PAGE;
            const rightSpillPage = RIGHT_PAGE;

            if (leftSpill && !rightSpill) {
                const extraPages = range(startPage - singleSpillOffset, startPage - 1);
                pages = [leftSpillPage, ...extraPages, ...pages];
            } else if (!leftSpill && rightSpill) {
                const extraPages = range(endPage + 1, endPage + singleSpillOffset);
                pages = [...pages, ...extraPages, rightSpillPage];
            } else if (leftSpill && rightSpill) {
                pages = [leftSpillPage, ...pages, rightSpillPage];
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    };

    if (totalPages === 1) return null;

    const pages = fetchPageNumbers();

    return (
        <PaginationWrapper>
            {pages.map((page, index) => (
                <PaginationItem
                    $active={currentPage.toString() === page.toString()}
                    key={index}
                    onClick={(e) => {
                        if (page === LEFT_PAGE) {
                            handleMoveLeft(e);
                        } else if (page === RIGHT_PAGE) {
                            handleMoveRight(e);
                        } else {
                            handleClick(parseInt(page.toString()), e);
                        }
                    }}
                >
                    {[LEFT_PAGE, RIGHT_PAGE].includes(page.toString()) ? '...' : page}
                </PaginationItem>
            ))}
        </PaginationWrapper>
    );
};

export default Pagination;
