import React, { FC } from 'react';
import { ApplicationWrapper, MainContentWrapper } from '../../style/styled-components/reusable.css';
import Sidebar from '../common/Sidebar/Sidebar';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import { Capabilities, CapabilitiesScreen } from '../Capabilities/CapabilitiesTopScreen';
import useRestrictedProjectAccess from '../../hooks/useRestrictedAccess';
import { restrictedSectionsTypes } from '../../types/Project';

const Personalize: FC = () => {
    const { isRestrictedArea: isPersonalizeRestricted } = useRestrictedProjectAccess(restrictedSectionsTypes.CAPABILITIES, 'personalize');
    return (
        <ApplicationWrapper>
            <Sidebar />
            <MainContentWrapper>
                <ScreenTitle loading={false} title={'Personalize'} withProfile />
                <CapabilitiesScreen type={Capabilities.PERSONALIZE} disabled={isPersonalizeRestricted} />
            </MainContentWrapper>
        </ApplicationWrapper>
    );
};

export default Personalize;
