import React, { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import SVGInline from 'react-inlinesvg';
import {
    ApplicationWrapper,
    MainContentWrapper,
    PageActionButton,
    PageActionsWrapper,
    TruncatedText
} from '../../style/styled-components/reusable.css';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import Sidebar from '../common/Sidebar/Sidebar';
import { SearchBarContainer } from '../common/SearchBar/SearchBar.css';
import { SearchBar } from '../common/SearchBar/SearchBar';
import GenericTable, { ActionsTableCell, HeaderTableCell, tableActions } from '../common/Table/Table';
import { AssetsTableSizes } from '../../types/TableSizes';
import { TableBody, TableRow, Tooltip } from '@material-ui/core';
import { WidthTableCell } from '../common/Table/Table.css';
import { generateDateStringForTables, getDateAndTime } from '../../utils/fnDate';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import { AssetManagerState, createAsset, deleteAsset, updateAsset, fetchAssets } from '../../redux/slices/assetManagerSlice';
import {
    AssetsPaginationBottomContainer,
    AssetStatus,
    AssetStatusWrapper,
    LastModifiedWrapper,
    TagsWrapper,
    ThumbnailWrapper
} from './AssetManager.css';
import noImage from '../../assets/images/notAvailableLogo.png';
import { ContentAsset, AssetImportStatus, OverallImportStatus, ImportStatus } from '../../types/Asset';
import { Loader } from '../common/Loader/Loader';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { fetchCountryCodes, fetchLanguageCodes, LanguagesState } from '../../redux/slices/languagesSlice';
import { NewAssetDialog } from './Dialogs/NewAsset';
import Pagination from '../common/Pagination/Pagination';
import icons from '../../assets/images/icons';
import { dialogAlert, dialogConfirm, ToastAlert } from '../../utils/fnDialogs';
import { RemoveModuleWrapper } from '../Modules/Modules.css';
import { useNavigate } from 'react-router-dom';
import configServiceAPI from '../../utils/api/configServiceAPI';
import { renderTooltip, tooltipTypes } from '../common/Tooltips/Tooltips';
import useScreenSize from '../../hooks/useScreenSize';

export const TENANTS_WITH_ASSET_MANAGER = ['9b95a9d2-406e-4bc1-bf3e-179079b64a3b', 'b6d0424d-525f-4663-a4ed-126e53d1b965'];

export const AssetManager: FC = () => {
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { assets: storeAssets, loading, error, totalResults }: AssetManagerState = useAppSelector((state) => state.assetManager);
    const { languageCodes, countryCodesAssetManager }: LanguagesState = useAppSelector((state) => state.languages);

    const { isSmallMobile, isMobile } = useScreenSize();

    const [openNewAssetDialog, setOpenNewAssetDialog] = useState(false);
    const [assets, setAssets] = useState<ContentAsset[]>([]);
    const [assetToEdit, setAssetToEdit] = useState<ContentAsset | undefined>(undefined);

    // PAGINATION AND SEARCH
    const [pages, setPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const [importStatus, setImportStatus] = useState<ImportStatus>({});
    const [importDisabled, setImportDisabled] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loadLanguageCodes = async () => {
        return await dispatch(fetchLanguageCodes()).unwrap();
    };

    const loadCountryCodes = async () => {
        return await dispatch(fetchCountryCodes(true)).unwrap();
    };

    const loadAssets = async (currentPage: number, pageSize: number, searchTerm: string) => {
        const offset = (currentPage - 1) * pageSize;
        await dispatch(fetchAssets({ tenantId: activeTenantId, projectId: activeProjectId, offset, size: pageSize, searchTerm })).unwrap();
    };

    const saveAsset = async (asset: ContentAsset) => {
        try {
            const result = await dispatch(createAsset(asset)).unwrap();
            if (!result.id) return false;
            loadAssets(1, pageSize, '');
            setCurrentPage(1);
            setSearchTerm('');
        } catch (ex) {
            return false;
        }
        return true;
    };

    const editAsset = async (asset: ContentAsset) => {
        try {
            const result = await dispatch(updateAsset(asset)).unwrap();
            if (!result.ok) return false;
            loadAssets(1, pageSize, searchTerm);
            setCurrentPage(1);
        } catch (ex) {
            return false;
        }
        return true;
    };

    const removeAsset = async (id: string) => {
        try {
            const result = await dispatch(deleteAsset(id)).unwrap();
            if (!result.ok) return false;
            loadAssets(currentPage, pageSize, searchTerm);
        } catch (ex) {
            return false;
        }
        return true;
    };

    const startImport = async () => {
        const countries = countryCodesAssetManager.map((country) => country.code.toUpperCase());
        try {
            const result = await configServiceAPI.startImport(countries);
            if (result.response) {
                const values = {
                    title: 'Success',
                    text: 'Import started!'
                };
                ToastAlert('success', values.title, values.text);
                return true;
            } else throw new Error('Import failed');
        } catch (ex) {
            console.error(ex);
            const values = {
                title: 'Error',
                text: 'Import could not start!'
            };
            ToastAlert('error', values.title, values.text, undefined, undefined, () => {
                dialogAlert('', undefined, values, undefined, undefined, icons.warningIcon);
            });
        }
        return false;
    };

    const getImportStatus = async () => {
        let importStatus: ImportStatus | null = null;
        try {
            const result = await configServiceAPI.getImportStatus();
            if (result.response) {
                importStatus = result.response as any as ImportStatus;
                setImportStatus(importStatus);
            } else throw new Error('Import status check failed');
        } catch (ex) {
            console.error(ex);
            setImportDisabled(true);
        }
        return importStatus;
    };

    const getAssetThumbnail = (asset: ContentAsset): string => {
        return decodeURIComponent(asset.thumbnail) || noImage;
    };

    useEffect(() => {
        !languageCodes.length && loadLanguageCodes();
        !countryCodesAssetManager.length && loadCountryCodes();
        getImportStatus();
    }, [activeProjectId]);

    useEffect(() => {
        if (totalResults === undefined) return;
        setPages(Math.ceil(totalResults / pageSize));
    }, [pageSize, totalResults]);

    useEffect(() => {
        if (!activeProjectId) return;
        loadAssets(currentPage, pageSize, '');
    }, [activeProjectId]);

    useEffect(() => {
        if (loading || error) return;
        setAssets(storeAssets);
    }, [loading]);

    useEffect(() => {
        importStatus.status && setImportDisabled(importStatus.status !== OverallImportStatus.DONE);
    }, [importStatus]);

    useEffect(() => {
        if (!activeTenantId) return;
        if (!TENANTS_WITH_ASSET_MANAGER.includes(activeTenantId)) {
            navigate(-1);
        }
    }, [activeTenantId]);

    const handleImport = async () => {
        const status = await getImportStatus();
        if (status && status.status !== OverallImportStatus.DONE) {
            const values = {
                title: 'Error',
                text: 'Import could not start! Another import is running!'
            };
            return ToastAlert('error', values.title, values.text, undefined, undefined, () =>
                dialogAlert('', undefined, values, undefined, undefined, icons.warningIcon)
            );
        }
        const importStarted = await startImport();
        if (importStarted) getImportStatus();
    };

    const handleEditClick = (asset: ContentAsset) => {
        setAssetToEdit(asset);
        setOpenNewAssetDialog(true);
    };
    const handleDeleteClick = (id: string) => {
        const values = {
            title: `Remove Asset`,
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                removeAsset(id);
            },
            values,
            <RemoveModuleWrapper>
                <SVGInline src={icons.warningIcon} />
                <p>
                    <strong>{`Are you sure you want to remove this asset?`}</strong>
                    <br />
                    By Pressing “Remove” you still will be able to create new one from list
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            undefined,
            undefined,
            true
        );
    };

    const buildTableBody = () => {
        const rows = assets.map((asset) => {
            const lastModified = generateDateStringForTables(asset?.lastUpdated || 0);
            const thumbnail = getAssetThumbnail(asset);
            return (
                <>
                    <TableRow key={`asset_${asset.id}`} style={{ height: '142px' }} onClick={() => {}}>
                        <WidthTableCell {...AssetsTableSizes.thumbnail}>
                            <ThumbnailWrapper $image={thumbnail} />
                        </WidthTableCell>
                        <WidthTableCell {...AssetsTableSizes.name}>
                            <TruncatedText>{asset.title}</TruncatedText>
                        </WidthTableCell>
                        <WidthTableCell {...AssetsTableSizes.tags}>
                            <Tooltip title={asset.tags} placement={'top'}>
                                <TagsWrapper>
                                    <TruncatedText>{asset.tags}</TruncatedText>
                                </TagsWrapper>
                            </Tooltip>
                        </WidthTableCell>
                        <WidthTableCell {...AssetsTableSizes.status}>
                            <AssetStatusWrapper>
                                <AssetStatus $imported={asset.importStatus === AssetImportStatus.IMPORTED} />{' '}
                                {!isSmallMobile && _.startCase(asset.importStatus)}
                            </AssetStatusWrapper>
                        </WidthTableCell>
                        {!isMobile && (
                            <WidthTableCell {...AssetsTableSizes.lastModified}>
                                <LastModifiedWrapper>
                                    <TruncatedText>{lastModified}</TruncatedText>
                                </LastModifiedWrapper>
                            </WidthTableCell>
                        )}

                        <WidthTableCell {...AssetsTableSizes.actions}>
                            <ActionsTableCell
                                actions={[tableActions.EDIT, tableActions.REMOVE]}
                                onEdit={(e: React.MouseEvent<any>) => {
                                    e.stopPropagation();
                                    handleEditClick(asset);
                                }}
                                onRemove={(e: React.MouseEvent<any>) => {
                                    e.stopPropagation();
                                    handleDeleteClick(asset.id);
                                }}
                                tooltipTexts={{
                                    edit: 'assets_icon_edit',
                                    delete: 'assets_icon_delete'
                                }}
                            />
                        </WidthTableCell>
                    </TableRow>
                </>
            );
        });

        return <TableBody>{rows}</TableBody>;
    };

    const buildTableColumns = () => {
        const columns = !assets.length
            ? []
            : isMobile
            ? ['thumbnail', 'title', 'tags', 'status']
            : ['thumbnail', 'title', 'tags', 'status', 'lastModified'];
        return columns.map((key) => (
            <HeaderTableCell
                key={`asset_${key}_cell`}
                text={_.startCase(key)}
                columnSize={AssetsTableSizes[key as keyof typeof AssetsTableSizes]}
            />
        ));
    };

    const importButtonTooltip =
        "An import is running and another one can't be started yet. Refresh the page to update the status." +
        (importStatus.importedAt ? ` Last successful import was at ${getDateAndTime(importStatus.importedAt)}.` : '');

    let importButton = (
        <PageActionButton onClick={handleImport} label={'Import'} type={'BLUE'} isDisabled={importDisabled || !assets.length} />
    );

    if (importDisabled && assets.length) {
        importButton = renderTooltip(importButton, tooltipTypes.TEXT, importButtonTooltip);
    }

    return (
        <>
            {error && <BackendErrorDialog error={error} />}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle
                        loading={loading}
                        title={'Asset Manager'}
                        withAddButton
                        withSecondaryButton
                        withProfile
                        addLabel={'Create Asset'}
                        onAdd={() => {
                            setOpenNewAssetDialog(true);
                        }}
                        secondaryButtonLabel={'Import'}
                        onSecondarybuttonClick={handleImport}
                        extraButtonProps={{
                            secondary: {
                                disabled: importDisabled || !assets.length,
                                ...(importDisabled && assets.length && { tooltip: { value: importButtonTooltip } })
                            }
                        }}
                    />
                    <SearchBarContainer>
                        <SearchBar
                            title={'Search by Title'}
                            searchTerm={searchTerm}
                            onSearch={(value) => {
                                loadAssets(1, pageSize, value);
                                setCurrentPage(1);
                            }}
                            setSearchTerm={setSearchTerm}
                            disabled={false}
                            tooltipText={'assets_icon_search'}
                        />
                    </SearchBarContainer>

                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            {' '}
                            <GenericTable body={buildTableBody()} columns={buildTableColumns()} />
                            <PageActionsWrapper>
                                <PageActionButton onClick={() => setOpenNewAssetDialog(true)} label={'Create Asset'} type={'BLUE'} />
                                {importButton}
                            </PageActionsWrapper>
                        </>
                    )}

                    <AssetsPaginationBottomContainer>
                        <Pagination
                            totalPages={pages}
                            pageLimit={pageSize}
                            pageNeighbours={1}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            onPageChanged={(data: any) => {
                                loadAssets(data.currentPage, data.pageLimit, searchTerm);
                            }}
                        />
                    </AssetsPaginationBottomContainer>
                </MainContentWrapper>

                <NewAssetDialog
                    open={openNewAssetDialog}
                    onSave={(asset: ContentAsset) => (asset.id ? editAsset(asset) : saveAsset(asset))}
                    onClose={() => {
                        setOpenNewAssetDialog(false);
                        setAssetToEdit(undefined);
                    }}
                    asset={assetToEdit}
                />
            </ApplicationWrapper>
        </>
    );
};
