import { FC, useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogCheckbox, DialogTypes } from '../../common/Dialog/GenericDialog';
import { MissingAudiencesLabel, MissingAudiencesTitle, MissingAudiencesWrapper } from '../GroupEdit.css';
import { EMPTY_WORD_STRING } from '../../../utils/Globals';
import { renderTooltip, tooltipPositions, tooltipTypes } from '../../common/Tooltips/Tooltips';
import icons from '../../../style';
import SVGInline from 'react-inlinesvg';
import TranslationTooltip from '../../common/TranslationTooltip/TranslationTooltip';
import { renderPublishStatusIcon } from '../../../utils/fnPublish';
import useTranslation from '../../../hooks/useTranslation';

const toolTipText = 'The objects will be updated with the new audiences only after saving the group';
type MissingAudiencesDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (ids: string[]) => void;
    missingAudiences: { menus: any[]; pages: any[]; settings: any[] };
};

export const MissingAudiencesDialog: FC<MissingAudiencesDialogProps> = ({ open, missingAudiences, onClose, onSave }) => {
    const { translate } = useTranslation();
    const [objectsToAddAudience, setObjectsToAddAudience] = useState<string[]>([]);
    const handleAddClick = () => {
        onSave(objectsToAddAudience);
        handleCloseClick();
    };

    const handleCloseClick = () => {
        setObjectsToAddAudience([]);
        onClose();
    };
    const addButton: DialogButton = {
        label: 'Add',
        type: 'BLUE',
        onClick: handleAddClick,
        disabled: !objectsToAddAudience.length
    };

    const closeButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    if (!open) return null;

    return (
        <GenericDialog
            type={DialogTypes.Form}
            title={'Some Audiences are missing!'}
            actionButtons={[closeButton, addButton]}
            onClose={handleCloseClick}
        >
            <MissingAudiencesWrapper>
                <MissingAudiencesTitle>
                    {'Do you wish to add all the group audiences to the following objects?'}{' '}
                    {renderTooltip(<SVGInline src={icons.infoIcon} />, tooltipTypes.TEXT, toolTipText || '', tooltipPositions.TOP)}
                </MissingAudiencesTitle>
                <>
                    {Object.entries(missingAudiences).map(([objKey, objArray]) =>
                        !!objArray.length
                            ? objArray.map((obj, index) => {
                                  const isActive = objectsToAddAudience.includes(obj._id);
                                  const label = (
                                      <MissingAudiencesLabel>
                                          {translate(obj.name || EMPTY_WORD_STRING)} <TranslationTooltip translationKey={obj.name} />
                                          {renderPublishStatusIcon(obj.publishStatus, obj.publishAt)}
                                      </MissingAudiencesLabel>
                                  );
                                  return (
                                      <DialogCheckbox
                                          key={index}
                                          text={label}
                                          value={isActive}
                                          active={isActive}
                                          onClick={() => {
                                              const selectedObjects = [...objectsToAddAudience];

                                              if (isActive) {
                                                  const index = selectedObjects.findIndex((object) => object === obj._id);
                                                  selectedObjects.splice(index, 1);
                                              } else selectedObjects.push(obj._id);
                                              setObjectsToAddAudience(selectedObjects);
                                          }}
                                      />
                                  );
                              })
                            : null
                    )}
                </>
            </MissingAudiencesWrapper>
        </GenericDialog>
    );
};
