import React, { useEffect, useState } from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import { DIALOG_NAMES, DIALOG_TITLES_MAP } from '../../../utils/fnDialogs';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../../common/Dialog/GenericDialog';

import icons from '../../../style';
import SVGInline from 'react-inlinesvg';
import { validator } from '../../../utils/fnValidator';
import _ from 'lodash';
import { FormDescription, FormSubtitle, FormTitle, FormWrapper, sendOtpButtonStyle } from '../Login.css';
import Button from '../../Buttons/Button/Button';

export type ResetPasswordProps = {
    onReset: any;
    errorMsg: string;
};

const ResetPasswordDialog: React.FC<ResetPasswordProps> = ({ onReset, errorMsg }) => {
    const [errors, setErrors] = useState<{ password?: string; repeat?: string }>({ password: errorMsg, repeat: errorMsg });
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);

    useEffect(() => {
        setErrors({
            password: errorMsg,
            repeat: errorMsg
        });
    }, [errorMsg]);

    const validateResetPassword = () => {
        return Object.values(errors).filter((value) => !!value).length === 0;
    };

    const checkMatchingPasswords = (value: string) => {
        const newErrors = { ...errors };
        if (!!newErrors.repeat) return;
        if (value !== newPassword) {
            newErrors.repeat = 'Passwords do not match!';
        }
        setErrors(newErrors);
    };

    const handleResetClick = (evt: any) => {
        evt.preventDefault();
        if (onReset) {
            if (validateResetPassword()) {
                onReset(newPassword);
            }
        }
    };

    const resetButton: DialogButton = {
        label: 'Reset Password',
        type: 'BLUE',
        onClick: handleResetClick
    };

    const passwordAdornment = (repeat = false) => (
        <InputAdornment position="end">
            <IconButton
                onClick={(evt) => {
                    evt.preventDefault();
                    if (repeat) {
                        setShowRepeatPassword(!showRepeatPassword);
                    } else {
                        setShowPassword(!showPassword);
                    }
                }}
            >
                {repeat ? (
                    <SVGInline src={showRepeatPassword ? icons.hidePasswordIcon : icons.showPasswordIcon} />
                ) : (
                    <SVGInline src={showPassword ? icons.hidePasswordIcon : icons.showPasswordIcon} />
                )}
            </IconButton>
        </InputAdornment>
    );

    const { title, text, subtitle } = DIALOG_TITLES_MAP.get(DIALOG_NAMES.RESET_PASSWORD) ?? {};
    return (
        <FormWrapper>
            <FormTitle>{title}</FormTitle>
            <FormSubtitle>{subtitle}</FormSubtitle>
            <FormDescription>{text}</FormDescription>
            <DialogTextField
                value={newPassword}
                error={errors.password}
                label={'Enter New Password'}
                type={showPassword ? 'text' : 'password'}
                InputProps={{ endAdornment: passwordAdornment() }}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    setNewPassword(evt.target.value);
                    setErrors(_.set(errors, 'password', validator({ required: true, minLength: 8, password: true }, evt.target.value)));
                }}
            />
            <DialogTextField
                value={repeatPassword}
                error={errors.repeat}
                label={'Repeat New Password'}
                type={showRepeatPassword ? 'text' : 'password'}
                InputProps={{ endAdornment: passwordAdornment(true) }}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    setRepeatPassword(evt.target.value);
                    setErrors(
                        _.set(
                            errors,
                            'repeat',
                            validator(
                                {
                                    required: true,
                                    minLength: 8,
                                    password: true
                                },
                                evt.target.value
                            )
                        )
                    );
                    checkMatchingPasswords(evt.target.value);
                }}
                onKeyPress={(evt: any) => {
                    if (evt.key === 'Enter') handleResetClick(evt);
                }}
            />
            <Button onClick={handleResetClick} id="reset-button" label={'Reset Password'} type="BLUE" style={sendOtpButtonStyle} />
        </FormWrapper>
    );
};

export default ResetPasswordDialog;
