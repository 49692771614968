import styled, { css } from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { ToggleContainer } from '../common/Dialog/GenericDialog.css';
import { ToggleViewSwitchWrapper } from '../common/Switch/ToggleViewSwitch.css';
import { devices } from '../../style/styled-components/reusable.css';
import { TranslationTooltipIconContainer } from '../common/TranslationTooltip/TranslationTooltip.css';
import { SelectWrapper } from '../common/Select/Select.css';
import { WarningTypes } from './UXElements/Content/Content';

const theme: any = getTheme();

const warningColors: any = {
    UNIQUE_AUDIENCES: theme.palette.error.main,
    UNIQUE_TYPES: theme.palette.warning.main,
    GROUP_AUDIENCES: theme.palette.warning.secondary
};

export const GroupNameContainer = styled.div`
    display: flex;
    align-items: center;
    svg {
        width: 24px;
        height: 100%;
        padding: 0 8px;
    }
    span {
        vertical-align: top;
    }
`;

export const GroupStatusContainer = styled.div`
    svg {
        width: 24px;
        height: 24px;
    }
`;

export const FieldAndToggleContainer = styled.div<{ withWarning?: boolean }>`
    background-color: rgba(0, 0, 0, 0.04);
    padding: 22px 24px;
    height: 62px;
    box-sizing: border-box;
    align-items: center;
    display: flex;

    ${ToggleViewSwitchWrapper} {
        ${(props) => !props.withWarning && `margin-left: auto`};
        ${ToggleContainer} {
            margin-top: 0;
        }
    }
`;

export const GroupFieldsContainer = styled.div<{ withBottomMargin?: boolean }>`
    display: flex;
    ${({ withBottomMargin }) => withBottomMargin && 'margin-bottom: 24px;'};
`;

export const GroupField = styled.div<{ $active: boolean; $disabled?: boolean }>`
    padding: 8px 16px;
    border-radius: 3px;
    ${fontSizesCSS.Body2};
    opacity: ${(props) => (props.$active ? '1' : '0.7')};
    cursor: pointer;
    ${(props) => (props.$active ? `background: ${theme.palette.white.main}` : `background: ${theme.palette.background.pressed}`)};
    ${(props) => props.$disabled && 'pointer-events: none'};
`;

export const AddElementCell = styled.div`
    ${fontSizesCSS.Caption2}
    display: flex;
    align-items: center;

    svg {
        margin-left: 16px;
    }
`;

//UX Content

export const GridRowContainer = styled.div<{ $expanded?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 36px;
    ${(props) => !props.$expanded && 'max-height: 380px'};
    overflow: hidden;
    position: relative;
`;

export const GridContainerMoreOverlay = styled.div`
    position: absolute;
    bottom: 0;
    height: 46px;
    width: 100%;
    background: linear-gradient(0deg, #fafbfb 0%, rgba(115, 115, 115, 0) 100%);
`;

export const TitleWrapper = styled.div`
    margin-bottom: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const Title = styled.div`
    ${fontSizesCSS.Caption1};
`;
export const GridCardWrapper = styled.div<{ isEmpty?: boolean; $warning?: WarningTypes }>`
    position: relative;
    box-sizing: border-box;
    width: 390px;
    ${devices.mobileL} {
        width: 400px;
    }
    ${devices.mobileS} {
        width: 100%;
    }
    height: 92px;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 18px 24px;
    background: ${theme.palette.white.main};
    border: ${({ $warning }) => (!!$warning ? `1px solid ${warningColors[$warning]}` : `1px solid ${theme.palette.background.border}`)};

    ${Title} {
        color: ${({ $warning }) => (!!$warning ? `${warningColors[$warning]}` : `${theme.palette.primary.main}`)};
    }
    border-radius: 4px;
    ${(props) =>
        props.isEmpty &&
        css`
            ${fontSizesCSS.Caption2};
            cursor: pointer;
        `};
`;
export const RemoveAndPublishStatusIconHolder = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;
    svg {
        width: 16px;
        height: 16px;
    }
`;
export const PublishIconHolder = styled.div`
    svg {
        width: 24px;
        height: 24px;
    }
`;
export const GridCardText = styled.div`
    position: absolute;
    left: 24px;
`;

export const GridCardAddIcon = styled.div`
    position: absolute;
    right: 24px;
`;

export const CardInfos = styled.div`
    display: flex;
    flex-direction: column;
    jusify-content: flex-start;
    width: 220px;
`;

export const Targets = styled.div``;

export const CardIcon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${theme.palette.black.main};
    text-align: center;
    display: flex;
    align-items: center;

    svg {
        display: block;
        margin: auto;
        path {
            fill: ${theme.palette.white.main};
        }
    }
`;

export const GridTitle = styled.div<{ $expandable?: boolean }>`
    margin-bottom: 7px;
    height: 40px;
    ${fontSizesCSS.Body2};
    background: ${theme.palette.background.pressed};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    ${(props) => props.$expandable && 'cursor: pointer'}
`;

export const TargetGroupName = styled.div`
    margin-right: 16px;
    cursor: pointer;
    color: ${theme.palette.primary.main};
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const WarningLabelsWrapper = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
`;

export const WarningLabel = styled.div<{ critical?: boolean; withoutText?: boolean }>`
    border-radius: 28px;
    padding: ${(props) => (props.withoutText ? '6px' : '8px')};
    height: 28px;
    width: 28px;
    box-sizing: border-box;
    display: flex;
    gap: 4px;
    align-items: center;
    background: ${(props) => (props.critical ? theme.palette.error.main : theme.palette.warning.main)};
    color: ${(props) => (props.critical ? theme.palette.white.main : theme.palette.black.main)};
    width: fit-content;
    ${fontSizesCSS.Caption3};
    font-weight: bold;
    cursor: pointer;

    svg {
        width: ${(props) => (props.withoutText ? '16px' : '14px')};
        height: ${(props) => (props.withoutText ? '16px' : '14px')};
        path {
            stroke: ${(props) => props.critical && theme.palette.white.main};
        }
    }
`;
export const GroupWarningLabel = styled.div<{ $warningType?: WarningTypes }>`
    width: 28px;
    height: 28px;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ $warningType }) => $warningType && `background: ${warningColors[$warningType]}`};

    svg {
        width: 16px;
        height: 16px;
    }
`;

export const WarningTooltip = styled.span`
    font-weight: bold;
`;

export const WarningLabelPlaceHolder = styled.div``;

export const MissingAudiencesWrapper = styled.div`
    a {
        margin-bottom: 4px;
    }
`;

export const MissingAudiencesTitle = styled.div`
    ${fontSizesCSS.Body1};
    color: ${theme.palette.background.hovered};
    margin-bottom: 4px;
    display: flex;
    gap: 4px;
    align-items: center;
`;

export const MissingAudiencesLabel = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    ${TranslationTooltipIconContainer} {
        svg {
            height: 24px;
            width: 18px;
        }
    }
`;

export const GroupEditSearchContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.04);
    padding: 0 24px 20px 24px;
    margin-bottom: 24px;
`;

export const WarningIconWrapper = styled.div<{ $warningType: any }>`
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: ${({ $warningType }) => warningColors[$warningType]};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
        width: 12px;
        height: 12px;
    }
`;
