import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Template, templateType } from '../../types/Template';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { ObjectFilter } from '../../utils/fnFilter';

export const fetchTemplates = createAsyncThunk<
    { templates: any[]; error: { message: string; code: string } | null; permissions?: any; totalResults?: number },
    {
        type: templateType;
        released?: boolean;
        projectId?: string;
        pageSize?: number;
        pageNumber?: number;
        orderBy?: string;
        searchTerm?: string;
        filter?: ObjectFilter;
    }
>('templates/fetchTemplates', async ({ type, released, projectId, pageSize, pageNumber, orderBy, searchTerm, filter }, thunkApi) => {
    const result = await ConfigServiceAPI.getTemplates(type, released, projectId, pageSize, pageNumber, orderBy, searchTerm, filter);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { templates: result.response as any, error: null, totalResults: result.totalResults };
});

export const fetchTemplate = createAsyncThunk<
    { template: any; error: { message: string; code: string } | null; permissions?: any },
    { templateId: string; type: templateType }
>('templates/fetchTemplate', async ({ templateId, type }, thunkApi) => {
    const result = await ConfigServiceAPI.getTemplate(templateId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { template: result.response as any, error: null };
});

export const applyTemplate = createAsyncThunk<
    { id: string; error: { message: string; code: string } | null },
    {
        templateId: string;
        type: templateType;
        projectId: string;
        tenantId: string;
        pageId?: string;
        index?: number;
        groupId?: string;
        moduleId?: string;
    }
>('templates/applyTemplate', async ({ templateId, type, projectId, tenantId, pageId, index, groupId, moduleId }, thunkApi) => {
    const result = await ConfigServiceAPI.applyTemplate(templateId, projectId, tenantId, pageId, index, groupId, moduleId);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { id: result.response as any, error: null };
});

export const createTemplate = createAsyncThunk<{ id: string; error: { message: string; code: string } | null }, any>(
    'templates/createTemplate',
    async (template: Template, thunkApi) => {
        const result = await ConfigServiceAPI.createTemplate(template);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any, type: template.type, error: null };
    }
);

export const updateTemplate = createAsyncThunk<{ id: string; error: { message: string; code: string } | null }, any>(
    'templates/updateTemplate',
    async (template: Template, thunkApi) => {
        const result = await ConfigServiceAPI.updateTemplate(template);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any, type: template.type, error: null };
    }
);

export const deleteTemplate = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { templateId: string; type: templateType }
>('templates/deleteTemplate', async ({ templateId, type }, thunkApi) => {
    const result = await ConfigServiceAPI.deleteTemplate(templateId);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: result.response as any, error: null };
});

export const retractTemplate = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { templateId: string; type: templateType }
>('templates/retractTemplate', async ({ templateId, type }, thunkApi) => {
    const result = await ConfigServiceAPI.retractTemplate(templateId);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const releaseTemplate = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { templateId: string; type: templateType }
>('templates/releaseTemplate', async ({ templateId, type }, thunkApi) => {
    const result = await ConfigServiceAPI.releaseTemplate(templateId);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const fetchTemplatesTags = createAsyncThunk<{ tags: string[]; error: { message: string; code: string } | null }>(
    'templates/fetchTemplatesTags',
    async (_, thunkApi) => {
        const result = await ConfigServiceAPI.getTemplatesTags();
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { tags: result.response as any, error: null };
    }
);

export interface templatesState {
    templates: {
        page?: Template[];
        module?: Template[];
        targetGroup?: Template[];
        audience?: Template[];
        item?: Template[];
        pageStyle?: Template[];
        dynamicSource?: Template[];
    };
    selectedTemplate?: Template;
    templatesTags: string[];
    tagsLoading: boolean;
    loading: boolean;
    totalResults: { [key in templateType]?: number | undefined };
    templateTypeLoading: { [key in templateType]: boolean };
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: templatesState = {
    templates: {},
    templateTypeLoading: {
        page: false,
        module: false,
        targetGroup: false,
        audience: false,
        item: false,
        pageStyle: false,
        dynamicSource: false
    },
    totalResults: {},
    templatesTags: [],
    tagsLoading: false,
    loading: false,
    error: null
};

const slice = createSlice({
    name: 'templates',
    initialState,
    reducers: {
        unsetTemplates(state) {
            state.templates = {};
        },
        unsetTemplateError(state) {
            state.error = null;
        },
        unsetSelectedTemplate(state) {
            state.selectedTemplate = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTemplates.fulfilled, (state, action: any) => {
                state.templates = {
                    ...state.templates,
                    [action.meta.arg.type]: [...action.payload.templates]
                };
                state.error = null;
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
                state.totalResults[action.meta.arg.type as templateType] = action.payload.totalResults;
            })
            .addCase(fetchTemplates.rejected, (state, action: any) => {
                state.templates = {};
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
            })
            .addCase(fetchTemplates.pending, (state, action) => {
                state.loading = true;
                state.templateTypeLoading[action.meta.arg.type as templateType] = true;
                state.templates = {
                    ...state.templates,
                    [action.meta.arg.type]: []
                };
            })
            .addCase(fetchTemplate.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
            })
            .addCase(fetchTemplate.pending, (state, action) => {
                state.loading = true;
                state.templateTypeLoading[action.meta.arg.type as templateType] = true;
            })
            .addCase(fetchTemplate.fulfilled, (state, action: any) => {
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
                state.selectedTemplate = action.payload.template;
            })
            .addCase(fetchTemplatesTags.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.tagsLoading = false;
            })
            .addCase(fetchTemplatesTags.pending, (state, _action) => {
                state.tagsLoading = true;
            })
            .addCase(fetchTemplatesTags.fulfilled, (state, action: any) => {
                state.tagsLoading = false;
                state.templatesTags = action.payload.tags;
            })
            .addCase(applyTemplate.fulfilled, (state, action: any) => {
                state.error = null;
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
            })
            .addCase(applyTemplate.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
            })
            .addCase(applyTemplate.pending, (state, action) => {
                state.loading = true;
                state.templateTypeLoading[action.meta.arg.type as templateType] = true;
            })
            .addCase(createTemplate.fulfilled, (state, action: any) => {
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
            })
            .addCase(createTemplate.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
            })
            .addCase(createTemplate.pending, (state, action: any) => {
                state.loading = true;
                state.templateTypeLoading[action.meta.arg.type as templateType] = true;
            })
            .addCase(updateTemplate.fulfilled, (state, action: any) => {
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
            })
            .addCase(updateTemplate.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.error };
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
            })
            .addCase(updateTemplate.pending, (state, action: any) => {
                state.loading = true;
                state.templateTypeLoading[action.meta.arg.type as templateType] = true;
            })
            .addCase(deleteTemplate.fulfilled, (state, action: any) => {
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
            })
            .addCase(deleteTemplate.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
            })
            .addCase(deleteTemplate.pending, (state, action: any) => {
                state.loading = true;
                state.templateTypeLoading[action.meta.arg.type as templateType] = true;
            })
            .addCase(releaseTemplate.fulfilled, (state, action: any) => {
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
            })
            .addCase(releaseTemplate.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
            })
            .addCase(releaseTemplate.pending, (state, action: any) => {
                state.loading = true;
                state.templateTypeLoading[action.meta.arg.type as templateType] = true;
            })
            .addCase(retractTemplate.fulfilled, (state, action: any) => {
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
            })
            .addCase(retractTemplate.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
                state.templateTypeLoading[action.meta.arg.type as templateType] = false;
            })
            .addCase(retractTemplate.pending, (state, action: any) => {
                state.loading = true;
                state.templateTypeLoading[action.meta.arg.type as templateType] = true;
            });
    }
});

export const { unsetTemplates, unsetTemplateError, unsetSelectedTemplate } = slice.actions;

export default slice.reducer;
