import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { TableContainer } from '../common/Table/Table.css';
import { descendingZIndex, UserAvatar } from '../Projects/Projects.css';
import { SearchBarContainer } from '../common/SearchBar/SearchBar.css';
import { devices } from '../../style/styled-components/reusable.css';
import { ButtonWrapper } from '../Buttons/Button/Button.css';

const theme: any = getTheme();

export const TenantsContainer = styled.div`
    min-height: 100vh;
    background-color: ${theme.palette.background.main};
    ${TableContainer} {
        margin: 24px 36px;
    }
    ${SearchBarContainer} {
        margin: 0 36px;
        box-sizing: border-box;
    }
`;

export const TenantsNotFoundContainer = styled.div`
    margin: 12px 36px;
`;

export const HeaderRight = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;

    ${ButtonWrapper} {
        margin: 0;
        padding: 0;
        width: 142px;
        height: 36px;
        border-radius: 4px;

        ${devices.mobileL} {
            width: 52px;
        }
    }

    ${devices.mobileL} {
        width: unset;
    }
`;

export const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;

    svg {
        width: 106px;
        height: 32px;
        &:hover {
            cursor: pointer;
        }
    }

    ${devices.tablet} {
        gap: unset;
    }
`;

export const HeaderTitle = styled.div`
    ${fontSizesCSS.Title3};
    color: ${theme.palette.text.main};
`;

export const Header = styled.div`
    padding: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const TenantAdminsContainer = styled.div`
    display: flex;
    ${descendingZIndex()};
    ${UserAvatar} {
        border: solid 1px ${theme.palette.white.main};
        margin-right: -8px;
        width: 30px;
        height: 30px;
    }
`;

export const TenantPreviewForDeletion = styled.div`
    display: flex;
    align-items: center;
    background-color: ${theme.palette.background.contrast};
    img {
        width: 48px;
        height: 48px;
        padding: 0;
        margin: 0 12px;
    }
    p {
        font-weight: 700;
        padding-left: 5px;
    }
    margin-bottom: 36px;
`;
