import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DynamicData } from '../../utils/api/dataHandlers/dynamicData';

export interface PreviewItemsState {
    items: DynamicData[];
}

const initialState: PreviewItemsState = {
    items: []
};

// small slice for the preview items. they are manually added after they are retrieved so that we can easily make use of the metadata anywhere in the app
const slice = createSlice({
    name: 'preview',
    initialState,
    reducers: {
        setPreviewItems(state, action: PayloadAction<DynamicData[]>) {
            state.items = action.payload;
        },
        unsetPreviewItems(state) {
            state.items = [];
        }
    }
});

export const { setPreviewItems, unsetPreviewItems } = slice.actions;
export default slice.reducer;
