import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { devices } from '../../../style/styled-components/reusable.css';

const theme: any = getTheme();

export const NewsWrapper = styled.div`
    box-sizing: border-box;
    width: 1056px;
    padding: 0 36px;
    display: flex;
    flex-direction: column;
    ${devices.desktopS} {
        width: 360px;
    }
    cursor: pointer;
`;
export const NewsTitle = styled.div`
    span {
        color: ${theme.palette.error.main};
    }
    ${fontSizesCSS.Title3}
    margin-bottom: 12px;
`;

export const NewsSubtitle = styled.div`
    ${fontSizesCSS.Caption2};
    ${devices.desktopS} {
        ${fontSizesCSS.Caption3};
        font-weight: bold;
    }
`;

export const NewsDescription = styled.div`
    ${fontSizesCSS.Caption4}
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const NewsWarning = styled.div`
    display: flex;
    margin-top: 32px;
    flex-direction: column;
`;
export const NewsWarningTitle = styled.div`
    gap: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    ${fontSizesCSS.Caption2};

    svg {
        width: 24px;
        height: 24px;

        path {
            fill: ${theme.palette.warning.main};
        }
    }
`;

export const NewsWarningText = styled.div`
    ${fontSizesCSS.Caption1};
    padding-left: 32px;
`;
