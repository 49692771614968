import React, { useEffect, useRef, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import _ from 'lodash';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';

import { ACCEPTED_SORT_FIELDS, DEFAULT_SORT_CONFIG, ISortConfig } from '../../utils/fnSort';
import { generateRandomColor } from '../../utils/fnGenerator';
import { generateDateStringForTables } from '../../utils/fnDate';
import { DIALOG_NAMES, dialogConfirm, ToastAlert } from '../../utils/fnDialogs';

import icons from '../../style';
import {
    CategoryRow,
    IconWrapper,
    ProjectLeadsContainer,
    ProjectPreviewForDeletion,
    ProjectSelectionContainer,
    UserAvatar,
    UserAvatarPlaceholder
} from './Projects.css';

import Button from '../Buttons/Button/Button';
import Profile from '../common/Profile/Profile';
import GenericTable, {
    ActionsTableCell,
    HeaderTableCell,
    ImageTableCell,
    SortableHeaderTableCell,
    tableActions
} from '../common/Table/Table';
import { SearchBar } from '../common/SearchBar/SearchBar';
import { BlankTableRow, GroupedByIcon, GroupedByTableRow, GroupedByTitle, WidthTableCell } from '../common/Table/Table.css';
import { TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import NewProjectDialog from './Dialogs/NewProject';
import { renderTooltip, tooltipPositions, tooltipTypes } from '../common/Tooltips/Tooltips';
import DuplicateProjectDialog from './Dialogs/DuplicateProject';
import {
    copyProject,
    createProject as insertProject,
    disableProject as removeProject,
    duplicateProject as insertProjectCopy,
    fetchProjects,
    ProjectsState,
    updateProject as editProject
} from '../../redux/slices/projectsSlice';
import { Project } from '../../types/Project';
import { Header, HeaderLeft, HeaderRight, HeaderTitle } from '../Tenants/Tenants.css';
import { PermissionsState, setUserPermissions } from '../../redux/slices/permissionsSlice';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { setActiveProject, setActiveTenant } from '../../redux/slices/activeItemSlice';
import configServiceAPI from '../../utils/api/configServiceAPI';
import { Loader } from '../common/Loader/Loader';
import useScreenSize from '../../hooks/useScreenSize';
import HelpIcon, { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';
import { buildPathWithProjectId, PageRoutes } from '../../types/RouteTypes';
import { MoreInfoDialog, MoreInfoTypes } from '../common/Dialog/MoreInfoDialog';
import { USERROLES } from '../../utils/Globals';
import { TruncatedText } from '../../style/styled-components/reusable.css';
import { ProjectsTableSizes } from '../../types/TableSizes';
import CopyProjectDialog from './Dialogs/CopyProjectDialog';
import { SearchBarContainer } from '../common/SearchBar/SearchBar.css';
import { capitalizeAndSplitCamelCaseString } from '../../utils/fnString';
import { TooltipActionWrapper, TooltipValue, TooltipWrapper } from '../common/Tooltips/Tooltips.css';

const ProjectSelection: React.FC = () => {
    const { projects: storeProjects, error, loading }: ProjectsState = useAppSelector((state) => state.projects);
    const { userPermissions }: PermissionsState = useAppSelector((state) => state.permissions);
    // second projects state needed for sorting
    const [projects, setProjects] = useState<Project[]>([]);
    const [openAddProject, setOpenAddProject] = useState(false);
    const [openDuplicateProject, setOpenDuplicateProject] = useState(false);
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [openTooltips, setOpenTooltips] = useState<any>(null);
    const [colors, setColors] = useState<any>(null);
    const [duplicateIndex, setDuplicateIndex] = useState<number | null>(null);
    const [isTooltipLinkCopied, setIsTooltipLinkCopied] = useState<boolean>(false);

    // SEARCHING AND SORTING STATE
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [activeSortingKey, setActiveSortingKey] = useState<string | null>(ACCEPTED_SORT_FIELDS.lastModified);
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const toolTipTimeout = useRef<any>(null);

    const [openCopyProjectDialog, setOpenCopyProjectDialog] = useState(false);
    const [projectToCopy, setProjectToCopy] = useState<Project | undefined>(undefined);

    const [showMoreDialog, setShowMoreDialog] = useState<{ type: MoreInfoTypes | null; show: boolean; data: any[] }>({
        type: null,
        show: false,
        data: []
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation() as any;

    const { isSmallMobile, isMobile } = useScreenSize();

    const columnKeys = ['name', 'key', 'projectLeads', 'lastModified'];
    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;

    useEffect(() => {
        loadProjects(true, orderBy, searchTerm).then((response) => {
            if (response.permissions) {
                dispatch(setUserPermissions(response.permissions));
            }
        });
    }, []);

    useEffect(() => {
        // we are getting here from users and trying to automatically edit a project
        if (location?.state?.selectedProjectId && projects.length) {
            const index = projects.findIndex((project) => project._id === location.state.selectedProjectId);
            setEditIndex(index === -1 ? null : index);
            setOpenAddProject(index !== -1);
            window.history.replaceState({}, '');
        }
    }, [projects]);

    useEffect(() => {
        // each time loading state from slice is changed, we need to check for new projects or errors
        if (loading || error) return;
        if (storeProjects) {
            const newColors = { ...colors };
            const newProjects = [...storeProjects];
            newProjects.forEach((project) => {
                newColors[project._id] = generateRandomColor();
            });

            setColors(newColors);
            setProjects(newProjects);
        }
    }, [loading]);

    useEffect(() => {
        const newTooltips = { ...openTooltips };
        projects.forEach((_, index) => {
            newTooltips[index] = false;
        });
        setOpenTooltips(newTooltips);
    }, [projects]);

    const loadProjects = async (addPermissions?: boolean, orderBy?: string, searchTerm?: string) => {
        return await dispatch(fetchProjects({ addPermissions, orderBy, searchTerm })).unwrap();
    };

    const createProject = async (project: Project) => {
        const response = await dispatch(insertProject(project)).unwrap();
        if (response.id) {
            loadProjects(true);
            setSearchTerm(undefined);
            setSortConfig(DEFAULT_SORT_CONFIG);
            setActiveSortingKey(ACCEPTED_SORT_FIELDS.lastModified);
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.PROJECT_CREATED);
        }
    };
    const _copyProject = async (projectId: string, tenantId: string, projectKey: string) => {
        const response = await dispatch(copyProject({ projectId, tenantId, projectKey })).unwrap();
        if (response.id) {
            loadProjects(true, orderBy, searchTerm);
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.PROJECT_COPIED);
        }
    };

    const duplicateProject = async (projectId: string, tenantId: string, projectKey: string, projectName: string) => {
        const response = await dispatch(insertProjectCopy({ projectId, tenantId, projectKey, projectName })).unwrap();
        if (response.id) {
            loadProjects(true, orderBy, searchTerm);
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.PROJECT_DUPLICATED);
        }
    };

    const updateProject = async (project: Project) => {
        await dispatch(editProject(project)).unwrap();
        loadProjects(true, orderBy, searchTerm);
    };

    const disableProject = async (id: string) => {
        await dispatch(removeProject(id)).unwrap();
        loadProjects(true, orderBy, searchTerm);
    };

    const handleOnSearch = (searchTerm: string) => {
        loadProjects(true, orderBy, searchTerm);
    };

    const handleTokenClick = async (id: string) => {
        const { response } = await configServiceAPI.createToken(id);

        if (response) {
            const token = (response as any).token;
            navigator.clipboard.writeText(token);
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.GENERATE_TOKEN);
        }
    };

    const handleSortIconClick = (field: string) => {
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && (sortConfig.field === field || sortConfig.field.split('.')[0] === field) && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            direction,
            field
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadProjects(true, orderBy, searchTerm);
        setShowSortArrows(false);
    };

    const handleDeleteIconClick = (id: string) => {
        const project = projects.find((project) => project._id === id);
        const values = {
            title: 'Disable Project',
            text: (
                <>
                    <p>
                        You are in the process of disabling your project <strong>{project?.name}</strong>. Are you sure you want to disable
                        the selected project including all its connected configurations, audiences, targets and files?
                    </p>
                    <p>Are you sure you want to disable?</p>
                </>
            )
        };
        dialogConfirm(
            '',
            () => {
                disableProject(id);
            },
            values,
            <ProjectPreviewForDeletion>
                <img src={project?.logo || icons.projectIcon1} alt="" />
                <p>{project?.name}</p>
            </ProjectPreviewForDeletion>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Disable'
                }
            }
        );
    };

    const isProjectLead = (project: any) => {
        if (!userPermissions?.projectLeadIn.length) return false;
        return !userPermissions?.isSuperAdmin && userPermissions?.projectLeadIn.includes(project._id);
    };

    const isTenantAdmin = (tenantId: any) => {
        if (!userPermissions?.tenantAdminIn.length) return false;
        return userPermissions?.tenantAdminIn.includes(tenantId);
    };

    const renderActions = (project: any) => {
        const overrideRole = userPermissions?.overrideRole?.find((role: any) => role.projectId === project._id)?.roleName;

        const isEditor = overrideRole ? overrideRole === USERROLES.EDITOR : userPermissions?.isEditor;
        const isViewer = overrideRole ? overrideRole === USERROLES.VIEWER : userPermissions?.isViewer;
        if (isEditor || isViewer) {
            if (isTenantAdmin(project.tenantId)) {
                return [tableActions.EDIT, tableActions.DUPLICATE, tableActions.TOKEN, tableActions.REMOVE];
            }
            if (isProjectLead(project)) {
                return [tableActions.EDIT, tableActions.TOKEN];
            }
        }
        if (userPermissions?.isSuperAdmin) {
            return [tableActions.EDIT, tableActions.COPY, tableActions.DUPLICATE, tableActions.TOKEN, tableActions.REMOVE];
        }
        return isViewer ? [] : [tableActions.TOKEN];
    };

    const handleEditIconClick = (id: string) => {
        const index = projects.findIndex((project) => project._id === id);
        if (index < 0) return;
        setEditIndex(index);
        setOpenAddProject(true);
    };

    const handleDuplicateIconClick = (id: string) => {
        const index = projects.findIndex((project) => project._id === id);
        if (index < 0) return;
        setDuplicateIndex(index);
        setOpenDuplicateProject(true);
    };

    const buildTableColumns = () => {
        const columns = projects?.length
            ? columnKeys.map((key, index) => {
                  const text = capitalizeAndSplitCamelCaseString(key);
                  const isAcceptedSorField = ACCEPTED_SORT_FIELDS[key as keyof typeof ACCEPTED_SORT_FIELDS];

                  return isAcceptedSorField ? (
                      <SortableHeaderTableCell
                          key={index}
                          text={text}
                          onClick={() => handleSortIconClick(key)}
                          onMouseEnter={() => setShowSortArrows(true)}
                          onMouseLeave={() => setShowSortArrows(false)}
                          hideArrow={!showSortArrows && activeSortingKey !== key}
                          columnSize={ProjectsTableSizes[key as keyof typeof ProjectsTableSizes]}
                          direction={
                              ((sortConfig?.field === key || sortConfig?.field.split('.')[0] === key) && sortConfig?.direction) || 'asc'
                          }
                      />
                  ) : (
                      <HeaderTableCell text={text} key={index} columnSize={ProjectsTableSizes[key as keyof typeof ProjectsTableSizes]} />
                  );
              })
            : [];
        !isSmallMobile && columns.unshift(<TableCell key={'first_cell'} />);
        return columns;
    };

    const buildTableBody = () => {
        const projectsToRender = _.groupBy(projects, 'tenantId');
        const multipleTenants = Object.keys(projectsToRender).length > 1;

        const rows: JSX.Element[] = [];
        Object.values(projectsToRender)
            .flat()
            .forEach((project, index) => {
                const lastModified = generateDateStringForTables(project.lastModified || 0);

                const tooltipValue = `${window.location.protocol}//${window.location.hostname}/projects/${project._id}`;
                const tooltip = (
                    <TooltipWrapper>
                        <IconWrapper>
                            <SVGInline src={icons.linkSimpleIcon} />
                        </IconWrapper>
                        <TooltipValue>{tooltipValue}</TooltipValue>
                        <TooltipActionWrapper
                            onClick={() => {
                                navigator.clipboard.writeText(tooltipValue);
                                setIsTooltipLinkCopied(true);
                                setOpenTooltips({
                                    ...openTooltips,
                                    [index]: false
                                });
                                clearTimeout(toolTipTimeout.current);
                                toolTipTimeout.current = setTimeout(() => {
                                    setIsTooltipLinkCopied(false);
                                }, 500);
                            }}
                        >
                            {isTooltipLinkCopied ? 'Copied' : 'Copy Link'}
                        </TooltipActionWrapper>
                    </TooltipWrapper>
                );
                if (multipleTenants && rows.findIndex((elem) => elem.key === project.tenantId) < 0) {
                    index !== 0 && rows.push(<BlankTableRow key={`blank_tr_${index}`} />);
                    rows.push(
                        <GroupedByTableRow key={project.tenantId}>
                            <TableCell colSpan={7}>
                                <GroupedByIcon src={project.tenant?.logo || icons.tenantIcon} />
                                <GroupedByTitle> {project.tenant?.name}</GroupedByTitle>
                            </TableCell>
                        </GroupedByTableRow>
                    );
                }
                rows.push(
                    <TableRow
                        key={project._id}
                        id={`${project._id}`}
                        data-cy={`project-row-${index}`}
                        onMouseLeave={() => {
                            setOpenTooltips((tooltips: any) => {
                                const newTooltips = { ...tooltips };
                                Object.keys(newTooltips).forEach((key) => {
                                    newTooltips[key] = false;
                                });
                                return newTooltips;
                            });
                        }}
                    >
                        {/* PROJECTS ICON TABLE CELL */}
                        {!isSmallMobile && (
                            <ImageTableCell
                                shape={'square'}
                                imageSize={{ width: 48, height: 48 }}
                                src={project.logo || icons.projectIcon1}
                            />
                        )}

                        {/* PROJECTS NAME TABLE CELL */}
                        <WidthTableCell {...ProjectsTableSizes.name}>
                            {renderTooltip(
                                <Link
                                    to={buildPathWithProjectId(project._id, PageRoutes.DASHBOARD)}
                                    onMouseEnter={() =>
                                        setOpenTooltips((tooltips: any) => {
                                            const newOpenTooltips = { ...tooltips };
                                            Object.keys(newOpenTooltips).forEach((key) => {
                                                newOpenTooltips[key] = key === index.toString();
                                            });
                                            return newOpenTooltips;
                                        })
                                    }
                                    onClick={() => {
                                        dispatch(setActiveProject(project._id));
                                        dispatch(setActiveTenant(project.tenantId));
                                    }}
                                >
                                    <TruncatedText>{project.name}</TruncatedText>
                                    {project?.description && (
                                        <CategoryRow>
                                            <TruncatedText>{project.description}</TruncatedText>
                                        </CategoryRow>
                                    )}
                                </Link>,
                                tooltipTypes.HTML,
                                tooltip,
                                tooltipPositions.BOTTOM_START,
                                undefined,
                                true,
                                false,
                                openTooltips?.[index] || false
                            )}
                        </WidthTableCell>

                        {/* KEY TABLE CELL */}
                        <WidthTableCell {...ProjectsTableSizes.key}>{project.key}</WidthTableCell>

                        {/* PROJECT LEADS TABLE CELL */}
                        <WidthTableCell {...ProjectsTableSizes.projectLeads}>
                            <ProjectLeadsContainer>{buildProjectLeads(project.projectLeads || [])}</ProjectLeadsContainer>
                        </WidthTableCell>

                        {/* LAST MODIFIED TABLE CELL */}
                        <WidthTableCell {...ProjectsTableSizes.lastModified}>{lastModified}</WidthTableCell>

                        {/* ACTIONS TABLE CELL */}
                        <WidthTableCell {...ProjectsTableSizes.actions}>
                            <ActionsTableCell
                                tooltipTexts={{
                                    edit: 'projects_icon_edit',
                                    duplicate: 'projects_icon_duplicate',
                                    delete: 'projects_icon_disable',
                                    token: 'projects_icon_generate_token',
                                    copy: 'projects_icon_copy'
                                }}
                                actions={renderActions(project)}
                                onEdit={() => handleEditIconClick(project._id)}
                                onRemove={() => handleDeleteIconClick(project._id)}
                                onDuplicate={() => handleDuplicateIconClick(project._id)}
                                onToken={() => handleTokenClick(project._id)}
                                onCopy={() => {
                                    setOpenCopyProjectDialog(true);
                                    setProjectToCopy(project);
                                }}
                                canCopy={userPermissions?.isSuperAdmin}
                                showDuplicateAnyway
                            />
                        </WidthTableCell>
                    </TableRow>
                );
            });

        return <TableBody>{rows}</TableBody>;
    };
    const buildProjectLeads = (projectLeads: any[]) => {
        const numOfItemsToShow = isMobile ? 1 : 3;
        return (
            <>
                {projectLeads.slice(0, numOfItemsToShow).map((lead, index) => {
                    return (
                        <Tooltip key={index} title={lead.fullName} placement="right-start">
                            <UserAvatar background={lead.icon || icons.userIcon} />
                        </Tooltip>
                    );
                })}
                {projectLeads.length > numOfItemsToShow && (
                    <UserAvatarPlaceholder onClick={() => setShowMoreDialog({ type: MoreInfoTypes.USERS, show: true, data: projectLeads })}>
                        +{projectLeads.length - numOfItemsToShow}
                    </UserAvatarPlaceholder>
                )}
            </>
        );
    };

    if (!loading && !userPermissions) {
        // if data is loaded and permissions are not existant, display an error
        return <BackendErrorDialog error={{ status: 401 }} />;
    }

    return (
        <>
            {error && <BackendErrorDialog error={error} />}
            <ProjectSelectionContainer>
                <Header>
                    <HeaderLeft>
                        <SVGInline src={icons.logo3Ready} />
                        <HeaderTitle>Projects</HeaderTitle>
                    </HeaderLeft>
                    <HeaderRight>
                        <HelpIcon circlesSlugOptions={{ default: CIRCLE_SLUGS.projects, onboarding: ONBOARDING_CIRCLE_SLUGS.projects }} />
                        {(userPermissions?.isSuperAdmin || userPermissions?.tenantAdminIn?.length !== 0) && (
                            <Button type="BLUE" label="Create Project" onClick={() => setOpenAddProject(true)} responsive={isSmallMobile} />
                        )}
                        {(userPermissions?.tenantAdminIn?.length || userPermissions?.isSuperAdmin) && (
                            <Button
                                type="DEFAULT"
                                label="Manage Users"
                                onClick={() => navigate(PageRoutes.USERS_GROUPS)}
                                responsive={isSmallMobile}
                                dataCy={'manage-users-button'}
                            />
                        )}
                        <Profile />
                    </HeaderRight>
                </Header>
                <SearchBarContainer>
                    <SearchBar
                        title={'Search Name'}
                        disabled={loading}
                        searchTerm={searchTerm}
                        onSearch={handleOnSearch}
                        setSearchTerm={setSearchTerm}
                        tooltipText={'projects_icon_search'}
                    />
                </SearchBarContainer>
                {loading ? (
                    <Loader title={'Projects'} />
                ) : (
                    <GenericTable
                        body={buildTableBody()}
                        columns={buildTableColumns()}
                        sortableColumns={['name', 'key', 'lead', 'type']}
                        dataCy={'projects-table'}
                    />
                )}
                <NewProjectDialog
                    projects={projects}
                    open={openAddProject}
                    onClose={() => {
                        setOpenAddProject(false);
                        setEditIndex(null);
                    }}
                    errorMsg=""
                    editIndex={editIndex}
                    onSave={(newProject: Project) => {
                        // This is not the best implementation but we'll how to handle colors with backend
                        if (editIndex === null) {
                            setColors((prevColors: any) => {
                                return {
                                    ...prevColors,
                                    [newProject._id]: generateRandomColor()
                                };
                            });
                            createProject(newProject);
                        } else {
                            updateProject(newProject);
                        }
                        setEditIndex(null);
                        setOpenAddProject(false);
                    }}
                    project={editIndex !== null ? projects[editIndex] : undefined}
                    isProjectLead={editIndex !== null && isProjectLead(projects[editIndex])}
                />
                <DuplicateProjectDialog
                    open={openDuplicateProject}
                    projects={projects}
                    project={duplicateIndex !== null ? projects[duplicateIndex] : undefined}
                    onClose={() => {
                        setOpenDuplicateProject(false);
                        setDuplicateIndex(null);
                    }}
                    onSave={(projectId: string, tenantId: string, projectKey: string, projectName: string) => {
                        duplicateProject(projectId, tenantId, projectKey, projectName);
                        setDuplicateIndex(null);
                        setOpenDuplicateProject(false);
                    }}
                />
                <CopyProjectDialog
                    open={openCopyProjectDialog}
                    project={projectToCopy}
                    onClose={() => {
                        setProjectToCopy(undefined);
                        setOpenCopyProjectDialog(false);
                    }}
                    onCopy={async (projectId: string, tenantId: string, projectKey: string) => {
                        try {
                            await _copyProject(projectId, tenantId, projectKey);
                        } catch (ex) {
                            console.error(ex);
                        } finally {
                            setOpenCopyProjectDialog(false);
                        }
                    }}
                />
                <MoreInfoDialog
                    type={showMoreDialog.type!}
                    open={showMoreDialog.show}
                    onClose={() => setShowMoreDialog({ type: null, show: false, data: [] })}
                    data={showMoreDialog.data}
                />
            </ProjectSelectionContainer>
        </>
    );
};

export default ProjectSelection;
