import React, { FC } from 'react';
import { ProviderLogoCardContainer, ProviderCardSizeKey, ProviderLogoCardBox, ProviderLogoImg } from '../VisualEditor.css';
import { providerCardLogos } from '../../../assets/images/providerCardLogos';

export const supportedProvidersForLogos = ['youtube', 'youtube_reco', 'prime_video'] as const;
export type providerLogos = typeof supportedProvidersForLogos[number];

const ProviderLogoCard: FC<{ provider: providerLogos; template: ProviderCardSizeKey }> = ({ provider, template }) => {
    const renderProviderLogo = () => {
        switch (provider) {
            case 'youtube':
            case 'youtube_reco':
                return <ProviderLogoImg src={providerCardLogos.YoutubeCardLogo} alt="youtube" />;
            case 'prime_video':
                return <ProviderLogoImg src={providerCardLogos.PrimeVideoCardLogo} alt="prime_video" />;
            default:
                return null;
        }
    };

    return (
        <ProviderLogoCardBox>
            <ProviderLogoCardContainer $type={template} $provider={provider}>
                {renderProviderLogo()}
            </ProviderLogoCardContainer>
        </ProviderLogoCardBox>
    );
};

export default ProviderLogoCard;
