import { FC } from 'react';
import { tableActions } from '../Table/Table';
import useScreenSize from '../../../hooks/useScreenSize';
import { EncodeStatusHolder, TablePublishStatusHolder } from '../Table/Table.css';
import { renderTooltipWithKey } from '../Tooltips/Tooltips';
import SVGInline from 'react-inlinesvg';
import icons from '../../../style';
import {
    ActionCheckboxWrapper,
    ActionContainer,
    ActionIconHolder,
    ActionWrapper,
    ArrowIconHolder,
    MobileActionContainer,
    MobileActionOption,
    MobileActionsDropdown,
    MobileActionsMenuWrapper,
    MobileActionText
} from './Actions.css';
import { PUBLISHED_STATUS, renderPublishStatusIcon } from '../../../utils/fnPublish';
import { DialogCheckbox } from '../Dialog/GenericDialog';
import { EncodeStatus, renderEncodeStatusIcon } from '../../../utils/fnEncode';

export type ActionsProps = {
    actions: tableActions[];
    onCopy?: (evt?: any) => void;
    onEdit?: (evt?: any) => void;
    onRemove?: (evt?: any) => void;
    onDuplicate?: (evt?: any) => void;
    onCustomize?: (evt?: any) => void;
    onToken?: (evt?: any) => void;
    onDownload?: (evt?: any) => void;
    onEnable?: (evt?: any) => void;
    onArrowToggle?: () => void;
    onPurge?: () => void;
    onInfoClick?: () => void;
    onMultiSelect?: () => void;
    onEncode?: () => void;
    onAdminLock?: (evt: any) => void;
    onDeactivate?: (evt: any) => void;
    withArrow?: boolean;
    open?: boolean;
    encodeStatus?: EncodeStatus;
    publishedStatus?: PUBLISHED_STATUS;
    publishAt?: number;
    withoutDropdown?: boolean;
    tooltipTexts?: {
        copy?: string;
        edit?: string;
        customize?: string;
        token?: string;
        delete?: string;
        duplicate?: string;
        download?: string;
        enable?: string;
        published?: string;
        arrowOpen?: string;
        arrowClose?: string;
        purge?: string;
        info?: string;
        encode?: string;
        adminLock?: string;
        deactivate?: string;
    };
    canCopy?: boolean;
    selected?: boolean;
    // needed until we bring back duplicate functionality because project duplication should still exist
    showDuplicateAnyway?: boolean;
    adminLocked?: boolean;
    deactivated?: boolean;
};

export const ObjectActions: FC<ActionsProps> = ({
    actions,
    onCopy,
    onEdit,
    onRemove,
    onDuplicate,
    onCustomize,
    onToken,
    onDownload,
    onEnable,
    onArrowToggle,
    onPurge,
    onInfoClick,
    onMultiSelect,
    onEncode,
    onAdminLock,
    onDeactivate,
    tooltipTexts,
    withArrow,
    open,
    encodeStatus,
    publishedStatus,
    publishAt,
    withoutDropdown,
    canCopy,
    showDuplicateAnyway,
    selected,
    adminLocked,
    deactivated
}) => {
    const actionTooltips: { [key: string]: string } = {
        [tableActions.COPY]: tooltipTexts?.copy || 'generic_icon_copy',
        [tableActions.EDIT]: tooltipTexts?.edit || 'generic_icon_edit',
        [tableActions.DUPLICATE]: tooltipTexts?.duplicate || 'generic_icon_duplicate',
        [tableActions.REMOVE]: tooltipTexts?.delete || 'generic_icon_delete',
        [tableActions.CUSTOMIZE]: tooltipTexts?.customize || 'generic_icon_customize',
        [tableActions.TOKEN]: tooltipTexts?.token || 'generic_icon_generate_token',
        [tableActions.DOWNLOAD]: tooltipTexts?.download || 'generic_icon_generate_download',
        [tableActions.ENABLE]: tooltipTexts?.enable || 'generic_icon_generate_enable',
        [tableActions.PURGE]: tooltipTexts?.purge || '',
        [tableActions.INFO]: tooltipTexts?.info || '',
        [tableActions.ADMIN_LOCK]: tooltipTexts?.adminLock || '',
        [tableActions.DEACTIVATE]: tooltipTexts?.deactivate || deactivated ? 'generic_icon_activate' : 'generic_icon_deactivate'
    };

    const actionTexts: { [key: string]: string } = {
        [tableActions.COPY]: 'Copy',
        [tableActions.EDIT]: 'Edit',
        [tableActions.DUPLICATE]: 'Duplicate',
        [tableActions.REMOVE]: 'Delete',
        [tableActions.CUSTOMIZE]: 'Customize',
        [tableActions.TOKEN]: 'Generate Token',
        [tableActions.DOWNLOAD]: 'Download',
        [tableActions.ENABLE]: 'Enable',
        [tableActions.PURGE]: 'Purge',
        [tableActions.INFO]: 'Details',
        [tableActions.ADMIN_LOCK]: adminLocked ? 'Unlock' : 'Lock',
        [tableActions.DEACTIVATE]: deactivated ? 'Activate' : 'Deactivate'
    };

    const actionIcons: { [key: string]: string } = {
        [tableActions.COPY]: icons.copyToIcon,
        [tableActions.EDIT]: icons.editIcon,
        [tableActions.DUPLICATE]: icons.duplicateIcon,
        [tableActions.REMOVE]: icons.trashIcon,
        [tableActions.CUSTOMIZE]: icons.customizeIcon,
        [tableActions.TOKEN]: icons.iconToken,
        [tableActions.DOWNLOAD]: icons.downloadIcon,
        [tableActions.ENABLE]: icons.checkboxActiveIcon,
        [tableActions.PURGE]: icons.purgeIcon,
        [tableActions.INFO]: icons.infoIcon,
        [tableActions.ADMIN_LOCK]: adminLocked ? icons.adminUnlockBlack : icons.adminLockBlack,
        [tableActions.DEACTIVATE]: deactivated ? icons.activateObjectIcon : icons.deactivateObjectIcon
    };

    const { isMobile } = useScreenSize();
    const handleIconClick = (evt: any, type: tableActions) => {
        evt.preventDefault();
        switch (type) {
            case tableActions.COPY:
                if (onCopy) {
                    onCopy(evt);
                }
                break;
            case tableActions.EDIT:
                if (onEdit) {
                    onEdit(evt);
                }
                break;
            case tableActions.REMOVE:
                if (onRemove) {
                    onRemove(evt);
                }
                break;
            case tableActions.DUPLICATE:
                if (onDuplicate) {
                    onDuplicate(evt);
                }
                break;
            case tableActions.CUSTOMIZE:
                if (onCustomize) {
                    onCustomize();
                }
                break;
            case tableActions.TOKEN:
                if (onToken) {
                    onToken();
                }
                break;
            case tableActions.DOWNLOAD:
                if (onDownload) {
                    onDownload(evt);
                }
                break;
            case tableActions.ENABLE:
                if (onEnable) {
                    onEnable(evt);
                }
                break;
            case tableActions.PURGE:
                if (onPurge) {
                    onPurge();
                }
                break;
            case tableActions.INFO:
                if (onInfoClick) {
                    onInfoClick();
                }
                break;
            case tableActions.ADMIN_LOCK:
                if (onAdminLock) {
                    onAdminLock(evt);
                }
                break;
            case tableActions.DEACTIVATE:
                if (onDeactivate) {
                    onDeactivate(evt);
                }
                break;
            default:
                break;
        }
    };

    if (actions.includes(tableActions.MULTI_SELECT)) {
        return (
            <ActionContainer>
                <ActionWrapper>
                    {publishedStatus && (
                        <TablePublishStatusHolder>
                            {renderPublishStatusIcon(publishedStatus, publishAt, tooltipTexts?.published)}
                        </TablePublishStatusHolder>
                    )}
                    <ActionCheckboxWrapper>
                        <DialogCheckbox
                            value={selected || false}
                            onClick={() => {
                                onMultiSelect?.();
                            }}
                            active={selected || false}
                            text={''}
                            tooltipText="multi_select_object"
                        />
                    </ActionCheckboxWrapper>
                </ActionWrapper>
            </ActionContainer>
        );
    }

    if (isMobile && !withoutDropdown) {
        return (
            <MobileActionContainer>
                {withArrow && (
                    <ArrowIconHolder>
                        {renderTooltipWithKey(
                            <SVGInline
                                src={open ? icons.arrowUpIcon : icons.arrowDownIcon}
                                onClick={() => {
                                    onArrowToggle?.();
                                }}
                            />,
                            open ? tooltipTexts?.arrowClose || '' : tooltipTexts?.arrowOpen || ''
                        )}
                    </ArrowIconHolder>
                )}
                <MobileActionsMenuWrapper>
                    {encodeStatus && (
                        <EncodeStatusHolder
                            onClick={(evt) => {
                                evt.preventDefault();
                                evt.stopPropagation();
                                onEncode?.();
                            }}
                        >
                            {renderEncodeStatusIcon(encodeStatus)}
                        </EncodeStatusHolder>
                    )}
                    {publishedStatus && (
                        <TablePublishStatusHolder>
                            {renderPublishStatusIcon(publishedStatus, publishAt, tooltipTexts?.published)}
                        </TablePublishStatusHolder>
                    )}
                    <ActionIconHolder>
                        <SVGInline src={icons.moreIcon} />
                    </ActionIconHolder>

                    <MobileActionsDropdown>
                        {actions.map((action, index) => {
                            if ((action === tableActions.COPY && !canCopy) || (action === tableActions.DUPLICATE && !showDuplicateAnyway))
                                return null;
                            const tooltipText = actionTooltips[action];
                            const text = actionTexts[action];
                            const icon = actionIcons[action];

                            return (
                                <MobileActionOption key={`${index}_icon`} onClick={(evt) => handleIconClick(evt, action)}>
                                    {renderTooltipWithKey(<SVGInline data-cy={`table-action-${index}`} src={icon} />, tooltipText)}
                                    <MobileActionText>{text}</MobileActionText>
                                </MobileActionOption>
                            );
                        })}
                    </MobileActionsDropdown>
                </MobileActionsMenuWrapper>
            </MobileActionContainer>
        );
    }

    const validatedActions = actions
        .map((action, index) => {
            if ((action === tableActions.COPY && !canCopy) || (action === tableActions.DUPLICATE && !showDuplicateAnyway)) return null;
            const tooltipText = actionTooltips[action];
            const icon = actionIcons[action];

            const tooltipedActionIcon = renderTooltipWithKey(
                <SVGInline
                    data-cy={`table-action-${index}`}
                    key={`${index}_icon`}
                    src={icon}
                    onClick={(evt) => handleIconClick(evt, action)}
                />,
                tooltipText
            );
            return <ActionIconHolder key={index}>{tooltipedActionIcon}</ActionIconHolder>;
        })
        .filter((elem) => elem);

    if (publishedStatus)
        validatedActions.unshift(
            <TablePublishStatusHolder>
                {renderPublishStatusIcon(publishedStatus, publishAt, tooltipTexts?.published)}
            </TablePublishStatusHolder>
        );

    if (encodeStatus) {
        validatedActions.unshift(
            <EncodeStatusHolder
                onClick={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    onEncode?.();
                }}
            >
                {renderEncodeStatusIcon(encodeStatus)}
            </EncodeStatusHolder>
        );
    }

    return (
        <ActionContainer>
            {withArrow && (
                <ArrowIconHolder>
                    {renderTooltipWithKey(
                        <SVGInline
                            src={open ? icons.arrowUpIcon : icons.arrowDownIcon}
                            onClick={() => {
                                onArrowToggle?.();
                            }}
                        />,
                        open ? tooltipTexts?.arrowClose || '' : tooltipTexts?.arrowOpen || ''
                    )}
                </ArrowIconHolder>
            )}
            <ActionWrapper $elements={validatedActions.length}>
                {validatedActions.map((elem) => elem)}
                {!!validatedActions.length && (
                    <ActionIconHolder>
                        <SVGInline src={icons.moreIcon} />
                    </ActionIconHolder>
                )}
            </ActionWrapper>
        </ActionContainer>
    );
};
