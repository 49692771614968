import styled from 'styled-components';
import getTheme, { fontSizesCSS, zIndexesCSS } from '../../../style/themes/theme';
import { TruncatedText } from '../../../style/styled-components/reusable.css';
import { CheckboxContainer } from '../Dialog/GenericDialog.css';

const theme: any = getTheme();
export const HelpIconContainer = styled.div<{ inDialog?: boolean }>`
    position: relative;
    z-index: ${(props) => (props.inDialog ? 'inherit' : zIndexesCSS.zIndexProfile)};
    display: flex;
    align-self: center;
    svg {
        cursor: pointer;
    }
`;

export const OnboardingTipContainer = styled.div`
    position: absolute;
    width: 363px;
    height: 116px;
    background: ${theme.palette.background.onboardingTip};
    right: -24px;
    top: calc(100% + 20px);
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding: 24px;
    color: ${theme.palette.white.main};
`;
export const OnboardingTipArrow = styled.div`
    content: '';
    background: ${theme.palette.background.onboardingTip};
    width: 24px;
    height: 24px;
    right: 24px;
    border-radius: 4px;
    top: -10px;
    transform: rotate(-45deg);
    position: absolute;
    z-index: -1;
`;

export const OnboardingTipCloseIcon = styled.div`
    position: absolute;
    top: 8px;
    right: 18px;
    svg {
        padding: 0;
        margin: 0;
        width: 26px;
        height: 26px;
    }
    opacity: 0.7;
    &:hover {
        opacity: 1;
    }
`;

export const OnboardTipTitle = styled.div`
    ${fontSizesCSS.Caption2};
`;
export const OnboardTipSubtitle = styled.div`
    ${fontSizesCSS.Caption3};
`;
export const OnboardTipCheckbox = styled.div``;
