import { FC, useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../../common/Dialog/GenericDialog';
import { validator } from '../../../utils/fnValidator';
import { ConfigurableKeys } from './ConfigurableSelect';

export type ConfigurableValueDialogProps = {
    open: boolean;
    type: ConfigurableKeys;
    title: string;
    existingValue?: string | number;
    existingLabel?: string;
    onClose: () => void;
    onSave: (value: string | number, label?: string) => void;
};

export const ConfigurableValueDialog: FC<ConfigurableValueDialogProps> = ({
    type,
    title,
    open,

    existingValue,
    existingLabel,
    onClose,
    onSave
}) => {
    const [value, setValue] = useState<string>('');
    const [label, setLabel] = useState<string>('');
    const [newValueError, setNewValueError] = useState<string>('');

    const isNumber = type === ConfigurableKeys.DISPLAY_CONDITIONS_RATINGS;

    const validateNewValue = () => {
        const nameEror = validator({ required: true }, `${value}`);
        setNewValueError(nameEror);
        return !nameEror.length;
    };
    const handleSaveClick = () => {
        if (!validateNewValue()) return;

        onSave(isNumber ? parseInt(value) : value.trim(), label);
        handleCloseClick();
    };

    const handleCloseClick = () => {
        setValue('');
        setLabel('');
        setNewValueError('');
        onClose?.();
    };

    const addButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleSaveClick
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    useEffect(() => {
        if (!existingValue) return;
        setValue(`${existingValue}`);
        setLabel(existingLabel || '');
    }, [existingValue]);

    if (!open) return null;

    return (
        <GenericDialog
            type={DialogTypes.Form}
            title={existingValue ? `Edit ${title}` : `Create ${title}`}
            actionButtons={[cancelButton, addButton]}
            onClose={handleCloseClick}
        >
            <DialogTextField
                value={`${value}`}
                onChange={(e: any) => {
                    if (isNumber && !e.target.value.match(/^([0-9]+)?(\.)?([0-9])?$/)) return;
                    setValue(e.target.value);
                }}
                error={newValueError}
                placeholder={'Value'}
                label={'Value'}
            />
            <DialogTextField
                value={label}
                onChange={(e: any) => {
                    setLabel(e.target.value);
                }}
                placeholder={'Label'}
                label={'Label'}
                toolTipText="configurable_values_label"
                tooltipIsKey
                optional
            />
        </GenericDialog>
    );
};
