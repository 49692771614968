import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContentAsset } from '../../types/Asset';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import _ from 'lodash';

export const fetchAssets = createAsyncThunk<
    { assets: ContentAsset[]; totalCount: number | undefined; error: { message: string; code: string } | null; permissions?: any },
    any | undefined
>('assetManager/fetchAssets', async ({ tenantId, projectId, size, offset, searchTerm }, thunkApi) => {
    const result = await ConfigServiceAPI.getAssets(tenantId, projectId, offset, size, searchTerm);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return {
        assets: (result.response as any).assets,
        totalCount: (result.response as any).totalCount,
        error: null,
        permissions: result.permissions
    };
});

export const createAsset = createAsyncThunk<{ id: string; error: { message: string; code: string } | null }, ContentAsset>(
    'assetManager/createAsset',
    async (asset, thunkApi) => {
        const result = await ConfigServiceAPI.createAsset(asset);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any, error: null };
    }
);

export const updateAsset = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, ContentAsset>(
    'assetManager/updateAsset',
    async (asset, thunkApi) => {
        const result = await ConfigServiceAPI.updateAsset(asset);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: result.response as any, error: null };
    }
);

export const deleteAsset = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'assetManager/deleteAsset',
    async (id, thunkApi) => {
        const result = await ConfigServiceAPI.deleteAsset(id);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: result.response as any, error: null };
    }
);

export interface AssetManagerState {
    assets: ContentAsset[];
    loading: boolean;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
    totalResults?: number;
}

const initialState: AssetManagerState = {
    assets: [],
    loading: false,
    error: null
};

const slice = createSlice({
    name: 'assetManager',
    initialState,
    reducers: {
        unsetAssetManagerError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAssets.fulfilled, (state, action: any) => {
                state.assets = action.payload.assets;
                state.totalResults = action.payload.totalCount;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchAssets.rejected, (state, action: any) => {
                state.assets = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchAssets.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createAsset.fulfilled, (state, action: any) => {
                state.error = null;
                state.loading = false;
            })
            .addCase(createAsset.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(createAsset.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateAsset.fulfilled, (state, action: any) => {
                state.error = null;
                state.loading = false;
            })
            .addCase(updateAsset.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(updateAsset.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteAsset.fulfilled, (state, action: any) => {
                state.error = null;
                state.loading = false;
            })
            .addCase(deleteAsset.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteAsset.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetAssetManagerError } = slice.actions;

export default slice.reducer;
