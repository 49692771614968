import React, { FC } from 'react';
import { ApplicationWrapper, MainContentWrapper } from '../../style/styled-components/reusable.css';
import Sidebar from '../common/Sidebar/Sidebar';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import { ContentProviderCards } from '../Aggregate/Aggregate.css';
import { ContentProviderCard, ContentProviderTypes, serviceStates } from '../Aggregate/ContentProvider';
import { Capabilities, CapabilitiesScreen } from '../Capabilities/CapabilitiesTopScreen';
import useRestrictedProjectAccess from '../../hooks/useRestrictedAccess';
import { restrictedSectionsTypes } from '../../types/Project';
import { ProjectsState } from '../../redux/slices/projectsSlice';
import { useAppSelector } from '../../hooks/redux';

const supportedAds = [
    { key: 'google', status: serviceStates.DISABLED },
    { key: 'magnite', status: serviceStates.DISABLED },
    { key: 'forScreen', status: serviceStates.DISABLED },
    { key: 'amagi', status: serviceStates.DISABLED }
];

const Monetize: FC = () => {
    const { isRestrictedArea: isMonetizeRestricted } = useRestrictedProjectAccess(restrictedSectionsTypes.CAPABILITIES, 'monetize');
    const { projects }: ProjectsState = useAppSelector((state) => state.projects);

    return (
        <ApplicationWrapper>
            <Sidebar />
            <MainContentWrapper>
                <ScreenTitle loading={false} title={'Monetize'} withProfile />
                <CapabilitiesScreen type={Capabilities.MONETIZE} disabled={isMonetizeRestricted} />

                {!isMonetizeRestricted && !!projects?.length && (
                    <ContentProviderCards>
                        {supportedAds.map((ad) => {
                            return (
                                <ContentProviderCard
                                    key={ad.key}
                                    type={ContentProviderTypes.AD}
                                    providerState={ad.status}
                                    serviceKey={ad.key}
                                    onSettingsClick={() => {}}
                                    onPrimaryButtonClick={() => {}}
                                />
                            );
                        })}
                    </ContentProviderCards>
                )}
            </MainContentWrapper>
        </ApplicationWrapper>
    );
};

export default Monetize;
