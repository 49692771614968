export const TIMEFRAME_LABEL_MAPPING = {
    yesterday: 'Yesterday',
    last_7_days: 'Last 7 days',
    last_30_days: 'Last 30 days',
    custom: 'Custom',
    custom_placeholder: 'custom_placeholder'
};

export const COMPARED_TIMEFRAME_LABEL_MAPPING: any = {
    yesterday: 'Previous day',
    last_7_days: 'Previous 7 days',
    last_30_days: 'Previous 30 days'
};

export const TIMEFRAME_VALUES = {
    yesterday: 'yesterday',
    last_7_days: 'last_7_days',
    last_30_days: 'last_30_days',
    custom: 'custom',
    custom_placeholder: 'custom_placeholder'
} as const;

export const DEVICE_CATEGORIES = {
    all: 'all',
    tv: 'tv',
    mobile: 'mobile',
    car: 'car',
    web: 'web'
} as const;
export const deviceCategoryOptions = [
    {
        value: DEVICE_CATEGORIES.all,
        label: 'All Devices'
    },
    {
        value: DEVICE_CATEGORIES.tv,
        label: 'TV'
    },
    {
        value: DEVICE_CATEGORIES.mobile,
        label: 'Mobile'
    },
    {
        value: DEVICE_CATEGORIES.car,
        label: 'Car'
    },
    {
        value: DEVICE_CATEGORIES.web,
        label: 'Web'
    }
];
