import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DisplayCondition } from '../../types/DisplayCondition';
import { Page } from '../../types/Page';
import { Project } from '../../types/Project';
import { Setting } from '../../types/Setting';
import { User } from '../../types/User';
import { Menu } from '../../types/Menu';
import { Item } from '../../types/Item';
import { Module } from '../../types/Module';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';

export const fetchProjects = createAsyncThunk<
    { projects: Project[]; error: { message: string; code: string } | null; permissions?: any },
    { addPermissions?: boolean; orderBy?: string; searchTerm?: string }
>('projects/fetchProjects', async ({ addPermissions, orderBy, searchTerm }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllProjects(addPermissions, orderBy, searchTerm);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { projects: result.response as any, error: null, permissions: result.permissions };
});

export const fetchSecretPasscode = createAsyncThunk<
    {
        secretPasscode: string;
        error: { message: string; code: string } | null;
    },
    string
>('projects/fetchSecretPasscode', async (projectId, thunkApi) => {
    const result = await ConfigServiceAPI.getSecretPasscode(projectId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { secretPasscode: (result.response as any as { secretPasscode: string }).secretPasscode, error: null };
});

export const fetchDashbard = createAsyncThunk<{ dashboardData: dashboardData; error: { message: string; code: string } | null }, string>(
    'projects/fetchDashboard',
    async (projectId, thunkApi) => {
        const result = await ConfigServiceAPI.getProjectDashboard(projectId);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { dashboardData: result.response as any, error: null };
    }
);
export const fetchDisabledProjects = createAsyncThunk<
    { disabledProjects: Project[]; error: { message: string; code: string } | null },
    { orderBy?: string; searchTerm?: string }
>('projects/fetchDisabledProjects', async ({ orderBy, searchTerm }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllDisabledProjects(orderBy, searchTerm);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { disabledProjects: result.response as any, error: null, permissions: result.permissions };
});

export const createProject = createAsyncThunk<{ id: string; error: { message: string; code: string } | null }, Project>(
    'projects/createProject',
    async (project: Project, thunkApi) => {
        const result = await ConfigServiceAPI.createProject(project);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any, error: null };
    }
);

export const copyProject = createAsyncThunk<
    { id: string; error: { message: string; code: string } | null },
    { projectId: string; tenantId: string; projectKey: string }
>('projects/copyProject', async ({ projectId, tenantId, projectKey }, thunkApi) => {
    const result = await ConfigServiceAPI.copyProject(projectId, tenantId, projectKey);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { id: result.response as any, error: null };
});

export const duplicateProject = createAsyncThunk<
    { id: string; error: { message: string; code: string } | null },
    {
        projectId: string;
        tenantId: string;
        projectKey: string;
        projectName: string;
    }
>('projects/duplicateProject', async ({ projectId, tenantId, projectKey, projectName }, thunkApi) => {
    const result = await ConfigServiceAPI.duplicateProject(projectId, tenantId, projectKey, projectName);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { id: result.response as any, error: null };
});

export const updateProject = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, Project>(
    'projects/updateProject',
    async (project: Project, thunkApi) => {
        const result = await ConfigServiceAPI.updateProject(project);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response as any, error: null };
    }
);

export const disableProject = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'projects/disableProject',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.disableProject(id);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export const enableProject = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'projects/enableProject',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.enableProject(id);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export const deleteProject = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'projects/deleteProject',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.deleteProject(id);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export interface ProjectsState {
    projects: Project[];
    disabledProjects: Project[];
    secretPasscode?: string;
    loading: boolean;
    loadingDashboard: boolean;
    dashboardData: dashboardData;
    secretPasscodeLoading?: boolean;
    error: {
        message: string;
        code?: string;
        status?: number;
    } | null;
}

type dashboardData = {
    pages?: Page[];
    modules?: Module[];
    items?: Item[];
    menus?: Menu[];
    settings?: Setting[];
    displayConditions?: DisplayCondition[];
    users?: User[];
};

const initialState: ProjectsState = {
    projects: [],
    dashboardData: {},
    disabledProjects: [],
    error: null,
    loading: false,
    loadingDashboard: false
};

const slice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        unsetProjectError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.projects = action.payload.projects;
                state.loading = false;
                state.error = null;
            })
            .addCase(fetchProjects.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchProjects.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.projects = [];
                state.loading = false;
            })
            .addCase(fetchDashbard.fulfilled, (state, action) => {
                state.loadingDashboard = false;
                state.dashboardData = action.payload.dashboardData;
                state.error = null;
            })
            .addCase(fetchDashbard.pending, (state, _action) => {
                state.loadingDashboard = true;
            })
            .addCase(fetchDashbard.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.dashboardData = {};
                state.loadingDashboard = false;
            })
            .addCase(fetchSecretPasscode.fulfilled, (state, action) => {
                state.secretPasscode = action.payload.secretPasscode;
                state.secretPasscodeLoading = false;
                state.error = null;
            })
            .addCase(fetchSecretPasscode.pending, (state, _action) => {
                state.secretPasscodeLoading = true;
            })
            .addCase(fetchSecretPasscode.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.projects = [];
                state.secretPasscodeLoading = false;
            })
            .addCase(fetchDisabledProjects.fulfilled, (state, action) => {
                state.disabledProjects = action.payload.disabledProjects;
                state.loading = false;
                state.error = null;
            })
            .addCase(fetchDisabledProjects.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchDisabledProjects.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.disabledProjects = [];
                state.loading = false;
            })
            .addCase(createProject.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createProject.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createProject.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(copyProject.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(copyProject.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(copyProject.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(duplicateProject.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(duplicateProject.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(duplicateProject.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(updateProject.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateProject.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateProject.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(disableProject.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(disableProject.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(disableProject.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(deleteProject.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteProject.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteProject.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            });
    }
});

export const { unsetProjectError } = slice.actions;
export default slice.reducer;
