import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Permission } from '../../types/Permission';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';

export type UserPermissions = {
    isSuperAdmin: boolean;
    isViewer: boolean;
    isEditor: boolean;
    tenantIds: string[];
    projectIds: string[];
    userGroupIds: string[];
    tenantAdminIn: string[];
    projectLeadIn: string[];
    projectLeadInTenants: string[];
    overrideRole?: {
        projectId: string;
        roleName: string;
    }[];
    restrictedSections?: {
        projectId: string;
        sections: string[];
    }[];
};
export const calculateIsTenantAdmin = (tenantId: string, userPermissions?: UserPermissions): boolean =>
    !!userPermissions && userPermissions.tenantAdminIn.includes(tenantId);
export const calculateIsProjectLead = (projectId: string, userPermissions?: UserPermissions): boolean =>
    !!userPermissions && userPermissions.projectLeadIn.includes(projectId);

export const fetchPermissions = createAsyncThunk<{ permissions: Permission[]; error: { message: string; code: string } | null }>(
    'permissions/fetchAllPermissions',
    async (_, thunkApi) => {
        const result = await ConfigServiceAPI.getAllPermissions();

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { permissions: result.response as any, error: null };
    }
);

export interface PermissionsState {
    permissions: Permission[];
    userPermissions?: UserPermissions;
    loading: boolean;
    error: {
        message: string;
        code?: string;
        status?: number;
    } | null;
}

const initialState: PermissionsState = {
    permissions: [],
    error: null,
    loading: false
};

const slice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        setUserPermissions(state, action: PayloadAction<any>) {
            state.userPermissions = action.payload;
        },
        unsetPermissionError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPermissions.fulfilled, (state, action: any) => {
                state.permissions = action.payload.permissions;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchPermissions.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.permissions = [];
                state.loading = false;
            })
            .addCase(fetchPermissions.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { setUserPermissions, unsetPermissionError } = slice.actions;
export default slice.reducer;
