import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Module } from '../../types/Module';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { FailedDeletionMessages } from '../../types/Object';
import { ObjectFilter } from '../../utils/fnFilter';

export const fetchModules = createAsyncThunk<
    { modules: Module[]; error: { message: string; code: string } | null; permissions?: any },
    {
        addPermissions?: boolean;
        projectId?: string;
        level?: number;
        pageSize?: number;
        pageNumber?: number;
        orderBy?: string;
        searchTerm?: string;
        filter?: ObjectFilter;
    }
>('modules/fetchModules', async ({ addPermissions, projectId, level, pageSize, pageNumber, orderBy, searchTerm, filter }, thunkApi) => {
    const result = await ConfigServiceAPI.getModules(
        addPermissions,
        projectId,
        typeof level === 'number' ? level : 3,
        pageSize,
        pageNumber,
        orderBy,
        searchTerm,
        filter
    );
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { modules: result.response as any, error: null, permissions: result.permissions, totalResults: result.totalResults };
});

export const fetchAllModules = createAsyncThunk<
    { modules: Module[]; error: { message: string; code: string } | null },
    {
        projectId?: string;
    }
>('modules/fetchAllModules', async ({ projectId }, thunkApi) => {
    const result = await ConfigServiceAPI.getModules(undefined, projectId, 3);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { modules: result.response as any, error: null };
});

export const fetchModulesTemplates = createAsyncThunk<
    { templates: any[]; error: { message: string; code: string } | null },
    string | undefined
>('modules/fetchTemplates', async (projectId, thunkApi) => {
    const result = await ConfigServiceAPI.getModulesTemplates(projectId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { templates: result.response as any, error: null };
});

export const createModule = createAsyncThunk<
    { id: string; shouldShowSlugWarning: boolean; shouldShowEmptySlugMessage: boolean; error: { message: string; code: string } | null },
    Module
>('modules/createModule', async (module: Module, thunkApi) => {
    const result = await ConfigServiceAPI.createModule(module);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return {
        id: (result.response as any)._id,
        shouldShowSlugWarning: (result.response as any).shouldShowSlugWarning,
        shouldShowEmptySlugMessage: (result.response as any).shouldShowEmptySlugMessage,
        error: null
    };
});

export const updateModule = createAsyncThunk<
    { ok: boolean; shouldShowSlugWarning: boolean; shouldShowEmptySlugMessage: boolean; error: { message: string; code: string } | null },
    { module: Module; shouldUnlockAfterSave?: boolean }
>('modules/updateModule', async ({ module, shouldUnlockAfterSave }, thunkApi) => {
    const result = await ConfigServiceAPI.updateModule(module, shouldUnlockAfterSave);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return {
        ok: !!(result.response as any)._id,
        shouldShowSlugWarning: (result.response as any).shouldShowSlugWarning,
        shouldShowEmptySlugMessage: (result.response as any).shouldShowEmptySlugMessage,
        error: null
    };
});

export const deleteModule = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null; failedDeletions?: FailedDeletionMessages },
    { id: string; withItems?: boolean }
>('modules/deleteModule', async ({ id, withItems }, thunkApi) => {
    const result = await ConfigServiceAPI.deleteModule(id, withItems);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return {
        ok: !!result.response,
        error: null,
        failedDeletions: typeof result.response !== 'string' ? (result.response as unknown as FailedDeletionMessages) : undefined
    };
});

export const deleteModuleList = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null; failedDeletions: FailedDeletionMessages },
    { modules: string[]; withItems?: boolean }
>('pages/deleteModuleList', async ({ modules, withItems }, thunkApi) => {
    const result = await ConfigServiceAPI.deleteModuleList(modules, withItems);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }

    return { ok: !!result.response, error: null, failedDeletions: result.response as unknown as FailedDeletionMessages };
});

export interface ModulesState {
    modules: Module[]; // with the addition of pagination, the modules variable will hold the modules of the current loaded page
    allModules: Module[]; // this is needed for when we need to load all the modules (e.g. linking to a module in the ActionDialog). This aproach should be implemented for all the objects we have pagination for
    validTemplates?: any[];
    loadingValidTemplates: boolean;
    loading: boolean;
    loadingAllModules: boolean;
    createOrModifyLoading?: boolean;
    totalResults?: number;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: ModulesState = {
    modules: [],
    allModules: [],
    loading: false,
    loadingAllModules: false,
    loadingValidTemplates: false,
    error: null
};

const slice = createSlice({
    name: 'modules',
    initialState,
    reducers: {
        unsetModuleError(state) {
            state.error = null;
        },
        unsetModules(state) {
            state.modules = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchModules.fulfilled, (state, action: any) => {
                state.modules = action.payload.modules;
                state.error = null;
                state.loading = false;
                state.totalResults = action.payload.totalResults;
            })
            .addCase(fetchModules.rejected, (state, action: any) => {
                state.modules = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchModules.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchAllModules.fulfilled, (state, action: any) => {
                state.allModules = action.payload.modules;
                state.error = null;
                state.loadingAllModules = false;
            })
            .addCase(fetchAllModules.rejected, (state, action: any) => {
                state.allModules = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loadingAllModules = false;
            })
            .addCase(fetchAllModules.pending, (state, _action) => {
                state.loadingAllModules = true;
            })
            .addCase(fetchModulesTemplates.fulfilled, (state, action: any) => {
                state.validTemplates = action.payload.templates;
                state.error = null;
                state.loadingValidTemplates = false;
            })
            .addCase(fetchModulesTemplates.rejected, (state, action: any) => {
                state.validTemplates = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loadingValidTemplates = false;
            })
            .addCase(fetchModulesTemplates.pending, (state, _action) => {
                state.loadingValidTemplates = true;
            })
            .addCase(createModule.fulfilled, (state, _action) => {
                state.createOrModifyLoading = false;
            })
            .addCase(createModule.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.createOrModifyLoading = false;
            })
            .addCase(createModule.pending, (state, _action) => {
                state.createOrModifyLoading = true;
            })
            .addCase(updateModule.fulfilled, (state, _action) => {
                state.createOrModifyLoading = false;
            })
            .addCase(updateModule.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.createOrModifyLoading = false;
            })
            .addCase(updateModule.pending, (state, _action) => {
                state.createOrModifyLoading = true;
            })
            .addCase(deleteModule.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteModule.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteModule.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteModuleList.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteModuleList.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteModuleList.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export default slice.reducer;
export const { unsetModuleError, unsetModules } = slice.actions;
