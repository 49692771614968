import styled from 'styled-components';
import SVGInline from 'react-inlinesvg';

export const ShowMoreIconContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const ShowMoreIconWrapper = styled(SVGInline)<{ $withTransparency?: boolean }>`
    opacity: ${(props) => (props.$withTransparency ? '0.7' : '1')};
    cursor: pointer;
    min-width: 47px;
    height: 20px;
`;
