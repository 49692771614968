import { emailRegex, passwordRegex } from './Globals';

export enum validatorKeys {
    REQUIRED = 'required',
    MINLENGTH = 'minLength',
    MAXLENGTH = 'maxLength',
    EMAIL = 'email',
    PASSWORD = 'password',
    MESSAGE = 'customMessage'
}

export const validatorMessages = {
    [validatorKeys.REQUIRED]: 'Please enter a value for this field!',
    [validatorKeys.EMAIL]: 'Please enter a valid email',
    [validatorKeys.PASSWORD]: 'Please enter a valid password',
    [validatorKeys.MINLENGTH]: 'Please enter for this field a value of at least',
    [validatorKeys.MAXLENGTH]: 'Please enter for this field a value of maximum'
};

export type validators = {
    [key in validatorKeys]?: any;
};

export const nameValidator = (value: string, length: number) => {
    return value.length >= length;
};

export const emailValidator = (value: string) => {
    return emailRegex.test(value);
};

export const passwordValidator = (value: string) => {
    return passwordRegex.test(value);
};

export const maxLength = (value: string, length: number) => {
    return value.length < length;
};

export const minLength = (value: string | string[], length: number) => {
    return value.length >= length;
};

const validateSwitch = (key: string, expectedValue: any, value: string | string[]) => {
    if (Array.isArray(value)) {
        switch (key) {
            case validatorKeys.REQUIRED:
                return value.length === 0 ? validatorMessages[key] : '';
            case validatorKeys.MINLENGTH:
                return !minLength(value, expectedValue) ? `${validatorMessages[key]} ${expectedValue} elements ` : '';
            default:
                return '';
        }
    }
    switch (key) {
        case validatorKeys.REQUIRED:
            return !value ? validatorMessages[key] : '';
        case validatorKeys.MINLENGTH:
            return !minLength(value, expectedValue) ? `${validatorMessages[key]} ${expectedValue} characters ` : '';
        case validatorKeys.MAXLENGTH:
            return !maxLength(value, expectedValue) ? `${validatorMessages[key]} ${expectedValue} characters ` : '';
        case validatorKeys.PASSWORD:
            return !passwordValidator(value) ? validatorMessages[key] : '';
        case validatorKeys.EMAIL:
            return !emailValidator(value) ? validatorMessages[key] : '';
        default:
            return '';
    }
};

export const validator = (validators: validators, value: string | string[]) => {
    if (!Object.keys(validators).length) return '';
    const { customMessage, ...newValidators } = validators;

    let error;
    const entries = Object.entries(newValidators);

    for (let i = 0; i < entries.length; i++) {
        const [key, expectedValue] = entries[i];
        error = validateSwitch(key, expectedValue, value);
        if (error) {
            break;
        }
    }

    return error ? customMessage || error : '';
};

export const isValidUrl = (url: string) => {
    var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return res !== null;
};

export const validTranslationKeyRegex = /^[a-zA-Z0-9_&\- ]*$/;

export const validateTranslations = (translation: any) => {
    if (!Array.isArray(translation)) {
        return {
            error: true,
            warning: false
        };
    }
    if (translation.length < 1) {
        return {
            error: true,
            warning: false
        };
    }
    let error = false;
    let warning = false;
    translation.forEach((obj: any) => {
        let { key, value } = obj;

        if (typeof value !== 'string' && typeof value !== 'object') {
            error = true;
        }
        if (typeof key === 'string' && !validTranslationKeyRegex.test(key)) {
            warning = true;
        }
        if (typeof value === 'object') {
            if (value === null) {
                error = true;
            }
            if (Object.keys(value).length < 1) {
                error = true;
            }
            Object.values(value).forEach((value) => {
                if (typeof value !== 'string') {
                    error = true;
                }
            });
        }
    });
    return {
        error,
        warning
    };
};
