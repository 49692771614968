import icons from '../assets/images/icons';
import { renderTooltip, tooltipPositions, tooltipTypes } from '../components/common/Tooltips/Tooltips';
import SVGInline from 'react-inlinesvg';
import styled from 'styled-components';
import { FailedDeletionMessages } from '../types/Object';
import { EMPTY_WORD_STRING } from './Globals';
import { dialogAlert } from './fnDialogs';

export const renderDeactivatedIcon = () => {
    const toolTipText = `This object is deactivated and will not be visible in the app until reactivated`;
    return renderTooltip(
        <SVGInline key={`deactivated`} width={20} src={icons.deactivateObjectIcon} />,
        tooltipTypes.TEXT,
        toolTipText || '',
        tooltipPositions.TOP
    );
};

const FailedDeletionEntriesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
export const renderFailedObjectStatusChange = (failedActions: FailedDeletionMessages, changeToDeactivated: boolean = true) => {
    const showIndex = failedActions.length > 1;
    const values = {
        title: `Some of the objects were not ${changeToDeactivated ? 'deactivated' : 'reactivated'}`,
        text: (
            <FailedDeletionEntriesWrapper>
                {failedActions.map((entry, index) => (
                    <div key={JSON.stringify(entry)}>
                        {showIndex && `${index + 1}.`} The <strong>{entry.objectType.slice(0, -1)}</strong> with the name{' '}
                        <strong>{entry.objectName || EMPTY_WORD_STRING}</strong> was not{' '}
                        {changeToDeactivated ? 'deactivated' : 'reactivated'} because {entry.message}
                    </div>
                ))}
            </FailedDeletionEntriesWrapper>
        )
    };

    dialogAlert('', false, values, null, false, icons.warningYellowIcon, null, true);
};
