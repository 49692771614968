import React, { useEffect, useState } from 'react';
import config from '../../../config/cc-config.json';
import icons from '../../../style';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { useNavigate } from 'react-router-dom';
import {
    ProfileAvatar,
    ProfileAvatarIcon,
    ProfileAvatarWrapper,
    ProfileComponent,
    ProfileDetails,
    ProfileEmail,
    ProfileHeader,
    ProfileHeaderComponent,
    ProfileMenuItems,
    ProfileName,
    ProfileRoleName
} from './Profile.css';
import { DropdownItemWithSvg, DropdownMenu } from '../DropdownMenu/DropdownMenu';
import { LoadingWrapper, ProfileSVGInline } from '../../../style/styled-components/reusable.css';
import MyProfileDialog from './Dialogs/MyProfile';
import ProjectLayoutDialog from './Dialogs/ProjectLayout';
import { logUserOut } from '../../../redux/slices/authSlice';
import { dialogAlert } from '../../../utils/fnDialogs';
import { calculateIsTenantAdmin, PermissionsState } from '../../../redux/slices/permissionsSlice';
import { fetchUserProfile, UserProfileState } from '../../../redux/slices/profileSlice';
import BackendErrorDialog from '../Dialog/BackendErrorDialog';
import { User } from '../../../types/User';
import { updateUser } from '../../../redux/slices/usersSlice';
import { USERROLES } from '../../../utils/Globals';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { ChangePasswordValues } from './Dialogs/ChangePasswordFields';
import configServiceAPI from '../../../utils/api/configServiceAPI';
import { renderTooltipWithKey } from '../Tooltips/Tooltips';
import { fetchRoles, RolesState } from '../../../redux/slices/rolesSlice';
import { PageRoutes } from '../../../types/RouteTypes';

const profileMenu = config.profile_menu;

const Profile: React.FC = (...rest) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [openMyProfile, setOpenMyProfile] = useState(false);
    const [isProjectLayoutOpen, setIsProjectLayoutOpen] = useState(false);
    const [isSuperAdminOrTenantAdmin, setIsSuperAdminOrTenantAdmin] = useState(false);
    const [shownRole, setShownRole] = useState('');

    const { userPermissions }: PermissionsState = useAppSelector((state) => state.permissions);
    const { userProfile, error: profileError }: UserProfileState = useAppSelector((state) => state.profile);
    const { activeTenantId, activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { roles }: RolesState = useAppSelector((state) => state.roles);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loadUserInfo = async () => {
        await dispatch(fetchUserProfile()).unwrap();
    };

    const loadRoles = async () => {
        await dispatch(fetchRoles()).unwrap();
    };

    useEffect(() => {
        if (isOpen) {
            loadRoles();
            loadUserInfo();
        }
    }, [isOpen]);

    useEffect(() => {
        if (!userProfile) {
            loadUserInfo();
        }
    }, []);

    useEffect(() => {
        setShownRole(calculateUserRole());
    }, [activeProjectId, activeTenantId, userProfile, roles]);

    useEffect(() => {
        if (userPermissions) {
            const isSuperAdminOrTenantAdmin = () => {
                return userPermissions.isSuperAdmin || (!!activeTenantId && calculateIsTenantAdmin(activeTenantId, userPermissions));
            };
            setIsSuperAdminOrTenantAdmin(isSuperAdminOrTenantAdmin());
        }
    }, [userPermissions, activeTenantId]);

    const onMyProfileClicked = () => {
        setIsOpen(false);
        setOpenMyProfile(true);
    };

    const onProjectLayoutClicked = () => {
        setIsOpen(false);
        setIsProjectLayoutOpen(true);
    };

    const onSuperadminUIClicked = () => {
        navigate(PageRoutes.SUPERADMIN_GROUPS);
    };

    const onProjectsClicked = () => {
        navigate(PageRoutes.PROJECTS);
    };
    const onTenantsClicked = () => {
        navigate(PageRoutes.TENANTS);
    };

    const onManageUsersClicked = () => {
        navigate(PageRoutes.USERS_GROUPS);
    };

    const modifyUser = async (user: User) => {
        await dispatch(updateUser(user)).unwrap();
        loadUserInfo();
    };

    const changePassword = async (values: ChangePasswordValues) => {
        const result = await configServiceAPI.changeUserPassword(values.oldPassword, values.newPassword);
        return result.error || true;
    };

    const onLogoutClicked = async () => {
        try {
            await dispatch(logUserOut()).unwrap();
            navigate(PageRoutes.LOGIN);
        } catch (err: any) {
            if (!err) return;
            const values = {
                title: 'Could not logout!',
                subtitle: 'An error occured while trying to logout.',
                text: err.message
            };
            dialogAlert('', false, values);
        }
    };

    const renderProfileMenu = () => {
        let items: JSX.Element[] = [];

        profileMenu.length &&
            profileMenu.forEach((item, index) => {
                switch (item.key) {
                    case 'my_profile':
                        items.push(
                            <DropdownItemWithSvg svg={icons.iconProfile} onClick={onMyProfileClicked} key={`profile_item_${index}`}>
                                <span className="user-title">{item.title}</span>
                                <span className="user-role">Admin</span>
                            </DropdownItemWithSvg>
                        );
                        break;
                    case 'superadmin_ui':
                        if (!userPermissions?.isSuperAdmin) return;
                        items.push(
                            <DropdownItemWithSvg svg={icons.superadminUIIcon} onClick={onSuperadminUIClicked} key={`profile_item_${index}`}>
                                {item.title}
                            </DropdownItemWithSvg>
                        );
                        break;
                    case 'project_layout':
                        // Hidden until functionality is defined
                        break;
                        if (!isSuperAdminOrTenantAdmin) return;
                        items.push(
                            <DropdownItemWithSvg
                                svg={icons.recommendationIcon}
                                onClick={onProjectLayoutClicked}
                                key={`profile_item_${index}`}
                            >
                                {item.title}
                            </DropdownItemWithSvg>
                        );
                        break;
                    case 'projects':
                        items.push(
                            <DropdownItemWithSvg svg={icons.projectsIcon} onClick={onProjectsClicked} key={`profile_item_${index}`}>
                                {item.title}
                            </DropdownItemWithSvg>
                        );
                        break;
                    case 'tenants':
                        if (!userPermissions?.isSuperAdmin) break;
                        items.push(
                            <DropdownItemWithSvg svg={icons.tenantsIcon} onClick={() => onTenantsClicked()} key={`profile_item_${index}`}>
                                {item.title}
                            </DropdownItemWithSvg>
                        );
                        break;
                    case 'manage_users':
                        if (!isSuperAdminOrTenantAdmin) break;
                        items.push(
                            <DropdownItemWithSvg
                                svg={icons.manageAccountsIcon}
                                onClick={() => onManageUsersClicked()}
                                key={`profile_item_${index}`}
                            >
                                {item.title}
                            </DropdownItemWithSvg>
                        );
                        break;
                    case 'logout':
                        items.push(
                            <LoadingWrapper>
                                <DropdownItemWithSvg
                                    svg={icons.logout}
                                    onClick={onLogoutClicked}
                                    key={`profile_item_${index}`}
                                    dataCy={'logout-button'}
                                >
                                    {item.title}
                                </DropdownItemWithSvg>
                            </LoadingWrapper>
                        );
                        break;
                    default:
                        break;
                }
            });
        return items;
    };

    const handleIsOpen = (val: any) => {
        setIsOpen(val);
    };

    const calculateUserRole = () => {
        const profileRole: string = roles.find((role) => role._id === userProfile?.roleId)?.name || '';

        switch (profileRole) {
            case USERROLES.SUPER_ADMIN:
                return 'super admin';
            case USERROLES.EDITOR:
            case USERROLES.VIEWER:
                if (!activeProjectId || !activeTenantId)
                    return userProfile?.tenantAdminIn?.length
                        ? 'tenant admin'
                        : userProfile?.projectLeadIn?.length
                        ? 'project lead'
                        : profileRole;
                if (userProfile?.tenantAdminIn?.includes(activeTenantId)) return 'tenant admin';
                if (userProfile?.projectLeadIn?.includes(activeProjectId)) return 'project lead';
                break;
            default:
                break;
        }
        return profileRole;
    };

    if (profileError) {
        return <BackendErrorDialog error={profileError} />;
    }

    return (
        <>
            <DropdownMenu
                {...rest}
                triggerButton={
                    <ProfileAvatarIcon onClick={handleIsOpen}>
                        {renderTooltipWithKey(
                            <img src={userProfile?.icon || icons.avatarIcon} alt="profile_image" />,
                            'profile_icon_image'
                        )}

                        <ProfileSVGInline src={isOpen ? icons.arrowUpIcon : icons.arrowDownIcon} data-cy={'profile-dropdown-icon'} />
                    </ProfileAvatarIcon>
                }
                horizontalPosition={'right'}
                verticalPosition={'top'}
                handleIsOpen={(val) => handleIsOpen(val)}
            >
                <ProfileComponent>
                    <ProfileHeaderComponent>
                        <ProfileHeader>
                            <ProfileAvatarWrapper>
                                <ProfileAvatar $icon={userProfile?.icon || icons.avatarIcon} />
                            </ProfileAvatarWrapper>
                            <ProfileDetails>
                                <ProfileName>{userProfile?.fullName}</ProfileName>
                                <ProfileEmail>{userProfile?.email}</ProfileEmail>
                                <ProfileRoleName>{shownRole}</ProfileRoleName>
                            </ProfileDetails>
                        </ProfileHeader>
                    </ProfileHeaderComponent>
                    <ProfileMenuItems>{renderProfileMenu()}</ProfileMenuItems>
                </ProfileComponent>
            </DropdownMenu>
            <MyProfileDialog
                open={openMyProfile}
                onClose={() => {
                    setOpenMyProfile(false);
                }}
                errorMsg=""
                onSave={(user: User) => modifyUser(user)}
                onChangePassword={(values: ChangePasswordValues) => changePassword(values)}
                profile={userProfile}
                role={shownRole}
            />
            <ProjectLayoutDialog open={isProjectLayoutOpen} onClose={() => setIsProjectLayoutOpen(false)} onSave={null} />
        </>
    );
};

export default Profile;
