import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { logUserOut } from '../../../redux/slices/authSlice';
import { unsetGroupError } from '../../../redux/slices/groupsSlice';
import { unsetLanguageError } from '../../../redux/slices/languagesSlice';
import { unsetPageError } from '../../../redux/slices/pagesSlice';
import { PermissionsState, unsetPermissionError } from '../../../redux/slices/permissionsSlice';
import { unsetProfileError } from '../../../redux/slices/profileSlice';
import { unsetProjectError } from '../../../redux/slices/projectsSlice';
import { unsetRoleError } from '../../../redux/slices/rolesSlice';
import { unsetTenantError } from '../../../redux/slices/tenantsSlice';
import { unsetUserError } from '../../../redux/slices/usersSlice';
import { unsetActiveProject, unsetActiveTenant } from '../../../redux/slices/activeItemSlice';
import { DIALOG_NAMES, DIALOG_TITLES_MAP } from '../../../utils/fnDialogs';
import { API_ERROR_CODES, API_ERROR_MESSAGES } from '../../../utils/Globals';
import GenericDialog, { DialogButton, DialogTypes } from './GenericDialog';
import { unsetMenuError } from '../../../redux/slices/menusSlice';
import { unsetModuleError } from '../../../redux/slices/moduleSlice';
import { unsetItemError } from '../../../redux/slices/itemSlice';
import { unsetAudienceError } from '../../../redux/slices/audienceSlice';
import { unsetTemplateError } from '../../../redux/slices/templatesSlice';
import { unsetFilesError } from '../../../redux/slices/fileManagerSlice';
import { PageRoutes } from '../../../types/RouteTypes';
import { unsetTargetGroupError } from '../../../redux/slices/targetGroupsSlice';
import { unsetDisplayConditionError } from '../../../redux/slices/displayConditionsSlice';
import { unsetPageStylesError } from '../../../redux/slices/pageStylesSlice';
import { unsetSettingsError } from '../../../redux/slices/settingsSlice';
import { unsetSourceError } from '../../../redux/slices/sourceSlice';
import { unsetUnpublishedChangesError } from '../../../redux/slices/unpublishedChangesSlice';
import { unsetCopyObjectError } from '../../../redux/slices/copyObjectSlice';
import icons from '../../../style';
import { unsetAnalyticsError } from '../../../redux/slices/analyticsSlice';
import { unsetApplicationsError } from '../../../redux/slices/applicationsSlice';
import { unsetAdminLockError } from '../../../redux/slices/adminLockSlice';
import { unsetConfigurableValuesError } from '../../../redux/slices/configurableValuesSlice';
import { unsetAbTestingGroupError } from '../../../redux/slices/abTestingGroupSlice';
import { unsetFeatureFlagsError } from '../../../redux/slices/featureFlagSlice';
import { unsetDeactivateObjectError } from '../../../redux/slices/deactivateObjectSlice';
import { unsetAssetManagerError } from '../../../redux/slices/assetManagerSlice';

type BackendErrorDialogProps = {
    error: any;
    customValues?: { title?: string | JSX.Element; subtitle?: string | JSX.Element; text?: string | JSX.Element };
    customCallback?: any;
};

const BackendErrorDialog: React.FC<BackendErrorDialogProps> = ({ error, customValues, customCallback }) => {
    const [values, setValues] = useState<any>(customValues);
    const { userPermissions }: PermissionsState = useAppSelector((state) => state.permissions);
    const { loggedIn } = useAppSelector((state) => state.auth);

    const timeoutRef = useRef<NodeJS.Timeout>();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // log the user out if needed and clear the errors to avoiding the unauth dialog pop-up several times
    const dispatchActions = async (logout = false) => {
        if (logout) {
            await dispatch(logUserOut()).unwrap();
        }
        dispatch(unsetUserError());
        dispatch(unsetTenantError());
        dispatch(unsetProjectError());
        dispatch(unsetGroupError());
        dispatch(unsetRoleError());
        dispatch(unsetPermissionError());
        dispatch(unsetProfileError());
        dispatch(unsetPageError());
        dispatch(unsetMenuError());
        dispatch(unsetLanguageError());
        dispatch(unsetItemError());
        dispatch(unsetModuleError());
        dispatch(unsetAudienceError());
        dispatch(unsetAbTestingGroupError());
        dispatch(unsetTemplateError());
        dispatch(unsetFilesError());
        dispatch(unsetTargetGroupError());
        dispatch(unsetDisplayConditionError());
        dispatch(unsetPageStylesError());
        dispatch(unsetSettingsError());
        dispatch(unsetSourceError());
        dispatch(unsetUnpublishedChangesError());
        dispatch(unsetCopyObjectError());
        dispatch(unsetAnalyticsError());
        dispatch(unsetApplicationsError());
        dispatch(unsetAdminLockError());
        dispatch(unsetConfigurableValuesError());
        dispatch(unsetFeatureFlagsError());
        dispatch(unsetDeactivateObjectError());
        dispatch(unsetAssetManagerError());
    };

    useEffect(() => {
        // When a user logs out the userPermissions are reset to undefined so we need to assure that the dialog is not invoked
        if (!loggedIn && error.code !== API_ERROR_CODES.AUTHENTICATION_ERROR) return;
        // set the dialog texts based on the error status, code & permissions existance

        let values: any = customValues || {
            title: 'Oops.. An error occurred!',
            subtitle: 'An error occurred while communicating with the backend.',
            text: error?.message
        };

        if (error?.status === 401) {
            const disabledError = error.code === API_ERROR_CODES.DISABLED_ERROR;
            if (!disabledError && !customValues) {
                values =
                    (!userPermissions
                        ? DIALOG_TITLES_MAP.get(DIALOG_NAMES.PERMISSIONS_NOT_LOADED)
                        : DIALOG_TITLES_MAP.get(DIALOG_NAMES.UNAUTHORIZED)) || values;
            }

            const timeout = setTimeout(() => {
                dispatchActions(!userPermissions).then(() => {
                    if (!userPermissions) {
                        navigate(PageRoutes.LOGIN);
                    } else if (disabledError) {
                        dispatch(unsetActiveProject());
                        dispatch(unsetActiveTenant());
                        navigate(PageRoutes.PROJECTS);
                    }
                });
            }, 30000); //30 sec
            timeoutRef.current = timeout;
        } else if (error?.status === 400) {
            const resourceNotFound = error.code === API_ERROR_CODES.DATABASE_ERROR;
            const alreadyModified = error.code === API_ERROR_CODES.ALREADY_MODIFIED;
            if (resourceNotFound) {
                values = DIALOG_TITLES_MAP.get(DIALOG_NAMES.RESOURCE_NOT_FOUND) || values;
            }
            if (alreadyModified) {
                values = DIALOG_TITLES_MAP.get(DIALOG_NAMES.ALREADY_MODIFIED) || values;
            }
            const timeout = setTimeout(() => {
                dispatchActions().then(() => {
                    resourceNotFound && navigate(-1);
                });
            }, 30000);
            timeoutRef.current = timeout;
        }

        setValues(values);

        return () => {
            timeoutRef.current && clearTimeout(timeoutRef.current);
        };
    }, []);

    useEffect(() => {
        if (!values) return;
        // when the error message is for when an inexistant resource is referenced somewhere, append the refresh text
        let text = values.text;
        if (!text || typeof text !== 'string') return;

        if (text.includes(API_ERROR_MESSAGES.RESOURCE_NOT_FOUND_FOR_ID)) {
            const stringCount = text.split('.').filter((part) => part.includes(API_ERROR_MESSAGES.RESOURCE_NOT_FOUND_FOR_ID)).length;
            if (stringCount > 1) {
                text =
                    'Provided data is not valid. We did not find the resources in the database or the resources belong to other projects.';
            }
            const append = 'Please try again after refreshing the page';
            if (!text.includes(append)) {
                setValues({
                    ...values,
                    text: `${text} ${append}`
                });
            }
        }
    }, [values]);

    const callback = () => {
        customCallback && customCallback();
        switch (error?.status) {
            case 401:
                const disabledError = error.code === API_ERROR_CODES.DISABLED_ERROR;
                dispatchActions(!userPermissions).then(() => {
                    if (!userPermissions) {
                        navigate(PageRoutes.LOGIN);
                    } else if (disabledError) {
                        dispatch(unsetActiveProject());
                        dispatch(unsetActiveTenant());
                        navigate(PageRoutes.PROJECTS);
                    }
                });
                break;
            case 400:
                const resourceNotFound = error.code === API_ERROR_CODES.DATABASE_ERROR;
                dispatchActions().then(() => {
                    resourceNotFound && navigate(-1);
                });
                break;
            default:
                break;
        }
    };
    if (!values) return null;
    const { title, subtitle, text } = values;
    if (userPermissions && error?.status === 401) {
        const okButton: DialogButton = {
            label: 'Ok',
            type: 'BLUE',
            onClick: () => callback(),
            closeOnClick: true
        };

        const logOutButton: DialogButton = {
            label: 'Logout',
            type: 'DEFAULT',
            onClick: () => {
                dispatchActions(true).then(() => navigate(PageRoutes.LOGIN));
            }
        };

        const dialogButtons = [API_ERROR_CODES.LOCKED_ERROR, API_ERROR_CODES.ADMIN_LOCKED_ERROR].includes(error.code)
            ? [okButton]
            : [logOutButton, okButton];
        let icon: string | undefined = undefined;
        switch (error.code) {
            case API_ERROR_CODES.LOCKED_ERROR:
                icon = icons.lockedObjectIcon;
                break;
            case API_ERROR_CODES.ADMIN_LOCKED_ERROR:
                icon = icons.adminLockAlert;
                break;
        }

        return (
            <GenericDialog
                type={DialogTypes.Form}
                title={title}
                subtitle={subtitle}
                text={text}
                onClose={callback}
                actionButtons={dialogButtons}
                customIcon={icon}
            />
        );
    }
    return <GenericDialog type={DialogTypes.Alert} title={title} subtitle={subtitle} text={text} onClose={callback} />;
};

export default BackendErrorDialog;
