import { FC, useEffect, useState } from 'react';
import { DialogTextField } from '../../../common/Dialog/GenericDialog';
import { Template, TemplateDefaultValues, templateType, templateTypes } from '../../../../types/Template';
import _ from 'lodash';
import { generateUID } from '../../../../utils/fnGenerator';
import { validator } from '../../../../utils/fnValidator';
import { NewTemplate } from '../Common/NewTemplate';
import { NewTemplateProps } from '../Items/NewItem';

export type NewPageTemplateProps = NewTemplateProps & { page?: Template };

export const NewPageTemplate: FC<NewPageTemplateProps> = ({ open, page, onClose, onRelease, onRetract, onSave }) => {
    const [name, setName] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ icon?: string; name?: string }>({});

    const validateNewPage = (values: TemplateDefaultValues) => {
        const { icon } = values;
        const newErrors = { ...errors };
        newErrors.name = validator({ required: true, minLength: 3 }, name);
        newErrors.icon = validator({ required: true, minLength: 3 }, icon);

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };
    const onReleaseDialog = () => {
        if (!page) return;
        onRelease(page._id);
    };

    const onSaveDialog = (values: TemplateDefaultValues) => {
        const { icon, iconBackground, projectIds, tenantIds } = values;
        if (!validateNewPage(values)) return;
        const newPage: Template = {
            _id: page?._id || generateUID(),
            type: templateTypes.PAGE,
            projectIds,
            tenantIds,

            values: {
                ...page?.values,
                name: name
            },
            icon,
            iconBackground,
            audienceValues: page?.audienceValues || [],
            lastModified: page?.lastModified
        };
        onSave(newPage);
    };

    const onCloseDialog = () => {
        setName('');
        setErrors({});
        onClose();
    };

    const onRetractDialog = () => {
        if (!page) return;
        onRetract(page._id);
    };

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (!page) return;
        setName(page.values?.name || '');
    }, [page]);

    const children = (
        <DialogTextField
            value={name}
            error={errors.name}
            onChange={(e: any) => {
                setName(e.target.value);
                setErrors(_.omit(errors, 'name'));
            }}
            placeholder={'Name'}
            label={'Name'}
        />
    );

    if (!isOpen) return null;

    return (
        <NewTemplate
            open={open}
            templateType={templateTypes.PAGE}
            template={page}
            onRelease={onReleaseDialog}
            onRetract={onRetractDialog}
            onSave={onSaveDialog}
            onClose={onCloseDialog}
            errors={errors}
            setErrors={setErrors}
            extraValues={{ name }}
        >
            {children}
        </NewTemplate>
    );
};
