import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { User } from '../../types/User';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';

export const fetchUsers = createAsyncThunk<
    { users: User[]; error: { message: string; code: string } | null; permissions?: any },
    { addPermissions?: boolean; orderBy?: string; searchTerm?: string }
>('users/fetchUsers', async ({ addPermissions, orderBy, searchTerm }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllUsers(addPermissions, orderBy, searchTerm);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { users: result.response as any, error: null, permissions: result.permissions };
});

export const createUser = createAsyncThunk<{ id: string; error: { message: string; code: string } | null }, User>(
    'users/createUser',
    async (user: User, thunkApi) => {
        const result = await ConfigServiceAPI.createUser(user);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any, error: null };
    }
);

export const updateUser = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, User>(
    'users/updateUser',
    async (user: User, thunkApi) => {
        const result = await ConfigServiceAPI.updateUser(user);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: result.response as any, error: null };
    }
);

export const inviteUser = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'users/inviteUser',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.inviteUser(id);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: result.response as any, error: null };
    }
);

export const deleteUser = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'users/deleteUser',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.deleteUser(id);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: result.response as any, error: null };
    }
);

export const disableUser = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'users/disableUser',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.disableUser(id);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: result.response as any, error: null };
    }
);

export const enableUser = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'users/enableUser',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.enableUser(id);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: result.response as any, error: null };
    }
);

export interface UsersState {
    users: User[];
    loading: boolean;
    error: {
        message: string;
        code?: string;
    } | null;
}

const initialState: UsersState = {
    users: [],
    error: null,
    loading: false
};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        unsetUserError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.users = action.payload.users;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchUsers.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.users = [];
                state.loading = false;
            })
            .addCase(fetchUsers.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createUser.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createUser.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createUser.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(updateUser.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateUser.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateUser.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(deleteUser.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteUser.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteUser.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(inviteUser.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(inviteUser.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(inviteUser.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(disableUser.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(disableUser.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(disableUser.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(enableUser.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(enableUser.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(enableUser.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            });
    }
});

export const { unsetUserError } = slice.actions;
export default slice.reducer;
