export const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const generateUID = (length: number | null = null) => {
    const result = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
    return length !== null ? result.slice(0, length) : result;
};

export const generateIDFromName = (name: string) => {
    return name
        .replace(/[^a-zA-Z0-9-_ ]/g, '')
        .replace(/ /g, '_')
        .toUpperCase();
};

export const generateRankingString = (ranking: number) => {
    let result = '';
    if (ranking !== undefined || ranking !== null || ranking !== '') {
        result = `${ranking >= 0 ? '+' : ''}${ranking}${ranking >= 0 && ranking < 10 && Math.floor(ranking) === ranking ? '.0' : ''}%`;
    }
    return result;
};

export const generateNameInitials = (fullName: string) => {
    return fullName.split(' ').map((name) => name.charAt(0));
};

export const generateKeyFromName = (name: string) => {
    name = name.trim();
    const parts = name.split(' ');
    if (parts.length === 1) {
        return parts[0].substring(0, 3);
    }
    const acronym = name.match(/\b(\w)/g)?.join('') || '';
    if (acronym.length > 2) {
        return acronym.substring(0, 3);
    }
    let key = `${acronym}`;
    if (parts.some((part) => part.length > 1)) {
        let i = 0;
        while (key.length < 3) {
            const newName = parts.map((part) => (part.length === 1 ? part : part.substring(i + 1, part.length))).join(' ');
            const newAcronym = newName.match(/\b(\w)/g)?.join('') || '';
            key += newAcronym;
            i++;
        }
    }
    return key.substring(0, 3);
};
