import React, { useEffect, useState } from 'react';
import icons from '../../../style';
import Profile from '../Profile/Profile';
import SelectSingle from '../Select/SelectSingle';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { AddButtonStyle, FilterByDropdown, IconsContainer, LeftSide, OptionsContainer, RightSide, Title } from './DashboardTitle.css';
import Button from '../../Buttons/Button/Button';
import { ScreenActions, StyledSVGInline } from '../../../style/styled-components/reusable.css';
import { renderTooltip, renderTooltipWithKey, tooltipTypes } from '../Tooltips/Tooltips';
import HelpIcon, { CircleSlugOption } from '../HelpIcon/HelpIcon';
import { HeaderSearch } from '../SearchBar/Header/HeaderSearch';
import { useAppSelector } from '../../../hooks/redux';
import { SearchState } from '../../../redux/slices/searchSlice';
import useScreenSize from '../../../hooks/useScreenSize';

type ScreenTitleProps = {
    title: string | JSX.Element;
    withAddButton?: boolean;
    addLabel?: string | JSX.Element;
    onAdd?: any;
    withProfile?: boolean;
    withFilter?: boolean;
    onFilter?: any;
    withoutSearch?: boolean;
    filterOptions?: string[];
    maxHeight?: boolean;
    addBreadcrumbs?: boolean;
    backIcon?: boolean;
    onBack?: any;
    breadcrumbsProps?: { path?: string; callback?: (arg: string) => void; placeholder?: string };
    loading?: boolean;
    withSecondaryButton?: boolean;
    secondaryButtonLabel?: string | JSX.Element;
    onSecondarybuttonClick?: () => void;
    extraButtonProps?: {
        [key in 'primary' | 'secondary']?: {
            disabled?: boolean;
            tooltip?: {
                value: string;
                isKey?: boolean;
            };
        };
    };
    /************
     * When the HeaderSearch needs to behave as a local search, we need to pass some parameters
     * the object to be filtered and the callback
     */
    circlesSlugOptions?: CircleSlugOption; // if not provided, the first page of the documentation will be displayed
};
export const SCREEN_TITLE_ID = 'SCREEN_TITLE_ID';

const ScreenTitle: React.FC<ScreenTitleProps> = ({
    withAddButton,
    addLabel,
    onAdd,
    withFilter,
    onFilter,
    withoutSearch,
    title,
    withProfile,
    filterOptions,
    maxHeight,
    addBreadcrumbs,
    backIcon,
    onBack,
    breadcrumbsProps,
    loading,
    withSecondaryButton,
    secondaryButtonLabel,
    onSecondarybuttonClick,
    circlesSlugOptions,
    extraButtonProps
}) => {
    const [filterBy, setFilterBy] = useState<{ value: string; label: string } | null>(null);
    const { isSidebarOpen }: SearchState = useAppSelector((state) => state.search);
    const { isMobile, isDesktop } = useScreenSize();

    useEffect(() => {
        if (onFilter) {
            onFilter();
        }
    }, [filterBy]);

    let options = filterOptions?.length
        ? filterOptions.map((option) => {
              const value = option.toLowerCase();
              return {
                  label: option,
                  value: value.includes(' ') ? value.split(' ').join('_') : value
              };
          })
        : [];

    const showOnlySearchbar = isMobile && isSidebarOpen;

    let primaryButton = (
        <Button
            label={addLabel || ''}
            type="BLUE"
            style={AddButtonStyle}
            onClick={(evt: any) => {
                evt.preventDefault();
                onAdd && onAdd();
            }}
            responsive={!isDesktop}
            dataCy={'add-resource-button'}
        />
    );

    let secondaryButton = (
        <Button
            label={secondaryButtonLabel || ''}
            type="DEFAULT"
            style={AddButtonStyle}
            onClick={(evt: any) => {
                evt.preventDefault();
                onSecondarybuttonClick && onSecondarybuttonClick();
            }}
            responsive={!isDesktop}
            dataCy={'secondary-resource-button'}
            isDisabled={extraButtonProps?.secondary?.disabled}
        />
    );

    if (extraButtonProps?.primary?.tooltip) {
        primaryButton = renderTooltip(
            primaryButton,
            tooltipTypes.TEXT,
            extraButtonProps.primary.tooltip.value,
            undefined,
            undefined,
            extraButtonProps.primary.tooltip.isKey
        );
    }

    if (extraButtonProps?.secondary?.tooltip) {
        secondaryButton = renderTooltip(
            secondaryButton,
            tooltipTypes.TEXT,
            extraButtonProps.secondary.tooltip.value,
            undefined,
            undefined,
            extraButtonProps.secondary.tooltip.isKey
        );
    }

    return (
        <ScreenActions id={SCREEN_TITLE_ID} $maxHeight={withFilter || maxHeight} $loading={loading}>
            {showOnlySearchbar ? (
                <HeaderSearch />
            ) : (
                <>
                    <LeftSide>
                        <Title>
                            {backIcon &&
                                renderTooltipWithKey(
                                    <StyledSVGInline
                                        src={icons.arrowLeftIcon}
                                        onClick={(evt) => {
                                            evt.preventDefault();
                                            onBack && onBack();
                                        }}
                                        data-cy={'generic_icon_back'}
                                    />,
                                    'generic_icon_back'
                                )}
                            {title}
                        </Title>
                        {addBreadcrumbs && (
                            <Breadcrumbs
                                path={breadcrumbsProps?.path}
                                callback={breadcrumbsProps?.callback}
                                placeholder={breadcrumbsProps?.placeholder}
                            />
                        )}
                    </LeftSide>
                    <RightSide>
                        <OptionsContainer withAddButton={withAddButton}>
                            <IconsContainer>
                                {!withoutSearch && <HeaderSearch />}
                                <HelpIcon circlesSlugOptions={circlesSlugOptions} />
                            </IconsContainer>

                            {withAddButton && primaryButton}
                            {withSecondaryButton && secondaryButton}
                            {withProfile && <Profile />}
                        </OptionsContainer>
                        {withFilter && (
                            <FilterByDropdown>
                                <SelectSingle
                                    placeholder={'Filter By'}
                                    options={options}
                                    value={filterBy}
                                    onChange={(value) => setFilterBy(value)}
                                />
                            </FilterByDropdown>
                        )}
                    </RightSide>
                </>
            )}
        </ScreenActions>
    );
};

export default ScreenTitle;
