import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';

const theme: any = getTheme();

export const CreateResourceCardsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
`;

export const CreateResourceIconContainer = styled.div`
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: ${theme.palette.primary.main};
`;

export const TemplateCard = styled.div`
    width: 344px;
    height: 92px;
    padding: 24px;
    box-sizing: border-box;
    border: 1px solid ${theme.palette.background.border};
    border-radius: 4px;
    box-shadow: 0 0 3px ${theme.palette.background.border};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    & > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    cursor: pointer;
    &:hover {
        border: 1px solid ${theme.palette.primary.main};
    }
`;

export const TemplateName = styled.div`
    ${fontSizesCSS.Caption2}
`;
export const TemplateType = styled.div`
    ${fontSizesCSS.Caption3}
`;
