import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks/redux';
import { pagesState } from '../../../redux/slices/pagesSlice';
import GenericDialog, { DialogButton, DialogDropdownSingle, DialogTextField, DialogTypes } from '../../common/Dialog/GenericDialog';
import { validator } from '../../../utils/fnValidator';
import { IconsSelect } from '../../common/Select/IconsSelect';
import { capitalizeAndSplitCamelCaseString } from '../../../utils/fnString';
import { MenuItemTypes, MenuTypes } from '../../../types/Menu';
import { ActionFieldsToggle } from '../../Items/Items.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../assets/images/icons';
import {
    ApplicationFieldLabelValues,
    ApplicationFieldTooltips,
    ApplicationFieldTooltipsValues,
    ApplicationValues
} from '../../Applications/ApplicationDialog';
import { ApplicationsState } from '../../../redux/slices/applicationsSlice';
import { useNavigate } from 'react-router-dom';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';
import { getPagesSelectOptions } from '../../../utils/fnDialogs';
import ApplicationSelect from '../../Applications/ApplicationSelect';
import useTranslation from '../../../hooks/useTranslation';

type NewMenuItemProps = {
    open: boolean;
    menuItem?: any;
    newPageId?: string;
    onClose: () => void;
    onSave: (item: any) => void;
    onAddNewItem?: () => void;
    showOnlyTypePage?: boolean;
    withoutNewOption?: boolean;
    menuType: MenuTypes;
};

export const NewMenuItemDialog: FC<NewMenuItemProps> = ({
    open,
    menuItem,
    newPageId,
    withoutNewOption,
    onClose,
    onSave,
    onAddNewItem,
    showOnlyTypePage,
    menuType
}) => {
    const [title, setTitle] = useState<string>('');
    const [subtitle, setSubtitle] = useState('');
    const [url, setUrl] = useState('');
    const [icon, setIcon] = useState<any>('');
    const [itemType, setItemType] = useState<MenuItemTypes | ''>('');
    const [pageId, setPageId] = useState<string>('');
    const [errors, setErrors] = useState<{
        type?: string;
        page?: string;
        package?: string;
        title?: string;
        subtitle?: string;
        url?: string;
        selectedApplication?: string;
        [key: string]: any;
    }>({});

    const navigate = useNavigate();
    const { translate } = useTranslation();
    const { allPages: pages, loadingAllPages: pagesLoading }: pagesState = useAppSelector((state) => state.pages);
    const { applications }: ApplicationsState = useAppSelector((state) => state.applications);
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);

    // APPLICATIONS
    const [selectedApplication, setSelectedApplication] = useState('');
    const [applicationFieldValues, setApplicationFieldValues] = useState<any>({});
    const [optionsExpanded, setOptionsExpanded] = useState(false);

    const onHandleSave = () => {
        if (!validateMenuItem()) return;

        let newMenuItem: any = {
            title: title
        };

        switch (menuType) {
            case MenuTypes.CUSTOMER_SERVICE:
                newMenuItem.subtitle = subtitle;
                newMenuItem.url = url;
                break;
            case MenuTypes.MAIN:
                if (!itemType) return;
                newMenuItem.type = itemType;
                newMenuItem.icon = icon;
                if (itemType === MenuItemTypes.APPLICATION) {
                    newMenuItem = { ...newMenuItem, ...applicationFieldValues, appName: selectedApplication };
                }
                if (itemType === MenuItemTypes.PAGE) {
                    newMenuItem.pageId = pageId;
                }
                break;
            default:
                break;
        }
        onSave(newMenuItem);
        onHandleClose();
    };
    const onHandleClose = () => {
        onClose && onClose();
        setSelectedApplication('');
        setApplicationFieldValues({});
        setOptionsExpanded(false);
        setTitle('');
        setItemType('');
        setPageId('');
        setErrors({});
        setIcon('');
        setSubtitle('');
        setUrl('');
    };
    const validateMenuItem = () => {
        const newErrors = { ...errors };

        switch (menuType) {
            case MenuTypes.MAIN:
                newErrors.type = validator({ required: true }, itemType);
                if (itemType === MenuItemTypes.PAGE) {
                    newErrors.page = validator({ required: true }, pageId);
                }
                if (itemType === MenuItemTypes.APPLICATION) {
                    let valuesCount = Object.values(applicationFieldValues).filter((value: any) => !!value).length;
                    Object.keys(applicationFieldValues).forEach((key: string) => {
                        newErrors[key] = valuesCount === 0 ? 'At least one field needs to be filled' : '';
                    });

                    if (valuesCount === 0) {
                        newErrors['selectedApplication'] = validator({ required: true }, selectedApplication);
                    }
                }
                break;
            case MenuTypes.CUSTOMER_SERVICE:
                newErrors.title = validator({ required: true }, title);
                newErrors.subtitle = validator({ required: true }, subtitle);
                newErrors.url = validator({ required: true }, url);
                break;
            default:
                break;
        }

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    useEffect(() => {
        if (!!newPageId) {
            setPageId(newPageId);
        }
    }, [newPageId]);

    useEffect(() => {
        if (menuItem) {
            setTitle(menuItem.title);
            setIcon(menuItem.icon);
            setItemType(menuItem.type);
            menuItem.type === MenuItemTypes.APPLICATION && setSelectedApplication(menuItem.appName || '');
            menuItem.type === MenuItemTypes.APPLICATION &&
                setApplicationFieldValues(
                    Object.keys(ApplicationFieldTooltipsValues).reduce((obj, key) => ({ ...obj, [key]: menuItem[key] || '' }), {})
                );
            menuItem.type === MenuItemTypes.PAGE && setPageId(menuItem.pageId || '');
            setSubtitle(menuItem.subtitle || '');
            setUrl(menuItem.url || '');
        }
    }, [menuItem]);

    useEffect(() => {
        if (!menuItem || menuItem.type !== MenuItemTypes.APPLICATION) return;
        // the options should be expanded if the app fields are overidden
        if (!selectedApplication && Object.values(applicationFieldValues).some((val) => !!val)) {
            setOptionsExpanded(true);
        }
    }, [menuItem, selectedApplication, applicationFieldValues]);

    const checkAppExistsAndReturnKey = (values: ApplicationValues) => {
        for (const appKey in applications) {
            const isSelected = Object.keys(applications[appKey]).every(
                (key) => applications[appKey][key as keyof ApplicationValues] === `${values[key as keyof ApplicationValues]}`.trim()
            );
            if (isSelected) {
                return appKey;
            }
        }
        return '';
    };

    const saveButton: DialogButton = {
        label: menuItem ? 'Save' : 'Create',
        type: 'BLUE',
        disabled: pagesLoading,
        onClick: onHandleSave
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: onHandleClose
    };

    const typeOptions = showOnlyTypePage
        ? [{ label: capitalizeAndSplitCamelCaseString(MenuItemTypes.PAGE), value: MenuItemTypes.PAGE }]
        : Object.values(MenuItemTypes).map((type) => {
              return {
                  label: capitalizeAndSplitCamelCaseString(type),
                  value: type
              };
          });

    const pageOptions = getPagesSelectOptions(pages, activeProjectId || '', navigate, translate, true);
    const pageValue = pageOptions.find((page: any) => page.value === pageId);
    const typeValue = typeOptions.find((type: any) => type.value === itemType);

    const renderFieldsForTypePage = () => (
        <DialogDropdownSingle
            options={pageOptions}
            onChange={(e: any) => {
                setPageId(e.value);
                setErrors(_.omit(errors, 'page'));
            }}
            withTopMargin
            value={pageValue}
            placeholder={'Page Selection'}
            error={errors.page}
            isDisabled={pagesLoading}
            newOption={
                !withoutNewOption
                    ? {
                          name: 'page',
                          onClick: () => {
                              onAddNewItem?.();
                          }
                      }
                    : undefined
            }
        />
    );

    const renderFieldsForTypeApplication = () => {
        const fields = [];

        fields.push(
            <ApplicationSelect
                selectedApplication={selectedApplication}
                onSelectedApplicationChange={(newSelectedApp: string) => setSelectedApplication(newSelectedApp)}
                errors={errors}
                setErrors={setErrors}
                onApplicationFieldValuesChange={(newApplicationValues?: ApplicationValues) =>
                    setApplicationFieldValues(newApplicationValues || {})
                }
            />
        );

        fields.push(
            <ActionFieldsToggle onClick={() => setOptionsExpanded(!optionsExpanded)} key={'expand_toggle'}>
                {`${!optionsExpanded ? 'Show' : 'Hide'} Fields`}{' '}
                <SVGInline src={optionsExpanded ? icons.arrowUpIcon : icons.arrowDownIcon} />
            </ActionFieldsToggle>
        );

        optionsExpanded &&
            Object.keys(ApplicationFieldTooltipsValues).forEach((field) => {
                const fieldName: keyof ApplicationFieldTooltips = field in ApplicationFieldTooltipsValues ? (field as any) : '';

                fields.push(
                    <DialogTextField
                        key={`param_${field}`}
                        value={applicationFieldValues[field] || ''}
                        onChange={(e: any) => {
                            const newValues = { ...applicationFieldValues, [field]: e.target.value };
                            setSelectedApplication(checkAppExistsAndReturnKey(newValues));
                            setApplicationFieldValues(newValues);
                            setErrors(_.omit(errors, Object.keys(ApplicationFieldTooltipsValues)));
                        }}
                        toolTipText={ApplicationFieldTooltipsValues[fieldName]}
                        label={ApplicationFieldLabelValues[fieldName]}
                        error={errors?.[field]}
                        withTopMargin
                    />
                );
            });
        return fields;
    };

    if (!open) return null;

    const renderTypeSpecificFields = () => {
        switch (menuType) {
            case MenuTypes.CUSTOMER_SERVICE:
                return (
                    <>
                        <DialogTextField
                            placeholder="Menu item Subtitle"
                            label="Menu item Subtitle"
                            value={subtitle}
                            onChange={(e: any) => {
                                setSubtitle(e.target.value);
                                setErrors(_.omit(errors, 'subtitle'));
                            }}
                            error={errors?.subtitle}
                            withTranslations
                            withTopMargin
                        />
                        <DialogTextField
                            placeholder="Menu item Url"
                            label="Menu item Url"
                            value={url}
                            onChange={(e: any) => {
                                setUrl(e.target.value);
                                setErrors(_.omit(errors, 'url'));
                            }}
                            error={errors?.url}
                            withTopMargin
                        />
                    </>
                );
            case MenuTypes.MAIN:
                return (
                    <>
                        <DialogDropdownSingle
                            options={typeOptions}
                            onChange={(e: any) => {
                                setItemType(e.value);
                                setErrors({});
                            }}
                            value={typeValue}
                            placeholder={'Menu Component'}
                            error={errors.type}
                            withTopMargin
                        />{' '}
                        {itemType === MenuItemTypes.PAGE && renderFieldsForTypePage()}
                        {itemType === MenuItemTypes.APPLICATION && renderFieldsForTypeApplication()}
                        <IconsSelect value={icon} onChange={(value) => setIcon(value)} />
                    </>
                );
        }
    };

    return (
        <GenericDialog
            type={DialogTypes.Form}
            title={`${menuItem ? ` Edit` : `Create`}  Menu Item`}
            actionButtons={[cancelButton, saveButton]}
            onClose={onHandleClose}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.menus }}
        >
            <DialogTextField
                placeholder="Menu item Title"
                label="Menu item Title"
                value={title}
                onChange={(e: any) => {
                    setTitle(e.target.value);
                    setErrors(_.omit(errors, 'title'));
                }}
                error={errors?.title}
                withTranslations
            />
            {renderTypeSpecificFields()}
        </GenericDialog>
    );
};
