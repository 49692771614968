import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';

import { AppBranding } from '../../types/AppBranding';

export const fetchAppBrandings = createAsyncThunk<
    { appBrandings: AppBranding[]; error: { message: string; code: string } | null; permissions?: any },
    any | undefined
>('appBrandings/fetchAppBrandings', async ({ addPermissions, projectId }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllAppBrandings(addPermissions, projectId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { appBrandings: result.response as any as AppBranding[], error: null, permissions: result.permissions };
});

export const fetchAppBranding = createAsyncThunk<{ appBranding: AppBranding; error: { message: string; code: string } | null }, string>(
    'appBrandings/fetchAppBranding',
    async (appBrandingId, thunkApi) => {
        const result = await ConfigServiceAPI.getAppBrandingById(appBrandingId);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { appBranding: result.response as any as AppBranding, error: null };
    }
);

export const createAppBranding = createAsyncThunk<{ id: string; error: { message: string; code: string } | null }, AppBranding>(
    'appBrandings/createAppBranding',
    async (appBranding: AppBranding, thunkApi) => {
        const result = await ConfigServiceAPI.createAppBranding(appBranding);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any as string, error: null };
    }
);

export const updateAppBranding = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, AppBranding>(
    'appBrandings/updateAppBranding',
    async (appBranding: AppBranding, thunkApi) => {
        const result = await ConfigServiceAPI.updateAppBranding(appBranding);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export const deleteAppBranding = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'appBrandings/deleteAppBranding',
    async (appBrandingId: string, thunkApi) => {
        const result = await ConfigServiceAPI.deleteAppBranding(appBrandingId);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export interface appBrandingsState {
    appBrandings: AppBranding[];
    selectedAppBranding?: AppBranding;
    loading: boolean;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: appBrandingsState = {
    appBrandings: [],
    loading: false,
    error: null
};

const slice = createSlice({
    name: 'appBrandings',
    initialState,
    reducers: {
        unsetAppBrandingError(state) {
            state.error = null;
        },
        unsetSelectedAppBranding(state) {
            state.selectedAppBranding = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAppBrandings.fulfilled, (state, action: any) => {
                state.appBrandings = action.payload.appBrandings;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchAppBrandings.rejected, (state, action: any) => {
                state.appBrandings = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchAppBrandings.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchAppBranding.fulfilled, (state, action: any) => {
                state.selectedAppBranding = action.payload.appBranding;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchAppBranding.rejected, (state, action: any) => {
                state.selectedAppBranding = undefined;
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchAppBranding.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createAppBranding.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createAppBranding.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(createAppBranding.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateAppBranding.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateAppBranding.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(updateAppBranding.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteAppBranding.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteAppBranding.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteAppBranding.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetAppBrandingError, unsetSelectedAppBranding } = slice.actions;
export default slice.reducer;
