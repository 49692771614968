import styled from 'styled-components';
import getTheme, { fontSizes } from '../../../../../style/themes/theme';
import { SelectWrapper } from '../../../../common/Select/Select.css';
const theme: any = getTheme();

export const HeaderWrapper = styled.div``;

export const SectionTitle = styled.div`
    display: flex;
    align-items: center;
    svg {
        margin-left: 10px;
        cursor: pointer;
    }
    ${fontSizes.Caption2}
`;

export const Section = styled.div`
    margin-top: 20px;
`;

export const SectionBody = styled.div<{ error?: boolean }>`
    ${fontSizes.Body1};
    overflow-wrap: break-word;
    ${(props) => props.error && `color: ${theme.palette.error.main}`}
`;

export const SectionLink = styled.a`
    color: ${theme.palette.primary.main};
    text-decoration: none;
`;

export const CurlURL = styled.div`
    span {
        font-weight: bold;
    }
    overflow-wrap: break-word;
`;

export const CurlHeader = styled.div`
    span {
        font-weight: bold;
    }
`;

export const TargetingSubtitle = styled.h4``;

export const TargetingVersionSelectWrapper = styled.div`
    ${SelectWrapper} {
        width: 200px;
    }
`;
