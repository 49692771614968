import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { LastModified } from '../TargetGroups/UXElements/Audiences/Audiences.css';
import { ActionContainer, ActionIconHolder, ActionWrapper } from '../common/Actions/Actions.css';
import { devices } from '../../style/styled-components/reusable.css';
import { SmallTableRowWrapper, TableRowWrapper } from '../common/Table/Table.css';

const theme: any = getTheme();

export const ArrowContainer = styled.div`
    display: flex;
    gap: 8px;
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;
    padding-right: 16px;
    span {
        display: block;
        height: 16px;
    }

    svg {
        width: 16px;
        height: 16px;
    }
`;

export const ServiceContainer = styled.div`
    position: relative;
    width: 100%;
    height: 48px;

    display: flex;
    align-items: center;
    cursor: pointer;
    ${ArrowContainer} {
        opacity: 0.7;

        &:hover {
            opacity: 1;
        }
    }
`;

export const ServiceTableRow = styled(TableRowWrapper)`
    background: ${theme.palette.background.pressed};
    height: 48px;
    cursor: pointer;
`;

export const SourceSmallTableRow = styled(SmallTableRowWrapper)`
    &:hover {
        background: ${theme.palette.background.pressed};
    }
`;

export const ServiceTitle = styled.div`
    ${fontSizesCSS.Body2}
`;

export const SourceName = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        ${fontSizesCSS.Body1};
    }
    svg {
        opacity: 1;
    }
`;

export const SourcePlaced = styled.div`
    margin-left: auto;
`;

export const ReleaseYearContainer = styled.div`
    display: flex;
    justify-content: space-between;
    [id^='select_wrapper'] {
        width: 47.5%;
    }
`;

export const TemplateNameWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    overflow: hidden;
`;
