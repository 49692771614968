import { Snackbar } from '@material-ui/core';
import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { toastTypes } from './Toast';

const theme: any = getTheme();
export const StyledSnackBar = styled(Snackbar)`
    bottom: 72px;
`;

export const SnackBarWrapper = styled.div<{ $type: toastTypes }>`
    width: fit-content;
    max-width: 539px;
    height: 32px;
    padding: 6px 8px;
    border-radius: 25px;
    background-color: ${({ $type }) =>
        $type === 'success' ? theme.palette.success.semantic : $type === 'warning' ? theme.palette.warning.main : theme.palette.error.main};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 12px;
    svg {
        width: 16px;
        height: 16px;
        ${({ $type }) =>
            $type === 'critical_warning' &&
            `path {
                fill: ${theme.palette.white.main};
            }`}
    }
    cursor: ${({ $type }) => ($type === 'success' ? 'default' : 'pointer')};
    color: ${({ $type }) => ($type === 'warning' ? theme.palette.black.main : theme.palette.white.main)};
`;

export const CloseIconWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const MainIconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const SnackBarTitle = styled.div`
    ${fontSizesCSS.Caption2};
`;

export const SnackBarMessage = styled.div`
    ${fontSizesCSS.Caption1};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const SnackBarText = styled.div`
    display: flex;
    gap: 8px;
    max-width: calc(100% - 24px - 32px); // svg widths + gaps
    overflow: hidden;
    white-space: nowrap;
`;
