import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';

const theme: any = getTheme();
export const PieChartWrapper = styled.div<{ width?: number; height?: number }>`
    width: ${(props) => (props.width ? `${props.width}px` : '200px')};
    height: ${(props) => (props.height ? `${props.height}px` : '200px')};
`;
export const BarChartWrapper = styled.div<{ width?: number; height?: number }>`
    width: 100%;
    height: ${(props) => (props.height ? `${props.height}px` : '200px')};
    div > {
        height: ${(props) => (props.height ? `${props.height}px` : '200px')};
    }
    svg {
        width: 100%;
        padding: 0;
    }
`;

export const TableChartWrapper = styled.div`
    width: 100%;
    min-width: 204px;
    max-height: 336px;
    padding-right: 12px;
    overflow: auto;
    ::-webkit-scrollbar {
        width: 4px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: ${theme.palette.background.border};
        border-radius: 4px;
    }
`;

export const TableAndBarChartWrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    gap: 14px;
    ${TableChartWrapper} {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;
export const TableChartRow = styled.div`
    display: flex;
    margin: 4px 0;
`;
export const TableRowLeftSpacer = styled.div`
    margin-left: auto;
`;
export const TableChartRowKey = styled.div`
    ${fontSizesCSS.Caption2};
    color: ${theme.palette.grey.dark};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
export const TableChartRowValue = styled.div`
    ${fontSizesCSS.Caption1};
`;
export const TableChartRowChangeValue = styled.div`
    ${fontSizesCSS.Caption1};
    margin-left: 24px;
    margin-right: 4px;
    flex-basis: 38px;
`;
export const TableChartRowChangeIndicator = styled.div`
    svg {
        width: 14px;
        height: 14px;
    }
`;
