import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { devices } from '../../style/styled-components/reusable.css';
import { ButtonWrapper } from '../Buttons/Button/Button.css';

const theme: any = getTheme();

export const CapabilitiesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;

    ${ButtonWrapper} {
        width: 180px;
        margin-top: 20px;
    }
`;

export const CapabilitiesLink = styled.span`
    color: ${theme.palette.primary.main};
    cursor: pointer;
`;

export const CapabilitiesTitle = styled.div<{ $extraMargin?: boolean }>`
    padding: 16px 36px;
    background: ${theme.palette.background.pressed};
    text-align: center;
    max-width: 1200px;
    box-sizing: border-box;
    ${fontSizesCSS.Caption1};
    ${(props) => props.$extraMargin && 'margin-bottom: 36px;'}
`;

export const CapabilitiesImage = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    img {
        ${devices.tablet} {
            width: 300px;
        }
    }
`;
