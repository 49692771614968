import styled from 'styled-components';
import { fontSizesCSS } from '../../../style/themes/theme';

export const ObjectTitle = styled.div`
    ${fontSizesCSS.Body2};
`;
export const ObjectInfoMessage = styled.div`
    margin-top: 4px;
    ${fontSizesCSS.Caption3};
    margin-bottom: 16px;
`;
