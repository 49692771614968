import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DisplayCondition } from '../../types/DisplayCondition';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';

export const fetchDisplayConditions = createAsyncThunk<
    { displayConditions: any; error: { message: string; code: string } | null; permissions?: any },
    { addPermissions?: boolean; projectId?: string; orderBy?: string; searchTerm?: string }
>('displayConditions/fetchDisplayConditions', async ({ addPermissions, projectId, orderBy, searchTerm }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllDisplayConditions(addPermissions, projectId, orderBy, searchTerm);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { displayConditions: result.response as any, error: null, permissions: result.permissions };
});

export const fetchPredefinedValues = createAsyncThunk<{ values: any; error: { message: string; code: string } | null }>(
    'displayConditions/getValues',
    async (_, thunkApi) => {
        const result = await ConfigServiceAPI.getPredefinedValues();

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { values: result.response as any, error: null };
    }
);

export const createDisplayCondition = createAsyncThunk<{ id: string; error: { message: string; code: string } | null }, DisplayCondition>(
    'displayConditions/createDisplayCondition',
    async (condition: DisplayCondition, thunkApi) => {
        const result = await ConfigServiceAPI.createDisplayCondition(condition);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any as string, error: null };
    }
);

export const updateDisplayCondition = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { condition: DisplayCondition; shouldUnlockAfterSave?: boolean }
>('displayConditions/updateDisplayCondition', async ({ condition, shouldUnlockAfterSave }, thunkApi) => {
    const result = await ConfigServiceAPI.updateDisplayCondition(condition, shouldUnlockAfterSave);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const deleteDisplayCondition = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'displayConditions/deleteDisplayCondition',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.deleteDisplayCondition(id);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export type DisplayConditionsState = {
    displayConditions: DisplayCondition[];
    predefinedValues?: { [key: string]: { title: string; value: string }[] };
    loading: boolean;
    valuesLoading?: boolean;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
};

const initialState: DisplayConditionsState = {
    displayConditions: [],
    loading: false,
    error: null
};

const slice = createSlice({
    name: 'displayConditions',
    initialState,
    reducers: {
        unsetDisplayConditionError(state) {
            state.error = null;
        },
        unsetDisplayConditions(state) {
            state.displayConditions = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDisplayConditions.fulfilled, (state, action: any) => {
                state.displayConditions = action.payload.displayConditions;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchDisplayConditions.rejected, (state, action: any) => {
                state.displayConditions = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchDisplayConditions.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchPredefinedValues.fulfilled, (state, action: any) => {
                state.predefinedValues = action.payload.values;
                state.error = null;
                state.valuesLoading = false;
            })
            .addCase(fetchPredefinedValues.rejected, (state, action: any) => {
                state.predefinedValues = {};
                state.error = { ...action.payload.error, status: action.payload.status };
                state.valuesLoading = false;
            })
            .addCase(fetchPredefinedValues.pending, (state, _action) => {
                state.valuesLoading = true;
            })
            .addCase(createDisplayCondition.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createDisplayCondition.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(createDisplayCondition.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateDisplayCondition.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateDisplayCondition.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(updateDisplayCondition.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteDisplayCondition.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteDisplayCondition.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteDisplayCondition.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetDisplayConditionError, unsetDisplayConditions } = slice.actions;
export default slice.reducer;
