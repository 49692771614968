import _ from 'lodash';
import moment from 'moment';
import SVGInline from 'react-inlinesvg';
import React, { FC, useEffect, useState } from 'react';
import icons from '../../../assets/images/icons';
import { useAppSelector } from '../../../hooks/redux';
import { LanguagesState } from '../../../redux/slices/languagesSlice';
import { StyledSVGInline, TruncatedText } from '../../../style/styled-components/reusable.css';
import FileManagerDialog from '../../common/Dialog/FileDialogs/FileManagerDialog';
import GenericDialog, {
    DialogButton,
    DialogDropdownMultiple,
    DialogDropdownSingle,
    DialogTextField,
    DialogTypes,
    notSupportedExtensionAlert
} from '../../common/Dialog/GenericDialog';
import { MultiLineTextField } from '../../common/MultiLineTextField/MultiLineTextField';
import { SelectLanguageDropdownLabel } from '../../Languages/Dialogs/NewLanguage.css';
import {
    AssetManagerImageHeader,
    AssetManagerSectionTitle,
    AssetManagerAssetFieldsContainer,
    AssetManagerImageFields,
    AssetManagerFileContainer,
    AssetManagerFileField,
    AssetManagerFileLabel
} from '../AssetManager.css';
import { ContainerImagePreview, FileManagerAdornmentWrapper, ImagePreview, ImagePreviewIcon } from '../../common/Dialog/GenericDialog.css';
import { getImgixUrl } from '../../../utils/Globals';
import configServiceAPI from '../../../utils/api/configServiceAPI';
import { validator } from '../../../utils/fnValidator';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { ContentAsset } from '../../../types/Asset';
import { convertDateStringToUnixTimestamp } from '../../../utils/fnDate';

type NewAssetDialogProps = {
    open: boolean;
    onSave: (asset: ContentAsset) => Promise<boolean>;
    onClose: () => void;
    asset?: ContentAsset;
};

export const assetTagsRegex = /^([a-zA-Z0-9]+:[a-zA-Z0-9]+)(,[a-zA-Z0-9]+:[a-zA-Z0-9]+)*$/;

const DDMMYYRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

const acceptedImageExtensions = ['.png', '.jpg', '.webp'];
const acceptedVideoExtensions = ['.mp4', '.mov'];
const imageAssetsInfo = {
    width: 188,
    height: 106,
    um: 'px'
};

type assetKey = 'video' | 'thumbnail' | 'heroBackground';

export const NewAssetDialog: FC<NewAssetDialogProps> = ({ open, onSave, onClose, asset }) => {
    const [title, setTitle] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [longDescription, setLongDescription] = useState('');
    const [copyright, setCopyright] = useState('');
    const [tags, setTags] = useState('');
    const [language, setLanguage] = useState('');
    const [countries, setCountries] = useState<string[]>([]);
    const [releaseDate, setReleaseDate] = useState('');

    const [openFileManagerDialog, setOpenFileManagerDialog] = useState(false);
    const [fileManagerKey, setFileManagerKey] = useState<assetKey | undefined>(undefined);
    const [assetUrls, setAssetUrls] = useState<{ video?: string; thumbnail?: string; heroBackground?: string }>({});

    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const [languageOptions, setLanguageOptions] = useState<any[]>([]);
    const [countryOptions, setCountryOptions] = useState<any[]>([]);

    const [errors, setErrors] = useState<{
        tags?: string;
        title?: string;
        longDescription?: string;
        releaseDate?: string;
        language?: string;
        countries?: string;
        video?: string;
        thumbnail?: string;
    }>({});

    const { activeTenantId: tenantId, activeProjectId: projectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { languageCodes, countryCodesAssetManager }: LanguagesState = useAppSelector((state) => state.languages);
    const { config } = useAppSelector((state) => state.config);

    const editMode = !!asset?.id;

    useEffect(() => {
        if (!languageCodes?.length) return;
        const options = languageCodes.map((langCode) => {
            return {
                label: (
                    <span>
                        <SelectLanguageDropdownLabel>
                            <img alt={`${langCode.code}`} src={langCode.flag} />
                            <TruncatedText>{`${langCode.name} (${langCode.code})`}</TruncatedText>
                        </SelectLanguageDropdownLabel>
                    </span>
                ),
                value: langCode.code,
                valueForSearch: `${langCode.name} ${langCode.code}`
            };
        });
        setLanguageOptions(options);
    }, [languageCodes]);

    useEffect(() => {
        if (!countryCodesAssetManager?.length) return;
        const options = countryCodesAssetManager.map((countryCode) => {
            const code = countryCode.code.toUpperCase();
            return {
                label: (
                    <span>
                        <SelectLanguageDropdownLabel>
                            <img alt={`${code}`} src={countryCode.flag} />
                            <TruncatedText>
                                {countryCode.name
                                    .toString()
                                    .split('_')
                                    .map((s: string) => (s !== 'and' ? _.capitalize(s) : 'and'))
                                    .join(' ') + ` (${code})`}
                            </TruncatedText>
                        </SelectLanguageDropdownLabel>
                    </span>
                ),
                value: code,
                valueForSearch: `${countryCode.name} ${code}`
            };
        });
        setCountryOptions(options);
    }, [countryCodesAssetManager]);

    useEffect(() => {
        if (!open) return;
        setTitle(asset?.title || '');
        setShortDescription(asset?.shortDescription || '');
        setLongDescription(asset?.longDescription || '');
        setCopyright(asset?.copyright || '');
        setReleaseDate(asset?.releaseDate ? moment.unix(asset.releaseDate).format('DD/MM/YYYY') : '');
        setLanguage(asset?.language || '');
        setCountries(asset?.countries || []);
        setTags(asset?.tags || '');
        setAssetUrls({
            ...(asset?.thumbnail && { thumbnail: decodeURIComponent(asset.thumbnail) }),
            ...(asset?.heroBackground && { heroBackground: decodeURIComponent(asset.heroBackground) }),
            ...(asset?.video && { video: decodeURIComponent(asset.video) })
        });
    }, [open]);

    const clearState = () => {
        setTitle('');
        setShortDescription('');
        setLongDescription('');
        setCopyright('');
        setReleaseDate('');
        setLanguage('');
        setCountries([]);
        setTags('');
        setAssetUrls({});
        setErrors({});
    };

    const handleCloseClick = () => {
        clearState();
        onClose();
    };

    const handleSaveClick = () => {
        if (!validateAsset()) return;
        const newAsset: ContentAsset = {
            id: asset?.id || '',
            tenantId: tenantId || '',
            projectId: projectId || '',
            title,
            ...(shortDescription && { shortDescription }),
            longDescription,
            releaseDate: convertDateStringToUnixTimestamp(releaseDate),
            language,
            countries,
            tags: tags.replace(/\s+/g, ''),
            thumbnail: encodeURIComponent(assetUrls.thumbnail || ''),
            ...(assetUrls.heroBackground && { heroBackground: encodeURIComponent(assetUrls.heroBackground) }),
            video: encodeURIComponent(assetUrls.video || ''),
            ...(copyright && { copyright })
        };
        onSave(newAsset).then(() => {
            handleCloseClick();
        });
    };

    const handleFilePick = async (url: string) => {
        if (!fileManagerKey) return;

        const fileExtension = _.last(url?.split('.')) || '';
        const isVideo = fileManagerKey === 'video';
        const extensions = isVideo ? acceptedVideoExtensions : acceptedImageExtensions;
        const isSupported = extensions.includes(`.${fileExtension}`);
        if (!isSupported) return notSupportedExtensionAlert(extensions);

        let value = '';
        if (isVideo) {
            value = await getEncodedVideoUrl(url);
            if (!value) return notSupportedExtensionAlert(['encoded videos']);
        } else {
            value = getImgixUrl(config.imgixBaseUrl, url);
        }
        setAssetUrls({ ...assetUrls, [fileManagerKey]: value });
        setErrors({ ...errors, [fileManagerKey]: undefined });
        setOpenFileManagerDialog(false);
        setFileManagerKey(undefined);
    };

    const getEncodedVideoUrl = async (url: string) => {
        let encodedUrl = '';
        try {
            const result = await configServiceAPI.checkEncoded(url);
            encodedUrl = result.response ? result.response.toString() : '';
        } catch (error) {
            console.error(error);
        }
        return encodedUrl;
    };

    const validateAsset = () => {
        const newErrors = { ...errors };
        newErrors.title = validator({ required: true }, title);
        newErrors.longDescription = validator({ required: true }, longDescription);
        newErrors.releaseDate = newErrors.releaseDate || validator({ required: true }, releaseDate);
        newErrors.language = validator({ required: true }, language);
        newErrors.countries = validator({ required: true }, countries);
        newErrors.tags = newErrors.tags || validator({ required: true }, tags);
        newErrors.video = validator(
            {
                required: true,
                customMessage: `Please add a value for this field! *Supported files: ${acceptedVideoExtensions
                    .map((ext) => ext.toUpperCase().replace('.', ''))
                    .join(', ')}.`
            },
            assetUrls.video || ''
        );
        newErrors.thumbnail = validator({ required: true, customMessage: 'Please add a Thumbnail image!' }, assetUrls.thumbnail || '');
        setErrors(newErrors);
        return !Object.values(newErrors).some((value) => !!value);
    };

    const validateDate = (date: string) => {
        if (!DDMMYYRegex.test(date)) return false;

        const [day, month, year] = date.split('/').map(Number);
        const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (month === 2 && ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0)) {
            daysInMonth[1] = 29;
        }

        return day > 0 && day <= daysInMonth[month - 1];
    };

    const saveButton: DialogButton = {
        label: editMode ? 'Save' : 'Create',
        type: 'BLUE',
        onClick: handleSaveClick
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    if (!open) return null;

    const renderAssetFields = () => {
        const onAddClick = (key: assetKey) => {
            if (getBackgroundUrl(key)) return;
            setOpenFileManagerDialog(true);
            setFileManagerKey(key);
        };
        const onRemoveClick = (key: assetKey) => {
            setAssetUrls({ ...assetUrls, [key]: undefined });
        };
        const getBackgroundUrl = (key: assetKey) => {
            if (!assetUrls[key] || key === 'video') return undefined;

            const url = assetUrls[key] + '&w=188&h=106&fit=crop';
            return url;
        };
        return (
            <AssetManagerAssetFieldsContainer>
                <AssetManagerSectionTitle>
                    <AssetManagerImageHeader>Images & Video</AssetManagerImageHeader>
                </AssetManagerSectionTitle>
                <AssetManagerImageFields>
                    <AssetManagerFileContainer>
                        <AssetManagerFileLabel>Thumbnail</AssetManagerFileLabel>
                        <AssetManagerFileField onClick={() => onAddClick('thumbnail')} $hasValue={!!getBackgroundUrl('thumbnail')}>
                            {!getBackgroundUrl('thumbnail') ? (
                                <SVGInline src={icons.addIconWhite} />
                            ) : (
                                <ContainerImagePreview
                                    width={imageAssetsInfo.width}
                                    height={imageAssetsInfo.height}
                                    um={imageAssetsInfo.um}
                                    isVideo={false}
                                >
                                    <ImagePreview
                                        width={imageAssetsInfo.width}
                                        height={imageAssetsInfo.height}
                                        src={getBackgroundUrl('thumbnail')}
                                    />
                                    <ImagePreviewIcon>
                                        <StyledSVGInline onClick={() => onRemoveClick('thumbnail')} src={icons.trashIcon} />
                                    </ImagePreviewIcon>
                                </ContainerImagePreview>
                            )}
                        </AssetManagerFileField>
                        <AssetManagerFileLabel>
                            *{acceptedImageExtensions.map((ext) => ext.toUpperCase().replace('.', '')).join(', ')}
                        </AssetManagerFileLabel>
                        <AssetManagerFileLabel $error>{errors.thumbnail}</AssetManagerFileLabel>
                    </AssetManagerFileContainer>
                    <AssetManagerFileContainer>
                        <AssetManagerFileLabel>Hero Background Image</AssetManagerFileLabel>
                        <AssetManagerFileField
                            onClick={() => onAddClick('heroBackground')}
                            $hasValue={!!getBackgroundUrl('heroBackground')}
                        >
                            {!getBackgroundUrl('heroBackground') ? (
                                <SVGInline src={icons.addIconWhite} />
                            ) : (
                                <ContainerImagePreview
                                    width={imageAssetsInfo.width}
                                    height={imageAssetsInfo.height}
                                    um={imageAssetsInfo.um}
                                    isVideo={false}
                                >
                                    <ImagePreview
                                        width={imageAssetsInfo.width}
                                        height={imageAssetsInfo.height}
                                        src={getBackgroundUrl('heroBackground')}
                                    />
                                    <ImagePreviewIcon>
                                        <StyledSVGInline onClick={() => onRemoveClick('heroBackground')} src={icons.trashIcon} />
                                    </ImagePreviewIcon>
                                </ContainerImagePreview>
                            )}
                        </AssetManagerFileField>
                        <AssetManagerFileLabel>
                            *{acceptedImageExtensions.map((ext) => ext.toUpperCase().replace('.', '')).join(', ')}
                        </AssetManagerFileLabel>
                    </AssetManagerFileContainer>
                </AssetManagerImageFields>
                <DialogTextField
                    value={assetUrls.video || ''}
                    onChange={() => {}}
                    label={'Encoded Video'}
                    placeholder={'Browse files'}
                    withTopMargin
                    onClick={() => onAddClick('video')}
                    helperText={
                        !errors.video
                            ? `*Supported files: ${acceptedVideoExtensions.map((ext) => ext.toUpperCase().replace('.', '')).join(', ')}`
                            : undefined
                    }
                    InputProps={{
                        endAdornment: (
                            <div>
                                <FileManagerAdornmentWrapper>
                                    <SVGInline src={icons.fileManagerIcon} />
                                </FileManagerAdornmentWrapper>
                            </div>
                        )
                    }}
                    error={errors.video}
                />
            </AssetManagerAssetFieldsContainer>
        );
    };

    return (
        <>
            <GenericDialog
                title={editMode ? 'Edit Asset' : 'Create Asset'}
                type={DialogTypes.Form}
                onClose={handleCloseClick}
                actionButtons={[cancelButton, saveButton]}
            >
                <DialogTextField
                    value={title}
                    onChange={(e: any) => {
                        setTitle(e.target.value);
                        setErrors({ ...errors, title: undefined });
                    }}
                    label={'Title'}
                    placeholder={'Title'}
                    error={errors.title}
                />
                <DialogTextField
                    value={shortDescription}
                    onChange={(e: any) => {
                        setShortDescription(e.target.value);
                    }}
                    label={'Short Description'}
                    placeholder={'Short Description'}
                    withTopMargin
                    optional
                />
                <MultiLineTextField
                    value={longDescription}
                    onChange={(e: any) => {
                        setLongDescription(e.target.value);
                        setErrors({ ...errors, longDescription: undefined });
                    }}
                    label={'Long Description'}
                    placeholder={'Long Description'}
                    error={errors.longDescription}
                    withTopMargin
                />
                <DialogTextField
                    value={releaseDate}
                    onChange={(e: any) => {
                        setReleaseDate(e.target.value);
                        if (!validateDate(e.target.value))
                            setErrors({
                                ...errors,
                                releaseDate: "Please add a valid date. Format: 'DD/MM/YYYY'."
                            });
                        else setErrors({ ...errors, releaseDate: undefined });
                    }}
                    toolTipText={`Please add release date in the format: 'DD/MM/YYYY'. Day and month numbers should also be logically correct.`}
                    label={'Release Date'}
                    placeholder={'Release Date (DD/MM/YYYY)'}
                    error={errors.releaseDate}
                    withTopMargin
                />
                <DialogTextField
                    value={copyright}
                    onChange={(e: any) => {
                        setCopyright(e.target.value);
                    }}
                    label={'Copyright'}
                    placeholder={'Copyright'}
                    withTopMargin
                    optional
                />
                <DialogDropdownSingle
                    options={languageOptions}
                    placeholder={'Select Language'}
                    labelText={'Language'}
                    onChange={(value: any) => {
                        setLanguage(value.value);
                        setErrors({ ...errors, language: undefined });
                    }}
                    value={languageOptions.find((opt) => opt.value === language)}
                    error={errors.language}
                    withTopMargin
                />
                <DialogDropdownMultiple
                    options={countryOptions}
                    placeholder={'Select Country/Countries'}
                    labelText={'Countries'}
                    onChange={(value: any[]) => {
                        setCountries(value.map((elem) => elem.value));
                        setErrors({ ...errors, countries: undefined });
                    }}
                    value={countryOptions.filter((opt) => countries.includes(opt.value))}
                    selectAllOption
                    allowSelectAll
                    error={errors.countries}
                    withTopMargin
                />
                <MultiLineTextField
                    value={tags}
                    onChange={(e: any) => {
                        const escapedValue = e.target.value.replace(/\s+/g, '');
                        setTags(e.target.value);
                        if (!assetTagsRegex.test(escapedValue)) setErrors({ ...errors, tags: 'Please add a valid value.' });
                        else setErrors({ ...errors, tags: undefined });
                    }}
                    toolTipText={`Please add tags in the format: 'key:value,key:value'. Whitespace and newline is allowed but will be deleted at save.`}
                    label={'Tags'}
                    placeholder={'Tags'}
                    error={errors.tags}
                    withTopMargin
                />
                {renderAssetFields()}
            </GenericDialog>
            <FileManagerDialog
                open={openFileManagerDialog}
                onClose={() => {
                    setOpenFileManagerDialog(false);
                }}
                onFileClick={handleFilePick}
            />
        </>
    );
};
