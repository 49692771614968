import SVGInline from 'react-inlinesvg';
import icons from '../assets/images/icons';
import { renderTooltipWithKey } from '../components/common/Tooltips/Tooltips';
import { DIALOG_NAMES, dialogAlert, ToastAlert } from './fnDialogs';

export const ENCODE_STATUSES = {
    NOT_ENCODED: 'NOT_ENCODED',
    ENCODED: 'ENCODED',
    ENCODING_ERROR: 'ENCODING_ERROR',
    ENCODING_IN_PROGRESS: 'ENCODING_IN_PROGRESS'
} as const;
export type EncodeStatus = typeof ENCODE_STATUSES[keyof typeof ENCODE_STATUSES];
export const renderEncodeStatusIcon = (encodeStatus: EncodeStatus) => {
    switch (encodeStatus) {
        case ENCODE_STATUSES.NOT_ENCODED:
            return renderTooltipWithKey(<SVGInline key={`${encodeStatus}`} src={icons.encodeIcon} />, 'file_manager_video_not_encoded');
        case ENCODE_STATUSES.ENCODED:
            return renderTooltipWithKey(<SVGInline key={`${encodeStatus}`} src={icons.encodeFinishedIcon} />, 'file_manager_video_encoded');
        case ENCODE_STATUSES.ENCODING_IN_PROGRESS:
            return renderTooltipWithKey(
                <SVGInline key={`${encodeStatus}`} src={icons.encodePendingIcon} />,
                'file_manager_video_encoding_in_progress'
            );
        case ENCODE_STATUSES.ENCODING_ERROR:
            return renderTooltipWithKey(
                <SVGInline key={`${encodeStatus}`} src={icons.encodeErrorIcon} />,
                'file_manager_video_encoding_error'
            );
    }
};

export const renderEncodingStartedToast = () => {
    return ToastAlert('success', '', '', '', DIALOG_NAMES.FILE_MANAGER_ENCODING_STARTED);
};

export const renderEncodingInProgressAlert = () => {
    return dialogAlert(DIALOG_NAMES.FILE_MANAGER_ENCODING_IN_PROGRESS);
};

export const renderDisabledActionWhileEncodingInProgressAlert = () => {
    return dialogAlert(
        DIALOG_NAMES.FILE_MANAGER_DISABLED_ACTION_WHILE_ENCODING_IN_PROGRESS,
        false,
        null,
        null,
        false,
        icons.warningYellowIcon
    );
};
