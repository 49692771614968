export interface ISortConfig {
    field: string | AcceptedSortField;
    direction: 'asc' | 'desc';
    isArray?: boolean;
    isObject?: boolean;
}

export const ACCEPTED_SORT_FIELDS = {
    name: 'name',
    fullName: 'fullName',
    lastModified: 'lastModified',
    template: 'template',
    contentSourceType: 'contentSourceType',
    maxNumberOfItems: 'maxNumberOfItems',
    targetGroupId: 'targetGroupId',
    objectType: 'objectType',
    size: 'size',
    key: 'key',
    value: 'value'
} as const;

export type AcceptedSortField = typeof ACCEPTED_SORT_FIELDS[keyof typeof ACCEPTED_SORT_FIELDS];

export const DEFAULT_SORT_CONFIG: ISortConfig = { direction: 'desc', field: ACCEPTED_SORT_FIELDS.lastModified } as const;
export const DEFAULT_SORT_CONFIG_FOR_TRANSLATIONS: ISortConfig = { direction: 'asc', field: ACCEPTED_SORT_FIELDS.key } as const;
export const DEFAULT_SORT_CONFIG_FOR_UNPUBLISHED: ISortConfig = { direction: 'asc', field: ACCEPTED_SORT_FIELDS.objectType } as const;
export const DEFAULT_SORT_CONFIG_FOR_AUDIENCES: ISortConfig = { direction: 'desc', field: ACCEPTED_SORT_FIELDS.targetGroupId } as const;

export const calculateOrderByFromSortConfig = (sortConfig: ISortConfig) => {
    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;
    return orderBy;
};

export const sortObject = (o: any) =>
    Object.keys(o)
        .sort((a, b) => {
            if (a === b) {
                return 0;
            }
            // undefined key sort after anything else
            if (a === 'undefined') {
                return -1;
            }
            if (b === 'undefined') {
                return 1;
            }
            // otherwise, if we're ascending, lowest sorts first
            return a < b ? -1 : 1;
        })
        .reduce((r: any, k) => ((r[k] = o[k]), r), {});
