import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { ObjectFilter } from '../../utils/fnFilter';
import { FeatureFlag } from '../../types/FeatureFlags';

export const fetchFeatureFlags = createAsyncThunk<
    { featureFlags: FeatureFlag[]; error: { message: string; code: string } | null; permissions?: any },
    { addPermissions?: boolean; projectId?: string; orderBy?: string; searchTerm?: string; filter?: ObjectFilter }
>('featureFlags/fetchFeatureFlags', async ({ addPermissions, projectId, orderBy, searchTerm, filter }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllFeatureFlags(addPermissions, projectId, orderBy, searchTerm, filter);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { featureFlags: result.response as any as FeatureFlag[], error: null, permissions: result.permissions };
});

export const fetchFeatureFlag = createAsyncThunk<{ featureFlag: FeatureFlag; error: { message: string; code: string } | null }, string>(
    'featureFlags/fetchFeatureFlag',
    async (featureFlagId, thunkApi) => {
        const result = await ConfigServiceAPI.getFeatureFlagById(featureFlagId);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { featureFlag: result.response as any as FeatureFlag, error: null };
    }
);

export const createFeatureFlag = createAsyncThunk<
    { id: string; error: { message: string; code: string } | null },
    { featureFlag: FeatureFlag; addObjectToGroup?: boolean }
>('featureFlags/createFeatureFlag', async ({ featureFlag, addObjectToGroup = false }, thunkApi) => {
    const result = await ConfigServiceAPI.createFeatureFlag(featureFlag, addObjectToGroup);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { id: result.response as any as string, error: null };
});

export const updateFeatureFlag = createAsyncThunk<
    { id: string; error: { message: string; code: string } | null },
    { featureFlag: FeatureFlag; shouldUnlockAfterSave?: boolean; shouldRepublish?: boolean; addObjectToGroup?: boolean }
>('featureFlags/updateFeatureFlag', async ({ featureFlag, shouldUnlockAfterSave, shouldRepublish, addObjectToGroup }, thunkApi) => {
    const result = await ConfigServiceAPI.updateFeatureFlag(featureFlag, shouldUnlockAfterSave, shouldRepublish, addObjectToGroup);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { id: result.response as any, error: null };
});

export const deleteFeatureFlag = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'featureFlags/deleteFeatureFlag',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.deleteFeatureFlag(id);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export const publishFeatureFlag = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { featureFlagId: string; published?: number }
>('featureFlags/publishFeatureFlag', async ({ featureFlagId }, thunkApi) => {
    const result = await ConfigServiceAPI.publishFeatureFlag(featureFlagId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const abortPublishFeatureFlag = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { featureFlagId: string; published?: number }
>('featureFlags/abortPublishFeatureFlag', async ({ featureFlagId }, thunkApi) => {
    const result = await ConfigServiceAPI.abortPublishFeatureFlag(featureFlagId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export interface featureFlagState {
    featureFlags: FeatureFlag[];
    selectedFeatureFlag?: FeatureFlag;
    loading: boolean;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: featureFlagState = {
    featureFlags: [],
    loading: false,
    error: null
};

const slice = createSlice({
    name: 'featureFlags',
    initialState,
    reducers: {
        unsetFeatureFlagsError(state) {
            state.error = null;
        },
        unsetSelectedFeatureFlag(state) {
            state.selectedFeatureFlag = undefined;
        },
        unsetFeatureFlags(state) {
            state.featureFlags = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFeatureFlags.fulfilled, (state, action: any) => {
                state.featureFlags = action.payload.featureFlags;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchFeatureFlags.rejected, (state, action: any) => {
                state.featureFlags = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchFeatureFlags.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchFeatureFlag.fulfilled, (state, action: any) => {
                state.selectedFeatureFlag = action.payload.featureFlag;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchFeatureFlag.rejected, (state, action: any) => {
                state.selectedFeatureFlag = undefined;
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchFeatureFlag.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createFeatureFlag.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createFeatureFlag.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(createFeatureFlag.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(publishFeatureFlag.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(publishFeatureFlag.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(publishFeatureFlag.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(abortPublishFeatureFlag.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(abortPublishFeatureFlag.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(abortPublishFeatureFlag.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateFeatureFlag.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateFeatureFlag.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(updateFeatureFlag.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteFeatureFlag.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteFeatureFlag.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteFeatureFlag.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetFeatureFlagsError, unsetSelectedFeatureFlag, unsetFeatureFlags } = slice.actions;
export default slice.reducer;
