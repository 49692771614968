import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { CCSettings } from '../../types/CCSettings';
import _ from 'lodash';

export const fetchCCSettings = createAsyncThunk<{ ccSettings: CCSettings; error: { message: string; code: string } | null }>(
    'ccSettings/fetchCCSettings',
    async (_, thunkApi) => {
        const result = await ConfigServiceAPI.getCCSettings();

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }

        const ccSettings = { ...(result.response as any) };
        delete ccSettings._id;

        return { ccSettings, error: null };
    }
);

export const updateCCSettings = createAsyncThunk<{ ccSettings: CCSettings; error: { message: string; code: string } | null }, any>(
    'ccSettings/updateCCSettings',
    async (values: any, thunkApi) => {
        const result = await ConfigServiceAPI.updateCCSettings(values);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }

        const ccSettings = { ...(result.response as any) };
        delete ccSettings._id;

        return { ccSettings, error: null };
    }
);

export interface CCSettingsState {
    ccSettings: CCSettings;
    loading: boolean;
    error: {
        message: string;
        code?: string;
        status?: number;
    } | null;
}

const defaultCCSettings: CCSettings = {
    updatesWidget: {
        title: '',
        hyperlink: '',
        text: '',
        warningMessage: ''
    }
};

const initialState: CCSettingsState = {
    ccSettings: defaultCCSettings,
    error: null,
    loading: false
};

const slice = createSlice({
    name: 'ccSettings',
    initialState,
    reducers: {
        unsetCCSettingsError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCCSettings.fulfilled, (state, action) => {
                state.ccSettings = _.isEmpty(action.payload.ccSettings) ? defaultCCSettings : action.payload.ccSettings;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchCCSettings.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.ccSettings = defaultCCSettings;
                state.loading = false;
            })
            .addCase(fetchCCSettings.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetCCSettingsError } = slice.actions;

export default slice.reducer;
