import React from 'react';
import { HtmlTooltip, HtmlTooltipWrapper, TextTooltip, TextTooltipWrapper } from './Tooltips.css';
import tooltipsJSON from '../../../utils/tooltips.json';

export enum tooltipTypes {
    HTML = 1,
    TEXT
}

export enum tooltipPositions {
    TOP = 'top',
    TOP_START = 'top-start',
    TOP_END = 'top-end',
    RIGHT = 'right',
    RIGHT_START = 'right-start',
    RIGHT_END = 'right-end',
    BOTTOM = 'bottom',
    BOTTOM_START = 'bottom-start',
    BOTTOM_END = 'bottom-end',
    LEFT = 'left',
    LEFT_START = 'left-start',
    LEFT_END = 'left-end'
}

type TooltipsProps = {
    innerElement: JSX.Element;
    type: tooltipTypes;
    value: JSX.Element | string;
    position?: tooltipPositions;
    withArrow?: boolean;
    isKey?: boolean;
    controlled?: boolean;
    open?: boolean;
};

const Tooltips: React.FC<TooltipsProps> = ({ open, innerElement, type, value, position, withArrow, isKey }) => {
    /*
     * https://mui.com/material-ui/api/tooltip/         * Because the children of the tooltip, in our case the innerElement prop has to be able to hold a ref (check documentation link above),
     *if the innerElement is not able to hold a ref (it's type is not in the list) we wrap it with a span
     */
    const withWrapper = !['div', 'span', 'button', 'input'].includes(innerElement.type?.target || innerElement.type);

    if (type === tooltipTypes.HTML) {
        return (
            <HtmlTooltip open={open} title={value} arrow={withArrow} placement={position} TransitionProps={{ timeout: 0 }}>
                {withWrapper ? <HtmlTooltipWrapper>{innerElement}</HtmlTooltipWrapper> : innerElement}
            </HtmlTooltip>
        );
    }
    return (
        <TextTooltip open={open} title={value} arrow={withArrow} placement={position} TransitionProps={{ timeout: 0 }}>
            {withWrapper ? <TextTooltipWrapper>{innerElement}</TextTooltipWrapper> : innerElement}
        </TextTooltip>
    );
};

export const renderTooltip = (
    innerElement: JSX.Element,
    type: tooltipTypes,
    value: JSX.Element | string,
    position: tooltipPositions = tooltipPositions.TOP,
    withArrow: boolean = false,
    isKey: boolean = false,
    controlled?: boolean,
    open?: boolean,
    specificKey?: string
) => {
    if (isKey && typeof value === 'string') {
        value = (tooltipsJSON as any)[value];
    }
    const keyForTooltip = specificKey
        ? specificKey
        : React.isValidElement(value)
        ? value.key
        : isKey && typeof value === 'string'
        ? value
        : innerElement.key;

    return (
        <Tooltips
            key={keyForTooltip}
            open={open}
            controlled={controlled}
            innerElement={innerElement}
            type={type}
            value={value}
            position={position}
            withArrow={withArrow}
        />
    );
};

export const renderTooltipWithKey = (
    innerElement: JSX.Element,
    value: string,
    position?: tooltipPositions,
    specificKey?: string,
    controlled?: boolean,
    open?: boolean
) => {
    return renderTooltip(innerElement, tooltipTypes.TEXT, value, position, false, true, controlled, open, specificKey);
};
