import SVGInline from 'react-inlinesvg';
import icons from '../assets/images/icons';
import { renderTooltipWithKey, renderTooltip, tooltipTypes } from '../components/common/Tooltips/Tooltips';
import {
    ErrorObjectWrapper,
    MultipleErrorObjectWrapper,
    ObjectListHeader,
    ObjectListWrapper,
    PublishedTextSpan
} from '../style/styled-components/reusable.css';
import BackendErrorDialog from '../components/common/Dialog/BackendErrorDialog';
import moment from 'moment';
import { ObjectType, ObjectTypes } from '../types/Object';
import { PageRoutes, buildPathWithProjectId } from '../types/RouteTypes';
import { EMPTY_WORD_STRING } from './Globals';
import _ from 'lodash';
import ObjectNameTooltip from '../components/common/Tooltips/ObjectNameTooltip/ObjectNameTooltip';

export enum PUBLISHED_STATUS {
    PUBLISHED = 'published',
    UNPUBLISHED = 'unpublished',
    PUBLISHED_WITH_CHANGES = 'publishedWithChanges',
    SCHEDULED_TO_PUBLISH = 'scheduledToPublish',
    SCHEDULED_TO_REPUBLISH = 'scheduledToRepublish'
}

export const renderPublishStatusIcon = (
    publishedStatus?: PUBLISHED_STATUS,
    publishAt?: number,
    specificTooltip?: string,
    specificText?: string
) => {
    if (!publishedStatus) return <></>;
    const scheduledText = publishAt
        ? `Scheduled to ${publishedStatus === PUBLISHED_STATUS.SCHEDULED_TO_PUBLISH ? 'publish' : 'republish'} on ${moment(
              publishAt
          ).format('DD-MM-YYYY HH:mm')}`
        : '';

    switch (publishedStatus) {
        case PUBLISHED_STATUS.PUBLISHED:
            const publishedIcon = <SVGInline key={`${publishedStatus}`} src={icons.publishedIcon} />;
            return specificText
                ? renderTooltip(publishedIcon, tooltipTypes.TEXT, specificText)
                : renderTooltipWithKey(publishedIcon, specificTooltip || 'object_published');
        case PUBLISHED_STATUS.UNPUBLISHED:
            const unpublishedIcon = <SVGInline key={`${publishedStatus}`} src={icons.unpublishedIcon} />;
            return specificText
                ? renderTooltip(unpublishedIcon, tooltipTypes.TEXT, specificText)
                : renderTooltipWithKey(unpublishedIcon, specificTooltip || 'object_unpublished');
        case PUBLISHED_STATUS.PUBLISHED_WITH_CHANGES:
            const publishedWithChangesIcon = <SVGInline key={`${publishedStatus}`} src={icons.publishedWithChangesIcon} />;
            return specificText
                ? renderTooltip(publishedWithChangesIcon, tooltipTypes.TEXT, specificText)
                : renderTooltipWithKey(publishedWithChangesIcon, specificTooltip || 'object_published_with_changes');
        case PUBLISHED_STATUS.SCHEDULED_TO_PUBLISH:
            const scheduledPublishIcon = <SVGInline key={`${publishedStatus}`} src={icons.scheduledPublishIcon} />;
            return specificText || scheduledText
                ? renderTooltip(scheduledPublishIcon, tooltipTypes.TEXT, specificText || scheduledText)
                : renderTooltipWithKey(scheduledPublishIcon, specificTooltip || 'object_scheduled_to_publish');
        case PUBLISHED_STATUS.SCHEDULED_TO_REPUBLISH:
            const scheduledRepublishIcon = <SVGInline key={`${publishedStatus}`} src={icons.scheduledPublishIcon} />;
            return specificText || scheduledText
                ? renderTooltip(scheduledRepublishIcon, tooltipTypes.TEXT, specificText || scheduledText)
                : renderTooltipWithKey(scheduledRepublishIcon, specificTooltip || 'object_scheduled_to_republish');
        default:
            return <></>;
    }
};

export const renderPublishStatusText = (publishedStatus: PUBLISHED_STATUS, publishAt?: number) => {
    switch (publishedStatus) {
        case PUBLISHED_STATUS.PUBLISHED:
            return renderTooltipWithKey(<PublishedTextSpan>[Published]</PublishedTextSpan>, 'object_published');
        case PUBLISHED_STATUS.UNPUBLISHED:
            return renderTooltipWithKey(<PublishedTextSpan>[Unpublished]</PublishedTextSpan>, 'object_unpublished');
        case PUBLISHED_STATUS.PUBLISHED_WITH_CHANGES:
            return renderTooltipWithKey(
                <PublishedTextSpan>[Published with Unpublished Changes]</PublishedTextSpan>,
                'object_published_with_changes'
            );
        case PUBLISHED_STATUS.SCHEDULED_TO_PUBLISH:
            return renderTooltipWithKey(
                <PublishedTextSpan>
                    [Scheduled to publish {publishAt ? `on ${moment(publishAt).format('DD-MM-YYYY HH:mm')}` : ''}]
                </PublishedTextSpan>,
                'object_scheduled_to_publish'
            );
        case PUBLISHED_STATUS.SCHEDULED_TO_REPUBLISH:
            return renderTooltipWithKey(
                <PublishedTextSpan>
                    [Scheduled to republish {publishAt ? `on ${moment(publishAt).format('DD-MM-YYYY HH:mm')}` : ''}]
                </PublishedTextSpan>,
                'object_scheduled_to_republish'
            );
        default:
            return <></>;
    }
};

export const renderPublishedDeleteError = (error: any) => {
    const values = {
        title: 'Delete is not allowed!',
        text: `The object you are trying to delete is published in following ${error.message}. Remove the object from the published version of the previously mentioned one(s) before deleting!`
    };
    return <BackendErrorDialog error={error} customValues={values} />;
};

export const renderReferencedObjectDeleteError = (type: ObjectType, error: any, translate?: (arg: string) => string) => {
    const objects: any[] = error.message ? JSON.parse(error.message) : [];
    const menus = objects.filter((obj: any) => obj.type === ObjectTypes.MENUS) || [];
    const items = objects.filter((obj: any) => obj.type === ObjectTypes.ITEMS) || [];

    const renderModuleMessage = () => {
        return (
            <>
                The Module you are trying to delete is used in the following Editorial Item(s):{' '}
                <ErrorObjectWrapper>
                    {items.map((item) => (
                        <ObjectNameTooltip
                            key={item._id}
                            id={item._id}
                            name={translate?.(item.name) || item.name || EMPTY_WORD_STRING}
                            type={ObjectTypes.ITEMS}
                        />
                    ))}
                </ErrorObjectWrapper>
                Remove the Module from the previously mentioned Editorial Item(s) before deleting!
            </>
        );
    };

    const renderPageMessage = () => {
        switch (true) {
            case !!menus.length && !!items.length:
                return (
                    <>
                        The Page you are trying to delete is used in the following objects. Remove the Page from these objects before
                        deleting!
                        {Object.entries({ menus, items }).map(([objKey, objArray]) => (
                            <ObjectListWrapper key={`${objKey}`}>
                                <ObjectListHeader>{objKey === 'items' ? 'Editorial Items' : _.upperFirst(objKey)}:</ObjectListHeader>
                                <>
                                    {(objArray as any[]).map((obj) => (
                                        <MultipleErrorObjectWrapper key={obj._id}>
                                            -{' '}
                                            <ObjectNameTooltip
                                                id={obj._id}
                                                name={translate?.(obj.name) || obj.name || EMPTY_WORD_STRING}
                                                type={obj.type}
                                            />
                                        </MultipleErrorObjectWrapper>
                                    ))}
                                </>
                            </ObjectListWrapper>
                        ))}
                    </>
                );

            case !!menus.length:
                return (
                    <>
                        {' '}
                        The Page you are trying to delete is used in the following Menu(s):{' '}
                        <ErrorObjectWrapper>
                            {menus.map((menu) => (
                                <ObjectNameTooltip
                                    key={menu._id}
                                    id={menu._id}
                                    name={translate?.(menu.name) || menu.name || EMPTY_WORD_STRING}
                                    type={ObjectTypes.MENUS}
                                />
                            ))}
                        </ErrorObjectWrapper>
                        Remove the Page from the previously mentioned Menu(s) before deleting!
                    </>
                );
            case !!items.length:
                return (
                    <>
                        {' '}
                        The Page you are trying to delete is used in the following Editorial Item(s):{' '}
                        <ErrorObjectWrapper>
                            {items.map((item) => (
                                <ObjectNameTooltip
                                    key={item._id}
                                    id={item._id}
                                    name={translate?.(item.name) || item.name || EMPTY_WORD_STRING}
                                    type={ObjectTypes.ITEMS}
                                />
                            ))}
                        </ErrorObjectWrapper>
                        Remove the Page from the previously mentioned Editorial Item(s) before deleting!
                    </>
                );
            default:
                return <></>;
        }
    };
    const values = {
        title: 'Delete is not allowed!',
        text: type === ObjectTypes.PAGES ? renderPageMessage() : renderModuleMessage()
    };
    return <BackendErrorDialog error={error} customValues={values} />;
};
