import { CSSProperties } from '@material-ui/styles';
import React from 'react';
import SVGInline from 'react-inlinesvg';
import icons from '../../assets/images/icons';
import { useAppSelector } from '../../hooks/redux';
import { FeedbackButtonWrapper } from './FeedbackButton.css';
import { renderTooltip, tooltipTypes } from '../common/Tooltips/Tooltips';
import { ConfigState } from '../../redux/slices/configSlice';

const markerSDK = require('@marker.io/browser')?.default;

const FeedbackButton: React.FC<{ style?: CSSProperties }> = ({ style }) => {
    const { pageActionsShow } = useAppSelector((state) => state);
    const { config }: ConfigState = useAppSelector((state) => state.config);
    const handleClick = async (evt: any) => {
        evt.preventDefault();
        const widget = await markerSDK?.loadWidget?.({
            destination: config.MARKERIO_DESTINATION || '62b06ac2ba944a36d7ed4138'
        });
        widget?.capture?.();
    };

    return renderTooltip(
        <FeedbackButtonWrapper onClick={handleClick} $withActions={pageActionsShow} style={{ ...style }}>
            <SVGInline src={icons.commentsIcon}></SVGInline>
        </FeedbackButtonWrapper>,
        tooltipTypes.TEXT,
        'Provide Feedback'
    );
};

export default FeedbackButton;
