import { FC, useEffect, useState } from 'react';
import { validator } from '../../../../utils/fnValidator';
import { Template, TemplateDefaultValues, templateTypes } from '../../../../types/Template';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../../../common/Dialog/GenericDialog';
import _ from 'lodash';
import { TemplatePreview } from '../Common/TemplatePreview';
import { useAppSelector } from '../../../../hooks/redux';
import { defaultColorValues } from '../../../../types/PageStyle';
import { NewTemplate } from '../Common/NewTemplate';

type NewPageStyleTemplateProps = {
    open: boolean;
    pageStyleTemplate?: Template;
    onClose: () => void;
    onSave: (arg: Template) => void;
    onRelease: (id: string) => void;
    onRetract: (id: string) => void;
};
export const NewPageStyleTemplate: FC<NewPageStyleTemplateProps> = ({ open, pageStyleTemplate, onSave, onClose, onRelease, onRetract }) => {
    const [name, setName] = useState<string>('');
    const [errors, setErrors] = useState<{ name?: string; icon?: string }>({});

    useEffect(() => {
        if (!open) return;
        setName(pageStyleTemplate?.values?.name || '');
    }, [pageStyleTemplate, open]);

    const onReleaseDialog = () => {
        if (!pageStyleTemplate) return;
        onRelease(pageStyleTemplate._id);
    };

    const onRetractDialog = () => {
        if (!pageStyleTemplate) return;
        onRetract(pageStyleTemplate._id);
    };

    const validateNewItem = (values: TemplateDefaultValues) => {
        const { icon } = values;
        const newErrors = { ...errors };
        newErrors.name = validator({ required: true, minLength: 3 }, name);
        newErrors.icon = validator({ required: true, minLength: 3 }, icon);

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    const onSaveDialog = (values: TemplateDefaultValues) => {
        if (!validateNewItem(values)) return;
        const { icon, iconBackground, projectIds, tenantIds } = values;
        const newPageStyleTemplate: Template = {
            _id: pageStyleTemplate?._id || '',
            type: templateTypes.PAGE_STYLE,
            projectIds,
            tenantIds,
            values: {
                ...pageStyleTemplate?.values,
                name: name
            },
            icon,
            iconBackground,
            audienceValues: pageStyleTemplate?.audienceValues || [],
            lastModified: pageStyleTemplate?.lastModified
        };
        //when creating assign default colors
        if (!pageStyleTemplate) {
            newPageStyleTemplate.values.colors = { ...defaultColorValues };
        }
        onSave(newPageStyleTemplate);
    };

    const onCloseDialog = () => {
        setName('');
        setErrors({});
        onClose();
    };

    const children = (
        <DialogTextField
            value={name}
            error={errors.name}
            onChange={(e: any) => {
                setName(e.target.value);
                setErrors(_.omit(errors, 'name'));
            }}
            placeholder={'Name'}
            label={'Name'}
        />
    );

    if (!open) return null;

    return (
        <NewTemplate
            open={open}
            templateType={templateTypes.PAGE_STYLE}
            template={pageStyleTemplate}
            onRelease={onReleaseDialog}
            onRetract={onRetractDialog}
            onSave={onSaveDialog}
            onClose={onCloseDialog}
            errors={errors}
            setErrors={setErrors}
            extraValues={{ name }}
        >
            {children}
        </NewTemplate>
    );
};
