import styled, { CSSProperties } from 'styled-components';
import { StyledSVGInline, TruncatedText } from '../../../style/styled-components/reusable.css';
import getTheme, { fontSizes, fontSizesCSS, zIndexesCSS } from '../../../style/themes/theme';

const theme: any = getTheme();

export const LeftSide = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
`;

export const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const SearchSVGInline = styled(StyledSVGInline)`
    padding: 0;
    color: ${theme.palette.text.main};
    cursor: pointer;
    align-self: center;
`;

export const OptionsContainer = styled.div<{ withAddButton?: boolean }>`
    justify-content: flex-end;
    display: flex;
    gap: 24px;
    
    ${SearchSVGInline} {
        width: 20px;
        height: 20px;
    }}
`;

export const IconsContainer = styled.div`
    justify-content: flex-end;
    padding-left: 32px;
    min-width: 56px;
    display: flex;
    gap: 4px;
`;

export const Title = styled(TruncatedText)`
    color: ${theme.palette.text.main};
    ${fontSizesCSS.Title3};
    display: flex;

    ${StyledSVGInline} {
        vertical-align: middle;
        padding: 0 16px 0 0;
        height: 100%;
    }
`;

export const PageNameContainer = styled.div`
    display: flex;
    align-items: center;
    max-width: calc(100% - 40px);

    svg {
        width: 20px;
        height: 100%;
        padding: 0 8px;
    }
    span {
        vertical-align: top;
        align-self: center;
    }
`;

export const PagePrefix = styled.div`
    display: flex;
    align-items: center;
`;

export const UnsavedSpan = styled.span`
    font-weight: normal;
`;

export const AddButtonStyle: CSSProperties = {
    textTransform: 'capitalize',
    width: 'fit-content',
    height: '36px',
    margin: 0,
    ...fontSizes.Body2
};

export const FilterByDropdown = styled.div`
    width: 210px;
    height: 36px;
    z-index: ${zIndexesCSS.zIndexDropdown};
`;
