import { PUBLISHED_STATUS } from '../utils/fnPublish';
import { PlacedObjectType } from './Object';
import { Project } from './Project';
import { Tenant } from './Tenant';

export type FeatureFlag = {
    _id: string;
    name: string;
    tenantId: string;
    projectId: string;
    tenant?: Tenant;
    project?: Project;
    conditionIds: string[];
    lastModified?: number;
    abTestingGroupIds?: string[];
    conditions?: any;
    locked?: string;
    lockedByUser?: {
        name: string;
        icon?: string;
    };
    modifiedBy?: string;
    modifiedByUser?: {
        name: string;
        icon?: string;
    };
    placed?: { name: string; _id: string; type: PlacedObjectType }[];
    flags?: Flags;
    published?: boolean;
    publishAt?: number;
    publishStatus?: PUBLISHED_STATUS;
};

export type Flags = {
    recordings: Record<RecordingsKeys, boolean>;
    userStatus: Record<UserStatusKeys, boolean>;
};

export enum RecordingsKeys {
    REPLAY = 'replay'
}

export enum UserStatusKeys {
    GUEST_MODE = 'guestMode'
}

export const DEFAULT_FLAGS: Flags = {
    recordings: { replay: false },
    userStatus: { guestMode: false }
};

export const FEATURE_FLAGS_TOOLTIPS = {
    [RecordingsKeys.REPLAY]: 'feature_flag_recordings_replay',
    [UserStatusKeys.GUEST_MODE]: 'feature_flag_userStatus_guestMode'
};
