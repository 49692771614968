import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';

const theme: any = getTheme();

export const CCBrandingWrapper = styled.div``;

export const SectionContainer = styled.div`
    background-color: ${theme.palette.white.main};
    flex-direction: column;
    border-radius: 4px;
    margin-top: 24px;
    padding: 24px;
    display: flex;
    gap: 24px;
`;

export const SectionTitle = styled.div`
    ${fontSizesCSS.Body2};
    align-items: center;
    display: flex;
    gap: 6px;
`;

export const MultilineTextFieldWrapper = styled.div`
    .MuiInput-input {
        padding: 6px 12px 32px;
    }
`;
