import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DynamicSource } from '../../types/DynamicSource';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { Service } from '../../utils/Services';

export const fetchSources = createAsyncThunk<
    { sources: any[]; error: { message: string; code: string } | null; permissions?: any },
    any | undefined
>('dynamicSources/fetchSources', async ({ addPermissions, projectId, searchTerm, level }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllSources(addPermissions, projectId, searchTerm, typeof level === 'number' ? level : 1);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { sources: result.response as any, error: null, permissions: result.permissions };
});

export const fetchServices = createAsyncThunk<{ services: any[]; error: { message: string; code: string } | null }, string | undefined>(
    'dynamicSources/fetchServices',
    async (projectId, thunkApi) => {
        const result = await ConfigServiceAPI.getAllServices(projectId);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { services: result.response as any, error: null };
    }
);

export const fetch3rdPartyServices = createAsyncThunk<{ services: any[]; error: { message: string; code: string } | null }>(
    'dynamicSources/thirdPartyServices',
    async (_, thunkApi) => {
        const result = await ConfigServiceAPI.getAll3rdPartyServices();

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { services: result.response as any, error: null };
    }
);

export const createSource = createAsyncThunk<{ id: string; error: { message: string; code: string } | null }, any>(
    'dynamicSources/createSource',
    async (source: any, thunkApi) => {
        const result = await ConfigServiceAPI.createSource(source);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any, error: null };
    }
);

export const updateSource = createAsyncThunk<{ id: string; error: { message: string; code: string } | null }, any>(
    'dynamicSources/updateSource',
    async ({ source, shouldUnlockAfterSave }, thunkApi) => {
        const result = await ConfigServiceAPI.updateSource(source, shouldUnlockAfterSave);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any, error: null };
    }
);

export const deleteSource = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'dynamicSources/deleteSource',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.deleteSource(id);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: result.response as any, error: null };
    }
);

export interface SourcesState {
    sources: DynamicSource[];
    services: Service[];
    thirdPartyServices: Service[];
    loading: boolean;
    error: {
        message: string;
        code?: string;
        status?: number;
    } | null;
}

const initialState: SourcesState = {
    sources: [],
    thirdPartyServices: [],
    services: [],
    error: null,
    loading: false
};

const slice = createSlice({
    name: 'dynamicSources',
    initialState,
    reducers: {
        unsetSourceError(state) {
            state.error = null;
        },
        unsetSources(state) {
            state.sources = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSources.fulfilled, (state, action: any) => {
                state.sources = action.payload.sources;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchSources.rejected, (state, action: any) => {
                state.sources = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchSources.pending, (state, _action: any) => {
                state.loading = true;
            })
            .addCase(fetchServices.fulfilled, (state, action: any) => {
                state.services = action.payload.services;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchServices.rejected, (state, action: any) => {
                state.services = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchServices.pending, (state, _action: any) => {
                state.loading = true;
            })
            .addCase(fetch3rdPartyServices.fulfilled, (state, action: any) => {
                state.thirdPartyServices = action.payload.services;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetch3rdPartyServices.rejected, (state, action: any) => {
                state.thirdPartyServices = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetch3rdPartyServices.pending, (state, _action: any) => {
                state.loading = true;
            })
            .addCase(createSource.fulfilled, (state, _action: any) => {
                state.loading = false;
            })
            .addCase(createSource.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(createSource.pending, (state, _action: any) => {
                state.loading = true;
            })
            .addCase(updateSource.fulfilled, (state, _action: any) => {
                state.loading = false;
            })
            .addCase(updateSource.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(updateSource.pending, (state, _action: any) => {
                state.loading = true;
            })
            .addCase(deleteSource.fulfilled, (state, _action: any) => {
                state.loading = false;
            })
            .addCase(deleteSource.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteSource.pending, (state, _action: any) => {
                state.loading = true;
            });
    }
});

export const { unsetSourceError, unsetSources } = slice.actions;

export default slice.reducer;
