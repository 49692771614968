import styled from 'styled-components';
import { ButtonWrapper } from './Button/Button.css';
import getTheme from '../../style/themes/theme';

const theme: any = getTheme();

export const FeedbackButtonWrapper = styled.div<{ $withActions?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: ${({ $withActions }) => ($withActions ? '108px' : '24px')};
    right: 36px;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    background: ${theme.palette.background.feedback};
    color: ${theme.palette.white.main};
    margin: 0;
    &:hover {
        background: ${theme.palette.background.feedbackHover};
        cursor: pointer;
    }
    svg {
        width: 28px;
        height: 28px;
    }

    z-index: 1301;
`;
