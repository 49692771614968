import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
    DropdownContent,
    DropdownContentModified,
    DropdownItemSimpleText,
    DropdownItemWrapper,
    DropdownLabel,
    DropdownMenuList,
    DropdownWrapper,
    SVGWrapper
} from './DropdownMenu.css';
import { StyledSVGInline } from '../../../style/styled-components/reusable.css';

export type DropdownMenuProps = {
    showMenuContent?: boolean;
    onClick?: () => void;
    handleIsOpen?: (val: boolean) => void;
    triggerButton: ReactNode;
    verticalPosition?: 'top' | 'bottom';
    horizontalPosition?: 'left' | 'right';
    openOnMouseEnter?: boolean;
    closeOnMouseLeave?: boolean;
    selectedIndex?: number;
    visibilityChangeCallback?: (isVisible: boolean) => void;
    modified?: boolean;
    children?: React.ReactNode;
    optionsDisabled?: boolean;
};

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
    showMenuContent = false,
    openOnMouseEnter = false,
    closeOnMouseLeave = true,
    selectedIndex = null,
    onClick = () => {},
    children,
    triggerButton,
    verticalPosition,
    horizontalPosition,
    visibilityChangeCallback,
    handleIsOpen,
    modified,
    optionsDisabled,
    ...rest
}) => {
    const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState<boolean>(showMenuContent);
    const [autoVerticalPosition, setDropdownVerticalPosition] = useState<string>('');
    const [autoHorizontalPosition, setDropdownHorizontalPosition] = useState<string>('');
    const menuButtonRef = useRef<HTMLElement | null>(null);
    const menuContentRef = useRef<HTMLElement | null>(null);
    const hideAnimationTimeout = useRef<any | null>(null);

    useEffect(() => {
        return () => {
            clearTimeout(hideAnimationTimeout.current);
        };
    }, []);

    const setDropdownPosition = () => {
        if (window && menuButtonRef?.current && menuContentRef?.current) {
            setDropdownVerticalPosition(
                menuButtonRef.current.getBoundingClientRect().bottom + menuContentRef.current.getBoundingClientRect().height <
                    window.innerHeight
                    ? 'bottom'
                    : 'top'
            );

            setDropdownHorizontalPosition(
                menuButtonRef.current.getBoundingClientRect().left + menuContentRef.current.getBoundingClientRect().width <
                    window.innerWidth
                    ? 'left'
                    : 'right'
            );
        }
    };

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
        setDropdownPosition();
        setIsDropdownMenuOpen(!isDropdownMenuOpen);
        if (handleIsOpen) {
            handleIsOpen(!isDropdownMenuOpen);
        }
    };

    const handleMouseEnter = () => {
        clearTimeout(hideAnimationTimeout.current);
        if (openOnMouseEnter) {
            setDropdownPosition();
            setIsDropdownMenuOpen(true);
            if (handleIsOpen) {
                handleIsOpen(true);
            }
        }
    };

    const handleMouseLeave = () => {
        if (!closeOnMouseLeave) return;
        hideAnimationTimeout.current = setTimeout(() => {
            setIsDropdownMenuOpen(false);
            if (handleIsOpen) {
                handleIsOpen(false);
            }
        }, 500);
    };

    const getDesktopView = () => {
        const props = {
            showMenuContent: isDropdownMenuOpen,
            verticalPosition: verticalPosition || autoVerticalPosition,
            horizontalPosition: horizontalPosition || autoHorizontalPosition,
            disabled: optionsDisabled,
            ref: (ref: any) => {
                menuContentRef.current = ref;
            }
        };
        return modified ? (
            <DropdownContentModified {...props}>
                <DropdownMenuList>{children}</DropdownMenuList>
            </DropdownContentModified>
        ) : (
            <DropdownContent {...props}>
                <DropdownMenuList>{children}</DropdownMenuList>
            </DropdownContent>
        );
    };

    return (
        <DropdownWrapper
            {...rest}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={(ref: any) => {
                menuButtonRef.current = ref;
            }}
            onClick={handleClick}
        >
            {triggerButton}
            {getDesktopView()}
        </DropdownWrapper>
    );
};

export type DropdownItemWithSvgProp = {
    svg: string;
    onClick: () => void;
    children?: React.ReactNode;
    dataCy?: string;
};

export const DropdownItemWithSvg: React.FC<DropdownItemWithSvgProp> = ({ svg = '', onClick, children, dataCy }) => {
    const handleClick = (evt: any) => {
        evt.preventDefault();
        if (onClick) {
            onClick();
        }
    };

    return (
        <DropdownItemWrapper data-cy={dataCy} onClick={handleClick}>
            <SVGWrapper>
                <StyledSVGInline src={svg} />
            </SVGWrapper>
            <DropdownLabel>{children}</DropdownLabel>
        </DropdownItemWrapper>
    );
};

export type DropdownItemSimpleProp = {
    onClick: () => void;
};

export const DropdownItemSimple: React.FC<DropdownItemSimpleProp> = ({ onClick, children }) => {
    const handleClick = (evt: any) => {
        evt.preventDefault();
        if (onClick) {
            onClick();
        }
    };

    return (
        <DropdownItemSimpleText onClick={handleClick}>
            <DropdownLabel>{children}</DropdownLabel>
        </DropdownItemSimpleText>
    );
};
