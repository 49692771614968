import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { RadioButtonWrapper, RadioLabel, RadioText } from './GenericDialog.css';
const theme: any = getTheme();

export const RemoveObjectSubtitleWrapper = styled.div`
    display: flex;
    gap: 16px;
`;

export const RemoveObjectSubtitle = styled.div`
    ${fontSizesCSS.Caption1};
`;

export const RemoveObjectIcon = styled.div``;

export const RemoveObjectMainSection = styled.div`
    min-width: 50%;
    overflow-wrap: break-word;
`;

export const RemoveObjectSelectSection = styled.div`
    margin-top: 16px;

    ${RadioButtonWrapper} {
        ${RadioLabel} {
            min-width: 12px;
        }

        ${RadioText} {
            min-width: 50%;
        }
        font-weight: unset;
        ${fontSizesCSS.Caption3};
        margin-bottom: 8px;
    }
`;

export const BoldedTitle = styled.span`
    font-weight: bold;
`;
