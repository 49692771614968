import React, { useEffect } from 'react';
import { docsWidget } from '../../App';
import { NewsDescription, NewsSubtitle, NewsTitle, NewsWarning, NewsWarningText, NewsWarningTitle, NewsWrapper } from './News.css';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { CCSettingsState, fetchCCSettings } from '../../../redux/slices/ccSettingsSlice';
import SVGInline from 'react-inlinesvg';
import icons from '../../../style';

const News = () => {
    const { ccSettings }: CCSettingsState = useAppSelector((state) => state.ccSettings);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCCSettings());
    }, []);

    return (
        <NewsWrapper
            onClick={() => {
                if (!docsWidget) return;
                !!ccSettings.updatesWidget.hyperlink.length
                    ? docsWidget.setSlug(ccSettings.updatesWidget.hyperlink, '', true)
                    : docsWidget.setSlug('', 'control-center-news');
                docsWidget.element?.click();
            }}
        >
            <NewsTitle>
                <span>Updates:</span> {ccSettings.updatesWidget.title}
            </NewsTitle>
            <NewsSubtitle>{ccSettings.updatesWidget.text}</NewsSubtitle>
            <NewsDescription>Click here to open the news section of the documentation.</NewsDescription>
            {!!ccSettings.updatesWidget.warningMessage.length && (
                <NewsWarning>
                    <NewsWarningTitle>
                        <SVGInline src={icons.warningWhiteIcon} /> Warning Message:
                    </NewsWarningTitle>
                    <NewsWarningText>{ccSettings.updatesWidget.warningMessage}</NewsWarningText>
                </NewsWarning>
            )}
        </NewsWrapper>
    );
};

export default News;
