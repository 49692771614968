import { FC, useEffect, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { deleteFile, fetchFiles, FilesState, uploadFilesSync } from '../../../redux/slices/fileManagerSlice';
import icons from '../../../style';
import { ApplicationWrapper, MainContentWrapper } from '../../../style/styled-components/reusable.css';
import ScreenTitle from '../../common/DashboardTitle/ScreenTitle';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import { DialogFileField, DialogTextField } from '../../common/Dialog/GenericDialog';
import { Loader } from '../../common/Loader/Loader';
import { ImageFieldContainer, ImageFields, ImageHeader } from '../../StyleAndBranding/PageStyle/PageStyle.css';
import Sidebar from '../Sidebar/Sidebar';
import { pageStyleImageInfo } from '../../StyleAndBranding/PageStyle/PageStyle';
import { MultilineTextFieldWrapper, SectionContainer, SectionTitle } from './Branding.css';
import { CCSettingsState, fetchCCSettings, updateCCSettings } from '../../../redux/slices/ccSettingsSlice';
import PageActions from '../../common/PageActions/PageActions';

export const Branding: FC = () => {
    const { files, loading: filesLoading, error }: FilesState = useAppSelector((state) => state.files);
    const { ccSettings, loading: ccSettingsLoading }: CCSettingsState = useAppSelector((state) => state.ccSettings);
    const { config } = useAppSelector((state) => state.config);
    const loading = filesLoading || ccSettingsLoading;

    const [imageUrlStamp, setImageUrlStamp] = useState(Date.now);
    const [loginImagePath, setLoginImagePath] = useState<string>('');

    const [updatesWidgetTitle, setUpdatesWidgetTitle] = useState<string>('');
    const [updatesWidgetText, setUpdatesWidgetText] = useState<string>('');
    const [updatesWidgetHyperlink, setUpdatesWidgetHyperlink] = useState<string>('');
    const [updatesWidgetWarningMessage, setUpdatesWidgetWarningMessage] = useState<string>('');

    const LOGIN_BACKGROUND_URL = `${config.imgixBaseUrl}login_background/login.gif`;
    const LOGIN_BACKGROUND_PREFIX = `${config.imgixBaseUrl}login_background`;

    const dispatch = useDispatch();
    const showUnsaved =
        updatesWidgetTitle !== ccSettings.updatesWidget?.title ||
        updatesWidgetText !== ccSettings.updatesWidget?.text ||
        updatesWidgetHyperlink !== ccSettings.updatesWidget?.hyperlink ||
        updatesWidgetWarningMessage !== ccSettings.updatesWidget?.warningMessage;

    const loadFiles = async () => {
        return await dispatch(fetchFiles({ prefix: LOGIN_BACKGROUND_PREFIX })).unwrap();
    };

    const removeFile = async (originalPath: string) => {
        const deletedFile = await dispatch(deleteFile(originalPath)).unwrap();
        if (deletedFile) {
            setLoginImagePath('');
            loadFiles();
        }
    };

    useEffect(() => {
        loadFiles();
        dispatch(fetchCCSettings());
    }, []);

    useEffect(() => {
        if (ccSettings) {
            setUpdatesWidgetTitle(ccSettings.updatesWidget.title);
            setUpdatesWidgetText(ccSettings.updatesWidget.text);
            setUpdatesWidgetHyperlink(ccSettings.updatesWidget.hyperlink);
            setUpdatesWidgetWarningMessage(ccSettings.updatesWidget.warningMessage);
        }
    }, [ccSettings]);

    useEffect(() => {
        if (!files?.length) return;

        setLoginImagePath(files[0].originalPath || '');
    }, [files]);

    const createFile = async (file: File) => {
        const name = `login.gif`;
        const newFile = new File([file], name, { type: file.type });

        try {
            const response = await dispatch(
                uploadFilesSync({ files: [newFile], prefix: LOGIN_BACKGROUND_PREFIX, overwrite: true })
            ).unwrap();
            loadFiles();
            setImageUrlStamp(Date.now());
            return response.urls;
        } catch (ex) {
            return [];
        }
    };

    const onSave = async () => {
        const data = {
            updatesWidget: {
                title: updatesWidgetTitle,
                text: updatesWidgetText,
                hyperlink: updatesWidgetHyperlink,
                warningMessage: updatesWidgetWarningMessage
            }
        };

        const response = await dispatch(updateCCSettings(data)).unwrap();
        if (response) {
            await dispatch(fetchCCSettings());
        }
    };

    const onCancel = () => {
        setUpdatesWidgetTitle(ccSettings.updatesWidget.title);
        setUpdatesWidgetText(ccSettings.updatesWidget.text);
        setUpdatesWidgetHyperlink(ccSettings.updatesWidget.hyperlink);
        setUpdatesWidgetWarningMessage(ccSettings.updatesWidget.warningMessage);
    };

    const renderLoginBackgroundSection = () => {
        return (
            <ImageFieldContainer>
                <ImageHeader>Login Screen Background</ImageHeader>
                <ImageFields>
                    <DialogFileField
                        imageInfo={pageStyleImageInfo}
                        customAddIcon={icons.addIconWhite}
                        preview={loginImagePath ? `${LOGIN_BACKGROUND_URL}?timestamp=${imageUrlStamp}` : undefined}
                        fieldKey={'Login Background'}
                        customLabel={'Login Background' + (!!loginImagePath ? '' : ' (Currently default image is displayed)')}
                        localFileCallBack={(file) => {
                            if (!file) {
                                loginImagePath && removeFile(loginImagePath);
                            } else {
                                createFile(file);
                            }
                        }}
                        localFileOnly
                        alwaysShowLabel
                        withoutPreview
                        accepts={['.jpg', '.png', '.gif']}
                    />
                </ImageFields>
            </ImageFieldContainer>
        );
    };

    const renderDashboardUpdatesWidget = () => {
        return (
            <SectionContainer>
                <SectionTitle>Dashboard Updates Widget</SectionTitle>
                <DialogTextField
                    label={'Headline'}
                    placeholder={'Add Title'}
                    value={updatesWidgetTitle}
                    onChange={(e: any) => setUpdatesWidgetTitle(e.target.value)}
                />
                <DialogTextField
                    label={'Hyperlink'}
                    placeholder={'Hyperlink'}
                    value={updatesWidgetHyperlink}
                    onChange={(e: any) => setUpdatesWidgetHyperlink(e.target.value)}
                />
                <MultilineTextFieldWrapper>
                    <DialogTextField
                        multiline
                        label={'Text'}
                        placeholder={'Text'}
                        value={updatesWidgetText}
                        onChange={(e: any) => setUpdatesWidgetText(e.target.value)}
                    />
                </MultilineTextFieldWrapper>
                <MultilineTextFieldWrapper>
                    <DialogTextField
                        multiline
                        label={'Warning Message'}
                        placeholder={'Warning Message'}
                        value={updatesWidgetWarningMessage}
                        onChange={(e: any) => setUpdatesWidgetWarningMessage(e.target.value)}
                    />
                </MultilineTextFieldWrapper>
            </SectionContainer>
        );
    };

    return (
        <>
            {error && <BackendErrorDialog error={error} />}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle loading={loading} title="CC Settings" withProfile withoutSearch />
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            {renderLoginBackgroundSection()}
                            {renderDashboardUpdatesWidget()}
                        </>
                    )}
                    <PageActions onSave={() => onSave()} onCancel={onCancel} disabled={{ save: !showUnsaved }} />
                </MainContentWrapper>
            </ApplicationWrapper>
        </>
    );
};
