import React, { useEffect, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import GenericDialog, { DialogTypes } from '../../common/Dialog/GenericDialog';
import icons from '../../../style';
import { useNavigate } from 'react-router-dom';

import { CreateResourceCardsContainer, CreateResourceIconContainer, TemplateCard, TemplateName } from './CreateResourceDialog.css';

export type CreateResourceProps = {
    title: string;
    open: boolean;
    onClose: any;
    historyUrl: string;
    handleOpenTemplateClick?: () => void;
    handleCreateNewResourceClick?: () => void;
    withSelectExisting?: boolean;
    withoutSelectTemplate?: boolean;
    handleSelectExistingClick?: () => void;
};

const CreateResourceDialog: React.FC<CreateResourceProps> = ({
    title,
    open,
    onClose,
    historyUrl,
    handleOpenTemplateClick,
    handleCreateNewResourceClick,
    withSelectExisting,
    withoutSelectTemplate,
    handleSelectExistingClick
}) => {
    const [isOpen, setIsOpen] = useState(open);

    const navigate = useNavigate();

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const handleCloseClick = (evt: any) => {
        evt.preventDefault();
        if (onClose) {
            onClose();
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <GenericDialog
            title={`Select option for ${title}`}
            type={DialogTypes.Resource}
            onClose={handleCloseClick}
            dataCy={`create-resource-dialog-${title}`}
        >
            <CreateResourceCardsContainer>
                <TemplateCard
                    onClick={() => {
                        handleCreateNewResourceClick ? handleCreateNewResourceClick() : navigate(historyUrl);
                    }}
                    data-cy={`new-resource-button-${title}`}
                >
                    <CreateResourceIconContainer>
                        <SVGInline src={icons.addItemIconLight} />
                    </CreateResourceIconContainer>
                    <div>
                        <TemplateName>Create {title}</TemplateName>
                    </div>
                </TemplateCard>
                {withSelectExisting && (
                    <TemplateCard
                        data-cy={`existing-resource-button-${title}`}
                        onClick={() => handleSelectExistingClick && handleSelectExistingClick()}
                    >
                        <CreateResourceIconContainer>
                            <SVGInline src={icons.newResourceIcon} />
                        </CreateResourceIconContainer>
                        <div>
                            <TemplateName>Add Existing {title}</TemplateName>
                        </div>
                    </TemplateCard>
                )}
                {!withoutSelectTemplate && (
                    <TemplateCard
                        data-cy={`template-resource-button-${title}`}
                        onClick={() => handleOpenTemplateClick && handleOpenTemplateClick()}
                    >
                        <CreateResourceIconContainer>
                            <SVGInline src={icons.templateIcon} />
                        </CreateResourceIconContainer>
                        <div>
                            <TemplateName>Select Template</TemplateName>
                        </div>
                    </TemplateCard>
                )}
            </CreateResourceCardsContainer>
        </GenericDialog>
    );
};

export default CreateResourceDialog;
