import getTheme from '../../../../style/themes/theme';
import styled from 'styled-components';
import { ObjectNameTooltipIconHolder } from '../Tooltips.css';
import { Link } from 'react-router-dom';

const theme: any = getTheme();
export const ObjectNameWrapper = styled.div`
    height: 36px;
    display: flex;
    align-items: center;
    position: relative;
`;

export const ObjectName = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const BlueObjectName = styled(ObjectName)`
    color: ${theme.palette.primary.main};
`;

export const TooltipTrigger = styled.div`
    position: absolute;
    height: 6px;
`;

export const SVGWrapper = styled(ObjectNameTooltipIconHolder)`
    display: flex;
    align-items: center;

    svg {
        width: 14px;
        height: 14px;
    }
`;

export const TooltipLink = styled(Link)`
    color: ${theme.palette.white.main};
    text-decoration: none;
    font-weight: bold;
`;
