import { FC, useEffect, useState } from 'react';
import {
    ApplicationWrapper,
    MainContentWrapper,
    NoResourcesContainer,
    PageActionButton,
    PageActionsWrapper,
    TruncatedText
} from '../../style/styled-components/reusable.css';
import Sidebar from '../common/Sidebar/Sidebar';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import PageActions from '../common/PageActions/PageActions';
import { PUBLISHED_STATUS } from '../../utils/fnPublish';
import GenericTable, { HeaderTableCell, SortableHeaderTableCell, tableActions } from '../common/Table/Table';
import { SettingsTableSizes } from '../../types/TableSizes';
import {
    ACCEPTED_SORT_FIELDS,
    AcceptedSortField,
    DEFAULT_SORT_CONFIG,
    ISortConfig,
    calculateOrderByFromSortConfig
} from '../../utils/fnSort';
import { generateDateStringForTables } from '../../utils/fnDate';
import { TableRowWrapper, WidthTableCell } from '../common/Table/Table.css';
import { DialogTextField, DialogToggleButton } from '../common/Dialog/GenericDialog';
import { TargetConditionsSelect } from '../common/Select/TargetConditionsSelect';
import { ABTestingGroupSelect } from '../common/Select/ABTestingGroupSelect';
import { MoreInfoTypes } from '../common/Dialog/MoreInfoDialog';
import { PageRoutes, buildPathWithProjectId } from '../../types/RouteTypes';
import { withScroll } from '../../HOCs/ScrollableWrapper/ScrollableWrapper';
import { API_ERROR_CODES, EMPTY_WORD_STRING } from '../../utils/Globals';
import Labels from '../common/Labels/Labels';
import { ObjectTypes } from '../../types/Object';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import icons from '../../style';
import { ObjectActions } from '../common/Actions/Actions';
import useScreenSize from '../../hooks/useScreenSize';
import { TableRow, Tooltip } from '@material-ui/core';
import {
    FeatureFlagEditWrapper,
    FeatureFlagFieldWrapper,
    FeatureFlagTitle,
    FlagSection,
    FlagSubsection,
    FlagsExpandedRow,
    FlagsSectionWrapper,
    FlagsSubsectionName
} from './FeatureFlags.css';
import { AbTestingSelectWrapper, LastModified } from '../Settings/Settings.css';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import { DEFAULT_FLAGS, FEATURE_FLAGS_TOOLTIPS, FeatureFlag, Flags, RecordingsKeys, UserStatusKeys } from '../../types/FeatureFlags';
import { renderTooltipWithKey } from '../common/Tooltips/Tooltips';
import SVGInline from 'react-inlinesvg';
import {
    abortPublishFeatureFlag,
    createFeatureFlag,
    deleteFeatureFlag,
    featureFlagState,
    fetchFeatureFlags,
    publishFeatureFlag,
    unsetFeatureFlags,
    updateFeatureFlag
} from '../../redux/slices/featureFlagSlice';
import { DIALOG_NAMES, ToastAlert, dialogConfirm, handleCheckBeforeSave } from '../../utils/fnDialogs';
import { ObjectFilter } from '../../utils/fnFilter';
import { setUserPermissions } from '../../redux/slices/permissionsSlice';
import { audiencesState, fetchAudiences } from '../../redux/slices/audienceSlice';
import { fetchDisplayConditions } from '../../redux/slices/displayConditionsSlice';
import { fetchTargetGroups, targetGroupsState } from '../../redux/slices/targetGroupsSlice';
import _ from 'lodash';
import { Loader } from '../common/Loader/Loader';
import { RemoveModuleWrapper } from '../Modules/Modules.css';
import useLockSystem, { LockableObjectTypes } from '../../hooks/useLockSystem';
import { renderABIcon, renderLockIcon, renderLockedError, renderLockedWarningAlert } from '../../utils/fnLockingSystem';
import { SearchBarContainer } from '../common/SearchBar/SearchBar.css';
import { SearchBarWrapper } from '../PaginationWrapper/PaginationWrapper.css';
import { FancyFilter, searchTermUnsetValue } from '../common/Select/FancyFilter';
import { ResetCallbackProps, renderUnsavedAlertBeforeAction } from '../PaginationWrapper/PaginationWrapper';
import useBlockNavigation from '../../hooks/useBlockNavigation';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { ResourceCard } from '../Cards/ResourceCard/ResourceCard';
import { resourceCardImages } from '../../assets/images/resourceCards';
import { openDocumentation } from '../../utils/parsers';
import circleSlugs from '../../utils/circle_slugs.json';
import { getSearchParam } from '../../utils/fnUrl';

export const ScrollableFlagsContainer = withScroll(TableRowWrapper);

const FeatureFlags: FC<any> = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { isDesktop, isMobile, isLargeDesktop } = useScreenSize();
    const { lock, unlock, unlockOnClose, isObjectLocked, objectIsLockedBy } = useLockSystem(LockableObjectTypes.FEATURE_FLAGS);

    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { featureFlags: storeFeatureFlags, loading, error }: featureFlagState = useAppSelector((state) => state.featureFlags);
    const { targetGroups }: targetGroupsState = useAppSelector((state) => state.targetGroups);
    const { audiences: storeAudiences }: audiencesState = useAppSelector((state) => state.audiences);

    const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);
    const [creatingFlag, setCreatingFlag] = useState<boolean>(false);
    const [featureFlagToEdit, setFeatureFlagToEdit] = useState<Partial<FeatureFlag> | undefined>(undefined);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [expandedFlagKeys, setExpandedFlagKeys] = useState<{ [key: string]: boolean } | null>(null);
    //used for redirect
    const [flagId, setFlagId] = useState<string>('');

    // SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [activeSortingKey, setActiveSortingKey] = useState<AcceptedSortField>(ACCEPTED_SORT_FIELDS.lastModified);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [activeObjectFilter, setActiveObjectFilter] = useState<ObjectFilter | undefined>(undefined);

    const [showUnsaved, setShowUnsaved] = useState<boolean>(false);

    useBlockNavigation(showUnsaved, () => renderAlertUnsavedChanges(), [featureFlagToEdit]);

    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;

    const loadFeatureFlags = async (
        addPermissions?: boolean,
        projectId?: string,
        orderBy?: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ) => {
        setFeatureFlags([]);
        if (storeFeatureFlags.length && getSearchParam('id')) {
            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.FEATURE_FLAGS));
        }
        // if redirected, reset the search term and filter after any action
        const isRedirected = !!activeObjectFilter?._id;
        if (isRedirected) {
            setActiveObjectFilter(undefined);
            setSearchTerm('');
        } else {
            // there might be a searchTerm in the fancy filter but a search was not conducted, so we need to clear the searchTerm inside the fancy filter
            // to do that we assign the searchTerm a special value which will be used in the fancy filter to clear both search terms
            const flagId = location?.state?.editingId || getSearchParam('id');
            if (!flagId && !searchTerm) setSearchTerm(searchTermUnsetValue);
        }
        return await dispatch(
            fetchFeatureFlags({
                addPermissions,
                projectId,
                orderBy,
                ...(!isRedirected && { searchTerm }),
                ...(!isRedirected && { filter })
            })
        ).unwrap();
    };

    const _publishFeatureFlag = async (id: string) => {
        await dispatch(publishFeatureFlag({ featureFlagId: id })).unwrap();
        if (!showUnsaved) {
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.FEATURE_FLAG_PUBLISHED);
        }
    };

    const _abortPublishFeatureFlag = async (id: string) => {
        const response = await dispatch(abortPublishFeatureFlag({ featureFlagId: id })).unwrap();
        if (response.ok) {
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.FEATURE_FLAG_ABORT_PUBLISH);
        }
        return response;
    };

    const saveFeatureFlags = async (featureFlag: FeatureFlag, reload: boolean = true, addObjectToGroup?: boolean, publishing?: boolean) => {
        const response = await dispatch(createFeatureFlag({ featureFlag, addObjectToGroup })).unwrap();
        if (response.id) {
            ToastAlert(
                'success',
                '',
                '',
                undefined,
                publishing
                    ? featureFlag.publishAt
                        ? DIALOG_NAMES.FEATURE_FLAG_SCHEDULED_PUBLISH_AND_SAVED
                        : DIALOG_NAMES.FEATURE_FLAG_PUBLISHED_AND_SAVED
                    : DIALOG_NAMES.FEATURE_FLAG_CREATED
            );
            loadTargetGroups();
            resetCallback();
            reload && loadFeatureFlags(false, activeProjectId, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG));
        }
        return response.id;
    };

    const modifyFeatureFlags = async (
        featureFlag: FeatureFlag,
        reload: boolean = true,
        addObjectToGroup?: boolean,
        publishing?: boolean
    ) => {
        const response = await dispatch(updateFeatureFlag({ featureFlag, shouldUnlockAfterSave: true, addObjectToGroup })).unwrap();
        const showScheduledPublishAlert = featureFlag.publishAt && featureFlag.publishAt !== featureFlagToEdit?.publishAt;
        if (response.id) {
            showScheduledPublishAlert
                ? ToastAlert('success', '', '', icons.scheduledPublishWhiteIcon, DIALOG_NAMES.FEATURE_FLAG_SCHEDULED_PUBLISH_AND_SAVED)
                : ToastAlert(
                      'success',
                      '',
                      '',
                      undefined,
                      publishing ? DIALOG_NAMES.FEATURE_FLAG_PUBLISHED_AND_SAVED : DIALOG_NAMES.FEATURE_FLAG_UPDATED
                  );
            loadTargetGroups();
            if (reload) {
                resetCallback({ visualEditor: true, filterObject: true, searchTerm: true });
                loadFeatureFlags(
                    false,
                    activeProjectId,
                    calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG),
                    searchTerm,
                    activeObjectFilter
                );
            }
        }
        return response.id;
    };

    const removeFeatureFlags = async (id: string) => {
        const response = await dispatch(deleteFeatureFlag(id)).unwrap();
        if (response.ok) {
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.FEATURE_FLAG_DELETED);
            loadFeatureFlags(false, activeProjectId, orderBy, searchTerm, activeObjectFilter);
            resetCallback({ searchTerm: true, sortConfig: true, filterObject: true });
        }
    };

    const loadAudiences = async () => {
        return await dispatch(fetchAudiences({ addPermissions: false, projectId: activeProjectId }));
    };
    const loadConditions = async () => {
        return await dispatch(fetchDisplayConditions({ addPermissions: false, projectId: activeProjectId }));
    };
    const loadTargetGroups = () => {
        return dispatch(fetchTargetGroups({ projectId: activeProjectId }));
    };

    const resetCallback = (keep?: ResetCallbackProps) => {
        !keep?.searchTerm && setSearchTerm('');
        !keep?.sortConfig && setActiveSortingKey(DEFAULT_SORT_CONFIG.field as AcceptedSortField);
        !keep?.sortConfig && setSortConfig(DEFAULT_SORT_CONFIG);
        !keep?.filterObject && setActiveObjectFilter(undefined);
        if (!keep?.visualEditor) {
            featureFlagToEdit?._id && unlock(featureFlagToEdit?._id);
            setFeatureFlagToEdit(undefined);
            setFlagId('');
        }
    };

    const lockFeatureFlags = (id: string) => {
        lock(id);
        unlockOnClose(id);
    };

    const handleCreateClick = () => {
        featureFlagToEdit?._id && unlock(featureFlagToEdit._id);
        setCreatingFlag(true);
        setFeatureFlagToEdit({ flags: DEFAULT_FLAGS });
    };

    const handleValueChange = (value: any, key: keyof FeatureFlag, flagSection?: keyof Flags, flagKey?: string) => {
        if (key === 'flags') {
            if (!flagSection || !flagKey) return;
            const flags: any = _.cloneDeep(featureFlagToEdit?.flags || {});
            flags[flagSection][flagKey] = value;
            setFeatureFlagToEdit((prev) => {
                return { ...prev, flags };
            });
            return;
        }
        setFeatureFlagToEdit((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const handleSaveClick = (publishAt?: number, reload?: boolean, publishing?: boolean): Promise<string> | undefined => {
        if (!featureFlagToEdit) return;
        const oldFlag = featureFlags.find((f) => f._id === featureFlagToEdit._id) || null;

        return new Promise((resolve) => {
            const onSave = async (addObjectToGroup?: boolean) => {
                const id = await handleSave(publishAt, reload, addObjectToGroup, publishing);
                resolve(id || '');
            };

            handleCheckBeforeSave(storeAudiences, targetGroups, oldFlag, featureFlagToEdit, 'featureFlags', onSave);
        });
    };

    const handleSave = (publishAt?: number, reload?: boolean, addObjectToGroup?: boolean, publishing?: boolean) => {
        if (!featureFlagToEdit) return;
        const featureFlag: FeatureFlag = {
            _id: featureFlagToEdit?._id || '',
            name: featureFlagToEdit?.name || '',
            tenantId: activeTenantId || '',
            projectId: activeProjectId || '',
            publishAt: publishAt,
            conditionIds: featureFlagToEdit?.conditionIds || [],
            abTestingGroupIds: featureFlagToEdit?.abTestingGroupIds || [],
            flags: featureFlagToEdit?.flags,
            lastModified: featureFlagToEdit?.lastModified
        };

        if (publishAt) {
            featureFlag.publishAt = publishAt;
        }

        handleCancelClick();
        return creatingFlag
            ? saveFeatureFlags(featureFlag, reload, addObjectToGroup, publishing)
            : modifyFeatureFlags(featureFlag, reload, addObjectToGroup, publishing);
    };
    const handleAbortPublishClick = async (id: string = '') => {
        await _abortPublishFeatureFlag(id);
        loadFeatureFlags(false, activeProjectId, orderBy, searchTerm, activeObjectFilter);
        handleCancelClick();
    };

    const handlePublishClick = async () => {
        // If there are changes we save first then publish the new menu!
        let featureId;
        if (showUnsaved) {
            // when CREATING a flag, the ID will be returned and we use it for publishing
            featureId = await handleSaveClick(undefined, false, true);
            if (!featureId) return;
        }
        await _publishFeatureFlag(featureId || featureFlagToEdit?._id || '');
        creatingFlag && resetCallback();
        loadFeatureFlags(
            false,
            activeProjectId,
            creatingFlag ? '' : orderBy,
            creatingFlag ? '' : searchTerm,
            creatingFlag ? undefined : activeObjectFilter
        );
        handleCancelClick();
    };

    const handleCancelClick = () => {
        if (!creatingFlag && featureFlagToEdit?._id) {
            unlock(featureFlagToEdit._id);
        }
        setFeatureFlagToEdit(undefined);
        setIsEditing(false);
        setCreatingFlag(false);
        setExpandedFlagKeys(null);
        setFlagId('');
    };

    const handleOnSearch = (filter: ObjectFilter | string) => {
        if (typeof filter === 'string') {
            setSearchTerm(filter);
            loadFeatureFlags(false, activeProjectId, orderBy, filter);
        } else {
            if (!Object.keys(filter).length) resetCallback({ sortConfig: true });
            setActiveObjectFilter(filter);
            loadFeatureFlags(true, activeProjectId, orderBy, '', filter);
        }
    };

    const handleDeleteClick = (id: string) => {
        const values = {
            title: 'Remove Feature Flags',
            text: ''
        };

        dialogConfirm(
            '',
            async () => {
                await removeFeatureFlags(id);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to remove this Feature Flags?</strong>
                    <br />
                    By Pressing “Remove” you still will be able to create new one from list
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    const calculateShowUnsaved = () => {
        if (creatingFlag) return true;

        if (featureFlagToEdit) {
            const featureFlag = featureFlags.find((flag) => flag._id === featureFlagToEdit._id);
            return JSON.stringify(featureFlag) !== JSON.stringify(featureFlagToEdit);
        }

        return false;
    };

    useEffect(() => {
        setShowUnsaved(calculateShowUnsaved());
    }, [featureFlagToEdit]);

    useEffect(() => {
        // when changing between projects or onLoad, we cancel any editing state
        handleCancelClick();
        if (!activeProjectId) return;
        resetCallback();

        if (storeFeatureFlags.length) {
            dispatch(unsetFeatureFlags());
        }
        const flagId = location?.state?.editingId || getSearchParam('id');

        let filter: ObjectFilter = {};
        if (flagId) {
            filter._id = [flagId];
            setActiveObjectFilter(filter);
            setFlagId(flagId);
            window.history.replaceState({}, '');
        }

        loadFeatureFlags(true, activeProjectId, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG), '', filter).then((response: any) => {
            if (response.permissions) {
                dispatch(setUserPermissions(response.permissions));
            }
        });
        loadAudiences();
        loadConditions();
        loadTargetGroups();
    }, [activeProjectId]);

    useEffect(() => {
        if (loading || error) return;
        setFeatureFlags([...storeFeatureFlags]);
    }, [loading]);

    useEffect(() => {
        if (loading) return;
        if (flagId) {
            const flag = featureFlags.find((f) => f._id === flagId);
            if (!flag) return;
            const isLocked = isObjectLocked(flag);
            const isLockedBy = objectIsLockedBy(flag);
            isLocked && renderLockedWarningAlert(isLockedBy);
            if (!isLocked) {
                setIsEditing(true);
                lockFeatureFlags(flagId);
            }
            if (activeObjectFilter?._id) {
                setSearchTerm(flag.name || `${EMPTY_WORD_STRING} ID: ${flag._id.substring(0, 8)}...`);
            }
            setFeatureFlagToEdit(_.cloneDeep(flag));
        }
    }, [featureFlags]);

    const renderAlertUnsavedChanges = () => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => {
                handleSaveClick();
            },
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                handleCancelClick();
            },
            true
        );
    };

    const expandFeatureFlags = (featureFlag: FeatureFlag) => {
        if (featureFlag._id === featureFlagToEdit?._id) {
            unlock(featureFlag._id);
            setFeatureFlagToEdit(undefined);
            setIsEditing(false);
        } else {
            lockFeatureFlags(featureFlag._id);
            setFeatureFlagToEdit(featureFlag);
        }
        setCreatingFlag(false);
        setExpandedFlagKeys(null);
    };

    const handleSortIconClick = (field: AcceptedSortField) => {
        if (showUnsaved) return renderUnsavedAlertBeforeAction();

        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            field,
            direction
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadFeatureFlags(false, activeProjectId, orderBy, searchTerm, activeObjectFilter);
        setShowSortArrows(false);
        setFlagId('');
    };

    const renderCreatingFeatureFlags = () => {
        const expanded = true;
        return (
            <>
                <TableRow style={{ height: '16px' }} />
                <ScrollableFlagsContainer shouldScroll={creatingFlag} key={'flag_create'} id={'create-flag-container'}>
                    <WidthTableCell colSpan={4} $um={'px'}>
                        <FeatureFlagEditWrapper>
                            <FeatureFlagFieldWrapper>
                                <DialogTextField
                                    label={'Feature Flag Name'}
                                    value={featureFlagToEdit?.name || ''}
                                    onChange={(evt: any) => {
                                        handleValueChange(evt.target.value, 'name');
                                    }}
                                    placeholder={'Feature Flag Name'}
                                />
                            </FeatureFlagFieldWrapper>
                            <FeatureFlagFieldWrapper>
                                <TargetConditionsSelect
                                    targets={featureFlagToEdit?.conditionIds || []}
                                    onChange={(val) => {
                                        handleValueChange(val, 'conditionIds');
                                    }}
                                />
                            </FeatureFlagFieldWrapper>
                            <AbTestingSelectWrapper>
                                <ABTestingGroupSelect
                                    selectedGroups={featureFlagToEdit?.abTestingGroupIds || []}
                                    selectedTargetConditions={featureFlagToEdit?.conditionIds}
                                    onChange={(val) => {
                                        handleValueChange(val, 'abTestingGroupIds');
                                    }}
                                />
                            </AbTestingSelectWrapper>
                        </FeatureFlagEditWrapper>
                    </WidthTableCell>
                    <WidthTableCell {...SettingsTableSizes.actions}>
                        <ObjectActions
                            actions={[tableActions.REMOVE]}
                            withArrow
                            onRemove={() => {
                                showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick();
                            }}
                            open={expanded}
                            onArrowToggle={() => {
                                if (showUnsaved) return renderAlertUnsavedChanges();
                                handleCancelClick();
                            }}
                            tooltipTexts={{
                                delete: 'feature_flag_icon_delete',
                                arrowClose: 'feature_flag_icon_hide',
                                arrowOpen: 'feature_flag_icon_show'
                            }}
                        />
                    </WidthTableCell>
                </ScrollableFlagsContainer>

                {/* SUBSECTIONS TABLE ROW */}
                {expanded && (
                    <>
                        <TableRowWrapper>
                            <WidthTableCell style={{ padding: 0 }} colSpan={5} $um={'px'}>
                                {renderFlags(featureFlagToEdit as FeatureFlag)}
                            </WidthTableCell>
                        </TableRowWrapper>
                    </>
                )}
            </>
        );
    };

    const renderFlags = (featureFlag: FeatureFlag) => {
        const isLocked = isObjectLocked(featureFlag);
        const isLockedBy = objectIsLockedBy(featureFlag);
        return (
            <FlagsSectionWrapper>
                {Object.keys(featureFlag.flags || {}).map((flagKey) => {
                    const expandedFlag = expandedFlagKeys?.[flagKey];
                    const tooltipText = expandedFlag ? `feature_flag_${flagKey}_icon_hide` : `feature_flag_${flagKey}_icon_show`;
                    return (
                        <>
                            <FlagSection key={flagKey}>
                                <FlagsSubsectionName
                                    onClick={() => {
                                        isLocked && !expandedFlagKeys?.[flagKey] && renderLockedWarningAlert(isLockedBy);
                                        setExpandedFlagKeys({ ...expandedFlagKeys, [flagKey]: !expandedFlagKeys?.[flagKey] });
                                    }}
                                >
                                    {_.startCase(flagKey)}
                                </FlagsSubsectionName>
                                {renderTooltipWithKey(
                                    <SVGInline
                                        src={expandedFlag ? icons.arrowUpIcon : icons.arrowDownIcon}
                                        onClick={() => {
                                            isLocked && !expandedFlagKeys?.[flagKey] && renderLockedWarningAlert(isLockedBy);
                                            setExpandedFlagKeys({ ...expandedFlagKeys, [flagKey]: !expandedFlagKeys?.[flagKey] });
                                        }}
                                    />,
                                    tooltipText
                                )}
                            </FlagSection>
                            {expandedFlag && <div>{renderExpandedFlag(flagKey, featureFlag.flags)}</div>}
                        </>
                    );
                })}
            </FlagsSectionWrapper>
        );
    };

    const renderExpandedFlag = (key: string, flags?: Flags) => {
        if (!flags) return <></>;
        const expandedFlags = flags[key as keyof Flags] || {};

        return (Object.keys(expandedFlags) as Array<keyof typeof expandedFlags>).map((flag) => {
            const toggleValue = expandedFlags[flag] || false;
            const toggleTooltip = FEATURE_FLAGS_TOOLTIPS?.[flag] || '';
            return (
                <FlagsExpandedRow key={flag}>
                    <FlagSubsection>
                        <DialogToggleButton
                            checked={toggleValue}
                            text={_.startCase(flag)}
                            toggleCallback={() => {
                                handleValueChange(!toggleValue, 'flags', key as keyof Flags, flag);
                            }}
                        />
                        {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, toggleTooltip)}
                    </FlagSubsection>
                </FlagsExpandedRow>
            );
        });
    };

    const buildTableColumns = () => {
        const columns = [
            <SortableHeaderTableCell
                key={`name_cell`}
                text={'Feature Flag Name'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.name}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.name)}
                onMouseEnter={() => setShowSortArrows(!showSortArrows)}
                onMouseLeave={() => setShowSortArrows(!showSortArrows)}
                columnSize={SettingsTableSizes['name']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.name && sortConfig?.direction) || 'asc'}
            />,
            <HeaderTableCell key={'placed_cell'} text={'Part of Group(s)'} columnSize={SettingsTableSizes.placed} />,
            <HeaderTableCell key={'conditions_cell'} text={'Target Conditions'} columnSize={SettingsTableSizes.conditions} />,
            <SortableHeaderTableCell
                key={`last_modified_cell`}
                text={'Last Modified'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.lastModified}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.lastModified)}
                onMouseEnter={() => setShowSortArrows(!showSortArrows)}
                onMouseLeave={() => setShowSortArrows(!showSortArrows)}
                columnSize={SettingsTableSizes['lastModified']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.lastModified && sortConfig?.direction) || 'asc'}
            />
        ];
        return columns;
    };

    const buildTableBody = () => {
        return (
            <>
                {featureFlags?.map((flag, index) => {
                    const dateString = generateDateStringForTables(flag.lastModified || 0);
                    const isLocked = isObjectLocked(flag);
                    const isLockedBy = objectIsLockedBy(flag);
                    const isLast = FeatureFlags.length - 1 === index;
                    const expanded = flag._id === featureFlagToEdit?._id;

                    return (
                        <>
                            <ScrollableFlagsContainer key={flag._id} shouldScroll={flagId === flag._id}>
                                {/* SETTING TITLE TABLE CELL */}
                                {/*In case of editing, the input field is rendered*/}
                                {expanded && isEditing ? (
                                    <WidthTableCell colSpan={4} $um={'px'}>
                                        <FeatureFlagEditWrapper>
                                            <FeatureFlagFieldWrapper>
                                                <DialogTextField
                                                    label={'Feature Flag Name'}
                                                    value={featureFlagToEdit?.name || ''}
                                                    onChange={(evt: any) => {
                                                        handleValueChange(evt.target.value, 'name');
                                                    }}
                                                    placeholder={'Feature Flag Name'}
                                                />
                                            </FeatureFlagFieldWrapper>
                                            <FeatureFlagFieldWrapper>
                                                <TargetConditionsSelect
                                                    targets={featureFlagToEdit?.conditionIds || []}
                                                    onChange={(val) => {
                                                        handleValueChange(val, 'conditionIds');
                                                    }}
                                                />
                                            </FeatureFlagFieldWrapper>
                                            <AbTestingSelectWrapper>
                                                <ABTestingGroupSelect
                                                    selectedTargetConditions={featureFlagToEdit?.conditionIds || []}
                                                    selectedGroups={featureFlagToEdit?.abTestingGroupIds || []}
                                                    onChange={(val) => {
                                                        handleValueChange(val, 'abTestingGroupIds');
                                                    }}
                                                />
                                            </AbTestingSelectWrapper>
                                        </FeatureFlagEditWrapper>
                                    </WidthTableCell>
                                ) : (
                                    <>
                                        {/*  SETTING NAME TABLE CELL */}
                                        <WidthTableCell {...SettingsTableSizes.name}>
                                            <FeatureFlagTitle
                                                onClick={() => {
                                                    if (showUnsaved) return renderAlertUnsavedChanges();
                                                    expandFeatureFlags(flag);
                                                    if (isLocked && !expanded) {
                                                        return renderLockedWarningAlert(isLockedBy);
                                                    }
                                                }}
                                            >
                                                <TruncatedText>{flag.name || EMPTY_WORD_STRING}</TruncatedText>
                                                {isLocked && renderLockIcon(isLockedBy)}
                                                {renderABIcon(flag)}
                                            </FeatureFlagTitle>
                                        </WidthTableCell>

                                        {/* PLACED TABLE CELL */}
                                        <WidthTableCell {...SettingsTableSizes.placed}>
                                            <Labels
                                                type={MoreInfoTypes.PLACED}
                                                values={flag.placed || []}
                                                noOfLabels={isLargeDesktop ? 4 : isDesktop ? 2 : isMobile ? 0 : 1}
                                                onClickLabel={(obj) => {
                                                    navigate(
                                                        buildPathWithProjectId(
                                                            activeProjectId,
                                                            PageRoutes.TARGET_GROUP.replace(':group_id', obj._id)
                                                        )
                                                    );
                                                }}
                                            />
                                        </WidthTableCell>

                                        {/* CONDITIONS TABLE CELL */}
                                        <WidthTableCell {...SettingsTableSizes.conditions}>
                                            <Labels
                                                type={MoreInfoTypes.TARGETS}
                                                values={flag?.conditions || []}
                                                noOfLabels={isDesktop ? 3 : isMobile ? 0 : 1}
                                                onClickLabel={(obj) => {
                                                    const isAudience = obj?.objectType === ObjectTypes.AUDIENCES;

                                                    navigate(
                                                        buildPathWithProjectId(
                                                            activeProjectId,
                                                            isAudience ? PageRoutes.AUDIENCES : PageRoutes.TARGET_CONDITIONS
                                                        ),
                                                        {
                                                            state: isAudience ? { audienceId: obj._id } : { conditionId: obj._id }
                                                        }
                                                    );
                                                }}
                                            />
                                        </WidthTableCell>

                                        {/* LAST MODIFIED TABLE CELL */}
                                        <WidthTableCell {...SettingsTableSizes.lastModified}>
                                            <LastModified>
                                                {dateString}
                                                <Tooltip title={flag?.modifiedByUser?.name || ''} placement="right-start">
                                                    <img src={flag?.modifiedByUser?.icon || icons.avatarIcon} alt={''} />
                                                </Tooltip>
                                            </LastModified>
                                        </WidthTableCell>
                                    </>
                                )}

                                {/* ACTIONS TABLE CELL */}
                                <WidthTableCell {...SettingsTableSizes.actions}>
                                    <ObjectActions
                                        actions={[tableActions.EDIT, tableActions.REMOVE]}
                                        withArrow
                                        onRemove={() => {
                                            if (isLocked) {
                                                return renderLockedWarningAlert(isLockedBy);
                                            }
                                            showUnsaved ? renderAlertUnsavedChanges() : handleDeleteClick(flag._id);
                                        }}
                                        onEdit={() => {
                                            if (showUnsaved && !expanded) return renderAlertUnsavedChanges();
                                            isLocked
                                                ? renderLockedWarningAlert(isLockedBy)
                                                : setIsEditing(flag._id === featureFlagToEdit?._id ? !isEditing : true);

                                            if (!expanded) {
                                                setFeatureFlagToEdit(flag);
                                                lockFeatureFlags(flag._id);
                                            }
                                        }}
                                        open={expanded}
                                        onArrowToggle={() => {
                                            if (showUnsaved) return renderAlertUnsavedChanges();
                                            expandFeatureFlags(flag);
                                            if (isLocked && !expanded) {
                                                return renderLockedWarningAlert(isLockedBy);
                                            }
                                        }}
                                        tooltipTexts={{
                                            delete: 'feature_flag_icon_delete',
                                            edit: 'feature_flag_icon_edit',
                                            arrowClose: 'feature_flag_icon_hide',
                                            arrowOpen: 'feature_flag_icon_show'
                                        }}
                                        publishedStatus={flag.publishStatus}
                                        publishAt={flag.publishAt}
                                    />
                                </WidthTableCell>
                            </ScrollableFlagsContainer>

                            {/* SUBSECTIONS TABLE ROW */}
                            {expanded && (
                                <>
                                    <TableRowWrapper>
                                        <WidthTableCell style={{ padding: 0 }} colSpan={5} $um={'px'}>
                                            {renderFlags(featureFlagToEdit as FeatureFlag)}
                                        </WidthTableCell>
                                    </TableRowWrapper>
                                </>
                            )}

                            {/* SPACE BETWEEN ROWS */}
                            {!isLast && <TableRow style={{ height: '24px' }} />}
                        </>
                    );
                })}

                {/* CREATE SETTING ROW */}
                {creatingFlag && renderCreatingFeatureFlags()}
            </>
        );
    };

    const renderFeatureFlags = () => {
        return <GenericTable columns={buildTableColumns()} body={buildTableBody()} />;
    };

    const renderNoFeatureFlags = () => (
        <NoResourcesContainer>
            <ResourceCard
                image={resourceCardImages.menusCard}
                title={'New Feature Flag'}
                subtitle={'Create feature flag from scratch'}
                primaryButtonLabel={'Create Feature Flag'}
                secondaryButtonLabel={'Learn more'}
                onPrimaryButtonClick={() => {
                    handleCreateClick();
                }}
                onSecondaryButtonClick={() => {
                    //TO DO: To be replaced when we will have a section for feature flags
                    openDocumentation(circleSlugs.feature_flags);
                }}
                createResource
            />
        </NoResourcesContainer>
    );

    const isEmpty = !storeFeatureFlags?.length;

    return (
        <>
            {error && (error.code === API_ERROR_CODES.LOCKED_ERROR ? renderLockedError(error) : <BackendErrorDialog error={error} />)}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle
                        loading={loading}
                        title={'Feature Flags'}
                        withAddButton
                        withProfile
                        addLabel={'Create Feature Flag'}
                        onAdd={() => {
                            handleCreateClick();
                        }}
                        circlesSlugOptions={{ default: CIRCLE_SLUGS.feature_flags }}
                    />
                    <SearchBarContainer>
                        <SearchBarWrapper>
                            <FancyFilter
                                searchTerm={searchTerm}
                                type={ObjectTypes.FEATURE_FLAGS}
                                showUnsaved={showUnsaved}
                                onChange={handleOnSearch}
                                setSearchTerm={setSearchTerm}
                                activeObjectFilter={activeObjectFilter}
                            />
                        </SearchBarWrapper>
                    </SearchBarContainer>

                    {loading ? (
                        <Loader title="Feature Flags" />
                    ) : isEmpty && !creatingFlag ? (
                        renderNoFeatureFlags()
                    ) : (
                        <>
                            {renderFeatureFlags()}
                            {!creatingFlag && (
                                <PageActionsWrapper>
                                    <PageActionButton
                                        type={'BLUE'}
                                        label={'Create Feature Flag'}
                                        onClick={() => {
                                            handleCreateClick();
                                        }}
                                    />
                                </PageActionsWrapper>
                            )}
                        </>
                    )}
                </MainContentWrapper>
                {(creatingFlag || featureFlagToEdit) && (
                    <PageActions
                        onSave={() => {
                            handleSaveClick();
                        }}
                        publishedStatus={featureFlagToEdit?.publishStatus || PUBLISHED_STATUS.UNPUBLISHED}
                        onAbortPublish={() => handleAbortPublishClick(featureFlagToEdit?._id)}
                        onPublish={(publishAt) => {
                            publishAt ? handleSaveClick(publishAt, undefined, true) : handlePublishClick();
                        }}
                        timestamp={featureFlagToEdit?.publishAt}
                        withSchedule
                        onCancel={() => (showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick())}
                        disabled={{
                            save: !showUnsaved,
                            publish: featureFlagToEdit?.publishStatus === PUBLISHED_STATUS.PUBLISHED && !showUnsaved
                        }}
                    />
                )}
            </ApplicationWrapper>
        </>
    );
};

export default FeatureFlags;
