import React, { useEffect, useState } from 'react';
import {
    Header,
    HeaderLeft,
    HeaderRight,
    HeaderTitle,
    TenantAdminsContainer,
    TenantPreviewForDeletion,
    TenantsContainer,
    TenantsNotFoundContainer
} from './Tenants.css';
import Profile from '../common/Profile/Profile';
import SVGInline from 'react-inlinesvg';
import icons from '../../style';
import Button from '../Buttons/Button/Button';
import { UserAvatarPlaceholder, UserAvatar } from '../Projects/Projects.css';
import GenericTable, {
    ActionsTableCell,
    HeaderTableCell,
    ImageTableCell,
    SortableHeaderTableCell,
    tableActions
} from '../common/Table/Table';
import { TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { ACCEPTED_SORT_FIELDS, DEFAULT_SORT_CONFIG, ISortConfig } from '../../utils/fnSort';
import {
    createTenant as insertTenant,
    disableTenant as removeTenant,
    fetchTenants,
    TenantsState,
    updateTenant as editTenant
} from '../../redux/slices/tenantsSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { dialogConfirm } from '../../utils/fnDialogs';
import { Tenant } from '../../types/Tenant';
import { SearchBar } from '../common/SearchBar/SearchBar';
import CreateTenant from './Dialogs/NewTenant';
import { PermissionsState, setUserPermissions } from '../../redux/slices/permissionsSlice';
import { WidthTableCell } from '../common/Table/Table.css';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { generateDateStringForTables } from '../../utils/fnDate';
import { Loader } from '../common/Loader/Loader';
import useScreenSize from '../../hooks/useScreenSize';
import HelpIcon from '../common/HelpIcon/HelpIcon';
import { PageRoutes } from '../../types/RouteTypes';
import { MoreInfoDialog, MoreInfoTypes } from '../common/Dialog/MoreInfoDialog';
import { TenantsTableSizes } from '../../types/TableSizes';
import { TruncatedText } from '../../style/styled-components/reusable.css';
import { SearchBarContainer } from '../common/SearchBar/SearchBar.css';
import { capitalizeAndSplitCamelCaseString } from '../../utils/fnString';

const { logo3Ready } = icons;

const Tenants: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation() as any;
    const { isSmallMobile, isMobile } = useScreenSize();

    const { tenants: storeTenants, error, loading }: TenantsState = useAppSelector((state) => state.tenants);
    const { userPermissions }: PermissionsState = useAppSelector((state) => state.permissions);

    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [openCreateTenant, setOpenCreateTenant] = useState<boolean>(false);
    const [tenantToEdit, setTenantToEdit] = useState<Tenant | null>(null);
    const [showMoreDialog, setShowMoreDialog] = useState<{ type: MoreInfoTypes | null; show: boolean; data: any[] }>({
        type: null,
        show: false,
        data: []
    });

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [activeSortingKey, setActiveSortingKey] = useState<string | null>(ACCEPTED_SORT_FIELDS.lastModified);

    const columnKeys = ['name', 'key', 'tenantAdmins', 'lastModified'];
    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;

    useEffect(() => {
        loadTenants(true, orderBy, searchTerm).then((response) => {
            if (response.permissions) {
                dispatch(setUserPermissions(response.permissions));
            }
        });
    }, []);

    useEffect(() => {
        // we are getting here from users and trying to automatically edit a tenant
        if (location?.state?.selectedTenantId && tenants.length) {
            const tenant = tenants.find((tenant) => tenant._id === location.state.selectedTenantId);
            setTenantToEdit(tenant || null);
            setOpenCreateTenant(tenant !== undefined);
            window.history.replaceState({}, '');
        }
    }, [tenants]);

    useEffect(() => {
        if (loading || error) return;
        if (storeTenants) {
            setTenants(storeTenants);
        }
    }, [loading]);

    const loadTenants = async (addPermissions?: boolean, orderBy?: string, searchTerm?: string) => {
        return await dispatch(fetchTenants({ addPermissions, orderBy, searchTerm })).unwrap();
    };

    const createTenant = async (tenant: Tenant) => {
        await dispatch(insertTenant(tenant)).unwrap();
        loadTenants();
        setSearchTerm(undefined);
        setSortConfig(DEFAULT_SORT_CONFIG);
        setActiveSortingKey(ACCEPTED_SORT_FIELDS.lastModified);
    };
    const updateTenant = async (tenant: Tenant) => {
        await dispatch(editTenant(tenant)).unwrap();
        loadTenants(false, orderBy, searchTerm);
    };
    const deleteTenant = async (id: string) => {
        await dispatch(removeTenant(id)).unwrap();
        loadTenants(false, orderBy, searchTerm);
    };
    const handleEditIconClick = (id: string) => {
        const tenant = tenants.find((tenant: Tenant) => tenant._id === id);
        setTenantToEdit(tenant || null);
        setOpenCreateTenant(true);
    };

    const handleOnSearch = (searchTerm: string) => {
        loadTenants(false, orderBy, searchTerm);
    };

    const handleDeleteIconClick = (id: string) => {
        const tenant = tenants.find((tenant: Tenant) => tenant._id === id);
        const values = {
            title: 'Disable Tenant',
            text: (
                <>
                    <p>
                        You are in the process of disable tenant with the name: <strong>{tenant?.name}</strong>. Are you sure you want to
                        disable the selected tenant?
                    </p>
                    <p>Are you sure you want to disable?</p>
                </>
            )
        };
        dialogConfirm(
            '',
            () => {
                deleteTenant(id);
            },
            values,
            <TenantPreviewForDeletion>
                <img src={tenant?.logo || icons.tenantIcon1} alt="" />
                <p>{tenant?.name}</p>
            </TenantPreviewForDeletion>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Disable'
                }
            }
        );
    };

    const buildTenantAdmins = (tenantAdmins: any[]) => {
        const numOfItemsToShow = isMobile ? 1 : 3;
        return (
            <>
                {tenantAdmins.slice(0, numOfItemsToShow).map((admin, index) => {
                    return (
                        <Tooltip key={index} title={admin.fullName} placement="right-start">
                            <UserAvatar background={admin.icon || icons.userIcon} />
                        </Tooltip>
                    );
                })}
                {tenantAdmins.length > numOfItemsToShow && (
                    <UserAvatarPlaceholder onClick={() => setShowMoreDialog({ type: MoreInfoTypes.USERS, show: true, data: tenantAdmins })}>
                        +{tenantAdmins.length - numOfItemsToShow}
                    </UserAvatarPlaceholder>
                )}
            </>
        );
    };

    const buildTableColumns = () => {
        const columns = columnKeys.map((key, index) => {
            const text = capitalizeAndSplitCamelCaseString(key);
            const isAcceptedSorField = ACCEPTED_SORT_FIELDS[key as keyof typeof ACCEPTED_SORT_FIELDS];

            return isAcceptedSorField ? (
                <SortableHeaderTableCell
                    key={index}
                    text={text}
                    onClick={() => handleSortIconClick(key)}
                    onMouseEnter={() => setShowSortArrows(true)}
                    onMouseLeave={() => setShowSortArrows(false)}
                    hideArrow={!showSortArrows && activeSortingKey !== key}
                    columnSize={TenantsTableSizes[key as keyof typeof TenantsTableSizes]}
                    direction={((sortConfig?.field === key || sortConfig?.field.split('.')[0] === key) && sortConfig?.direction) || 'asc'}
                />
            ) : (
                <HeaderTableCell key={index} text={text} columnSize={TenantsTableSizes[key as keyof typeof TenantsTableSizes]} />
            );
        });

        !isSmallMobile && columns.unshift(<TableCell key={'first_cell'} />);
        return columns;
    };

    const buildTableBody = () => {
        const rows: JSX.Element[] = [];
        tenants?.forEach((tenant) => {
            const lastModified = generateDateStringForTables(tenant.lastModified || 0);
            rows.push(
                <TableRow key={tenant._id}>
                    {/* TENANTS ICON TABLE CELL */}
                    {!isSmallMobile && (
                        <ImageTableCell shape={'square'} imageSize={{ width: 48, height: 48 }} src={tenant.logo || icons.tenantIcon1} />
                    )}

                    {/* TENANTS NAME TABLE CELL */}
                    <WidthTableCell {...TenantsTableSizes.name}>
                        <Link to={PageRoutes.PROJECTS}>
                            <TruncatedText>{tenant.name}</TruncatedText>
                        </Link>
                    </WidthTableCell>

                    {/* KEY TABLE CELL */}
                    <WidthTableCell {...TenantsTableSizes.key}>{tenant.key}</WidthTableCell>

                    {/* TENANT ADMINS TABLE CELL */}
                    <WidthTableCell {...TenantsTableSizes.tenantAdmins}>
                        <TenantAdminsContainer>{buildTenantAdmins(tenant.tenantAdmins || [])}</TenantAdminsContainer>
                    </WidthTableCell>

                    {/* LAST MODIFIED TABLE CELL */}
                    <WidthTableCell {...TenantsTableSizes.lastModified}>{lastModified}</WidthTableCell>

                    {/* ACTIONS TABLE CELL */}
                    <WidthTableCell {...TenantsTableSizes.actions}>
                        <ActionsTableCell
                            tooltipTexts={{
                                edit: 'tenants_icon_edit',
                                delete: 'tenants_icon_disable'
                            }}
                            actions={[tableActions.EDIT, tableActions.REMOVE]}
                            onEdit={() => handleEditIconClick(tenant._id)}
                            onRemove={() => handleDeleteIconClick(tenant._id)}
                        />
                    </WidthTableCell>
                </TableRow>
            );
        });
        return <TableBody>{rows}</TableBody>;
    };

    const handleSortIconClick = (field: string) => {
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            direction,
            field
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadTenants(false, orderBy, searchTerm);
        setShowSortArrows(false);
    };
    if (!loading && !userPermissions) {
        return <BackendErrorDialog error={{ status: 401 }} />;
    }
    return (
        <>
            {error && <BackendErrorDialog error={error} />}
            <TenantsContainer>
                <Header>
                    <HeaderLeft>
                        <SVGInline src={logo3Ready} onClick={() => navigate('/')} />
                        <HeaderTitle>Tenants</HeaderTitle>
                    </HeaderLeft>
                    <HeaderRight>
                        <HelpIcon />
                        <Button type="BLUE" label="Create Tenant" onClick={() => setOpenCreateTenant(true)} responsive={isSmallMobile} />
                        <Button
                            type="DEFAULT"
                            label="Manage Users"
                            onClick={() => navigate(PageRoutes.USERS_GROUPS)}
                            responsive={isSmallMobile}
                        />
                        <Profile />
                    </HeaderRight>
                </Header>
                <SearchBarContainer>
                    <SearchBar
                        title={'Search Name'}
                        disabled={loading}
                        searchTerm={searchTerm}
                        onSearch={handleOnSearch}
                        setSearchTerm={setSearchTerm}
                        tooltipText={'tenants_icon_search'}
                    />
                </SearchBarContainer>
                {loading ? (
                    <Loader title={'Tenants'} />
                ) : tenants.length > 0 ? (
                    <GenericTable
                        body={buildTableBody()}
                        columns={buildTableColumns()}
                        sortableColumns={['tenant', 'key', 'lastModified']}
                    />
                ) : (
                    <TenantsNotFoundContainer>Tenants not loaded/non existent</TenantsNotFoundContainer>
                )}
                <CreateTenant
                    open={openCreateTenant}
                    onSave={(newTenant: Tenant) => {
                        if (tenantToEdit) {
                            updateTenant(newTenant);
                        } else {
                            createTenant(newTenant);
                        }
                        setOpenCreateTenant(false);
                        setTenantToEdit(null);
                    }}
                    onClose={() => {
                        setTenantToEdit(null);
                        setOpenCreateTenant(false);
                    }}
                    tenant={tenantToEdit}
                />
                <MoreInfoDialog
                    type={showMoreDialog.type!}
                    open={showMoreDialog.show}
                    onClose={() => setShowMoreDialog({ type: null, show: false, data: [] })}
                    data={showMoreDialog.data}
                />
            </TenantsContainer>
        </>
    );
};

export default Tenants;
