import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const slice = createSlice({
    name: 'pageActionsShow',
    initialState,
    reducers: {
        showPageActions: (state) => {
            return (state = true);
        },
        hidePageActions: (state) => {
            return (state = false);
        }
    }
});

export const { showPageActions, hidePageActions } = slice.actions;
export default slice.reducer;
