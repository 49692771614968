import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import GenericDialog, { DialogButton, DialogFileField, DialogTypes } from '../../common/Dialog/GenericDialog';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { FilesState, uploadFilesSync } from '../../../redux/slices/fileManagerSlice';
import { getImgixUrl } from '../../../utils/Globals';
import { extractFileNameFromAzureURL } from '../../../utils/fnUrl';

export type LogoDialogProps = {
    open: boolean;
    onSave: (logo: string | null) => void;
    onClose: () => void;
    logo: string;
    isTemplate?: boolean;
};

const LogoDialog: FC<LogoDialogProps> = ({ open, onSave, onClose, logo, isTemplate }) => {
    const [isOpen, setIsOpen] = useState<boolean>(open);
    const [logoFile, setLogoFile] = useState<File | null | string>(null);
    const [initialLogo, setInitialLogo] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string>('');

    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { error: imageError, loading }: FilesState = useAppSelector((state) => state.files);
    const { config } = useAppSelector((state) => state.config);

    const dispatch = useDispatch();

    const handleCloseClick = () => {
        onClose && onClose();
    };

    const createFile = async (file: File) => {
        const prefix = isTemplate ? 'system_templates/items/logos' : `${activeProjectId}/logos`;
        try {
            const response = await dispatch(uploadFilesSync({ files: [file], prefix, overwrite: true })).unwrap();
            return response.urls;
        } catch (ex) {
            return [];
        }
    };

    const handleSaveClick = async () => {
        let logoUrl: string | null = null;
        if (logoFile && typeof logoFile !== 'string') {
            const urls = await createFile(logoFile);
            if (urls?.length) {
                const fileName = extractFileNameFromAzureURL(config.AZURE_CONFIG.account, config.AZURE_CONFIG.container, urls[0]);
                logoUrl = encodeURIComponent(getImgixUrl(config.imgixBaseUrl, fileName));
            }
        } else {
            logoUrl = imageUrl;
        }
        onSave && onSave(logoUrl);
    };

    useEffect(() => {
        setIsOpen(open);
        if (open) {
            setInitialLogo(decodeURIComponent(logo));
        } else {
            setInitialLogo('');
        }
    }, [open]);

    const saveButton: DialogButton = {
        label: 'Add',
        type: 'BLUE',
        onClick: handleSaveClick,
        loading: loading
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    if (!isOpen) return null;

    return (
        <GenericDialog type={DialogTypes.Form} title={'Add Logo'} onClose={handleCloseClick} actionButtons={[cancelButton, saveButton]}>
            <DialogFileField
                fieldKey={'image'}
                preview={imageUrl || initialLogo}
                imageInfo={{ previewImage: true, height: 128, width: 128, um: 'px' }}
                localFileCallBack={(file) => {
                    setLogoFile(file);
                }}
                fileManagerCallback={(url: string) => {
                    setImageUrl(url ? getImgixUrl(config.imgixBaseUrl, url) : '');
                }}
                openUrlCallback={(url: string) => setImageUrl(url)}
                error={imageError?.message || undefined}
                withoutFileManager={isTemplate}
            ></DialogFileField>
        </GenericDialog>
    );
};

export default LogoDialog;
