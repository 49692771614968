import { StorageClient } from './FileManagerClient';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import configServiceAPI from '../configServiceAPI';
import { sanitizeFileName } from '../../fnString';
import { generateUID } from '../../fnGenerator';
import { Buffer } from 'buffer';
import { FILE_EXISTS_CODE } from '../../../components/FileManager/FileManager';
import _ from 'lodash';

export const DEFAULT_ERROR_CODE = 'CLIENT_UPLOADER_ERROR';
const chunkSize = 4 * 1024 * 1024; // 4MB;
export const maxFileSize = 5368709120; // 5GB

export class AzureBlobStorageClient implements StorageClient {
    private containerClient: ContainerClient | null = null;

    private azureContainer: string = '';
    private azureClientUrl: string = '';

    private _projectId: string = '';
    private _abortController: AbortController | null = null; // used for eventually aborting an upload. since we support only one upload at a time, a single abort controller is enough

    set projectId(value: string) {
        this._projectId = value;
    }

    get projectId() {
        return this._projectId;
    }

    public async downloadFile(file: any) {
        const response = await fetch(file.fullUrl);
        if (!response.ok) return;

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    public async abortFileUpload() {
        if (!this._abortController) return false;
        this._abortController.abort();
        return true;
    }

    private async getAzureConfig(isUserIcon?: boolean) {
        const result = await configServiceAPI.getClientConfig(isUserIcon ? 'user_icons' : this._projectId);
        if (!result.response) return;

        const data = result.response as any;
        this.azureContainer = data.container;
        this.azureClientUrl = data.clientUrl;
    }

    private async getAzureToken(fileSize?: number, isUserIcon?: boolean) {
        this.containerClient = null;
        if (!this.azureContainer || !this.azureClientUrl) {
            await this.getAzureConfig(isUserIcon);
            if (!this.azureContainer || !this.azureClientUrl) return;
        }

        const result = await configServiceAPI.getUploadToken(this._projectId, fileSize);
        const token = result.response as any;
        if (!token) return;

        const client = new BlobServiceClient(`${this.azureClientUrl}?${token}`);
        this.containerClient = client.getContainerClient(this.azureContainer);
    }

    private async checkFileExistence(name: string) {
        let result = false;
        try {
            const file = await this.containerClient?.getBlockBlobClient(name).exists();
            result = !!file;
        } catch (ex) {
            console.error(ex);
        }
        return result;
    }

    private uploadFileInChunks = async (
        file: File,
        chunkSize: number,
        prefix?: string,
        uploadProgressCallback?: (data: { progress: number; name: string }) => void
    ) => {
        let offset = 0;
        let order = 0;
        let totalBytesUploaded = 0;
        const blockIds: string[] = [];
        const chunkUploadProgress: { [key: string]: number } = {};

        const blobOptions = {
            blobHTTPHeaders: { blobContentType: file.type },
            timeout: 30 * 6 * 1000 // 30 minutes
        };
        const blobName = file.name ? sanitizeFileName(file.name) : `blob_${generateUID()}`;

        const uploadFileChunk = async () => {
            const chunk = file.slice(offset, offset + chunkSize);
            offset += chunkSize;

            // each block should have an unique id based on which the final blob will be created
            // the ids must have a consistent length and they must be in the order that blocks should be commited
            const blockId = Buffer.from(order.toString().padStart(6, '0')).toString('base64');
            await this.containerClient
                ?.getBlockBlobClient(prefix ? `${prefix}/${blobName}` : blobName)
                .stageBlock(blockId, chunk, chunk.size, {
                    onProgress: (evt) => {
                        chunkUploadProgress[blockId] = evt.loadedBytes;
                        totalBytesUploaded = Object.values(chunkUploadProgress).reduce((a, b) => a + b, 0);
                    },
                    abortSignal: this._abortController?.signal
                });
            blockIds.push(blockId);
            order++;

            if (offset >= file.size) {
                return await this.containerClient
                    ?.getBlockBlobClient(prefix ? `${prefix}/${blobName}` : blobName)
                    .commitBlockList(blockIds, blobOptions);
            }

            uploadProgressCallback?.({ progress: (totalBytesUploaded / file.size) * 100, name: blobName });
            await uploadFileChunk();
        };

        return await uploadFileChunk();
    };

    private uploadBlobInChunks = async (
        blob: Blob,
        name: string,
        chunkSize: number,
        prefix?: string,
        uploadProgressCallback?: (data: { progress: number; name: string }) => void
    ) => {
        let offset = 0;
        let order = 0;
        let totalBytesUploaded = 0;
        const chunkUploadProgress: { [key: string]: number } = {};
        const blockIds: string[] = [];

        const blobOptions = {
            blobHTTPHeaders: { blobContentType: blob.type },
            timeout: 30 * 6 * 1000 // 30 minutes
        };
        const blobName = name ? sanitizeFileName(name) : `blob_${generateUID()}`;

        const uploadBlobChunk = async () => {
            const chunk = (await blob.arrayBuffer()).slice(offset, offset + chunkSize);
            offset += chunkSize;

            // each block should have an unique id based on which the final blob will be created
            // the ids must have a consistent length and they must be in the order that blocks should be commited
            const blockId = Buffer.from(order.toString().padStart(6, '0')).toString('base64');
            await this.containerClient
                ?.getBlockBlobClient(prefix ? `${prefix}/${blobName}` : blobName)
                .stageBlock(blockId, chunk, chunk.byteLength, {
                    onProgress: (evt) => {
                        chunkUploadProgress[blockId] = evt.loadedBytes;
                        totalBytesUploaded = Object.values(chunkUploadProgress).reduce((a, b) => a + b, 0);
                    },
                    abortSignal: this._abortController?.signal
                });
            blockIds.push(blockId);
            order++;

            if (offset >= blob.size) {
                return await this.containerClient
                    ?.getBlockBlobClient(prefix ? `${prefix}/${blobName}` : blobName)
                    .commitBlockList(blockIds, blobOptions);
            }

            uploadProgressCallback?.({ progress: (totalBytesUploaded / blob.size) * 100, name: blobName });
            await uploadBlobChunk();
        };

        return await uploadBlobChunk();
    };

    async uploadFile(
        file: File,
        prefix?: string,
        uploadProgressCallback?: (data: { progress: number; name: string }) => void,
        uploadSuccessCallback?: (name: string) => void,
        uploadErrorCallback?: (error: { code: string; message: string }) => void,
        overwrite?: boolean
    ) {
        if (file.size > maxFileSize) {
            return {
                success: false,
                error: {
                    message: 'The file is too big. Maximum file size allowed is 5GB. Please upload a smaller file',
                    code: DEFAULT_ERROR_CODE
                },
                status: 400
            };
        }
        await this.getAzureToken(file.size);
        if (!this.containerClient) {
            return {
                success: false,
                error: {
                    message:
                        'Uploader could not be initialized. You might not have upload rights. If you are sure you do, please try to reload the page.',
                    code: DEFAULT_ERROR_CODE
                },
                status: 401
            };
        }

        if (!overwrite) {
            const newFileExists = await this.checkFileExistence(prefix ? `${prefix}/${file.name}` : file.name);
            if (newFileExists) {
                return {
                    success: false,
                    error: {
                        code: FILE_EXISTS_CODE,
                        message: 'There is already a file with the same name and path.'
                    }
                };
            }
        }

        const unknownErrorMessage = 'An unknown error occured. Please try again';

        this._abortController = new AbortController();
        this.uploadFileInChunks(file, chunkSize, prefix, uploadProgressCallback ? (data) => uploadProgressCallback(data) : undefined)
            .then(async (response) => {
                if (response?.errorCode) {
                    const error = {
                        code: DEFAULT_ERROR_CODE,
                        message: response?.errorCode || unknownErrorMessage
                    };
                    return uploadErrorCallback?.(error);
                }
                uploadSuccessCallback?.(file.name ? sanitizeFileName(file.name) : `blob_${generateUID()}`);
            })
            .catch(async (ex) => {
                const error = {
                    code: DEFAULT_ERROR_CODE,
                    message: ex
                };
                uploadErrorCallback?.(error);
                return { success: false };
            })
            .finally(() => {
                this._abortController = null;
            });

        return { success: true };
    }

    async editFile(
        newPath: string,
        originalPath: string,
        overwrite?: boolean,
        uploadProgressCallback?: (data: { progress: number; name: string }) => void,
        uploadSuccessCallback?: (name: string) => void,
        uploadErrorCallback?: (error: { code: string; message: string }) => void
    ) {
        const file = (await configServiceAPI.getFile(originalPath)).response as any;
        await this.getAzureToken(file?.size);
        if (!this.containerClient) {
            return {
                success: false,
                error: {
                    message:
                        'Uploader could not be initialized. You might not have rights to perform this action. If you are sure you do, please try to reload the page.',
                    code: DEFAULT_ERROR_CODE
                },
                status: 401
            };
        }

        if (!overwrite) {
            const newFileExists = await this.checkFileExistence(newPath);
            if (newFileExists) {
                return {
                    success: false,
                    error: {
                        code: FILE_EXISTS_CODE,
                        message: 'There is already a file with the same name and path.'
                    }
                };
            }
        }

        const fileUrl = `${this.azureClientUrl}/${this.azureContainer}/${originalPath}`;
        const name = _.last(newPath.split('/')) || '';
        const prefix = newPath.replace(`/${name}`, '');

        const unknownErrorMessage = 'An unknown error occured. Please try again';

        fetch(fileUrl).then(async (response) => {
            try {
                this._abortController = new AbortController();
                const blob = await response.blob();

                const uploadResponse = await this.uploadBlobInChunks(
                    blob,
                    name,
                    chunkSize,
                    prefix,
                    uploadProgressCallback ? (data) => uploadProgressCallback(data) : undefined
                );
                if (uploadResponse?.errorCode) {
                    const error = {
                        code: DEFAULT_ERROR_CODE,
                        message: uploadResponse?.errorCode || unknownErrorMessage
                    };
                    return uploadErrorCallback?.(error);
                }
                uploadSuccessCallback?.(name ? sanitizeFileName(name) : `blob_${generateUID()}`);
            } catch (ex) {
                console.error(ex);
                const error = {
                    code: DEFAULT_ERROR_CODE,
                    message: ex
                };
                uploadErrorCallback?.(error);
                return { success: false };
            } finally {
                this._abortController = null;
            }
        });

        return { success: true };
    }

    async uploadFilesSync(files: File[], prefix?: string, overwrite?: boolean) {
        const isUserIcon = prefix?.includes('user_icons');
        const uploadFileSync = async (file: File, prefix?: string, overwrite?: boolean) => {
            if (file.size > maxFileSize) {
                return {
                    success: false,
                    error: {
                        message: 'The file is too big. Maximum file size allowed is 5GB. Please upload a smaller file',
                        code: DEFAULT_ERROR_CODE
                    },
                    status: 400
                };
            }
            await this.getAzureToken(file.size, isUserIcon);
            if (!this.containerClient) {
                return {
                    success: false,
                    error: {
                        message:
                            'Uploader could not be initialized. You might not have upload rights. If you are sure you do, please try to reload the page.',
                        code: DEFAULT_ERROR_CODE
                    },
                    status: 401
                };
            }

            if (!overwrite) {
                const newFileExists = await this.checkFileExistence(prefix ? `${prefix}/${file.name}` : file.name);
                if (newFileExists) {
                    return {
                        success: false,
                        error: {
                            code: FILE_EXISTS_CODE,
                            message: 'There is already a file with the same name and path.'
                        },
                        status: 400
                    };
                }
            }

            // Get a reference to a blob
            const fileName = file.name ? sanitizeFileName(file.name) : `blob_${generateUID()}`;
            const blobName = prefix ? `${prefix}/${fileName}` : fileName;
            const blockBlobClient = this.containerClient.getBlockBlobClient(blobName);

            // Upload data to the blob
            const uploadOptions = {
                blobHTTPHeaders: {
                    blobContentType: file.type
                }
            };

            try {
                await blockBlobClient.uploadData(file, uploadOptions);
                return {
                    success: true,
                    url: `${this.azureClientUrl}/${this.azureContainer}/${blobName}`,
                    error: null
                };
            } catch (error) {
                console.error('Error uploading file:', error);
                return {
                    success: false,
                    error: {
                        message: 'Error uploading file to Azure Blob Storage',
                        code: DEFAULT_ERROR_CODE
                    }
                };
            }
        };
        const responses = await Promise.all(files.map((file) => uploadFileSync(file, prefix, overwrite)));
        return responses;
    }
}
