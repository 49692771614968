import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { PageStyle } from '../../types/PageStyle';

export const fetchPageStyles = createAsyncThunk<
    { pageStyles: PageStyle[]; error: { message: string; code: string } | null; permissions?: any },
    { addPermissions?: boolean; projectId?: string; orderBy?: string; searchTerm?: string }
>('pageStyles/fetchPageStyles', async ({ addPermissions, projectId, orderBy, searchTerm }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllPageStyles(addPermissions, projectId, orderBy, searchTerm);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { pageStyles: result.response as any as PageStyle[], error: null, permissions: result.permissions };
});

export const fetchPageStyle = createAsyncThunk<{ pageStyle: PageStyle; error: { message: string; code: string } | null }, string>(
    'pageStyles/fetchPageStyle',
    async (settingId, thunkApi) => {
        const result = await ConfigServiceAPI.getPageStyleById(settingId);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { pageStyle: result.response as any as PageStyle, error: null };
    }
);
export const fetchContentMarkers = createAsyncThunk<{
    contentMarkers: any;
    error: { message: string; code: string } | null;
}>('pageStyles/fetchContentMarkers', async (_, thunkApi) => {
    const result = await ConfigServiceAPI.getAllContentMarkers();
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { contentMarkers: result.response as any, error: null };
});

export const createPageStyle = createAsyncThunk<{ id: string; error: { message: string; code: string } | null }, PageStyle>(
    'pageStyles/createPageStyle',
    async (pageStyle: PageStyle, thunkApi) => {
        const result = await ConfigServiceAPI.createPageStyle(pageStyle);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any as string, error: null };
    }
);

export const updatePageStyle = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { pageStyle: PageStyle; shouldUnlockAfterSave?: boolean }
>('pageStyles/updatePageStyle', async ({ pageStyle, shouldUnlockAfterSave }, thunkApi) => {
    const result = await ConfigServiceAPI.updatePageStyle(pageStyle, shouldUnlockAfterSave);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const deletePageStyle = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'pageStyles/deletePageStyle',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.deletePageStyle(id);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export const changePageStyleDefault = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, { id: string }>(
    'pageStyles/updateDefault',
    async ({ id }, thunkApi) => {
        const result = await ConfigServiceAPI.changePageStyleDefault(id);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export interface PageStylesState {
    pageStyles: PageStyle[];
    contentMarkers: any;
    loading: boolean;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: PageStylesState = {
    pageStyles: [],
    loading: false,
    error: null,
    contentMarkers: null
};

const slice = createSlice({
    name: 'pageStyles',
    initialState,
    reducers: {
        unsetPageStylesError(state) {
            state.error = null;
        },
        unsetPageStyles(state) {
            state.pageStyles = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPageStyles.fulfilled, (state, action: any) => {
                state.pageStyles = action.payload.pageStyles;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchPageStyles.rejected, (state, action: any) => {
                state.pageStyles = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchPageStyles.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchContentMarkers.fulfilled, (state, action: any) => {
                state.contentMarkers = action.payload.contentMarkers;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchContentMarkers.rejected, (state, action: any) => {
                state.contentMarkers = null;
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchContentMarkers.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createPageStyle.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createPageStyle.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(createPageStyle.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updatePageStyle.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updatePageStyle.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(updatePageStyle.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deletePageStyle.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deletePageStyle.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deletePageStyle.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(changePageStyleDefault.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(changePageStyleDefault.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(changePageStyleDefault.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetPageStylesError, unsetPageStyles } = slice.actions;
export default slice.reducer;
