import React, { useState } from 'react';
import { useAppSelector } from '../../hooks/redux';
import { ApplicationWrapper, MainContentWrapper } from '../../style/styled-components/reusable.css';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import Sidebar from '../common/Sidebar/Sidebar';
import AppBrandings, { APP_BRANDING_ADD_BUTTON_ID } from './AppBranding/AppBrandings';
import PageStyle, { PAGE_STYLE_ADD_BUTTON_ID } from './PageStyle/PageStyle';
import { appBrandingsState } from '../../redux/slices/appBrandingsSlice';
import { PageStylesState } from '../../redux/slices/pageStylesSlice';
import { usePersistentState } from '../../hooks/usePersistentState';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';

const APP_BRANDING = 'App Branding';
const PAGE_STYLE = 'Page Style';

// all App Branding things will be comented out as the decision was to take it out of the UI
// they will not be deleted as there is a chance we will bring this back at some point

const StyleAndBranding: React.FC = () => {
    const [activePage] = useState<typeof APP_BRANDING | typeof PAGE_STYLE>(PAGE_STYLE);
    const { appBrandings }: appBrandingsState = useAppSelector((state) => state.appBrandings);
    const { pageStyles, loading: pageStylesLoading }: PageStylesState = useAppSelector((state) => state.pageStyles);

    // const generalLoading = appBrandingsLoading || pageStylesLoading;

    return (
        <ApplicationWrapper>
            <Sidebar />
            <MainContentWrapper>
                <ScreenTitle
                    loading={pageStylesLoading}
                    title={'Page Styles'}
                    withProfile
                    withAddButton={activePage === APP_BRANDING ? !!appBrandings.length : !!pageStyles.length}
                    addLabel={`Create ${activePage}`}
                    onAdd={() => {
                        activePage === PAGE_STYLE && document.getElementById(PAGE_STYLE_ADD_BUTTON_ID)?.click();
                        activePage === APP_BRANDING && document.getElementById(APP_BRANDING_ADD_BUTTON_ID)?.click();
                    }}
                    circlesSlugOptions={{
                        default: CIRCLE_SLUGS.style_and_branding,
                        onboarding: ONBOARDING_CIRCLE_SLUGS.style_and_branding
                    }}
                />
                {/* <FieldAndToggleContainer>
                    <GroupFieldsContainer>
                        <GroupField onClick={() => setActivePage(APP_BRANDING)} active={activePage === APP_BRANDING}>
                            App Branding
                        </GroupField>
                        <GroupField onClick={() => setActivePage(PAGE_STYLE)} active={activePage === PAGE_STYLE}>
                            Page Style
                        </GroupField>
                    </GroupFieldsContainer>
                </FieldAndToggleContainer> */}
                {activePage === APP_BRANDING ? <AppBrandings /> : <PageStyle />}
            </MainContentWrapper>
        </ApplicationWrapper>
    );
};

export default StyleAndBranding;
