import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogColorField, DialogTypes } from '../../../common/Dialog/GenericDialog';
import { DialogColorFieldsContainer } from '../AppBranding.css';
import { CIRCLE_SLUGS } from '../../../common/HelpIcon/HelpIcon';

export type ContentMarkersDialogProps = {
    title: string;
    open: boolean;
    onSave: (param: ContentMarker) => void;
    onClose: () => void;
    contentMarker?: ContentMarker;
};

export type ContentMarker = {
    fontColor?: string;
    backgroundColor?: string;
    borderColor?: string;
};

const ContentMarkersDialog: React.FC<ContentMarkersDialogProps> = ({ open, onSave, onClose, contentMarker, title }) => {
    const [isOpen, setIsOpen] = useState(open);
    const [backgroundColor, setBackgroundColor] = useState('');
    const [fontColor, setFontColor] = useState('');
    const [borderColor, setBorderColor] = useState('');

    useEffect(() => {
        setIsOpen(open);
        if (open && contentMarker) {
            setBackgroundColor(contentMarker.backgroundColor || '');
            setFontColor(contentMarker.fontColor || '');
            setBorderColor(contentMarker.borderColor || '');
        }
    }, [open]);

    const handleCloseClick = (evt: any) => {
        evt.preventDefault();
        onClose();
        setBackgroundColor('');
        setFontColor('');
        setBorderColor('');
    };

    const handleSaveClick = (evt: any) => {
        evt.preventDefault();
        onSave({ backgroundColor, fontColor, borderColor });
        setBackgroundColor('');
        setFontColor('');
        setBorderColor('');
    };

    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleSaveClick
    };

    const closeButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    return isOpen ? (
        <GenericDialog
            type={DialogTypes.Form}
            title={title}
            onClose={handleCloseClick}
            actionButtons={[closeButton, saveButton]}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.conditions }}
        >
            <DialogColorFieldsContainer>
                <DialogColorField
                    width="128px"
                    height="128px"
                    onColorPicked={(newColor) => setFontColor(newColor)}
                    value={fontColor}
                    showColorValue
                    label={'Font Color'}
                />
                <DialogColorField
                    width="128px"
                    height="128px"
                    onColorPicked={(newColor) => setBackgroundColor(newColor)}
                    value={backgroundColor}
                    showColorValue
                    label={'Background Color'}
                />
                <DialogColorField
                    width="128px"
                    height="128px"
                    onColorPicked={(newColor) => setBorderColor(newColor)}
                    value={borderColor}
                    showColorValue
                    label={'Border Color'}
                />
            </DialogColorFieldsContainer>
        </GenericDialog>
    ) : null;
};

export default ContentMarkersDialog;
