import { FC, useEffect, useState } from 'react';
import Button from '../../Buttons/Button/Button';
import { HintContainer, HintIcon, HintMainSection, HintSubtitle, HintText, HintTitle } from './Hint.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../assets/images/icons';
import { GradientBackgroundToRight } from '../../PageEdit/VisualEditor.css';
import { generateGradientValues } from '../../../utils/fnColor';

export enum HINT_TYPES {
    SCREEN_SIZE_PAGE = 'ScreenSizePage',
    SCREEN_SIZE_CIS = 'ScreenSizeCIS'
}

export const hintText = {
    [HINT_TYPES.SCREEN_SIZE_PAGE]: {
        title: 'Increase Window Size',
        subtitle: 'Increase your window size or switch to list view',
        icon: icons.increaseArrowsIcon,
        button_label: 'Ok'
    },
    [HINT_TYPES.SCREEN_SIZE_CIS]: {
        title: 'Increase Window Size',
        subtitle: 'Increase your window size to 1440px resolution',
        icon: icons.increaseArrowsIcon,
        button_label: 'Ok'
    }
};

export type HintProps = {
    type: HINT_TYPES;
    showHint: boolean;
    onHide: () => void;
};

export const Hint: FC<HintProps> = ({ type, showHint, onHide }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { title, subtitle, button_label, icon } = hintText[type];

    useEffect(() => {
        setIsOpen(showHint);

        if (showHint) {
            document.body.style.overflow = 'hidden';

            return () => {
                document.body.style.overflow = 'unset';
            };
        }
    }, [showHint]);

    if (!isOpen) return null;
    return (
        <HintContainer>
            <GradientBackgroundToRight gradientsValues={generateGradientValues(0, 10, 1)} />
            <HintMainSection>
                <HintIcon>
                    <SVGInline src={icon} />
                </HintIcon>
                <HintText>
                    <HintTitle>{title}</HintTitle>
                    <HintSubtitle>{subtitle}</HintSubtitle>
                </HintText>
                <Button type={'BLUE'} label={button_label} onClick={() => onHide()} />
            </HintMainSection>
        </HintContainer>
    );
};
