import React from 'react';
import { createRoot } from 'react-dom/client';
import './style/scss/global.scss';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';
import App from './components/App';
import getTheme from './style/themes/theme';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';

import './utils/circle.js';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <StylesProvider injectFirst>
            <ThemeProvider theme={getTheme()}>
                <DndProvider backend={HTML5Backend}>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <App />
                        </PersistGate>
                    </Provider>
                </DndProvider>
            </ThemeProvider>
        </StylesProvider>
    </React.StrictMode>
);
