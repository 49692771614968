import React, { FC, useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogDropdownSingle, DialogTypes } from './GenericDialog';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { ProjectsState } from '../../../redux/slices/projectsSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { ProjectLabel } from '../../Pages/Pages.css';
import icons from '../../../style';
import { allTemplates, Module, templates } from '../../../types/Module';
import { TemplateIconWrapper, TemplateOption } from '../../Modules/Dialogs/NewModule.css';
import SVGInline from 'react-inlinesvg';
import { TemplateIcons } from '../../../assets/images/icons';
import { allowedTemplatesForCollection, moduleTypes } from '../../../utils/Globals';
import { ObjectInfoMessage, ObjectTitle } from './CopyToProjectDialog.css';
import { Item } from '../../../types/Item';
import { DIALOG_NAMES, dialogAlert, ToastAlert } from '../../../utils/fnDialogs';
import { ObjectType, ObjectTypes } from '../../../types/Object';
import { copyObjectCheck, copyObjectState } from '../../../redux/slices/copyObjectSlice';
import { ModulesState } from '../../../redux/slices/moduleSlice';
import { pagesState } from '../../../redux/slices/pagesSlice';
import { ItemState } from '../../../redux/slices/itemSlice';
import { Page, PageIntents } from '../../../types/Page';
import { TEMPLATES_WITHOUT_PREVIEW, TEMPLATES_WITHOUT_TOP_TEN } from '../../Modules/Dialogs/NewModule';

export interface CopyToProjectDialogProps {
    open: boolean;
    objectId: string;
    objectType: ObjectType;
    onClose: () => void;
    onCopy: (
        projectId: string,
        newTemplateValues?: { moduleId: string; template: string }[],
        showItemActionWarningAfterCopy?: boolean,
        withRedirect?: boolean,
        hideSuccessToast?: boolean
    ) => void;
    restrictedTemplatesForPage?: templates[];
}

const CopyToProjectDialog: FC<CopyToProjectDialogProps> = ({ open, objectId, objectType, onCopy, onClose, restrictedTemplatesForPage }) => {
    const { projects, error: projectsError, loading: projectsLoading }: ProjectsState = useAppSelector((state) => state.projects);
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { loading: copyObjectLoading }: copyObjectState = useAppSelector((state) => state.copyObject);
    const { modules }: ModulesState = useAppSelector((state) => state.modules);
    const { pages }: pagesState = useAppSelector((state) => state.pages);
    const { items }: ItemState = useAppSelector((state) => state.items);

    const [restrictedTemplates, setRestrictedTemplates] = useState<string[]>([]);
    const [selectedProject, setSelectedProject] = useState<any>(null);
    const [templateOptions, setTemplateOptions] = useState<any[]>([]);
    const [projectOptions, setProjectOptions] = useState<any>([]);
    const [object, setObject] = useState<any>(null);

    const [newTemplateValues, setNewTemplateValues] = useState<{ [key: string]: templates }>({});

    const [conflictingModules, setConflictingModules] = useState<Module[]>([]);
    const [showItemActionWarning, setShowActionWarning] = useState<boolean>(false);
    const [showDynamicItemWarning, setShowDynamicItemWarning] = useState<boolean>(false);
    const [showCWModuleWarning, setShowCwModuleWarning] = useState<boolean>(false);
    const [showTvOSTSCModuleWarning, setShowTvOSTSCModuleWarning] = useState<boolean>(false);
    const dispatch = useDispatch();

    const contentWorldModuleAlert = {
        title: 'Warning',
        text:
            objectType === ObjectTypes.PAGES
                ? 'This Page contains a Content World Module, the module will be copied, but the Content World checkbox will be deactivated'
                : 'The module will be copied, but the Content World checkbox will be deactivated.'
    };
    const tvOSTopShelfContentModuleAlert = {
        title: 'Warning',
        text: 'The Page will be copied, but the Page Intent will be set to Basic.'
    };

    const renderContentWorldAlert = () => {
        return dialogAlert('', false, contentWorldModuleAlert, undefined, false, icons.warningYellowIcon);
    };

    const isTemplateValid = (template: string) => {
        return !restrictedTemplates?.map((t) => t?.toLowerCase()).includes(template?.toLowerCase());
    };

    const copyCheck = async () => {
        const { showDynamicItemWarning, showItemActionWarningAfterCopy } = await dispatch(
            copyObjectCheck({ _id: objectId, objectType: objectType, projectId: selectedProject.value })
        ).unwrap();

        setShowActionWarning(showItemActionWarningAfterCopy);
        setShowDynamicItemWarning(showDynamicItemWarning);
    };

    useEffect(() => {
        if (selectedProject?.value && objectId) copyCheck();
    }, [objectId, selectedProject]);

    useEffect(() => {
        if (!object) return;

        switch (objectType) {
            case ObjectTypes.MODULES:
                return setShowCwModuleWarning(!!(object as Module).contentWorld);
            case ObjectTypes.PAGES:
                setShowTvOSTSCModuleWarning((object as Page).intent === PageIntents.TVOS_TV_TOP_SHELF_CONTENT || false);
                return setShowCwModuleWarning((object as Page).modules?.some((module) => !!module.contentWorld) || false);
            default:
                break;
        }
    }, [object]);

    useEffect(() => {
        switch (objectType) {
            case ObjectTypes.PAGES:
                const page = pages.find((page) => page._id === objectId);
                const conflictingModules = page?.modules?.filter((module) => !isTemplateValid(module.template));
                setConflictingModules(conflictingModules || []);
                setNewTemplateValues(
                    conflictingModules?.reduce(
                        (acc, module) => ({ ...acc, [module._id]: module.template }),
                        {} as { [key: string]: templates }
                    ) || {}
                );
                setObject(page);
                break;
            case ObjectTypes.MODULES:
                const module = modules?.find((module: Module) => module._id === objectId);
                const confModules = module && !isTemplateValid(module.template) ? [module] : [];
                setConflictingModules(confModules);
                setNewTemplateValues(
                    confModules?.reduce((acc, module) => ({ ...acc, [module._id]: module.template }), {} as { [key: string]: templates }) ||
                        {}
                );
                setObject(module);
                break;
            case ObjectTypes.ITEMS:
                const item = items?.find((item: Item) => item._id === objectId);
                setObject(item);
                break;
            default:
                break;
        }
    }, [objectId, restrictedTemplates]);

    useEffect(() => {
        const validTemplatesForProject = allTemplates.filter((template) => !restrictedTemplates.includes(template.key));

        const allowedTemplates = restrictedTemplatesForPage
            ? validTemplatesForProject?.filter((item) => !restrictedTemplatesForPage.includes(item.value))
            : validTemplatesForProject;

        setTemplateOptions(
            allowedTemplates?.map((item: any, index: number) => ({
                value: item.value,
                valueForSearch: item.title,
                label: (
                    <TemplateOption key={index}>
                        <TemplateIconWrapper>
                            <SVGInline src={TemplateIcons[item.key]} />
                        </TemplateIconWrapper>
                        <span>
                            {item.title} {isTemplateValid(item.value) ? '' : '(Unavailable)'}
                        </span>
                    </TemplateOption>
                )
            })) || []
        );
    }, [restrictedTemplates, restrictedTemplatesForPage]);

    useEffect(() => {
        if (projectsLoading || projectsError) return;
        if (projects) {
            setProjectOptions(
                projects
                    .filter((project) => project._id !== activeProjectId && project.tenantId === activeTenantId)
                    .map((project) => ({
                        value: project._id,
                        label: (
                            <ProjectLabel>
                                <img src={project.logo || icons.projectIcon1} alt="" />
                                <span>{project.name}</span>
                            </ProjectLabel>
                        ),
                        valueForSearch: project.name
                    }))
            );
        }
    }, [projectsLoading, activeProjectId]);

    useEffect(() => {
        if (!selectedProject) return;
        const project = projects.find((project) => project._id === selectedProject?.value);
        setRestrictedTemplates(project?.restrictedSections?.templates || []);
    }, [selectedProject]);

    const handleOnCopy = async (withRedirect = false) => {
        if (!selectedProject || !object) return;
        const templateValues = Object.entries(newTemplateValues).map(([moduleId, template]) => ({ moduleId, template }));

        if (showDynamicItemWarning) {
            ToastAlert(
                'critical_warning',
                '',
                '',
                undefined,
                DIALOG_NAMES.OBJECT_COPY_WARNING,
                () => {
                    dialogAlert(DIALOG_NAMES.OBJECT_COPY_WARNING, false, undefined, undefined, undefined, icons.warningIcon);
                },
                showItemActionWarning
                    ? () => {
                          ToastAlert('warning', '', '', undefined, DIALOG_NAMES.OBJECT_COPY_ITEM_ACTION_WARNING, () => {
                              renderItemActionWarningAfterCopy();
                          });
                      }
                    : undefined
            );
        }

        if (showItemActionWarning && !showDynamicItemWarning) {
            ToastAlert('warning', '', '', undefined, DIALOG_NAMES.OBJECT_COPY_ITEM_ACTION_WARNING, () => {
                renderItemActionWarningAfterCopy();
            });
        }

        onCopy(selectedProject.value, templateValues, false, withRedirect, showDynamicItemWarning || showItemActionWarning);
        handleCloseClick();
    };

    const handleCloseClick = () => {
        onClose();
        setNewTemplateValues({});
        setSelectedProject(null);
        setObject(null);
        setShowActionWarning(false);
        setShowDynamicItemWarning(false);
    };

    const copyToButton: DialogButton = {
        onClick: () => handleOnCopy(),
        disabled: !selectedProject || !Object.values(newTemplateValues).every((template) => isTemplateValid(template)),
        label: 'Copy',
        type: 'BLUE',
        loading: copyObjectLoading
    };

    const copyAndEditButton: DialogButton = {
        onClick: () => handleOnCopy(true),
        disabled: !selectedProject || !Object.values(newTemplateValues).every((template) => isTemplateValid(template)),
        label: 'Copy & Edit',
        type: 'DEFAULT',
        loading: copyObjectLoading
    };

    const cancelButton: DialogButton = {
        onClick: handleCloseClick,
        label: 'Cancel',
        type: 'DEFAULT'
    };

    const renderConflictingModules = () => {
        return conflictingModules.map((module) => {
            let options =
                module.moduleType === moduleTypes.COLLECTION
                    ? [...templateOptions.filter((opt: any) => allowedTemplatesForCollection.includes(opt.value))]
                    : [...templateOptions];

            if (module.topTen) {
                options = options.filter((opt) => !TEMPLATES_WITHOUT_TOP_TEN.includes(opt.value));
            }

            if (module.preview) {
                options = options.filter((opt) => !TEMPLATES_WITHOUT_PREVIEW.includes(opt.value));
            }

            // Since options contain ONLY the templates that are available for the selected Project
            // Push the disabled template so it appears in the dropdown
            const template = allTemplates.find((t) => t.key.toLowerCase() === module.template.toLowerCase());
            options.unshift({
                value: template?.key,
                valueForSearch: template?.title,
                label: (
                    <TemplateOption key={module.template}>
                        <TemplateIconWrapper>{template && <SVGInline src={TemplateIcons[template.key]} />}</TemplateIconWrapper>
                        <span>{template?.title || 'Unknown'} (Unavailable)</span>
                    </TemplateOption>
                ),
                isDisabled: true
            });

            return (
                <div key={module._id}>
                    <ObjectTitle>{module.name}</ObjectTitle>
                    <ObjectInfoMessage>The UI template is not valid, please select a new one.</ObjectInfoMessage>
                    <DialogDropdownSingle
                        labeledSelect
                        placeholder={''}
                        value={options.find((opt) => opt.value.toLowerCase() === newTemplateValues[module._id]?.toLowerCase())}
                        options={options}
                        onChange={(value: any) => setNewTemplateValues({ ...newTemplateValues, [module._id]: value.value })}
                    />
                </div>
            );
        });
    };

    if (!open) return null;

    const warnings: any[] = [];

    if (showDynamicItemWarning) {
        warnings.push({
            icon: icons.warningIcon,
            critical: true,
            onClick: () => dialogAlert(DIALOG_NAMES.OBJECT_COPY_WARNING, false, undefined, undefined, undefined, icons.warningIcon)
        });
    }
    if (showCWModuleWarning) {
        warnings.push({
            icon: icons.infoIcon,
            onClick: () => renderContentWorldAlert()
        });
    }
    if (showTvOSTSCModuleWarning) {
        warnings.push({
            icon: icons.infoIcon,
            onClick: () => dialogAlert('', false, tvOSTopShelfContentModuleAlert, undefined, false, icons.warningYellowIcon)
        });
    }
    if (showItemActionWarning) {
        warnings.push({ icon: icons.infoIcon, onClick: () => renderItemActionWarningAfterCopy() });
    }

    return (
        <>
            <GenericDialog
                title={'Copy to Project'}
                type={DialogTypes.Form}
                onClose={handleCloseClick}
                actionButtons={[cancelButton, copyAndEditButton, copyToButton]}
                warnings={warnings}
            >
                <DialogDropdownSingle
                    placeholder={projectsLoading ? 'Loading...' : 'Select Project'}
                    onChange={(newValue: any) => setSelectedProject(newValue)}
                    options={projectOptions}
                    value={selectedProject}
                    clearable={false}
                    isDisabled={copyObjectLoading}
                    noError
                />
                {selectedProject && renderConflictingModules()}
            </GenericDialog>
        </>
    );
};

export default CopyToProjectDialog;

export const renderItemActionWarningAfterCopy = () => {
    return dialogAlert(DIALOG_NAMES.OBJECT_COPY_ITEM_ACTION_WARNING, false, null, null, false, icons.warningYellowIcon);
};

export const renderUnsavedWhenCopyingAlert = () => {
    ToastAlert('warning', '', '', undefined, DIALOG_NAMES.OBJECT_COPY_ITEM_ACTION_WARNING_WHILE_UNSAVED, () => {
        dialogAlert(DIALOG_NAMES.OBJECT_COPY_ITEM_ACTION_WARNING_WHILE_UNSAVED, false, null, null, false, icons.warningYellowIcon);
    });
};
