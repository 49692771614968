import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { CC3BaseObject } from '../../types/Object';

export const fetchAllWithUnpublishedChanges = createAsyncThunk<
    { objectsWithUnpublishedChanges: any[]; error: { message: string; code: string } | null },
    { projectId: string; addPermissions: boolean; orderBy?: string; searchTerm?: string }
>('revert/fetchAllWithUnpublishedChanges', async ({ projectId, addPermissions = false, orderBy, searchTerm }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllWithUnpublishedChanges(addPermissions, projectId, orderBy, searchTerm);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { objectsWithUnpublishedChanges: result.response as any, error: null };
});

export const revertObjects = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, { objectIds: string[] }>(
    'revert/revertObjects',
    async ({ objectIds }, thunkApi) => {
        const result = await ConfigServiceAPI.revertObjects(objectIds);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export interface unpublishedChangesSlice {
    loading: boolean;
    objectsWithUnpublishedChanges: CC3BaseObject[];
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: unpublishedChangesSlice = {
    objectsWithUnpublishedChanges: [],
    loading: false,
    error: null
};

const slice = createSlice({
    name: 'revert',
    initialState,
    reducers: {
        unsetUnpublishedChangesError(state) {
            state.error = null;
        },
        unsetUnpublishedChangesObjects(state) {
            state.objectsWithUnpublishedChanges = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllWithUnpublishedChanges.fulfilled, (state, action) => {
                state.objectsWithUnpublishedChanges = action.payload.objectsWithUnpublishedChanges;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchAllWithUnpublishedChanges.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
                state.objectsWithUnpublishedChanges = [];
            })
            .addCase(fetchAllWithUnpublishedChanges.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(revertObjects.fulfilled, (state, _action) => {
                state.error = null;
                state.loading = false;
            })
            .addCase(revertObjects.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(revertObjects.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export default slice.reducer;
export const { unsetUnpublishedChangesError, unsetUnpublishedChangesObjects } = slice.actions;
