import { InputAdornment } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import icons from '../../../assets/images/icons';
import { logos } from '../../../assets/images/providerLogos';
import { useAppSelector, useAppDispatch as useDispatch } from '../../../hooks/redux';
import { usePersistentState } from '../../../hooks/usePersistentState';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { fetchCountryCodes, LanguagesState } from '../../../redux/slices/languagesSlice';
import { SourcesState } from '../../../redux/slices/sourceSlice';
import { contentSourceTypes } from '../../../types/Item';
import configServiceAPI from '../../../utils/api/configServiceAPI';
import GenericDialog, {
    DialogTypes,
    DialogButton,
    DialogTextField,
    DialogToggleButton,
    DialogDropdownMultiple
} from '../../common/Dialog/GenericDialog';
import { SelectLanguageDropdownLabel } from '../../Languages/Dialogs/NewLanguage.css';
import { SettingsContainer, YoutubeSettingsToggles, YoutubeSettingsTitle } from '../Aggregate.css';
import { dialogConfirm } from '../../../utils/fnDialogs';
import { fetchProjects } from '../../../redux/slices/projectsSlice';

type providerSettingsDialogProps = {
    open: boolean;
    onSave: () => void;
    onClose: () => void;
    selectedService: string;
};

export const ProviderSettingsDialog: React.FC<providerSettingsDialogProps> = ({ open, onSave, onClose, selectedService }) => {
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { services, thirdPartyServices }: SourcesState = useAppSelector((state) => state.dynamicSources);
    const { countryCodes, loading: loadingCountryCodes }: LanguagesState = useAppSelector((state) => state.languages);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [token, setToken] = useState('');
    const [loadingToken, setLoadingToken] = useState(false);
    const [editingToken, setEditingToken] = useState(true);
    const { store: selectedCountries, set: setSelectedCountries } = usePersistentState('selectedPrimeVideoCountries');

    const initialToken = useRef<string>('');
    const initialSelectedCountries = useRef<string[]>([]);
    const dispatch = useDispatch();

    const isPrimeVideoSettings = selectedService === contentSourceTypes.PRIME_VIDEO;

    useEffect(() => {
        setIsOpen(open);
        if (!open) {
            setToken('');
            return;
        }

        loadPreviewToken();
        if (isPrimeVideoSettings) {
            !countryCodes?.length && loadCountryCodes();
            initialSelectedCountries.current = selectedCountries || [];
        }
    }, [open]);

    const loadCountryCodes = async () => {
        return await dispatch(fetchCountryCodes()).unwrap();
    };
    const loadProjects = async () => {
        return await dispatch(fetchProjects({})).unwrap();
    };

    const loadPreviewToken = async () => {
        setLoadingToken(true);
        let token = '';

        const result = await configServiceAPI.getPreviewToken(activeProjectId || '', selectedService);
        if (!result.error) {
            token = result.response?.toString() || '';
        }

        setEditingToken(!token);
        setToken(token);
        initialToken.current = token;
        setLoadingToken(false);
    };

    if (!isOpen) return null;

    const handleClose = () => {
        setToken('');
        setSelectedCountries(initialSelectedCountries.current);
        onClose();
    };

    const handleSave = async () => {
        if (initialToken.current !== token) {
            setLoadingToken(true);
            await configServiceAPI.createPreviewToken(activeProjectId || '', selectedService, token);
            await loadProjects();
            setLoadingToken(false);
        }
        onSave();
    };

    const renderTokenChangeConfirm = () => {
        const values = {
            title: 'Warning!',
            text: 'If you exchange your API key this might cause problems in the connection. Are you sure you want to proceed?'
        };
        return dialogConfirm(
            '',
            () => {
                handleSave();
            },
            values,
            undefined,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Confirm'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    const cancelButton: DialogButton = {
        type: 'DEFAULT',
        onClick: handleClose,
        label: 'Cancel'
    };

    const saveButton: DialogButton = {
        type: 'BLUE',
        onClick: !!initialToken.current && initialToken.current !== token ? renderTokenChangeConfirm : handleSave,
        label: 'Save Settings',
        disabled: isPrimeVideoSettings ? loadingToken || loadingCountryCodes : loadingToken
    };

    const deleteTokenAdornment = (
        <InputAdornment position="end">
            <SVGInline
                src={icons.trashIcon}
                onClick={() => {
                    setEditingToken(true);
                    setToken('');
                }}
            />
        </InputAdornment>
    );

    let tokenValue = token;
    if (!editingToken) {
        const starCount = token.length > 4 ? token.length - 4 : token.length;
        tokenValue = '*'.repeat(starCount) + (starCount === token.length ? '' : token.slice(-4));
    }

    const renderYoutubeSettings = () => {
        return (
            <>
                <SVGInline src={logos.YoutubeLogoSettings} />
                <SettingsContainer>
                    <YoutubeSettingsTitle>Enable / Disable Content Sources</YoutubeSettingsTitle>
                    <YoutubeSettingsToggles>
                        <DialogToggleButton
                            checked={!!services.find((s) => s.key === contentSourceTypes.YOUTUBE)}
                            toggleCallback={() => {}}
                            text={thirdPartyServices.find((s) => s.key === contentSourceTypes.YOUTUBE)?.title || ''}
                        />
                        <DialogToggleButton
                            checked={!!services.find((s) => s.key === contentSourceTypes.YOUTUBE_RECO)}
                            toggleCallback={() => {}}
                            text={thirdPartyServices.find((s) => s.key === contentSourceTypes.YOUTUBE_RECO)?.title || ''}
                        />
                    </YoutubeSettingsToggles>
                    <DialogTextField
                        value={tokenValue}
                        onChange={(e: any) => {
                            setToken(e.target.value);
                        }}
                        label={'API Token'}
                        placeholder={loadingToken ? 'Loading...' : 'API Token'}
                        isDisabled={loadingToken || !editingToken}
                        InputProps={!editingToken ? { endAdornment: deleteTokenAdornment } : undefined}
                    />
                </SettingsContainer>
            </>
        );
    };

    const renderPrimeVideoSettings = () => {
        const countryOptions = countryCodes
            .filter((elem) => ['us', 'gb', 'de', 'au'].includes(elem.code))
            .map((elem) => ({
                label: (
                    <span>
                        <SelectLanguageDropdownLabel>
                            <img src={elem.flag} />
                            {elem.name
                                .split('_')
                                .map((s: string) => (s !== 'and' ? _.capitalize(s) : 'and'))
                                .join(' ')}
                        </SelectLanguageDropdownLabel>
                    </span>
                ),
                value: elem.code,
                valueForSearch: elem.name
            }));

        const countryDefault = countryOptions.filter((elem) => elem.value === 'de');
        const countryValue = countryOptions.filter((opt: any) => selectedCountries?.includes(opt.value));

        return (
            <>
                <SVGInline src={logos.PrimeVideoLogoSettings} />
                <SettingsContainer>
                    <DialogDropdownMultiple
                        value={countryValue.length ? countryValue : countryDefault}
                        options={countryOptions}
                        placeholder={'Select Country'}
                        onChange={(value: any) => {
                            if (!value?.length) {
                                setSelectedCountries(countryDefault[0].value);
                            } else setSelectedCountries(value.map((elem: any) => elem.value));
                        }}
                        allowSelectAll
                    />
                    <DialogTextField
                        value={tokenValue}
                        onChange={(e: any) => {
                            setToken(e.target.value);
                        }}
                        label={'API Token'}
                        placeholder={loadingToken ? 'Loading...' : 'API Token'}
                        isDisabled={loadingToken || !editingToken}
                        InputProps={!editingToken ? { endAdornment: deleteTokenAdornment } : undefined}
                    />
                </SettingsContainer>
            </>
        );
    };

    const renderStingraySettings = () => {
        return (
            <>
                <SVGInline src={logos.StingrayLogoSettings} />
                <SettingsContainer>
                    <DialogTextField
                        value={tokenValue}
                        onChange={(e: any) => {
                            setToken(e.target.value);
                        }}
                        label={'API Token'}
                        placeholder={loadingToken ? 'Loading...' : 'API Token'}
                        isDisabled={loadingToken || !editingToken}
                        InputProps={!editingToken ? { endAdornment: deleteTokenAdornment } : undefined}
                    />
                </SettingsContainer>
            </>
        );
    };

    const renderSettings = () => {
        switch (selectedService) {
            case contentSourceTypes.YOUTUBE:
            case contentSourceTypes.YOUTUBE_RECO:
                return renderYoutubeSettings();
            case contentSourceTypes.PRIME_VIDEO:
                return renderPrimeVideoSettings();
            case contentSourceTypes.STINGRAY_KARAOKE:
                return renderStingraySettings();
            default:
                return <></>;
        }
    };

    return (
        <GenericDialog type={DialogTypes.Form} title={'Settings'} actionButtons={[cancelButton, saveButton]} onClose={handleClose}>
            {renderSettings()}
        </GenericDialog>
    );
};
