import React, { useEffect, useState } from 'react';
import { DIALOG_NAMES, DIALOG_TITLES_MAP } from '../../../../utils/fnDialogs';

import GenericDialog, { DialogButton, DialogFileField, DialogTypes } from '../../Dialog/GenericDialog';
import { BrandingDialogStyle, DialogFileFieldBrandingWrapper } from '../Profile.css';

export type ProjectLayoutProps = {
    open: boolean;
    onSave: any;
    onClose: any;
};

const ProjectLayoutDialog: React.FC<ProjectLayoutProps> = ({ open, onSave, onClose }) => {
    const [isOpen, setIsOpen] = useState(open);

    const { title, subtitle } = DIALOG_TITLES_MAP.get(DIALOG_NAMES.ADD_BRANDING) || {};

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const handleCloseClick = (evt: any) => {
        evt.preventDefault();
        onClose();
    };

    const handleSaveClick = (evt: any) => {
        evt.preventDefault();
        onClose();
    };

    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleSaveClick
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    return isOpen ? (
        <GenericDialog
            type={DialogTypes.Form}
            title={title as string}
            subtitle={subtitle as string}
            onClose={handleCloseClick}
            actionButtons={[cancelButton, saveButton]}
            style={BrandingDialogStyle}
        >
            <DialogFileFieldBrandingWrapper>
                <DialogFileField
                    imageInfo={{ previewImage: true, height: 210, width: 190, um: 'px' }}
                    fieldKey="login logo (240x135px)"
                    alwaysShowLabel
                />
                <DialogFileField
                    imageInfo={{ previewImage: true, height: 210, width: 190, um: 'px' }}
                    fieldKey="menu logo (148x43)"
                    alwaysShowLabel
                />
                <DialogFileField
                    imageInfo={{ previewImage: true, height: 210, width: 190, um: 'px' }}
                    fieldKey="bg image (720x810)"
                    alwaysShowLabel
                />
            </DialogFileFieldBrandingWrapper>
        </GenericDialog>
    ) : null;
};

export default ProjectLayoutDialog;
