(function (w, d, s, o, f, js, fjs) {
    w['circleWidget'] = o;
    w[o] =
        w[o] ||
        function () {
            (w[o].q = w[o].q || []).push(arguments);
        };
    (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
    js.id = o;
    js.src = f;
    js.async = 1;
    fjs.parentNode.insertBefore(js, fjs);
})(window, document, 'script', 'mw', 'https://plus.3ready.tv/external/widget.js');

mw('init', {
    community_public_uid: '172a89e4',
    brand_color: '#0066FF'
});

mw('setDefaults', {
    space_slug: 'cc-documentation',
    post_slug: ''
});

export const setupDocumentation = () => {
    const isSafari =
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;
    const widget = document.getElementsByClassName('circle-widget-trigger').length
        ? document.getElementsByClassName('circle-widget-trigger')[0]
        : null;
    if (!widget) return null;

    const closeButton = document.getElementsByClassName('button-close').length ? document.getElementsByClassName('button-close')[0] : null;
    if (closeButton) {
        closeButton.innerHTML = '<div><img /></div>';
        closeButton.onclick = () => {
            iFrame?.removeAttribute('src');
        };
    }

    const iFrame = document.getElementById('circleWidgetIframe');
    const iFrameBaseSrc = iFrame?.getAttribute('src')?.slice(0, iFrame.getAttribute('src').indexOf('space_slug=')) || '';
    const setSlug = (slug, spaceSlug = 'cc-documentation', fullUrl = false) => {
        if (isSafari) {
            const url = fullUrl ? slug : iFrameBaseSrc + 'space_slug=' + spaceSlug + '&post_slug=' + slug;
            window.open(url, '_blank');
            return;
        }
        /*
         * Whenever you reuse an iframe and only change its src attribute to point to some other content,
         * it’s treated as a content navigation, and the iframe’s current` src gets pushed onto the browser’s window.history stack.
         * When you later try to navigate backward, your browser pops the top of the history stack, navigating the iframe itself.
         *
         * In order to eliminate this bug we have to remount the whole iFrame everytime we change the slug, to have proper back navigation in the app
         * */
        const oldiFrame = document.getElementById('circleWidgetIframe');
        const iFrame = oldiFrame?.cloneNode();
        if (!iFrame) return;
        if (fullUrl) {
            iFrame.setAttribute('src', slug);
        } else {
            iFrame.setAttribute('src', iFrameBaseSrc + 'space_slug=' + spaceSlug + '&post_slug=' + slug);
        }
        iFrame.style.display = 'none';
        document.body.style.cursor = 'wait';
        closeButton?.removeAttribute('style');
        const parentNode = document.querySelector('.circle-widget-overlay');
        parentNode.replaceChild(iFrame, oldiFrame);
        setTimeout(() => {
            // there was a requirement to have a delay until the documentation page actually changes only then to open the dialog
            // there is no way for us to know when the actual page is loaded in the iFrame, so we wait for 2 seconds
            iFrame.removeAttribute('style');
            document.body.removeAttribute('style');
            closeButton?.setAttribute('style', 'display: flex');
        }, 2000);
    };

    return {
        element: isSafari ? null : widget,
        setSlug
    };
};
