import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { encodedDataParser } from '../../utils/parsers';

export const logUserIn = createAsyncThunk<{ userData: any; error: { message: string; code: string } | null }, any>(
    'users/logUserInStatus',
    async (params: { email: string; password: string; rememberMe?: boolean }, thunkApi) => {
        const { email, password, rememberMe } = params;
        const result = await ConfigServiceAPI.login(email, password, rememberMe);
        if (result.error || !result.response?.data) {
            return thunkApi.rejectWithValue(result.error);
        }
        if (Object.keys(result.response.data).length > 1) {
            let userData: any = {};
            userData['userId'] = result.response.data.userId;
            userData['userInfo'] = encodedDataParser(result.response.data.userInfo);
            userData['permissions'] = result.response.data.permissions;
            userData['rememberMe'] = rememberMe;
            return {
                userData,
                error: null
            };
        }
        return {
            userData: {
                userId: result.response?.data,
                rememberMe
            },
            error: null
        };
    }
);

export const logUserInWithOTP = createAsyncThunk<{ userData: any; error: { message: string; code: string } | null }, any>(
    'users/logUserInWithOTPStatus',
    async (params: { phoneNumber: string; otp: string; email: string; password: string; rememberMe?: boolean }, thunkApi) => {
        const { email, password, rememberMe, phoneNumber, otp } = params;
        const result = await ConfigServiceAPI.verifyOTPLogin(phoneNumber, otp, email, password, rememberMe);
        if (result.error || !result.response?.data) {
            return thunkApi.rejectWithValue(result.error);
        }
        if (Object.keys(result.response.data).length > 1) {
            let userData: any = {};
            userData['userId'] = result.response.data.userId;
            userData['userInfo'] = encodedDataParser(result.response.data.userInfo);
            userData['permissions'] = result.response.data.permissions;
            userData['rememberMe'] = rememberMe;
            return {
                userData,
                error: null
            };
        }
        return {
            userData: {
                userId: result.response?.data,
                rememberMe
            },
            error: null
        };
    }
);

export const logUserOut = createAsyncThunk('users/logUserOutStatus', async (_, thunkApi) => {
    const result = await ConfigServiceAPI.logout();
    if (result.error) {
        return thunkApi.rejectWithValue(result.error);
    }
    return { user: null, error: null };
});

export interface AuthState {
    userId: string;
    loggedIn: boolean;
    loggedInAt: number | null;
    sessionExpiresAt: number | null;
    error: {
        message: string;
        code?: string;
    } | null;
    loading: boolean;
}

const initialState: AuthState = {
    userId: '',
    loggedIn: false,
    loggedInAt: null,
    error: null,
    sessionExpiresAt: null,
    loading: false
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(logUserIn.fulfilled, (state, action) => {
                const oneDayMillis = 86400000;
                state.userId = action.payload.userData.userId;
                state.loggedIn = true;
                state.loggedInAt = Date.now();
                state.sessionExpiresAt = Date.now() + (!action.payload.userData.rememberMe ? oneDayMillis : 15 * oneDayMillis);
                state.loading = false;
            })
            .addCase(logUserIn.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(logUserIn.rejected, (state, action: any) => {
                state.error = {
                    message: action.payload.message,
                    code: action.payload.code
                };
                state.loggedIn = false;
                state.userId = '';
                state.loggedInAt = null;
                state.sessionExpiresAt = null;
                state.loading = false;
            })
            .addCase(logUserInWithOTP.fulfilled, (state, action) => {
                const oneDayMillis = 86400000;
                state.userId = action.payload.userData.userId;
                state.loggedIn = true;
                state.loggedInAt = Date.now();
                state.sessionExpiresAt = Date.now() + (!action.payload.userData.rememberMe ? oneDayMillis : 15 * oneDayMillis);
                state.loading = false;
            })
            .addCase(logUserInWithOTP.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(logUserInWithOTP.rejected, (state, action: any) => {
                state.error = {
                    message: action.payload.message,
                    code: action.payload.code
                };
                state.loggedIn = false;
                state.userId = '';
                state.loggedInAt = null;
                state.sessionExpiresAt = null;
                state.loading = false;
            })
            .addCase(logUserOut.fulfilled, (state) => {
                state.userId = '';
                state.loggedIn = false;
                state.loggedInAt = null;
                state.sessionExpiresAt = null;
            })
            .addCase(logUserOut.rejected, (state, action: any) => {
                state.error = {
                    message: action.payload.message,
                    code: action.payload.code
                };
            });
    }
});

export default slice.reducer;
