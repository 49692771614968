import { PlacedObjectType } from './Object';

export type DisplayCondition = {
    _id: string;
    name: string;
    tenantId: string;
    projectId: string;
    modifiedBy: string;
    modifiedByUser?: {
        name: string;
        icon?: string;
    };
    weekDays?: {
        is: boolean;
        value: string[];
    };
    dateInterval?: {
        is: boolean;
        value: {
            startDate: number; //timestamp
            endDate: number; //timestamp
        }[];
    };
    timeInterval?: {
        is: boolean;
        value: {
            startTime: number; //no of milis from start of day
            endTime: number; //no of milis from start of day
        }[];
    };
    languages?: {
        is: boolean;
        value: string[];
    };
    countries?: {
        is: boolean;
        value: string[];
    };
    ageRating?: {
        is: boolean;
        value: number;
    };
    subscriptionStatus?: {
        is: boolean;
        value: string;
    };
    happinessScore?: {
        is: boolean;
        value: string;
    };
    persona?: {
        is: boolean;
        value: string;
    };
    guestMode?: {
        is: boolean;
        value: boolean;
    };
    coldStart?: {
        is: boolean;
        value: boolean;
    };
    segments?: {
        is: boolean;
        value: string[];
    };
    dataProtectionRegulation?: {
        is: boolean;
        value: string[];
    };
    state?: {
        is: boolean;
        value: string;
    };
    city?: {
        is: boolean;
        value: string;
    };
    lastModified?: number;
    placed?: { _id: string; name: string; type: PlacedObjectType }[];

    locked?: string;
    lockedByUser?: {
        name: string;
        icon?: string;
    };
};

export const SubscriptionOptions = ['Other', 'Standard with Ads', 'Basic', 'Standard', 'Premium', 'Trial'];

export enum CategoriesKeys {
    WEEK_DAYS = 'weekDays',
    TIMES = 'timeInterval',
    DATES = 'dateInterval',
    LANGUAGES = 'languages',
    COUNTRIES = 'countries',
    RATING = 'ageRating',
    SUBSCRIBED = 'subscriptionStatus',
    HAPPINESS = 'happinessScore',
    PERSONA = 'persona',
    GUEST_MODE = 'guestMode',
    COLD_START = 'coldStart',
    SEGMENTS = 'segments',
    DATA_PROTECTION_REGULATION = 'dataProtectionRegulation',
    STATE = 'state',
    CITY = 'city'
}

export const keysTitle: Record<CategoriesKeys, string> = {
    [CategoriesKeys.WEEK_DAYS]: 'Week Days',
    [CategoriesKeys.TIMES]: 'Start & End Time',
    [CategoriesKeys.DATES]: 'Start & End Date',
    [CategoriesKeys.LANGUAGES]: 'Language',
    [CategoriesKeys.RATING]: 'Age Rating',
    [CategoriesKeys.COUNTRIES]: 'Country',
    [CategoriesKeys.SUBSCRIBED]: 'Subscribed',
    [CategoriesKeys.HAPPINESS]: 'Happiness Score',
    [CategoriesKeys.PERSONA]: 'Persona',
    [CategoriesKeys.GUEST_MODE]: 'User Status',
    [CategoriesKeys.COLD_START]: 'Cold Start',
    [CategoriesKeys.SEGMENTS]: 'Segments',
    [CategoriesKeys.DATA_PROTECTION_REGULATION]: 'Data Protection Regulation',
    [CategoriesKeys.STATE]: 'State',
    [CategoriesKeys.CITY]: 'City'
};

export const KeysPlaceHolders: Record<CategoriesKeys, string> = {
    [CategoriesKeys.WEEK_DAYS]: 'Select Week Day(s)',
    [CategoriesKeys.TIMES]: 'Start & End Time',
    [CategoriesKeys.DATES]: 'Start & End Date',
    [CategoriesKeys.LANGUAGES]: 'Select language(s)',
    [CategoriesKeys.RATING]: 'Select Rating',
    [CategoriesKeys.COUNTRIES]: 'Select Country/Countries',
    [CategoriesKeys.SUBSCRIBED]: 'Select Subscription(s)',
    [CategoriesKeys.HAPPINESS]: 'Happiness Score',
    [CategoriesKeys.PERSONA]: 'Persona',
    [CategoriesKeys.GUEST_MODE]: 'User Status',
    [CategoriesKeys.COLD_START]: 'Cold Start',
    [CategoriesKeys.SEGMENTS]: 'Select Segments',
    [CategoriesKeys.DATA_PROTECTION_REGULATION]: 'Data Protection Regulation',
    [CategoriesKeys.STATE]: 'State',
    [CategoriesKeys.CITY]: 'City'
};

export const valueKeys = Object.values(CategoriesKeys);

export const booleanCategoryKeys = [CategoriesKeys.GUEST_MODE, CategoriesKeys.COLD_START];
