import React, { FC } from 'react';
import { AnalyticsWidget } from '../Analytics.css';
import WidgetHeader from './WidgetHeader';
import { AnalyticsChangeIndicator, calculateChangePercentAndIndicator, formatNumber, formatSeconds } from '../Charts/ChartUtils';

export const keysWithMinutesValue = ['average_session_duration', 'average_duration', 'average_playback_session', 'average_playback_try'];

const ChartlessWidget: FC<{
    dataKey: string;
    title: string;
    value: number;
    comparedValue?: number;
    comparedTimeframeLabel: string;
    subtitle: string;
}> = ({ dataKey, title, subtitle, value, comparedValue, comparedTimeframeLabel }) => {
    const valueToShow = keysWithMinutesValue.includes(dataKey) ? formatSeconds(value) : formatNumber(value);
    const comparedValueToShow = keysWithMinutesValue.includes(dataKey)
        ? formatSeconds(comparedValue || 0)
        : formatNumber(comparedValue || 0);

    const { percentChange, changeIndicator } = calculateChangePercentAndIndicator(value, comparedValue || 0);

    return (
        <AnalyticsWidget withoutGraph>
            <WidgetHeader
                title={title}
                subtitle={subtitle}
                value={valueToShow}
                percentChange={percentChange}
                changeIndicator={changeIndicator}
                comparedTimeframeLabel={comparedTimeframeLabel}
                comparedValueToShow={comparedValueToShow || ''}
            />
        </AnalyticsWidget>
    );
};

export default ChartlessWidget;
