import React, { useEffect, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import GenericDialog, { DialogTypes } from '../GenericDialog';
import icons from '../../../../style';

import { CreateResourceCardsContainer, CreateResourceIconContainer, TemplateCard, TemplateName } from '../CreateResourceDialog.css';
import { CIRCLE_SLUGS } from '../../HelpIcon/HelpIcon';

export type AddFileProps = {
    open: boolean;
    onClose: any;
    handleFileManagerClick: any;
    handleOpenURLClick: any;
};

const AddFileDialog: React.FC<AddFileProps> = ({ open, onClose, handleFileManagerClick, handleOpenURLClick }) => {
    const [isOpen, setIsOpen] = useState(open);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const handleCloseClick = (evt: any) => {
        evt.preventDefault();
        if (onClose) {
            onClose();
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <GenericDialog
            title={`Add File`}
            type={DialogTypes.Resource}
            onClose={handleCloseClick}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.file_manager }}
        >
            <CreateResourceCardsContainer>
                <TemplateCard onClick={() => handleFileManagerClick?.()}>
                    <CreateResourceIconContainer>
                        <SVGInline src={icons.addItemIconLight} />
                    </CreateResourceIconContainer>

                    <div>
                        <TemplateName>Choose File</TemplateName>
                    </div>
                </TemplateCard>
                <TemplateCard onClick={() => handleOpenURLClick?.()}>
                    <CreateResourceIconContainer>
                        <SVGInline src={icons.templateIcon} />
                    </CreateResourceIconContainer>
                    <div>
                        <TemplateName>From Open URL</TemplateName>
                    </div>
                </TemplateCard>
            </CreateResourceCardsContainer>
        </GenericDialog>
    );
};

export default AddFileDialog;
