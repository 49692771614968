import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Menu } from '../../types/Menu';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';

export const fetchMenus = createAsyncThunk<
    { menus: Menu[]; error: { message: string; code: string } | null; permissions?: any },
    any | undefined
>('menus/fetchMenus', async ({ addPermissions, projectId, orderBy, searchTerm, filter }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllMenus(addPermissions, projectId, orderBy, searchTerm, filter);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { menus: result.response as any as Menu[], error: null, permissions: result.permissions };
});

export const fetchMenuTypes = createAsyncThunk<{ menuTypes: any[]; error: { message: string; code: string } | null }, string | undefined>(
    'menus/fetchMenuTypes',
    async (projectId, thunkApi) => {
        const result = await ConfigServiceAPI.getMenuTypes(projectId);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { menuTypes: result.response as any, error: null };
    }
);

export const fetchMenu = createAsyncThunk<{ menu: Menu; error: { message: string; code: string } | null }, string>(
    'menus/fetchMenu',
    async (menuId, thunkApi) => {
        const result = await ConfigServiceAPI.getMenuById(menuId);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { menu: result.response as any as Menu, error: null };
    }
);

export const createMenu = createAsyncThunk<
    { id: string; error: { message: string; code: string } | null },
    { menu: Menu; addObjectToGroup?: boolean }
>('menus/createMenu', async ({ menu, addObjectToGroup = false }, thunkApi) => {
    const result = await ConfigServiceAPI.createMenu(menu, addObjectToGroup);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { id: result.response as any as string, error: null };
});

export const updateMenu = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { menu: Menu; shouldUnlockAfterSave?: boolean; shouldRepublish?: boolean; addObjectToGroup?: boolean }
>('menus/updateMenu', async ({ menu, shouldUnlockAfterSave, shouldRepublish, addObjectToGroup }, thunkApi) => {
    const result = await ConfigServiceAPI.updateMenu(menu, shouldUnlockAfterSave, shouldRepublish, addObjectToGroup);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const deleteMenu = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'menus/deleteMenu',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.deleteMenu(id);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export const publishMenu = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'menus/publishMenu',
    async (id, thunkApi) => {
        const result = await ConfigServiceAPI.publishMenu(id);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }

        return { ok: !!result.response, error: null };
    }
);

export const abortPublishMenu = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, { menuId: string }>(
    'menus/abortPublishMenu',
    async ({ menuId }, thunkApi) => {
        const result = await ConfigServiceAPI.abortPublishMenu(menuId);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export interface menusState {
    menus: Menu[];
    menuTypes: any[];
    loadingMenuTypes: boolean;
    selectedMenu?: Menu;
    loading: boolean;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: menusState = {
    menus: [],
    menuTypes: [],
    loadingMenuTypes: false,
    loading: false,
    error: null
};

const slice = createSlice({
    name: 'menus',
    initialState,
    reducers: {
        unsetMenuError(state) {
            state.error = null;
        },
        unsetSelectedMenu(state) {
            state.selectedMenu = undefined;
        },
        unsetMenus(state) {
            state.menus = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMenus.fulfilled, (state, action: any) => {
                state.menus = action.payload.menus;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchMenus.rejected, (state, action: any) => {
                state.menus = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchMenus.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchMenuTypes.fulfilled, (state, action: any) => {
                state.menuTypes = action.payload.menuTypes;
                state.error = null;
                state.loadingMenuTypes = false;
            })
            .addCase(fetchMenuTypes.rejected, (state, action: any) => {
                state.menuTypes = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loadingMenuTypes = false;
            })
            .addCase(fetchMenuTypes.pending, (state, _action) => {
                state.loadingMenuTypes = true;
            })
            .addCase(fetchMenu.fulfilled, (state, action: any) => {
                state.selectedMenu = action.payload.menu;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchMenu.rejected, (state, action: any) => {
                state.selectedMenu = undefined;
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchMenu.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createMenu.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createMenu.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(createMenu.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateMenu.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateMenu.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(updateMenu.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteMenu.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteMenu.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteMenu.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(publishMenu.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(publishMenu.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(publishMenu.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(abortPublishMenu.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(abortPublishMenu.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(abortPublishMenu.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            });
    }
});

export const { unsetMenuError, unsetSelectedMenu, unsetMenus } = slice.actions;
export default slice.reducer;
