import { useNavigate } from 'react-router-dom';
import Button from '../../Buttons/Button/Button';

export default function NoAccess() {
    const navigate = useNavigate();
    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <div>You do not have access to this route!</div>
            <Button type={'BLUE'} label={'Take me back'} onClick={() => navigate(-1)} />
        </div>
    );
}
