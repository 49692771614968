import ReactDOMServer from 'react-dom/server';

export const calculateElementsToShow = (elements: (JSX.Element | string)[], containerWidth: number): number => {
    let totalWidth = 0;
    let maxToShow = 0;

    elements.forEach((element) => {
        const tempElement = document.createElement('div');

        tempElement.style.font = 'Inter';
        tempElement.style.fontSize = '12px';
        tempElement.style.fontWeight = 'normal';
        tempElement.style.letterSpacing = '0.25px';
        tempElement.style.position = 'absolute';
        tempElement.style.visibility = 'hidden';
        tempElement.style.whiteSpace = 'nowrap';

        if (typeof element === 'string') {
            tempElement.textContent = element;
        } else {
            tempElement.innerHTML = ReactDOMServer.renderToString(element);
        }

        document.body.appendChild(tempElement);
        const labelWidth = tempElement.offsetWidth;
        document.body.removeChild(tempElement);

        const elementWidth = labelWidth + 21 + 16 + 2 + 4; // label width + remove button + padding + border + margin
        totalWidth += elementWidth > 111 ? 111 : elementWidth;

        // 52px is the space allocated for the show more button + 6px for text input
        // TODO: after the search input is moved inside the dropdown, this should be 52px
        if (totalWidth + 58 < containerWidth) {
            maxToShow++;
        }
    });

    return maxToShow;
};
