import { supportedResolutionsType } from '../components/Items/Dialogs/BackgroundDialog';
import getTheme from '../style/themes/theme';

const theme: any = getTheme();

export const alphaToHex = (alpha: any) => {
    return Math.round(alpha * 255).toString(16);
};
export const alphaHexToRGBA = (alphaHex: string) => {
    alphaHex = alphaHex.slice(1);
    let index = 0,
        a = 1;
    if (alphaHex.length === 8) {
        a = Number((parseInt(alphaHex.slice(index, (index += 2)), 16) / 255).toFixed(2));
    }
    const r = parseInt(alphaHex.slice(index, (index += 2)), 16);
    const g = parseInt(alphaHex.slice(index, (index += 2)), 16);
    const b = parseInt(alphaHex.slice(index, (index += 2)), 16);
    return { r: r, g: g, b: b, a: a };
};

export const generateGradientValues = (startPercent: number, endPercent: number, num: number) => {
    const step = (endPercent - startPercent) / num;
    const opacityStep = 100 / num;
    const values = [];
    for (let i = startPercent, j = opacityStep; i < endPercent; i += step, j += opacityStep) {
        values.push(`rgba(0,0,0,${j}) ${i}%`);
    }
    return values.join(',');
};

export const calculateItemBackgroundToShow = (
    item: any,
    isHero = false,
    defaultValue = theme.palette.text.main,
    resolution: any = null
) => {
    const color = isHero ? item.heroBackgroundColor : item.backgroundColor;
    const image = isHero ? item.heroBackgroundImage : item.backgroundImage;
    if (color) {
        const { r, g, b, a } = alphaHexToRGBA(color);
        return `rgba(${r}, ${g}, ${b}, ${a})`;
    }
    if (image) {
        if (typeof image === 'string') {
            return `url('${decodeURIComponent(image)}')`;
        }
        const url = resolution ? image?.[resolution] || '' : Object.values(image).find((val) => !!val);
        if (!url) return '';
        return `url('${decodeURIComponent(`${url}`)}')`;
    }
    return defaultValue;
};
