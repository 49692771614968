import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { ButtonWrapper } from '../Buttons/Button/Button.css';
import { devices } from '../../style/styled-components/reusable.css';

const theme: any = getTheme();

export const ActionIconHolder = styled.div``;

// GRID VIEW
export const GridViewWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: unset;
    ${devices.tablet} {
        justify-content: center;
    }
    gap: 24px;
`;
export const GroupAddGrid = styled.div`
    width: 360px;
    height: 244px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed ${theme.palette.background.border};
    border-radius: 4px;
    background-color: ${theme.palette.white.main};
    box-sizing: border-box;
    &:hover {
        cursor: pointer;
    }
`;
export const ActionsContainer = styled.div`
    line-height: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    ${ActionIconHolder} {
        &:hover {
            cursor: pointer;
        }
        display: none;
        &:last-of-type {
            display: block;
        }
    }
`;
export const GroupContainerGrid = styled.div`
    width: 360px;
    height: 244px;
    padding: 24px 36px;
    border-radius: 4px;
    gap: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.palette.white.main};
    ${ButtonWrapper} {
        margin: 0;
    }
    &:hover {
        ${ActionsContainer} {
            ${ActionIconHolder} {
                display: inline;

                &:last-of-type {
                    display: none;
                }
            }
        }
    }
`;
export const GroupContainerGridName = styled.div`
    ${fontSizesCSS.Title3};
    align-self: flex-start;
    margin-bottom: 8px;
    max-width: 288px;
`;
export const GroupContainerListName = styled.div`
    max-width: 400px;
    ${devices.desktopS} {
        max-width: 280px;
    }
    ${devices.tablet} {
        max-width: 100px;
    }
`;
export const GroupContainerGridActionRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    //Remove the margin from the checkbox
    a {
        margin: 0;
    }
`;

// LIST VIEW
export const GroupAddList = styled.div`
    ${fontSizesCSS.Body2}
    background-color: ${theme.palette.white.main};
    box-sizing: border-box;
    padding: 26px 24px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    &:hover {
        cursor: pointer;
        background-color: ${theme.palette.background.menuItemHover};
    }
`;
export const ListViewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1px;
    ${ActionsContainer} {
        margin-left: auto;
    }
`;
export const GroupContainerList = styled.div`
    background-color: ${theme.palette.white.main};
    box-sizing: border-box;
    padding: 26px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    a {
        margin-bottom: 0;
    }
    &:hover {
        background-color: ${theme.palette.background.menuItemHover};
        ${ActionsContainer} {
            ${ActionIconHolder} {
                display: inline;

                &:last-of-type {
                    display: none;
                }
            }
        }
    }
`;
export const CheckboxContainerList = styled.div`
    width: 7%;
    min-width: fit-content;
`;
export const GroupNameContainerList = styled.div`
    ${fontSizesCSS.Body2}
    width: 40%;
    min-width: fit-content;
    display: flex;
    gap: 4px;
    align-items: center;
`;
export const ButtonsContainerList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    ${ButtonWrapper} {
        margin: 0;
        width: fit-content;
    }
`;

// AB TESTING
export const GroupIconsContainer = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
`;
export const ABTestingIndicator = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 4px 8px;
    border: 1px solid ${theme.palette.background.hovered};
    border-radius: 33px;
    svg {
        width: 8px;
        height: 8px;
        path {
            fill: ${theme.palette.background.hovered};
        }
    }
    span {
        ${fontSizesCSS.Caption4};
        line-height: 10px;
        cursor: default;
    }
`;
