import styled from 'styled-components';
import { NameWrapper } from '../Modules/Modules.css';

export const ObjectWithUnpublishedChangesNameContainer = styled(NameWrapper)``;

export const ObjectWithUnpublishedChangesIcon = styled.div`
    display: flex;
    align-items: center;
    span,
    svg {
        height: 18px;
        width: 18px;
    }
`;

export const EmptyUnpublishedChangesTextHolder = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const RevertObjectsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 16px;
    svg {
        width: 40px;
        height: 40px;
    }
`;

export const UnpublishedContentWrapper = styled.div<{ $extraPadding?: boolean }>`
    ${(props) => props.$extraPadding && 'padding-bottom: 72px'}
`;
