import styled, { CSSProperties } from 'styled-components';
import { RadioText } from '../../common/Dialog/GenericDialog.css';
import { HideWhenSelected } from '../../common/Select/Select.css';

export const ModuleTemplateIconHolder = styled.div`
    height: 24px;
    svg {
        height: 24px;
    }
`;

export const OptionWrapper = styled.div`
    width: calc(100% - 26px);
    height: 36px;
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const PublishedStatusIconHolder = styled.div`
    height: 36px;
    display: flex;
    align-items: center;
`;

export const useExistingDialogStyle: CSSProperties = {
    width: '608px'
};

export const ExistingMenuOptionsWrapper = styled.div`
    display: flex;
    gap: 24px;

    ${RadioText} {
        font-size: 12px;
        font-weight: unset;
    }
`;

export const LabelsWrapper = styled(HideWhenSelected)`
    margin-left: auto;
    min-width: 125px;
    display: flex;
    width: 125px;
    height: 28px;
    gap: 4px;
`;

export const ValueToDisplayWrapper = styled.div`
    display: flex;
    gap: 4px;
`;

export const ValueToDisplayIconsPlaceholder = styled.div`
    width: 28px;
`;
