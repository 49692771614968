import React, { FC, useEffect, useState } from 'react';
import { fetchAllLanguageTranslations, fetchLanguageCodes, LanguagesState } from '../../../redux/slices/languagesSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { getTranslationKeys } from '../../../utils/fnData';
import {
    TooltipKey,
    TooltipName,
    TooltipTranslation,
    TranslationToolTipContainer,
    TranslationToolTipRow
} from '../../Modules/Dialogs/NewModule.css';
import SVGInline from 'react-inlinesvg';
import { icons } from '../../../style';
import { renderTooltip, tooltipPositions, tooltipTypes } from '../Tooltips/Tooltips';
import {
    TranslationTooltipIconContainer,
    VisualEditorTranslationContainer,
    VisualEditorTranslationTextWrapper,
    VisualEditorTranslationTooltipIcon
} from './TranslationTooltip.css';
import { NewTranslation } from '../../Translations/NewTranslationKeyDialog';
import { IconButton, InputAdornment } from '@material-ui/core';
import useTranslation from '../../../hooks/useTranslation';

// These variables are use to prevent multiple loading of the languages/codes
let startedLoadingTranslations = false;
let startedLoadingCodes = false;
let projectIdForCurrentLanguages = '';

const newTranslationKeyTooltipText = 'Create or Choose Translation';

type TranslationTooltipProps = {
    translationKey?: string;
    position?: tooltipPositions;
    iconColor?: string;
    showNewTranslationKey?: boolean;
    isVisualEditor?: boolean;
    isTextField?: boolean;
    handleKeyChange?: (e: string) => void;
};

const TranslationTooltip: FC<TranslationTooltipProps> = ({
    translationKey,
    position,
    iconColor,
    showNewTranslationKey,
    isVisualEditor,
    isTextField,
    handleKeyChange
}) => {
    const { languageCodes, allTranslations }: LanguagesState = useAppSelector((state) => state.languages);
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const dispatch = useDispatch();
    const { shouldDisplayTranslations } = useTranslation();

    const [opeNewTranslationKeyDialog, setOpenNewTranslationKeyDialog] = useState<boolean>(false);
    const [translationKeyToEdit, setTranslationKeyToEdit] = useState<string>('');

    useEffect(() => {
        if (!activeProjectId) return;
        loadCodes();
        loadAllTranslations();
    }, [activeProjectId]);

    const loadCodes = async () => {
        if (!languageCodes?.length && !startedLoadingCodes) {
            startedLoadingCodes = true;
            await dispatch(fetchLanguageCodes()).unwrap();
        }
    };
    const loadAllTranslations = async () => {
        if (activeProjectId !== projectIdForCurrentLanguages || (!allTranslations && !startedLoadingTranslations)) {
            startedLoadingTranslations = true;
            projectIdForCurrentLanguages = activeProjectId || '';
            await dispatch(fetchAllLanguageTranslations({ projectId: activeProjectId || '' })).unwrap();
        }
    };

    if (!translationKey) return null;
    const translationKeys = allTranslations ? getTranslationKeys(translationKey, allTranslations, languageCodes, true) : [];

    const tooltip = (
        <TranslationToolTipContainer>
            {shouldDisplayTranslations && (
                <TranslationToolTipRow>
                    <TooltipKey> {translationKey}</TooltipKey>{' '}
                </TranslationToolTipRow>
            )}
            {translationKeys.map((key: any, index: number) => {
                const { flag, name } = languageCodes.find((elem) => elem.code === key.code) || {};
                return (
                    <TranslationToolTipRow key={index}>
                        <img src={flag} alt=" " />
                        <TooltipName>{name}</TooltipName>
                        <TooltipTranslation>{key.translation || '[Translation missing]'}</TooltipTranslation>
                    </TranslationToolTipRow>
                );
            })}
        </TranslationToolTipContainer>
    );

    const renderExistingTranslationTooltip = () => {
        return renderTooltip(
            <TranslationTooltipIconContainer iconColor={isVisualEditor ? 'white' : iconColor}>
                <SVGInline src={icons.languagesIcon} />
            </TranslationTooltipIconContainer>,
            tooltipTypes.HTML,
            tooltip,
            position ?? tooltipPositions.BOTTOM,
            false
        );
    };

    const renderNewTranslationKeyTooltip = () => {
        if (isVisualEditor) {
            return (
                <VisualEditorTranslationContainer>
                    <VisualEditorTranslationTooltipIcon>
                        <SVGInline src={icons.translationsIcon} />
                    </VisualEditorTranslationTooltipIcon>
                    <VisualEditorTranslationTextWrapper>{newTranslationKeyTooltipText}</VisualEditorTranslationTextWrapper>
                </VisualEditorTranslationContainer>
            );
        }

        return renderTooltip(
            <TranslationTooltipIconContainer iconColor={iconColor}>
                <SVGInline src={icons.translationsIcon} />
            </TranslationTooltipIconContainer>,
            tooltipTypes.HTML,
            newTranslationKeyTooltipText,
            position ?? tooltipPositions.BOTTOM,
            false
        );
    };

    const renderTranslationTooltip = () => {
        return translationKeys.length ? (
            renderExistingTranslationTooltip()
        ) : showNewTranslationKey ? (
            renderNewTranslationKeyTooltip()
        ) : (
            <></>
        );
    };

    if (!translationKeys.length && !showNewTranslationKey) return null;

    return (
        <>
            {isTextField ? (
                <InputAdornment position="end">
                    <IconButton
                        onClick={() => {
                            if (!showNewTranslationKey) return;
                            setOpenNewTranslationKeyDialog(true);
                            setTranslationKeyToEdit(translationKey);
                        }}
                    >
                        {renderTranslationTooltip()}
                    </IconButton>
                </InputAdornment>
            ) : (
                renderTranslationTooltip()
            )}
            <NewTranslation
                open={opeNewTranslationKeyDialog}
                onClose={() => {
                    setOpenNewTranslationKeyDialog(false);
                    setTranslationKeyToEdit('');
                }}
                translationKey={translationKeyToEdit}
                onChange={handleKeyChange}
            />
        </>
    );
};

export default TranslationTooltip;
