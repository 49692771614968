import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const sentryDsn =
    process.env.REACT_APP_SENTRY_DSN || 'https://d9006333fac9455a9fef44dc4bf254cd@o4504316022358016.ingest.sentry.io/4504571311947776';

// only init sentry if sentry env var is present
if (sentryDsn) {
    // default config of sentry - to be changed if needed in the future
    Sentry.init({
        dsn: sentryDsn,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0
    });
}

export default Sentry;
