import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Role } from '../../types/Role';
import configServiceAPI from '../../utils/api/configServiceAPI';

export const fetchRoles = createAsyncThunk<{ roles: Role[]; error: { message: string; code: string } | null }>(
    'roles/fetchRoles',
    async (_, thunkApi) => {
        const result = await configServiceAPI.getAllUserRoles();
        if (!result.response || result.error) {
            return thunkApi.rejectWithValue(result);
        }
        return { roles: result.response as any, error: null };
    }
);

export interface RolesState {
    roles: Role[];
    loading: boolean;
    error: {
        message: string;
        code?: string;
        status?: number;
    } | null;
}

const initialState: RolesState = {
    roles: [],
    error: null,
    loading: false
};

const slice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        unsetRoleError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoles.fulfilled, (state, action: any) => {
                state.roles = action.payload.roles;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchRoles.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.roles = [];
                state.loading = false;
            })
            .addCase(fetchRoles.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetRoleError } = slice.actions;
export default slice.reducer;
