import ConditionsCard from './conditions.png';
import ItemsCard from './items.png';
import GroupCard from './group.png';
import UserCard from './user.png';
import InsightsCard from './insights.png';
import LanguagesCard from './languages.png';
import ProjectsCard from './projects.png';
import MenuCard from './menu.png';
import SettingsCard from './settings.png';
import StyleBrandingCard from './style_branding.png';
import UsersCard from './users.png';
import ListCard from './lists.png';
import TargetGroupCard from './target_groups.png';
import UXContentCard from './ux_content.png';
import VisualEditorCard from './visual_editor.png';
import ItemCard from './item.png';

export const OnboardingImages: any = {
    'Display conditions': ConditionsCard,
    Item: ItemCard,
    'User groups': GroupCard,
    User: UserCard,
    Insights: InsightsCard,
    Languages: LanguagesCard,
    Projects: ProjectsCard,
    Menus: MenuCard,
    Settings: SettingsCard,
    'Style and branding': StyleBrandingCard,
    Users: UsersCard,
    List: ListCard,
    'Target groups': TargetGroupCard,
    'Ux content': UXContentCard,
    'Visual editor': VisualEditorCard,
    'Content item': ItemsCard
};
