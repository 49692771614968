import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';

const theme: any = getTheme();

export const NewTranslationDialogWrapper = styled.div`
.MuiInputAdornment-positionStart {
    img {
        width: 26px;
        height: 16px;
        margin-left: 12px;
    }
`;

export const TranslationKeyValueBox = styled.div`
    // 16px width of th translation svg, a minimum 8px a gap between the ellipsis and the icon
    width: calc(100% - 16px - 8px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
export const TranslationKeyLabelContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    svg {
        width: 16px;
        height: 16px;
        path {
            fill: ${theme.palette.text.main};
        }
    }
`;
export const TranslationLabelContainer = styled.div`
    margin-top: 8px;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 4px;
    ${fontSizesCSS.Caption3};
    color: ${theme.palette.label.main};

    img {
        width: 24px;
    }
`;
