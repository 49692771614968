import { withTheme } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import styled, { css } from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';

const theme: any = getTheme();

export const BreadcrumbsWrapper = withTheme(styled(Breadcrumbs)<{ insideTableHeader?: boolean; $unclickable?: boolean }>`
    ${({ insideTableHeader }) =>
        insideTableHeader &&
        css`
            &.MuiTypography-root.MuiBreadcrumbs-root {
                position: unset;
                svg {
                    padding: 0;
                }
            }
        `}
    svg {
        ${(props) => props.$unclickable && 'cursor: unset'};
    }

    .MuiBreadcrumbs-ol {
        flex-wrap: unset;
        overflow: hidden;

        .MuiBreadcrumbs-li {
            &:not(:last-child) {
                min-width: 50px;
            }
            &:first-child {
                min-width: fit-content;
            }
            &:last-child {
                width: fit-content;
            }

            overflow: hidden;
            p {
                ${({ insideTableHeader }) =>
                    insideTableHeader &&
                    css`
                        padding: 0;
                        ${fontSizesCSS.Caption2};
                    `}

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                ${(props) => props.$unclickable && 'cursor: unset'};
            }
        }
    }
`);

export const LinkPlaceholder = styled.p`
    ${fontSizesCSS.Title3};
    color: ${theme.palette.text.main};
    margin: 0;
    text-transform: capitalize;
    cursor: pointer;
`;

export const MobileBreadcrumbsWrapper = styled.div<{ $unclickable?: boolean }>`
    display: flex;
    gap: 8px;
    align-items: center;

    p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        ${(props) => props.$unclickable && 'cursor: unset'};
    }

    svg {
        ${(props) => !props.$unclickable && 'cursor: pointer'};
        min-width: 24px;
    }
`;
