import React, { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { StyledSVGInline } from '../../style/styled-components/reusable.css';
import icons from '../../style';
import {
    ActionButtonsWrapper,
    ActionsContainer,
    AdvancedBackgroundGap,
    ButtonCardPresenter,
    CardActions,
    CardCollectionIndicator,
    CardContainer,
    CardDragIcon,
    CardInfo,
    CardPresenter,
    CardSeparatorContainer,
    CardSeparatorWrapper,
    CardTitle,
    CardTypeIndicator,
    CardTypeSeparator,
    CardWrapper,
    CollectionCardWrapper,
    DeactivatedCardOverlay,
    DividerDiv,
    DragContainer,
    DraggableCardWrapper,
    GradientBackgroundToRightV2,
    GradientBackgroundToTopV2,
    LogoContainer,
    ProviderLogo,
    SVGAddIconContainer,
    SVGRoundIconContainer,
    TemplateCardBox,
    TemplateCardContainer,
    TemplateSizeKey,
    TruncatingTextHolder
} from './VisualEditor.css';
import { Actions } from '../Items/EditorialView';
import SVGInline from 'react-inlinesvg';
import { Item, itemTypes } from '../../types/Item';
import { alphaHexToRGBA, generateGradientValues } from '../../utils/fnColor';

import Button from '../Buttons/Button/Button';
import _ from 'lodash';
import { renderTooltipWithKey } from '../common/Tooltips/Tooltips';
import { getDynamicItems } from '../../utils/fnPreviews';
import { DynamicData } from '../../utils/api/dataHandlers/dynamicData';
import TranslationTooltip from '../common/TranslationTooltip/TranslationTooltip';
import { ButtonWrapper } from './EditorialItemCustomization/EditorialItemCustomization.css';
import { PageStyle, PageStyleColors } from '../../types/PageStyle';
import usePrevious from '../../hooks/usePrevious';
import { UNSAFE_useEffectOnce } from '../../hooks/useEffectOnce';
import { moduleTypes } from '../../utils/Globals';
import { ShowMoreIconWrapper } from '../common/ShowMoreIcon/ShowMoreIcon.css';
import useTranslation from '../../hooks/useTranslation';
import { renderDeactivatedIcon } from '../../utils/fnDeactivateSystem';

interface IDraggableModule {
    id: string;
    type: string;
    moduleType: moduleTypes;
    moveItem: (id: string, to: number) => void;
    findItem: (id: string) => { index: number };
    actions?: Actions[];
    onEdit?: (param?: any) => void;
    onDuplicate?: (param?: any) => void;
    onRemove?: (param?: any) => void;
    template?: any;
    background?: any;
    advancedBackground?: boolean;
    isSearchModule?: boolean;
    draggableDisabled?: boolean;
    isGalleryOrPreview?: boolean;
    withoutSeparator?: boolean;
    withoutTooltip?: boolean;
}

export const DraggableModule: React.FC<IDraggableModule> = ({
    id,
    moveItem,
    findItem,
    actions,
    onEdit,
    onDuplicate,
    onRemove,
    type,
    children,
    template,
    background,
    advancedBackground,
    isSearchModule,
    draggableDisabled,
    moduleType,
    isGalleryOrPreview,
    withoutSeparator,
    withoutTooltip
}) => {
    const originalIndex = findItem(id).index;
    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type,
            item: { id, originalIndex },
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            })
        }),
        [id, originalIndex, moveItem]
    );
    const [, drop] = useDrop(
        () => ({
            accept: type,
            hover({ id: draggedId }: any) {
                if (draggedId !== id) {
                    const { index: overIndex } = findItem(id);
                    moveItem(draggedId, overIndex);
                }
            }
        }),
        [findItem, moveItem]
    );

    const handleIconClick = (evt: any, type: Actions) => {
        evt.preventDefault();
        switch (type) {
            case Actions.EDIT:
                onEdit && onEdit();
                break;
            case Actions.REMOVE:
                onRemove && onRemove();
                break;
            case Actions.DUPLICATE:
                onDuplicate && onDuplicate();
                break;
            default:
                break;
        }
    };

    const opacity = isDragging ? 0.8 : 1;
    return (
        <DraggableCardWrapper
            ref={preview}
            style={{ opacity }}
            background={background}
            $search={isSearchModule}
            $extraMargin={withoutSeparator}
            $isGalleryOrPreview={isGalleryOrPreview}
        >
            {moduleType === moduleTypes.COLLECTION && (
                <>
                    <GradientBackgroundToTopV2 black={10} transparent={30} />
                    <GradientBackgroundToRightV2 black={20} transparent={100} />
                </>
            )}
            {advancedBackground && <AdvancedBackgroundGap />}
            <CardContainer>
                <CardDragIcon $disabled={draggableDisabled} ref={(ref) => !draggableDisabled && drag(drop(ref))} $moduleType={template}>
                    {renderTooltipWithKey(
                        <StyledSVGInline src={icons.dragLightIcon} />,
                        withoutTooltip
                            ? ''
                            : draggableDisabled
                            ? 'pages_edit_icon_drag_and_drop_disabled'
                            : 'pages_edit_icon_drag_and_drop_module'
                    )}
                </CardDragIcon>
                {!!actions?.length && (
                    <ActionButtonsWrapper>
                        {actions.map((action, index) => {
                            if (action === Actions.DUPLICATE) return null;

                            const icon =
                                action === Actions.EDIT
                                    ? icons.editLightIcon
                                    : action === Actions.REMOVE
                                    ? icons.trashLightIcon
                                    : icons.duplicateLightIcon;
                            const tooltipText =
                                action === Actions.EDIT
                                    ? 'pages_edit_icon_edit_module'
                                    : action === Actions.REMOVE
                                    ? 'pages_edit_icon_delete_module'
                                    : 'pages_edit_icon_duplicate_module';

                            return renderTooltipWithKey(
                                <StyledSVGInline key={`${index}_icon`} src={icon} onClick={(evt) => handleIconClick(evt, action)} />,
                                tooltipText
                            );
                        })}
                    </ActionButtonsWrapper>
                )}
                {children}
            </CardContainer>
        </DraggableCardWrapper>
    );
};
export interface ITemplateCardProps {
    type: TemplateSizeKey;
    onPlus?: any;
    isCollection?: boolean;
    disabled?: boolean;
    isFirst?: boolean;
}
export interface IDraggableActionableTemplateCardProps {
    type: TemplateSizeKey;
    item: Item;
    onPlus?: any;
    onView?: () => void;
    onEdit?: any;
    onDuplicate?: any;
    onDelete?: any;
    onCardClick?: any;
    moveItem: (id: string, moduleIndex: number, to: number) => void;
    findItem: (id: string, moduleIndex: number) => { index: number };
    moduleIndex: number;
    dragType: string;
    showMock?: boolean;
    autoCollection?: boolean;
    style?: PageStyle;
    isTemplate?: boolean;
    isCollection?: boolean;
    lastItem?: boolean;
    dragDisabled?: boolean;
}
export const TemplateCard: FC<ITemplateCardProps> = ({ type, onPlus, isCollection, disabled, isFirst }) => {
    const handlePlusClick = (evt: any) => {
        evt.preventDefault();
        onPlus?.();
    };
    const renderAddCard = () => {
        return (
            <TemplateCardContainer
                onClick={(evt: any) => {
                    !disabled && handlePlusClick(evt);
                }}
                $type={type}
                $isCollection={isCollection}
                $isDisabled={disabled}
                $isFirst={isFirst}
            >
                <TemplateCardBox $type={type}>
                    {renderTooltipWithKey(<SVGInline src={icons.addIconWhite} />, 'modules_editor_view_icon_add')}
                </TemplateCardBox>
            </TemplateCardContainer>
        );
    };

    return renderAddCard();
};

export const DraggableActionableTemplateCard: FC<IDraggableActionableTemplateCardProps> = ({
    type,
    item,
    onView,
    onEdit,
    onDelete,
    onCardClick,
    onDuplicate,
    findItem,
    moveItem,
    moduleIndex,
    dragType,
    autoCollection,
    style,
    isTemplate,
    isCollection,
    lastItem,
    dragDisabled
}) => {
    const [dynamicItem, setDynamicItem] = useState<DynamicData | null>(null);
    const [pageColors, setPageColors] = useState<PageStyleColors>({});
    const ref = useRef<ReactElement | null>(null);
    const previousItem = usePrevious(item);
    const { translate } = useTranslation();

    const loadDynamicData = async () => {
        const dItem = await getDynamicItems(item, true, isTemplate);
        if (!Array.isArray(dItem)) {
            setDynamicItem({ ...dItem });
        }
    };

    useEffect(() => {
        if (style && style?.colors) {
            const { colors } = style;
            setPageColors(colors);
        }
    }, [style]);

    UNSAFE_useEffectOnce(() => {
        if (!item || item.itemType === itemTypes.EDITORIAL) return;
        if (previousItem?.dynamicSourceId !== item.dynamicSourceId || !dynamicItem?.image) {
            loadDynamicData();
        }
    }, [item]);

    const itemId = item._id;
    const hasAbTestingGroups = !!item.abTestingGroupIds?.length;
    const isAdminLocked = !!item.adminLocked;
    const originalIndex = findItem(itemId, moduleIndex).index;
    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: dragType,
            item: { itemId, originalIndex },
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            })
        }),
        [itemId, originalIndex, moveItem]
    );
    const [{ isHovering }, drop] = useDrop(
        () => ({
            accept: dragType,
            drop: (draggedItem: { itemId: string }) => {
                if (draggedItem.itemId !== itemId) {
                    moveItem(draggedItem.itemId, moduleIndex, originalIndex);
                }
            },
            collect: (monitor) => ({
                isHovering: monitor?.isOver() && monitor?.canDrop()
            })
        }),
        [findItem, moveItem]
    );

    const handleViewClick = (evt: any) => {
        evt.preventDefault();
        onView?.();
    };

    const handleEditClick = (evt: any) => {
        evt.preventDefault();
        onEdit?.();
    };

    const handleDeleteClick = (evt: any) => {
        evt.preventDefault();
        onDelete?.();
    };

    const handleOnCardClick = (evt: any) => {
        evt.preventDefault();
        onCardClick?.();
    };

    const calculateSelectorBackground = (item: Item) => {
        if (item?.backgroundColor) {
            const { r, g, b, a } = alphaHexToRGBA(item.backgroundColor);
            return `rgba(${r}, ${g}, ${b}, ${a})`;
        }
        if (item?.backgroundImage) {
            const url = Object.values(item.backgroundImage).find((val) => !!val);
            if (!url) return '';
            return `url('${decodeURIComponent(url)}')`;
        }
        return '';
    };

    const noImageIcon = ['16X9_S', '1X1_SQUARED', '1X1_ROUNDED', 'BANNER_S', 'UNKNOWN', 'GALLERY'].includes(type)
        ? icons.photoIcon
        : icons.largePhotoIcon;

    const isButton = type === 'BUTTON';

    const callbackRef = useCallback(
        (node) => {
            if (dragDisabled) return;
            drag(drop(node));
            ref.current = preview(node);
        },
        [drag, preview, dragDisabled]
    );
    const renderButtonCM = () => {
        let buttonStyle: any = null;
        if (!_.isEmpty(pageColors)) {
            buttonStyle = {
                backgroundColor: pageColors?.primary,
                color: pageColors?.body
            };
        }

        const tooltipText =
            item.itemType === itemTypes.EDITORIAL ? 'modules_editor_view_icon_customize' : 'modules_editor_view_icon_preview';

        return (
            <div style={{ display: 'inline' }} ref={callbackRef} onClick={handleOnCardClick}>
                <CardWrapper style={{ opacity: isDragging || isHovering ? 0.8 : 1 }} $type={type}>
                    <ButtonCardPresenter $type={type}>
                        <CardActions $background={'transparent'}>
                            <ActionsContainer>
                                {renderTooltipWithKey(
                                    <SVGInline src={icons.editLightIcon} onClick={handleEditClick} />,
                                    'modules_editor_view_icon_edit'
                                )}
                                {!isTemplate &&
                                    renderTooltipWithKey(
                                        <SVGInline
                                            src={item.itemType === itemTypes.EDITORIAL ? icons.editorialIcon : icons.viewIcon}
                                            onClick={handleViewClick}
                                        />,
                                        tooltipText
                                    )}
                                {renderTooltipWithKey(
                                    <SVGInline src={icons.trashLightIcon} onClick={handleDeleteClick} />,
                                    'modules_editor_view_icon_delete'
                                )}
                            </ActionsContainer>
                        </CardActions>
                        <Button
                            style={
                                item.itemType === itemTypes.EDITORIAL
                                    ? !!buttonStyle && item.action && buttonStyle
                                    : !!buttonStyle && buttonStyle
                            }
                            type={item.itemType === itemTypes.EDITORIAL ? (item?.action ? 'BLUE' : 'WHITE') : 'BLUE'}
                            label={
                                <ButtonWrapper>
                                    {_.truncate(item.itemType === itemTypes.EDITORIAL ? item?.actionName : 'Default', {
                                        length: 10
                                    }) || ' Default'}
                                    {<TranslationTooltip translationKey={item.actionName} />}
                                </ButtonWrapper>
                            }
                        />
                    </ButtonCardPresenter>
                </CardWrapper>
            </div>
        );
    };
    const renderEditorialCard = () => {
        const Wrapper = isCollection ? CollectionCardWrapper : CardWrapper;
        return (
            <div style={{ display: 'inline' }} ref={callbackRef} onClick={handleOnCardClick}>
                <Wrapper
                    style={{ opacity: isDragging ? 0.8 : 1 }}
                    $type={type}
                    $extraMargin={isCollection && lastItem}
                    $itemAdminLocked={isAdminLocked}
                >
                    <>
                        <CardPresenter $disabled={dragDisabled} $type={type} $background={calculateSelectorBackground(item!)}>
                            {!isCollection && (
                                <CardTypeIndicator>
                                    {(hasAbTestingGroups || isAdminLocked || item.deactivated) && (
                                        <>
                                            {isAdminLocked && <SVGInline src={icons.adminLockRed} />}
                                            {item.deactivated && renderDeactivatedIcon()}
                                            {hasAbTestingGroups && <ShowMoreIconWrapper src={icons.abTestingDarkIcon} />}
                                            <CardTypeSeparator />
                                        </>
                                    )}
                                    <SVGInline src={icons.editorialIconDark} />
                                </CardTypeIndicator>
                            )}
                            <CardActions>
                                <DragContainer>
                                    {renderTooltipWithKey(<SVGInline src={icons.dragIcon} />, 'modules_editor_view_icon_drag_and_drop')}
                                </DragContainer>
                                <ActionsContainer>
                                    {renderTooltipWithKey(
                                        <SVGInline src={icons.editLightIcon} onClick={handleEditClick} />,
                                        'modules_editor_view_icon_edit'
                                    )}
                                    {renderTooltipWithKey(
                                        <SVGInline src={icons.editorialIcon} onClick={handleViewClick} />,
                                        'modules_editor_view_icon_customize'
                                    )}
                                    {renderTooltipWithKey(
                                        <SVGInline src={icons.trashLightIcon} onClick={handleDeleteClick} />,
                                        'modules_editor_view_icon_delete'
                                    )}
                                </ActionsContainer>
                            </CardActions>
                            {!calculateSelectorBackground(item) && <SVGInline src={noImageIcon} />}
                            {item.deactivated && <DeactivatedCardOverlay rounded={type === '1X1_ROUNDED'} />}
                        </CardPresenter>
                        {!isCollection && (
                            <CardInfo $type={type}>
                                <CardTitle color={pageColors?.body}>
                                    <TruncatingTextHolder>{translate(item?.title || '')}</TruncatingTextHolder>
                                    {<TranslationTooltip translationKey={item.title} />}
                                </CardTitle>
                            </CardInfo>
                        )}
                    </>
                </Wrapper>
            </div>
        );
    };

    const renderDynamicCard = () => {
        return (
            <div style={{ display: 'inline' }} ref={callbackRef} onClick={handleOnCardClick}>
                <CardWrapper style={{ opacity: isDragging ? 0.8 : 1 }} $type={type} $itemAdminLocked={isAdminLocked}>
                    <CardPresenter $disabled={dragDisabled} $type={type} $background={`url('${dynamicItem?.image}')`}>
                        {dynamicItem?.logo && (
                            <LogoContainer gradientsValues={generateGradientValues(0, 1, 10)}>
                                <ProviderLogo $logo={dynamicItem.logo} />
                            </LogoContainer>
                        )}
                        <CardTypeIndicator>
                            {(hasAbTestingGroups || isAdminLocked || item.deactivated) && (
                                <>
                                    {isAdminLocked && <SVGInline src={icons.adminLockRed} />}
                                    {item.deactivated && renderDeactivatedIcon()}
                                    {hasAbTestingGroups && <ShowMoreIconWrapper src={icons.abTestingDarkIcon} />}
                                    <CardTypeSeparator />
                                </>
                            )}
                            <SVGInline src={icons.dynamicIconDark} />
                            <div>{item?.singleAsset ? '1' : item?.maxNumberOfItems || '∞'}</div>
                        </CardTypeIndicator>
                        <CardActions>
                            <DragContainer>
                                {renderTooltipWithKey(<SVGInline src={icons.dragIcon} />, 'modules_editor_view_icon_drag_and_drop')}
                            </DragContainer>
                            <ActionsContainer>
                                {renderTooltipWithKey(
                                    <SVGInline src={icons.editLightIcon} onClick={handleEditClick} />,
                                    'modules_editor_view_icon_edit'
                                )}
                                {renderTooltipWithKey(
                                    <SVGInline src={icons.viewIcon} onClick={handleViewClick} />,
                                    'modules_editor_view_icon_preview'
                                )}
                                {renderTooltipWithKey(
                                    <SVGInline src={icons.trashLightIcon} onClick={handleDeleteClick} />,
                                    'modules_editor_view_icon_delete'
                                )}
                            </ActionsContainer>
                        </CardActions>
                        {autoCollection && (
                            <CardCollectionIndicator>
                                {renderTooltipWithKey(<SVGInline src={icons.collectionIcon} />, `modules_editor_icon_collection`)}
                            </CardCollectionIndicator>
                        )}

                        {item.deactivated && <DeactivatedCardOverlay rounded={type === '1X1_ROUNDED'} />}
                    </CardPresenter>
                    <CardInfo $type={type}>
                        <CardTitle color={pageColors?.body}>
                            <TruncatingTextHolder>{dynamicItem?.title}</TruncatingTextHolder>
                        </CardTitle>
                    </CardInfo>
                </CardWrapper>
            </div>
        );
    };
    if (isButton) {
        return renderButtonCM();
    }

    return <>{item.itemType === itemTypes.EDITORIAL ? renderEditorialCard() : renderDynamicCard()}</>;
};

export interface ICardSeparatorProps {
    type: TemplateSizeKey;
    onPlus?: any;
}
export const CardSeparator: FC<ICardSeparatorProps> = ({ type, onPlus }) => {
    const handlePlusClick = (evt: any) => {
        evt.preventDefault();
        onPlus?.();
    };

    return (
        <CardSeparatorWrapper onClick={handlePlusClick} $type={type}>
            <CardSeparatorContainer $type={type}>
                <DividerDiv />
                <SVGRoundIconContainer>
                    <SVGInline src={icons.separatorRoundIcon} />
                </SVGRoundIconContainer>
                <SVGAddIconContainer>
                    {renderTooltipWithKey(<SVGInline src={icons.addIconWhite} />, 'modules_editor_view_icon_add_to_position')}
                </SVGAddIconContainer>
                <DividerDiv />
            </CardSeparatorContainer>
        </CardSeparatorWrapper>
    );
};
