import { AzureBlobStorageClient } from './AzureBlobStorageClient';
import { AWSS3Client } from './AWSS3Client';

export type CloudProviders = 'AWS' | 'AZURE';
export interface StorageClient {
    projectId: string;
    uploadFile(
        file: File,
        prefix?: string,
        uploadProgressCallback?: (data: { progress: number; name: string }) => void,
        uploadSuccessCallback?: (name: string) => void,
        uploadErrorCallback?: (error: { code: string; message: string }) => void,
        overwrite?: boolean
    ): Promise<any>;
    uploadFilesSync(files: File[], prefix?: string, overwrite?: boolean, projectId?: string, tenantId?: string): Promise<any>;
    editFile(
        newPath: string,
        originalPath: string,
        overwrite?: boolean,
        uploadProgressCallback?: (data: { progress: number; name: string }) => void,
        uploadSuccessCallback?: (name: string) => void,
        uploadErrorCallback?: (error: { code: string; message: string }) => void
    ): Promise<any>;

    abortFileUpload(): Promise<boolean>;
    downloadFile(file: any): void;
}
class FileManagerClient {
    private _cloudPrivider: CloudProviders = 'AZURE';
    private storageClient: StorageClient = new AzureBlobStorageClient();

    private static _instance: FileManagerClient;

    set projectId(value: string) {
        this.storageClient.projectId = value;
    }

    get projectId() {
        return this.storageClient.projectId;
    }

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
    private constructor() {}

    set cloudProvider(cloudProvider: CloudProviders) {
        this._cloudPrivider = cloudProvider;
        this.storageClient = cloudProvider === 'AWS' ? new AWSS3Client() : new AzureBlobStorageClient();
    }

    get cloudProvider() {
        return this._cloudPrivider;
    }

    async uploadFile(
        file: File,
        prefix?: string,
        uploadProgressCallback?: (data: { progress: number; name: string }) => void,
        uploadSuccessCallback?: (name: string) => void,
        uploadErrorCallback?: (error: { code: string; message: string }) => void,
        overwrite?: boolean
    ) {
        return this.storageClient.uploadFile(file, prefix, uploadProgressCallback, uploadSuccessCallback, uploadErrorCallback, overwrite);
    }

    async uploadFilesSync(files: File[], prefix?: string, overwrite?: boolean, projectId?: string, tenantId?: string) {
        return this.storageClient.uploadFilesSync(files, prefix, overwrite, projectId, tenantId);
    }

    editFile(
        newPath: string,
        originalPath: string,
        overwrite?: boolean,
        uploadProgressCallback?: (data: { progress: number; name: string }) => void,
        uploadSuccessCallback?: (name: string) => void,
        uploadErrorCallback?: (error: { code: string; message: string }) => void
    ) {
        return this.storageClient.editFile(
            newPath,
            originalPath,
            overwrite,
            uploadProgressCallback,
            uploadSuccessCallback,
            uploadErrorCallback
        );
    }

    abortFileUpload() {
        return this.storageClient.abortFileUpload();
    }

    async downloadFile(file: any) {
        return this.storageClient.downloadFile(file);
    }
}

export default FileManagerClient.Instance;
