import styled from 'styled-components';
import getTheme from '../../../style/themes/theme';

const theme: any = getTheme();

export const PaginationWrapper = styled.div`
    display: flex;
    gap: 2px;
`;

export const PaginationItem = styled.div<{ $active?: boolean }>`
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    padding: 8px;
    border: 1px solid ${theme.palette.background.border};
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: ${theme.palette.white.main};
    ${({ $active }) => $active && `text-decoration: underline; text-decoration-color: ${theme.palette.primary.main};`}
`;
