import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { RadioButtonWrapper, RadioText, ToggleContainer } from '../../common/Dialog/GenericDialog.css';

const theme: any = getTheme();

export const TemplateOption = styled.div`
    height: 44px;
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const OptionSection = styled.div<{ $notLastElement?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 16px;
    .MuiInputBase-adornedEnd {
        span {
            opacity: 0.7;
            padding: 6px 8px;
        }
    }
    ${($notLastElement) => $notLastElement && `margin-bottom: 16px;`}; // if not last element in the dialog, add a bottom margin

    ${ToggleContainer} {
        width: fit-content;
    }
`;

export const TemplateIconWrapper = styled.div`
    height: 24px;
    svg {
        height: 24px;
    }
`;

export const OptionsToggle = styled.div`
    ${fontSizesCSS.Caption2};
    cursor: pointer;
    width: fit-content;
`;

export const OptionEntry = styled.div`
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    gap: 8px;
`;

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const TranslationToolTipContainer = styled.div``;

export const TranslationToolTipRow = styled.div`
    height: 32px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    margin: 10px 0;
    width: 100%;
    max-height: 100%;
    box-sizing: border-box;

    img {
        width: 26px;
        height: 16px;
    }
`;

export const TooltipName = styled.div`
    margin-left: 10px;
    white-space: nowrap;
`;

export const TooltipKey = styled.div`
    ${fontSizesCSS.Body1};
`;

export const TooltipTranslation = styled.div`
    margin-left: 10px;
    font-weight: bold;
    cursor: pointer;
    padding-left: 10px;
    border-left: 0.5px solid rgba(255, 255, 255, 0.2);
    width: 100%;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 100%;
`;

export const TargetConditionsWrapper = styled.div<{ $noError?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;

    label {
        ${fontSizesCSS.Caption3}
        color: ${theme.palette.label.main};
        &.error {
            color: ${theme.palette.error.main};
            opacity: 1;
            display: ${({ $noError }) => ($noError ? 'none' : 'block')};
            margin-top: 4px;
            height: 15px;
            width: 100%;

            span {
                color: ${theme.palette.label.main};
            }
        }
    }
`;

export const RadioButtonsWrapper = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    ${RadioButtonWrapper} {
        ${fontSizesCSS.Body1};
    }
`;

export const ManualModuleTooltipWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 4px;
`;

export const TargetConditionsInfoWrapper = styled.div`
    margin-bottom: 16px;
`;

export const TargetConditionsInfo = styled.div`
    ${fontSizesCSS.Caption1};
    opacity: 0.7;
`;

export const ModuleTypeWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;
`;

export const RadioButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 4px;
    // Remove the margin-bottom of the CheckboxContainer
    a {
        margin-bottom: unset;
    }
    svg {
        &:hover {
            cursor: pointer;
        }
    }
`;

export const AutoCollectionExtraFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: ${theme.palette.background.menuItem};
    padding: 16px;
    ${fontSizesCSS.Body2};
    ${RadioText} {
        font-weight: normal;
    }
`;

export const CheckboxListContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    max-width: 100%;
    overflow: hidden;
`;

export const SingleAssetContainer = styled.div`
    width: 100%;
    background-color: ${theme.palette.background.actions};
    padding: 16px;
    margin-top: -7px; // this element only needs a 1px gap from the top and the gap is 8 px
`;

export const EPGOptionsWrapper = styled.div`
    flex-direction: column;
    display: flex;
    gap: 8px;
`;

export const EPGOption = styled.div`
    background-color: ${theme.palette.background.menuItem};
    border-radius: 4px;
    padding: 16px;
    flex: 1;

    .MuiInputBase-adornedEnd span {
        padding: 0 8px;
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Hide the arrows in Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
`;
