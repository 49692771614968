import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import icons from '../../../assets/images/icons';
import { Item } from '../../../types/Item';
import { DIALOG_NAMES, dialogConfirm, actionNameMissingAlert } from '../../../utils/fnDialogs';
import Button from '../../Buttons/Button/Button';
import { DialogTextField } from '../../common/Dialog/GenericDialog';
import ActionDialog from '../../Items/Dialogs/ActionDialog';
import BackgroundDialog, { supportedResolutionsType } from '../../Items/Dialogs/BackgroundDialog';
import LogoDialog from '../../Items/Dialogs/LogoDialog';
import {
    BackgroundContainer,
    BackgroundSelector,
    ButtonsContainer,
    GalleryAndPreviewCustomizeHolder
} from './EditorialItemCustomization.css';
import {
    BackgroundDropZoneCM,
    BackgroundSelectionButton,
    BackgroundSelectionContainer,
    ContentHolder,
    DescriptionField,
    DragAndDropTextWrapper,
    FieldsContainer,
    SubtitleField,
    TemplateSizeKey,
    TitleField
} from '../VisualEditor.css';
import SVGInline from 'react-inlinesvg';
import { AddLogoContainer, AddLogoIcon } from '../../Items/EditorialView.css';
import { ScreenTypes } from '../../../types/AppBranding';
import { CloseButtonWrapper } from '../../Modules/VisualEditor.css';
import BackgroundSwitcher from '../../Items/BackgroundSwitcher';
import { resolutionsIcons } from '../../Items/EditorialView';
import getTheme from '../../../style/themes/theme';
import { renderTooltipWithKey } from '../../common/Tooltips/Tooltips';

const theme: any = getTheme();

export type GalleryAndPreviewCustomizeProps = {
    item: Item;
    onSave: (item: Item) => void;
    onClose: () => void;
    onBackgroundChange?: (preview: any) => void;
    calculateBackground?: (
        values: CustomizeValues,
        isHero?: boolean,
        defaultValue?: string,
        resolution?: keyof supportedResolutionsType
    ) => string;
    type: 'GALLERY' | 'PREVIEW';
    template?: TemplateSizeKey;
};

export type CustomizeValues = {
    title?: string;
    subtitle?: string;
    description?: string;
    logo?: string;
    color?: string;
    heroBackgroundColor?: string;
    heroBackgroundImage?: ScreenTypes;
    backgroundImage?: ScreenTypes;
    backgroundColor?: string;
    actionName?: string;
    action?: any;
    videoForAutoplay?: string;
};

export const GalleryAndPreviewEditorialCustomization: FC<GalleryAndPreviewCustomizeProps> = ({
    item,
    type,
    onSave,
    onClose,
    calculateBackground,
    onBackgroundChange,
    template
}) => {
    const [values, setvalues] = useState<CustomizeValues>({});
    const [isBackgroundDialogOpen, setIsBackgroundDialogOpen] = useState<boolean>(false);
    const [isOpenActionDialog, setIsOpenActionDialog] = useState<boolean>(false);
    const [isOpenLogoDialog, setIsOpenLogoDialog] = useState<boolean>(false);
    const [isEditingHeroBG, setIsEditingHeroBG] = useState<boolean>(false);
    const [droppedFile, setDroppedFile] = useState<string | undefined>(undefined);
    const [extensionError, setExtensionError] = useState<undefined | string>(undefined);

    const [activeResolution, setActiveResolution] = useState<keyof typeof resolutionsIcons>('bigScreen');

    const isGallery = type === 'GALLERY';
    const isPreview = type === 'PREVIEW';

    useEffect(() => {
        setvalues({
            title: item?.title || '',
            subtitle: item?.subtitle || '',
            description: item?.description || '',
            logo: item?.logo || '',
            color: item?.backgroundColor || '',
            heroBackgroundColor: item?.heroBackgroundColor || '',
            heroBackgroundImage: item?.heroBackgroundImage || undefined,
            backgroundImage: item?.backgroundImage || undefined,
            backgroundColor: item?.backgroundColor || '',
            actionName: item?.actionName || '',
            action: item?.action || '',
            videoForAutoplay: item?.videoForAutoplay || ''
        });
    }, [item]);

    useEffect(() => {
        onBackgroundChange?.(
            isGallery
                ? calculateBackground?.(values, false, undefined, activeResolution)
                : calculateBackground?.(values, true, undefined, activeResolution)
        );
    }, [values.backgroundColor, values.backgroundImage, values.heroBackgroundImage, values.heroBackgroundColor, activeResolution]);

    const checkShowUnsaved = () => {
        const initialValues = {
            title: item?.title || '',
            subtitle: item?.subtitle || '',
            description: item?.description || '',
            logo: item?.logo || '',
            color: item?.backgroundColor || '',
            heroBackgroundColor: item?.heroBackgroundColor || '',
            heroBackgroundImage: item?.heroBackgroundImage || undefined,
            backgroundImage: item?.backgroundImage || undefined,
            backgroundColor: item?.backgroundColor || '',
            actionName: item?.actionName || '',
            action: item?.action || '',
            videoForAutoplay: item?.videoForAutoplay || ''
        };

        return JSON.stringify(values) !== JSON.stringify(initialValues);
    };

    const handleChange = (valuesToChange: { [key in keyof CustomizeValues]?: any } = {}) => {
        const newValues = {
            ...values
        };
        (Object.keys(valuesToChange) as Array<keyof CustomizeValues>).forEach((key) => {
            newValues[key] = valuesToChange[key];
        });

        setvalues(newValues);
    };

    const saveItem = () => {
        if (!item) return;

        const newItem: Item = {
            ...item,
            ...values
        };
        if (type === 'GALLERY' && !newItem.actionName) {
            actionNameMissingAlert();
        }
        onSave?.(newItem);
    };

    const handleBackgroundSave = (backgroundValue: string | supportedResolutionsType, autoplay?: string, isColor?: boolean) => {
        const videoForAutoplay = autoplay || '';
        if (isEditingHeroBG) {
            handleChange(
                isColor
                    ? { heroBackgroundColor: backgroundValue }
                    : { heroBackgroundImage: typeof backgroundValue !== 'string' ? backgroundValue : undefined }
            );
        } else {
            handleChange(
                isColor
                    ? { backgroundColor: backgroundValue, videoForAutoplay }
                    : { backgroundImage: typeof backgroundValue !== 'string' ? backgroundValue : undefined, videoForAutoplay }
            );
        }
        setIsBackgroundDialogOpen(false);
        setIsEditingHeroBG(false);
    };

    const handleDrop = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        const { files } = e.dataTransfer;
        if (files && files.length) {
            const { type } = files[0];
            if (!['jpeg', 'png'].includes(type.split('/')[1])) {
                setExtensionError('Incorrect file extension, can upload only JPG or PNG');
            }
            setDroppedFile(files);
            setIsBackgroundDialogOpen(true);
            setIsEditingHeroBG(isPreview);
        }
    };

    const renderEditingAlertDialog = () => {
        return dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => saveItem(),
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => handleCloseClick(),
            true
        );
    };

    const handleCloseClick = (check: boolean = false) => {
        if (check && checkShowUnsaved()) {
            renderEditingAlertDialog();
            return;
        }
        onClose?.();
        setvalues({});
        setExtensionError(undefined);
    };

    const renderTextFields = () => {
        return (
            <FieldsContainer isGallery={isGallery}>
                <AddLogoContainer
                    onClick={() => {
                        setIsOpenLogoDialog(true);
                    }}
                >
                    <AddLogoIcon $preview={values.logo ? decodeURIComponent(values.logo) : ''}>
                        {!values.logo && <SVGInline src={icons.iconAddLogo} />}
                    </AddLogoIcon>
                    <span>Click to {!values.logo ? 'add' : 'edit'} logo</span>
                </AddLogoContainer>
                <TitleField>
                    <DialogTextField
                        placeholder={'Click to edit Title'}
                        value={values.title || ''}
                        onChange={(e: any) => {
                            const title = e.target.value;
                            handleChange({ title });
                        }}
                        withTranslations
                        isVisualEditor
                        noError
                        noLabel
                    />
                </TitleField>

                <SubtitleField>
                    <DialogTextField
                        placeholder={'Click to edit Subtitle'}
                        value={values.subtitle || ''}
                        onChange={(e: any) => {
                            const subtitle = e.target.value;
                            handleChange({ subtitle });
                        }}
                        withTranslations
                        isVisualEditor
                        noError
                        noLabel
                    />
                </SubtitleField>
                <DescriptionField>
                    <DialogTextField
                        placeholder={'Click to edit Description'}
                        value={values.description || ''}
                        onChange={(e: any) => {
                            const description = e.target.value;
                            handleChange({ description });
                        }}
                        withTranslations
                        isVisualEditor
                        noError
                        noLabel
                    />
                </DescriptionField>
                <ButtonsContainer>
                    <Button
                        type={'WHITE'}
                        label={
                            values.action
                                ? _.truncate(values.actionName || 'DEFAULT', {
                                      length: 20,
                                      omission: '...'
                                  })
                                : 'Set Action'
                        }
                        onClick={() => {
                            setIsOpenActionDialog(true);
                        }}
                    />
                    <Button
                        type={'BLUE'}
                        label={'Save'}
                        onClick={() => {
                            saveItem();
                        }}
                    />
                </ButtonsContainer>
            </FieldsContainer>
        );
    };

    const renderPreviewCustomize = () => {
        return (
            <>
                <CloseButtonWrapper onClick={() => handleCloseClick(true)}>
                    <SVGInline src={icons.closeIcon} />
                </CloseButtonWrapper>
                <BackgroundDropZoneCM
                    onDragOver={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    onDrop={handleDrop}
                />
                <BackgroundSelectionContainer
                    onClick={() => {
                        setIsBackgroundDialogOpen(true);
                        setIsEditingHeroBG(true);
                    }}
                    onDragOver={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    onDrop={handleDrop}
                >
                    <BackgroundSelectionButton>
                        <SVGInline src={icons.photoIcon} />
                    </BackgroundSelectionButton>
                    <DragAndDropTextWrapper>
                        {values.heroBackgroundImage || values.heroBackgroundColor
                            ? 'Click to change or Drag and Drop'
                            : 'Drag and Drop or Browse Files'}
                    </DragAndDropTextWrapper>
                </BackgroundSelectionContainer>

                <GalleryAndPreviewCustomizeHolder>
                    {template !== 'BUTTON' && (
                        <BackgroundContainer
                            template={type === 'GALLERY' ? '16X9_M' : template || 'UNKNOWN'}
                            onClick={() => {
                                setIsBackgroundDialogOpen(true);
                            }}
                        >
                            <BackgroundSelector
                                showAsBanner={false}
                                $background={calculateBackground && calculateBackground(values, false, undefined, activeResolution)}
                            >
                                {values.backgroundImage?.[activeResolution] || values.backgroundColor
                                    ? renderTooltipWithKey(<SVGInline src={icons.editLightIcon} />, 'item_editorial_icon_edit')
                                    : renderTooltipWithKey(<SVGInline src={icons.addIconWhite} />, 'item_editorial_icon_add')}
                            </BackgroundSelector>
                            <BackgroundSwitcher
                                activeResolution={activeResolution}
                                setActiveResolution={setActiveResolution}
                                backgroundImage={values.backgroundImage}
                                backgroundColor={values.backgroundColor}
                                setIsOpenBgDialog={setIsBackgroundDialogOpen}
                            />
                        </BackgroundContainer>
                    )}

                    {renderTextFields()}
                </GalleryAndPreviewCustomizeHolder>
            </>
        );
    };

    const renderGalleryCustomize = () => {
        return (
            <>
                <CloseButtonWrapper onClick={() => handleCloseClick(true)}>
                    <SVGInline src={icons.closeIcon} />
                </CloseButtonWrapper>
                <BackgroundDropZoneCM
                    onDragOver={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    onDrop={handleDrop}
                />
                <BackgroundSelectionContainer
                    onClick={() => {
                        setIsBackgroundDialogOpen(true);
                    }}
                    onDragOver={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    onDrop={handleDrop}
                >
                    <BackgroundSelectionButton>
                        <SVGInline src={icons.photoIcon} />
                    </BackgroundSelectionButton>
                    <DragAndDropTextWrapper>
                        {values.backgroundImage || values.heroBackgroundColor
                            ? 'Click to change or Drag and Drop'
                            : 'Drag and Drop or Browse Files'}
                    </DragAndDropTextWrapper>
                </BackgroundSelectionContainer>
                <BackgroundSwitcher
                    activeResolution={activeResolution}
                    setActiveResolution={setActiveResolution}
                    backgroundImage={values.backgroundImage}
                    backgroundColor={values.backgroundColor}
                    setIsOpenBgDialog={setIsBackgroundDialogOpen}
                    insideGallery
                />
                {renderTextFields()}
                <ContentHolder marginTop="124px" />
            </>
        );
    };

    return (
        <>
            {isGallery ? renderGalleryCustomize() : renderPreviewCustomize()}

            <BackgroundDialog
                open={isBackgroundDialogOpen}
                onSave={(backgroundValue, autoplay, isColor) => {
                    handleBackgroundSave(backgroundValue, autoplay, isColor);
                }}
                onClose={() => {
                    setIsBackgroundDialogOpen(false);
                    setIsEditingHeroBG(false);
                    setDroppedFile(undefined);
                }}
                background={
                    isEditingHeroBG
                        ? values.heroBackgroundColor || values.heroBackgroundImage
                        : values.backgroundColor || values.backgroundImage
                }
                isColor={droppedFile ? false : isEditingHeroBG ? !!values.heroBackgroundColor : !!values.backgroundColor}
                backgroundAutoplay={values?.videoForAutoplay}
                isHero={isEditingHeroBG}
                droppedFile={droppedFile}
                extensionError={extensionError}
            />

            <ActionDialog
                open={isOpenActionDialog}
                action={values?.action}
                name={values?.actionName}
                onSave={(actionName, action) => {
                    const newValues = {
                        ...values,
                        actionName,
                        action
                    };
                    setvalues(newValues);
                    setIsOpenActionDialog(false);
                }}
                onClose={() => setIsOpenActionDialog(false)}
                inGalleryOrButton={isGallery}
            />

            <LogoDialog
                logo={values.logo || ''}
                open={isOpenLogoDialog}
                onSave={(newLogo) => {
                    handleChange({ logo: newLogo });
                    setIsOpenLogoDialog(false);
                }}
                onClose={() => setIsOpenLogoDialog(false)}
            />
        </>
    );
};
