import iconPdfExtension from './ico-pdfExtension.svg';
import iconImageExtension from './ico-imageExtension.svg';
import iconSvgExtension from './ico-svgExtension.svg';
import iconAudioExtension from './ico-audioExtension.svg';
import iconUnknownExtension from './ico-unknownExtension.svg';
import iconVideoExtension from './ico-videoExtension.svg';

const fileExtensionIcons = {
    iconPdfExtension,
    iconImageExtension,
    iconSvgExtension,
    iconAudioExtension,
    iconUnknownExtension,
    iconVideoExtension
};
export default fileExtensionIcons;
