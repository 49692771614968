import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { SelectWrapper } from '../../common/Select/Select.css';
import { devices } from '../../../style/styled-components/reusable.css';

const theme: any = getTheme();

export const FeatureContainer = styled.div`
    margin-bottom: 24px;
    background: ${theme.palette.background.active};
`;

export const FeatureTitle = styled.div`
    ${fontSizesCSS.Body2}
`;

export const FeatureSubTitle = styled.div`
    ${fontSizesCSS.Caption2}
    margin-left: 24px
`;

export const FeatureSections = styled.div`
    overflow: hidden;
    display: flex;
    margin: 24px;
    gap: 24px;
`;

export const FeatureSubSection = styled(FeatureSections)`
    margin: 12px 24px 24px;
`;

export const FeatureTitleRow = styled.div`
    display: flex;
    margin: 24px;
`;

export const FeatureColumnWrapper = styled.div`
    width: 33%;
    ${devices.tablet} {
        width: 50%;
    }

    a {
        margin-bottom: 4px;
    }
`;

export const ProjectsDropdownWrapper = styled.div`
    ${SelectWrapper} {
        width: 480px;
    }
`;
