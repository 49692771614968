import { Project } from './Project';
import { Role } from './Role';
import { Tenant } from './Tenant';
import { UserGroup } from './UserGroup';

export interface User {
    _id: string;
    tenantIds?: string[];
    projectIds?: string[];
    userGroupIds?: string[];
    tenantGroups?: {
        tenantId: string;
        userGroupId: string;
    }[];
    roleId?: string;
    email: string;
    fullName: string;
    resetLink?: string | null;
    tenants?: Tenant[];
    userGroups?: UserGroup[];
    projects?: Project[];
    role?: Role;
    password?: string;
    superAdmin?: boolean;
    state: userStates;
    icon?: string;
}

export enum userStates {
    INACTIVE,
    ACTIVE,
    DISABLED
}
