import { FC, useEffect, useRef, useState, Fragment } from 'react';
import icons from '../../style';
import {
    ActionsContainer,
    CardActions,
    CardPresenter,
    CollectionCardWrapper,
    CollectionCustomizeOverlay,
    CollectionWrapper,
    TEMPLATE_SIZES,
    TemplateSizeKey
} from './VisualEditor.css';
import SVGInline from 'react-inlinesvg';
import { Module } from '../../types/Module';
import { CardSeparator, DraggableActionableTemplateCard, TemplateCard } from './VisualEditor';
import { Item, itemTypes } from '../../types/Item';
import { getDynamicItems } from '../../utils/fnPreviews';
import { renderTooltipWithKey } from '../common/Tooltips/Tooltips';
import { Loader } from '../common/Loader/Loader';
import { UNSAFE_useEffectOnce } from '../../hooks/useEffectOnce';
import { EditorialItemCustomize } from './EditorialItemCustomization/EditorialItemCustomization';
import { ItemState } from '../../redux/slices/itemSlice';
import { useAppSelector } from '../../hooks/redux';
import useScreenSize from '../../hooks/useScreenSize';
import { DRAGGABLE_TYPES } from '../../utils/Globals';

type CollectionProps = {
    module: Module;
    type: TemplateSizeKey;
    moduleIndex: number;
    onEdit: (arg: Item) => void;
    onDelete: (arg: Item) => void;
    addItem: (index?: number) => void;
    setItemForPreview: (itemId: string, moduleId: string, itemType: itemTypes) => void;
    unsetItemForPreview: () => void;
    onItemSave: (item: Item) => void;
    findItem: (id: string, moduleIndex: number) => { index: number };
    moveItem: (id: string, moduleIndex: number, to: number) => void;
    isTemplate?: boolean;
};

const GAP_BETWEEN_CARDS = 16;
const MIN_NO_OF_ROWS = 3;

export const Collection: FC<CollectionProps> = ({
    type,
    module,
    isTemplate,
    moduleIndex,
    onEdit,
    onDelete,
    addItem,
    setItemForPreview,
    unsetItemForPreview,
    onItemSave,
    findItem,
    moveItem
}) => {
    const { itemPreview }: ItemState = useAppSelector((state) => state.items);
    const { screenSize } = useScreenSize();
    const [selectedItems, setSelectedItems] = useState<Item[]>([]);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [dynamicData, setDynamicData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [collectionType, setCollectionType] = useState<itemTypes | undefined>(undefined);

    const [numberOfRows, setNumberOfRows] = useState<number>(MIN_NO_OF_ROWS);
    const [numberOfCards, setNumberOfCards] = useState<number>(30);

    const collectionWrapperRef = useRef<HTMLDivElement>(null);

    const cardHeight = parseInt((TEMPLATE_SIZES[type]?.height || TEMPLATE_SIZES['UNKNOWN'].height).replace('px', ''));
    const cardWidth = parseInt((TEMPLATE_SIZES[type]?.width || TEMPLATE_SIZES['UNKNOWN'].width).replace('px', ''));

    const generateBgImage = (url?: string) => {
        return url ? `url('${decodeURIComponent(url)}')` : '';
    };

    const loadDynamicData = async (item: Item) => {
        setLoading(true);
        const items = await getDynamicItems(item, false, isTemplate);
        if (Array.isArray(items)) {
            setDynamicData(items);
        }
    };

    UNSAFE_useEffectOnce(() => {
        if (!module) return;

        setIsEmpty(!module.items?.length);
        setCollectionType(module?.items?.[0]?.itemType);
        setSelectedItems([...(module.items || [])]);
    }, [module]);

    UNSAFE_useEffectOnce(() => {
        if (!!selectedItems.length && collectionType === itemTypes.DYNAMIC) {
            loadDynamicData(selectedItems[0]);
        }
    }, [selectedItems, collectionType]);

    useEffect(() => {
        if (!dynamicData) return;
        setLoading(false);
    }, [dynamicData]);

    useEffect(() => {
        const containerWidth = collectionWrapperRef.current?.getBoundingClientRect().width || 0;
        const noOfCardsPerRow = Math.floor(containerWidth / (cardWidth + GAP_BETWEEN_CARDS));

        noOfCardsPerRow && setNumberOfCards(noOfCardsPerRow * numberOfRows);
    }, [collectionWrapperRef?.current, screenSize, numberOfRows]);

    useEffect(() => {
        if (!selectedItems.length) return;
        // initially, 3 rows of cards has to be displayed, if the number of the items will be bigger than this,
        // we need to extend the collection with one more row

        const noOfCardsPerRow = numberOfCards / numberOfRows;
        const emptyCards = numberOfCards - selectedItems.length;

        if (emptyCards <= 0) {
            return setNumberOfRows(numberOfRows + 1);
        }

        if (emptyCards > noOfCardsPerRow && numberOfRows > MIN_NO_OF_ROWS) {
            return setNumberOfRows(numberOfRows - 1);
        }
    }, [selectedItems, numberOfCards]);

    const renderEmptyCards = (length: number) => {
        return Array.from({ length }).map((_, elem) => {
            return (
                <CollectionCardWrapper $empty $extraMargin key={`collection_card_${elem}`} $type={type}>
                    <CardPresenter $type={type} />
                </CollectionCardWrapper>
            );
        });
    };

    const renderEditorialCollection = () => {
        return (
            <>
                {selectedItems.map((item, index) => {
                    const isLastItem = selectedItems.length === index + 1;
                    return (
                        <Fragment key={item._id}>
                            <DraggableActionableTemplateCard
                                key={item._id}
                                isTemplate={isTemplate}
                                type={type}
                                item={item}
                                onEdit={() => {
                                    onEdit(item);
                                }}
                                onDelete={() => {
                                    onDelete(item);
                                }}
                                onDuplicate={() => {}}
                                onView={() => {
                                    setItemForPreview(item._id, module._id, itemTypes.EDITORIAL);
                                }}
                                moveItem={moveItem}
                                findItem={findItem}
                                moduleIndex={moduleIndex}
                                dragType={DRAGGABLE_TYPES.ITEM}
                                isCollection
                                lastItem={isLastItem}
                            />

                            {!isLastItem && (
                                <CardSeparator
                                    key={index + '_separator'}
                                    type={type}
                                    onPlus={() => {
                                        addItem(index);
                                    }}
                                />
                            )}
                        </Fragment>
                    );
                })}

                <TemplateCard
                    key={'_plus'}
                    type={type}
                    onPlus={() => {
                        addItem();
                    }}
                    isCollection
                />
                {renderEmptyCards(numberOfCards - (selectedItems.length + 1))}
            </>
        );
    };

    const renderDynamicCollection = () => {
        const currentItem = selectedItems[0];
        return (
            <>
                {loading ? (
                    <Loader lightTitle />
                ) : (
                    dynamicData.map((item, index) => {
                        return (
                            <CollectionCardWrapper key={`collection_item_${index}`} $type={type} $extraMargin $isDynamic>
                                <CardPresenter $type={type} $background={generateBgImage(item.image)}>
                                    <CardActions>
                                        <ActionsContainer>
                                            {renderTooltipWithKey(
                                                <SVGInline src={icons.editLightIcon} onClick={() => onEdit(currentItem)} />,
                                                'modules_editor_view_icon_edit'
                                            )}

                                            {renderTooltipWithKey(
                                                <SVGInline src={icons.trashLightIcon} onClick={() => onDelete(currentItem)} />,
                                                'modules_editor_view_icon_delete'
                                            )}
                                        </ActionsContainer>
                                    </CardActions>
                                </CardPresenter>
                            </CollectionCardWrapper>
                        );
                    })
                )}
            </>
        );
    };

    const renderCollectionUI = () => {
        const isEditorial = collectionType === itemTypes.EDITORIAL;

        return (
            <>
                {isEmpty ? (
                    <>
                        <TemplateCard
                            key={'_plus'}
                            type={type}
                            onPlus={() => {
                                addItem();
                            }}
                            isFirst
                            isCollection
                        />
                        {renderEmptyCards(numberOfCards)}
                    </>
                ) : isEditorial ? (
                    renderEditorialCollection()
                ) : (
                    renderDynamicCollection()
                )}
            </>
        );
    };

    return (
        <CollectionWrapper ref={collectionWrapperRef} $height={(cardHeight + GAP_BETWEEN_CARDS) * numberOfRows}>
            {renderCollectionUI()}

            {!isTemplate && (
                <>
                    {itemPreview?.moduleId === module._id && <CollectionCustomizeOverlay />}
                    <EditorialItemCustomize
                        open={itemPreview?.moduleId === module._id}
                        template={type}
                        onSave={(item) => {
                            onItemSave(item);
                        }}
                        onClose={() => {
                            unsetItemForPreview();
                        }}
                    />{' '}
                </>
            )}
        </CollectionWrapper>
    );
};
