import styled from 'styled-components';
import { BUTTON_COLORS_MAP } from '../../../utils/Globals';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';

const theme: any = getTheme();

export const ButtonIcon = styled.div`
    display: flex;
    justify-content: center;
`;

export const ButtonWrapper = styled.button.attrs((props: { htmlAttrs: any }) => {
    return { ...props.htmlAttrs };
})<{ $buttonType: string; $loading: boolean; $responsive: boolean; $fullWidth: boolean }>`
    background: ${(props) => BUTTON_COLORS_MAP.get(props.$buttonType)?.color};
    color: ${(props) => BUTTON_COLORS_MAP.get(props.$buttonType)?.textColor};
    border: ${({ $buttonType }) => ($buttonType === 'DEFAULT' ? 'solid 1px rgb(51, 51, 51, 0.4)' : 'none')};
    padding: 0 16px;
    ${fontSizesCSS.Body2};
    margin: 8px 36px;
    min-width: fit-content;
    width: ${(props) => (props.$responsive && !props.$fullWidth ? '52px ' : '288px')};
    min-height: 36px;
    &:hover {
        cursor: pointer;
        background: ${(props) => BUTTON_COLORS_MAP.get(props.$buttonType)?.hover};
        color: ${(props) =>
            BUTTON_COLORS_MAP.get(props.$buttonType)?.textColorHover || BUTTON_COLORS_MAP.get(props.$buttonType)?.textColor};

        ${ButtonIcon} {
            svg {
                path {
                    fill: ${(props) =>
                        BUTTON_COLORS_MAP.get(props.$buttonType)?.textColorHover || BUTTON_COLORS_MAP.get(props.$buttonType)?.textColor};
                }
            }
        }
    }
    display: ${(props) => (props.$loading ? 'flex' : 'block')};
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    ${ButtonIcon} {
        svg {
            path {
                fill: ${(props) => BUTTON_COLORS_MAP.get(props.$buttonType)?.textColor};
            }
        }
    }
`;

export const ButtonInner = styled.span`
    ${fontSizesCSS.Body2};
    text-transform: capitalize;
`;

export const ButtonLoader = styled.div<{ type: 'BLUE' | 'RED' | 'PINK' | 'DEFAULT' | 'WHITE' }>`
    :after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.5em solid ${({ type }) => (type === 'DEFAULT' ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.2)')};
    border-right: 0.5em solid ${({ type }) => (type === 'DEFAULT' ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.2)')};
    border-bottom: 0.5em solid ${({ type }) => (type === 'DEFAULT' ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.2)')};
    border-left: 0.5em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
`;
