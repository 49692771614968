import React, { ChangeEvent, createRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import {
    fetchCustomIcons,
    fetchSystemIcons,
    fetchTemplateIcons,
    FilesState,
    uploadFilesSync
} from '../../../redux/slices/fileManagerSlice';
import { DropdownLabel, IconPreviewImage, IconsSelectOptionWrapper } from '../../../style/styled-components/reusable.css';
import { DROPDOWN_GROUP_LABEL_VALUE, getImgixUrl } from '../../../utils/Globals';
import { DialogDropdownSingle } from '../Dialog/GenericDialog';
import { DIALOG_NAMES, getOptionsIndexes, ToastAlert } from '../../../utils/fnDialogs';
import BackendErrorDialog from '../Dialog/BackendErrorDialog';
import { extractFileNameFromAzureURL } from '../../../utils/fnUrl';
import FileManagerClient from '../../../utils/api/FileManagerClient/FileManagerClient';
import { ConfigState } from '../../../redux/slices/configSlice';

type IconsSelectProps = {
    value: string;
    onChange: (value: string) => void;
    error?: string;
    hideNoIconOption?: boolean;
    templates?: boolean; // if dropdown is in the template creation dialog, we must fetch and use template icons
};

export const IconsSelect: React.FC<IconsSelectProps> = ({ value, onChange, error, hideNoIconOption, templates }) => {
    const { systemIcons, templateIcons, customIcons, error: filesError }: FilesState = useAppSelector((state) => state.files);
    const { config }: ConfigState = useAppSelector((state) => state.config);
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);

    const [systemIconOptions, setSystemIconOptions] = useState<any[]>([]);
    const [templateIconOptions, setTemplateIconOptions] = useState<any[]>([]);
    const [customIconOptions, setCustomIconOptions] = useState<any[]>([]);
    const [newUrl, setNewUrl] = useState<string>('');

    const inputRef = createRef<HTMLInputElement>();

    const dispatch = useDispatch();

    const loadSystemIcons = async () => {
        return await dispatch(fetchSystemIcons()).unwrap();
    };

    const loadTemplateIcons = async () => {
        return await dispatch(fetchTemplateIcons()).unwrap();
    };

    const loadCustomIcons = async () => {
        if (activeProjectId) return await dispatch(fetchCustomIcons(`${activeProjectId}`)).unwrap();
    };

    useEffect(() => {
        if (templates) {
            loadTemplateIcons();
            return;
        }

        if (systemIcons?.length) {
            buildSystemIconOptions(systemIcons);
        } else {
            loadSystemIcons();
        }
        loadCustomIcons();
    }, []);

    useEffect(() => {
        FileManagerClient.projectId = activeProjectId || '';
    }, [activeProjectId]);

    useEffect(() => {
        buildSystemIconOptions(systemIcons);
    }, [systemIcons]);

    useEffect(() => {
        buildTemplateIconOptions(templateIcons);
    }, [templateIcons]);

    useEffect(() => {
        if (newUrl) {
            onChange(newUrl);
        }
    }, [newUrl]);

    useEffect(() => {
        buildCustomIconOptions(customIcons);
    }, [customIcons]);

    const buildTemplateIconOptions = (icons: any[]) => {
        const newIconOptions = icons.map((item) => {
            const url = getImgixUrl(config.imgixBaseUrl || '', item.name);
            const label = (
                <IconsSelectOptionWrapper>
                    <IconPreviewImage src={url} $invert={item.name.includes('.svg')} />
                    <span>{item.iconName}</span>
                </IconsSelectOptionWrapper>
            );
            return { value: url, label: label, valueForSearch: item.iconName };
        });
        newIconOptions.unshift({
            value: DROPDOWN_GROUP_LABEL_VALUE,
            label: (
                <DropdownLabel>
                    <span>Template icons</span>
                </DropdownLabel>
            ),
            valueForSearch: ''
        });
        setTemplateIconOptions(newIconOptions);
    };

    const buildSystemIconOptions = (icons: any[]) => {
        const newIconOptions = icons.map((item) => {
            const url = getImgixUrl(config.imgixBaseUrl || '', item.name);
            const label = (
                <IconsSelectOptionWrapper>
                    <IconPreviewImage src={url} $invert={item.name.includes('.svg')} />
                    <span>{item.iconName}</span>
                </IconsSelectOptionWrapper>
            );
            return { value: url, label: label, valueForSearch: item.iconName };
        });
        newIconOptions.unshift({
            value: DROPDOWN_GROUP_LABEL_VALUE,
            label: (
                <DropdownLabel>
                    <span>System icons</span>
                </DropdownLabel>
            ),
            valueForSearch: ''
        });
        setSystemIconOptions(newIconOptions);
    };

    const buildCustomIconOptions = (icons: any) => {
        const projectCustomIcons = _.get(icons, `${activeProjectId}`, []);
        if (!projectCustomIcons.length) return;

        const newIconOptions = [];
        projectCustomIcons.forEach((item: any) => {
            const url = getImgixUrl(config.imgixBaseUrl || '', item.name);
            const label = (
                <IconsSelectOptionWrapper>
                    <IconPreviewImage src={url} $invert={item.name.includes('.svg')} />
                    <span>{item.iconName}</span>
                </IconsSelectOptionWrapper>
            );
            newIconOptions.unshift({ value: url, label: label, valueForSearch: item.iconName });
        });
        newIconOptions.unshift({
            value: DROPDOWN_GROUP_LABEL_VALUE,
            label: (
                <DropdownLabel>
                    <span>Custom icons</span>
                </DropdownLabel>
            ),
            valueForSearch: ''
        });
        setCustomIconOptions(newIconOptions);
    };

    const createFiles = async (files: File[]) => {
        const prefix = templates ? 'system_templates/icons' : `${activeProjectId}/custom_icons`;
        const response = await dispatch(uploadFilesSync({ files, prefix })).unwrap();
        if (response.urls) {
            setNewUrl(
                getImgixUrl(
                    config.imgixBaseUrl || '',
                    extractFileNameFromAzureURL(config.AZURE_ACCOUNT || '', config.AZURE_CONTAINER || '', response.urls[0])
                )
            );
            if (templates) {
                loadTemplateIcons();
            } else {
                loadCustomIcons();
            }
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.FILE_MANAGER_FILE_CREATED);
        }
    };

    const handleFileInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
        if (!evt.target.files?.length) return;
        const files = [...evt.target.files];
        createFiles(files);
    };

    const iconOptions = [];
    if (!hideNoIconOption) {
        iconOptions.push({
            value: 'no_icon',
            label: (
                <IconsSelectOptionWrapper>
                    <span>No Icon</span>
                </IconsSelectOptionWrapper>
            ),
            valueForSearch: 'no icon'
        });
    }

    if (templates) {
        iconOptions.push(...templateIconOptions);
    } else {
        iconOptions.push(...customIconOptions, ...systemIconOptions);
    }

    return (
        <>
            {filesError && <BackendErrorDialog error={filesError} />}
            <DialogDropdownSingle
                onChange={(value: any) => {
                    onChange(value.value === 'no_icon' ? '' : value.value);
                }}
                placeholder="Choose icon"
                options={iconOptions}
                labeledSelect
                unclickableIndexes={getOptionsIndexes(iconOptions, [DROPDOWN_GROUP_LABEL_VALUE])}
                newOption={
                    activeProjectId
                        ? {
                              name: 'Custom icon',
                              onClick: () => inputRef.current?.click()
                          }
                        : templates
                        ? {
                              name: 'Template icon',
                              onClick: () => inputRef.current?.click()
                          }
                        : undefined
                }
                value={iconOptions.find((icon) => icon.value === value)}
                labelText="Add icon"
                error={error}
                withTopMargin
                notSorted
            />
            <input
                type="file"
                hidden
                onChange={handleFileInputChange}
                onClick={(e: any) => {
                    // reset the input value to be able to trigger onChange event even if the same file path is selected
                    e.target.value = '';
                }}
                multiple
                ref={inputRef}
                accept="image/*"
            />
        </>
    );
};
