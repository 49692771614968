import { itemTypes } from './Item';

export interface Template {
    _id: string;
    type: templateType;
    tenantIds?: string[];
    projectIds?: string[];
    values: any;
    icon?: string;
    audienceValues?: any;
    released?: boolean;
    lastModified?: number;
    modifiedBy?: string;
    iconBackground?: string;
    moduleTemplates?: Template[];
    itemTemplates?: Template[];
    dynamicSourceTemplate?: Template;
    placed?: Template[];
    tags?: string[];
}

export type TemplateDefaultValues = {
    tenantIds?: string[];
    projectIds?: string[];
    icon: string;
    iconBackground: string;
    tags?: string[];
};

export const templateTypes = {
    TARGET_GROUP: 'targetGroup',
    MODULE: 'module',
    PAGE: 'page',
    ITEM: 'item',
    AUDIENCE: 'audience',
    PAGE_STYLE: 'pageStyle',
    DYNAMIC_SOURCE: 'dynamicSource'
} as const;

// methods used to create the same flat structure as usual objects for previews
export const parseItemTemplate = (item: Template | undefined) => {
    if (item === undefined) return;
    const newItem = { ...item, ...item.values };
    if (newItem.itemType === itemTypes.DYNAMIC) {
        newItem.dynamicSourceId = item.values.dynamicSourceTemplateId;
        newItem.dynamicSource = { ...item.dynamicSourceTemplate?.values };
    }
    return newItem;
};

export const parseModuleTemplate = (module: Template) => {
    const newModule = { ...module, ...module.values };
    newModule.items = module?.itemTemplates?.map((item) => parseItemTemplate(item));

    return newModule;
};

export type templateType = typeof templateTypes[keyof typeof templateTypes];
