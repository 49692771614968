import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { Tenant } from '../../types/Tenant';

export const fetchTenants = createAsyncThunk<
    { tenants: Tenant[]; error: { message: string; code: string } | null; permissions?: any },
    { addPermissions?: boolean; orderBy?: string; searchTerm?: string }
>('tenants/fetchTenants', async ({ addPermissions, orderBy, searchTerm }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllTenants(addPermissions, orderBy, searchTerm);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { tenants: result.response as any, error: null, permissions: result.permissions };
});

export const fetchDisabledTenants = createAsyncThunk<
    {
        disabledTenants: Tenant[];
        error: { message: string; code: string } | null;
        permissions?: any;
    },
    { orderBy?: string; searchTerm?: string }
>('tenants/fetchDisabledTenants', async ({ orderBy, searchTerm }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllDisabledTenants(orderBy, searchTerm);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { disabledTenants: result.response as any, error: null, permissions: result.permissions };
});

export const createTenant = createAsyncThunk<{ id: string; error: { message: string; code: string } | null }, Tenant>(
    'tenants/createTenant',
    async (tenant: Tenant, thunkApi) => {
        const result = await ConfigServiceAPI.createTenant(tenant);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any, error: null };
    }
);

export const updateTenant = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, Tenant>(
    'tenants/updateTenant',
    async (tenant: Tenant, thunkApi) => {
        const result = await ConfigServiceAPI.updateTenant(tenant);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response as any, error: null };
    }
);
export const disableTenant = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'tenants/disableTenant',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.disableTenant(id);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);
export const enableTenant = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'tenants/disableTenant',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.enableTenant(id);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);
export const deleteTenant = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'tenants/disableTenant',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.deleteTenant(id);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export interface TenantsState {
    tenants: Tenant[];
    disabledTenants: Tenant[];
    loading: boolean;
    error: {
        message: string;
        code?: string;
        status?: number;
    } | null;
}

const initialState: TenantsState = {
    tenants: [],
    disabledTenants: [],
    error: null,
    loading: false
};

const slice = createSlice({
    name: 'tenants',
    initialState,
    reducers: {
        unsetTenantError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTenants.fulfilled, (state, action) => {
                state.tenants = action.payload.tenants;
                state.loading = false;
                state.error = null;
            })
            .addCase(fetchTenants.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchTenants.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.tenants = [];
                state.loading = false;
            })
            .addCase(fetchDisabledTenants.fulfilled, (state, action) => {
                state.disabledTenants = action.payload.disabledTenants;
                state.loading = false;
                state.error = null;
            })
            .addCase(fetchDisabledTenants.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchDisabledTenants.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.disabledTenants = [];
                state.loading = false;
            })
            .addCase(createTenant.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createTenant.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createTenant.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(updateTenant.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateTenant.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateTenant.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(disableTenant.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(disableTenant.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(disableTenant.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            });
    }
});

export const { unsetTenantError } = slice.actions;
export default slice.reducer;
