import { FC, useEffect, useMemo, useState } from 'react';
import GenericDialog, { DialogButton, DialogDropdownMultiple, DialogTypes } from '../../common/Dialog/GenericDialog';
import { EMPTY_WORD_STRING } from '../../../utils/Globals';
import { Setting, SettingSectionType, settingsSections, SUBSECTION_NAMES } from '../../../types/Setting';
import { PUBLISHED_STATUS, renderPublishStatusIcon } from '../../../utils/fnPublish';
import { PropagateSttingsTitle } from '../Settings.css';
import { fetchAllSettings, settingsState } from '../../../redux/slices/settingsSlice';
import { useAppSelector, useAppDispatch as useDispatch } from '../../../hooks/redux';
import _ from 'lodash';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { validator } from '../../../utils/fnValidator';
import { DropdownOptionWrapper, TruncatedText } from '../../../style/styled-components/reusable.css';
import ObjectNameTooltip from '../../common/Tooltips/ObjectNameTooltip/ObjectNameTooltip';
import {
    PublishedStatusIconHolder,
    ValueToDisplayIconsPlaceholder,
    ValueToDisplayWrapper
} from '../../PageEdit/Dialogs/UseExistingDialog.css';
import { ObjectTypes } from '../../../types/Object';

type PropagateSettingDialogProps = {
    id: string;
    open: boolean;
    onClose: () => void;
    onApply: (
        propagateOptions: { settingId: string; propagateTo: string[]; settingKey: SettingSectionType },
        withPublishAlert?: boolean
    ) => void;
    section?: SettingSectionType;
};

export const PropagateSettingDialog: FC<PropagateSettingDialogProps> = ({ open, id, onApply, onClose, section }) => {
    const dispatch = useDispatch();
    const { allSettings, loadingAllSettings }: settingsState = useAppSelector((state) => state.settings);
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const [settings, setSettings] = useState<Setting[]>([]);
    const [selectedSettings, setSelectedSettings] = useState<string[]>([]);
    const [selectedSettingsError, setSelectedSettingsError] = useState<string>('');

    const loadSettings = async (projectId?: string) => {
        if (!projectId) return;
        // fetching all modules here, not only the ones for the current page
        return await dispatch(fetchAllSettings({ projectId })).unwrap();
    };

    const validateSelection = () => {
        const selectedItemErr = validator({ required: true }, selectedSettings);
        setSelectedSettingsError(selectedItemErr);
        return !selectedItemErr;
    };

    const handleApplyClick = () => {
        if (!validateSelection()) return;
        const shouldShowRepublishAlert = settings
            .filter((setting) => selectedSettings.includes(setting._id))
            .some((sett) => sett.publishStatus === PUBLISHED_STATUS.PUBLISHED);

        onApply({ settingId: id, propagateTo: selectedSettings, settingKey: section! }, shouldShowRepublishAlert);
        handleCloseClick();
    };

    useEffect(() => {
        if (!id) return;
        const filteredSettings = allSettings.filter((setting) => setting._id !== id);
        setSettings(filteredSettings);
        setSelectedSettings(filteredSettings.map((set) => set._id));
    }, [allSettings, id]);

    useEffect(() => {
        if (!open) return;
        loadSettings(activeProjectId);
    }, [open]);

    const settingsOptions = useMemo(() => {
        return (
            settings?.map((setting) => ({
                value: setting._id,
                label: (
                    <DropdownOptionWrapper>
                        <TruncatedText>
                            <ObjectNameTooltip id={setting._id} name={setting.name || EMPTY_WORD_STRING} type={ObjectTypes.SETTINGS} />
                        </TruncatedText>

                        <PublishedStatusIconHolder>
                            {renderPublishStatusIcon(setting.publishStatus, setting.publishAt)}
                        </PublishedStatusIconHolder>
                    </DropdownOptionWrapper>
                ),
                valueForSearch: setting.name || EMPTY_WORD_STRING,
                valueToDisplay: (
                    <ValueToDisplayWrapper>
                        {setting.name || EMPTY_WORD_STRING}
                        <ValueToDisplayIconsPlaceholder />
                    </ValueToDisplayWrapper>
                )
            })) || []
        );
    }, [settings]);

    const handleCloseClick = () => {
        setSelectedSettings([]);
        setSettings([]);
        setSelectedSettingsError('');
        onClose();
    };

    const applyButton: DialogButton = {
        label: 'Apply',
        type: 'BLUE',
        onClick: handleApplyClick
    };

    const cancelButton: DialogButton = {
        label: `Cancel`,
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    if (!open) return null;

    return (
        <GenericDialog
            type={DialogTypes.Form}
            title={'Propagate Settings'}
            actionButtons={[cancelButton, applyButton]}
            onClose={handleCloseClick}
        >
            <PropagateSttingsTitle>
                The changes made in the <span>{SUBSECTION_NAMES[section || settingsSections.PIN]}</span> section will be applied to all of
                the selected Settings
            </PropagateSttingsTitle>
            <PropagateSttingsTitle>Do you wish to continue?</PropagateSttingsTitle>

            {/*SETTINGS*/}
            {
                <DialogDropdownMultiple
                    allowSelectAll
                    key={'settings'}
                    onChange={(selectedOptions: any[]) => {
                        setSelectedSettings(selectedOptions?.map((s) => s.value) || []);
                        setSelectedSettingsError('');
                    }}
                    placeholder={loadingAllSettings ? 'Loading...' : 'Settings List'}
                    labelText={'Settings List'}
                    options={settingsOptions}
                    value={settingsOptions.filter((opt) => selectedSettings.includes(opt.value))}
                    clearable
                    selectAllOption
                    isDisabled={loadingAllSettings}
                    error={selectedSettingsError}
                />
            }
        </GenericDialog>
    );
};
