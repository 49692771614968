import YoutubeLogo from './youtubeLogo.svg';
import YoutubeLogoAggregate from './youtubeLogoAggregate.svg';
import YoutubeLogoSettings from './youtubeLogoSettings.svg';
import PrimeVideoLogo from './primeVideoLogo.svg';
import PrimeVideoLogoAggregate from './primeVideoLogoAggregate.svg';
import PrimeVideoLogoSettings from './primeVideoLogoSettings.svg';
import StingrayLogoAggregate from './StingrayLogoAggregate.svg';
import StingrayLogoSettings from './StingrayLogoSettings.svg';
import DAZNLogoAggregate from './daznLogoAggregate.jpeg';
import DAZNLogo from './daznLogo.jpeg';
import AmagiLogoMonetize from './amagiLogoMonetize.png';
import ForScreenLogoMonetize from './4screenLogoMonetize.png';
import MagniteLogoMonetize from './magniteLogoMonetize.png';
import GoogleLogoMonetize from './googleLogoMonetize.png';

export const logos = {
    YoutubeLogo,
    YoutubeLogoAggregate,
    YoutubeLogoSettings,
    PrimeVideoLogo,
    PrimeVideoLogoSettings,
    PrimeVideoLogoAggregate,
    StingrayLogoAggregate,
    StingrayLogoSettings,
    DAZNLogoAggregate,
    DAZNLogo,
    AmagiLogoMonetize,
    ForScreenLogoMonetize,
    GoogleLogoMonetize,
    MagniteLogoMonetize
};
