import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';

export const adminLockObject = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { objectId: string; objectType: any }
>('adminLockSystem/adminLock', async ({ objectId, objectType }, thunkApi) => {
    const result = await ConfigServiceAPI.adminLockObject(objectId, objectType);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const adminUnlockObject = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { objectId: string; objectType: any }
>('adminLockSystem/adminUnlock', async ({ objectId, objectType }, thunkApi) => {
    const result = await ConfigServiceAPI.adminUnlockObject(objectId, objectType);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export interface AdminLockingState {
    loading: boolean;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: AdminLockingState = {
    loading: false,
    error: null
};

const slice = createSlice({
    name: 'adminLockSystem',
    initialState,
    reducers: {
        unsetAdminLockError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(adminLockObject.fulfilled, (state, _action) => {
                state.error = null;
                state.loading = false;
            })
            .addCase(adminLockObject.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(adminLockObject.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(adminUnlockObject.fulfilled, (state, _action) => {
                state.error = null;
                state.loading = false;
            })
            .addCase(adminUnlockObject.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(adminUnlockObject.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetAdminLockError } = slice.actions;
export default slice.reducer;
