import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import configServiceAPI from '../../utils/api/configServiceAPI';
import FileManagerClient from '../../utils/api/FileManagerClient/FileManagerClient';

export const fetchConfig = createAsyncThunk<{ config: any }>('config/fetechConfig', async (_, _thunkApi) => {
    const config = await fetch('/config.json')
        .then((response) => response.json())
        .catch(() => {});

    return { config };
});

interface CCConfig {
    IMGIX_PREFIX?: string;
    CLOUD_PROVIDER?: string;
    BACKEND_URL?: string;
    VIDEO_CDN_URL?: string;
    AZURE_ACCOUNT?: string;
    AZURE_CONTAINER?: string;
    AWS_S3_REGION?: string;
    CDN_BACKEND_URL?: string;
    FIREBASE_CONFIG?: string;
    SENTRY_DSN?: string;
    MARKERIO_DESTINATION?: string;
    imgixBaseUrl?: string;
    videoCdnUrl?: string;
}

export interface ConfigState {
    config: CCConfig;
    loadingConfig: boolean;
}

const initialState: ConfigState = {
    config: {},
    loadingConfig: false
};

const slice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchConfig.fulfilled, (state, action) => {
                const config = { ...action.payload.config };
                state.config = {
                    ...config,
                    imgixBaseUrl: `https://${config.IMGIX_PREFIX || 'cc3'}.imgix.net/`,
                    videoCdnUrl: config.VIDEO_CDN_URL || 'https://develop-CCP30-videos-a4gmetd7hkdfbda2.a02.azurefd.net/',
                    AZURE_CONFIG: {
                        account: config.AZURE_ACCOUNT,
                        container: config.AZURE_CONTAINER || 'static-assets'
                    },
                    AWS_S3_CONFIG: {
                        REGION: config.AWS_S3_REGION || 'eu-west-1', // fallback to eu-west-1
                        PREFIX: config.AWS_S3_PREFIX || 'static-assets'
                    }
                };
                configServiceAPI.baseUrl = (config.BACKEND_URL || `${window.location.protocol}//${window.location.host}`) + '/api';
                FileManagerClient.cloudProvider = config.CLOUD_PROVIDER;
                state.loadingConfig = false;
            })
            .addCase(fetchConfig.pending, (state, _action) => {
                state.loadingConfig = true;
            })
            .addCase(fetchConfig.rejected, (state, _action: any) => {
                state.config = {};
                state.loadingConfig = false;
            });
    }
});

export default slice.reducer;
