import React, { useEffect, useState } from 'react';
import { MembersTitle, MembersWrapper, ProjectMemberIconsHolder, ProjectNameOfMembers } from './Members.css';
import { User } from '../../../types/User';
import { MoreInfoDialog, MoreInfoTypes } from '../../common/Dialog/MoreInfoDialog';
import { Tooltip } from '@material-ui/core';
import icons from '../../../assets/images/icons';
import { UserAvatarPlaceholder, UserAvatar } from '../../Projects/Projects.css';
import useScreenSize from '../../../hooks/useScreenSize';
import { useAppDispatch as useDispatch } from '../../../hooks/redux';
import { fetchUsers } from '../../../redux/slices/usersSlice';

export const Members: React.FC<{ projectName: string; usersList: User[] }> = ({ projectName, usersList }) => {
    const [showMoreDialog, setShowMoreDialog] = useState<{ type: MoreInfoTypes.USERS | null; show: boolean; data: any[] }>({
        type: null,
        show: false,
        data: []
    });

    const { isDesktop } = useScreenSize();
    const dispatch = useDispatch();

    const loadUsers = async () => {
        await dispatch(fetchUsers({})).unwrap();
    };

    useEffect(() => {
        if (showMoreDialog.show) {
            loadUsers();
        }
    }, [showMoreDialog]);

    const maxNumOfUsersShown = isDesktop ? 7 : 4;
    return (
        <MembersWrapper>
            <MembersTitle>Members</MembersTitle>
            <ProjectNameOfMembers>Of {projectName}</ProjectNameOfMembers>
            <ProjectMemberIconsHolder onClick={() => setShowMoreDialog({ type: MoreInfoTypes.USERS, show: true, data: usersList })}>
                {usersList?.map((user, index: number) => {
                    if (index < maxNumOfUsersShown) {
                        return (
                            <Tooltip title={user.fullName} key={index}>
                                <UserAvatar background={user?.icon || icons.userIcon} />
                            </Tooltip>
                        );
                    }
                })}
                {usersList?.length > 0 && (
                    <UserAvatarPlaceholder>
                        {usersList.length > maxNumOfUsersShown ? `+ ${usersList.length - maxNumOfUsersShown}` : '...'}
                    </UserAvatarPlaceholder>
                )}
            </ProjectMemberIconsHolder>

            <MoreInfoDialog
                type={showMoreDialog.type!}
                open={showMoreDialog.show}
                onClose={() => setShowMoreDialog({ type: null, show: false, data: [] })}
                data={showMoreDialog.data}
                withAdd
            />
        </MembersWrapper>
    );
};
