import { TableRow, TableCell, TableBody } from '@material-ui/core';
import _ from 'lodash';
import SVGInline from 'react-inlinesvg';
import React, { FC, useEffect, useState } from 'react';
import icons from '../../../assets/images/icons';
import { useAppSelector, useAppDispatch as useDispatch } from '../../../hooks/redux';
import {
    AbTestingGroupsState,
    createAbTestingGroupsForTargetGroup,
    fetchAbTestingGroupsByTargetGroupId
} from '../../../redux/slices/abTestingGroupSlice';
import { AbTestingGroup } from '../../../types/AbTestingGroup';
import { renderLockedError } from '../../../utils/fnLockingSystem';
import { API_ERROR_CODES } from '../../../utils/Globals';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import { DialogTextField } from '../../common/Dialog/GenericDialog';
import { Loader } from '../../common/Loader/Loader';
import GenericTable, { ActionsTableCell, HeaderTableCell, tableActions } from '../../common/Table/Table';
import { WidthTableCell } from '../../common/Table/Table.css';
import PageActions from '../../common/PageActions/PageActions';
import { renderTooltipWithKey } from '../../common/Tooltips/Tooltips';
import { PageActionsWrapper, PageActionButton } from '../../../style/styled-components/reusable.css';
import { changeTargetGroupAbTestingStatus, fetchTargetGroup, targetGroupsState } from '../../../redux/slices/targetGroupsSlice';
import {
    AbTestingGroupNameInputWrapper,
    AbTestingGroupPercentageInputWrapper,
    AbTestingPercentageInputWrapper,
    ABTestingStatusWrapper,
    ABTestingStatusButton,
    ErrorContainer,
    ABTestingStatusInfo,
    AbTestingIconOngoingWrapper,
    AbTestingIconActiveWrapper
} from './AbTestingGroups.css';
import getTheme from '../../../style/themes/theme';
import { TargetGroupAbTestingStatus } from '../../../types/TargetGroup';
import { dialogAlert, dialogConfirm, DIALOG_NAMES } from '../../../utils/fnDialogs';
import useBlockNavigation from '../../../hooks/useBlockNavigation';
import { renderUnsavedAlertBeforeAction } from '../../PaginationWrapper/PaginationWrapper';
import { UNSAFE_useEffectOnce } from '../../../hooks/useEffectOnce';

export type AbTestingGroupsProps = {
    groupId?: string;
    abTestingStatus?: TargetGroupAbTestingStatus;
};

const ABTestingActiveWarningValues = {
    title: 'A/B Testing active',
    subtitle: `An A/B Testing campaign is currently running or it's paused.`,
    text: `Save is disabled because of it. If you wish to make changes to the A/B testing groups, you must stop the campaign.`
};

export enum AbTestingUIStatus {
    EDITING,
    ACTIVE,
    PAUSED,
    DEFAULT,
    ONGOING
}

const ABTestingStatusValues: Record<AbTestingUIStatus, { title: string; text: string }> = {
    [AbTestingUIStatus.DEFAULT]: {
        title: 'A/B Testing',
        text: 'A/B Testing is not active. Please form at least two groups and set your allocation percentages for A/B testing.'
    },
    [AbTestingUIStatus.ACTIVE]: {
        title: 'Start A/B Testing',
        text: 'A/B Testing is not active. You can start A/B Testing at any time.'
    },
    [AbTestingUIStatus.EDITING]: {
        title: 'Start A/B Testing',
        text: 'Please finish editing before changing the A/B testing status.'
    },
    [AbTestingUIStatus.PAUSED]: {
        title: 'Paused A/B Testing',
        text: 'A/B Testing is paused. A/B testing can be resumed or stopped. No other changes can be done.'
    },
    [AbTestingUIStatus.ONGOING]: {
        title: 'Ongoing A/B Testing',
        text: 'A/B Testing is running. A/B testing can be stopped or paused. No other changes can be done.'
    }
};

const theme: any = getTheme();

export const AbTestingGroups: FC<AbTestingGroupsProps> = ({ groupId, abTestingStatus }) => {
    const { abTestingGroups: storeGroups, loading, error } = useAppSelector<AbTestingGroupsState>((state) => state.abTestingGroups);
    const { loadingGroup, loading: targetGroupsLoading } = useAppSelector<targetGroupsState>((state) => state.targetGroups);
    const [percentageError, setPercentageError] = useState(false);
    const [errors, setErrors] = useState<{ name?: boolean; percentage?: boolean }>({});

    const [showUnsaved, setShowUnsaved] = useState(false);

    const [abTestingGroups, setAbTestingGroups] = useState<AbTestingGroup[]>([]);

    const totalPercentage = abTestingGroups.reduce((total, group) => total + group.percentage, 0);

    const ABTestingRunning = [TargetGroupAbTestingStatus.ACTIVE, TargetGroupAbTestingStatus.PAUSED].includes(abTestingStatus!);

    const dispatch = useDispatch();
    useBlockNavigation(showUnsaved, () => renderAlertUnsavedChanges(), [abTestingGroups]);

    const loadAbTestingGroups = async () => {
        if (groupId) await dispatch(fetchAbTestingGroupsByTargetGroupId({ targetGroupId: groupId, createDefault: true })).unwrap();
    };

    const createAbTestingGroups = async (abTestingGroups: { name: string; percentage: number; _id?: string }[]) => {
        if (groupId) await dispatch(createAbTestingGroupsForTargetGroup({ targetGroupId: groupId, abTestingGroups })).unwrap();
    };

    const loadTargetGroup = async () => {
        if (groupId) await dispatch(fetchTargetGroup({ targetGroupId: groupId })).unwrap();
    };

    const _changeTargetGroupABTestingStatus = async (abTestingStatus: TargetGroupAbTestingStatus) => {
        if (!groupId) return;
        const response = await dispatch(changeTargetGroupAbTestingStatus({ targetGroupId: groupId, abTestingStatus })).unwrap();
        if (response.error) return;

        loadTargetGroup();
        loadAbTestingGroups();
    };

    // make sure this is only called once in development because if not, this can create 2 default ab testing groups if called twice at the same time
    UNSAFE_useEffectOnce(() => {
        loadAbTestingGroups();
    }, [groupId]);

    useEffect(() => {
        setAbTestingGroups(storeGroups);
    }, [storeGroups]);

    useEffect(() => {
        setShowUnsaved(!_.isEqual(abTestingGroups, storeGroups));
    }, [abTestingGroups]);

    useEffect(() => {
        if (!abTestingGroups.length || totalPercentage === 100) {
            return;
        }
        const difference = totalPercentage - 100;
        const defaultGroupIndex = abTestingGroups.findIndex((elem) => elem.isDefault);
        const newGroups = [...abTestingGroups];
        newGroups[defaultGroupIndex] = {
            ...abTestingGroups[defaultGroupIndex],
            percentage: abTestingGroups[defaultGroupIndex].percentage - difference
        };
        if (newGroups[defaultGroupIndex].percentage < 0) newGroups[defaultGroupIndex].percentage = 0;
        if (newGroups[defaultGroupIndex].percentage > 100) newGroups[defaultGroupIndex].percentage = 100;
        setAbTestingGroups(newGroups);
    }, [totalPercentage]);

    const validateGroups = (abTestingGroups: any) => {
        const noNameGroups = abTestingGroups.filter((elem: any) => !elem.name);
        const noPercentageGroups = abTestingGroups.filter((elem: any) => !elem.percentage || elem.percentage <= 0);
        const newErrors = { name: noNameGroups.length > 0, percentage: noPercentageGroups.length > 0 };
        setErrors(newErrors);
        return !newErrors.name && !newErrors.percentage;
    };

    const handleTableChange = (e: any, key: 'name' | 'percentage', index: number) => {
        const value = key === 'percentage' ? (isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)) : e.target.value;
        const newAbTestingGroup = {
            ...abTestingGroups[index],
            [key]: value
        };
        if (key === 'percentage') {
            const oldAbTestingGroup = abTestingGroups[index];
            const defaultAbTestingGroupPercentage = abTestingGroups.find((elem) => elem.isDefault)?.percentage || 0;
            const newPercentage = totalPercentage - oldAbTestingGroup.percentage + value - defaultAbTestingGroupPercentage;
            setPercentageError(newPercentage > 100);
        }
        const newAbTestingGroups = [...abTestingGroups];
        newAbTestingGroups[index] = newAbTestingGroup;

        if (errors.name || errors.percentage) {
            validateGroups(newAbTestingGroups);
        }

        setAbTestingGroups(newAbTestingGroups);
    };

    const buildTableColumns = () =>
        abTestingGroups.length
            ? ['', 'name', '%'].map((key, index) => {
                  return (
                      <HeaderTableCell
                          key={`${index}_cell`}
                          text={key === '%' ? 'Set %' : key === 'name' ? 'Group Name' : ''}
                          columnSize={key === '' && { $width: 14, $um: 'px' }}
                      />
                  );
              })
            : [];

    const buildTableBody = () => {
        const rows = abTestingGroups.map(({ name, percentage, isDefault }, index) => {
            return (
                <TableRow key={`ab-testing-groups-row-${index}`} data-cy={`ab-testing-groups-row-${index}`}>
                    <WidthTableCell $width={14} $um={'px'} $fixedSize>
                        {renderTooltipWithKey(
                            <SVGInline style={!isDefault ? { visibility: 'hidden' } : undefined} src={icons.infoIcon} />,
                            'target_groups_ab_testing_groups_default_group'
                        )}
                    </WidthTableCell>
                    <TableCell>
                        <AbTestingGroupNameInputWrapper>
                            <DialogTextField
                                placeholder={'Add Name'}
                                value={name}
                                onChange={(e: any) => {
                                    handleTableChange(e, 'name', index);
                                }}
                                inputProps={errors.name && !name && { style: { color: theme.palette.error.main } }}
                                isDisabled={ABTestingRunning}
                                noLabel
                            />
                        </AbTestingGroupNameInputWrapper>
                    </TableCell>
                    <WidthTableCell $width={40} $um={'%'} $fixedSize>
                        <AbTestingGroupPercentageInputWrapper>
                            <DialogTextField
                                placeholder={'Add Percentage'}
                                value={percentage.toString()}
                                onChange={(e: any) => {
                                    handleTableChange(e, 'percentage', index);
                                }}
                                isDisabled={isDefault || ABTestingRunning}
                                inputProps={
                                    (percentageError || (errors.percentage && !percentage)) && {
                                        style: { color: theme.palette.error.main }
                                    }
                                }
                                noLabel
                            />
                            <AbTestingPercentageInputWrapper $disabled={isDefault || ABTestingRunning}>%</AbTestingPercentageInputWrapper>
                        </AbTestingGroupPercentageInputWrapper>
                    </WidthTableCell>
                    {/* ACTIONS TABLE CELL */}
                    <WidthTableCell $width={56} $um={'px'} $fixedSize>
                        {!isDefault && (
                            <ActionsTableCell
                                actions={ABTestingRunning ? [] : [tableActions.REMOVE]}
                                onRemove={() => {
                                    const newAbTestingGroups = [...abTestingGroups];
                                    newAbTestingGroups.splice(index, 1);
                                    setAbTestingGroups(newAbTestingGroups);
                                    (errors.name || errors.percentage) && validateGroups(newAbTestingGroups);
                                    setPercentageError(newAbTestingGroups.reduce((total, group) => total + group.percentage, 0) > 100);
                                }}
                                tooltipTexts={{ delete: 'target_groups_ab_testing_delete_ab_testing_group' }}
                            />
                        )}
                    </WidthTableCell>
                </TableRow>
            );
        });
        return <TableBody>{rows}</TableBody>;
    };

    const renderError = (error: any) => {
        switch (error.code) {
            case API_ERROR_CODES.LOCKED_ERROR:
                return renderLockedError(error);
            default:
                return <BackendErrorDialog error={error} />;
        }
    };

    const renderStopConfirmDialog = () => {
        const values = {
            title: `Stop A/B Testing`,
            text: (
                <>
                    <div>
                        You are in the process of stopping the A/B testing campaign. This will cause all the A/B testing groups to be
                        unassigned from all the objects and the setup must be done again from scratch. If you only want to stop for a
                        limited amount of time, please consider pausing.
                    </div>
                    <br />
                    <div>Are you sure you want to stop the A/B testing campaign?</div>
                </>
            )
        };
        dialogConfirm(
            '',
            () => {
                _changeTargetGroupABTestingStatus(TargetGroupAbTestingStatus.INACTIVE);
            },
            values,
            null,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Stop'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    const renderErrorArea = () => {
        const texts: string[] = [];
        if (percentageError) {
            texts.push(
                'The sum of percentages should be 100%.\nPlease try a smaller percentage, or alternatively, adjust the other input values.'
            );
            return <ErrorContainer>{texts}</ErrorContainer>;
        }

        if (errors.name) texts.push('All A/B Testing groups must have a name.');
        if (errors.percentage) texts.push('All A/B Testing groups must have a percentage higher than 0.');
        return texts.length ? <ErrorContainer>{texts.join('\n')}</ErrorContainer> : null;
    };

    const renderAbTestingStatusArea = () => {
        const onClick = (status: TargetGroupAbTestingStatus) => {
            if (showUnsaved) return renderUnsavedAlertBeforeAction();
            if (status === TargetGroupAbTestingStatus.INACTIVE) {
                return renderStopConfirmDialog();
            }
            _changeTargetGroupABTestingStatus(status);
        };

        const enoughGroupsForStarting = abTestingGroups.filter((group) => group.percentage > 0).length > 1;

        let UIStatus: AbTestingUIStatus = AbTestingUIStatus.DEFAULT;
        if (showUnsaved) UIStatus = enoughGroupsForStarting ? AbTestingUIStatus.EDITING : AbTestingUIStatus.DEFAULT;
        else {
            switch (abTestingStatus) {
                case undefined:
                case TargetGroupAbTestingStatus.INACTIVE:
                    if (enoughGroupsForStarting) UIStatus = AbTestingUIStatus.ACTIVE;
                    else UIStatus = AbTestingUIStatus.DEFAULT;
                    break;
                case TargetGroupAbTestingStatus.ACTIVE:
                    UIStatus = AbTestingUIStatus.ONGOING;
                    break;
                case TargetGroupAbTestingStatus.PAUSED:
                    UIStatus = AbTestingUIStatus.PAUSED;
                    break;
                default:
                    break;
            }
        }

        const textValues = ABTestingStatusValues[UIStatus];
        const ABTestingStatusJSX: Record<AbTestingUIStatus, JSX.Element> = {
            [AbTestingUIStatus.DEFAULT]: <SVGInline src={icons.abTestingStatusIcon} />,
            [AbTestingUIStatus.ONGOING]: (
                <AbTestingIconOngoingWrapper>
                    <div onClick={() => onClick(TargetGroupAbTestingStatus.PAUSED)}>
                        <SVGInline src={icons.abTestingPauseIcon} />
                    </div>
                    <div onClick={() => onClick(TargetGroupAbTestingStatus.INACTIVE)}>
                        <SVGInline src={icons.abTestingStopIcon} />
                    </div>
                </AbTestingIconOngoingWrapper>
            ),
            [AbTestingUIStatus.ACTIVE]: (
                <AbTestingIconActiveWrapper onClick={() => onClick(TargetGroupAbTestingStatus.ACTIVE)}>
                    <SVGInline src={icons.abTestingPlayIcon} />
                </AbTestingIconActiveWrapper>
            ),
            [AbTestingUIStatus.PAUSED]: (
                <AbTestingIconOngoingWrapper $paused>
                    <div onClick={() => onClick(TargetGroupAbTestingStatus.ACTIVE)}>
                        <SVGInline src={icons.abTestingPlayIcon} />
                    </div>
                    <div onClick={() => onClick(TargetGroupAbTestingStatus.INACTIVE)}>
                        <SVGInline src={icons.abTestingStopIcon} />
                    </div>
                </AbTestingIconOngoingWrapper>
            ),
            [AbTestingUIStatus.EDITING]: (
                <AbTestingIconActiveWrapper $editing>
                    <SVGInline src={icons.abTestingPlayIcon} />
                </AbTestingIconActiveWrapper>
            )
        };
        return (
            <ABTestingStatusWrapper>
                <ABTestingStatusButton>{ABTestingStatusJSX[UIStatus]}</ABTestingStatusButton>
                <ABTestingStatusInfo>
                    <div>{textValues.title}</div>
                    <div>{textValues.text}</div>
                </ABTestingStatusInfo>
            </ABTestingStatusWrapper>
        );
    };

    const renderAlertUnsavedChanges = () => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => {
                handleSave();
            },
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                handleCancel();
            },
            true
        );
    };

    const handleSave = async () => {
        if (!validateGroups(abTestingGroups)) return;

        const groupsToSave = abTestingGroups.map((elem) => ({
            name: elem.name,
            projectId: elem.projectId,
            percentage: elem.percentage,
            _id: elem._id
        }));
        await createAbTestingGroups(groupsToSave);
        loadAbTestingGroups();
    };

    const handleCancel = () => {
        setAbTestingGroups(storeGroups);
        setPercentageError(false);
        setErrors({});
    };

    const generalLoading = loading || loadingGroup || targetGroupsLoading;

    return (
        <>
            {error && renderError(error)}

            {generalLoading ? (
                <Loader title={'A/B Testing Groups'} />
            ) : (
                <>
                    {renderAbTestingStatusArea()}
                    <GenericTable body={buildTableBody()} columns={buildTableColumns()} />
                    {(percentageError || errors.name || errors.percentage) && renderErrorArea()}

                    <PageActionsWrapper>
                        <PageActionButton
                            onClick={() => {
                                const newGroup: any = {
                                    name: '',
                                    percentage: 0
                                };
                                setAbTestingGroups([...abTestingGroups, newGroup]);
                            }}
                            isDisabled={!!abTestingGroups.find((elem) => elem.percentage === 0) || ABTestingRunning}
                            label={'Create A/B Testing Group'}
                            type={'BLUE'}
                        />
                    </PageActionsWrapper>

                    <PageActions
                        warnings={
                            ABTestingRunning
                                ? [
                                      {
                                          icon: icons.infoIcon,
                                          onClick: () => {
                                              dialogAlert('', false, ABTestingActiveWarningValues, null, false, icons.warningYellowIcon);
                                          }
                                      }
                                  ]
                                : undefined
                        }
                        onSave={handleSave}
                        disabled={{
                            save: !showUnsaved || ABTestingRunning
                        }}
                        onCancel={handleCancel}
                    />
                </>
            )}
        </>
    );
};
