import styled from 'styled-components';
import { ButtonWrapper } from '../../Buttons/Button/Button.css';
import { StyledDialog } from '../Dialog/GenericDialog.css';

export const PublishButtonsWrapper = styled.div`
    display: flex;
    gap: 2px;

    div.react-datepicker__month-container {
        border-bottom: unset;
    }

    div.react-datepicker-popper {
        // these props are marked with important because the popper has a style property that needs to be overridden
        position: fixed !important;
        transform: none !important;
        top: 50% !important;
        left: 50% !important;

        z-index: 9999;
        margin-top: -290px;
        margin-left: -178px;
    }

    ${StyledDialog} {
        width: 100px;
        div.MuiBackdrop-root {
            display: none;
        }

        div.MuiPaper-root {
            width: fit-content;
            margin-left: 50%;
        }
    }
`;

export const ScheduleButtonWrapper = styled.div`
    ${ButtonWrapper} {
        width: 32px;
        height: 32px;
    }
`;
