import styled from 'styled-components';
import { devices } from '../../style/styled-components/reusable.css';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { SelectWrapper } from '../common/Select/Select.css';
import { NameWrapper } from '../Modules/Modules.css';

const theme: any = getTheme();

/*
 *   TemplateSelectionDialog
 * */

export const TemplatesContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    ${SelectWrapper} {
        ${fontSizesCSS.Body1}
        [class$='indicatorSeparator'] {
            display: none;
        }
    }
`;
export const FiltersAndSortersContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const TemplateCard = styled.div`
    width: 350px;
    height: 92px;
    padding: 24px;
    box-sizing: border-box;
    border: 1px solid ${theme.palette.background.border};
    border-radius: 4px;
    box-shadow: 0 0 3px ${theme.palette.background.border};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    & > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    cursor: pointer;
    &:hover {
        border: 1px solid ${theme.palette.primary.main};
    }
    ${devices.desktopS} {
        width: 344px;
    }
    ${devices.tablet} {
        width: 100%;
    }
`;
export const TemplateName = styled.div`
    ${fontSizesCSS.Caption2}
`;
export const TemplateType = styled.div`
    ${fontSizesCSS.Caption3}
`;

export const TemplatePlacedContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const TemplateFiltersContainer = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    ${devices.tablet} {
        flex-direction: column;
    }

    .MuiFormControl-root {
        width: 344px;
        border-radius: 4px;
    }
    .MuiInputBase-root {
        border-radius: 4px;
    }
`;

export const TemplateCardsContainer = styled.div`
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    max-height: calc(95vh - 220px);
    max-width: calc(100% + 32px);
`;

export const TemplatesLoaderWrapper = styled.div`
    height: 347px;
`;
export const SortSelectWrapper = styled.div`
    ${SelectWrapper} {
        width: 180px;
        ${devices.tablet} {
            width: 100%;
        }
    }
`;
export const OptionWrapper = styled.div`
    display: flex;
    align-items: center;
    span {
        margin-left: 4px;
        font-weight: bold;
    }
`;
export const OptionText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
`;

export const FilterSelectWrapper = styled.div`
    margin-left: auto;
    ${devices.tablet} {
        margin-left: 0;
    }

    ${SelectWrapper} {
        ${devices.tablet} {
            margin-left: 0;
            width: 100%;
        }
        margin-left: auto;
        width: 160px;
    }
`;

export const ProjectLabel = styled.div`
    padding: 0;
    display: flex;
    align-items: center;
    gap: 4px;
    img {
        min-width: 16px;
        height: 16px;
    }
`;

export const PageNameContainer = styled(NameWrapper)``;

/*--------------------------------------*/

export const CreatePageIconContainer = styled.div<{ $background?: string }>`
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 4px;
    background: ${({ $background }) => $background || theme.palette.primary.main};
    img {
        color: ${theme.palette.white.main};
        width: 40px;
        height: 40px;
        object-fit: contain;
    }
`;
