import _ from 'lodash';
import React, { FC, useState } from 'react';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../GenericDialog';

export type OpenUrlDialog = {
    open: boolean;
    onSave: (url: string) => void;
    onClose: () => void;
};

const OpenUrlDialog: FC<OpenUrlDialog> = ({ open, onSave, onClose }) => {
    const [url, setUrl] = useState<string>('');

    const handleCloseClick = () => {
        onClose?.();
        setUrl('');
    };

    const handleSaveClick = async () => {
        onSave?.(url);
        handleCloseClick();
    };

    const addButton: DialogButton = {
        label: 'Add',
        type: 'BLUE',
        onClick: handleSaveClick
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    if (!open) {
        return null;
    }

    return (
        <GenericDialog type={DialogTypes.Form} actionButtons={[cancelButton, addButton]} title={'Set URL'} onClose={handleCloseClick}>
            <DialogTextField
                value={url}
                onChange={(e: any) => {
                    setUrl(e.target.value);
                }}
                label={'URL'}
            />
        </GenericDialog>
    );
};

export default OpenUrlDialog;
