import styled from 'styled-components';
import { fontSizesCSS } from '../../../style/themes/theme';
import { devices } from '../../../style/styled-components/reusable.css';
import { UserAvatar } from '../../Projects/Projects.css';

export const MembersWrapper = styled.div`
    box-sizing: border-box;
    height: 108px;
    width: 360px;
    padding: 0 36px;
    display: flex;
    flex-direction: column;
    ${devices.desktopS} {
        width: 251px;
    }
    ${devices.tablet} {
        width: 360px;
    }
`;
export const MembersTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    ${fontSizesCSS.Title3}
    margin-bottom: 12px;
`;

export const ProjectNameOfMembers = styled.div`
    ${fontSizesCSS.Caption3}
    font-weight: bold;
`;

export const ProjectMemberIconsHolder = styled.div`
    display: flex;
    ${UserAvatar} {
        margin-right: -8px;
        width: 40px;
        height: 40px;
    }
`;
