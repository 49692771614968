import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';

const theme: any = getTheme();

export const DashboardCardsWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 24px;
`;

export const MoreInfoCardsWrapper = styled(DashboardCardsWrapper)``;

export const Footer = styled.footer<{ $sidebarOpen?: boolean }>`
    display: flex;
    justify-content: space-between;
    color: ${theme.palette.text.main};
    ${fontSizesCSS.Caption3};
    //padding-bottom: 36px;
    //width: ${(props) => (!props.$sidebarOpen ? 'calc(100% - 96px)' : 'calc(100% - 312px)')};
    transition: width 150ms ease-in-out;
    //position: absolute;
    //bottom: 100px;
    margin-top: auto;
`;

export const DateAndCompanyWrapper = styled.div``;

export const ApplicationVersionWrapper = styled.div``;
