import React, { FC, useEffect, useState } from 'react';
import {
    ApplicationWrapper,
    MainContentWrapper,
    NoResourcesContainer,
    PageActionButton,
    PageActionsWrapper,
    TruncatedText
} from '../../../../style/styled-components/reusable.css';
import Sidebar from '../../Sidebar/Sidebar';
import ScreenTitle from '../../../common/DashboardTitle/ScreenTitle';
import {
    createTemplate as _createTemplate,
    deleteTemplate as _deleteTemplate,
    fetchTemplates,
    releaseTemplate as _releaseTemplate,
    retractTemplate as _retractTemplate,
    templatesState,
    updateTemplate as _updateTemplate
} from '../../../../redux/slices/templatesSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import { Template, templateTypes } from '../../../../types/Template';
import { TemplateImage, TemplateImageWrapper, TemplateName, TemplatesWrapper } from '../Templates.css';
import { ResourceCard } from '../../../Cards/ResourceCard/ResourceCard';
import { docsWidget } from '../../../App';
import circleSlugs from '../../../../utils/circle_slugs.json';
import GenericTable, { ActionsTableCell, HeaderTableCell, SortableHeaderTableCell, tableActions } from '../../../common/Table/Table';
import { generateDateStringForTables } from '../../../../utils/fnDate';
import { PUBLISHED_STATUS } from '../../../../utils/fnPublish';
import { TableBody, TableRow } from '@material-ui/core';
import { WidthTableCell } from '../../../common/Table/Table.css';
import { dialogConfirm } from '../../../../utils/fnDialogs';
import { RemoveModuleWrapper, TemplateHeaderStyle } from '../../../Modules/Modules.css';
import { ACCEPTED_SORT_FIELDS, calculateOrderByFromSortConfig, DEFAULT_SORT_CONFIG, ISortConfig } from '../../../../utils/fnSort';
import { NewPageTemplate } from './NewPage';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../../../types/RouteTypes';
import { GroupField, GroupFieldsContainer } from '../../../TargetGroups/GroupEdit.css';
import { Loader } from '../../../common/Loader/Loader';
import { SearchBarContainer } from '../../../common/SearchBar/SearchBar.css';
import { SearchBar } from '../../../common/SearchBar/SearchBar';

export const PagesTemplates: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { templates: storeTemplates, templateTypeLoading }: templatesState = useAppSelector((state) => state.templates);

    const [activeTab, setActiveTab] = useState<'SYSTEM_TEMPLATES' | 'USER_SUGGESTIONS'>('SYSTEM_TEMPLATES');
    const [openNewTemplate, setOpenNewTemplate] = useState<boolean>(false);
    const [pageToEdit, setPageToEdit] = useState<Template | undefined>(undefined);
    const [newId, setNewId] = useState<string>('');
    const [pageTemplates, setPageTemplates] = useState<any[]>([]);

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [activeSortingKey, setActiveSortingKey] = useState<string | null>(ACCEPTED_SORT_FIELDS.lastModified);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);

    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;

    const openDocumentation = (key: string) => {
        if (!docsWidget) return;
        docsWidget.setSlug(key);
        return docsWidget.element?.click();
    };

    const loadTemplates = async (orderBy?: string, searchTerm?: string) => {
        await dispatch(fetchTemplates({ type: templateTypes.PAGE, orderBy, searchTerm })).unwrap();
    };

    const handleOnSearch = (searchTerm: string) => {
        loadTemplates(orderBy, searchTerm);
    };

    const createTemplate = async (template: Template) => {
        const response = await dispatch(_createTemplate(template)).unwrap();

        if (response.id) {
            loadTemplates();
            setNewId(response.id);
            setSearchTerm(undefined);
            setSortConfig(DEFAULT_SORT_CONFIG);
            setActiveSortingKey(ACCEPTED_SORT_FIELDS.lastModified);
        }
    };

    const updateTemplate = async (values: any) => {
        const response = await dispatch(_updateTemplate(values)).unwrap();
        if (response) {
            loadTemplates(calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG), searchTerm);
            setActiveSortingKey(ACCEPTED_SORT_FIELDS.lastModified);
            setSortConfig(DEFAULT_SORT_CONFIG);
        }
    };

    const deleteTemplate = async (id: string) => {
        const response = await dispatch(_deleteTemplate({ templateId: id, type: templateTypes.PAGE })).unwrap();
        if (response) {
            loadTemplates(orderBy, searchTerm);
        }
    };

    const releaseTemplate = async (id: string) => {
        const response = await dispatch(_releaseTemplate({ templateId: id, type: templateTypes.PAGE })).unwrap();
        if (response) {
            loadTemplates(orderBy, searchTerm);
        }
    };

    const retractTemplate = async (id: string) => {
        const response = await dispatch(_retractTemplate({ templateId: id, type: templateTypes.PAGE })).unwrap();
        if (response) {
            loadTemplates(orderBy, searchTerm);
        }
    };

    const handleSortIconClick = (field: string) => {
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && (sortConfig.field === field || sortConfig.field.split('.')[0] === field) && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            direction,
            field
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadTemplates(orderBy, searchTerm);
        setShowSortArrows(false);
    };

    const handleDeleteClick = (id: string) => {
        const values = {
            title: 'Remove Page Template',
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                deleteTemplate(id);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to remove this Page Template?</strong>
                    <br />
                    By Pressing “Remove” you still will be able to create new one from list
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    useEffect(() => {
        loadTemplates(orderBy, searchTerm);
    }, []);

    useEffect(() => {
        if (templateTypeLoading.page) return;
        if (storeTemplates?.page?.length) {
            setPageTemplates(storeTemplates.page);
        } else setPageTemplates([]);
    }, [templateTypeLoading.page]);

    useEffect(() => {
        if (!storeTemplates.page?.length) return;
        if (pageToEdit) return setPageToEdit(storeTemplates?.page?.find((template) => template._id === pageToEdit._id));

        if (newId && openNewTemplate) {
            setPageToEdit(storeTemplates?.page?.find((template) => template._id === newId));
            setNewId('');
        }
    }, [storeTemplates?.page]);

    const renderNoPages = () => {
        return (
            <NoResourcesContainer>
                <ResourceCard
                    title={'Create a new Page Template'}
                    subtitle={'Create a new Page Template from scratch'}
                    primaryButtonLabel={'Create Page Template'}
                    secondaryButtonLabel={'Learn More'}
                    onPrimaryButtonClick={() => setOpenNewTemplate(true)}
                    onSecondaryButtonClick={() => openDocumentation(circleSlugs.templates)}
                />
            </NoResourcesContainer>
        );
    };

    const buildTableColumns = () => {
        return Object.entries({
            icon: 'Icon',
            name: 'Page Name',
            lastModified: 'Last Modified'
        }).map(([key, value], index) => {
            const isAcceptedSorField = ACCEPTED_SORT_FIELDS[key as keyof typeof ACCEPTED_SORT_FIELDS];
            return isAcceptedSorField ? (
                <SortableHeaderTableCell
                    text={value}
                    key={`${index}_cell`}
                    style={index === 0 ? TemplateHeaderStyle : undefined}
                    direction={((sortConfig?.field === key || sortConfig?.field.split('.')[0] === key) && sortConfig?.direction) || 'asc'}
                    onClick={() => handleSortIconClick(key)}
                    onMouseEnter={() => setShowSortArrows(true)}
                    onMouseLeave={() => setShowSortArrows(false)}
                    hideArrow={!showSortArrows && activeSortingKey !== key}
                />
            ) : (
                <HeaderTableCell text={value} key={`${index}_cell`} columnSize={{}} />
            );
        });
    };

    const buildTableBody = () => {
        const rows = pageTemplates.map((page) => {
            const lastModified = generateDateStringForTables(page?.lastModified || 0);
            const publishedStatus = page?.released ? PUBLISHED_STATUS.PUBLISHED : PUBLISHED_STATUS.UNPUBLISHED;
            return (
                <>
                    <TableRow onClick={() => {}}>
                        {/* TEMPLATE ICON TABLE CELL */}
                        <WidthTableCell $width={48} $um={'px'} $fixedSize={true}>
                            <TemplateImageWrapper $background={page?.iconBackground}>
                                <TemplateImage src={page?.icon} />
                            </TemplateImageWrapper>
                        </WidthTableCell>

                        {/* TEMPLATE NAME TABLE CELL */}
                        <WidthTableCell $width={380} $wDesktopL={260} $wDesktopS={140} $wTab={104} $um={'px'}>
                            <TemplateName onClick={() => navigate(PageRoutes.SUPERADMIN_PAGE.replace(':page_id', page._id))}>
                                <TruncatedText>{page.values.name}</TruncatedText>
                            </TemplateName>
                        </WidthTableCell>

                        {/* LAST MODIFIED TABLE CELL */}
                        <WidthTableCell $width={108} $um={'px'} $fixedSize={true}>
                            {lastModified}
                        </WidthTableCell>

                        {/* ACTIONS TABLE CELL */}
                        <WidthTableCell $wTab={92} $um={'px'}>
                            <ActionsTableCell
                                actions={[tableActions.EDIT, tableActions.REMOVE]}
                                onEdit={(e: React.MouseEvent<any>) => {
                                    setOpenNewTemplate(true);
                                    setPageToEdit(page);
                                    e.stopPropagation();
                                }}
                                onRemove={(e: React.MouseEvent<any>) => {
                                    handleDeleteClick(page._id);
                                    e.stopPropagation();
                                }}
                                tooltipTexts={{
                                    edit: 'superadmin_pages_templates_icon_edit',
                                    delete: 'superadmin_pages_templates_icon_delete',
                                    published: page?.released
                                        ? 'superadmin_pages_templates_icon_released'
                                        : 'superadmin_pages_templates_icon_unreleased'
                                }}
                                publishedStatus={publishedStatus}
                            />
                        </WidthTableCell>
                    </TableRow>
                </>
            );
        });

        return <TableBody>{rows}</TableBody>;
    };

    const renderPagesTable = () => {
        return (
            <>
                <GenericTable body={buildTableBody()} columns={buildTableColumns()} dataCy={'superAdmin-pages-table'} />{' '}
                <PageActionsWrapper>
                    <PageActionButton
                        onClick={() => {
                            setOpenNewTemplate(true);
                        }}
                        label={'Create Page Template'}
                        type={'BLUE'}
                    />
                </PageActionsWrapper>
            </>
        );
    };

    const renderUserSuggestions = () => {
        return <></>;
    };

    const renderSystemTemplates = () => {
        return <TemplatesWrapper>{pageTemplates.length ? renderPagesTable() : renderNoPages()}</TemplatesWrapper>;
    };

    return (
        <ApplicationWrapper>
            <Sidebar />
            <MainContentWrapper>
                <ScreenTitle
                    title={'Page Templates'}
                    withProfile
                    withAddButton
                    withoutSearch
                    addLabel={'Create Page Template'}
                    onAdd={() => {
                        setOpenNewTemplate(true);
                    }}
                />
                <GroupFieldsContainer withBottomMargin>
                    <GroupField onClick={() => setActiveTab('SYSTEM_TEMPLATES')} $active={activeTab === 'SYSTEM_TEMPLATES'}>
                        System Templates
                    </GroupField>
                    <GroupField
                        onClick={() => {
                            setActiveTab('USER_SUGGESTIONS');
                        }}
                        $active={activeTab === 'USER_SUGGESTIONS'}
                        $disabled
                    >
                        User Suggestions
                    </GroupField>
                </GroupFieldsContainer>
                <SearchBarContainer>
                    <SearchBar
                        title={'Search by Name'}
                        searchTerm={searchTerm}
                        onSearch={handleOnSearch}
                        setSearchTerm={setSearchTerm}
                        disabled={templateTypeLoading.page}
                    />
                </SearchBarContainer>
                {templateTypeLoading.page ? (
                    <Loader />
                ) : activeTab === 'SYSTEM_TEMPLATES' ? (
                    renderSystemTemplates()
                ) : (
                    renderUserSuggestions()
                )}
                <NewPageTemplate
                    open={openNewTemplate}
                    onClose={() => {
                        setOpenNewTemplate(false);
                        setPageToEdit(undefined);
                    }}
                    page={pageToEdit}
                    onRelease={releaseTemplate}
                    onRetract={retractTemplate}
                    onSave={(template) => (pageToEdit ? updateTemplate(template) : createTemplate(template))}
                />
            </MainContentWrapper>
        </ApplicationWrapper>
    );
};
