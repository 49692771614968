import styled from 'styled-components';
import getTheme from '../../../style/themes/theme';
import { LandingCardTitle } from '../../Cards/DashboardCard/DashboardCard.css';

const theme: any = getTheme();

export const TemplateImageWrapper = styled.div<{ $background?: string }>`
    min-width: 48px;
    width: 48px;
    height: 48px;
    border-radius: 4px;
    background: ${(props) => (props.$background ? props.$background : theme.palette.primary.main)};
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 40px;
        height: 40px;
    }
`;

export const TemplateImage = styled.img`
    width: 40px;
    height: 40px;
`;

export const TemplateName = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${theme.palette.primary.main};
`;

export const TemplatesWrapper = styled.div`
    ${LandingCardTitle} {
        height: unset;
    }
`;
