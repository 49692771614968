import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { ItemState, fetchItem } from '../../../redux/slices/itemSlice';
import icons from '../../../style';
import { Item } from '../../../types/Item';
import Button from '../../Buttons/Button/Button';
import ActionDialog from '../../Items/Dialogs/ActionDialog';
import { EditorialItemCustomizeProps } from './EditorialItemCustomization';
import { ButtonsContainer, ButtonWrapper, EditorialInfo, VisualContainer } from './EditorialItemCustomization.css';
import { Loader } from '../../common/Loader/Loader';
import TranslationTooltip from '../../common/TranslationTooltip/TranslationTooltip';
import { CloseButtonWrapper } from '../../Modules/VisualEditor.css';
import SVGInline from 'react-inlinesvg';
import { actionNameMissingAlert } from '../../../utils/fnDialogs';

export const ButtonsCustomize: FC<EditorialItemCustomizeProps> = ({ open, template, onClose, onSave }) => {
    const [action, setAction] = useState<any>('');
    const [actionName, setActionName] = useState<string>('');
    const [isOpenActionDialog, setIsOpenActionDialog] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { selectedItemLoading: loading, error, selectedItem, itemPreview }: ItemState = useAppSelector((state) => state.items);

    const dispatch = useDispatch();

    const loadItem = async (id: string) => {
        await dispatch(fetchItem(id)).unwrap();
    };

    const saveItem = () => {
        if (!selectedItem) return;

        const newItem: Item = {
            ...selectedItem,
            action,
            actionName
        };
        if (!newItem.actionName) {
            actionNameMissingAlert();
        }
        onSave && onSave(newItem);
        handleCloseClick();
    };

    const handleCloseClick = () => {
        onClose && onClose();
    };

    useEffect(() => {
        if (!itemPreview?.itemId) return;

        if (open) {
            loadItem(itemPreview.itemId);
        }
        setIsOpen(open);
    }, [open, itemPreview?.itemId]);

    useEffect(() => {
        if (loading || error || !selectedItem) return;

        setAction(selectedItem?.action || '');
        setActionName(selectedItem?.actionName || '');
    }, [loading]);

    const buttonLabel = (
        <ButtonWrapper>
            {_.truncate(actionName, { length: 20, omission: '...' }) || '[NO ACTION NAME]'}
            {<TranslationTooltip translationKey={actionName} />}
        </ButtonWrapper>
    );

    if (!isOpen) return null;

    return (
        <VisualContainer>
            <CloseButtonWrapper onClick={() => handleCloseClick()}>
                <SVGInline src={icons.closeIcon} />
            </CloseButtonWrapper>
            {loading ? (
                <Loader lightTitle />
            ) : (
                <EditorialInfo template={template}>
                    <ButtonsContainer>
                        <Button
                            type={'WHITE'}
                            label={action ? buttonLabel : 'Set Action'}
                            onClick={() => {
                                setIsOpenActionDialog(true);
                            }}
                        />
                        <Button
                            type={'BLUE'}
                            label={'Save'}
                            onClick={() => {
                                saveItem();
                            }}
                        />
                    </ButtonsContainer>
                </EditorialInfo>
            )}

            <ActionDialog
                open={isOpenActionDialog}
                action={action}
                name={actionName}
                onSave={(actionName, action) => {
                    setAction(action);
                    setActionName(actionName);
                    setIsOpenActionDialog(false);
                }}
                onClose={() => setIsOpenActionDialog(false)}
                inGalleryOrButton
            />
        </VisualContainer>
    );
};
