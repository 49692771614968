import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../hooks/redux';
import { Audience, AudienceFilterHeaders, audienceFilterKeys } from '../../../../../types/Audience';
import configServiceAPI from '../../../../../utils/api/configServiceAPI';
import { clientTokenGenerator } from '../../../../../utils/fnData';
import { DIALOG_NAMES, ToastAlert } from '../../../../../utils/fnDialogs';
import GenericDialog, { DialogDropdownSingle, DialogTypes } from '../../../../common/Dialog/GenericDialog';
import { renderTooltipWithKey } from '../../../../common/Tooltips/Tooltips';
import {
    CurlHeader,
    CurlURL,
    Section,
    SectionBody,
    SectionLink,
    SectionTitle,
    TargetingSubtitle,
    TargetingVersionSelectWrapper
} from './TargetingGuide.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../../../assets/images/icons';

type TargetingGuideProps = {
    open: boolean;
    audience?: Audience;
    onClose?: () => void;
};

const availableAPIVersions = ['v3', 'v4'];

const TOKEN_MESSAGE = 'Please generate a project token';
const TARGETING_SUBTITLE = 'The parameters needed to be sent in order to retrieve a group with this audience assigned are:';

const TOKEN_PLACEHOLDER = ':token_placeholder';
const HEADER_PLACEHOLDER = '<header_placeholder>';
const TOKEN_PLACEHOLDER_MESSAGE = 'The token depends on the following headers, please generate it with your specific values';

export const TargetingGuide: FC<TargetingGuideProps> = ({ open, audience, onClose }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [apiKey, setApiKey] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const [headers, setHeaders] = useState<{ [key: string]: any }>({});
    const [versionOptions, setVersionOptions] = useState<any[]>([]);
    const [selectedVersion, setSelectedVersion] = useState<string>(availableAPIVersions[1]);
    const [canShowToken, setCanShowToken] = useState<boolean>(true);

    const [apiKeyError, setApiKeyError] = useState<boolean>(false);
    const { activeProjectId } = useAppSelector((state) => state.activeItem);
    const { config } = useAppSelector((state) => state.config);

    const baseUrl = config.CDN_BACKEND_URL || config.BACKEND_URL || `${window.location.protocol}//${window.location.host}`;
    const groupUrl = baseUrl + (config.CDN_BACKEND_URL ? '/:v/group' : '/api/:v/group');
    const docsUrl = baseUrl + (config.CDN_BACKEND_URL ? '/:v/docs' : '/api/:v/docs');

    const loadProjectToken = async () => {
        const { response, error } = await configServiceAPI.getToken(activeProjectId);

        setApiKeyError(!!error);

        if (response) {
            const { token } = response as any;
            setApiKey(token);
        }
    };

    useEffect(() => {
        setVersionOptions(
            availableAPIVersions.map((version) => {
                return {
                    value: version,
                    label: `${version} api version`
                };
            })
        );
    }, []);

    const handleCloseClick = () => {
        setApiKey('');
        setToken('');
        setHeaders({});
        setSelectedVersion(availableAPIVersions[1]);
        setCanShowToken(true);
        onClose?.();
    };

    const handleCopyClick = (url: string) => {
        navigator.clipboard.writeText(url);
        ToastAlert('success', '', '', undefined, DIALOG_NAMES.COPY_CURL);
    };

    useEffect(() => {
        if (!audience) return;
        const newHeaders: any = {};
        (Object.keys(audience) as Array<keyof typeof audience>).forEach((key) => {
            if (audienceFilterKeys.includes(key)) {
                if (key === 'country') {
                    canShowToken && setCanShowToken(false);
                    newHeaders[AudienceFilterHeaders[key]] = HEADER_PLACEHOLDER;
                    return;
                }
                if (typeof audience[key] !== 'object') {
                    return (newHeaders[AudienceFilterHeaders[key]] = Array.isArray(audience[key])
                        ? (audience[key] as string[])[0]
                        : audience[key]);
                }
                const audienceValue = audience[key] as any;

                if (['type', 'firstVersion', 'secondVersion'].some((key) => Object.keys(audienceValue).includes(key))) {
                    canShowToken && setCanShowToken(false);
                    newHeaders[AudienceFilterHeaders[key]] = HEADER_PLACEHOLDER;
                }

                if (Array.isArray(audienceValue)) {
                    newHeaders[AudienceFilterHeaders[key]] = audienceValue[0];
                }
            }
        });
        setHeaders(newHeaders);
    }, [audience]);

    useEffect(() => {
        if (_.isEmpty(headers)) return;

        setToken(!canShowToken ? TOKEN_PLACEHOLDER : clientTokenGenerator(apiKey, headers, AudienceFilterHeaders));
    }, [headers, apiKey]);

    useEffect(() => {
        if (!activeProjectId) return;
        if (open) {
            loadProjectToken().then(() => {
                setIsOpen(true);
            });
        } else {
            setIsOpen(false);
        }
    }, [open, activeProjectId]);

    const renderCurlCommand = (url: string) => {
        const curlHeaders = Object.keys(headers).map((key) => `-H "${key}":"${headers[key]}"`);
        const curlURL = `curl ${curlHeaders.join(' ')} "${url}"`;

        if (apiKeyError) return <></>;

        return (
            <Section>
                <SectionTitle>
                    cURL Command
                    {renderTooltipWithKey(
                        <SVGInline
                            src={icons.paperclipIcon}
                            onClick={() => {
                                handleCopyClick(curlURL);
                            }}
                        />,
                        'target_groups_targeting_guide_icon_value_copy'
                    )}
                </SectionTitle>
                <SectionBody>
                    <CurlURL>curl {url}</CurlURL>
                    {curlHeaders.map((header, index) => {
                        return <CurlHeader key={index}>{header}</CurlHeader>;
                    })}
                </SectionBody>
            </Section>
        );
    };

    const renderFinalUrl = (url: string) => {
        if (apiKeyError) return <></>;
        return (
            <Section>
                <SectionTitle> Final URL</SectionTitle>
                <SectionBody>{url}</SectionBody>
            </Section>
        );
    };

    const renderDocsUrl = (url: string) => {
        return (
            <Section>
                <SectionTitle>Documentation </SectionTitle>
                <SectionBody>
                    <SectionLink href={url} target="_blank">
                        {url}
                    </SectionLink>
                </SectionBody>
            </Section>
        );
    };

    const renderAudienceItems = () => {
        const url = `${groupUrl.replace(':v', selectedVersion)}/${token}?apiKey=${apiKey}`;
        const documentationUrl = `${docsUrl.replace(':v', selectedVersion)}`;
        const headerKeysThatPreventTokenGeneration = Object.entries(headers)
            .map(([key, value]) => (value === HEADER_PLACEHOLDER ? key : null))
            .filter((key) => key);
        return (
            <>
                <Section>
                    <SectionTitle>API Key</SectionTitle>
                    <SectionBody error={apiKeyError}>{apiKeyError ? TOKEN_MESSAGE : apiKey}</SectionBody>
                </Section>
                <Section>
                    <SectionTitle>Token</SectionTitle>
                    <SectionBody error={!canShowToken}>
                        {!canShowToken ? (
                            <>
                                <div>{TOKEN_PLACEHOLDER_MESSAGE}:</div>
                                {headerKeysThatPreventTokenGeneration.map((key) => (
                                    <div key={key}>- {key}</div>
                                ))}
                            </>
                        ) : (
                            token
                        )}
                    </SectionBody>
                </Section>
                <Section>
                    <SectionTitle>Headers</SectionTitle>
                    <SectionBody>
                        {(Object.keys(headers) as Array<keyof typeof headers>).map((key, index) => {
                            return <div key={index}> {`${key}: ${headers[key]}`}</div>;
                        })}
                    </SectionBody>
                </Section>
                {renderFinalUrl(url)}
                {renderCurlCommand(url)}
                {renderDocsUrl(documentationUrl)}
            </>
        );
    };

    if (!isOpen || !audience) return null;

    return (
        <GenericDialog onClose={handleCloseClick} type={DialogTypes.Form} title={'Targeting Guide'} dataCy={'targeting-guide-dialog'}>
            <TargetingVersionSelectWrapper>
                <DialogDropdownSingle
                    options={versionOptions}
                    value={versionOptions.find((version) => version.value === selectedVersion)}
                    onChange={(value: any) => {
                        setSelectedVersion(value.value);
                    }}
                    placeholder={'Select API Version'}
                />
            </TargetingVersionSelectWrapper>
            <TargetingSubtitle>{TARGETING_SUBTITLE}</TargetingSubtitle>
            {token && renderAudienceItems()}
        </GenericDialog>
    );
};
