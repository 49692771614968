import styled from 'styled-components';
import { UMTypes } from '../../components/common/Dialog/GenericDialog';
import getTheme, { zIndexesCSS } from '../../style/themes/theme';

const theme: any = getTheme();

export const DragAndDropWrapper = styled.div<{
    $error?: boolean;
    imageInfo?: { previewImage: boolean; width: number; height: number; um: UMTypes };
}>`
    box-sizing: border-box;
    border-radius: 4px;
    width: ${({ imageInfo }) => (imageInfo?.previewImage ? `${imageInfo.width}${imageInfo.um}` : '100%')};
    height: ${({ imageInfo }) => (imageInfo?.previewImage ? `${imageInfo.height}${imageInfo.um}` : '100px')};
    padding: 0 12px;
    border: dashed ${(props) => (props.$error ? theme.palette.error.main : theme.palette.background.pressed)} 1px;
    background-color: ${theme.palette.background.main};
    z-index: ${zIndexesCSS.zIndexDragAndDrop};
    display: flex;
    align-items: center;
    justify-content: center;
`;
