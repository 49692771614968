import { Project } from './Project';
import { Tenant } from './Tenant';
import { PUBLISHED_STATUS } from '../utils/fnPublish';
import { PlacedObjectType } from './Object';
import { PageIntents } from './Page';

export type Setting = {
    _id: string;
    name: string;
    tenantId: string;
    projectId: string;
    tenant?: Tenant;
    project?: Project;
    conditionIds: string[];
    conditions?: any;
    avatars?: any;
    pin?: PinSettings;
    forcedUpgrade?: ForcedUpgradeSettings;
    miscellaneous?: MiscellaneousSettings;
    login?: LoginSettings;
    playback?: PlaybackSettings;
    customer?: CustomerSettings;
    locked?: string;
    lockedByUser?: {
        name: string;
        icon?: string;
    };
    placed?: { name: string; _id: string; type: PlacedObjectType }[];

    published?: boolean;
    publishAt?: number;
    publishStatus?: PUBLISHED_STATUS;
    abTestingGroupIds?: string[];
};
export type PinSettings = {
    allowedWrongAttempts?: number;
    wrongAttemptsLockDuration?: number;
};

export type ForcedUpgradeSettings = {
    forcedUpgrade?: boolean;
    minimumVersion?: string;
};

export type MiscellaneousSettings = {
    appStartup?: string;
    channelGenresList?: string[];
    vtvgwUrl?: string;
    epgOverCdn?: string;
    brandId?: string;
    deviceProfileMechanism?: string[];
    analyticsEndpointUrl?: string;
    epgPastVisibility?: number;
    epgFutureVisibility?: number;
    kalturaChannelFilterCollectionId?: string;
    regionalization?: string;
};

export type LoginSettings = {
    silentLogin?: boolean;
    qrLogin?: boolean;
    credentialLogin?: boolean;
    credentialLoginForgotPasswordUrl?: string;
    qrLoginCodeUrl?: string;
};

export type CustomerSettings = {
    signupUrl?: string;
    errorCodeUrl?: string;
    thirdPartyActivationUrl?: string;
};

export type PlaybackSettings = {
    defaultVodSubtitleHandling?: boolean;
};

export enum SettingsUMTypes {
    PERCENTAGE = 'percentage',
    SECONDS = 'seconds',
    DAYS = 'days',
    BOOLEAN = 'boolean',
    FREE_INPUT = 'free input',
    INTEGER = 'integer',
    SINGLE_SELECT = 'single select',
    MULTIPLE_SELECT = 'multiple select'
}

export const SUBSECTION_NAMES: any = {
    pin: 'Pin Settings',
    forcedUpgrade: 'App Upgrade',
    miscellaneous: 'Miscellaneous',
    login: 'Login Options',
    customer: 'Customer',
    playback: 'Playback Settings',
    avatars: 'Avatars'
};

export const settingKeysWithTranslations: string[] = [];

export const specificDefaultSettingValues: Record<string, any> = {
    regionalization: false
};
export const DefaultSettingValues: Record<SettingsUMTypes, any> = {
    [SettingsUMTypes.PERCENTAGE]: 0,
    [SettingsUMTypes.SECONDS]: 0,
    [SettingsUMTypes.INTEGER]: 0,
    [SettingsUMTypes.DAYS]: 0,
    [SettingsUMTypes.FREE_INPUT]: '',
    [SettingsUMTypes.BOOLEAN]: true,
    [SettingsUMTypes.SINGLE_SELECT]: '',
    [SettingsUMTypes.MULTIPLE_SELECT]: []
};

export const settingsSections = {
    PIN: 'pin',
    PLAYBACK: 'playback',
    FORCED_UPGRADE: 'forcedUpgrade',
    MISCELLANEOUS: 'miscellaneous',
    LOGIN: 'login',
    CUSTOMER: 'customer',
    AVATARS: 'avatars'
} as const;

export type SettingSectionType = typeof settingsSections[keyof typeof settingsSections];

export type SettingsValues = Partial<Record<SettingSectionType, { [key: string]: SettingsUMTypes }>>;

export const loginSubsectionKeys = ['silentLogin', 'qrLogin', 'credentialLogin'];

export const groupedLoginSubsections: Record<string, string[]> = {
    silentLogin: ['silentLogin'],
    qrLogin: ['qrLogin', 'qrLoginCodeUrl'],
    credentialLogin: ['credentialLogin', 'credentialLoginForgotPasswordUrl']
};

export const SETTINGS_TOOLTIPS: Record<string, string> = {
    qrLogin: 'settings_qr_login',
    credentialLogin: 'settings_credential_login',
    silentLogin: 'settings_silent_login',
    qrLoginCodeUrl: 'settings_qr_login_url',
    credentialLoginForgotPasswordUrl: 'settings_forgot_password_url',
    appStartup: 'settings_app_startup',
    forcedUpgrade: 'settings_forced_upgrade',
    minimumVersion: 'settings_minimum_version',
    channelGenresList: 'settings_channel_genres_list',
    analyticsEndpointUrl: 'settings_analytics_endpoint_url',
    epgPastVisibility: 'settings_epg_past_visibility',
    epgFutureVisibility: 'settings_epg_future_visibility',
    regionalization: 'settings_regionalization'
};

export const settingsDropdownOptions: Record<string, { value: string; label: string }[]> = {
    appStartup: [
        { value: PageIntents.HOME, label: 'Homescreen' },
        { value: PageIntents.TV_PLAYER, label: 'TV Player' }
    ]
};
