import GenericDialog, { DialogTypes } from '../components/common/Dialog/GenericDialog';
import { createRoot, Root } from 'react-dom/client';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import getTheme from '../style/themes/theme';
import { Audience } from '../types/Audience';
import { DisplayCondition } from '../types/DisplayCondition';
import { DropdownOptionWrapper, ObjectListItem, ObjectListWrapper, TruncatedText } from '../style/styled-components/reusable.css';
import { EMPTY_WORD_STRING } from './Globals';
import icons, { iconType } from '../assets/images/icons';
import { ContentActionTypes } from '../types/Item';
import ObjectNameTooltip from '../components/common/Tooltips/ObjectNameTooltip/ObjectNameTooltip';
import { ObjectTypes } from '../types/Object';
import { Page, PageIntents } from '../types/Page';
import { Menu } from '../types/Menu';
import { Setting } from '../types/Setting';
import React from 'react';
import _ from 'lodash';
import Toast, { toastTypes } from '../components/common/Toast/Toast';
import { RemovableObjects, RemoveObjectDialog } from '../components/common/Dialog/RemoveObjectDialog';
import { LabelsWrapper, MenuItemsOptionsWrapper } from '../components/Menus/Menus.css';
import TranslationTooltip from '../components/common/TranslationTooltip/TranslationTooltip';
import { renderPublishStatusIcon } from './fnPublish';
import Labels from '../components/common/Labels/Labels';
import { MoreInfoTypes } from '../components/common/Dialog/MoreInfoDialog';
import { buildPathWithProjectId, PageRoutes } from '../types/RouteTypes';
import { AbTestingGroup } from '../types/AbTestingGroup';
import { TargetGroupAbTestingStatus } from '../types/TargetGroup';
import { FeatureFlag } from '../types/FeatureFlags';
import { ValueToDisplayIconsPlaceholder, ValueToDisplayWrapper } from '../components/PageEdit/Dialogs/UseExistingDialog.css';

let toastsRoot: null | Root = null;

function renderDialog(dialog: JSX.Element) {
    const container = document.getElementById('dialogs-container');
    createRoot(container!).render(
        <StylesProvider injectFirst>
            <ThemeProvider theme={getTheme()}>{dialog}</ThemeProvider>
        </StylesProvider>
    );
}

function renderToast(toast: JSX.Element) {
    const container = document.getElementById('toasts-container');
    if (toastsRoot) {
        toastsRoot.unmount();
    }
    toastsRoot = createRoot(container!);
    toastsRoot.render(
        <StylesProvider injectFirst>
            <ThemeProvider theme={getTheme()}>{toast}</ThemeProvider>
        </StylesProvider>
    );
}

export function getHelperText(error: any, optional = false) {
    const helperTextOutput = typeof error === 'string' ? error : optional ? <span> Optional</span> : '';
    return helperTextOutput;
}

export function dialogFunctionalityDisabled() {
    const values = {
        title: 'Functionality temporary disabled!',
        text: 'This functionality is currently disabled. We will re-enable it soon.'
    };
    dialogAlert('', false, values, null, true);
}

export function actionNameMissingAlert(module?: boolean, returnToast = true) {
    let text =
        'The editorial item must have an actionName set when it is added to a Gallery or Button. A fallback value exists on the client applications, but please modify the actionName of the item if you wish to modify it.';
    if (module) {
        text =
            'There are editorial items without an actionName referenced within this module. The editorial item must have an actionName set when it is part of a Gallery or Button. A fallback value exists on the client applications, but please modify the actionName of editorial items if you wish to modify it.';
    }
    const values = {
        title: 'Warning! ActionName is missing!',
        text
    };
    if (!returnToast) {
        return dialogAlert('', false, values, undefined, undefined, icons.warningIcon);
    }
    ToastAlert('critical_warning', values.title, values.text, undefined, undefined, () => {
        dialogAlert('', false, values, undefined, undefined, icons.warningIcon);
    });
}

export function dialogAlert(
    dialogName: string,
    successIcon = false,
    customValues: any = null,
    callback: any = null,
    failIcon = false,
    customIcon?: iconType,
    onCloseCallback?: any,
    lockedAlert?: boolean
) {
    const { title, text, subtitle } = customValues || DIALOG_TITLES_MAP.get(dialogName) || {};
    const dialog = (
        <GenericDialog
            type={lockedAlert ? DialogTypes.LockedAlert : DialogTypes.Alert}
            text={text}
            title={`${title}`}
            subtitle={subtitle}
            successIcon={successIcon}
            failIcon={failIcon}
            onOk={callback}
            customIcon={customIcon}
            onClose={onCloseCallback}
            dataCy={`alert-dialog-${title}`}
        />
    );
    renderDialog(dialog);
}

export function dialogConfirm(
    dialogName: string,
    confirmCallback: any,
    customValues: any = null,
    children: React.ReactNode = null,
    buttonsInfo: {
        noButton: {
            label: string;
            type?: 'BLUE' | 'RED' | 'PINK' | 'DEFAULT';
        };
        confirmButton: {
            label: string;
            type?: 'BLUE' | 'RED' | 'PINK' | 'DEFAULT';
        };
    } = {
        noButton: {
            label: 'NO'
        },
        confirmButton: {
            label: 'YES'
        }
    },
    icons?: {
        successIcon?: boolean;
        failIcon?: boolean;
        warningIcon?: boolean;
        customIcon?: iconType;
    },
    noCallback?: any,
    reversed?: boolean,
    closeCallback?: any
) {
    const { title, text, subtitle } = customValues || DIALOG_TITLES_MAP.get(dialogName) || {};
    const dialog = (
        <GenericDialog
            buttonsInfo={buttonsInfo}
            type={DialogTypes.Confirm}
            text={text}
            title={`${title}`}
            subtitle={subtitle}
            onConfirm={confirmCallback}
            successIcon={icons?.successIcon}
            failIcon={icons?.failIcon}
            warningIcon={icons?.warningIcon}
            onClose={noCallback}
            confirmReversed={reversed}
            onConfirmClose={closeCallback}
            customIcon={icons?.customIcon}
            dataCy={`confirm-dialog-${title}`}
        >
            {children}
        </GenericDialog>
    );
    renderDialog(dialog);
}

export function dialogRemove(
    objectType: RemovableObjects,
    names: { object?: string; parent?: string },

    onDelete: (arg?: boolean) => void,
    onUnlink?: () => void,
    label?: string,
    isList?: boolean
) {
    const dialog = (
        <RemoveObjectDialog
            objectType={objectType}
            objectName={names?.object || EMPTY_WORD_STRING}
            parentName={names?.parent || EMPTY_WORD_STRING}
            onDelete={onDelete}
            onUnlink={onUnlink}
            withUnlink={!!onUnlink}
            label={label}
            isList={isList}
        />
    );

    renderDialog(dialog);
}

export function ToastAlert(
    type: toastTypes,
    title: string,
    message: string | JSX.Element,
    customIcon?: string,
    dialogName?: string,
    dialogRenderFunction?: () => void,
    onClose?: () => void
) {
    if (dialogName) {
        const dialogValues = DIALOG_TITLES_MAP.get(dialogName);
        if (dialogValues) {
            title = dialogValues.title;
            message = dialogValues.text || message;
        }
    }
    const toast = (
        <Toast
            title={title}
            type={type}
            message={message}
            customIcon={customIcon}
            dialogRenderFunction={dialogRenderFunction}
            onClose={onClose}
        />
    );
    renderToast(toast);
}

export const DIALOG_NAMES = {
    UNAUTHORIZED: 'UNAUTHORIZED',
    RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
    PERMISSIONS_NOT_LOADED: 'PERMISSIONS_NOT_LOADED',
    UNSAVED_CHANGES: 'UNSAVED_CHANGES',
    UNSAVED_GROUP_OBJECTS: 'UNSAVED_GROUP_OBJECTS',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',
    ACTIVATE_USER_PASSWORD: 'ACTIVATE_USER_PASSWORD',
    ACTIVATE_USER_PHONE: 'ACTIVATE_USER_PHONE',
    ACTIVATE_USER_PHONE_NOT_SET: 'ACTIVATE_USER_PHONE_NOT_SET',
    PASSWORD_EMAIL_SUCCESS: 'PASSWORD_EMAIL_SUCCESS',
    PASSWORD_EMAIL_ERROR: 'PASSWORD_EMAIL_ERROR',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
    ACTIVATE_USER_ERROR: 'ACTIVATE_USER_ERROR',
    ACTIVATE_USER_SUCCESS: 'ACTIVATE_USER_SUCCESS',
    ACTIVATE_USER_EMAIL: 'ACTIVATE_USER_EMAIL',
    GENERIC_DELETE_CONFIRM: 'GENERIC_DELETE_CONFIRM',
    TEST_CONFIRM: 'TEST_CONFIRM',
    ADD_CONFIGURATION: 'ADD_CONFIGURATION',
    EDIT_CONFIGURATION: 'EDIT_CONFIGURATION',
    TEST_ALERT: 'TEST_ALERT',
    ADD_BRANDING: 'ADD_BRANDING',
    INVITE_USER: 'INVITE_USER',
    LANGUAGE_CREATED: 'LANGUAGE_CREATED',
    PROJECT_CREATED: 'PROJECT_CREATED',
    PROJECT_COPIED: 'PROJECT_COPIED',
    PROJECT_DUPLICATED: 'PROJECT_DUPLICATED',
    PAGE_CREATED: 'PAGE_CREATED',
    PAGE_UPDATED: 'PAGE_UPDATED',
    PAGE_PUBLISHED: 'PAGE_PUBLISHED',
    PAGE_PUBLISHED_AND_SAVED: 'PAGE_PUBLISHED_AND_SAVED',
    PAGE_SCHEDULED_PUBLISH_AND_SAVED: 'PAGE_SCHEDULED_PUBLISH_AND_SAVED',
    PAGE_ABORT_PUBLISH: 'PAGE_ABORT_PUBLISH',
    PAGE_EXISTING_SLUG: 'PAGE_EXISTING_SLUG',
    PAGE_EMPTY_SLUG: 'PAGE_EMPTY_SLUG',
    PAGE_SCHEDULED_PUBLISH: 'PAGE_SCHEDULED_PUBLISH',
    MODULE_EXISTING_SLUG: 'MODULE_EXISTING_SLUG',
    MODULE_EMPTY_SLUG: 'MODULE_EMPTY_SLUG',
    SETTINGS_CREATED: 'SETTINGS_CREATED',
    SETTINGS_UPDATED: 'SETTINGS_UPDATED',
    SETTINGS_DELETED: 'SETTINGS_DELETED',
    SETTINGS_SCHEDULED_PUBLISH_AND_SAVED: 'SETTINGS_SCHEDULED_PUBLISH_AND_SAVED',
    MENU_UPDATED: 'MENU_UPDATED',
    MENU_CREATED: 'MENU_CREATED',
    MENU_PUBLISHED_AND_SAVED: 'MENU_PUBLISHED_AND_SAVED',
    MENU_PUBLISHED: 'MENU_PUBLISHED',
    MENU_DELETED: 'MENU_DELETED',
    MENU_ABORT_PUBLISH: 'MENU_ABORT_PUBLISH',
    MENU_SCHEDULED_PUBLISH: 'MENU_SCHEDULED_PUBLISH',
    MENU_SCHEDULED_PUBLISH_AND_SAVED: 'MENU_SCHEDULED_PUBLISH_AND_SAVED',
    SETTINGS_PUBLISHED: 'SETTINGS_PUBLISHED',
    SETTINGS_ABORT_PUBLISH: 'SETTINGS_ABORT_PUBLISH',
    SETTINGS_SCHEDULED_PUBLISH: 'SETTINGS_SCHEDULED_PUBLISH',
    SETTINGS_PUBLISHED_AND_SAVED: 'SETTINGS_PUBLISHED_AND_SAVED',
    FEATURE_FLAG_UPDATED: 'FEATURE_FLAG_UPDATED',
    FEATURE_FLAG_CREATED: 'FEATURE_FLAG_CREATED',
    FEATURE_FLAG_PUBLISHED_AND_SAVED: 'FEATURE_FLAG_PUBLISHED_AND_SAVED',
    FEATURE_FLAG_PUBLISHED: 'FEATURE_FLAG_PUBLISHED',
    FEATURE_FLAG_DELETED: 'FEATURE_FLAG_DELETED',
    FEATURE_FLAG_ABORT_PUBLISH: 'FEATURE_FLAG_ABORT_PUBLISH',
    FEATURE_FLAG_SCHEDULED_PUBLISH: 'FEATURE_FLAG_SCHEDULED_PUBLISH',
    FEATURE_FLAG_SCHEDULED_PUBLISH_AND_SAVED: 'FEATURE_FLAG_SCHEDULED_PUBLISH_AND_SAVED',
    APP_BRANDING_CREATED: 'APP_BRANDING_CREATED',
    APP_BRANDING_UPDATED: 'APP_BRANDING_UPDATED',
    APP_BRANDING_DELETED: 'APP_BRANDING_DELETED',
    GENERATE_TOKEN: 'GENERATE_TOKEN',
    COPY_CURL: 'COPY_CURL',
    COPY_URL: 'COPY URL',
    SIMILAR_AUDIENCE: 'SIMILAR_AUDIENCE',
    DISPLAY_CONDITIONS_CREATED: 'DISPLAY_CONDITIONS_CREATED',
    DISPLAY_CONDITIONS_UPDATED: 'DISPLAY_CONDITIONS_UPDATED',
    FILE_MANAGER_FILE_CREATED: 'FILE_CREATED',
    FILE_MANAGER_FOLDER_CREATED: 'FOLDER_CREATED',
    FILE_MANAGER_ENCODING_STARTED: 'FILE_MANAGER_ENCODING_STARTED',
    FILE_MANAGER_ENCODING_IN_PROGRESS: 'FILE_MANAGER_ENCODING_IN_PROGRESS',
    FILE_MANAGER_DISABLED_ACTION_WHILE_ENCODING_IN_PROGRESS: 'FILE_MANAGER_DISABLED_ACTION_WHILE_ENCODING_IN_PROGRESS',
    INGISHTS_DATA_WARNING: 'INSIGHTS_DATA_WARNING',
    AUDIENCE_PURGE_SUCCESS: 'AUDIENCE_PURGE_SUCCESS',
    AUDIENCE_PURGE_ERROR: 'AUDIENCE_PURGE_ERROR',
    GROUP_OBJECT_REPUBLISH: 'GROUP_OBJECT_REPUBLISH',
    EXTRACT_VALUE_FROM_URL_SUCCESS: 'EXTRACT_VALUE_FROM_URL_SUCCESS',
    EXTRACT_VALUE_FROM_URL_FAILED: 'EXTRACT_VALUE_FROM_URL_FAILED',
    ALREADY_MODIFIED: 'ALREADY_MODIFIED',
    INVALID_TRANSLATIONS: 'INVALID_TRANSLATIONS',
    DELETE_LIST_CONFIRM: 'DELETE_LIST_CONFIRM',
    OBJECT_COPY: 'OBJECT_COPY',
    OBJECT_COPY_FAIL: 'OBJECT_COPY_FAIL',
    OBJECT_COPY_WARNING: 'OBJECT_COPY_WARNING',
    OBJECT_COPY_ITEM_ACTION_WARNING: 'OBJECT_COPY_ITEM_ACTION_WARNING',
    OBJECT_COPY_ITEM_ACTION_WARNING_WHILE_UNSAVED: 'OBJECT_COPY_ITEM_ACTION_WARNING_WHILE_UNSAVED',
    GALLERY_NOT_FIRST_MODULE: 'GALLERY_NOT_FIRST_MODULE',
    DYNAMIC_ITEM_AUTOCOLLECTION: 'DYNAMIC_ITEM_AUTOCOLLECTION',
    ITEM_PROVIDER_LOGO_CARD: 'ITEM_PROVIDER_LOGO_CARD',
    DELETE_APPLICATION: 'DELETE_APPLICATION'
};

export const DIALOG_TITLES_MAP = new Map<string, { title: string; text?: string; subtitle?: string }>([
    [
        DIALOG_NAMES.UNAUTHORIZED,
        {
            title: 'Unauthorized!',
            text: 'You are not authorized to create/edit/delete this resource! To request access, please reach out to your admin.'
        }
    ],
    [
        DIALOG_NAMES.ITEM_PROVIDER_LOGO_CARD,
        {
            title: 'Different Provider!',
            text: 'You have Included Provider Card Logo for this module. Adding an Item that has a different provider will remove the Provider Card Logo!'
        }
    ],
    [
        DIALOG_NAMES.DYNAMIC_ITEM_AUTOCOLLECTION,
        {
            title: 'Dynamic Item with Auto-Collection',
            text: 'You can have only one Dynamic Item with Auto-Collection. Adding or having additional items will remove the Auto-Collection!'
        }
    ],
    [
        DIALOG_NAMES.GALLERY_NOT_FIRST_MODULE,
        {
            title: 'Warning',
            text: 'Galleries should be placed only on the top of the page because client apps might not be able to display them elsewhere. Saving will not be aborted but please make sure that you check your module’s position.'
        }
    ],
    [
        DIALOG_NAMES.RESOURCE_NOT_FOUND,
        {
            title: 'Not Found!',
            text: 'A resource was not found for this id! Press ok or wait to be redirected back!'
        }
    ],
    [
        DIALOG_NAMES.PERMISSIONS_NOT_LOADED,
        {
            title: 'Permissions not loaded!',
            text: `We could not load the user's permissions! You will be redirected to the login screen!`
        }
    ],
    [
        DIALOG_NAMES.UNSAVED_CHANGES,
        {
            title: 'Unsaved Changes!',
            subtitle: 'Do you want to make changes to your document before closing?',
            text: 'If you do not save, your changes will be lost!'
        }
    ],
    [
        DIALOG_NAMES.UNSAVED_GROUP_OBJECTS,
        {
            title: 'Unsaved Changes!',
            subtitle: 'Do you want to save before navigating?',
            text: 'You have added new objects to your group that were not saved, and you are about to navigate away from groups. If you do not save, your changes will be lost!'
        }
    ],
    [
        DIALOG_NAMES.FORGOT_PASSWORD,
        {
            title: 'Forgot your password?',
            text: `To reset your password, please fill in your email address below, and we'll send you a link to reset your password.`
        }
    ],
    [
        DIALOG_NAMES.RESET_PASSWORD,
        {
            title: 'Reset Password',
            subtitle: 'Enter the password you would like to use for your account.',
            text: `Password must be at least 8 characters long and contain at least: one uppercase letter, one lowercase letter, one number, one special sign.`
        }
    ],
    [
        DIALOG_NAMES.ACTIVATE_USER_PASSWORD,
        {
            title: 'Activate User',
            subtitle: 'Enter the password you would like to use for your account in order to activate it.',
            text: `Password must be at least 8 characters long and contain at least: one uppercase letter, one lowercase letter, one number, one special sign.`
        }
    ],
    [
        DIALOG_NAMES.ACTIVATE_USER_PHONE,
        {
            title: 'Activate User',
            subtitle: 'Enter a phone number for two factor authentication.',
            text: `Phone numbers must be valid for the selected country.`
        }
    ],
    [
        DIALOG_NAMES.ACTIVATE_USER_PHONE_NOT_SET,
        {
            title: 'Setup two factor authentication',
            subtitle: 'Enter a phone number for two factor authentication',
            text: `Phone numbers must be valid for the selected country.`
        }
    ],
    [
        DIALOG_NAMES.PASSWORD_EMAIL_SUCCESS,
        {
            title: 'Password email sent!',
            text: 'A password modification email has been successfully sent to the entered email address!'
        }
    ],
    [
        DIALOG_NAMES.PASSWORD_EMAIL_ERROR,
        {
            title: 'Password email could not be sent!',
            text: 'An error occured while trying to send the email. Please try again!'
        }
    ],
    [
        DIALOG_NAMES.RESET_PASSWORD_ERROR,
        {
            title: 'Password could not be reset!',
            text: 'An error occured while trying to reset the password. Please try again!'
        }
    ],
    [
        DIALOG_NAMES.ACTIVATE_USER_ERROR,
        {
            title: 'User could not be activated!',
            text: 'An error occured while trying to activate the user. Please try again!'
        }
    ],
    [
        DIALOG_NAMES.RESET_PASSWORD_SUCCESS,
        {
            title: 'Password Changed!',
            subtitle: 'Your password has been changed successfully!',
            text: 'Press OK to login with your new password'
        }
    ],
    [
        DIALOG_NAMES.ACTIVATE_USER_SUCCESS,
        {
            title: 'User activated!',
            subtitle: 'Your user has been activated successfully. You may now use your password to login!',
            text: 'Press OK to login with your new credentials'
        }
    ],
    [
        DIALOG_NAMES.ACTIVATE_USER_EMAIL,
        {
            title: 'Activation Email sent!',
            subtitle: 'An activation email has been successfully sent to the current user email'
        }
    ],

    [
        DIALOG_NAMES.GENERIC_DELETE_CONFIRM,
        {
            title: 'Confirm Delete',
            text: 'Are you sure you want to delete this item?'
        }
    ],
    [
        DIALOG_NAMES.TEST_CONFIRM,
        {
            title: 'This is a test!',
            subtitle: 'Are you sure you want to do this?',
            text: `If you do this something nice will happen. Press 'YES' to do it, or 'NO' to cancel.`
        }
    ],
    [
        DIALOG_NAMES.GENERIC_DELETE_CONFIRM,
        {
            title: 'Confirm Delete',
            text: 'Are you sure you want to delete this item?'
        }
    ],
    [
        DIALOG_NAMES.ADD_CONFIGURATION,
        {
            title: 'Add configuration'
        }
    ],
    [
        DIALOG_NAMES.EDIT_CONFIGURATION,
        {
            title: 'Edit configuration'
        }
    ],
    [
        DIALOG_NAMES.TEST_ALERT,
        {
            title: 'HAHA!',
            subtitle: 'Got cha! Nothing actually happens!',
            text: `Better luck next time!`
        }
    ],
    [
        DIALOG_NAMES.ADD_BRANDING,
        {
            title: 'Your company branding',
            subtitle: 'Upload branded company images'
        }
    ],
    [
        DIALOG_NAMES.ADD_BRANDING,
        {
            title: 'Your company branding',
            subtitle: 'Upload branded company images'
        }
    ],
    [
        DIALOG_NAMES.INVITE_USER,
        {
            title: 'Success',
            text: 'Invitation sent!'
        }
    ],
    [
        DIALOG_NAMES.LANGUAGE_CREATED,
        {
            title: 'Success',
            text: 'Language added.'
        }
    ],
    [
        DIALOG_NAMES.PROJECT_CREATED,
        {
            title: 'Success',
            text: 'Project created.'
        }
    ],
    [
        DIALOG_NAMES.PROJECT_COPIED,
        {
            title: 'Success',
            text: 'Project copied.'
        }
    ],
    [
        DIALOG_NAMES.PROJECT_DUPLICATED,
        {
            title: 'Success',
            text: 'Project duplicated.'
        }
    ],
    [
        DIALOG_NAMES.PAGE_CREATED,
        {
            title: 'Success',
            text: 'Page created.'
        }
    ],
    [
        DIALOG_NAMES.PAGE_UPDATED,
        {
            title: 'Success',
            text: 'Page updated.'
        }
    ],
    [
        DIALOG_NAMES.PAGE_PUBLISHED,
        {
            title: 'Success',
            text: 'Page published.'
        }
    ],
    [
        DIALOG_NAMES.PAGE_PUBLISHED_AND_SAVED,
        {
            title: 'Success',
            text: 'Page saved and published.'
        }
    ],
    [
        DIALOG_NAMES.PAGE_ABORT_PUBLISH,
        {
            title: 'Success',
            text: 'The scheduled publishing was stopped!'
        }
    ],
    [
        DIALOG_NAMES.PAGE_EXISTING_SLUG,
        {
            title: 'Warning! The page’s slug must be unique!',
            text: 'The slug must be unique per project. The value set already existed, therefore a unique ID was appended to it.'
        }
    ],
    [
        DIALOG_NAMES.PAGE_EMPTY_SLUG,
        {
            title: 'Warning! No page slug provided',
            text: `There was no slug provided, because of this, a unique one was generated.`
        }
    ],
    [
        DIALOG_NAMES.MODULE_EMPTY_SLUG,
        {
            title: 'Warning! No module slug provided',
            text: `There was no slug provided and autoCollection was set to true. Because of this, a unique one was generated.`
        }
    ],
    [
        DIALOG_NAMES.MODULE_EXISTING_SLUG,
        {
            title: `Warning! The module's slug must be unique!`,
            text: 'The slug must be unique per project. The value set already existed, therefore a unique ID was appended to it.'
        }
    ],
    [
        DIALOG_NAMES.PAGE_SCHEDULED_PUBLISH,
        {
            title: 'Success',
            text: 'Page set for later publishing!'
        }
    ],
    [
        DIALOG_NAMES.PAGE_SCHEDULED_PUBLISH_AND_SAVED,
        {
            title: 'Success',
            text: 'Page saved and set for later publishing!'
        }
    ],
    [
        DIALOG_NAMES.MENU_PUBLISHED_AND_SAVED,
        {
            title: 'Success',
            text: 'Menu saved and published.'
        }
    ],
    [
        DIALOG_NAMES.MENU_CREATED,
        {
            title: 'Success',
            text: 'Menu created.'
        }
    ],
    [
        DIALOG_NAMES.MENU_UPDATED,
        {
            title: 'Success',
            text: 'Menu updated.'
        }
    ],
    [
        DIALOG_NAMES.MENU_PUBLISHED,
        {
            title: 'Success',
            text: 'Menu published.'
        }
    ],
    [
        DIALOG_NAMES.MENU_ABORT_PUBLISH,
        {
            title: 'Success',
            text: 'The scheduled publishing was stopped!'
        }
    ],
    [
        DIALOG_NAMES.MENU_SCHEDULED_PUBLISH_AND_SAVED,
        {
            title: 'Success',
            text: 'Menu saved and set for later publishing!'
        }
    ],
    [
        DIALOG_NAMES.MENU_SCHEDULED_PUBLISH,
        {
            title: 'Success',
            text: 'Menu set for later publishing!'
        }
    ],
    [
        DIALOG_NAMES.MENU_DELETED,
        {
            title: 'Success',
            text: 'Menu deleted.'
        }
    ],
    [
        DIALOG_NAMES.FEATURE_FLAG_PUBLISHED_AND_SAVED,
        {
            title: 'Success',
            text: 'Feature Flag saved and published.'
        }
    ],
    [
        DIALOG_NAMES.FEATURE_FLAG_CREATED,
        {
            title: 'Success',
            text: 'Feature Flag created.'
        }
    ],
    [
        DIALOG_NAMES.FEATURE_FLAG_UPDATED,
        {
            title: 'Success',
            text: 'Feature Flag updated.'
        }
    ],
    [
        DIALOG_NAMES.FEATURE_FLAG_PUBLISHED,
        {
            title: 'Success',
            text: 'Feature Flag published.'
        }
    ],
    [
        DIALOG_NAMES.FEATURE_FLAG_ABORT_PUBLISH,
        {
            title: 'Success',
            text: 'The scheduled publishing was stopped!'
        }
    ],
    [
        DIALOG_NAMES.FEATURE_FLAG_SCHEDULED_PUBLISH_AND_SAVED,
        {
            title: 'Success',
            text: 'Feature Flag saved and set for later publishing!'
        }
    ],
    [
        DIALOG_NAMES.FEATURE_FLAG_SCHEDULED_PUBLISH,
        {
            title: 'Success',
            text: 'Feature Flag set for later publishing!'
        }
    ],
    [
        DIALOG_NAMES.FEATURE_FLAG_DELETED,
        {
            title: 'Success',
            text: 'Feature Flag deleted.'
        }
    ],
    [
        DIALOG_NAMES.SETTINGS_CREATED,
        {
            title: 'Success',
            text: 'Settings created.'
        }
    ],
    [
        DIALOG_NAMES.SETTINGS_DELETED,
        {
            title: 'Success',
            text: 'Settings deleted.'
        }
    ],
    [
        DIALOG_NAMES.SETTINGS_UPDATED,
        {
            title: 'Success',
            text: 'Settings updated.'
        }
    ],
    [
        DIALOG_NAMES.SETTINGS_PUBLISHED,
        {
            title: 'Success',
            text: 'Settings published.'
        }
    ],
    [
        DIALOG_NAMES.SETTINGS_PUBLISHED_AND_SAVED,
        {
            title: 'Success',
            text: 'Settings saved and published.'
        }
    ],
    [
        DIALOG_NAMES.SETTINGS_ABORT_PUBLISH,
        {
            title: 'Success',
            text: 'The scheduled publishing was stopped!'
        }
    ],
    [
        DIALOG_NAMES.SETTINGS_SCHEDULED_PUBLISH_AND_SAVED,
        {
            title: 'Success',
            text: 'Settings saved and set for later publishing!'
        }
    ],
    [
        DIALOG_NAMES.APP_BRANDING_CREATED,
        {
            title: 'Success',
            text: 'App Branding successfully created.'
        }
    ],
    [
        DIALOG_NAMES.APP_BRANDING_DELETED,
        {
            title: 'Success',
            text: 'App Branding successfully deleted.'
        }
    ],
    [
        DIALOG_NAMES.APP_BRANDING_UPDATED,
        {
            title: 'Success',
            text: 'App Branding successfully updated.'
        }
    ],
    [
        DIALOG_NAMES.DISPLAY_CONDITIONS_CREATED,
        {
            title: 'Success',
            text: 'Display Conditions created.'
        }
    ],
    [
        DIALOG_NAMES.DISPLAY_CONDITIONS_UPDATED,
        {
            title: 'Success',
            text: 'Display Conditions updated.'
        }
    ],
    [
        DIALOG_NAMES.GENERATE_TOKEN,
        {
            title: 'Success',
            text: 'Token generated and copied to the clipboard.'
        }
    ],
    [
        DIALOG_NAMES.COPY_CURL,
        {
            title: 'Success',
            text: 'Copied Curl command to the clipboard.'
        }
    ],
    [
        DIALOG_NAMES.COPY_URL,
        {
            title: 'Success',
            text: 'Copied File URL to the clipboard.'
        }
    ],
    [
        DIALOG_NAMES.SIMILAR_AUDIENCE,
        {
            title: 'Similar audience error!',
            text: 'A similar audience already exists in the project.'
        }
    ],
    [
        DIALOG_NAMES.FILE_MANAGER_FILE_CREATED,
        {
            title: 'Success',
            text: 'File upload successfully started.'
        }
    ],
    [
        DIALOG_NAMES.FILE_MANAGER_FOLDER_CREATED,
        {
            title: 'Success',
            text: 'Folder created.'
        }
    ],
    [
        DIALOG_NAMES.FILE_MANAGER_ENCODING_STARTED,
        {
            title: 'Success',
            text: 'Encoding started! The file can already be used.'
        }
    ],
    [
        DIALOG_NAMES.FILE_MANAGER_ENCODING_IN_PROGRESS,
        {
            title: 'Video Encoding Pending',
            text: 'Video encoding is in progress, it may take up to several minutes, comeback later and refresh to check status!'
        }
    ],
    [
        DIALOG_NAMES.FILE_MANAGER_DISABLED_ACTION_WHILE_ENCODING_IN_PROGRESS,
        {
            title: 'Action disabled!',
            text: 'This action cannot be performed while the encoding is in progress. Try again after it is finished!'
        }
    ],
    [
        DIALOG_NAMES.INGISHTS_DATA_WARNING,
        {
            title: 'Relevant data could not be fetched',
            text: 'We could not fetch enough relevant data for comparison for the selected date.'
        }
    ],
    [
        DIALOG_NAMES.AUDIENCE_PURGE_ERROR,
        {
            title: 'Purge could not be initiated',
            text: 'Purge action could not be started. Please try again later.'
        }
    ],
    [
        DIALOG_NAMES.AUDIENCE_PURGE_SUCCESS,
        {
            title: 'Purge has been successfully initiated',
            text: 'Purge action has been started. It can take up to 10 minutes for the purge to be completed.'
        }
    ],
    [
        DIALOG_NAMES.GROUP_OBJECT_REPUBLISH,
        {
            title: 'Some objects have been republished',
            text: `You have added some published objects to this group. The objects were automatically republished so their published version also contain the group's audiences.`
        }
    ],
    [
        DIALOG_NAMES.EXTRACT_VALUE_FROM_URL_SUCCESS,
        {
            title: 'Success',
            text: `The desired value pasted!`
        }
    ],
    [
        DIALOG_NAMES.EXTRACT_VALUE_FROM_URL_FAILED,
        {
            title: 'Warning',
            text: `The value from the pasted URL was not extracted!`
        }
    ],
    [
        DIALOG_NAMES.ALREADY_MODIFIED,
        {
            title: 'The object was modified',
            text: `The Object you are trying to update was already modified, please refresh the page to get the latest state of your objects!`
        }
    ],
    [
        DIALOG_NAMES.INVALID_TRANSLATIONS,
        {
            title: 'Invalid Translations',
            text: 'The Translation are not valid. Please make sure each key is unique, and each value is a string'
        }
    ],
    [
        DIALOG_NAMES.DELETE_LIST_CONFIRM,
        {
            title: 'Confirm Delete Lists',
            text: 'Are you sure you want to delete the selected lists?'
        }
    ],
    [
        DIALOG_NAMES.OBJECT_COPY,
        {
            title: 'Success',
            text: 'The object was copied!'
        }
    ],
    [
        DIALOG_NAMES.OBJECT_COPY_FAIL,
        {
            title: 'Warning',
            text: 'The object could not be copied!'
        }
    ],
    [
        DIALOG_NAMES.OBJECT_COPY_WARNING,
        {
            title: 'Copy Object Warning',
            text: 'The object you want to copy contains Dynamic Item(s) which will not be copied'
        }
    ],
    [
        DIALOG_NAMES.OBJECT_COPY_ITEM_ACTION_WARNING,
        {
            title: 'Copy Object Item Action Warning',
            text: `There are one or more item(s) that have the following actions: ${ContentActionTypes.PAGELINK}, ${ContentActionTypes.PAGELINK_WITH_TEASER}.\n
            These actions will not be copied, because they referenced pages and/or modules. Make sure you update them manually!
            `
        }
    ],
    [
        DIALOG_NAMES.OBJECT_COPY_ITEM_ACTION_WARNING_WHILE_UNSAVED,
        {
            title: 'Unsaved Changes',
            text: 'You have Unsaved Changes! Copying is not allowed, please save your changes and proceed with the copying afterwards!'
        }
    ],
    [
        DIALOG_NAMES.DELETE_APPLICATION,
        {
            title: 'Delete Application',
            text: 'Are you sure you want to delete this application?'
        }
    ]
]);

export const CONDITIONS_GROUP_LABEL = 'Conditions';
export const AUDIENCES_GROUP_LABEL = 'Audiences not assigned to a Group ';
export const AB_TESTING_GROUP_LABEL = 'A/B Testing';

export const buildABTestingOptions = (
    ABTestingGroups: AbTestingGroup[],
    selectedTargetConditions?: string[],
    selectedGroups?: string[]
) => {
    const ABTestingGrouped = ABTestingGroups.reduce((grouped, curr) => {
        const key = curr?.targetGroup?.name || '';
        const targetGroupAbTesingGroups = ABTestingGroups.filter((elem) => elem.targetGroupId === curr?.targetGroupId);
        const selectedTargetGroupAbTesingGroups =
            selectedGroups?.filter((elem) => targetGroupAbTesingGroups.map((group) => group._id).includes(elem)) || [];

        const disabledForSelectedAllGroups =
            selectedTargetGroupAbTesingGroups.length &&
            targetGroupAbTesingGroups.length &&
            selectedTargetGroupAbTesingGroups.length === targetGroupAbTesingGroups.length - 1 &&
            !selectedGroups?.includes(curr?._id);

        const disabledForNotSelectedAudience = !selectedTargetConditions?.some((elem) =>
            (curr?.targetGroup?.audiences as string[])?.includes(elem)
        );

        const disabledForABTestingInactive = curr?.targetGroup?.abTestingStatus !== TargetGroupAbTestingStatus.ACTIVE;

        const isDisabled = disabledForSelectedAllGroups || disabledForNotSelectedAudience || disabledForABTestingInactive;

        const label = {
            label: <TruncatedText>{curr.name}</TruncatedText>,
            valueForSearch: `${curr.name}${key}`, // add the key so the search matches the groups too
            targetGroupId: curr.targetGroupId,
            isDisabled,
            ABTestingInactive: disabledForABTestingInactive,
            value: curr._id
        };
        if (grouped[key]) {
            grouped[key].push(label);
        } else {
            grouped[key] = [label];
        }
        return grouped;
    }, {} as { [key: string]: any[] });

    const options = Object.entries(ABTestingGrouped).map(([key, value]) => {
        return { label: key, options: value };
    });

    options.sort((a, b) => (a.options.length < b.options.length ? 1 : -1));
    return options;
};

export const buildTargetOptions = (audiences: Audience[], displayConditions: DisplayCondition[]) => {
    const audiencesGroupped = audiences.reduce((groupped, curr) => {
        const key = curr.targetGroup ? curr.targetGroup.name : AUDIENCES_GROUP_LABEL;
        const label = {
            label: <ObjectNameTooltip id={curr._id} name={curr.name} type={ObjectTypes.AUDIENCES} />,
            valueForSearch: `${curr.name}${key}`, // add the key so the search matches the groups too
            valueToDisplay: curr.name,
            value: curr._id
        };
        if (groupped[key]) {
            groupped[key].push(label);
        } else {
            groupped[key] = [label];
        }
        return groupped;
    }, {} as { [key: string]: any[] });
    const audiencesGroup = Object.entries(audiencesGroupped).map(([key, value]) => {
        return { label: key !== AUDIENCES_GROUP_LABEL ? `Group: ${key}` : key, options: value };
    });

    const displayConditionsGroup = {
        label: CONDITIONS_GROUP_LABEL,
        options: displayConditions.length
            ? displayConditions?.map((condition) => ({
                  label: <ObjectNameTooltip id={condition._id} name={condition.name} type={ObjectTypes.CONDITIONS} />,
                  valueForSearch: condition.name,
                  valueToDisplay: condition.name,
                  value: condition._id
              }))
            : [
                  {
                      valueToDisplay: 'No Conditions',
                      label: 'No Conditions',
                      value: `no-conditions`,
                      isDisabled: true
                  }
              ]
    };
    const options = [...audiencesGroup, displayConditionsGroup];
    const order: any = {
        [AUDIENCES_GROUP_LABEL]: 1,
        [CONDITIONS_GROUP_LABEL]: 2
    };
    options.sort((a, b) => {
        const orderA = order[a.label] || 0;
        const orderB = order[b.label] || 0;
        if (orderA !== orderB) {
            return orderA - orderB;
        }
        return a.options.length < b.options.length ? 1 : -1;
    });
    return options;
};

export const getOptionsIndexes = (options: { label: any; value: string | number }[], values: any[]) => {
    const indexes: number[] = [];

    options.forEach((option, index) => {
        if (values.includes(option.value)) {
            indexes.push(index);
        }
    });
    return indexes;
};

export const getPagesSelectOptions = (
    _pages: Page[],
    activeProjectId: string,
    navigate: any,
    translate?: (arg: string) => string,
    isForMenus?: boolean
) => {
    const pages = isForMenus ? _pages.filter((page) => page.intent !== PageIntents.SEARCH_NO_RESULTS) : _pages;
    const pageNames: { [key: string]: number } = {};

    for (let i = 0; i < pages.length; i++) {
        if (pageNames[pages[i].name]) {
            pageNames[pages[i].name]++;
        } else {
            pageNames[pages[i].name] = 1;
        }
    }

    return pages.map((page) => {
        return {
            valueForSearch: translate?.(page.name) || page.name || EMPTY_WORD_STRING,
            valueToDisplay: (
                <ValueToDisplayWrapper>
                    {translate?.(page.name) || page.name || EMPTY_WORD_STRING}
                    <ValueToDisplayIconsPlaceholder />
                </ValueToDisplayWrapper>
            ),
            value: page._id,
            label: (
                <MenuItemsOptionsWrapper>
                    <DropdownOptionWrapper>
                        <TruncatedText>
                            <ObjectNameTooltip
                                id={page._id}
                                name={translate?.(page.name) || page.name || EMPTY_WORD_STRING}
                                type={ObjectTypes.PAGES}
                            />
                        </TruncatedText>
                        <div>
                            <TranslationTooltip translationKey={page.name} />
                        </div>
                        <div>{renderPublishStatusIcon(page.publishStatus, page.publishAt)}</div>
                    </DropdownOptionWrapper>

                    {pageNames[page.name] > 1 && (
                        <LabelsWrapper>
                            <Labels
                                values={page?.conditions || []}
                                type={MoreInfoTypes.PLACED}
                                noOfLabels={1}
                                onClickLabel={(obj) => {
                                    const isAudience = obj?.objectType === ObjectTypes.AUDIENCES;

                                    navigate(
                                        buildPathWithProjectId(
                                            activeProjectId,
                                            isAudience ? PageRoutes.AUDIENCES : PageRoutes.TARGET_CONDITIONS
                                        ),
                                        {
                                            state: isAudience ? { audienceId: obj._id } : { conditionId: obj._id }
                                        }
                                    );
                                }}
                            />
                        </LabelsWrapper>
                    )}
                </MenuItemsOptionsWrapper>
            )
        };
    });
};

export const handleCheckBeforeSave = (
    storeAudiences: Audience[],
    targetGroups: any[],
    oldObject: Page | Partial<Menu> | Setting | FeatureFlag | null,
    newObject: Page | Partial<Menu> | Setting | FeatureFlag,
    objectType: 'pages' | 'menus' | 'settings' | 'featureFlags',
    onSave: (addObjectToGroup?: boolean) => void,
    translate?: (arg: string) => string
) => {
    const conditionIds = newObject.conditionIds || [];

    const newAudiences = storeAudiences.filter(
        (audience) => conditionIds.includes(audience._id) && audience.targetGroupId && !oldObject?.conditionIds?.includes(audience._id)
    );

    const newTargetGroups = targetGroups.filter((tg) => {
        const audience = newAudiences.find((aud) => aud.targetGroupId === tg._id);

        const objects = [...(tg[objectType] || [])];
        const groupObjectIds = objects.map((object: any) => (typeof object === 'string' ? object : object._id));
        const isObjectAlreadyInGroup = groupObjectIds?.includes(oldObject?._id || '');

        return audience && !isObjectAlreadyInGroup;
    });

    if (!newTargetGroups.length) return onSave();

    const objectName = !['settings', 'featureFlags'].includes(objectType) ? translate?.(newObject?.name || '') : newObject.name;

    const values = {
        title: `${_.startCase(objectType.slice(0, -1))} is not part of the Group`,
        subtitle:
            newTargetGroups.length === 1
                ? `Do you wish to add ${objectName} to the ${newTargetGroups[0].name} group?`
                : `Do you wish to add ${objectName} to the following groups?`
    };

    const children =
        newTargetGroups.length === 1
            ? null
            : newTargetGroups.map((tg) => (
                  <ObjectListWrapper key={`${tg._id}`}>
                      <ObjectListItem>- {tg.name || EMPTY_WORD_STRING}</ObjectListItem>
                  </ObjectListWrapper>
              ));

    dialogConfirm(
        '',
        () => onSave(true),
        values,
        children,
        { confirmButton: { label: 'Add' }, noButton: { label: "Don't add" } },
        undefined,
        () => onSave()
    );
};
