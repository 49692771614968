import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { Language, LanguageCode, translationKey } from '../../types/Language';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { translationsParser } from '../../utils/parsers';
import { ObjectFilter } from '../../utils/fnFilter';

export const fetchLanguages = createAsyncThunk<
    { languages: Language[]; error: { message: string; code: string } | null; permissions?: any },
    any | undefined
>('languages/fetchLanguages', async ({ addPermissions, projectId, orderBy, searchTerm }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllLanguages(addPermissions, projectId, orderBy, searchTerm);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { languages: result.response as any, error: null, permissions: result.permissions };
});

export const fetchLockedLanguages = createAsyncThunk<{ languages: Language[]; error: { message: string; code: string } | null }, string>(
    'languages/fetchLockedLanguages',
    async (projectId, thunkApi) => {
        const result = await ConfigServiceAPI.getAllLockedLanguages(projectId);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { languages: result.response as any, error: null, permissions: result.permissions };
    }
);

export const fetchLanguage = createAsyncThunk<
    { language: Language; error: { message: string; code: string } | null },
    { id: string; addTranslations?: boolean }
>('languages/fetchLanguage', async ({ id, addTranslations }, thunkApi) => {
    const result = await ConfigServiceAPI.getLanguage(id, addTranslations);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }

    return { language: result.response as any as Language, error: null };
});

export const fetchLanguageTranslations = createAsyncThunk<
    {
        translations: any;
        totalCount: number | undefined;
        error: { message: string; code: string } | null;
    },
    {
        languageId: string;
        page?: number;
        size?: number;
        orderBy?: string;
        searchTerm?: string;
        filter?: ObjectFilter;
        withDescriptions?: boolean;
    }
>('languages/fetchLanguageTranslations', async ({ languageId, page, size, orderBy, searchTerm, filter, withDescriptions }, thunkApi) => {
    const result = await ConfigServiceAPI.getTranslations(languageId, page, size, orderBy, searchTerm, filter, withDescriptions);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { translations: result.response as any, totalCount: result.totalResults, error: null };
});

export const fetchAllLanguageTranslations = createAsyncThunk<
    { allTranslations: Record<string, translationKey>; error: { message: string; code: string } | null },
    { projectId: string }
>('languages/fetchAllLanguageTranslations', async ({ projectId }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllTranslations(projectId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { allTranslations: result.response as any, error: null };
});

export const fetchLanguageCodes = createAsyncThunk<{ codes: LanguageCode[]; error: { message: string; code: string } | null }>(
    'languages/fetchLanguageCodes',
    async (_, thunkApi) => {
        const result = await ConfigServiceAPI.getAllLanguageCodes();
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { codes: result.response as any, error: null };
    }
);

export const fetchCountryCodes = createAsyncThunk<{ codes: any[]; error: { message: string; code: string } | null }, boolean | undefined>(
    'languages/fetchCountryCodes',
    async (forAssetManager, thunkApi) => {
        const result = await ConfigServiceAPI.getAllCountryCodes(forAssetManager);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { codes: result.response as any, error: null };
    }
);

export const createLanguage = createAsyncThunk<{ id: string; error: { message: string; code: string } | null }, Language>(
    'languages/createLanguage',
    async (language: Language, thunkApi) => {
        const result = await ConfigServiceAPI.createLanguage(language);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any, error: null };
    }
);
export const updateLanguageList = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, any[]>(
    'languages/updateLanguageList',
    async (languages: any[], thunkApi) => {
        const result = await ConfigServiceAPI.updateLanguageList(languages);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: result.response as any, error: null };
    }
);
export const updateLanguageTranslations = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null; lockedLanguages: any[] },
    { projectId: string; tenantId: string; key: string; values: any; description?: string }
>('languages/updateLanguageTranslations', async ({ projectId, tenantId, key, values, description }, thunkApi) => {
    const result = await ConfigServiceAPI.updateTranslations(tenantId, projectId, key, values, description);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    const { updated, lockedLanguages } = result.response as any;
    return { ok: updated, error: null, lockedLanguages };
});
export const updateLanguage = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { language: Language; translationDescriptions?: Record<string, string> }
>('languages/updateLanguage', async ({ language, translationDescriptions }, thunkApi) => {
    const result = await ConfigServiceAPI.updateLanguage(language, translationDescriptions);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: result.response as any, error: null };
});
export const deleteLanguage = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'languages/deleteLanguage',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.deleteLanguage(id);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: result.response as any, error: null };
    }
);
export interface LanguagesState {
    languages: Language[];
    allTranslations?: Record<string, translationKey>;
    allTranslationsLoading?: boolean;
    languageCodes: LanguageCode[];
    countryCodes: any[];
    countryCodesAssetManager: any[];
    loading: boolean;
    loadingLanguage: boolean;
    translationsLoading: boolean;
    error: {
        message: string;
        code?: string;
        status?: number;
    } | null;
}

const initialState: LanguagesState = {
    languages: [],
    languageCodes: [],
    countryCodes: [],
    countryCodesAssetManager: [],
    error: null,
    loading: false,
    loadingLanguage: false,
    translationsLoading: false
};

const slice = createSlice({
    name: 'languages',
    initialState,
    reducers: {
        unsetLanguageError(state) {
            state.error = null;
        },
        unsetLanguages(state) {
            state.languages = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLanguages.fulfilled, (state, action: any) => {
                state.languages = translationsParser(action.payload.languages);
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchLanguages.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.languages = [];
                state.loading = false;
            })
            .addCase(fetchLanguages.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchLockedLanguages.fulfilled, (state, action: any) => {
                state.loading = false;
            })
            .addCase(fetchLockedLanguages.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(fetchLockedLanguages.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchLanguage.fulfilled, (state, action: any) => {
                // every time a certain language updates, it needs to be updated also in languages state
                const language = translationsParser([action.payload.language])[0];
                const languages = [...current(state.languages)];
                if (!languages.length) {
                    languages.push(language);
                } else {
                    const index = languages.findIndex((lang) => lang._id === language._id);
                    languages.splice(index, 1, language);
                }
                state.languages = languages;
                state.loadingLanguage = false;
            })
            .addCase(fetchLanguage.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loadingLanguage = false;
            })
            .addCase(fetchLanguage.pending, (state, _action) => {
                state.loadingLanguage = true;
            })
            .addCase(fetchLanguageTranslations.fulfilled, (state, action: any) => {
                state.translationsLoading = false;
            })
            .addCase(fetchLanguageTranslations.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.translationsLoading = false;
            })
            .addCase(fetchLanguageTranslations.pending, (state, _action) => {
                state.translationsLoading = true;
            })
            .addCase(fetchAllLanguageTranslations.fulfilled, (state, action: any) => {
                state.allTranslationsLoading = false;
                state.allTranslations = action.payload.allTranslations;
            })
            .addCase(fetchAllLanguageTranslations.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.allTranslationsLoading = false;
            })
            .addCase(fetchAllLanguageTranslations.pending, (state, _action) => {
                state.allTranslationsLoading = true;
            })
            .addCase(fetchLanguageCodes.fulfilled, (state, action: any) => {
                state.languageCodes = action.payload.codes;
            })
            .addCase(fetchCountryCodes.fulfilled, (state, action: any) => {
                if (action.meta.arg === true) {
                    // for asset manager
                    state.countryCodesAssetManager = action.payload.codes;
                } else {
                    state.countryCodes = action.payload.codes;
                }
            })
            .addCase(createLanguage.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createLanguage.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createLanguage.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(updateLanguage.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateLanguage.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateLanguage.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(updateLanguageList.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateLanguageList.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateLanguageList.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(updateLanguageTranslations.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateLanguageTranslations.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateLanguageTranslations.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(deleteLanguage.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteLanguage.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteLanguage.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            });
    }
});

export const { unsetLanguageError, unsetLanguages } = slice.actions;
export default slice.reducer;
