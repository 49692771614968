import React from 'react';
import { CSSProperties } from 'styled-components';
import icons from '../../../assets/images/icons';
import useScreenSize from '../../../hooks/useScreenSize';
import { ButtonIcon, ButtonInner, ButtonLoader, ButtonWrapper } from './Button.css';
import SVGInline from 'react-inlinesvg';

const ButtonIcons: any = {
    'Manage Users': icons.usersIcon,
    default: icons.addIcon
};

type ButtonProps = {
    id?: string;
    type: 'BLUE' | 'RED' | 'PINK' | 'DEFAULT' | 'WHITE';
    className?: string;
    label: string | JSX.Element | JSX.Element[];
    onClick?: any;
    onMouseEnter?: any;
    onMouseLeave?: any;
    style?: CSSProperties;
    isDisabled?: boolean;
    openNewTab?: boolean;
    htmlAttrs?: any;
    loading?: boolean;
    children?: React.ReactNode;
    responsive?: boolean;
    mobileIcon?: any;
    dataCy?: string;
};

const Button: React.FC<ButtonProps> = ({
    htmlAttrs = {},
    type,
    onClick,
    onMouseLeave,
    onMouseEnter,
    className,
    label,
    isDisabled,
    openNewTab,
    style,
    loading,
    responsive,
    dataCy,
    ...rest
}) => {
    const { isSmallMobile } = useScreenSize();

    const clickHandler = (e: any) => {
        if (onClick) onClick(e);
    };

    const hoverHandler = (e: any, direction: 'in' | 'out') => {
        direction === 'in' ? onMouseEnter?.(e) : onMouseLeave?.(e);
    };

    const newStyle: CSSProperties = { ...style };
    if (isDisabled || loading) {
        newStyle.pointerEvents = 'none';
        newStyle.opacity = 0.5;
    }

    return (
        <ButtonWrapper
            htmlAttrs={htmlAttrs}
            style={newStyle}
            className={`${className}`}
            onClick={isDisabled || loading ? () => null : clickHandler}
            onMouseEnter={isDisabled || loading ? () => null : (e: any) => hoverHandler(e, 'in')}
            onMouseLeave={isDisabled || loading ? () => null : (e: any) => hoverHandler(e, 'out')}
            data-cy={dataCy}
            $buttonType={type}
            $loading={loading}
            $responsive={responsive}
            $fullWidth={!isSmallMobile}
            {...rest}
        >
            {loading ? (
                <ButtonLoader type={type} />
            ) : (
                <ButtonInner>
                    {rest.children}
                    {responsive ? (
                        <ButtonIcon>
                            <SVGInline src={ButtonIcons[label as string] || ButtonIcons.default} />
                        </ButtonIcon>
                    ) : (
                        label
                    )}
                </ButtonInner>
            )}
        </ButtonWrapper>
    );
};

export default Button;
