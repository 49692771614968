import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { TableContainer } from '../common/Table/Table.css';
import { SearchBarContainer } from '../common/SearchBar/SearchBar.css';
import { TruncatedText } from '../../style/styled-components/reusable.css';

const theme: any = getTheme();

export const ProjectSelectionContainer = styled.div`
    min-height: 100vh;
    display: block;
    background: ${theme.palette.background.default};
    .MuiTable-root {
        table-layout: auto;

        .MuiTableCell-root {
            white-space: unset;
        }
    }

    ${TableContainer} {
        margin: 16px 36px 24px;
    }
    ${SearchBarContainer} {
        margin: 0 36px;
        box-sizing: border-box;
    }
`;

export const UserAvatar = styled.div<{ background?: string }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: url(${(props) => `'${props.background}'`});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const UserAvatarPlaceholder = styled(UserAvatar)`
    background-color: ${theme.palette.grey.light};
    ${fontSizesCSS.Caption3};
    border-radius: 50%;
    font-weight: bold;
    color: #fff;
    &:hover {
        cursor: pointer;
    }
`;

export const descendingZIndex = () => {
    let styles = ``;
    for (let i = 1; i < 10; i++) {
        styles += `${UserAvatar}:nth-child(${i}) {
            z-index: ${10 - i};
        }`;
    }
    return styles;
};

export const ProjectLeadsContainer = styled.div`
    display: flex;
    ${descendingZIndex()};

    ${UserAvatar} {
        border: solid 1px ${theme.palette.white.main};
        margin-right: -8px;
        width: 30px;
        height: 30px;
    }
`;

export const LeadInitialsContainer = styled.div<{ $color: string; $lastSumUp?: boolean }>`
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 50%;
    span {
        color: ${theme.palette.white.main};
    }
    background-color: ${(props) => (props.$lastSumUp ? theme.palette.grey.light : props.$color)};
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;

    svg {
        width: 16px;
        height: 16px;
        color: ${theme.palette.grey.main};
    }
`;

export const ProjectPreviewForDeletion = styled.div`
    display: flex;
    align-items: center;
    background-color: ${theme.palette.background.contrast};
    margin-bottom: 36px;

    img {
        width: 48px;
        height: 48px;
        padding: 0;
        margin-right: 12px;
        margin-left: 12px;
    }

    p {
        padding-left: 5px;
        font-weight: bold;
    }
`;

export const CategoryRow = styled.div`
    ${fontSizesCSS.Caption1};
    color: ${theme.palette.text.main};
`;

export const UserOptionContainer = styled.div`
    display: flex;
    align-items: center;

    ${UserAvatar} {
        width: 24px;
        height: 24px;
    }
`;

export const UserOptionName = styled(TruncatedText)`
    margin-left: 8px;
`;

export const UserOptionEmail = styled.div`
    margin-left: 8px;
`;

export const PasscodeContainer = styled.div`
    .MuiInputBase-root {
        .MuiInputAdornment-positionEnd {
            margin-right: 8px;
            cursor: pointer;
        }
    }
`;
