import React, { FC, useState } from 'react';
import WidgetHeader from './WidgetHeader';
import {
    AnalyticsWidget,
    PieDataDeviceCategoryChangeIndicatorContainer,
    PieDataDeviceCategoryColumn,
    PieDataDeviceCategoryColumnChangeValue,
    PieDataDeviceCategoryColumnValue,
    PieDataDeviceCategoryIconHolder,
    PieDataDeviceCategoryLegendsWrapper,
    PieDataGenericLegendRowChangeIndicator,
    PieDataGenericLegendRowChangeValue,
    PieDataGenericLegendRowLabel,
    PieDataGenericLegendsBubbleIndicator,
    PieDataGenericLegendsRow,
    PieDataGenericLegendsWrapper,
    VerticallyCenteredAnalyticsWidgetContent
} from '../Analytics.css';
import AnalyticsPieChart from '../Charts/AnalyticsPieChart';
import {
    analyticsChangeIconSwitcher,
    CHART_KEYS,
    chartColorPalette,
    createPercentChangeLabel,
    calculatePercentageAndIndicator
} from '../Charts/ChartUtils';
import SVGInline from 'react-inlinesvg';
import icons from '../../../style';
import { Tooltip } from '@material-ui/core';
import { renderTooltip, tooltipPositions, tooltipTypes } from '../../common/Tooltips/Tooltips';
import { PlatformAnalyticsData } from '../../../redux/slices/analyticsSlice';
import { AnalyticsDeviceCategoryIcons } from '../../../assets/images/icons';

const PieChartWidget: FC<{
    dataKey: string;
    pieData: PlatformAnalyticsData;
    totalDevices: number;
    title: string;
    subtitle: string;
    comparedTimeframeLabel: string;
}> = ({ dataKey, title, subtitle, pieData, comparedTimeframeLabel, totalDevices }) => {
    const [hoveredId, setHoveredId] = useState<string>('');

    const renderLegends = () => {
        if (dataKey === CHART_KEYS.device_category)
            return (
                <PieDataDeviceCategoryLegends
                    legendData={pieData}
                    comparedTimeframeLabel={comparedTimeframeLabel}
                    totalDevices={totalDevices}
                />
            );
        return (
            <PieDataGenericLegends
                legendData={pieData}
                hoveredId={hoveredId}
                comparedTimeframeLabel={comparedTimeframeLabel}
                totalDevices={totalDevices}
            />
        );
    };

    return (
        <AnalyticsWidget>
            <WidgetHeader title={title} subtitle={subtitle} />
            <VerticallyCenteredAnalyticsWidgetContent>
                <AnalyticsPieChart data={pieData} onHoverData={(hoveredDataId) => setHoveredId(hoveredDataId)} />
                {renderLegends()}
            </VerticallyCenteredAnalyticsWidgetContent>
        </AnalyticsWidget>
    );
};

const PieDataGenericLegends: FC<{
    legendData: PlatformAnalyticsData;
    hoveredId: string;
    comparedTimeframeLabel: string;
    totalDevices: number;
}> = ({ legendData, hoveredId, comparedTimeframeLabel, totalDevices }) => {
    return (
        <PieDataGenericLegendsWrapper>
            {legendData.map((data, index) => {
                const { percentChange, changeIndicator } = calculatePercentageAndIndicator(
                    totalDevices,
                    data.value,
                    data.comparedValue || 0
                );

                return (
                    <PieDataGenericLegendsRow key={data.id}>
                        <PieDataGenericLegendsBubbleIndicator
                            color={chartColorPalette[index]}
                            hovered={hoveredId === data.id}
                        ></PieDataGenericLegendsBubbleIndicator>
                        <Tooltip title={data.label || data.id} placement={tooltipPositions.TOP}>
                            <PieDataGenericLegendRowLabel>{data.label || data.id}</PieDataGenericLegendRowLabel>
                        </Tooltip>
                        <PieDataDeviceCategoryColumnValue>{data.value}</PieDataDeviceCategoryColumnValue>
                        <PieDataGenericLegendRowChangeValue>{createPercentChangeLabel(percentChange)}</PieDataGenericLegendRowChangeValue>
                        {renderTooltip(
                            <PieDataGenericLegendRowChangeIndicator>
                                <SVGInline src={analyticsChangeIconSwitcher(changeIndicator)} />
                            </PieDataGenericLegendRowChangeIndicator>,
                            tooltipTypes.TEXT,
                            `${data.comparedValue || 0} ${comparedTimeframeLabel}`,
                            tooltipPositions.TOP,
                            true
                        )}
                    </PieDataGenericLegendsRow>
                );
            })}
        </PieDataGenericLegendsWrapper>
    );
};
const PieDataDeviceCategoryLegends: FC<{ legendData: PlatformAnalyticsData; comparedTimeframeLabel: string; totalDevices: number }> = ({
    legendData,
    comparedTimeframeLabel,
    totalDevices
}) => {
    return (
        <PieDataDeviceCategoryLegendsWrapper>
            {legendData.map((data, index) => {
                const { percentChange, changeIndicator } = calculatePercentageAndIndicator(
                    totalDevices,
                    data.value,
                    data.comparedValue || 0
                );

                return (
                    <PieDataDeviceCategoryColumn key={data.id}>
                        <PieDataDeviceCategoryIconHolder>
                            <SVGInline src={AnalyticsDeviceCategoryIcons[data.id]} />
                        </PieDataDeviceCategoryIconHolder>
                        <PieDataGenericLegendRowLabel>{data.label || data.id.toUpperCase()}</PieDataGenericLegendRowLabel>
                        <PieDataDeviceCategoryColumnValue>{data.value}</PieDataDeviceCategoryColumnValue>
                        <PieDataDeviceCategoryChangeIndicatorContainer>
                            <PieDataDeviceCategoryColumnChangeValue>
                                {createPercentChangeLabel(percentChange)}
                            </PieDataDeviceCategoryColumnChangeValue>
                            {renderTooltip(
                                <SVGInline src={analyticsChangeIconSwitcher(changeIndicator)} />,
                                tooltipTypes.TEXT,
                                `${data.comparedValue || 0} ${comparedTimeframeLabel}`,
                                tooltipPositions.TOP,
                                true
                            )}
                        </PieDataDeviceCategoryChangeIndicatorContainer>
                    </PieDataDeviceCategoryColumn>
                );
            })}
        </PieDataDeviceCategoryLegendsWrapper>
    );
};

export default PieChartWidget;
