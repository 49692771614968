import { TableRow } from '@material-ui/core';
import styled, { css } from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { DialogTextField } from '../common/Dialog/GenericDialog';
import SVGInline from 'react-inlinesvg';
import { itemTypes } from '../../types/Item';
import {
    DescriptionContainer,
    SubTitleContainer,
    TitleContainer
} from '../PageEdit/EditorialItemCustomization/EditorialItemCustomization.css';

const theme: any = getTheme();

export const EditorialTableRow = styled(TableRow)`
    background: ${theme.palette.background.pressed};
`;

export const EditorialViewContainer = styled.div<{ $itemType: itemTypes }>`
    height: ${({ $itemType }) => ($itemType === itemTypes.DYNAMIC ? '311px' : '359px')};
    background-color: ${({ $itemType }) => ($itemType === itemTypes.DYNAMIC ? `${theme.palette.black.main}` : 'transparent')};
    position: relative;
    border-radius: 5px;
    margin: 8px 0;
    width: auto;
    contain: size;

    .MuiInputAdornment-root {
        right: 0;
        top: 0;
        button {
            padding: 0;
        }
    }
    .MuiInputBase-root {
        border: none;
    }
`;

export const EditButtons = styled.div`
    position: absolute;
    width: 72px;
    height: 112px;
    top: calc(50% - 10px);
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 10px;
    z-index: 1;
    cursor: pointer;
`;

export const LaneWrapper = styled.div`
    overflow-x: scroll;
    white-space: nowrap;
    display: inline-block;
    width: calc(100% - 96px);
    margin-top: 36px;
    margin-left: 96px;
    height: 239px;
    background-color: ${theme.palette.black.main};
    @-moz-document url-prefix() {
        scrollbar-color: ${theme.palette.primary.main} ${theme.palette.text.main};
        scrollbar-width: thin;
    }

    ::-webkit-scrollbar {
        display: block;
        height: 4px;
        background-color: ${theme.palette.text.main};
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: ${theme.palette.primary.main};
    }
    ::-webkit-scrollbar-button {
        display: none;
    }
`;

export const CardContainer = styled.div`
    width: 290px;
    height: 211px;
    margin: 0 12px;
    &:first-child {
        margin-left: 0;
    }
    display: inline-block;
`;
export const CardImage = styled.div`
    width: 100%;
    height: 163px;
    background-color: ${theme.palette.text.main};
    position: relative;
`;

export const LogoWrapper = styled.div`
    width: 20px;
    height: 20px;
    position: absolute;
    right: 50px;
    top: 12px;
`;

export const Picture = styled.img`
    width: 100%;
    height: 100%;
`;

export const CardTitle = styled.div`
    ${fontSizesCSS.Caption2};
    color: white;
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const CardDescription = styled.div`
    ${fontSizesCSS.Caption3};
    color: white;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

// Editorial type
export const EditorialTypeContainer = styled.div<{ $background?: string }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 96px;
    height: 100%;
    z-index: 99;
    background: ${(props) => (props.$background ? ` ${props.$background}` : 'transparent')};
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px;
`;

export const BackgroundContainer = styled.div`
    position: relative;
    width: 290px;
    height: auto;
    z-index: 3;
`;

export const BackgroundSwitchContainer = styled.div<{ insideGallery?: boolean; insideBanner?: boolean }>`
    width: 100%;
    position: absolute;
    ${({ insideGallery }) =>
        insideGallery
            ? css`
                  right: 24px;
                  width: unset;
              `
            : ''}

    ${({ insideBanner }) =>
        insideBanner
            ? css`
                  right: 24px;
                  bottom: 24px;
                  width: unset;
              `
            : ''}
`;

export const AppliedForAllText = styled.div`
    white-space: nowrap;
    color: ${theme.palette.white.main};
    text-align: center;
    ${fontSizesCSS.Caption3};
    opacity: 0.7;
`;

export const BackgroundSwitch = styled.div`
    width: 160px;
    margin: 8px auto 0;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-around;
`;

export const DeviceBackgroundSVG = styled(SVGInline)<{ active: boolean; disabled?: boolean }>`
    cursor: pointer;
    opacity: ${(props) => (props.active ? '1' : '0.7')};

    width: 24px;
    height: 24px;

    &:hover {
        opacity: 1;
    }
    ${({ disabled }) =>
        disabled
            ? css`
                  pointer-events: none;
                  opacity: 0.4;
                  cursor: unset;
              `
            : ''}
`;

export const BackgroundSelector = styled.div<{ $background?: string }>`
    box-sizing: border-box;
    border: 2px dashed ${theme.palette.background.innerBorder};
    background: ${(props) => (props.$background ? `${props.$background}` : theme.palette.text.main)};
    border-radius: 5.5px;
    width: 290px;
    height: 163px;
    color: ${theme.palette.white.main};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    svg {
        opacity: 0.7;
        width: 32px;
        height: 32px;
    }
    &:hover {
        border: 2px dashed ${theme.palette.white.main};
        svg {
            opacity: 1;
        }
    }
`;

export const EditorialHeroInfo = styled.div`
    z-index: 3;
    width: 40%;
    padding-left: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .MuiInputBase-input {
        padding: 0 0 0 2px;
        background-color: transparent;
        color: ${theme.palette.text.light};
        box-sizing: border-box;
        &:focus {
            border: 1px solid ${theme.palette.primary.main};
        }
        &::placeholder {
            color: ${theme.palette.white.main};
            opacity: 1;
        }
    }

    ${SubTitleContainer}, ${DescriptionContainer}, ${TitleContainer} {
        padding-left: 12px;
        width: 100%;
        .MuiInputBase-input {
            width: 680px;
        }
    }

    ${SubTitleContainer},${DescriptionContainer} {
        .MuiInputBase-input {
            margin: 4px 0;
        }
    }
`;

export const AddLogoContainer = styled.div<{ preview?: boolean }>`
    color: ${theme.palette.white.main};
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    ${(props) => !props.preview && 'cursor: pointer'};
    ${fontSizesCSS.Caption3};
`;

export const AddLogoIcon = styled.div<{ $preview?: string }>`
    width: 24px;
    height: 24px;
    ${(props) => (props.$preview ? `background: url('${props.$preview}')` : `background-color: ${theme.palette.background.main}`)};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    ${(props) => !props.$preview && 'border-radius: 50%'};
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        path {
            fill: ${theme.palette.text.main};
        }
    }
`;

export const EditTextField = styled(DialogTextField)``;

export const ActionButtonContainer = styled.div`
    padding-top: 10px;
    button {
        width: 81px;
        min-width: fit-content;
        margin-left: 12px;
        height: 36px;
        margin-bottom: 0;
    }
`;

export const ButtonWrapper = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 4px;
    text-transform: none;

    .MuiInputAdornment-root {
        margin-top: 10px;
        button {
            width: 20px;
        }
    }

    svg {
        path {
            fill: ${theme.palette.white.main};
        }
    }
`;
