import React, { FC, useEffect, useState } from 'react';
import { Module, templates } from '../../../types/Module';
import {
    DescriptionField,
    FieldsContainer,
    GalleryCardsContainer,
    GalleryOrPreviewContentWrapper,
    GradientBackgroundToRight,
    GradientBackgroundToRightV2,
    GradientBackgroundToTop,
    GradientBackgroundToTopV2,
    PreviewOrGalleryViewContainer,
    SubtitleField,
    TitleField,
    TruncatingTextHolder
} from '../VisualEditor.css';
import { Item, itemTypes } from '../../../types/Item';
import _ from 'lodash';
import { ButtonsContainer } from '../EditorialItemCustomization/EditorialItemCustomization.css';
import Button from '../../Buttons/Button/Button';
import { useAppSelector } from '../../../hooks/redux';
import { calculateItemBackgroundToShow, generateGradientValues } from '../../../utils/fnColor';
import { DynamicItemPreview } from '../ItemPreview/DynamicItemPreview';
import { ItemState } from '../../../redux/slices/itemSlice';
import getTheme from '../../../style/themes/theme';
import icons from '../../../style';
import { GalleryAndPreviewEditorialCustomization } from '../EditorialItemCustomization/GalleryAndPreviewCustomization';
import { getDynamicItems } from '../../../utils/fnPreviews';
import { CardsStripe } from './CardsStripe';
import { ScreenTypes } from '../../../types/AppBranding';
import { AddLogoContainer, AddLogoIcon } from '../../Items/EditorialView.css';
import { PageStyle, PageStyleColors } from '../../../types/PageStyle';
import TranslationTooltip from '../../common/TranslationTooltip/TranslationTooltip';
import { UNSAFE_useEffectOnce } from '../../../hooks/useEffectOnce';
import { CloseButtonWrapper } from '../../Modules/VisualEditor.css';
import SVGInline from 'react-inlinesvg';
import { ModulesState } from '../../../redux/slices/moduleSlice';
import { calculateTop10offsetsForModule } from '../../../utils/fnData';
import useTranslation from '../../../hooks/useTranslation';

export type GalleryAndPreviewProps = {
    module: Module;
    moduleIndex: number;
    item?: Item;
    type: 'GALLERY' | 'PREVIEW';
    style?: PageStyle;
    findItem: (id: string, moduleIndex: number) => { index: number };
    moveItem: (id: string, moduleIndex: number, to: number) => void;
    setItemForPreview: (itemId: string, moduleId: string, type: itemTypes) => void;
    unsetItemForPreview: () => void;
    onBackgroundChange: (preview: any) => void;
    onCardClick: (ci: Item) => void;
    onItemAction: (type: any, item?: Item, index?: number) => void;
    onSave: (ci: Item) => void;
    isTemplate?: boolean;
    dragDisabled?: boolean;
};

type BackgroundValues = {
    heroBackgroundColor?: string;
    heroBackgroundImage?: ScreenTypes;
    backgroundImage?: ScreenTypes;
    backgroundColor?: string;
};

export const GalleryAndPreview: FC<GalleryAndPreviewProps> = ({
    module,
    item,
    type,
    moduleIndex,
    style,
    moveItem,
    findItem,
    setItemForPreview,
    unsetItemForPreview,
    onBackgroundChange,
    onCardClick,
    onItemAction,
    onSave,
    isTemplate,
    dragDisabled
}) => {
    const { itemPreview }: ItemState = useAppSelector((state) => state.items);
    const { validTemplates }: ModulesState = useAppSelector((state) => state.modules);
    const { translate } = useTranslation();
    const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
    const [isCustomizeOpen, setIsCustomizeOpen] = useState<boolean>(false);
    const [backgroundValues, setBackgroundValues] = useState<BackgroundValues>({});
    const [data, setData] = useState<any[]>([]);
    const [dynamicDataIndexToShow, setDynamicDataIndexToShow] = useState(0);
    const [pageColors, setPageColors] = useState<PageStyleColors>({});

    const isGallery = type === 'GALLERY';

    const theme: any = getTheme();

    const loadDynamicData = async (item: Item) => {
        const items = await getDynamicItems(item, false, isTemplate);
        if (Array.isArray(items)) {
            setData(items);
        }
    };

    const initBackground = () => {
        if (!item) return;
        setBackgroundValues({
            backgroundColor: item.backgroundColor || '',
            backgroundImage:
                item?.itemType === itemTypes.DYNAMIC
                    ? { bigScreen: data[dynamicDataIndexToShow]?.image }
                    : item?.backgroundImage || undefined,
            heroBackgroundImage:
                item?.itemType === itemTypes.DYNAMIC ? data[dynamicDataIndexToShow]?.image : item?.heroBackgroundImage || undefined,
            heroBackgroundColor: item.heroBackgroundColor || ''
        });
    };

    useEffect(() => {
        if (style && style?.colors) {
            setPageColors(style.colors);
        }
    }, [style]);

    UNSAFE_useEffectOnce(() => {
        if (!item) return;
        item?.itemType === itemTypes.DYNAMIC ? loadDynamicData(item) : initBackground();

        return () => {
            setBackgroundValues({});
        };
    }, [item]);

    useEffect(() => {
        if (!data.length) return;
        initBackground();
    }, [data, dynamicDataIndexToShow]);

    useEffect(() => {
        if (!backgroundValues) return;
        onBackgroundChange?.(calculateItemBackgroundToShow(backgroundValues, !isGallery));
    }, [backgroundValues]);

    useEffect(() => {
        if (!itemPreview || _.isEmpty(itemPreview)) {
            setIsPreviewOpen(false);
            setIsCustomizeOpen(false);
            return;
        }
        if (itemPreview.type === itemTypes.DYNAMIC) {
            setIsPreviewOpen(itemPreview.moduleId === module._id);
        } else {
            setIsCustomizeOpen(itemPreview.moduleId === module._id);
        }
    }, [itemPreview]);

    const handleSaveClick = (item: Item) => {
        onSave(item);
    };

    let buttonStyle: any = null;
    if (!_.isEmpty(pageColors)) {
        buttonStyle = {
            backgroundColor: pageColors?.primary,
            color: pageColors?.body
        };
    }

    const calculateLogoToShow = () => {
        if (item?.itemType === itemTypes.EDITORIAL && item?.logo) return decodeURIComponent(item.logo);
        if (item?.itemType === itemTypes.DYNAMIC && data[dynamicDataIndexToShow]?.logo) {
            return decodeURIComponent(data[dynamicDataIndexToShow].logo);
        }
        return '';
    };
    const logoToShow = calculateLogoToShow();

    const renderNormalView = () => {
        return (
            <>
                <FieldsContainer isGallery>
                    <AddLogoContainer preview>{logoToShow && <AddLogoIcon $preview={logoToShow} />}</AddLogoContainer>
                    <TitleField color={pageColors?.body}>
                        <TruncatingTextHolder>
                            {translate(
                                item?.itemType === itemTypes.DYNAMIC ? data[dynamicDataIndexToShow]?.title || '' : item?.title || ''
                            )}
                        </TruncatingTextHolder>
                        {item?.title && <TranslationTooltip translationKey={item.title} iconColor={theme.palette.white.main} />}
                    </TitleField>

                    <SubtitleField color={pageColors?.body}>
                        <TruncatingTextHolder>
                            {translate(
                                item?.itemType === itemTypes.DYNAMIC ? data[dynamicDataIndexToShow]?.subtitle || '' : item?.subtitle || ''
                            )}
                        </TruncatingTextHolder>
                        {item?.subtitle && <TranslationTooltip translationKey={item.subtitle} iconColor={theme.palette.white.main} />}
                    </SubtitleField>
                    <DescriptionField color={pageColors?.body} twoLine>
                        <TruncatingTextHolder twoLine>
                            {translate(
                                item?.itemType === itemTypes.DYNAMIC
                                    ? data[dynamicDataIndexToShow]?.description || ''
                                    : item?.description || ''
                            )}
                        </TruncatingTextHolder>
                        {item?.description && <TranslationTooltip translationKey={item.description} iconColor={theme.palette.white.main} />}
                    </DescriptionField>
                    {isGallery && (
                        <ButtonsContainer buttonsDisabled>
                            {item?.itemType === itemTypes.EDITORIAL && item?.actionName && (
                                <Button
                                    style={!!buttonStyle && buttonStyle}
                                    type={'BLUE'}
                                    label={
                                        item?.action
                                            ? _.truncate(item.actionName || 'DEFAULT', {
                                                  length: 20,
                                                  omission: '...'
                                              })
                                            : 'Set Action'
                                    }
                                    onClick={() => {}}
                                />
                            )}
                            {item?.itemType === itemTypes.DYNAMIC && (
                                <Button style={!!buttonStyle && buttonStyle} type={'BLUE'} label={'Watch'} onClick={() => {}} />
                            )}
                        </ButtonsContainer>
                    )}
                </FieldsContainer>
            </>
        );
    };
    const template = validTemplates?.find((t) => t.value === module.template);
    const renderCustomizeView = () => {
        return (
            <GalleryAndPreviewEditorialCustomization
                item={item!}
                onSave={handleSaveClick}
                onClose={unsetItemForPreview}
                calculateBackground={calculateItemBackgroundToShow}
                onBackgroundChange={onBackgroundChange}
                type={type}
                template={template.key}
            />
        );
    };

    const cardType = isGallery ? '16X9_S' : template?.key || 'UNKNOWN';
    const top10Offsets = calculateTop10offsetsForModule(module);
    const itemIndex = module?.items?.findIndex((item) => item._id === itemPreview?.itemId) || 0;
    const offset = module.topTen && top10Offsets?.length ? top10Offsets[itemIndex] : 0;
    return (
        <GalleryOrPreviewContentWrapper>
            <>
                {isPreviewOpen && (
                    <CloseButtonWrapper
                        onClick={() => {
                            setDynamicDataIndexToShow(0);
                            unsetItemForPreview();
                        }}
                    >
                        <SVGInline src={icons.closeIcon} />
                    </CloseButtonWrapper>
                )}
                <GradientBackgroundToTopV2 black={10} transparent={40} />
                <GradientBackgroundToRightV2 black={20} transparent={100} />
                <PreviewOrGalleryViewContainer noBottomMargin={!isCustomizeOpen && module.template === templates.BANNER_S}>
                    {isCustomizeOpen ? renderCustomizeView() : renderNormalView()}
                </PreviewOrGalleryViewContainer>

                {!isCustomizeOpen && (
                    <GalleryCardsContainer>
                        {!isPreviewOpen && (
                            <CardsStripe
                                module={module}
                                cardType={cardType}
                                findItem={findItem}
                                moveItem={moveItem}
                                setItemForPreview={setItemForPreview}
                                unsetItemForPreview={unsetItemForPreview}
                                onItemAction={onItemAction}
                                isGallery={isGallery}
                                onCardClick={(ci) => onCardClick(ci)}
                                moduleIndex={moduleIndex}
                                isTemplate={isTemplate}
                                style={style}
                                dragDisabled={dragDisabled}
                            />
                        )}

                        {itemPreview && isPreviewOpen && (
                            <DynamicItemPreview
                                open={isPreviewOpen}
                                template={cardType}
                                topTen={module?.topTen}
                                top10Offset={offset}
                                onClose={() => {
                                    unsetItemForPreview();
                                    setDynamicDataIndexToShow(0);
                                }}
                                isGalleryOrPreview={true}
                                autoCollection={!!module.autoCollection}
                                viewAllCardPlacement={module.viewAllCardPlacement}
                                onCardClick={(index) => setDynamicDataIndexToShow(index)}
                            />
                        )}
                    </GalleryCardsContainer>
                )}
            </>
        </GalleryOrPreviewContentWrapper>
    );
};
